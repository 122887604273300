import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { SalepointsField, SexField, ToggleField, LangsField, PhoneField } from '../../../components';
import { loadSalepoints } from '../../../redux';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css'

import './CustomerForm.css';

moment.locale('fr');
momentLocalizer();

let CustomerForm = ({ handleSubmit, isCreation, isLoading, pristine, submitting }) => {
    const { t }          = useTranslation();
    const dispatch       = useDispatch();
    const { salepoints } = useSelector(state => state.salepoints);

    useEffect(() => {
        dispatch(loadSalepoints());
    }, [dispatch]);

    const renderDateTimePicker = ({ input: { onChange, value }, showTime }) =>
        <DateTimePicker
            onChange={onChange}
            format="DD MMM YYYY"
            time={showTime}
            value={!value ? null : new Date(value)}
        />

    return (
            <Form className="form" onSubmit={handleSubmit}>
                <Row className="main">
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <i className="fa fa-user-circle"></i>&nbsp;
                                    {t('customers.profile')}
                                </CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Label for="name" xl={3}>{t('tktUsers.name')}</Label>
                                        <Col xl={9}>
                                            <Field required component="input" className="form-control" type="text" name="name" id="name" placeholder={t('tktUsers.name_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="new-password" xl={3}>{t('customers.password')}</Label>
                                        <Col xl={9}>
                                            <Field required={ isCreation } component="input" className="form-control" type="password" name="new-password" id="new-password" autoComplete="new-password" placeholder={t('customers.password_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="new-password-confirmation" xl={3}>{t('customers.password_confirmation')}</Label>
                                        <Col xl={9}>
                                            <Field required={ isCreation } component="input" className="form-control" type="password" name="new-password-confirmation" id="new-password-confirmation" autoComplete="new-password" placeholder={t('customers.password_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="verified" xl={3}>{t('customers.verified')}</Label>
                                        <Col xl={9}>
                                            <ToggleField name="verified" disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="salepoints" xl={3}>{t('customers.salepoint_plural')}</Label>
                                        <Col xl={9}>
                                            <SalepointsField name="salepoints" id="salepoints" salepoints={salepoints} />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <i className="fa fa-address-card"></i>&nbsp;
                                    {t('customers.address')}
                                </CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Label for="address" xl={3}>{t('customers.address')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="text" name="contact.address.street" id="address" autoComplete="address-line1" placeholder={t('customers.address_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="zip" xl={3}>{t('customers.zip')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="text" name="contact.address.zip" id="zip" autoComplete="postal-code" placeholder={t('customers.zip_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="city" xl={3}>{t('customers.city')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="text" name="contact.address.city" id="city" autoComplete="address-level2" placeholder={t('customers.city_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="country" xl={3}>{t('customers.country')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="text" name="contact.address.country" id="country" autoComplete="country-name" placeholder={t('customers.country_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="main">
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <i className="fa fa-envelope"></i>&nbsp;
                                    {t('customers.contact')}
                                </CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Label for="company" xl={3}>{t('customers.company')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="text" name="contact.company" id="company" autoComplete="company" placeholder={t('customers.company_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="title" xl={3}>{t('customers.title')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="text" name="contact.title" id="title" autoComplete="title" placeholder={t('customers.title_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="firstname" xl={3}>{t('customers.firstname')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="text" name="contact.firstname" id="firstname" autoComplete="given-name" placeholder={t('customers.firstname_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="lastname" xl={3}>{t('customers.lastname')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="text" name="contact.lastname" id="lastname" autoComplete="family-name" placeholder={t('customers.lastname_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="email" xl={3}>{t('customers.email')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="email" name="contact.email" autoComplete="email" placeholder={t('customers.email_placeholder')} disabled />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="phone" xl={3}>{t('customers.phone')}</Label>
                                        <Col xl={9}>
                                            <Field component={PhoneField} className="form-control" type="text" name="contact.phone" id="phone" autoComplete="tel-home" placeholder={t('customers.phone_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="cell_phone" xl={3}>{t('customers.cell_phone')}</Label>
                                        <Col xl={9}>
                                            <Field component={PhoneField} className="form-control" type="text" name="contact.cellphone" id="cellphone" autoComplete="tel-mobile" placeholder={t('customers.phone_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    <i className="fa fa-th-list"></i>&nbsp;
                                    {t('customers.other_informations')}
                                </CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Label for="age" xl={3}>{t('customers.age')}</Label>
                                        <Col xl={9}>
                                            <Field component="input" className="form-control" type="number" stepo={1} name="contact.age" id="age" autoComplete="age" placeholder={t('customers.age_placeholder')} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="birthdate" xl={3}>{t('customers.birthdate')}</Label>
                                        <Col xl={9}>
                                            <Field showTime={false} component={renderDateTimePicker} className="form-control" type="text" name="contact.birthdate" id="birthdate" autoComplete="" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="sex" xl={3}>{t('customers.sex')}</Label>
                                        <Col xl={9}>
                                            <SexField name="contact.sex" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="language" xl={3}>{t('customers.language')}</Label>
                                        <Col xl={9}>
                                            <LangsField name="contact.language" />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="main">
                    <Col className="text-center">
                        <ButtonGroup>
                            <Button tag={Link} to="/customers" color="warning">
                                <span><i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}</span>
                            </Button>
                            <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                                <span>
                                    <i className={`fa ${ isLoading ? 'fa-spinner fa-spin' : 'fa fa-save'}`}></i>&nbsp;{t("common.save")}
                                </span>
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Form>
        );
}

const mapDispatchToProps = {}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
CustomerForm = reduxForm({
    form: 'customerForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: true
})(CustomerForm)


CustomerForm = connect(
  state => ({
    initialValues: {
        ...state.customers.customer,
    },
  }), mapDispatchToProps
)(CustomerForm)

export default CustomerForm;
