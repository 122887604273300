import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { loadUsers } from '../../../redux/modules/users';

import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

class ResourceForm extends Component<{}> {
    componentDidMount() {
        this.props.dispatchLoadUsers();
    }

    render() {
        const { isLoading }                             = this.props.resources;
        const { handleSubmit, pristine, submitting, t } = this.props;
        const { users }                                 = this.props.users;

        return (
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("resources.informations")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="number" sm={3}>{t('resources.number')}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className="form-control" type="text" name="number" id="number" placeholder={t('resources.number_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="type" sm={3}>{t('resources.type')}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className="form-control" type="text" name="type" id="type" placeholder={t('resources.type_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="comment" sm={3}>{t('resources.comment')}</Label>
                                            <Col sm={9}>
                                                <Field component="textarea" className="form-control" name="comment" id="comment" placeholder={t('resources.comment_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                        {users &&
                                        <FormGroup row>
                                            <Label for="user_id" sm={3}>{t('resources.user')}</Label>
                                            <Col sm={9}>
                                                <Field component="select" className="form-control" type="text" name="user_id" id="user_id">
                                                    <option key="0" value="">---</option>
                                                    {
                                                        _.sortBy(users, u => u.fullname).map((u) => <option key={u.id} value={u.id}>{u.firstname} {u.lastname}</option>)
                                                    }
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                        }
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="text-center">
                            <br/>
                            <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-save"></i>&nbsp;
                                        {t("common.save")}
                                    </span>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadUsers: () => loadUsers(),
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ResourceForm = reduxForm({
  form: 'resourceForm' // a unique identifier for this form
})(ResourceForm)

// You have to connect() to any reducers that you wish to connect to yourself
ResourceForm = connect(
  state => ({
    initialValues: state.resources.resource, // pull initial values from resources reducer
    resources: state.resources,
    users: state.users,
}), mapDispatchToProps
)(ResourceForm)

export default withTranslation('translations')(ResourceForm)
