import React, { useState } from 'react';
import { Field } from 'redux-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';
import i18n from '../../locales/i18n';
import TranslatableField from '../TranslatableField/TranslatableField';
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import DurationField from '../DurationField/DurationField';
import OverlapField from '../OverlapField/OverlapField';
import { Utils } from '../../services';
import { Divider } from 'semantic-ui-react';
import { Jumbotron } from 'reactstrap';
import classnames from 'classnames';
import HelpTooltips from '../HelpTooltips/HelpTooltips';

import './WindowForm.css';

const WindowForm = ({ window, idx }) => {
    const { t }    = useTranslation();
    const currency = Utils.currency();
    const [activeMode, setMode] = useState('duration');

    const { tickettypes } = useSelector(state => state.tickettypes);

    const toggle = mode => {
        if(mode !== activeMode)
            setMode(mode);
    }

    const predefinedDurations = Utils.durations().map(d => ({
        text: t(`common.duration.${d}`),
        value: d
    }));

    return (
        <div className="WindowForm">
            <Jumbotron>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="screening_id" sm={12}>{t("tickettypes.window.screening_id")}</Label>
                            <Col sm={12}>
                                <Field data-tip data-for='screeningIdTip' component="input" type="text" className="form-control" name={`windows[${idx}].screening_id`} />
                                <HelpTooltips id='screeningIdTip' value='screening_id'/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Divider inverted horizontal>Ou</Divider>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="duration" sm={12}>{t("tickettypes.window.duration")}</Label>
                            <Col sm={12}>
                                <DurationField
                                    name={`windows[${idx}].duration`}
                                    predefinedValues={ predefinedDurations }
                                    onlyParts={['Y', 'M', 'D', 'H', 'X']}
                                    data-tip
                                    data-for='durationTip'
                                />
                                <HelpTooltips id='durationTip' value='duration'/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Divider inverted horizontal>Ou</Divider>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="start_at" sm={12}>{t("tickettypes.window.start_at")}</Label>
                            <Col sm={12}>
                                <Field
                                    component={DateTimePicker}
                                    type="text"
                                    name={`windows[${idx}].start_at`}
                                    timeFormat={true}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col>
                        { tickettypes && (
                        <FormGroup row>
                            <Label for="stop_at" sm={12}>{t("tickettypes.window.stop_at")}</Label>
                            <Col sm={12}>
                                <Field
                                    component={DateTimePicker}
                                    type="text"
                                    name={`windows[${idx}].stop_at`}
                                    timeFormat={true}
                                />
                            </Col>
                        </FormGroup>
                        )}
                    </Col>
                </Row>
            </Jumbotron>
            <Jumbotron>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="nbookings" sm={12}>{t("tickettypes.window.nbookings")}</Label>
                            <Col sm={12}>
                                <Field data-tip data-for='reservationNumTip' component="input" type="text" className="form-control" name={`windows[${idx}].nbookings`} />
                                <HelpTooltips id='reservationNumTip' value='reservation_number'/>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup row>
                            <Label for="nsame_screening" sm={12}>{t("tickettypes.window.nsame_screening")}</Label>
                            <Col sm={12}>
                                <Field data-tip data-for='sameScreeningTip' component="input" type="text" className="form-control" name={`windows[${idx}].nsame_screening`} />
                                <HelpTooltips id='sameScreeningTip' value='same_screening'/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="overlap" sm={12}>{t("tickettypes.window.overlap")}</Label>
                            <Col sm={12}>
                                <OverlapField
                                    name={`windows[${idx}].overlap`}
                                    defaultValue={window.overlap}
                                    placeholder="PT0M"
                                    predefinedValues={ predefinedDurations }
                                    onlyParts={['D', 'H', 'X']}
                                    data-tip
                                    data-for='overlapTip'
                                />
                                <HelpTooltips id='overlapTip' value='overlap'/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="same_day" sm={12}>{t("tickettypes.window.same_day")}</Label>
                            <Col sm={12}>
                                <Field
                                    data-tip
                                    data-for="sameDayTip"
                                    component="input"
                                    type="checkbox"
                                    className="form-control"
                                    name={`windows[${idx}].same_day`}
                                    format={v => { return !!v}}
                                />
                                <HelpTooltips id='sameDayTip' value='same_day'/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Jumbotron>
        </div>
    );
}

export default WindowForm;

