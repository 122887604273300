import store from '..';
import { Printer } from '../../models';
import { Printers, SioBridge } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer,
    getAction, listAction, updateAction
} = createRestSlices(Printers);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [ getReducer, listReducer, updateReducer ]);
}

/* Export CRUD actions */
export const loadPrinter   = getAction;
export const loadPrinters  = listAction;
export const updatePrinter = updateAction;

SioBridge.on('printers_update', (data) => {
    let connectedPrinters = data.map(printer => new Printer(printer));

    const storePrinters = (store.getState().printers.printers || []).map(printer => {
        printer.status = 'disconnected';
        connectedPrinters.forEach(connectedPrinter => {
            if (connectedPrinter._id === printer._id) {
                printer.status = 'connected';
                connectedPrinters = connectedPrinters.filter(connectedPrinter => connectedPrinter._id !== printer._id);
            }
        });

        return printer;
    });
    const printers = storePrinters.concat(connectedPrinters);
    store.dispatch({
        type: 'kronos/printers/LOADING_ITEMS_SUCCESS',
        printers
    });
});
