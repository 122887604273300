import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { drainCoins, drainBills } from '../../../../../../redux';
import Icon from '@mdi/react';
import { mdiLogout } from '@mdi/js';
import { Loader, PlusMinusInput, CollectorSituationResume } from '../../../../../../components';
import _ from 'lodash';

import './DrainButton.css';

const DrainButton = ({ collector }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const [isModalVisible, showModal] = useState(false);
    const toggleModal = useCallback(() => { showModal(!isModalVisible) }, [isModalVisible]);

    const defaultCoinsToDrain = {
        '0.05': 0, '0.10': 0, '0.20': 0, '0.50': 0, '1.00': 0, '2.00': 0, '5.00': 0
    };
    const defaultBillsToDrain = {
        '10.00': 0, '20.00': 0, '50.00': 0, '100.00': 0, '200.00': 0, '1000.00': 0
    };
    const [coinsToDrain, setCoinsToDrain] = useState(defaultCoinsToDrain);
    const [billsToDrain, setBillsToDrain] = useState(defaultBillsToDrain);
    const [mustDoDrain, setMustDoDrain] = useState(false);

    useEffect(() => {
        if (!collector.isIdle())
            return;

        if (!mustDoDrain)
            return;

        const coin = _.find(_.keys(coinsToDrain), coin => coinsToDrain[coin] > 0);
        const bill = _.find(_.keys(billsToDrain), bill => billsToDrain[bill] > 0);

        if (coin) {
            dispatch(drainCoins(collector.id, parseFloat(coin), coinsToDrain[coin]));
            setTimeout(() => {
                setCoinsToDrain({
                    ...coinsToDrain,
                    [coin]: 0
                });
            }, 500);
        } else if (bill) {
            dispatch(drainBills(collector.id, parseFloat(bill), billsToDrain[bill]));
            setTimeout(() => {
                setBillsToDrain({
                    ...billsToDrain,
                    [bill]: 0
                });
            }, 500);
        } else {
            toggleModal();
            setMustDoDrain(false);
        }

    }, [dispatch, toggleModal, collector, coinsToDrain, billsToDrain, mustDoDrain]);

    return (
        <div className="DrainButton">
            <Button onClick={ toggleModal } className="operation" color="warning" block>
                <div className="icon">
                    <Icon path={ mdiLogout } size={ 3 } color="#FFFFFF" />{ ' ' }
                </div>
                <div className="title">
                    { t('collectors.drain') }
                    <div className="subtitle">{ t('collectors.drain_desc') }</div>
                </div>
            </Button>
            <Form className="operation-form drain-form">
                <Modal isOpen={isModalVisible} toggle={toggleModal} size="lg">
                    <ModalHeader toggle={toggleModal}>
                        <Icon path={ mdiLogout } size={ 1 } color="#FFFFFF" />{ ' ' }
                        { t('collectors.drain_desc') }
                    </ModalHeader>
                    <ModalBody>
                        { collector.isLoading() && (
                            <Loader />
                        )}
                        { !collector.isLoading() && (
                        <div className="form-body">
                            <CollectorSituationResume
                                collector={ collector }
                                className="in-modal"
                                hideCharts
                            />
                            <br/>
                            <h4>{ t('collectors.coins') }</h4>
                            <Row>
                                {_.keys(coinsToDrain).map(coin => (
                                    <Col key={ coin } sm="3">
                                        <FormGroup key={ coin }>
                                            <PlusMinusInput
                                                title={ `${coin} CHF` }
                                                min={ 0 }
                                                defaultValue={ 0 }
                                                onChange={(nb) => {
                                                    setCoinsToDrain({
                                                        ...coinsToDrain,
                                                        [coin] : parseInt(nb)
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                ))}
                            </Row>
                            <h4>{ t('collectors.bills') }</h4>
                            <Row>
                                {_.keys(billsToDrain).map(bill => (
                                    <Col key={ bill } sm="3">
                                        <FormGroup className="form-group" key={ bill }>
                                            <PlusMinusInput
                                                title={ `${bill} CHF` }
                                                min={ 0 }
                                                defaultValue={ 0 }
                                                onChange={(nb) => {
                                                    setBillsToDrain({
                                                        ...billsToDrain,
                                                        [bill] : parseInt(nb)
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={ () => { setMustDoDrain(true) }}>
                            <Icon path={ mdiLogout } size={ 1 } color="#FFFFFF" />{ ' ' }
                            { t('collectors.do_drain') }
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>{ t('common.close') }</Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </div>
    );
}

export default DrainButton;
