import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import { Filters } from '../../../services/';
import { loadProgrammations } from '../../../redux/modules/programmations';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import Table from '../../../components/Table/Table';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';
import { getProgrammationsLinkedResources } from '../../../redux';

import './ProgrammationsList.css';

const ProgrammationsList = ({ events_ids }) => {
    const dispatch     = useDispatch();
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    const {
        programmations,
        linkedResources,
    } = useSelector(state => state.programmations);

    const places = linkedResources?.places ?? [];

    useEffect(() => {
        dispatch(getProgrammationsLinkedResources(['places']));
        dispatch(loadProgrammations(events_ids || []));
    }, [])

    if (!programmations || !places)
        return null;

    let events = [];
    programmations && programmations.map((p) => {
        events = events.concat(p.events);
    });

    events = _.uniqBy(events, 'id');
    let events_groups = [];
    if (events && events.length) {
        events_groups = _.uniq(events.map((e) => e.type)).map((t) => {
            return {
                label: Utils.event_types(t).label,
                events: _.sortBy(events.filter((e) => e.type === t), `title.${default_lang}`)
            };
        });
    }

    let columns = [];
    if (!events_ids)
        columns.push({
            Header: t("programmations.events"),
            accessor: "events",
            filterMethod: Filters.eventsFilterMethod,
            Filter: Filters.eventsFilter(events_groups),
            filterAll: true,
            Cell: row => (
                <div className="events_cell">
                    <Link to={`/programmations/edit/${row.original.id}`}>
                        <Card>
                            <CardBody style={{
                                "backgroundImage": "url('" + (
                                    row.value && row.value.length &&
                                        row.value[0].posters && row.value[0].posters.length ?
                                        row.value[0].posters[0].url : ""
                                ) + "')",
                                "backgroundPosition": "center center",
                                "backgroundSize": "cover"
                            }}>
                                <div className="title-wrapper">
                                    <CardTitle>{row.original.title[default_lang]}</CardTitle>
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </div>
            )
        });

    columns = columns.concat([{
        Header: t("programmations.days"),
        accessor: "days",
        Cell: row => {
            if (row && row.value.length === 7)
                return <span className="day">{t('programmations.all_days')}</span>
            else
                return row.value.map((d) =>
                    <span key={d} className="day">
                        {t('common.' + Utils.days(d).label)}
                    </span>
                )
        },
        filterMethod: Filters.daysFilterMethod,
        Filter: Filters.daysFilter,
        filterAll: true
    }, {
        filterAll: true,
        Header: t("programmations.hours"),
        accessor: "times",
        filterMethod: Filters.textFilterMethod('times'),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => {
            return row.value.map((h) =>
                <span key={h} className="hour">
                    {h}
                </span>
            )
        }
    }, {
        Header: t("programmations.start_at") + ' / ' + t("programmations.stop_at"),
        accessor: "start_at",
        Filter: Filters.dateFilter(true),
        filterMethod: Filters.sameDayDateFilterMethod("start_at"),
        filterAll: true,
        Cell: row => (
            <div className="dates">
                <span className="date">
                    <i className="fa fa-calendar"></i>&nbsp;
                    {moment(row.value).format('LL')}
                </span>
                <span className="date">
                    <i className="fa fa-calendar"></i>&nbsp;
                    {moment(row.original.stop_at).format('LL')}
                </span>
            </div>
        )
    }, {
        Header: t("places.place"),
        accessor: "place_id",
        filterMethod: Filters.textFilterMethod("place_id"),
        Filter: Filters.placesFilter(places),
        filterAll: true,
        Cell: row => row.value && places && places.length > 0 && (
            <span>
                {places.find((p) => p._id === row.value) && places.find((p) => p._id === row.value).name}
            </span>
        )
    }, {
        Header: t("programmations.screenings"),
        accessor: "screenings",
        filterable: false,
        className: "text-center",
        Cell: row => {
            if (row.original.nb_screenings > 0)
                return (
                    <Button tag={Link} to={`/programmations/show/${row.original.id}/oldscreenings`} color="info" title={t("common.edit")} size="sm">
                        <i className="fa fa-eye"></i>
                        &nbsp;{t("programmations.see_x_screenings", { nb: row.original.nb_screenings })}
                    </Button>
                );
            else
                return (
                    <Button tag={Link} to={"/programmations/edit/" + row.original.id} color="warning" title={t("common.edit")} size="sm">
                        <i className="fa fa-warning"></i>
                    </Button>
                );
        }
    }, {
        Header: t("common.actions"),
        accessor: "id",
        maxWidth: 110,
        className: "text-center",
        filterable: false,
        Cell: row => {
            return <TableButtons
                id={row.value}
                actions={["edit", "delete"]}
                module="programmations"
                owner={row.original.created_by}
            />
        }
    }]);

    const actions =
        <CreateButton
            module="programmations"
            text="programmations.add"
            customPath={`/programmations/new/${events_ids || ""}`}
        />

    const title =
        <span>{t("programmations.programmation_plural")}</span>

    return (
        <div className="ProgrammationsList">
            <PageTitle icon="calendar-plus-o" title={title} actions={actions} />
            <Table id="programmationsTable" data={programmations} columnsSortOrder={['start_at', 'days']} columns={columns}/>
        </div>
    );
}

const mapStateToProps = state => ({
    programmations: state.programmations,
    places: state.places
});

export default ProgrammationsList;
