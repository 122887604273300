import React from "react";
import PdfLayout from '../PdfLayout';
import PdfLayoutWidget from './PdfLayoutWidget';
import { Screen } from '../../services';

import qrImage from '../../assets/images//qrImage.png';

/** Class representing a PDF layout image widget. */
class QrcodeWidget extends PdfLayoutWidget {

    constructor(properties) {
        super({
            ...(QrcodeWidget.getDefaultOptions()),
            ...properties,
            type: PdfLayout.IMAGE,
            subType: PdfLayout.QRCODE
        });
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 1.8, height: 1.8 },
            transform: { rotation: 0 },
            options: {
                style: {
                    objectFit: "contain",
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return [
            PdfLayout.OPTION_X,
            PdfLayout.OPTION_Y,
            PdfLayout.OPTION_WIDTH,
            PdfLayout.OPTION_HEIGHT,
        ];
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        const { inEdition } = props;
        return (
            <>
                <img ref={ props.ref } src={ qrImage } style={{
                    width: Screen.cmToPx(this.dimensions?.width) - (inEdition ? 2 : 0),
                    height: Screen.cmToPx(this.dimensions?.height) - (inEdition ? 2 : 0),
                }} />
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                }} />
            </>
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'qrcode';
    }

    /**
     * Is this widget resizable ?
     **/
    isResizable = () => true;
}

export default QrcodeWidget;
