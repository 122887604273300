import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText, Badge, Label } from 'reactstrap';
import { Link, useParams } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import { loadTktUser } from '../../../redux/modules/tktusers';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import i18n from '../../../locales/i18n';

import './TicketackUserShow.css';
import TableButtons from '../../../components/TableButtons';

const TicketackUserShow = () => {
    const { t }                                         = useTranslation();
    const dispatch                                      = useDispatch();
    const { userId }                                    = useParams();
    const { tktuser }                                   = useSelector(state => state.tktusers);
    const { salepoints }                                = useSelector(state => state.salepoints);
    const [salepointsProcessed, setSalepointsProcessed] = useState();

    useEffect(() => {
        if (userId) {
            dispatch(loadTktUser(userId))
            dispatch(loadSalepoints());
        }
    }, [userId])

    useEffect(() => {
        if (salepoints && salepoints.length > 0) {
            let temp = salepoints.map(salepoint => {
                return {
                    name: salepoint.name[i18n.language],
                    id: salepoint._id
                }
            })
            setSalepointsProcessed(temp)
        }
    }, [salepoints])

    const prepareSalepoints = (salepoints) => {
        let finalSalePoints = [];
        {
            salepoints.map(salepoint => {
                salepointsProcessed.map(salepointProcessed => {
                    if (salepoint === salepointProcessed.id)
                        finalSalePoints.push(salepointProcessed.name);
                });
            });
            return finalSalePoints
        }
    };

    const title = tktuser?.name &&
        <span>{tktuser.name}</span>

    const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/ticketack_users" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
                {userId &&
                    <TableButtons
                        actions={["edit"]}
                        module={'ticketack_users'}
                    />
                }
            </ButtonGroup>
        </div>

    const prepareRolesAndSalespoints = (roles, color) => {
        let prepared = roles.map((role, index) => {
            return <span key={index}><Badge style={{ margin: "3px" }} color={color} pill >{role}</Badge>&nbsp;</span>
        })
        return prepared
    }

    return (
        <div className="TicketackUserShow">
            <PageTitle icon="users" title={title} actions={actions} />
            {tktuser &&
                <>
                    <Row>
                        <Col lg={6} md={12} className="mb-3">
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <i className="fa fa-user"></i>&nbsp;
                                        {t('users.profile')}
                                    </CardTitle>
                                    <CardText tag="div">
                                        <Row>
                                            <Col sm={12}>
                                                <Row>
                                                    <Col>
                                                        <Label className="showLabel"><b>{t('tktUsers.name') + " :"}</b></Label>
                                                        {tktuser?.name &&
                                                            <Label>{tktuser.name}</Label>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label className="showLabel"><b>{t('user_data.address') + " :"}</b></Label>
                                                        {tktuser?.contact?.opaque?.address &&
                                                            <Label>{tktuser.contact.opaque.address}</Label>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label className="showLabel"><b>{t('user_data.email') + " :"}</b></Label>
                                                        {tktuser?.contact?.opaque?.mail &&
                                                            <Label>{tktuser.contact.opaque.mail}</Label>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label className="showLabel"><b>{t('tickettypes.available_fields.phone') + " :"}</b></Label>
                                                        {tktuser?.contact?.opaque?.phone &&
                                                            <Label>{tktuser.contact.opaque.phone}</Label>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6} md={12}>
                            <Row>
                                <Col sm={12} className="mb-3">
                                    <Card>
                                        <CardBody>
                                            <CardTitle>
                                                <i className="fa fa-tags"></i>&nbsp;
                                                {t('tktUsers.role_plural')}
                                            </CardTitle>
                                            {tktuser.roles &&
                                                <CardText tag="div">
                                                    {prepareRolesAndSalespoints(tktuser.roles, "warning")}
                                                </CardText>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>
                                                <i className="fa fa-tags"></i>&nbsp;
                                                {t('tktUsers.salepoint_plural')}
                                            </CardTitle>
                                            {(tktuser?.salepoints && salepointsProcessed) &&
                                                <CardText tag="div">
                                                    {prepareRolesAndSalespoints(prepareSalepoints(tktuser.salepoints), "success")}
                                                </CardText>}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>}
        </div>
    );
}

export default TicketackUserShow
