import BaseModel from './BaseModel';
import _ from 'lodash';

/** Class representing a ticket window. */
class Window extends BaseModel {
    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = _.cloneDeep(this);

        ['screening_id', 'duration', 'start_at', 'stop_at', 'nsame_screening', 'overlap'].map(property => {
            const value = prepared[property];
            if (_.isNull(value) || value === "")
                delete prepared[property];
        });

        if (!prepared.screening_id && !prepared.duration && !prepared.start_at && !prepared.stop_at)
            prepared.duration = 'P1Y';

        ['nbookings', 'nsame_screening'].map(property => {
            prepared[property] = parseInt(prepared[property]);
        });
        if (!_.isNumber(prepared.nbookings) || _.isNaN(prepared.nbookings))
            prepared.nbookings = 0;
        if (!_.isNumber(prepared.nsame_screening) || _.isNaN(prepared.nsame_screening))
            prepared.nsame_screening = 1;

        return prepared;
    }
}

export default Window;
