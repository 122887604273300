import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button } from 'reactstrap';
import { submit } from 'redux-form';
import { withTranslation } from 'react-i18next';
import i18n from '../../../../locales/i18n';
import ArticleVariant from './ArticleVariant/ArticleVariant';
import {
    addArticleVariant,
    removeArticleVariant,
    duplicateArticleVariant
} from '../../../../redux/modules/articles';

import './ArticleVariants.css';

const ArticleVariants = (props) => {
    const dispatch = useDispatch();
    const { isCreation, stock_type, variants } = props;
    const { tickettypes } = useSelector(state => state.tickettypes);
    const { meta: { form } } = props;

    const add = () => {
        dispatch(addArticleVariant());
        setTimeout(() => {
            dispatch(submit(form));
        }, 100);
    }

    const remove = (_id) => {
        dispatch(removeArticleVariant(_id));
        setTimeout(() => {
            dispatch(submit(form));
        }, 100);
    }

    const duplicate = (_id) => {
        dispatch(duplicateArticleVariant(_id));
        setTimeout(() => {
            dispatch(submit(form));
        }, 100);
    }

    return (
        <div className="ArticleVariants">
            <Row>
                {variants.map((variant, index) => (
                    <ArticleVariant
                        key={index}
                        variant={variant}
                        index={index}
                        tickettypes={tickettypes}
                        stock_type={stock_type}
                        isCreation={isCreation}
                        onRemove={remove}
                        onDuplicate={duplicate}
                    />
                ))}
            </Row>
            {!isCreation && (
                <Row>
                    <Col className="text-center">
                        <br />
                        <ButtonGroup>
                            <Button color="info" size="sm" onClick={() => { add(); }}>
                                <i className="fa fa-plus"></i>&nbsp;{i18n.t("articles.add_variant")}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            )}
        </div>
    );
}


export default withTranslation("translations")(ArticleVariants);
