import BaseModel from './BaseModel';
import { ActivityReward } from '../models';

/** Class representing an activity. */
class Activity extends BaseModel {
    constructor(properties) {
        super(properties);

        if (this.reward)
            this.reward = new ActivityReward(this.reward);
    }
}

export default Activity;
