import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import { debit, drainCoins, drainBills } from '../../../../../../redux';
import Icon from '@mdi/react';
import { mdiCashMultiple } from '@mdi/js';
import { Collector } from '../../../../../../models';
import { Loader, PlusMinusInput, CollectorSituationResume } from '../../../../../../components';
import _ from 'lodash';

import './ChangeButton.css';

const ChangeButton = ({ collector }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const [isFormVisible, showForm]   = useState(false);
    const [isModalVisible, showModal] = useState(false);
    const toggleModal = useCallback(() => { showModal(!isModalVisible) }, [isModalVisible]);

    const [amount, setAmount] = useState(0);

    const defaultCoinsToChange = {
        '0.05': 0, '0.10': 0, '0.20': 0, '0.50': 0, '1.00': 0, '2.00': 0, '5.00': 0
    };
    const defaultBillsToChange = {
        '10.00': 0, '20.00': 0, '50.00': 0, '100.00': 0, '200.00': 0, '1000.00': 0
    };
    const [coinsToChange, setCoinsToChange] = useState(defaultCoinsToChange);
    const [billsToChange, setBillsToChange] = useState(defaultBillsToChange);
    const [mustDoChange, setMustDoChange]   = useState(false);

    const initChange = () => {
        dispatch(debit(collector.id, amount));
        //dispatch(startChange(collector.id, amount));
        toggleModal();
    }

    useEffect(() => {
        if (!collector.isIdle())
            return;

        if (!mustDoChange)
            return;

        const coin = _.find(_.keys(coinsToChange), coin => coinsToChange[coin] > 0);
        const bill = _.find(_.keys(billsToChange), bill => billsToChange[bill] > 0);

        if (coin) {
            dispatch(drainCoins(collector.id, parseFloat(coin), coinsToChange[coin]));
            setTimeout(() => {
                setCoinsToChange({
                    ...coinsToChange,
                    [coin]: 0
                });
            }, 500);
        } else if (bill) {
            dispatch(drainBills(collector.id, parseFloat(bill), billsToChange[bill]));
            setTimeout(() => {
                setBillsToChange({
                    ...billsToChange,
                    [bill]: 0
                });
            }, 500);
        } else {
            toggleModal();
            setMustDoChange(false);
            //dispatch(finishChange(collector.id));
        }

    }, [dispatch, toggleModal, collector, coinsToChange, billsToChange, mustDoChange]);

    return (
        <div className="ChangeButton">
            <Button onClick={ () => { showForm(!isFormVisible) }} className="operation" color="dark" block>
                <div className="icon">
                    <Icon path={ mdiCashMultiple } size={ 3 } color="#FFFFFF" />{ ' ' }
                </div>
                <div className="title">
                    { t('collectors.change') }
                    <div className="subtitle">{ t('collectors.change_desc') }</div>
                </div>
            </Button>

            { isFormVisible && (
            <Form className="operation-form">
                <div className="form-body">
                    <FormGroup>
                        <Input
                            type="decimal"
                            placeholder={ t('collectors.debit_amount_label_placeholder') }
                            onChange={(e) => { setAmount(e.target.value); }}
                            value={ amount }
                        />
                    </FormGroup>
                </div>
                <div className="form-footer">
                    <Button
                        color="info"
                        onClick={ initChange }
                    >
                        <Icon path={ mdiCashMultiple } size={ 1 } color="#FFFFFF" />{ ' ' }
                        { t('collectors.change') }
                    </Button>
                </div>
            </Form>
            )}

            <Form className="operation-form change-form">
                <Modal isOpen={isModalVisible} toggle={toggleModal} size="lg">
                    <ModalHeader toggle={toggleModal}>
                        <Icon path={ mdiCashMultiple } size={ 1 } color="#FFFFFF" />{ ' ' }
                        { t('collectors.change_desc') }
                    </ModalHeader>
                    <ModalBody>
                        { collector.isLoading() && (
                            <Loader />
                        )}
                        { !collector.isLoading() && (
                        <div className="form-body">
                            <CollectorSituationResume
                                collector={ collector }
                                className="in-modal"
                            />
                            <br/>
                            <h4>{ t('collectors.coins') }</h4>
                            <Row>
                                {_.keys(coinsToChange).map(coin => (
                                    <Col key={ coin } sm="3">
                                        <FormGroup key={ coin }>
                                            <PlusMinusInput
                                                title={ `${coin} CHF` }
                                                min={ 0 }
                                                defaultValue={ 0 }
                                                onChange={(nb) => {
                                                    setCoinsToChange({
                                                        ...coinsToChange,
                                                        [coin] : parseInt(nb)
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                ))}
                            </Row>
                            <h4>{ t('collectors.bills') }</h4>
                            <Row>
                                {_.keys(billsToChange).map(bill => (
                                    <Col key={ bill } sm="3">
                                        <FormGroup className="form-group" key={ bill }>
                                            <PlusMinusInput
                                                title={ `${bill} CHF` }
                                                min={ 0 }
                                                defaultValue={ 0 }
                                                onChange={(nb) => {
                                                    setBillsToChange({
                                                        ...billsToChange,
                                                        [bill] : parseInt(nb)
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={ () => {
                            setMustDoChange(true);
                        }}>
                            <Icon path={ mdiCashMultiple } size={ 1 } color="#FFFFFF" />{ ' ' }
                            { t('collectors.do_change') }
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>{ t('common.close') }</Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </div>
    );
}

export default ChangeButton;
