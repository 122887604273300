import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './CategoriesField.css';

const CategoriesField = ({ name, categories, placeholder, multi }) => {
    const { t } = useTranslation();

    if (!categories || categories.length == 0)
        return null;

    const tree = Utils.sortAndIndentCats(categories);

    const idField = ('id' in categories[0]) ? 'id' : '_id';

    const options = tree.map(category => {
        return {
            key: category[idField],
            text: Utils.localized_or_fallback(category.name, i18n.language),
            value: category[idField]
        };
    });

    return multi ? (
        <MultiSelectField
            name={ name }
            options={ options }
            placeholder={ placeholder || t('articlecategories.choose_a_category') }
            clearable
        />
    ) : (
        <SelectField
            name={ name }
            options={ options }
            placeholder={ placeholder || t('articlecategories.choose_a_category') }
            clearable
        />
    );
}

export default CategoriesField;
