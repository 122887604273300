import RestClient from './RestClient';
import { Pricinglist } from '../../models';

class Pricinglists extends RestClient {
    constructor() {
        super({
            "model": Pricinglist,
            "entryPoint": "pricinglist",
            "resource": "pricinglist",
            "resources": "pricinglists",
            "id_field": "_id",
            "sortBy": "name"
        });
    }
}

const PricinglistsApi = new Pricinglists();

export default PricinglistsApi;
