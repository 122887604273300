import RestClient from './RestClient';
import SioBridge from '../SioBridge';
import { Collector, CollectorStatus, CollectorStats } from '../../models';
import _ from 'lodash';

class Collectors extends RestClient {
    constructor() {
        super({
            model: Collector,
            entryPoint: 'collectors',
            resource:   'collector',
            resources:  'collectors'
        });
    }

    list() {
        return SioBridge.sendMessage('collectors')
            .then(collectors => {
                if (Array.isArray(collectors)) {
                    collectors = collectors.map(c => new Collector(c));

                    if (this.sortBy)
                        collectors = _.sortBy(collectors, this.sortBy);

                    return { collectors };
                }

                throw new Error();
            });
    }

    debit(id, amount) {
        return SioBridge.sendMessage('_debit_on_collector', { id, amount })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    refund(id, amount) {
        return this.debit(id, -1 * amount);
    }

    startChange(id, amount) {
        return SioBridge.sendMessage('start_change_on_collector', { id, amount })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    addChangeCoinsConfiguration(id, coin, quantity) {
        return SioBridge.sendMessage('add_coins_change_configuration_on_collector', { id, coin, quantity })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    addChangeBillsConfiguration(id, bill, quantity) {
        return SioBridge.sendMessage('drain_bills', { id, bill, quantity })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    finishChange(id) {
        return SioBridge.sendMessage('finish_change_on_collector', { id })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    autotest(id) {
        return SioBridge.sendMessage('autotest', { id })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    drainCoins(id, coin, quantity) {
        return SioBridge.sendMessage('drain_coins', { id, coin, quantity })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    drainBills(id, bill, quantity) {
        return SioBridge.sendMessage('drain_bills', { id, bill, quantity })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    resetChest(id) {
        return SioBridge.sendMessage('reset_chest', { id })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    unloadCoins(id, coin, quantity) {
        return SioBridge.sendMessage('unload_coins', { id, coin, quantity })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    unloadBills(id, bill, quantity) {
        return SioBridge.sendMessage('unload_bills', { id, bill, quantity })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    fillin(id) {
        return SioBridge.sendMessage('fillin_collector', { id })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    stopFillin(id) {
        return SioBridge.sendMessage('stop_fillin_collector', { id })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    get(id) {
        return SioBridge.sendMessage('collector', { id })
            .then(collector => {
                if (collector) {
                    collector = new Collector(collector);

                    return { collector };
                }

                throw new Error();
            });
    }

    getStatus(id) {
        return SioBridge.sendMessage('collector_status', { id })
            .then(status => {
                if (status) {
                    status = new CollectorStatus(status);

                    return { status };
                }

                throw new Error();
            });
    }

    getStats(id) {
        return SioBridge.sendMessage('collector_stats', { id })
            .then(stats => {
                if (stats) {
                    stats = new CollectorStats(stats);

                    return { stats };
                }

                throw new Error();
            });
    }
}

const CollectorsApi = new Collectors();

export default CollectorsApi;
