import BaseModel from './BaseModel';
import { FormLayout } from '../models';
import moment from 'moment';

/** Class representing a user availability. */
class Value extends BaseModel {

    constructor(properties) {
        super(properties);

        if (this.field)
            this.field = FormLayout.createField(this.field);
    }

    /**
     * Render this value data
     *
     * @param {Object} context: an object with the following keys:
     *                   - lang
     */
    renderData(context) {
        context = context || {};

        if (!this.data || !('value' in this.data))
            return null;

        return this.field.renderValue(this.data.value, context);
    }
}

export default Value;
