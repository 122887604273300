import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect } from "react-router-dom";
import ProgrammationForm from '../ProgrammationForm/ProgrammationForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { startCreateProgrammation, createProgrammation, getNoAbilityInfos } from '../../../redux/modules/programmations';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Utils } from '../../../services';
import i18n from '../../../locales/i18n';

const ProgrammationCreate = ({}) => {
    const [ initialized, setInitialized] = useState(false);

    const {
        isLoading,
        programmationSuccessfullyCreated,
        programmation
    }                    = useSelector(state => state.programmations);
    const events         = useSelector(state => state.programmations.linkedResources.events);
    const bucketlists    = useSelector(state => state.programmations.linkedResources.bucketlists);
    const dispatch       = useDispatch();
    const { t }          = useTranslation();
    let   { events_ids } = useParams();

    useEffect(() => {
        if (!initialized && bucketlists) {
            setInitialized(true);
            dispatch(startCreateProgrammation(bucketlists));
        }
    }, [bucketlists])

    const submit = (values) => {
        if (!values?.events?.length && !values.new_event) {
            if (events_ids) {
                events_ids = events_ids.split(',');
                if (events_ids.length)
                    values.new_event = events_ids[0];
            }
        }
        dispatch(createProgrammation(values));
    }

    const goBack = () => {
        window.history.back();
    }

    let title =
        <span>
            {t('programmations.add')}
            {(events && events.length > 0) ? " - " + Utils.localized_or_fallback(events?.find(e => e.id === events_ids)?.title, i18n.language) : ""}
        </span>;

    const actions =
        <Button onClick={goBack} color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="ProgrammationCreate">
            <PageTitle icon="th" title={ title } actions={ actions } />

            {programmation && programmationSuccessfullyCreated &&
                <Redirect to={`/programmations/edit/${programmation.id}`} />
            }

            <ProgrammationForm onSubmit={submit} isCreation="true" events_ids={events_ids} />
        </div>
    );
}

export default ProgrammationCreate;
