import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import SectionsList from './SectionsList/SectionsList';
import SectionShow from './SectionShow/SectionShow';
import SectionEdit from './SectionEdit/SectionEdit';
import SectionCreate from './SectionCreate/SectionCreate';
import SectionDelete from './SectionDelete/SectionDelete';

class Sections extends Component<{}> {
    render() {
        const { match } = this.props;
        const { hasLoadingError, loadingErrorMessage } = this.props.sections;
        return (
            <div className="Sections">

                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={SectionsList} />
                <Route exact path={ `${ match.url }/new` } component={SectionCreate} />
                <Route exact path={ `${ match.url }/show/:sectionId` } component={SectionShow} />
                <Route exact path={ `${ match.url }/edit/:sectionId` } component={SectionEdit} />
                <Route exact path={ `${ match.url }/delete/:sectionId` } component={SectionDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    sections: state.sections
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sections));
