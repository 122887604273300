import BaseModel from './BaseModel';

/** Class representing a salepoint. */
class Salepoint extends BaseModel {
    constructor(properties) {
        super(properties);

        this.delivery       = this.delivery || {};
        this.delivery.types = this.delivery.types || [];
    }
}

export default Salepoint;
