import RestClient from './RestClient';
import SioBridge from '../SioBridge';
import { Printer } from '../../models';
import _ from 'lodash';

class Printers extends RestClient {
    constructor() {
        super({
            model: Printer,
            entryPoint: 'printers',
            resource:   'printer',
            resources:  'printers'
        });
    }

    list() {
        // by sending all_efts instead of efts, we are
        // asking even the disconnected printers
        return SioBridge.sendMessage('all_printers')
            .then(printers => {
                if (Array.isArray(printers)) {
                    printers = printers.map(c => new Printer(c));

                    if (this.sortBy)
                        printers = _.sortBy(printers, this.sortBy);

                    return { printers };
                }

                throw new Error();
            });
    }

    get(id) {
        return SioBridge.sendMessage('printer', { id })
            .then(printer => {
                if (printer) {
                    printer = new Printer(printer);

                    return { printer };
                }

                throw new Error();
            }).catch(error => {
                throw new Error(error);
            });
    }

    update(printer) {
        const { _id, options } = printer;
        return SioBridge.sendMessage('save_printer_options', { _id, options })
            .then(printer => {
                if (printer) {
                    printer = new Printer(printer);

                    return { printer };
                }

                throw new Error();
            }).catch(error => {
                throw new Error(error);
            });
    }
}

const PrintersApi = new Printers();

export default PrintersApi;
