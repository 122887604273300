/**
 * Helper class to get informations about the user device screen
 */
class Screen {
    /**
     * User device screen PPI value
     */
    static ppi = 0;

    /**
     * Scale
     */
    static scale = 1;

    /**
     * Set the scale
     */
    static setScale(scale) {
        Screen.scale = scale;
    }

    /**
     * Get the PPI
     *
     * @see https://bitcoden.com/answers/get-device-ppi-in-javascript
     */
    static getPpi() {
        if (Screen.ppi === 0) {
            const el = document.createElement('div');
            el.style = 'width: 1in;'
            document.body.appendChild(el);

            Screen.ppi = el.offsetWidth;
            document.body.removeChild(el);
        }

        return Screen.ppi * Screen.scale;
    }

    /**
     * Convert centimeters to inches
     */
    static cmToInches = cm => 0.393701 * cm;

    /**
     * Convert inchers to centimeters
     */
    static inchesToCm = inches => 1 / 0.393701 * inches;

    /**
     * Convert centimeters on screen to number of pixels
     */
    static cmToPx = cm => {
        const inches = Screen.cmToInches(cm);
        const ppi = Screen.getPpi();

        return ppi * inches;
    }

    /**
     * Convert number of pixels to centimeters on screen
     */
    static pxToCm = px => {
        const ppi    = Screen.getPpi();
        const inches = px / ppi;

        return Math.round(Screen.inchesToCm(inches) * 1000) / 1000;
    }

    /**
     * Convert number of postscript points to centimeters on screen
     */
    static ppToPx = pp => {
        const ppi    = Screen.getPpi();
        const inches = pp / 72;
        const cm     = Screen.inchesToCm(inches);

        return Math.round(Screen.cmToPx(cm) * 1000) / 1000;
    }

}

export default Screen;
