import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services';
import i18n from '../../../locales/i18n';
import { Row, Col, Input, Form, Button } from 'reactstrap';
import moment from 'moment';
import Widget from '../Widget';
import './Clock.css';
import WidgetSharedSettings from '../WidgetSharedSettings/WidgetSharedSettings';

const Clock = ({ props, saveSettings, index, onCancel, insert, name }) => {
    const { t }            = useTranslation();
    const transparentColor = "rgb(0,0,0,0)"
    const baseColor        = "#2A2A2A"

    const [time, setTime] = useState(moment().format('LT'))
    const [date, setDate] = useState(moment().format('LL'))

    useEffect(() => {
        const timer = setInterval(() => tick(), 1000)
        return (() => {
            clearInterval(timer)
        })
    })

    const tick = () => {
        setTime(moment().format('LT'));
        setDate(moment().format('LL'));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let settings = {
            width: e.target.widgetWidth.value
        }
        saveSettings([settings, index, insert, name]);
    }

    return (
        <div className='Clock' >
            {
                (!saveSettings) ?
                    <Widget
                        content={
                            <div className="mainDisplay">
                                <div className="time">{time}</div>
                                <div className="date">{date}</div>
                            </div>
                        }
                    />
                    : // Do your widget settings form here
                    // TODO : Bind settings from state hook to HTML form tags
                    <Form onSubmit={handleSubmit}>
                        <Row className='ml-0 mr-0 pb-2 border-bottom'>
                            <Col className='col-sm-12 col-md-12 col-lg-6'>
                                <Row className='text-center'>
                                    <h4 className='border-bottom pb-1 mb-2 w-100 mr-1'>{t("widgets.settings.parameters")}</h4>
                                </Row>
                            </Col>
                            <Col className='col-sm-12 col-md-12 col-lg-6'>
                                <WidgetSharedSettings 
                                    width={props?.width ? props.width : 1} 
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="submitButtons">
                                    <Button onClick={() => onCancel()} className="mr-2" color="danger"><i className="fa fa-times"></i>&nbsp;{t("widgets.cancel")}</Button>
                                    <Button type="submit" color="success"><i className="fa fa-floppy-o"></i>&nbsp;{t("widgets.save")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
            }
        </div>
    )
}

export default Clock;
