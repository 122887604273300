import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadCashregister, deleteCashregister } from '../../../redux/modules/cashregisters';

class CashregisterDelete extends Component<{}> {
    componentDidMount() {
        const cashregisterId = this.props.match.params.cashregisterId;
        // Load cashregisters on component mount
        this.props.dispatchLoadCashregister(cashregisterId);
    }

    deleteCashregister(cashregisterId) {
        this.props.dispatchDeleteCashregister(cashregisterId);
    }

    render() {
        const { cashregister, isLoading, cashregisterSuccessfullyDeleted } = this.props.cashregisters;
        const { t } = this.props;

        const default_lang = Utils.default_lang();

        let title = <span>{t("bulidings.cashregister_plural")}</span>;
        if (cashregister)
            title = (
                <span>
                    {t("cashregisters.cashregister")}
                    <small> | { cashregister.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/cashregisters" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="CashregisterDelete">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {cashregisterSuccessfullyDeleted &&
                    <Redirect to="/cashregisters"/>
                }

                {cashregister &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('cashregisters.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("cashregisters.name")}</b>: { cashregister.name[default_lang] }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/cashregisters" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteCashregister(cashregister._id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadCashregister: (cashregisterId) => loadCashregister(cashregisterId),
    dispatchDeleteCashregister: (cashregisterId) => deleteCashregister(cashregisterId)
}

const mapStateToProps = state => ({
    cashregisters: state.cashregisters
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(CashregisterDelete))
