// Redux Messages module
// see https://github.com/erikras/ducks-modular-redux

import { Messages } from '../../services/';

const START_CREATING_MESSAGE   = 'kronos/messages/START_CREATING_MESSAGE';
const CREATING_MESSAGE         = 'kronos/messages/CREATING_MESSAGE';
const CREATING_MESSAGE_SUCCESS = 'kronos/messages/CREATING_MESSAGE_SUCCESS';
const CREATING_MESSAGE_FAILURE = 'kronos/messages/CREATING_MESSAGE_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    messageSuccessfullyCreated: false
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case START_CREATING_MESSAGE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                messageSuccessfullyCreated: false,
                // Ensure sane default message for creation
                message: {
                    recipients: [],
                    recipient_groups: [],
                    message: null
                }
            };
        case CREATING_MESSAGE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                messageSuccessfullyCreated: true,
                successMessage: action.message.success,
                // Ensure sane default message after creation
                message: {
                    recipients: [],
                    recipient_groups: [],
                    message: null
                }
            };
        case CREATING_MESSAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                messageSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function creatingMessage() { return { type: CREATING_MESSAGE } }
function creatingMessageSuccess(message) { return { type: CREATING_MESSAGE_SUCCESS, message: message} }
function creatingMessageFailure(err) { return { type: CREATING_MESSAGE_FAILURE, error: err } }
export function startCreateMessage() { return { type: START_CREATING_MESSAGE } }
export function createMessage(message) {
    return (dispatch) => {
        dispatch(creatingMessage());
        Messages.create(message)
            .then(data => {
                dispatch(creatingMessageSuccess(data.flash));
            })
            .catch(err => {
                dispatch(creatingMessageFailure(err))
            });
    }
}
