import React from "react";
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Label,
 } from 'reactstrap';
import { TranslatableField } from '../../../../../components';

const FormLayoutOptions = () => {
    const { t } = useTranslation();

    return (
        <div className="FormLayoutOptions">
            <Row>
                <Col>
                    <Label>{t('forms.form.name')}</Label>
                    <TranslatableField  data-for='forms.name' required component="input" className="form-control" type="text" name="name" placeholder={t("forms.layout.name_placeholder")} />
                </Col>
            </Row>
        </div>
    );
}

export default FormLayoutOptions;
