import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button, Col, Row, Label, Modal, ModalBody, ModalHeader, ModalFooter, Input, FormGroup, FormFeedback } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import i18n from '../../../locales/i18n';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';
import _ from 'lodash';
import { loadTickettypes, updateTickettype, duplicateTickettype } from '../../../redux/modules/tickettypes';
import { ClearableTextInput, Draggable, TranslatableFieldWithInput } from '../../../components';
import matchSorter from 'match-sorter';
import { setSmallDraggableCards } from '../../../redux';
import { Utils } from '../../../services';

import './TickettypesList.css';

const TickettypesList = () => {
    const { t }                                     = useTranslation();
    const dispatch                                  = useDispatch();
    const [sortedTickettypes, setSortedTickettypes] = useState([]);
    const [draggable, setDraggable]                 = useState(true);
    const [modal, setModal]                         = useState(false);
    const [duplicatedId, setDuplicateId]            = useState();
    const [newDuplicatedId, setNewDuplicatedId]     = useState();
    const [idValid, setIdValid]                     = useState(true);
    const [duplicateName, setDuplicateName]         = useState();
    const { tickettypes, duplicatedTickettype }     = useSelector(state => state.tickettypes);

    const { KUserSettings } = useSelector(state => state.userSettings);
    const smallCards        = !!KUserSettings.smallDraggableCards;

    const originalSortedTicketypes = useMemo(() => {
        return _.sortBy(tickettypes, ['opaque.eshop_sort_weight'], ['asc'])
    }, [tickettypes])

    useEffect(() => {
        dispatch(loadTickettypes());
    }, [dispatch, duplicatedTickettype]);

    const setUseSmallCards = useSmallCards => {
        dispatch(setSmallDraggableCards(!!useSmallCards));
    };

    const filterTickettypes = (filters) => {
        if (filters === "") {
            setDraggable(true)
            setSortedTickettypes(originalSortedTicketypes);
        } else {
            setDraggable(false);
            const regex = new RegExp(filters, 'i');
            setSortedTickettypes(
                matchSorter(originalSortedTicketypes, filters, { keys: [item => Object.keys(item.name).map(i => item.name[i])] })
            )
        }
    }

    const toggle = () => {
        setModal(!modal);
    }

    const tktTypeDuplicate = () => {
        setModal(!modal);
        dispatch(duplicateTickettype(
            tickettypes.find(tt => tt._id === duplicatedId),
            {
                _id: newDuplicatedId,
                name: duplicateName
            }
        ));
    }

    const modalDuplicate = (id) => {
        setModal(true);
        setDuplicateId(id);
        setNewDuplicatedId(id + "_copy");
        setDuplicateName(tickettypes.find(tt => tt._id === id).name);
        setIdValid(!tickettypes.find(tt => tt._id === id + "_copy"));
    }

    useEffect(() => {
        setIdValid(!tickettypes.find(tt => tt._id === newDuplicatedId));
    }, [newDuplicatedId])

    useEffect(() => {
        if (tickettypes.length > 0) {
            setSortedTickettypes(originalSortedTicketypes);
        }
    }, [originalSortedTicketypes])

    const buildPreview = tickettypes => {
        const Actions = ({ id }) =>
                (id === 'one-time-pass') ?
                <TableButtons
                    id={id}
                    actions={["edit"]}
                    module="tickettypes"
                />
                :
                <TableButtons
                    id={id}
                    actions={["edit", "duplicate", 'delete']}
                    module="tickettypes"
                    onDuplicate={modalDuplicate}
                />

        const SortableTab = SortableElement(({ tickettype, idx, sortIndex }) => (
            <Draggable
                key={"draggable" + idx}
                className="tickettypeCard"
                id={idx}
                toolTip={Utils.localized_or_fallback(tickettype.name, i18n.language)}
                title={
                    <span className='d-block text-nowrap text-truncate'>
                        {Utils.localized_or_fallback(tickettype.name, i18n.language)}<br />
                        <small><i>{Object.keys(tickettype.pricings).length} {t('pricinglists.pricings_maybe_plural')} / {tickettype.windows.length + ' ' + t('tickettypes.windows_maybe_plural')}</i></small>
                    </span>
                }
                badge={tickettype._id}
                actions={<Actions id={tickettype._id} />}
                draggable={draggable}
                link={"/tickettypes/edit/" + tickettype._id}
            />
        ));

        const SortableGrid = SortableContainer(({ tickettypes }) => {
            return (
                <Row className="sortable-grid mx-3">
                    {(tickettypes || []).map((tickettype, index) => {
                        return <Col key={`tkttype-item-${index}`} sm={!!smallCards ? 6 : 12} lg={!!smallCards ? 3 : 6} className="mb-2">
                            <SortableTab index={index} idx={index} tickettype={tickettype} sortIndex={tickettype.opaque.eshop_sort_weight} />
                        </Col>
                    })}
                </Row>
            );
        });

        const onSortEnd = ({ oldIndex, newIndex }) => {
            if (oldIndex === newIndex)
                return;
            let tickettypes = _.cloneDeep(sortedTickettypes);
            [tickettypes[oldIndex], tickettypes[newIndex]] = [tickettypes[newIndex], tickettypes[oldIndex]];
            tickettypes = tickettypes.map((t, i) => {
                t.opaque.eshop_sort_weight = i;
                return t;
            });
            setSortedTickettypes(tickettypes);
            tickettypes.forEach(t => {
                dispatch(updateTickettype(t));
            });

        };

        return <SortableGrid
            tickettypes={tickettypes}
            axis="xy"
            onSortEnd={onSortEnd}
            useDragHandle={true}
            helperClass="sortableHelper"
            useWindowAsScrollContainer
        />
    };

    const actions =
        <CreateButton
            module={`tickettypes`}
            text="tickettypes.add"
        />

    const title =
        <span>{t("tickettypes.tickettype_plural")}</span>

    return (
        <div className="TickettypesList">
            <PageTitle icon="tags" title={title} actions={actions} />
            <Row className='px-3'>
                <Col sm={3} md={2} lg={1}>
                    <Label><i className='fa fa-filter'></i>&nbsp;{t('statstypes.form.filters')}</Label>
                </Col>
                <Col sm={9} md={4} lg={3} className="mb-2">
                    <ClearableTextInput onChange={filterTickettypes} />
                </Col>
                <Col>
                    <ButtonGroup className="float-right">
                        <Button style={{height: "33.5px"}} color={smallCards ? 'primary' : 'dark'} onClick={() => setUseSmallCards(true)}>
                            <i className='fa fa-th'></i>
                        </Button>
                        <Button style={{height: "33.5px"}} color={!smallCards ? 'primary' : 'dark'} onClick={() => setUseSmallCards(false)}>
                            <i className='fa fa-th-large'></i>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {sortedTickettypes.length > 0 && buildPreview(sortedTickettypes)}
            <Modal isOpen={modal} toggle={toggle} contentClassName="modalCustomClass">
                <ModalHeader toggle={toggle}>{t('tickettypes.new_duplicate_id')}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col md={2}>
                                <Label>{t('tickettypes._id')}</Label>
                            </Col>
                            <Col md={10}>
                                <Input pattern="[a-zA-Z0-9_]" type="text" valid={idValid} invalid={!idValid} value={newDuplicatedId} onChange={(e) => setNewDuplicatedId(e.target.value.replace(/[^a-zA-Z0-9_]/, ""))} />
                            </Col>
                        </Row>
                        <FormFeedback invalid="true" tooltip>{t('tickettypes.id_already_used')}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col md={2}>
                                <Label>{t('tickettypes.name')}</Label>
                            </Col>
                            <Col md={10}>
                                <TranslatableFieldWithInput name={duplicateName} type="text" onChange={ value => setDuplicateName(value) }/>
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color={`${idValid ? "primary" : "warning"}`} disabled={!idValid} onClick={tktTypeDuplicate}>{t('common.duplicate')}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>{t('common.cancel')}</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default TickettypesList;
