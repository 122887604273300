import React, { useEffect } from 'react';
import 'react-table-6/react-table.css';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Sector } from '../../../models';
import { Utils, Filters } from '../../../services';
import { Badge } from 'reactstrap';
import { PageTitle, Table, TableButtons, CreateButton } from '../../../components';

import { loadSectors } from '../../../redux/modules/sectors';

import './SectorsList.css';

const SectorsList = () => {
    const { t, i18n }  = useTranslation();
    const { sectors }  = useSelector(state => state.sectors);
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();

    useEffect(() => {
        dispatch(loadSectors());
    }, [dispatch]);

    const title = <span>{t("sectors.sectors_plural")}</span>;

    const actions = <CreateButton module={`sectors`} text="sectors.add" />;

    const columns = [{
        Header: t('sectors.name'),
        accessor: "name",
        filterMethod: Filters.textFilterMethod("name." + default_lang),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => row.value && (
            <div className="name_cell">
                <Link to={`/sectors/edit/${row.original.id}`}>
                    {row.original.name[default_lang]}
                </Link>
            </div>
        )
    }, {
        Header: t('sectors.admins'),
        accessor: "admins",
        filterMethod: Filters.textFilterMethod("name." + default_lang),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => row.value && row.value.map(user => (
            <Link key={user.id} to={`/users/edit/${user.id}`}>
                <Badge color="info" className="mr-2">
                    <i className="fa fa-user"></i>&nbsp;
                    { user.fullname }
                </Badge>
            </Link>
        ))
    }, {
        Header: t('common.actions'),
        accessor: "id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete"]}
                module="sectors"
            />
        )
    }];

    const data = React.useMemo(() => Sector.buildTree(sectors, i18n.language), [
        sectors,
        i18n.language
    ]);

    return (
        <div className="SectorsList">
            <PageTitle icon="location-arrow" title={title} actions={actions} />
            <Table id="sectorsTable" data={data} columns={columns} />
        </div>
    );
}

export default SectorsList;
