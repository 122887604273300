import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { loadStats } from '../../../redux/modules/stats';
import { useLocation } from 'react-router-dom';
import StatsForm from "../StatsForm/StatsForm"
import StatsMovies from '../StatsMovies/StatsMovies';
import StatsPrices from '../StatsPrices/StatsPrices';
import StatsRooms from '../StatsRooms/StatsRooms';
import StatsDaily from '../StatsDaily/StatsDaily';
import StatsHourly from '../StatsHourly/StatsHourly';
import StatsBoxOffice from '../StatsBoxOffice/StatsBoxOffice';
import StatsCarts from '../StatsCarts/StatsCarts';
import moment from 'moment';

import './StatstypesList.css';

const StatstypeList = () => {
    const dispatch                        = useDispatch();
    const location                        = useLocation();
    const [statsState, setStatsState]     = useState('')
    const [stateFilters, setStateFilters] = useState(null)
    const { isLoading }                   = useSelector(state => state.stats);

    const loadStatsFromServer = (filters) => {
        if (statsState !== "")
            dispatch(loadStats(statsState, getSerializedFilters(filters)));
    };

    const getSerializedFilters = (filters) => {
        return {
            ...filters,
            start_date: (filters?.start_date || moment()).toISOString(),
            end_date: (filters?.end_date || moment()).toISOString()
        };
    }

    const onSubmit = (filtersFromChild) => {
        setStateFilters(filtersFromChild);
        loadStatsFromServer(filtersFromChild);
    }

    useEffect(() => {
        if (stateFilters)
            loadStatsFromServer(stateFilters);
    }, [statsState, stateFilters]);

    useEffect(() => {
        switch (location.pathname) {
            case "/stats/movies":
                setStatsState('movies')
                break;
            case "/stats/prices":
                setStatsState('prices')
                break;
            case "/stats/places":
                setStatsState('places')
                break;
            case "/stats/days":
                setStatsState('days')
                break;
            case "/stats/hours":
                setStatsState('hours')
                break;
            case "/stats/box_office":
                setStatsState('box_office')
                break;
        }
    })

    const serializedFilters = useMemo(() =>
        getSerializedFilters(stateFilters)
    , [stateFilters]);

    return (
        <div className="StatstypesList">
            <StatsForm isLoading={isLoading} onSubmittedForm={onSubmit} />
            {!isLoading && (
                {   //this is a switch, it will show component relative to StatsState
                    'movies'    : <StatsMovies filters={serializedFilters} />,
                    'prices'    : <StatsPrices filters={serializedFilters} />,
                    'places'    : <StatsRooms filters={serializedFilters} />,
                    'days'      : <StatsDaily filters={serializedFilters} />,
                    'hours'     : <StatsHourly filters={serializedFilters} />,
                    'box_office': <StatsBoxOffice filters={serializedFilters} />,
                    'carts'     : <StatsCarts filters={serializedFilters} />
                }[statsState]
            )}
        </div>
    );
}

export default StatstypeList;
