import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import DistributorForm from '../DistributorForm/DistributorForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateDistributor, createDistributor } from '../../../redux/modules/distributors';

class DistributorCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.props.dispatchStartCreateDistributor();
    }

    submit(values) {
        this.props.dispatchCreateDistributor(values);
    }

    render() {
        const { t }                              = this.props;
        const { distributorSuccessfullyCreated } = this.props.distributors;

        let title = <span>{t('distributors.add')}</span>;

        const actions =
            <Button tag={Link} to="/distributors" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="DistributorCreate">
                <PageTitle icon="money" title={ title } actions={ actions } />

                {distributorSuccessfullyCreated &&
                    <Redirect to="/distributors"/>
                }

                <DistributorForm onSubmit={this.submit} isCreation="true" />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateDistributor: (distributor) => startCreateDistributor(distributor),
    dispatchCreateDistributor: (distributor) => createDistributor(distributor)
}

const mapStateToProps = state => ({
    distributors: state.distributors,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(DistributorCreate))
