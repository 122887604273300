import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import SelectField from '../SelectField/SelectField';

import './LangsField.css';

const LangsField = ({ name, multi }) => {
    const { t }   = useTranslation();
    const options = Utils.sorted_standard_languages(/*current*/null).map(l => {
        return {
            key: l.code,
            text: l.language,
            value: l.code
        };
    });

    if (multi)
        return <MultiSelectField name={ name } options={ options } />

    return (
        <SelectField
            name={ name }
            options={ options }
            clearable
        />
    );
}

export default LangsField;
