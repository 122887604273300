import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import CashregistersList from './CashregistersList/CashregistersList';
import CashregisterShow from './CashregisterShow/CashregisterShow';
import CashregisterEdit from './CashregisterEdit/CashregisterEdit';
import CashregisterCreate from './CashregisterCreate/CashregisterCreate';
import CashregisterDelete from './CashregisterDelete/CashregisterDelete';

class Cashregisters extends Component<{}> {
    render() {
        const { match }     = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.cashregisters;
        return (
            <div className="Cashregisters">

                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={CashregistersList} />
                <Route exact path={ `${ match.url }/new` } component={CashregisterCreate} />
                <Route exact path={ `${ match.url }/show/:cashregisterId` } component={CashregisterShow} />
                <Route exact path={ `${ match.url }/edit/:cashregisterId` } component={CashregisterEdit} />
                <Route exact path={ `${ match.url }/delete/:cashregisterId` } component={CashregisterDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    cashregisters: state.cashregisters
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cashregisters));
