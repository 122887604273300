import React from "react";
import FormLayout from '../FormLayout';
import TextField from './TextField';
import { Field } from 'redux-form';
import { FileUpload } from '../../components';
import { Utils } from '../../services';

/** Class representing a PDF layout custom text field. */
class FileField extends TextField {

    constructor(properties) {
        super({
            ...(FileField.getDefaultOptions()),
            ...properties,
        });

        this.type = FormLayout.FILE;
    }

    /**
     * Get this field default options
     */
    static getDefaultOptions() {
        const properties = super.getDefaultOptions();
        Utils.langs().forEach(lang => { properties.label[lang] = "";  });

        properties.extensions = ['pdf', 'txt'];

        return properties;
    }

    /**
     * Return the list of options this field handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            FormLayout.OPTION_EXTENSIONS
        ]);
    }

    /**
     * Return this field preview input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getInput(props) {
        return (
            <Field
                component={ FileUpload }
                type="file"
                name={this.id}
                required={!!this.required}
                accept={ this.extensions.map(e => '.' + e).join(',') }
                className="form-control"
            />
        );
    }

    /**
     * Render a value
     *
     * @param {Any} value
     * @param {Object} context: an object with the following keys:
     *                   - lang (mandatory)
     *                   - t (mandatory)
     *                   - format (optional)
     *
     * @return {String}
     */
    renderValue(value, context) {
        return 'NOT IMPLEMENTED YET';
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'upload';
    }
}

export default FileField;
