import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody } from 'reactstrap';
import { FormGroup, Label, Jumbotron } from 'reactstrap';
import { Field } from 'redux-form'
import { withTranslation } from 'react-i18next';
import SalepointsField from '../../../../components/SalepointsField/SalepointsField';

import { addArticleVariantStock, addArticleStock, removeArticleVariantStock, removeArticleStock } from '../../../../redux/modules/articles';

//import './ArticleStocks.css';

class ArticleStocks extends Component<{}> {
    add() {
        const { variant_index, stock_type } = this.props;

        if (stock_type === 'variant') {
            this.props.dispatchAddArticleVariantStock(variant_index);
        } else if (stock_type === 'article') {
            this.props.dispatchAddArticleStock();
        }

        this.setState({});
    }

    remove(stock_index) {
        const { fields, variant_index, stock_type } = this.props;

        fields.remove(stock_index);

        if (stock_type === 'variant') {
            this.props.dispatchRemoveArticleVariantStock(variant_index, stock_index);
        } else if (stock_type === 'article') {
            this.props.dispatchRemoveArticleStock(stock_index);
        }

        this.setState({});
    }

    render() {
        const { t, fields, stocks, salepoints } = this.props;

        return (
            <Row>
                <Col>
                    <Card className="ArticleStocks">
                        <CardBody>
                            {stocks && stocks.map((s, i) => (
                                <Jumbotron key={i} className="mb-4">
                                    <FormGroup row>
                                        <Label for="name" sm={3}>{t("articles.pos")}</Label>
                                        <Col sm={9}>
                                            <SalepointsField
                                                salepoints={salepoints}
                                                name={`${fields.name}[${i}].salepoint_ids`}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="availability" sm={3}>{t("articles.stocks.availability")}</Label>
                                        <Col sm={9}>
                                            <Field required component="input" className="form-control" type="number" name={`${fields.name}[${i}].availability`} />
                                        </Col>
                                    </FormGroup>
                                    <div className="text-right">
                                        <ButtonGroup>
                                            <Button color="danger" size="sm" onClick={() => { this.remove(i); }}>
                                                <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Jumbotron>
                            ))}
                            <Row>
                                <Col className="text-center">
                                    <br />
                                    <ButtonGroup>
                                        <Button color="info" size="sm" onClick={() => { this.add(); }}>
                                            <i className="fa fa-plus"></i>&nbsp;{t("common.add")}
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapDispatchToProps = {
    dispatchAddArticleVariantStock: (variant_index) => addArticleVariantStock(variant_index),
    dispatchAddArticleStock: () => addArticleStock(),
    dispatchRemoveArticleVariantStock: (variant_index, stock_index) => removeArticleVariantStock(variant_index, stock_index),
    dispatchRemoveArticleStock: (stock_index) => removeArticleStock(stock_index)
}
const mapStateToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ArticleStocks))
