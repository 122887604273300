import React from 'react';
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom';
import SettingEdit from './SettingEdit/SettingEdit';

const Settings = ({ match }) => {
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.settings);
    return (
        <div className="Settings">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }

            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }

            <Route path={ `${ match.url }/edit/:settingId/:field?` } component={SettingEdit} />
        </div>
    );
}

export default Settings;
