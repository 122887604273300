import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    Button,
    Row,
    Col,
    Label,
    InputGroup,
    InputGroupText,
    Input,
    ButtonGroup
} from "reactstrap";
const randomColor = require("randomcolor");

const SelectedSeatOption = ({ dragSelectedSeats, zoneSeats, gridCells, onSubmit, onClose }) => {
    const [zoneCat, setZoneCat] = useState('');
    const [seatType, setSeatType] = useState('');
    const [availableCat] = useState(
        _.without(_.uniq(_.map(gridCells, "zoneCat")), "")
    );
    const { t } = useTranslation();
    const [seatPostionTypeofDragSeats, setSeatPostionTypeofDragSeats] = useState('l2r');
    const [startDragSeatNumber, setStartDragSeatNumber] = useState(1);
    const [skippedSeatNumber, setSkippedSeatNumber] = useState(0);


    const chooseCatName = (index) => {
        setZoneCat(availableCat[index]);
    };

    /**
     * Handle seat type change
     * @param {*} e
     */
    const handleSelect = (e) => {
        setSeatType(e);
    };

    const submitData = () => {
        const randomClr = randomColor({
            luminosity: "bright",
            hue: "yellow",
        });

        var postionType = seatPostionTypeofDragSeats
        if (postionType === '') {
            postionType = 'l2r'
        }
        const seats = gridCells;
        const zoneMixpointOfx = _.minBy(zoneSeats, o => o.x);
        const minPointOfY = _.minBy(dragSelectedSeats, o => o.y);
        const maxPointOfY = _.maxBy(dragSelectedSeats, o => o.y);

        var skippedNumber = parseInt(skippedSeatNumber)
        dragSelectedSeats.map((seat) => {
            // debugger
            const xPos = seat.x + zoneMixpointOfx.x
            const yPos = seat.y
            const index = gridCells.findIndex(
                (e) => e.x === xPos && e.y === yPos
            );

            if (index > -1) {
                let tempObj = gridCells[index];
                var emptyColumn = calculateEmptyColumn(zoneSeats,tempObj, postionType === 'l2r' ? 'l2r&t2b' : '')
                if (tempObj !== undefined && tempObj.zoneLabel !== '') {
                    if (postionType === 'l2r') {
                        var seatNumber = parseInt(startDragSeatNumber) + parseInt(seat.y - minPointOfY.y) - emptyColumn + (skippedNumber * (seat.y - (minPointOfY.y+emptyColumn)))
                        tempObj.seat_number = seatNumber
                        tempObj.placing.seat_number = seatNumber
                    } else {
                        const seatNumber = parseInt(startDragSeatNumber) + parseInt(maxPointOfY.y - seat.y) - emptyColumn + (skippedNumber * (maxPointOfY.y - (seat.y+emptyColumn)))
                        tempObj.seat_number = seatNumber
                        tempObj.placing.seat_number = seatNumber
                    }

                    if (seatType !== '') {
                        tempObj.additional_class_names = seatType;
                    }

                    if (tempObj.zoneCat !== zoneCat && zoneCat !== '') {
                        tempObj.zoneCat = zoneCat;
                        tempObj.borderColor = randomClr;
                    }
                    seats[index] = tempObj;
                }
            }
        })
        // setZoneSeats(seats.filter((data) => data.zoneLabel == selectedZone.zoneLabel));
        // setGridCellsTemp(seats)
        onSubmit(gridCells);
    };

    const calculateEmptyColumn = (seatArray, seatObj,positionTypeValue) => {
        var emptyColumnCount = 0
        if (positionTypeValue === 'l2r&t2b' || positionTypeValue === 'l2r&b2t') {
            const minYPointOnZone = _.minBy(seatArray, o => o.y);
            for (var i = seatObj.y - 1; i >= minYPointOnZone.y; i--) {
                const xDataEmpty = seatArray.filter((data) => data.y === i)
                if (xDataEmpty.length === 0) {
                    emptyColumnCount++
                }
            }
        } else {
            const maxYPointOnZone = _.maxBy(seatArray, o => o.y);
            for (var i = seatObj.y + 1; i <= maxYPointOnZone.y; i++) {
                const xDataEmpty = seatArray.filter((data) => data.y === i)
                if (xDataEmpty.length === 0) {
                    emptyColumnCount++
                }
            }
        }
        return emptyColumnCount
    }

    const seatTypes = [
        'classic', 'sofa',
        'sofa-middle', 'sofa-left',
        'sofa-right', 'foldable-seat',
        'wheelchair'
    ];
    return (
        <Col sm={3}>
            <Col>
                <Label>
                    {t("places.map.seatposition")}
                </Label>
            </Col>
            <Col>
                <Button onClick={() => setSeatPostionTypeofDragSeats('l2r')} className={'zoneOptionMargin'}>
                    {'Left to right'}
                </Button>

                <Button onClick={() => setSeatPostionTypeofDragSeats('r2l')} className={'zoneOptionMargin'}>
                    {'Right to left'}
                </Button>

            </Col>
            <Col>
                <Label className="zoneLabelTitle">
                    {t("places.map.startSeatNumber")}
                </Label>
            </Col>
            <Col>
                <Input
                    type="number"
                    value={startDragSeatNumber}
                    placeholder={t("places.map.startSeatNumber")}
                    onChange={event => {
                        setStartDragSeatNumber(event.target.value)
                    }}
                />
            </Col>

            <Col>
                <Label className="zoneLabelTitle">
                    {t("places.map.skippedSeatNumber")}
                </Label>
            </Col>
            <Col>
                <Input
                    type="number"
                    value={skippedSeatNumber}
                    placeholder={t("places.map.skippedSeatNumber")}
                    onChange={event => {
                        setSkippedSeatNumber(event.target.value)
                    }}
                />
            </Col>
            <Col>
            </Col>

            <Col>
                <Label for="category">
                    {t("places.map.category")}
                </Label>
            </Col>
            <Col>
                <InputGroup>
                    {availableCat.map((data, index) => {
                        return (
                            <InputGroupText key={index} className={'zoneOptionMargin'}>
                                <Button onClick={() => chooseCatName(index)}>{data}</Button>
                            </InputGroupText>
                        );
                    })}
                    <Input
                        type="text"
                        value={zoneCat}
                        placeholder={"Zone Seats Category"}
                        onChange={(event) => setZoneCat(event.target.value)}
                    />
                </InputGroup>
            </Col>

            <Col>
                <Label for="category">
                    {t("places.map.seattype")}
                </Label>
            </Col>
            <Col>
                <InputGroup>
                    {seatTypes.map((type, index) => (
                        <InputGroupText key={index} className={'zoneOptionMargin'}>
                            <Button onClick={() => handleSelect(type)}>
                                {t(`places.map.${type}`)}
                            </Button>
                        </InputGroupText>
                    ))}
                    <Input
                        type="text"
                        value={seatType}
                        placeholder={"Zone Seats Type"}
                        onChange={(event) => setSeatType(event.target.value)}
                    />
                </InputGroup>
            </Col>
            <Row>
                <Col className="text-right">
                    <br />
                    <ButtonGroup>
                        <Button color="warning" onClick={onClose}>
                            <span>{t("common.cancel")}</span>
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col className="text-left">
                    <br />
                    <ButtonGroup>
                        <Button color="success" onClick={submitData}>
                            <span>{t("common.save")}</span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Col>
    );
};

export default SelectedSeatOption;
