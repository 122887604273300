import BaseModel from './BaseModel';
import { User, Availability, Value } from '../models';
import _ from 'lodash';

/** Class representing a sector. */
class Application extends BaseModel {
    static STATUS_SUBMITTED = 'submitted';
    static STATUS_ACCEPTED  = 'accepted';
    static STATUS_REJECTED  = 'rejected';

    constructor(properties) {
        super(properties);

        if (this.user)
            this.user = new User(this.user);

        if (this.availabilities)
            this.availabilities = (this.availabilities || []).map(props => new Availability(props));

        if (this.values)
            this.values = (this.values || []).map(props => new Value(props));

        this.busy_rate = (this.max_nb_hours > 0 ? this.nb_hours / this.max_nb_hours * 100 : 0);
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = _.cloneDeep(this);

        delete prepared.busy_rate;

        return prepared;
    };
}

export default Application;
