import RestClient from './RestClient';
import { Sector } from '../../models';

class Sectors extends RestClient {
    constructor() {
        super({
            "model": Sector,
            "entryPoint": "sector",
            "resource": "sector",
            "resources": "sectors",
            "id": "id"
        });
    }
}

const SectorsApi = new Sectors();

export default SectorsApi;
