import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Application } from '../../models';
import { updateApplication, acceptApplication, rejectApplication } from '../../redux';
import { Field } from 'redux-form';
import { Input, Row, Col, Badge, Button, Card, CardBody, CardTitle, CardText, CardImg, FormGroup, Label } from 'reactstrap';
import moment from 'moment';

import user_avatar from '../../assets/images/user_avatar.jpg';

const ApplicationDetails = ({ application, onApprove, onReject }) => {
    const dispatch    = useDispatch();
    const { t, i18n } = useTranslation();

    const [max_nb_hours, set_max_nb_hours] = useState(application?.max_nb_hours);
    const [max_nb_hours_per_day, set_max_nb_hours_per_day] = useState(application?.max_nb_hours_per_day);

    const { isLoading, isLoadingOne } = useSelector(state => state.applications);

    const save = () => dispatch(updateApplication({
        ...application,
        max_nb_hours,
        max_nb_hours_per_day
    }));
    const accept = () => dispatch(acceptApplication(application.id, onApprove));
    const reject = () => dispatch(rejectApplication(application.id, onReject));

    if (!application)
        return null;

    return (
        <>
            <Row>
                <Col xs={12} xl={6}>
                    <Row>
                        <Col xs={12}>
                            {application.status === Application.STATUS_SUBMITTED && (
                                <div className="d-flex column gap-2">
                                    <Badge className="px-4" color="info">
                                        <h3>{ t('applications.status_submitted') }</h3>
                                    </Badge>
                                    <div className="w-100">
                                        <Button block color="success" onClick={accept}>
                                            <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'check'}`}></i>&nbsp;
                                            { t('applications.accept_this_application') }
                                        </Button>
                                    </div>
                                    <div className="w-100">
                                        <Button block color="danger" onClick={reject}>
                                            <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'times'}`}></i>&nbsp;
                                            { t('applications.reject_this_application') }
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {application.status === Application.STATUS_ACCEPTED && (
                                <div className="d-flex column gap-2">
                                    <Badge className="px-4" color="success">
                                        <h3>{ t('applications.status_accepted') }</h3>
                                    </Badge>
                                    <div className="w-100">
                                        <Button block color="danger" onClick={reject}>
                                            <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'times'}`}></i>&nbsp;
                                            { t('applications.reject_this_application') }
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {application.status === Application.STATUS_REJECTED && (
                                <div className="d-flex column gap-2">
                                    <Badge className="px-4" color="danger">
                                        <h3>{ t('applications.status_rejected') }</h3>
                                    </Badge>
                                    <div className="w-100">
                                        <Button block color="success" onClick={accept}>
                                            <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'check'}`}></i>&nbsp;
                                            { t('applications.accept_this_application') }
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <hr />
                            <div className="d-flex">
                                <Badge color={application.busy_rate >= 100 ? 'danger' : (application.busy_rate >= 75 ? 'warning' : (application.busy_rate > 0 ? 'success' : 'info'))}>
                                    <h5>
                                        { t('applications.busy_rate') } : { application.busy_rate.toFixed(2) } %
                                    </h5>
                                </Badge>
                            </div>
                            <hr />
                            <FormGroup row>
                                <Label for="max_nb_hours" sm={6}>{t('applications.max_nb_hours')}</Label>
                                <Col sm={6}>
                                    <Input className="form-control" type="number" value={ max_nb_hours } onChange={ e => set_max_nb_hours(e.target.value) }/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="max_nb_hours_per_day" sm={6}>{t('applications.max_nb_hours_per_day')}</Label>
                                <Col sm={6}>
                                    <Input className="form-control" type="number" value={ max_nb_hours_per_day } onChange={ e => set_max_nb_hours_per_day(e.target.value) }/>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="main">
                        <Col className="text-center">
                            <Button type="submit" color="success" onClick={ save }>
                                <span>
                                    <i className={`fa ${isLoadingOne ? 'fa-spinner fa-spin' : 'fa fa-save'}`}></i>&nbsp;{t("common.save")}
                                </span>
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} xl={6}>
                    <Row>
                        <Col sm={12}>
                            <dl>
                            {application.values?.map(value => (
                                <div key={value.id}>
                                    <dt>{ value.field.label[i18n.language] }</dt>
                                    <dd>{value.renderData({t, lang: i18n.language})}</dd>
                                </div>
                            ))}
                            </dl>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default ApplicationDetails;
