import React from "react";
import FormLayout from '../FormLayout';
import TextField from './TextField';
import { Field } from 'redux-form';
import { Utils } from '../../services';

/** Class representing a Form list field. */
class ListField extends TextField {

    constructor(properties) {
        super({
            ...(ListField.getDefaultOptions()),
            ...properties,
        });

        this.type = FormLayout.LIST;

        // sort choices by ascending position
        this.choices = (this.choices || []).sort((a, b) => a.position - b.position);
    }

    /**
     * Get this field default options
     */
    static getDefaultOptions() {
        const properties = super.getDefaultOptions();
        Utils.langs().forEach(lang => { properties.label[lang] = "";  });

        properties.choices  = [];
        properties.expanded = false;
        properties.multiple = false;

        return properties;
    }

    /**
     * Return the list of options this field handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            FormLayout.OPTION_CHOICES,
            FormLayout.OPTION_EXPANDED,
            FormLayout.OPTION_MULTIPLE,
        ]);
    }

    /**
     * Return this field preview input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getInput(props) {
        const { lang } = props;

        if (this.expanded)
            return this.choices.map(choice => (
                <div key={ choice.id } className="listFieldChoice">
                    <Field
                        component="input"
                        type={ this.multiple ? 'checkbox' : 'radio'}
                        className="form-control"
                        name={ `${this.id}[${choice.id}]` }
                        style={{ height: '25px', width: '25px' }}
                    />
                    <label>{choice.label[lang]}</label>
                </div>
            ));

        return (
            <Field
                component="select"
                name={this.id}
                multiple={!!this.multiple}
                required={!!this.required}
                className="form-control"
            >
                { this.choices?.map(choice => (
                    <option key={ choice.id } value={ choice.id }>
                        {choice.label[lang]}
                    </option>
                ))}
            </Field>
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'list';
    }
}

export default ListField;
