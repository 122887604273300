import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadActivity } from '../../../redux/modules/activities';

class ActivityShow extends Component<{}> {
    componentDidMount() {
        const activityId = this.props.match.params.activityId;
        // Load activities on component mount
        this.props.dispatchLoadActivity(activityId);
    }

    render() {
        const { t }           = this.props;
        const { activity } = this.props.activities;

        const default_lang = Utils.default_lang();

        let title = <span>{t("activities.activity_plural")}</span>;
        if (activity)
            title = (
                <span>
                    {t("activities.activity")}
                    <small> | { activity.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/activities" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {activity &&
                    <Button tag={Link} to={ `/activities/edit/${ activity.id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="ActivityShow">
                <PageTitle icon="id-badge" title={ title } actions={ actions } />
                {activity &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("activities.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("activities.name")}</b>: { activity.name[default_lang] }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadActivity: (activityId) => loadActivity(activityId)
}

const mapStateToProps = state => ({
    activities: state.activities
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ActivityShow))
