import BaseModel from './BaseModel';
import { User } from '../models';
import { Utils } from '../services';
import _ from 'lodash';

/** Class representing a sector. */
class Sector extends BaseModel {

    constructor(properties) {
        super(properties);

        if (this.parent)
            this.parent = new Sector(this.parent);

        this.children = (this.children || []).map(props => new Sector(props));

        if (this.admins) {
            this.admins = (this.admins || []).map(props => new User(props));
            this.admin_ids = this.admin_ids || this.admins.map(user => user.id);
        }
    }

    /**
     * Build a tree of sectors
     *
     * @param {Sector[]} sectors
     * @param {String} lang
     *
     * @return Sector[]
     */
    static buildTree(sectors, lang) {
        if (!sectors)
            return [];

        // since we change the sectors name (we indent it),
        // we have to clone the provided sectors
        sectors = _.cloneDeep(sectors);

        const data        = [];
        const parents     = sectors.filter(s => !s.parent_id);
        const childrenIds = sectors.filter(s => !!s.parent_id).map(s => s.id);

        const addToTree = (sector, depth) => {
            // let's "indent" the name
            Object.keys(sector.name).forEach(lang => {
                sector.name[lang] = `${'-'.repeat(depth)} ${sector.name[lang]}`;
            });

            // let's add this sector to the tree
            data.push(sector);

            // let's add its children
            sector.children
                // we must check if each child was in the provided sectors
                .filter(s => childrenIds.includes(s.id))
                .sort(Sector.sort(lang))
                .forEach(s => addToTree(s, depth + 1));
        };

        // let's add all the root sectors
        parents
            .sort(Sector.sort(lang))
            .forEach(sector => addToTree(sector, 0));

        return data;
    }

    /**
     * Generate a sort function based on the provided language
     *
     * @param {String} lang
     *
     * @return {Callable}
     */
    static sort(lang) {
        return (a, b) => Utils.localized_or_fallback(a.name, lang).localeCompare(
            Utils.localized_or_fallback(b.name, lang)
        );
    }
}

export default Sector;
