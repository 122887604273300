import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { tktsyncLegacyScreenings } from '../../redux/modules/legacyScreenings';

import './TktSyncButton.css';

const TktSyncButton = ({collapsed}) => {
    const { t }                                = useTranslation();
    const { isTktsyncing, hasTktsyncingError } = useSelector(state => state.legacyScreenings);
    const dispatch                             = useDispatch();

    const tktsync = e => {
        dispatch(tktsyncLegacyScreenings());
    }

    const color = hasTktsyncingError ? 'warning' : 'info';
    return (
        <Button onClick={ tktsync } color={ color } className={`TktSyncButton`}>
            <i className={`fa ${isTktsyncing ? 'fa-spinner fa-spin' : 'fa-download'}`}></i>
            {!collapsed && <span>&nbsp;{ t("events.tktsync") }</span>}
        </Button>
    );
}

export default TktSyncButton;
