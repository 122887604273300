import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText, CardImg } from 'reactstrap';
import { Link } from "react-router-dom";
import { Application } from '../../../models';
import { PageTitle } from '../../../components';
import { acceptApplication, rejectApplication } from '../../../redux';
import moment from 'moment';

import { Utils } from '../../../services/';
import { loadApplication } from '../../../redux/modules/applications';

import user_avatar from '../../../assets/images/user_avatar.jpg';

const ApplicationShow = ({ match }) => {
    const dispatch    = useDispatch();
    const { t, i18n } = useTranslation();

    const { application, isLoading } = useSelector(state => state.applications);

    useEffect(() => {
        const { applicationId } = match.params;
        dispatch(loadApplication(applicationId));
    }, [dispatch])

    const accept = () => dispatch(acceptApplication(application.id));
    const reject = () => dispatch(rejectApplication(application.id));

    if (!application?.user)
        return null;

    const title = (
        <span>
            {t('applications.application')}
            <small> | { application?.user?.firstname } { application?.user?.lastname }</small>
        </span>
    );

    const actions =
    <div>
        <ButtonGroup>
            <Button tag={Link} to="/applications" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
            </Button>
        </ButtonGroup>
    </div>

    const user = application.user;

    return (
        <div className="ApplicationShow">
            <PageTitle icon="applications" title={ title } actions={ actions } />
            {application?.user &&
                <Row>
                    <Col xs={12} lg={3}>
                        <Row>
                            <Col xs={12}>
                                <div style={{ maxWidth: '250px', margin: '20px auto' }}>
                                    <Card>
                                        <CardImg top src={user.avatar || user_avatar} alt="Card image cap" />
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {application.status === Application.STATUS_SUBMITTED && (
                                    <div className="d-flex column gap-2">
                                        <div className="badge badge-info px-4">
                                            <h3>{ t('applications.status_submitted') }</h3>
                                        </div>
                                        <div className="w-100">
                                            <Button block color="success" onClick={accept}>
                                                <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'check'}`}></i>&nbsp;
                                                { t('applications.accept_this_application') }
                                            </Button>
                                        </div>
                                        <div className="w-100">
                                            <Button block color="danger" onClick={reject}>
                                                <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'times'}`}></i>&nbsp;
                                                { t('applications.reject_this_application') }
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {application.status === Application.STATUS_ACCEPTED && (
                                    <div className="d-flex column gap-2">
                                        <div className="badge badge-success px-4">
                                            <h3>{ t('applications.status_accepted') }</h3>
                                        </div>
                                        <div className="w-100">
                                            <Button block color="danger" onClick={reject}>
                                                <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'times'}`}></i>&nbsp;
                                                { t('applications.reject_this_application') }
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {application.status === Application.STATUS_REJECTED && (
                                    <div className="d-flex column gap-2">
                                        <div className="badge badge-danger px-4">
                                            <h3>{ t('applications.status_rejected') }</h3>
                                        </div>
                                        <div className="w-100">
                                            <Button block color="success" onClick={accept}>
                                                <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'check'}`}></i>&nbsp;
                                                { t('applications.accept_this_application') }
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} lg={9}>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-user-circle"></i>&nbsp;
                                            {t('users.profile')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t('users.firstname')}</b>: { user.firstname }</div>
                                            <div><b>{t('users.lastname')}</b>: { user.lastname }</div>
                                            <div><b>{t('users.role')}</b>: { Utils.roles(user.role).label }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-envelope"></i>&nbsp;
                                            {t('users.contact')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t('users.email')}</b>: { user.email }</div>
                                            {user.fix_phone && (<div><b>{t('users.fix_phone')}</b> : { user.fix_phone }</div>)}
                                            {user.mobile_phone && (<div><b>{t('users.mobile_phone')}</b> : { user.mobile_phone }</div>)}
                                            {user.pro_phone && (<div><b>{t('users.pro_phone')}</b> : { user.pro_phone }</div>)}
                                            {user.fax && (<div><b>{t('users.fax')}</b> : { user.fax }</div>)}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-address-card"></i>&nbsp;
                                            {t('users.address')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div>{ user.address }</div>
                                            <div>{ user.zip } {user.city }</div>
                                            <div>{ user.country }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-th-list"></i>&nbsp;
                                            {t('users.other_informations')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            {user.birthday && (<div><b>{t('users.birthday')}</b>: { moment(user.birthday).format('LL') }</div>)}
                                            {application.values?.map(value => (
                                                <div key={value.id}>
                                                    <b>{ value.field.label[i18n.language] }</b>: {value.renderData({t, lang: i18n.language})}
                                                </div>
                                            ))}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-id-badge"></i>&nbsp;
                                        {t("users.activities")}
                                        </CardTitle>
                                        <CardText tag="div">
                                                { user.activities && user.activities.map(activity => <div className="badge badge-light mr-2"> {activity.name[i18n.language]}</div>) }
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-location-arrow"></i>&nbsp;
                                        {t("users.sectors")}
                                        </CardTitle>
                                        <CardText tag="div">
                                            { application.sectors?.map(sector => (
                                                <div key={sector.id} className="badge badge-light mr-2"> {sector.name[i18n.language]}</div>
                                            ))}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default ApplicationShow;
