import BaseModel from './BaseModel';

/** Class representing an article attribute choice. */
class ArticleAttributeChoice extends BaseModel {
    isDefault() {
        return !!this.default;
    }
}

export default ArticleAttributeChoice;
