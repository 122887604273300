import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import { Setting } from '../../models';
import { Col, CardTitle, CardText, FormGroup, Label, Button } from 'reactstrap';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import './SettingField.css';

const SettingField = (props) => {
    const user = useSelector(state => state.auth.user);
    let {
        name, setting, showTitle, level, overrides,
        tickettypes, salepoints, articles,
        articleCategories, postCategories, places,
        onDeleteFromSalePoint
    } = props;
    const { t } = useTranslation();

    level = level || 0;
    if (!name || !setting)
        return null;

    const nameForlabel = name?.replace('.production', '').replace('.preprod', '').replace('.testing', '');

    const isTranslatableField = value => {
        return _.isPlainObject(value) && (
            (Object.keys(value).includes('fr') && !_.isPlainObject(value.fr)) ||
            (Object.keys(value).includes('en') && !_.isPlainObject(value.en)) ||
            (Object.keys(value).includes('de') && !_.isPlainObject(value.de)) ||
            (Object.keys(value).includes('it') && !_.isPlainObject(value.it))
        );
    };

    const hasSubSettings = value => {
        return _.isPlainObject(value);
    };

    const isScalar = (value) => {
        return (
            ['string', 'boolean'].includes(typeof value) ||
            (_.isPlainObject(value) && Object.keys(value).length == 0) ||
            (_.isArray(value) && !_.isPlainObject(value))
        );
    }

    const { input, role, hideLabel } = Setting.propertyForm(t, setting, name, tickettypes, salepoints, articles, articleCategories, postCategories, places, overrides);
    const value = Utils.getDotified(setting, name);

    if (!input && !isTranslatableField(value) && value && hasSubSettings(value)) {
        const sortedScalarValues = _.sortBy(
            Object.keys(value).filter(key => {
                const value = Utils.getDotified(setting, `${name}.${key}`);
                return isScalar(value) || isTranslatableField(value);
            }),
            key => {
                const { order } = Setting.propertyForm(t, setting, `${name}.${key}`, tickettypes, salepoints, articles, articleCategories, postCategories, places, overrides);
                return order;
            }
        );

        const sortedObjectValues = _.sortBy(
            Object.keys(value).filter(key => {
                const value = Utils.getDotified(setting, `${name}.${key}`);
                return !isScalar(value) && !isTranslatableField(value);
            }),
            key => {
                const { order } = Setting.propertyForm(t, setting, `${name}.${key}`, tickettypes, salepoints, articles, articleCategories, postCategories, places, overrides);
                return order;
            }
        );

        return (
            <div className="SettingField">
                {(user?.isSuperAdmin() || (!role && user?.can('editSelf', 'settings'))) &&
                    <>
                        {showTitle && (
                            <CardTitle className="small">{!hasSubSettings(value) ? t(`settings.${nameForlabel}`) : t(`settings.${nameForlabel}.title`)}</CardTitle>
                        )}
                        <div className={`wrapper-${level}`}>

                            <CardText tag="div">
                                {sortedScalarValues.map(key => (
                                    <SettingField
                                        {...props}
                                        key={key}
                                        showTitle
                                        name={`${name}.${key}`}
                                        level={level + 1}
                                    />
                                ))}
                                {sortedObjectValues.map(key => (
                                    <SettingField
                                        {...props}
                                        key={key}
                                        showTitle
                                        name={`${name}.${key}`}
                                        level={level + 1}
                                    />
                                ))}
                            </CardText>
                        </div>
                    </>
                }
            </div>
        );
    }

    if (!input)
        return null;

    const cols = [];
    if (hideLabel && !onDeleteFromSalePoint)
        cols.push(12, 12, 12);
    else if (!hideLabel && !onDeleteFromSalePoint)
        cols.push(12, 8, 9);
    else
        cols.push(10, 7, 8);

    return (
        <>
            {(user?.isSuperAdmin() || (!role && user?.can('editSelf', 'settings'))) &&
                <div className="SettingField">
                    <FormGroup row>
                        {!hideLabel && (
                            <Label for="_id" lg={4} xl={3}>{t(`settings.${nameForlabel}`)}</Label>
                        )}
                        <Col xs={cols[0]} sm={cols[0]} md={cols[0]} lg={cols[1]} xl={cols[2]}>
                            {input}
                        </Col>
                        {onDeleteFromSalePoint &&
                        <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                            <Button color="danger" size="sm" className='float-right' onClick={() => onDeleteFromSalePoint(nameForlabel)}><i className='fa fa-times'></i></Button>
                        </Col>
                        }
                    </FormGroup>
                </div>
            }
        </>
    );
}

export default SettingField;
