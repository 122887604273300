import BaseModel from './BaseModel';
import moment from 'moment';

/** Class representing a section. */
class Phase extends BaseModel {
    constructor(properties) {
        super(properties || {});

        if (this.start_at)
            this.start_at = moment(this.start_at);

        if (this.stop_at)
            this.stop_at = moment(this.stop_at);
    }
}

export default Phase;
