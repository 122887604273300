import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Field } from 'redux-form'
import { changeSetting } from '../../redux/modules/settings';
import _ from 'lodash';

import './ExtraCurrenciesField.css';

const ExtraCurrenciesField = ({ name }) => {
    const [newCurrency, setNewCurrency] = useState('');

    const { t }       = useTranslation();
    const dispatch    = useDispatch();
    const { setting } = useSelector(state => state.settings);

    const { isMobile, isTablet } = useSelector(state => state.ui);

    const addExtraCurrency = () => {
        const newSetting = _.cloneDeep(setting);
        newSetting.l10n.extra_currencies = newSetting.l10n.extra_currencies || [];
        newSetting.l10n.extra_currencies.push({ currency: newCurrency, rate: 1 });
        dispatch(changeSetting(newSetting));
        setNewCurrency('');
    }

    const removeExtraCurrency = index => {
        const newSetting = _.cloneDeep(setting);
        newSetting.l10n.extra_currencies = newSetting.l10n.extra_currencies || [];
        newSetting.l10n.extra_currencies.splice(index, 1);
        dispatch(changeSetting(newSetting));
    }

    return (
        <div className="ExtraCurrenciesField">
            <Row>
                <Col sm={ 6 }>
                    <InputGroup>
                        <Input type="text" placeholder="EUR" value={newCurrency} onChange={e => setNewCurrency(e.target.value)} />
                        <InputGroupAddon addonType="append">
                            <Button color="info" onClick={ addExtraCurrency } disabled={!newCurrency || (newCurrency.length == 0)}>
                                <i className="fa fa-plus"></i>&nbsp;
                                { t('settings.l10n.add_extra_currency') }
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
            {(setting?.l10n.extra_currencies || []).map((extra, i) => (
                <Col key={ i } sm={12} xl={6}>
                    <InputGroup className={isMobile ? "pt-5" : ""}>
                        <InputGroupAddon addonType="prepend">1</InputGroupAddon>
                        <Field className="form-control" name={`${name}[${i}].currency`} component="input" type="text" />
                        <InputGroupAddon addonType="append" className={isMobile ? "col-sm-12 justify-content-center" : ""} >=</InputGroupAddon>
                        <Field className="form-control" name={`${name}[${i}].rate`} component="input" type="number" />
                        <InputGroupAddon addonType="append">{ setting.l10n.currency}</InputGroupAddon>
                        <InputGroupAddon addonType="append">
                            <Button color="danger" onClick={ e => removeExtraCurrency(i) }>
                                <i className="fa fa-trash"></i>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                    <hr></hr>
                </Col>
            ))}
            </Row>
        </div>
    );
}

export default ExtraCurrenciesField;
