import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadTktInstance, deleteTktInstance } from '../../../redux/modules/tktInstances';

class TktInstanceDelete extends Component<{}> {
    componentDidMount() {
        const tktInstanceId = this.props.match.params.tktInstanceId;
        // Load tktInstances on component mount
        this.props.dispatchLoadTktInstance(tktInstanceId);
    }

    deleteTktInstance(tktInstanceId) {
        this.props.dispatchDeleteTktInstance(tktInstanceId);
    }

    render() {
        const { tktInstance, isLoading, tktInstanceSuccessfullyDeleted } = this.props.tktInstances;
        const { t } = this.props;

        let title = <span>{t("tktInstances.tktInstance_plural")}</span>;
        if (tktInstance)
            title = (
                <span>
                    {t("tktInstances.tktInstance")}
                    <small> | {t('tktInstances.delete', {name: tktInstance.name})}</small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/tktInstances" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="TktInstanceDelete">
                <PageTitle icon="tktInstance-o" title={ title } actions={ actions } />

                {tktInstanceSuccessfullyDeleted &&
                    <Redirect to="/tktInstances"/>
                }

                {tktInstance &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('tktInstances.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("tktInstances.name")}</b>: { tktInstance.name }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/tktInstances" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteTktInstance(tktInstance.id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadTktInstance: (tktInstanceId) => loadTktInstance(tktInstanceId),
    dispatchDeleteTktInstance: (tktInstanceId) => deleteTktInstance(tktInstanceId)
}

const mapStateToProps = state => ({
    tktInstances: state.tktInstances
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(TktInstanceDelete))
