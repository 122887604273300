import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import ItemsList from '../../../../components/ItemsList/ItemsList';
import TktInstanceAbilitiesTemplate from '../TktInstanceAbilitiesTemplate/TktInstanceAbilitiesTemplate';
import { updateTktInstance } from '../../../../redux/modules/tktInstances';

const TktInstanceAbilitiesTemplateList = ({ instance }) => {
    const { t }                           = useTranslation();
    const dispatch                        = useDispatch();
    const [isActive, setIsActive]         = useState();

    const onDelete = (templateIndex) => {
        let temp = instance;
        temp.abilities_templates.splice(templateIndex, 1);
        dispatch(updateTktInstance(temp));
        setIsActive();
    }

    const onSelect = (values) => {
        setIsActive(values)
    }
 
    const listData = instance.abilities_templates.map((template, index) => {
        return {
            key: index,
            title: template.name,
            icon: "drivers-license",
            active: (index === isActive) ? true : false,
            selection: index
        }
    });

    return (
        <div className="TktInstanceAbilitiesTemplateList">
            <Row>
                <Col sm={3}>
                    <ItemsList
                        title={t("abilities.template")}
                        items={listData}
                        onSelect={onSelect}
                    />
                </Col>
                <Col sm={9}>
                    {(instance.abilities_templates && isActive !== undefined) &&
                        <TktInstanceAbilitiesTemplate
                            data={instance.abilities_templates[isActive].abilities}
                            index={isActive}
                            isEdit
                            editable
                            onDelete={onDelete}
                        />
                    }
                </Col>
            </Row>
        </div>
    )
}

export default TktInstanceAbilitiesTemplateList
