import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';

import './PosLayoutsField.css';

const PosLayoutsField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.posLayouts().map(l => {
        return {
            key: l,
            text: t(`salepoints.layouts.${l}`),
            value: l
        };
    });

    return <SelectField name={ name } options={ options } />;
}

export default PosLayoutsField;
