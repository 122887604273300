import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';

import './ShowCalculatorField.css';

const ShowCalculatorField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.showCalculatorOptions().map(option => ({
        key: option,
        text: t(`settings.payment.method.cash.show_calculator_${option}`),
        value: option,
    }));

    return <SelectField name={ name } options={ options } />;
}

export default ShowCalculatorField;
