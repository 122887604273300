import React, { useEffect, useMemo, useState } from 'react';
import { Yoda } from '@netoxygen/yoda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Col, Row, Collapse } from 'reactstrap';
import { updateUi } from '../../redux';
import moment from 'moment';
import { Utils } from '../../services';
import i18n from '../../locales/i18n';
import _ from 'lodash';
import config from '../../config/Config';

import './ChangeLog.css';
import ModalComponent from '../ModalComponent';

const ChangeLog = () => {
    const dispatch                          = useDispatch();
    const { t }                             = useTranslation();
    const { showChangelog }                 = useSelector(state => state.ui);
    const [changelog, setChangeLog]         = useState(null);
    const [opened, setOpened]               = useState(0);
    const [firstShowFlag, setFirstShowFlag] = useState(false);
    const [changelogUrl, setChangelogUrl]   = useState(null);
    const [isOpen, setIsOpen]               = useState(false);
    const [imageUrl, setImageUrl]           = useState(null);
    const [message, setMessage]             = useState(null);

    const releaseTypeValues = {
        feature: {
            icon: <i className='fa fa-plus'></i>,
            text: t('changelog.features'),
            color: 'success'
        },
        fix: {
            icon: <i className='fa fa-bug'></i>,
            text: t('changelog.fixes'),
            color: 'warning'
        },
        i18n: {
            icon: <i className='fa fa-language'></i>,
            text: t('changelog.i18n'),
            color: 'primary'
        },
        ui: {
            icon: <i className='fa fa-paint-brush'></i>,
            text: t('changelog.ui'),
            color: 'info'
        },
        build: {
            icon: <i className='fa fa-cog'></i>,
            text: t('changelog.builds'),
            color: 'muted'
        },
    };

    useEffect(() => {
        if (showChangelog)
            getContent();
        if (!firstShowFlag && showChangelog)
            setFirstShowFlag(true);
    }, [showChangelog]);

    const getContent = async () => {
        try {
            const yoda   = new Yoda();
            const result = await yoda.getChangelog(
                config.yoda_project_id,
                /*from*/'1.0.0',
                /*merge*/[config.yoda_kore_project_id]
            );

            if (!result.error) {
                setChangeLog(result.changelog.reverse().slice(0, 10));
                setChangelogUrl(result.changelogUrl);
            } else {
                setChangeLog(result.error());
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    };

    const processedChangelog = useMemo(() => {
        const tempResult = [];
        if (changelog && changelog.length > 1){
            for (let i = 0; i < changelog.length; i++) {
                const release = changelog[i];
                if (release.changes.length > 0){
                    tempResult.push({
                        releaseNo: release.version,
                        releaseDate: moment(release.commit.date).format('ll'),
                        releaseChanges: {
                            feature: [],
                            fix: [],
                            ui: [],
                            i18n: [],
                            build: []
                        }
                    });
                    release.changes.forEach(change => {
                        const key = tempResult.length - 1;
                        if (tempResult[key].releaseChanges[change.type])
                            tempResult[key].releaseChanges[change.type].push({
                                title: change.title,
                                description: change.description,
                                imageUrl: change.image
                            });
                    });
                };
            };
        }
        return tempResult;
    }, [changelog]);

    const hideChangeLog = () => {
        dispatch(updateUi({ showChangelog: !showChangelog }));
    };

    const toggleCollapse = (index) => {
        setOpened(index);
    }
    const goToChangelog = () => {
        window.open(changelogUrl, '_blank');
    };

    return (
        <div className={`Changelog${showChangelog ? " expandedLog" : " reducedLog"}${firstShowFlag ? " d-block" : ""}`}>
            {(showChangelog) &&
                <>
                    {processedChangelog && processedChangelog.length > 0 ?
                        <>
                        <Button color="danger" size="sm" className='float-right' onClick={hideChangeLog}>
                            <i className='fa fa-times'></i>
                        </Button>
                        <h3 className='border-bottom mt-1'>
                            <i className='fa fa-code-fork'></i>&nbsp;{t('changelog.changelog')}
                        </h3>
                        {processedChangelog.map((c, i) =>
                            <div key={i}>
                                {/* Release */}
                                <Button className="hover-version w-100 bg-transparent border-0 rounded-0 mb-1 text-left text-dark" onClick={() => toggleCollapse(i)}>
                                    <h5>
                                        <b>
                                            <i className='fa fa-rocket'></i>&nbsp;{c.releaseNo}
                                            <span className='d-block float-right'>{c.releaseDate}</span>
                                        </b>
                                    </h5>
                                </Button>
                                {/* Changes */}
                                <Collapse isOpen={opened === i}>
                                    {Object.keys(c.releaseChanges).filter(key => c.releaseChanges[key].length > 0).map((key, index) => 
                                        <Row className='px-2 mb-4' key={(i + index).toString()}>
                                            {/* Change title */}
                                            <Col xs='12' className='pt-2 mb-2 mt-2 d-flex align-items-center'>
                                                {releaseTypeValues[key]?.icon}&nbsp;
                                                <Badge color={releaseTypeValues[key]?.color}>{releaseTypeValues[key]?.text}</Badge>
                                            </Col>
                                            {/* Change description */}
                                            {c.releaseChanges[key].map((change, id) =>
                                                <React.Fragment key={i + key + id}>
                                                    <Col xs="1" className='mt-2'><small><i className='fa fa-bolt'></i></small></Col>
                                                    <Col xs="11" className='mt-2'><b>{Utils.localized_or_fallback(change.title, i18n.language)}</b></Col>
                                                    <Col xs="11" className="offset-1" dangerouslySetInnerHTML={{ __html: Utils.localized_or_fallback(change.description, i18n.language) }} />
                                                    {change.imageUrl &&
                                                        <Col xs="11" className='offset-1 mt-2 mb-2'>
                                                            <img
                                                                className='mw-100'
                                                                src={change.imageUrl}
                                                                onClick={() => {
                                                                    setMessage(Utils.localized_or_fallback(change.title, i18n.language));
                                                                    setImageUrl(change.imageUrl);
                                                                    setIsOpen(true);
                                                                }}
                                                                alt={change.title}
                                                            />
                                                        </Col>
                                                    }
                                                </React.Fragment>
                                            )}
                                        </Row>
                                    )}
                                </Collapse>
                            </div>
                        )}
                        { changelogUrl &&
                            <Button className='w-100 text-center">' color="info" onClick={goToChangelog}>
                                <i className='fa fa-history'></i> {t('app.history') }
                            </Button>
                        }
                        {isOpen && imageUrl &&
                            <ModalComponent onClose={() => setIsOpen(false)}>
                                <div className="changelog-modal text-center mt-3">
                                    <h3 className="title">{message}</h3>
                                        <img src={imageUrl} className="w-100 img" />
                                    </div>
                            </ModalComponent>
                        }
                        </>
                        :
                        <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    }
                </>
            }
        </div>
    );
}

export default ChangeLog;
