import { SioBridge } from '../../services';

const CONNECT          = `kronos/sioBridge/CONNECT`;
const CONNECTED        = `kronos/sioBridge/CONNECTED`;
const CONNECTION_ERROR = `kronos/sioBridge/CONNECTION_ERROR`;
const DISCONNECT       = `kronos/sioBridge/DISCONNECT`;
const DISCONNECTED     = `kronos/sioBridge/DISCONNECTED`;
const SEND_MSG         = `kronos/sioBridge/SEND_MSG`;
const SENT_MSG         = `kronos/sioBridge/SENT_MSG`;
const SEND_MSG_ERROR   = `kronos/sioBridge/SEND_MSG_ERROR`;
const RECEIVED_MSG     = `kronos/sioBridge/RECEIVED_MSG`;

const initialState = {
    connected: false,
    hasError: false,
    errorMessage: '',
    isLoading: false,
    lastMsg: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CONNECT:
            return {
                ...state,
                connected: false,
                isLoading: true,
                hasError: false,
                errorMessage: null
            }
        case CONNECTED:
            return {
                ...state,
                connected: true,
                isLoading: false,
                hasError: false,
                errorMessage: null
            }
        case CONNECTION_ERROR:
            return {
                ...state,
                connected: false,
                isLoading: false,
                hasError: true,
                errorMessage: action.error
            }
        case DISCONNECT:
            return {
                ...state,
                isLoading: true,
                hasError: false,
                errorMessage: null
            }
        case DISCONNECTED:
            return {
                ...state,
                connected: false,
                isLoading: false,
                hasError: false,
                errorMessage: null
            }
        case SEND_MSG:
            return {
                ...state,
                isLoading: true,
                hasError: false,
                errorMessage: null
            }
        case SENT_MSG:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                errorMessage: null
            }
        case SEND_MSG_ERROR:
            return {
                ...state,
                isLoading: false,
                hasError: true,
                errorMessage: action.error
            }
        case RECEIVED_MSG:
            return {
                ...state,
                isLoading: false,
                hasError: false,
                errorMessage: null,
                lastMsg: action.msg
            }
        default:
            return state;
    }
};

const connectAction = () => { return { type: CONNECT } };
const connectedAction = () => { return { type: CONNECTED } };
const connectionErrorAction = () => { return { type: CONNECTION_ERROR } };
export const connect = (apiKey, serverUrl, callbacks) => {
    return (dispatch) => {
        dispatch(connectAction());
        SioBridge.connect(apiKey, serverUrl, callbacks)
            .then(result => {
                dispatch(connectedAction(result));
            })
            .catch(err => {
                dispatch(connectionErrorAction(err));
            })
    }
}

const disconnectedAction = () => { return { type: DISCONNECTED } };
export const disconnect = () => {
    return (dispatch) => {
        dispatch(disconnectedAction());
    }
}

const sendAction = () => { return { type: SEND_MSG } };
const sentAction = () => { return { type: SENT_MSG } };
const sendErrorAction = () => { return { type: SEND_MSG_ERROR } };
export const sendMessage = (msg, params) => {
    return (dispatch) => {
        dispatch(sendAction());
        SioBridge.sendMessage(msg, params)
            .then(result => {
                dispatch(sentAction(result));
            })
            .catch(err => {
                dispatch(sendErrorAction(err));
            })
    }
}

export const receivedMsg = (msg) => {
    return (dispatch) => {
        dispatch({type: RECEIVED_MSG, msg});
    }
}
