import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './ModulesField.css';

const ModulesField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.modules().map(module => {
        return {
            key: module,
            text: t('modules.module_' + module),
            value: module
        };
    });

    return <MultiSelectField name={ name } options={ options } />
}

export default ModulesField;
