import RestClient from './RestClient';
import { Tickettype, Ref } from '../../models';

class Tickettypes extends RestClient {
    constructor() {
        super({
            "model": Tickettype,
            "entryPoint": "tickettype",
            "resource": "tickettype",
            "resources": "tickettypes",
            "id_field": "_id",
            "sortBy": "name"
        });

        this.REFS_PATH = '/refs';
    }

    loadRefs(params) {
        return this.GET(`/${this.entryPoint}${this.REFS_PATH}`, {})
            .then(response => response.json())
            .then(json => {
                if (json.refs)
                    return {
                        ...json,
                        refs: json.refs.map(ref => new Ref(ref))
                    };
                throw new Error(json.flash.error);
            });
    }
}

const TickettypesApi = new Tickettypes();

export default TickettypesApi;
