import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import TickettypeForm from '../TickettypeForm/TickettypeForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Utils } from '../../../services';
import { loadTickettype, updateTickettype } from '../../../redux/modules/tickettypes';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const TickettypeEdit = () => {
    const { t }            = useTranslation();
    const dispatch         = useDispatch();
    const { tickettypeId } = useParams();

    useEffect(() => {
        // Load tickettypes on component mount
        dispatch(loadTickettype(tickettypeId));
    }, [dispatch, tickettypeId])

    const submit = (values) => {
        dispatch(updateTickettype(values));
    }

    const { tickettype } = useSelector(state => state.tickettypes);

    let title = <span>{t('tickettypes.tickettype')}</span>;
    if (tickettype && tickettype.name)
        title = (
            <span>
                {t('tickettypes.tickettype')}
                <small> |&nbsp;{ t('tickettypes.update_tickettype', { name: tickettype.name[Utils.default_lang()] })} </small>
            </span>
        );

    const actions =
    <div>
        <ButtonGroup>
            <Button tag={Link} to="/tickettypes" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
            </Button>
        </ButtonGroup>
    </div>

    return (
        <div className="TickettypeEdit">
            <PageTitle icon="tags" title={ title } actions={ actions } />

            {(tickettype && tickettypeId === tickettype._id) &&
                <TickettypeForm tickettypeId={tickettype._id} onSubmit={ submit } />
            }
        </div>
    );
}

export default TickettypeEdit;
