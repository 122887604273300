// Redux Salepoints module
// see https://github.com/erikras/ducks-modular-redux

import { Salepoints } from '../../services/';


const LOADING_SALEPOINTS         = 'kronos/salepoints/LOADING_SALEPOINTS';
const LOADING_SALEPOINTS_SUCCESS = 'kronos/salepoints/LOADING_SALEPOINTS_SUCCESS';
const LOADING_SALEPOINTS_FAILURE = 'kronos/salepoints/LOADING_SALEPOINTS_FAILURE';
const LOADING_SALEPOINT          = 'kronos/salepoints/LOADING_SALEPOINT';
const LOADING_SALEPOINT_SUCCESS  = 'kronos/salepoints/LOADING_SALEPOINT_SUCCESS';
const LOADING_SALEPOINT_FAILURE  = 'kronos/salepoints/LOADING_SALEPOINT_FAILURE';
const UPDATING_SALEPOINT         = 'kronos/salepoints/UPDATING_SALEPOINT';
const UPDATING_SALEPOINT_SUCCESS = 'kronos/salepoints/UPDATING_SALEPOINT_SUCCESS';
const UPDATING_SALEPOINT_FAILURE = 'kronos/salepoints/UPDATING_SALEPOINT_FAILURE';
const START_CREATING_SALEPOINT   = 'kronos/salepoints/START_CREATING_SALEPOINT';
const CREATING_SALEPOINT         = 'kronos/salepoints/CREATING_SALEPOINT';
const CREATING_SALEPOINT_SUCCESS = 'kronos/salepoints/CREATING_SALEPOINT_SUCCESS';
const CREATING_SALEPOINT_FAILURE = 'kronos/salepoints/CREATING_SALEPOINT_FAILURE';
const DELETING_SALEPOINT         = 'kronos/salepoints/DELETING_SALEPOINT';
const DELETING_SALEPOINT_SUCCESS = 'kronos/salepoints/DELETING_SALEPOINT_SUCCESS';
const DELETING_SALEPOINT_FAILURE = 'kronos/salepoints/DELETING_SALEPOINT_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    salepointSuccessfullyCreated: false,
    salepoints: []
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_SALEPOINTS:
            return {
                ...state,
                isLoading: true,
                salepointSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_SALEPOINTS_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                salepoints: action.salepoints
            };
        case LOADING_SALEPOINTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_SALEPOINT:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_SALEPOINT_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                salepoint: action.salepoint
            };
        case LOADING_SALEPOINT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_SALEPOINT:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_SALEPOINT_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                salepoint: action.salepoint
            };
        case UPDATING_SALEPOINT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_SALEPOINT:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                salepointSuccessfullyCreated: false,
                salepoint: {}
            };
        case CREATING_SALEPOINT:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_SALEPOINT_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                salepointSuccessfullyCreated: true,
                salepoint: action.salepoint
            };
        case CREATING_SALEPOINT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                salepointSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_SALEPOINT:
            return {
                ...state,
                isLoading: true,
                salepointSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case DELETING_SALEPOINT_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                salepointSuccessfullyDeleted: true,
                salepoint: action.salepoint
            };
        case DELETING_SALEPOINT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                salepointSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingSalepoints() { return { type: LOADING_SALEPOINTS } }
function loadingSalepointsSuccess(salepoints) { return { type: LOADING_SALEPOINTS_SUCCESS, salepoints: salepoints } }
function loadingSalepointsFailure(err) { return { type: LOADING_SALEPOINTS_FAILURE, error: err } }
export function loadSalepoints() {
    return (dispatch) => {
        dispatch(loadingSalepoints());
        Salepoints.list()
            .then(data => {
                const salepoints = data.salepoints;
                dispatch(loadingSalepointsSuccess(salepoints));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingSalepointsFailure(err))
            });
    }
}

function loadingSalepoint() { return { type: LOADING_SALEPOINT } }
function loadingSalepointSuccess(salepoint) { return { type: LOADING_SALEPOINT_SUCCESS, salepoint: salepoint } }
function loadingSalepointFailure(err) { return { type: LOADING_SALEPOINT_FAILURE, error: err } }
export function loadSalepoint(salepointId) {
    return (dispatch) => {
        dispatch(loadingSalepoint());
        Salepoints.get(salepointId)
            .then(data => {
                const salepoint = data.salepoint;
                dispatch(loadingSalepointSuccess(salepoint));
            })
            .catch(err => {
                dispatch(loadingSalepointFailure(err))
            });
    }
}

function updatingSalepoint() { return { type: UPDATING_SALEPOINT } }
function updatingSalepointSuccess(salepoint) { return { type: UPDATING_SALEPOINT_SUCCESS, salepoint: salepoint } }
function updatingSalepointFailure(err) { return { type: UPDATING_SALEPOINT_FAILURE, error: err } }
export function updateSalepoint(salepoint) {
    return (dispatch) => {
        dispatch(updatingSalepoint());
        Salepoints.update(salepoint)
            .then(data => {
                const salepoint = data.salepoint;
                dispatch(updatingSalepointSuccess(salepoint));
            })
            .catch(err => {
                dispatch(updatingSalepointFailure(err))
            });
    }
}

function creatingSalepoint() { return { type: CREATING_SALEPOINT } }
function creatingSalepointSuccess(salepoint) { return { type: CREATING_SALEPOINT_SUCCESS, salepoint: salepoint } }
function creatingSalepointFailure(err) { return { type: CREATING_SALEPOINT_FAILURE, error: err } }
export function startCreateSalepoint() { return { type: START_CREATING_SALEPOINT } }
export function createSalepoint(salepoint) {
    return (dispatch) => {
        dispatch(creatingSalepoint());
        Salepoints.create(salepoint)
            .then(data => {
                const salepoint = data.salepoint;
                dispatch(creatingSalepointSuccess(salepoint));
                dispatch(loadingSalepointSuccess(salepoint));
            })
            .catch(err => {
                dispatch(creatingSalepointFailure(err))
            });
    }
}

function deletingSalepoint() { return { type: DELETING_SALEPOINT } }
function deletingSalepointSuccess() { return { type: DELETING_SALEPOINT_SUCCESS } }
function deletingSalepointFailure(err) { return { type: DELETING_SALEPOINT_FAILURE, error: err } }
export function deleteSalepoint(salepointId) {
    return (dispatch) => {
        dispatch(deletingSalepoint());
        Salepoints.delete(salepointId)
            .then(data => {
                dispatch(deletingSalepointSuccess());
            })
            .catch(err => {
                dispatch(deletingSalepointFailure(err))
            });
    }
}
