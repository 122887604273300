import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ArticleForm from '../ArticleForm/ArticleForm';
import { PageTitle, Loader } from '../../../components';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { Utils } from '../../../services';

import { loadArticle, updateArticle } from '../../../redux/modules/articles';

class ArticleEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const articleId = this.props.match.params.articleId;
        // Load articles on component mount
        this.props.dispatchLoadArticle(articleId);
    }

    submit(values) {
        this.props.dispatchUpdateArticle(values);
    }

    render() {
        const articleId = this.props.match.params.articleId;
        const { article, isLoading } = this.props.articles;
        const { t } = this.props;

        const default_lang = Utils.default_lang();

        let title = <span>{t('articles.article')}</span>;
        if (article)
            title = (
                <span>
                    {t('articles.article')}
                    <small> |&nbsp;{ t('articles.update_article', { name: article.name[default_lang] })} </small>
                </span>
            );

        const actions =
        <div>
            {article &&
            <ButtonGroup>
                <Button tag={Link} to={ `/articles/show/${ article._id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="ArticleEdit">
                <PageTitle icon="th" title={ title } actions={ actions } />
                {(article && (article._id === articleId)) ? (
                    <ArticleForm onSubmit={this.submit} />
                ) : (
                    <Loader />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadArticle: (articleId) => loadArticle(articleId),
    dispatchUpdateArticle: (article) => updateArticle(article)
}

const mapStateToProps = state => ({
    articles: state.articles,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ArticleEdit))
