import RestClient from './RestClient';
import { Cashregister } from '../../models';

class Cashregisters extends RestClient {
    constructor() {
        super({
            "model": Cashregister,
            "entryPoint": "cashregister",
            "resource": "cashregister",
            "resources": "cashregisters",
            "id_field": "_id"
        });
    }
}

const CashregistersApi = new Cashregisters();

export default CashregistersApi;
