import BaseModel from './BaseModel';
import eventival from '../assets/images/integrations/eventival.png';

/** Class representing a Ref. */
class Ref extends BaseModel {
    icone = () => {
        if (this.source === 'eventival')
            return eventival;

        return null;
    }

    display = () => {
        return [this.id, this.name].filter(Boolean).join(' - ');
    }
}

export default Ref;
