import React, { Component } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';

import './PercentageField.css';

const PercentageField = ({ name }) => {
    const currency = Utils.currency();

    return (
        <div className="PercentageField">
            <InputGroup>
                <Field component="input" type="text" className="form-control" name={ name } pattern="[0-9]+([\.][0-9]{1,2})?"/>
                <InputGroupAddon addonType="append">
                    <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </div>
    );
}

export default PercentageField;
