import RestClient from './RestClient';
import { Application } from '../../models';

class Applications extends RestClient {
    constructor() {
        super({
            "model": Application,
            "entryPoint": "application",
            "resource": "application",
            "resources": "applications",
            "id": "id"
        });
    }

    accept(id) {
        return this.PUT(`/application/accept/${id}`)
            .then(response => response.json())
            .then(json => {
                if (json.application) {
                    json.application = new Application(json.application);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    reject(id) {
        return this.PUT(`/application/reject/${id}`)
            .then(response => response.json())
            .then(json => {
                if (json.application) {
                    json.application = new Application(json.application);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const ApplicationsApi = new Applications();

export default ApplicationsApi;
