import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, useParams } from "react-router-dom";
import { useTranslation, withTranslation } from 'react-i18next';
import { Utils } from '../../../services';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadLegacyScreening, deleteLegacyScreening } from '../../../redux/modules/legacyScreenings';

const OldScreeningDelete = () => {
    const dispatch = useDispatch();
    const { screeningId, programmationId } = useParams();
    const { t } = useTranslation();
    const default_lang = Utils.default_lang();
    const {
        legacyScreening,
        isLoading,
        legacyScreeningSuccessfullyDeleted
    } = useSelector(state => state.legacyScreenings);

    const goBack = () => {
        window.history.back();
    }

    useEffect(() => {
        dispatch(loadLegacyScreening(screeningId));
    }, [])

    const deleteScreening = (id) => {
        dispatch(deleteLegacyScreening(id));
    }

    let title = <span>{t("bulidings.screening_plural")}</span>;
    if (legacyScreening?.title)
        title = (
            <span>
                {t("screenings.screening")}
                <small> | {legacyScreening.title[default_lang] ?? legacyScreening.title.original ?? ""}</small>
            </span>
        );

    const actions =
        <Button onClick={goBack} color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
        </Button>

    if (legacyScreeningSuccessfullyDeleted && programmationId)
        return <Redirect to={`/programmations/show/${programmationId}/oldscreenings`} />;

    if (legacyScreeningSuccessfullyDeleted && !programmationId)
        return <Redirect to="oldscreenings" />;

    return (
        <div className="OldScreeningDelete">
            {legacyScreening &&
                <>
                    <PageTitle icon="th" title={title} actions={actions} />

                    {Object.keys(legacyScreening).length > 0 &&
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-warning"></i>&nbsp;{t('screenings.confirm_delete')}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("screenings.title")}</b>: {legacyScreening.title[default_lang] ?? legacyScreening.title.original ?? ""}</div>
                                            <Row>
                                                <Col className="text-center">
                                                    <Button onClick={goBack} color="warning">
                                                        <i className="fa fa-arrow-left"></i>&nbsp;{t('common.cancel')}
                                                    </Button>&nbsp;
                                                    <Button color="danger" onClick={(e) => deleteScreening(legacyScreening.id)} disabled={isLoading}>
                                                        {isLoading ?
                                                            <span>
                                                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                            </span>
                                                            :
                                                            <span>
                                                                <i className="fa fa-trash"></i>&nbsp;
                                                                {t('common.delete')}
                                                            </span>
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </>
            }
        </div>
    );
}

export default OldScreeningDelete;
