import React, { Component } from 'react';

import './FileUpload.css';

const FileUpload = props => {

    const onChange = e => {
        const { input: { onChange } } = props;
        onChange(e.target.files[0])
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const onFileChange = async (e) => {
        const { input } = props;
        const targetFile = e.target.files[0];
        if (targetFile) {
            const val = await getBase64(targetFile);
            input.onChange(val);
        } else {
            input.onChange(null);
        }
    }


    const { input: { name }, required } = props;

    return(
        <input
            className={` form-control ${props.classname || ''}`}
            name={ name }
            type='file'
            accept={ props.accept || '.jpg, .png, .jpeg' }
            onChange={ onFileChange }
            required={ !!required }
        />
    )
}

export default FileUpload;

