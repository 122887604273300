import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Host } from '../../../models';
import { loadCollectors, loadPrinters, loadEfts } from '../../../redux';
import Icon from '@mdi/react';
import { mdiFormatListText } from '@mdi/js';
import { HostResume, CollectorResume, PrinterResume, EftResume } from '../../../components';
import Diagram, { createSchema } from 'beautiful-react-diagrams';

import 'beautiful-react-diagrams/styles.css';
import './Map.css';

const Map = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { connected }  = useSelector(state => state.sioBridge);
    const { collectors } = useSelector(state => state.collectors);
    const { printers }   = useSelector(state => state.printers);
    const { efts }       = useSelector(state => state.efts);

    useEffect(() => {
        if (connected) {
            dispatch(loadCollectors());
            dispatch(loadPrinters());
            dispatch(loadEfts());
        }
    }, [dispatch, connected]);

    const hosts = {};
    const devices = [].concat(printers || [], efts || []).filter(d => d.wasRecentlyConnected());
    devices.forEach(device => {
        const { _id, name } = device.getHostInfos();
        if (!(_id in hosts))
            hosts[_id] = new Host({ _id, name });

        hosts[_id].connectDevice(device);
    });

    const nodes = [];
    const links = [];
    const { innerWidth: width } = window;
    const hostWidth = 200;
    const hostHeight = 235;
    const deviceWidth = 180;
    const deviceHeight = 210;
    const devicesGap = 50;
    const canvasPadding = 20;
    let x = 0;
    let y = 20;
    let nbDevicesOnRow = 0;
    let maxNbDevicesOnRow = parseInt((width - 250) / 280, 10);
    let reachedMaxNbDevicesOnRow = 0;
    let nbRows = 1;

    const deviceContent = device => {
        switch (device.type) {
            case 'collector':
                return <CollectorResume hideHost hideDetails collector={ device } style={{ width: deviceWidth + 'px' }} />
            case 'printer':
                return <PrinterResume hideHost hideDetails printer={ device } style={{ width: deviceWidth + 'px' }} />
            case 'eft':
                return <EftResume hideHost hideDetails eft={ device } style={{ width: deviceWidth + 'px' }} />
        }
    }

    hosts && Object.values(hosts).forEach((host, i) => {
        const connectedDevices = host.getConnectedDevices();
        const centerX = connectedDevices.length * (deviceWidth + devicesGap) / 2 - hostWidth / 2 - 5;
        x += centerX;
        nodes.push({
            id: host._id,
            content: <HostResume hideDetails host={ host } style={{ width: `${hostWidth}px` }}/>,
            coordinates: [x, y],
        });

        x += canvasPadding - centerX;
        y += hostHeight + devicesGap;
        connectedDevices.forEach(device => {
            nodes.push({
                id: device._id,
                content: deviceContent(device),
                coordinates: [x, y]
            });
            links.push({ input: host._id, output: device._id, className: device.isConnected() ? 'connected' : 'disconnected' });
            x += deviceWidth + devicesGap;
        });
        nbDevicesOnRow += connectedDevices.length;

        if (nbDevicesOnRow >= maxNbDevicesOnRow) {
            if (nbDevicesOnRow > reachedMaxNbDevicesOnRow)
                reachedMaxNbDevicesOnRow = nbDevicesOnRow;
            if (i < Object.values(hosts).length - 1) {
                x = 0;
                y += deviceHeight + devicesGap;
                nbDevicesOnRow = 0;
                nbRows ++;
            }
        } else {
            y -= (hostHeight + devicesGap);
        }
    });
    let schema = createSchema({ nodes, links });

    const actions = <Link to="/devices/list">
        <Button color="info">
            <Icon path={ mdiFormatListText } size={ 1 } color="white" /> { t('devices.list') }
        </Button>
    </Link>;
    const canvasDims = {
        width: `${reachedMaxNbDevicesOnRow * (deviceWidth + devicesGap) + 20}px`,
        minWidth: '100%',
        height: `${nbRows * (hostHeight + deviceHeight + 2 * devicesGap)}px`
    };
    const collectorStyle = {
        width: '200px',
        float: 'left',
        marginRight: '50px'
    };
    return (
        <div className="Map">
            <PageTitle
                title={ t('devices.title') }
                icon="cubes"
                actions= {actions }
            />
            <div style={ canvasDims }>
                { schema && (
                    <Diagram schema={schema} />
                )}
            </div>
            { collectors && collectors.length > 0 && (
            <>
                <br />
                <h4>
                    <i className="fa fa-money" />{' '}
                    {t('collectors.title')}
                </h4>
                <Row>
                    <Col>
                    { collectors.filter(c => c.isConnected()).map(collector => (
                        <CollectorResume key={ collector.id } collector={ collector } className="mb-3" style={ collectorStyle } />
                    ))}
                    { collectors.filter(c => !c.isConnected()).map(collector => (
                        <CollectorResume collector={ collector } className="mb-3" style={ collectorStyle } />
                    ))}
                    </Col>
                </Row>
            </>
            )}
        </div>
    );
}

export default Map;
