import React from 'react';
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import StatstypeList from './StatstypeList/StatstypesList';
import "./Stats.css";

const Stats = ({ match }) => {

    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.stats);

    return (
        <div className="Stats">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }

            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }
            <Row>
                <Col>
                    <Route exact path={ match.path } component={StatstypeList} />
                </Col>
            </Row>
        </div>
    );
}

export default Stats;
