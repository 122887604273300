import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import ResourceForm from '../ResourceForm/ResourceForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateResource, createResource } from '../../../redux/modules/resources';

class ResourceCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.props.dispatchStartCreateResource();
    }

    submit(values) {
        this.props.dispatchCreateResource(values);
    }

    render() {
        const { t }                           = this.props;
        const { resourceSuccessfullyCreated } = this.props.resources;

        let title = <span>{t('resources.add')}</span>;

        const actions =
            <Button tag={Link} to="/resources" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="ResourceCreate">
                <PageTitle icon="key" title={ title } actions={ actions } />

                {resourceSuccessfullyCreated &&
                    <Redirect to="/resources"/>
                }

                <ResourceForm onSubmit={this.submit} isCreation="true" />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateResource: (resource) => startCreateResource(resource),
    dispatchCreateResource: (resource) => createResource(resource)
}

const mapStateToProps = state => ({
    resources: state.resources,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ResourceCreate))
