import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux'

import './ItemsList.css';

const ItemsList = ({ title, items, onSelect, onEdit, horizontal }) => {
    const { isMobile, isTablet }        = useSelector(state => state.ui);
    const [selectState, setSelectState] = useState({selection: items?.length && items[0].selection, index: 0});

    if (!items)
        return null;

    const renderItem = (i, index) => {
        if (isMobile || isTablet)
            return (
                <option
                    key={i.key}
                    value={index}
                >
                    {i.title}
                </option>
            );
        if (!i.isButton)
            return (
                <div
                    key={i.key}
                    className={`list-item depth-${i.depth} ${i.active ? 'active' : ''}`}
                    onClick={() => { onSelect(i.selection, index) }}
                >
                    {i.icon && <i className={`fa fa-${i.icon}`}></i>}

                    {i.image && (
                            <img src={i.image} className="img rounded" />
                    )}

                    {i.title}

                    {onEdit && i.selection && (
                        <Button color="outline" size="xs" onClick={() => { onEdit(i.selection, index) }} >
                            <i className={`fa fa-pencil`}></i>
                        </Button>
                    )}
                </div>
            );
        return (
            <div key={i.key} className={`list-item depth-${i.depth}`} >
                <Button color="info" size="sm" block onClick={() => { onSelect(i.selection, index) }} >
                    <i className={`fa fa-${i.icon}`}></i>
                    {i.title}
                </Button>
            </div>
        );
    };

    const renderEditButtonResponsive = (selection, index) => {
        return (
            <Button color="primary" size="xs" onClick={() => { onEdit(selection, index) }} >
                <i className={`fa fa-pencil`}></i>
            </Button>
        );
    }

    return (
        <div className={`ItemsList ${horizontal ? 'horizontal' : ''}`}>
            <div className="list-wrapper">
                {title && (
                    <div className="list-title">
                        {title}
                    </div>
                )}
                {isMobile || isTablet ?
                    <div className={`d-flex responsive-itemslist${onEdit ? " editable" : ""}`}>
                        <select
                            className={`form-control`}
                            onChange={(e) => {
                                const index = e.target.value;
                                const selection = items[index].selection;
                                setSelectState({selection: selection, index: index})
                                onSelect(selection, index)
                            }}
                        >
                            {items.map((c, i) => renderItem(c, i))}
                        </select>
                        {onEdit &&
                            renderEditButtonResponsive(selectState.selection, selectState.index)
                        }
                    </div>
                    :
                    <>
                        {items.map((c, i) => renderItem(c, i))}
                    </>
                }
            </div>
        </div>
    );
}

export default ItemsList;
