import React from 'react';
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Form } from 'reactstrap';
import _ from 'lodash'

let ScreeningFormGeneralField = ({ handleSubmit, children }) => {
    return (
        <Form onSubmit={(values) => handleSubmit(values)}>
            { children }
        </Form>
    )
}

const mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ScreeningFormGeneralField = reduxForm({
    form: Math.random().toString(36).replace(/[^a-z]+/g, ''),
    enableReinitialize: true
})(ScreeningFormGeneralField)

// You have to connect() to any reducers that you wish to connect to yourself
ScreeningFormGeneralField = connect(
    state => ({
        initialValues: state.screenings.screening,
        screening: state.screenings.screening,
        places: state.places,
        destroyOnUnmount: false
    }), mapDispatchToProps
)(ScreeningFormGeneralField)

export default ScreeningFormGeneralField;
