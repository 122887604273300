import store from '..';
import { Bucketlists, Utils } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction
} = createRestSlices(Bucketlists);

const initialBucket = () => {
    return {
        _id: 'new_' + Utils.randomString(6),
        may_steal_from: [],
        total_capacity: 'total_capacity',
        rules: {
            not_after: 'stop_at'
        }
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case ADD_BUCKET:
            return {
                ...state,
                bucketlist: {
                    ...state.bucketlist,
                    buckets: [
                        ...state.bucketlist.buckets,
                        initialBucket()
                    ]
                }
            };
        case CHANGE_BUCKET:
            return {
                ...state,
                bucketlist: {
                    ...state.bucketlist,
                    buckets: state.bucketlist.buckets.map(bucket => {
                        return bucket._id !== action.bucket?._id ?  bucket : action.bucket;
                    })
                }
            };
        default:
            return state;
    }
};

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer,
        reducer
    ]);
}

/* Export CRUD actions */
export const startCreateBucketlist = startCreateAction;
export const createBucketlist      = createAction;
export const loadBucketlist        = getAction;
export const loadBucketlists       = listAction;
export const updateBucketlist      = updateAction;
export const deleteBucketlist      = deleteAction;
export const duplicateBucketlist   = duplicateAction;

const ADD_BUCKET = 'kronos/bucketlists/ADD_BUCKET';
export const addBucket = () => dispatch => {
    dispatch({ type: ADD_BUCKET });
};

const CHANGE_BUCKET = 'kronos/bucketlists/CHANGE_BUCKET';
export function changeBucket(bucket) {
    return (dispatch) => {
        return dispatch({ type: CHANGE_BUCKET, bucket });
    };
}
