import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import ChannelForm from '../ChannelForm/ChannelForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { startCreateChannel, createChannel } from '../../../redux/modules/channels';

const ChannelCreate = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startCreateChannel());
    }, [dispatch])

    const submit = (values) => {
        dispatch(createChannel(values));
    }

    const { channelSuccessfullyCreated } = useSelector(state => state.channels);

    let title = <span>{t('channels.add')}</span>;

    const actions =
        <Button tag={Link} to="/channels" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="ChannelCreate">
            <PageTitle icon="th" title={ title } actions={ actions } />

            {channelSuccessfullyCreated &&
                <Redirect to="/channels"/>
            }

            <ChannelForm onSubmit={ submit } isCreation="true" />
        </div>
    );
}

export default ChannelCreate
