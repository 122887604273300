import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button, Badge } from 'reactstrap';
import { Link } from "react-router-dom";
import { PageTitle, TableButtons, CreateButton, Table } from '../../../components';
import { loadTktUsers, deleteTktUser, loadSalepoints } from '../../../redux';
import { Filters } from '../../../services';
import i18n from '../../../locales/i18n';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import './TicketackUsersList.css';

const TicketackUsersList = () => {
    const { t }          = useTranslation();
    const dispatch       = useDispatch();
    const { tktusers }   = useSelector(state => state.tktusers);
    const { salepoints } = useSelector(state => state.salepoints);

    useEffect(() => {
        dispatch(loadTktUsers());
        dispatch(loadSalepoints());
    }, []);

    const prepareRoles = roles => roles?.map((role, index) => (
        <Badge key={index} style={{ margin: "3px" }} color="warning" pill>
            <i className="fa fa-tags" />&nbsp;{t("roles." + role)}
        </Badge>
    ));

    const prepareSalepoints = userSalepoints => (salepoints || [])
        .filter(s => userSalepoints.includes(s._id))
        .map((salepoint, index) => (
            <Badge key={index} style={{ margin: "3px" }} color="success" pill>
                <i className="fa fa-map-marker" />&nbsp;{ salepoint.name[i18n.language] }
            </Badge>
        ));

    const tableTktUsers = React.useMemo(() => tktusers?.map((tktuser, i) => {
        const rolesAndSalepoints = [
            ...tktuser.roles,
            ...(tktuser.roles.map(r => t(`roles.${r}`))),
            ...(salepoints?.filter(s => tktuser.salepoints?.includes(s._id))
                .map(s => s.name[i18n.language])
            )
        ];

        return {
            name: tktuser.name,
            roles: [
                <span key={i}>
                    {prepareRoles(tktuser.roles)}<br />
                    {prepareSalepoints(tktuser.salepoints)}
                </span>
            ],
            rolesAndSalepoints,
            _id: tktuser._id
        };
    }), [tktusers, i18n.language]);

    const actions =
        <div>
            {tktusers &&
                <CreateButton
                    module="ticketack_users"
                    text="tktUsers.add_a_ticketack_user"
                />
            }
        </div>

    const deleteTicketackUser = (id) => {
        if (window.confirm(t("tktUsers.delete_tktUser")))
            dispatch(deleteTktUser(id));
    }

    const roleAndSalepointsFilterMethod = (filter, user) => {
        return user._original.rolesAndSalepoints.some(f => f?.toLowerCase().includes(filter.value.toLowerCase()));
    };

    let columns = [{
        Header: t('tktUsers.name'),
        accessor: "name",
        filterMethod: Filters.textFilterMethod("name"),
        Filter: Filters.textFilter,
        filterAll: true,
        maxWidth: 300,
    }, {
        Header: t('tktUsers.roles_and_salepoints'),
        accessor: "roles",
        filterMethod: roleAndSalepointsFilterMethod,
    }, {
        Header: t('common.actions'),
        accessor: "_id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["show", "edit", "delete"]}
                module="ticketack_users"
            />
        ),
    }];

    return (
        <div className="TicketackUsersList">
            <PageTitle icon="users" title={t('tktUsers.tktUsers')} actions={actions} />
            <Table id="tktUsersTable" data={tableTktUsers} columns={columns} />
        </div>
    );
}

export default TicketackUsersList
