import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './ArticlesField.css';

const ArticlesField = ({ name, articles, onChange, fixedWidth, single }) => {
    const { t } = useTranslation();

    if (!articles)
        return null;

    const options = [];
    articles.forEach(article => {
        (article.variants || []).forEach(variant => {
            options.push({
                key: variant._id,
                text: `${Utils.localized_or_fallback(article.name, i18n.language)} (${Utils.localized_or_fallback(article.name, i18n.language)})`,
                value: variant._id
            });
        });
    });

    const onFieldChange = (value) => {
        return onChange ? onChange(value) : true;
    }

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            placeholder={ t('articles.choose_an_article') }
            onSelectChange={ onFieldChange }
            fixedWidth={ fixedWidth }
            clearable
        />
    ) : (
        <MultiSelectField
            name={ name }
            options={ options }
            placeholder={ t('articles.choose_an_article') }
            onSelectChange={ onFieldChange }
            fixedWidth={ fixedWidth }
        />
    );
}

export default ArticlesField;
