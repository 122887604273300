import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';
import CustomersList from './CustomersList/CustomersList';
import CustomerShow from './CustomerShow/CustomerShow';
import CustomerEdit from './CustomerEdit/CustomerEdit';
import CustomerCreate from './CustomerCreate/CustomerCreate';
import CustomerDelete from './CustomerDelete/CustomerDelete';

const Customers = ({ match }) => {
    const { t }                                               = useTranslation();
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.customers);
    const { setting }                                         = useSelector(state => state.settings);

    return (
        <div className="Customers">
            {isLoading &&
                <span className="loadingMessage">{t('common.loading')}</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{ __html: loadingErrorMessage }} />
            }
            {setting?.eshop?.registration?.enabled === false && 
                <Redirect to="/admin" />
            }
            <Route exact path={match.path} component={CustomersList} />
            <Route exact path={`${match.url}/new`} component={CustomerCreate} />
            <Route exact path={`${match.url}/show/:customerId`} component={CustomerShow} />
            <Route exact path={`${match.url}/edit/:customerId`} component={CustomerEdit} />
            <Route exact path={`${match.url}/delete/:customerId`} component={CustomerDelete} />
        </div>
    );
}

export default Customers;
