import BaseModel from './BaseModel';
import Ref from './Ref';
import _ from 'lodash';

/** Class representing a place. */
class Place extends BaseModel {
    constructor(properties) {
        super(properties);
        this.refs = (this.refs || []).map(ref => new Ref(ref));
    }
    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = _.cloneDeep(this);

        if (prepared.map && !prepared.map.seats)
            prepared.map['seats'] = [];

        if (prepared.coordinates) {
            prepared.coordinates.lat  = parseFloat(prepared.coordinates.lat || 0);
            prepared.coordinates.long = parseFloat(prepared.coordinates.long || 0);
        }

        return prepared;
    }
}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
Place.getListingFields = () => ['_id', 'cinema', 'name', 'number', 'opaque', 'city', 'refs'];

export default Place;
