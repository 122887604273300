import React from 'react';
import BaseModel from './BaseModel';
import ReactTimeAgo from 'react-time-ago';
import i18n from '../locales/i18n';
import moment from 'moment';

/** Class representing a generic device. */
class Device extends BaseModel {
    constructor(properties) {
        super(properties);

        if (this.last_connexion)
            this.last_connexion = moment(this.last_connexion);
    }

    isConnected() {
        return this.status === 'connected';
    }

    wasRecentlyConnected() {
        return moment().subtract(7, 'days').isBefore(this.last_connexion);
    }

    getLastConnectedAt() {
        return (
            <ReactTimeAgo
                date={ this.last_connexion.toDate() }
                locale={ i18n.language }
                timeStyle="round-minute"
            />
        );
    }

    getHostInfos() {
        return {
            _id: this.host_id,
            name: this.host
        };
    }
}

export default Device;
