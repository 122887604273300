import React from "react";
import FormLayout from '../FormLayout';
import TextField from './TextField';
import { Field } from 'redux-form';
import { Utils } from '../../services';

/** Class representing a PDF layout custom text field. */
class NumberField extends TextField {

    constructor(properties) {
        super({
            ...(NumberField.getDefaultOptions()),
            ...properties,
        });

        this.type = FormLayout.NUMBER;
    }

    /**
     * Get this field default options
     */
    static getDefaultOptions() {
        const properties = super.getDefaultOptions();
        Utils.langs().forEach(lang => { properties.label[lang] = "";  });

        properties.min  = -1000;
        properties.max  = 1000;
        properties.step = 1;

        return properties;
    }

    /**
     * Return the list of options this field handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            FormLayout.OPTION_MIN,
            FormLayout.OPTION_MAX,
            FormLayout.OPTION_STEP,
        ]);
    }

    /**
     * Return this field preview input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getInput(props) {
        return (
            <Field
                component="input"
                type="number"
                name={this.id}
                min={this.min}
                max={this.max}
                step={this.step}
                required={!!this.required}
                className="form-control"
            />
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'keyboard-o';
    }
}

export default NumberField;
