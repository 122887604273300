import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import TranslatableField from '../../../../TranslatableField/TranslatableField';
import PostCategoriesField from '../../../../PostCategoriesField/PostCategoriesField';

const DiscoverSettings = ({ env, screen, widget, postCategories }) => {
    const { t } = useTranslation();

    return (
        <>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.title') }
                </Label>
                <Col sm={ 8 }>
                    <TranslatableField
                        type="text"
                        component="input"
                        className="form-control"
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].title` }
                    />
                </Col>
            </FormGroup>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.post_category_id') }
                </Label>
                <Col sm={ 8 }>
                    <PostCategoriesField
                        postCategories={ postCategories }
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].categoryId` }
                    />
                </Col>
            </FormGroup>
        </>
    );
}

export default DiscoverSettings;
