import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';

import './TwintMethodField.css';

const TwintMethodField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.twintMethods().map(e => {
        return {
            key: e,
            text: t(`twint_methods.${e}`),
            value: e
        };
    });

    return <SelectField name={ name } options={ options }  clearable />;
}

export default TwintMethodField;
