import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './RegistrationCallbackModesField.css';

const  RegistrationCallbackModesField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.registrationCallbackModes().map(m => {
        return {
            key: m,
            text: t(`registration_callback.${m}`),
            value: m
        };
    });

    return <MultiSelectField name={ name } options={ options } />;
}

export default  RegistrationCallbackModesField;
