import React from "react";
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Label,
 } from 'reactstrap';
import { TranslatableField } from '../../../../../components';

const EmailLayoutOptions = () => {
    const { t } = useTranslation();

    return (
        <div className="EmailLayoutOptions">
            <Row>
                <Col>
                    <Label>{t('layouts.layout.name')}</Label>
                    <TranslatableField  data-for='layouts.name' required component="input" className="form-control" type="text" name="name" placeholder={t("layouts.layout.name_placeholder")} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>{t('layouts.layout.subject')}</Label>
                    <TranslatableField  data-for='layouts.subject' required component="input" className="form-control" type="text" name="subject" placeholder={t("layouts.layout.subject_placeholder")} />
                </Col>
            </Row>
        </div>
    );
}

export default EmailLayoutOptions;
