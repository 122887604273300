import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadScreening, deleteScreening } from '../../../redux/modules/screenings';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locales/i18n';

const ScreeningDelete = ({}) => {
    const dispatch        = useDispatch();
    const { t }           = useTranslation();
    const { screeningId } = useParams();
    const { 
        screening,
        isLoading,
        screeningSuccessfullyDeleted
    } = useSelector(state => state.screenings);

    const goBack = () => {
        window.history.back();
    }

    useEffect(() => {
        dispatch(loadScreening(screeningId));
    }, [])

    const _deleteScreening = (screeningId) => {
        dispatch(deleteScreening(screeningId));
    }

    let title = screening?.title ?
        <span>
            {t("screenings.screening")}
            <small> | {screening?.title[i18n.language] ?? screening?.title.original ?? ""} </small>
        </span> : ""


    const actions =
        <Button onClick={goBack} color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
        </Button>

    return (
        <div className="ScreeningDelete">
            <PageTitle icon="th" title={title} actions={actions} />

            {screeningSuccessfullyDeleted &&
                <Redirect to="/screenings" />
            }

            {screening?._id && screening?.title &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{t('screenings.confirm_delete')}</CardTitle>
                                <CardText tag="div">
                                    <div><b>{t("screenings.title")}</b>: {screening?.title[i18n.language] ?? screening?.title.original ?? ""}</div>
                                    <Row>
                                        <Col className="text-center">
                                            <Button onClick={goBack} color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => _deleteScreening(screening._id)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        {t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default ScreeningDelete;
