import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import moment from 'moment';

import { loadCustomer } from '../../../redux/modules/customers';

class CustomerShow extends Component<{}> {
    componentDidMount() {
        const { customerId } = this.props.match.params;
        // Load users on component mount
        this.props.dispatchLoadCustomer(customerId);
    }

    render() {
        const { customer } = this.props.customers;
        const { current_customer, t } = this.props;

        if (!current_customer || !customer)
            return null;

        const title = current_customer.id === customer.id ?
            <span>{t('users.my_profile')}</span>
            :
            <span>
                {t('users.user')}
                <small> | {customer.firstname} {customer.lastname}</small>
            </span>

        const actions =
            <div>
                <ButtonGroup>
                    <Button tag={Link} to="/customers" color="warning" size="sm">
                        <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                    </Button>
                    {customer &&
                        <Button tag={Link} to={`/customers/edit/${customer.id}`} color="info" size="sm" style={{ display: "none" }}>
                            <i className="fa fa-edit"></i>&nbsp;{t('common.edit')}
                        </Button>
                    }
                </ButtonGroup>
            </div>

        const birthday = moment(customer.contact?.birthdate).format('MM/DD/YYYY');

        return (
            <div className="CustomerShow">
                <PageTitle icon="customers" title={title} actions={actions} />
                {customer &&
                    <div>
                        <Row>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-user-circle"></i>&nbsp;
                                            {t('users.profile')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t('users.firstname')}</b>: {customer.contact?.firstname}</div>
                                            <div><b>{t('users.lastname')}</b>: {customer.contact?.lastname}</div>
                                            <div><b>{t('users.company')}</b>: {customer.contact?.company} </div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-envelope"></i>&nbsp;
                                            {t('users.contact')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t('users.email')}</b>: {customer.contact?.email}</div>
                                            <div><b>{t('users.fix_phone')}</b> : {customer.contact?.fix_phone}</div>
                                            <div><b>{t('users.mobile_phone')}</b> : {customer.contact?.cellphone}</div>
                                            <div><b>{t('users.pro_phone')}</b> : {customer.pro_phone}</div>
                                            <div><b>{t('users.fax')}</b> : {customer.fax}</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-address-card"></i>&nbsp;
                                            {t('users.address')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div>{customer.contact?.address?.street}</div>
                                            <div>{customer.contact?.address?.zip} {customer.contact?.address?.city}</div>
                                            <div>{customer.contact?.address?.country}</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col sm={12} lg={6}>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-th-list"></i>&nbsp;
                                            {t('users.other_informations')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t('users.birthday')}</b>: {birthday}</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadCustomer: (customerId) => loadCustomer(customerId)
}

const mapStateToProps = state => ({
    customers: state.customers,
    current_customer: state.customers.customer,
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(CustomerShow));
