import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import TranslatableField from '../../../components/TranslatableField/TranslatableField';
import { Field, reduxForm } from 'redux-form'

import moment from 'moment';
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('fr');
momentLocalizer();

let PhaseForm = (props) => {
    const { isLoading, phase } = useSelector(state => state.phases);
    const { t }                = useTranslation();
    const { handleSubmit, pristine, submitting, isCreation } = props;

    if (!phase)
        return null;

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("phases.informations")}</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t("phases.name")}</Label>
                                            <Col sm={12}>
                                            <TranslatableField name="name" placeholder={t("phases.name_placeholder")} component="input" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <FormGroup row>
                                                    <Label for="start_at" sm={12}>{t('phases.active_from')}</Label>
                                                    <Col>
                                                        <Field
                                                            component={DateTimePicker}
                                                            type="text"
                                                            name="start_at"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup row>
                                                    <Label for="stop_at" sm={12}>{t("phases.active_until")}</Label>
                                                    <Col>
                                                        <Field
                                                            component={DateTimePicker}
                                                            type="text"
                                                            name="stop_at"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <br/>
                <Row>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
        </Form>
    );

}

PhaseForm = reduxForm({
  form: 'phaseForm',
  enableReinitialize: true
})(PhaseForm);

PhaseForm = connect(
  state => ({
    initialValues: state.phases.phase,
    Phases: state.phases,
}), {}
)(PhaseForm)

export default PhaseForm;
