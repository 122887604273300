import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadPlace, deletePlace } from '../../../redux/modules/places';

class PlaceDelete extends Component<{}> {
    componentDidMount() {
        const placeId = this.props.match.params.placeId;
        // Load places on component mount
        this.props.dispatchLoadPlace(placeId);
    }

    deletePlace(placeId) {
        this.props.dispatchDeletePlace(placeId);
    }

    render() {
        const { place, isLoading, placeSuccessfullyDeleted } = this.props.places;
        const { t } = this.props;

        let title = <span>{t("bulidings.place_plural")}</span>;
        if (place)
            title = (
                <span>
                    {t("places.place")}
                    <small> | { place.name } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/places" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="PlaceDelete">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {placeSuccessfullyDeleted &&
                    <Redirect to="/places"/>
                }

                {place &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('places.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("places.name")}</b>: { place.name }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/places" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deletePlace(place._id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadPlace: (placeId) => loadPlace(placeId),
    dispatchDeletePlace: (placeId) => deletePlace(placeId)
}

const mapStateToProps = state => ({
    places: state.places
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(PlaceDelete))
