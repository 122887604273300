import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from '../SelectField/SelectField';
import { Utils } from '../../services';

const CustomerTypeField = ({ name }) => {
    const { t } = useTranslation();

    const options = Utils.customerTypes().map(type => ({
        key: type,
        value: type,
        text: t('settings.customer.types.' + type),
    }));

    return (
        <SelectField
            name={ name }
            options={ options }
            fluid
            placeholder={t('settings.customer.type_placeholder')}
            clearable
            selectOnNavigation
        />
    )
}

export default CustomerTypeField;
