// Redux Channels module
// see https://github.com/erikras/ducks-modular-redux

import { Channels } from '../../services/';

const LOADING_CHANNELS         = 'kronos/channels/LOADING_CHANNELS';
const LOADING_CHANNELS_SUCCESS = 'kronos/channels/LOADING_CHANNELS_SUCCESS';
const LOADING_CHANNELS_FAILURE = 'kronos/channels/LOADING_CHANNELS_FAILURE';
const LOADING_CHANNEL           = 'kronos/channels/LOADING_CHANNEL';
const LOADING_CHANNEL_SUCCESS   = 'kronos/channels/LOADING_CHANNEL_SUCCESS';
const LOADING_CHANNEL_FAILURE   = 'kronos/channels/LOADING_CHANNEL_FAILURE';
const UPDATING_CHANNEL          = 'kronos/channels/UPDATING_CHANNEL';
const UPDATING_CHANNEL_SUCCESS  = 'kronos/channels/UPDATING_CHANNEL_SUCCESS';
const UPDATING_CHANNEL_FAILURE  = 'kronos/channels/UPDATING_CHANNEL_FAILURE';
const START_CREATING_CHANNEL    = 'kronos/channels/START_CREATING_CHANNEL';
const CREATING_CHANNEL          = 'kronos/channels/CREATING_CHANNEL';
const CREATING_CHANNEL_SUCCESS  = 'kronos/channels/CREATING_CHANNEL_SUCCESS';
const CREATING_CHANNEL_FAILURE  = 'kronos/channels/CREATING_CHANNEL_FAILURE';
const DELETING_CHANNEL          = 'kronos/channels/DELETING_CHANNEL';
const DELETING_CHANNEL_SUCCESS  = 'kronos/channels/DELETING_CHANNEL_SUCCESS';
const DELETING_CHANNEL_FAILURE  = 'kronos/channels/DELETING_CHANNEL_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    channelSuccessfullyCreated: false,
    channels: []
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_CHANNELS:
            return {
                ...state,
                isLoading: true,
                channelSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_CHANNELS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                channels: action.channels
            };
        case LOADING_CHANNELS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_CHANNEL:
            return {
                ...state,
                channel: null,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                channel: action.channel
            };
        case LOADING_CHANNEL_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                channel: null,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_CHANNEL:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                channel: action.channel
            };
        case UPDATING_CHANNEL_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_CHANNEL:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                channelSuccessfullyCreated: false,
                channel: {}
            };
        case CREATING_CHANNEL:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                channelSuccessfullyCreated: true,
                channel: action.channel
            };
        case CREATING_CHANNEL_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                channelSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_CHANNEL:
            return {
                ...state,
                isLoading: true,
                channelSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case DELETING_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                channelSuccessfullyDeleted: true,
                channel: action.channel
            };
        case DELETING_CHANNEL_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                channelSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingChannels() { return { type: LOADING_CHANNELS } }
function loadingChannelsSuccess(channels) { return { type: LOADING_CHANNELS_SUCCESS, channels: channels } }
function loadingChannelsFailure(err) { return { type: LOADING_CHANNELS_FAILURE, error: err } }
export function loadChannels(channelname, password) {
    return (dispatch) => {
        dispatch(loadingChannels());
        Channels.list()
            .then(data => {
                const channels = data.channels;
                dispatch(loadingChannelsSuccess(channels));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingChannelsFailure(err))
            });
    }
}

function loadingChannel() { return { type: LOADING_CHANNEL } }
function loadingChannelSuccess(channel) { return { type: LOADING_CHANNEL_SUCCESS, channel: channel } }
function loadingChannelFailure(err) { return { type: LOADING_CHANNEL_FAILURE, error: err } }
export function loadChannel(channelId) {
    return (dispatch) => {
        dispatch(loadingChannel());
        Channels.get(channelId)
            .then(data => {
                const channel = data.channel;
                dispatch(loadingChannelSuccess(channel));
            })
            .catch(err => {
                dispatch(loadingChannelFailure(err))
            });
    }
}

function updatingChannel() { return { type: UPDATING_CHANNEL } }
function updatingChannelSuccess(channel) { return { type: UPDATING_CHANNEL_SUCCESS, channel: channel } }
function updatingChannelFailure(err) { return { type: UPDATING_CHANNEL_FAILURE, error: err } }
export function updateChannel(channel) {
    return (dispatch) => {
        dispatch(updatingChannel());
        Channels.update(channel)
            .then(data => {
                const channel = data.channel;
                dispatch(updatingChannelSuccess(channel));
            })
            .catch(err => {
                dispatch(updatingChannelFailure(err))
            });
    }
}

function creatingChannel() { return { type: CREATING_CHANNEL } }
function creatingChannelSuccess(channel) { return { type: CREATING_CHANNEL_SUCCESS, channel: channel } }
function creatingChannelFailure(err) { return { type: CREATING_CHANNEL_FAILURE, error: err } }
export function startCreateChannel() { return { type: START_CREATING_CHANNEL } }
export function createChannel(channel) {
    return (dispatch) => {
        dispatch(creatingChannel());
        Channels.create(channel)
            .then(data => {
                const channel = data.channel;
                dispatch(creatingChannelSuccess(channel));
                dispatch(loadingChannelSuccess(channel));
            })
            .catch(err => {
                dispatch(creatingChannelFailure(err))
            });
    }
}

function deletingChannel() { return { type: DELETING_CHANNEL } }
function deletingChannelSuccess() { return { type: DELETING_CHANNEL_SUCCESS } }
function deletingChannelFailure(err) { return { type: DELETING_CHANNEL_FAILURE, error: err } }
export function deleteChannel(channelId) {
    return (dispatch) => {
        dispatch(deletingChannel());
        Channels.delete(channelId)
            .then(data => {
                dispatch(deletingChannelSuccess());
            })
            .catch(err => {
                dispatch(deletingChannelFailure(err))
            });
    }
}
