// Redux Search module
// see https://github.com/erikras/ducks-modular-redux

const SET_TEXT_SEARCH = 'kronos/articles/SET_TEXT_SEARCH';
const SET_TAG_SEARCH  = 'kronos/articles/SET_TAG_SEARCH';

const initialState = {
    text: { scopes: {}},
    tags: { scopes: {}}
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case SET_TEXT_SEARCH:
            return {
                ...state,
                text: {
                    scopes: {
                        ...state.text.scopes,
                        [action.scope]: action.search
                    }
                }
            };
        case SET_TAG_SEARCH:
            return {
                ...state,
                tags: {
                    scopes: {
                        ...state.tags.scopes,
                        [action.scope]: action.tags
                    }
                }
            };
        default:
            return state;
    }
};

export function setTextSearch(scope, search) {
    return { type: SET_TEXT_SEARCH, scope, search };
}

export function setTagsSearch(scope, tags) {
    return { type: SET_TAG_SEARCH, scope, tags };
}
