import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, FormGroup, ButtonGroup, Button, Card, CardTitle, CardBody, CardFooter } from 'reactstrap';
import {SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Setting } from '../../../../models';
import { Field } from 'redux-form';
import SelectField from '../../../SelectField/SelectField';
import SettingField from '../../../SettingField/SettingField';
import { changeSetting } from '../../../../redux/modules/settings';
import { Utils } from '../../../../services';
import i18n from '../../../../locales/i18n';
import { arrayMoveMutable } from 'array-move';
import RichTextSettings from './RichTextSettings/RichTextSettings';
import FeaturedMovieSettings from './FeaturedMovieSettings/FeaturedMovieSettings';
import FeaturedScreeningSettings from './FeaturedScreeningSettings/FeaturedScreeningSettings';
import OnScreenMoviesSettings from './OnScreenMoviesSettings/OnScreenMoviesSettings';
import SectionSettings from './SectionSettings/SectionSettings';
import GenreSettings from './GenreSettings/GenreSettings';
import CarouselSettings from './CarouselSettings/CarouselSettings';
import NewsListSettings from './NewsListSettings/NewsListSettings';
import ProgramListSettings from './ProgramListSettings/ProgramListSettings';
import PostsGridSettings from './PostsGridSettings/PostsGridSettings';
import DiscoverSettings from './DiscoverSettings/DiscoverSettings';
import ShopSettings from './ShopSettings/ShopSettings';

import './ScreenWidgetsSettings.css';

const ScreenWidgetsSettings = (props) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { env, screen }    = props;
    const { isLoading } = useSelector(state => state.settings);

    const [chooserIsVisible, showChooser]           = useState(false);
    const [activeWidgetIndex, setActiveWidgetIndex] = useState(null);
    const [activeWidget, setActiveWidget]           = useState(null);

    if (!props.setting)
        return null;

    const widgets = (((props.setting.mobile[env])?.screens || {})[screen])?.widgets || [];

    const onSortEnd = ({oldIndex, newIndex}) => {
        const newSetting = { ...props.setting };
        arrayMoveMutable(((newSetting.mobile[env])?.screens[screen])?.widgets || [], oldIndex, newIndex);
        dispatch(changeSetting(newSetting));
    };

    const onEdit = (index) => {
        const widget = (props.setting.mobile[env])?.screens[screen].widgets[index];
        widget.index = index;
        setActiveWidget(widget);
    }

    const saveActiveWidget = (index) => {
        setActiveWidget(null);
        return true;
    }

    const onRemove = (index) => {
        const newSetting = { ...props.setting };
        const newWidgets = [].concat(newSetting.mobile[env].screens[screen].widgets || []);
        newWidgets.splice(index, 1);
        newSetting.mobile[env].screens[screen].widgets = newWidgets;
        dispatch(changeSetting(newSetting));
    }

    const onAdd = (type) => {
        const newSetting = { ...props.setting };
        const newWidgets = [].concat((((newSetting.mobile[env])?.screens || {})[screen])?.widgets || []);
        newWidgets.push(availableWidgets.find(widget => widget.type === type));
        newSetting.mobile = (newSetting.mobile || {});
        newSetting.mobile[env] = (newSetting.mobile[env] || {});
        newSetting.mobile[env].screens = (newSetting.mobile[env].screens || {});
        newSetting.mobile[env].screens[screen] = (newSetting.mobile[env].screens[screen] || {});
        newSetting.mobile[env].screens[screen].widgets = newWidgets;
        dispatch(changeSetting(newSetting));
        showChooser(false);
    }

    const availableWidgets = [
        { type: 'RichText' },
        { type: 'ProgramList' },
        { type: 'Shop' },
        { type: 'FeaturedMovie' },
        { type: 'FeaturedScreening' },
        { type: 'OnScreenMovies' },
        { type: 'Section' },
        { type: 'Genre' },
        { type: 'NewsList' },
        { type: 'Carousel' },
        { type: 'Discover' },
        { type: 'PostsGrid' },
    ];

    const widgetSpecificSettings = widget => {
        switch (widget.type) {
            case 'RichText':
                return <RichTextSettings screen={ screen } widget={ activeWidget } env={ env } />
            case 'ProgramList':
                return <ProgramListSettings screen={ screen } widget={ activeWidget } places={ props.places } env={ env } />
            case 'Shop':
                return <ShopSettings screen={ screen } widget={ activeWidget } articlecategories={ props.articlecategories } env={ env } />
            case 'FeaturedMovie':
                return <FeaturedMovieSettings screen={ screen } widget={ activeWidget } env={ env } />
            case 'FeaturedScreening':
                return <FeaturedScreeningSettings screen={ screen } widget={ activeWidget } env={ env } />
            case 'OnScreenMovies':
                return <OnScreenMoviesSettings screen={ screen } widget={ activeWidget } places={ props.places } env={ env } />
            case 'Carousel':
                return <CarouselSettings screen={ screen } widget={ activeWidget } postCategories={ props.postCategories } env={ env } />
            case 'NewsList':
                return <NewsListSettings screen={ screen } widget={ activeWidget } postCategories={ props.postCategories } env={ env } />
            case 'Discover':
                return <DiscoverSettings screen={ screen } widget={ activeWidget } postCategories={ props.postCategories } env={ env } />
            case 'PostsGrid':
                return <PostsGridSettings screen={ screen } widget={ activeWidget } postCategories={ props.postCategories } env={ env } />
            case 'Section':
                return <SectionSettings screen={ screen } widget={ activeWidget } env={ env } />
            case 'Genre':
                return <GenreSettings screen={ screen } widget={ activeWidget } env={ env } />
            case 'Shop':
                return <ShopSettings screen={ screen } widget={ activeWidget } articleCategories={ props.articleCategories } env={ env } />
        }

        return null;
    };

    const DragHandle  = SortableHandle(() => <i className="drag-handle fa fa-bars" />);
    const SorwidgetleTab = SortableElement(({ widget, sortIndex }) => (
        <Col sm={ 12 }>
            <Card className="widget mt-1">
                <DragHandle />
                <CardTitle>
                    { widget.name || t(`settings.mobile.screens.widget.${widget.type}.title`) } -&nbsp;
                    <small>{ t(`settings.mobile.screens.widget.${widget.type}.title`) }</small>
                    <ButtonGroup className="pull-right">
                        <Button color="info" size="sm" onClick={ () => onEdit(sortIndex) }>
                            <i className="fa fa-pencil"></i>&nbsp;{t("common.edit")}
                        </Button>
                        <Button color="danger" size="sm" onClick={ () => onRemove(sortIndex) }>
                            <i className="fa fa-trash"></i>
                        </Button>
                    </ButtonGroup>
                </CardTitle>
            </Card>
        </Col>
    ));

    const SorwidgetleGrid = SortableContainer(({ widgets }) => {
        return (
            <Row>
                { (widgets || []).map((widget, index) => <SorwidgetleTab key={ `item-${ index }` } index={ index } widget={ widget } sortIndex={ index }/>)}
            </Row>
        );
    });

    return (
        <div className="ScreenWidgetsSettings">
            <div className="small card-title">
                { t('settings.mobile.screens.widgets') }
                <Button className="pull-right btn btn-success" onClick={ () => showChooser(true) }>
                    <i className="fa fa-plus"></i>&nbsp;
                    { t('settings.mobile.screens.add_widget') }
                </Button>
            </div>
            <p className="desc">
                { t('settings.mobile.screens.widgets_desc') }
            </p>
            <SorwidgetleGrid
                widgets={ widgets }
                axis="y"
                onSortEnd={ onSortEnd }
                useDragHandle={ true }
            />

            { chooserIsVisible && (
            <div className="widget-chooser-backdrop" onClick={ () => showChooser(false) }>
                <div className="widget-chooser" onClick={ e => e.stopPropagation() }>
                    <Button className="close-btn" color="link" onClick={ () => showChooser(false)}>
                        <i className="fa fa-times" />
                    </Button>
                    <div className="small card-title">
                        { t('settings.mobile.screens.choose_a_widget') }
                    </div>
                    <div className="widget-choices">
                        { availableWidgets.map(widget => (
                        <div className="widget-choice" onClick={() => onAdd(widget.type)}>
                            <div className="widget-choice-title">
                                { t(`settings.mobile.screens.widget.${widget.type}.title`) }
                            </div>
                            <div className="widget-choice-desc">
                                { t(`settings.mobile.screens.widget.${widget.type}.description`) }
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
            )}

            { activeWidget && (
            <div className="widget-editor-backdrop" onClick={ () => setActiveWidget(null) }>
                <div className="widget-editor" onClick={ e => e.stopPropagation() }>
                    <div className="small card-title">
                        { t('settings.mobile.screens.edit_a_widget') }
                        <ButtonGroup className="pull-right">
                            <Button color="danger" size="sm" onClick={ () => setActiveWidget(null) }>
                                <i className="fa fa-times"></i>&nbsp;{t("common.close")}
                            </Button>
                            <Button color="success" disabled={ isLoading }>
                                <span>
                                    <i className={ isLoading ? 'fa fa-spinner fa-spin' : 'fa fa-save' }></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div className="widget-settings">

                        { /* Common settings */ }
                        <FormGroup row className="widget-setting">
                            <Label className="col-sm-4">
                                { t('settings.mobile.screens.widget.settings.name') }
                            </Label>
                            <Col sm={ 8 }>
                                <Field
                                    type="text"
                                    component="input"
                                    className="form-control"
                                    name={ `mobile.${env}.screens.${screen}.widgets[${activeWidget.index}].name` }
                                />
                            </Col>
                        </FormGroup>
                        { /* Widget specific settings */}
                        { widgetSpecificSettings(activeWidget) }
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}

export default ScreenWidgetsSettings;
