import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadTktInstance } from '../../../redux/modules/tktInstances';

class TktInstanceShow extends Component<{}> {
    componentDidMount() {
        const tktInstanceId = this.props.match.params.tktInstanceId;
        // Load tktInstances on component mount
        this.props.dispatchLoadTktInstance(tktInstanceId);
    }

    render() {
        const { tktInstance } = this.props.tktInstances;
        const { t } = this.props;

        let title = <span>{t("tktInstances.tktInstance_plural")}</span>;
        if (tktInstance)
            title = (
                <span>
                    {t("tktInstances.tktInstance")}
                    <small> | { tktInstance.name } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/tktInstances" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {tktInstance &&
                    <Button tag={Link} to={ `/tktInstances/edit/${ tktInstance.id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="TktInstanceShow">
                <PageTitle icon="ticket" title={ title } actions={ actions } />
                {tktInstance &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("tktInstances.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("tktInstances.name")}</b>: { tktInstance.name }</div>
                                            <div><b>{t("tktInstances.engine_url")}</b>: { tktInstance.engine_url }</div>
                                            <div><b>{t("tktInstances.solego_url")}</b>: { tktInstance.solego_url }</div>
                                            <div><b>{t("tktInstances.api_key")}</b>: { tktInstance.api_key }</div>
                                            <div><b>{t("tktInstances.email")}</b>: { tktInstance.email }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadTktInstance: (tktInstanceId) => loadTktInstance(tktInstanceId)
}

const mapStateToProps = state => ({
    tktInstances: state.tktInstances
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(TktInstanceShow))
