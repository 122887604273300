import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services';
import "./EditDashboard.css";
import widgetsList from "../WidgetsList.js";
import Widget from '../../../components/Widget/Widget';
import AddWidget from '../../../components/Widget/AddWidget/AddWidget';
import _ from "lodash";

const EditDashboard = ({ board, width, onDeleteWidget, showWidgetsList, showWidgetsSettings }) => {
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    //Called when user wants to switch from widget board to widget edition
    //Hover param if a widget is hovered (index of hovered widget)
    const editWidgetsHtml = (hover) => {
        let temp = [[]];
        //Display edit widgets board loop
        for (let i = 0; i < board.length; i++) {
            temp.push(
                <Col
                    sm={12}
                    md={board[i][1]?.width ? board[i][1].width * width: width}
                    key={i}
                >
                    <div
                        onMouseEnter={() => editWidgetsHover(i)}
                        onMouseLeave={() => editWidgetsHoverLeave()}
                    >
                        {(i !== hover) ?
                            //Is not hovered widget
                            React.createElement(widgetsList[board[i][0]].component, { props: {...board[i][1], noCompute: true}, border: "dashed" })
                            :
                            //Is hovered widget
                            <Widget
                                content={//Show delete, insert and edit buttons
                                    <div className="editWidgetDiv">
                                        <div className="insertWidgetButtonLeft">
                                            <Button color="secondary" className="rounded-0" onClick={() => showWidgetsList([i, "left"])}>
                                                <i className="fa fa-plus"></i>
                                            </Button>
                                        </div>
                                        <div className='modifyWidgetButton'>
                                            <Button color="danger" onClick={() => onDeleteWidget(i)}>
                                            <i className="fa fa-trash"></i>&nbsp;{t("widgets.delete")}
                                            </Button>
                                            <Button color="info" onClick={() => showWidgetsSettings([i, board[i][0], "editProps"])}>
                                            <i className="fa fa-pencil-square-o"></i>&nbsp;{t("widgets.settings_btn")}
                                            </Button>
                                        </div>
                                        {(i !== board.length - 1) &&
                                            <div className="insertWidgetButtonRight">
                                                <Button color="secondary" className="rounded-0" onClick={() => showWidgetsList([i, "right"])}>
                                                    <i className="fa fa-plus"></i>
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                }
                                noCenter
                            />}
                    </div>
                </Col >
            )
        }
        //Add extra AddWidget at end to expand dashboard widget at end
        temp.push(
            <Col
                className={`mb-3 col-${width}`}
                key={board.length}
            >
                <div onClick={() => showWidgetsList([board.length])}>
                    <AddWidget border clickable />
                </div>
            </Col>
        )
        let tempWithRow = <Row>{temp}</Row>
        return (tempWithRow)
    }

    //Called when user hovers in or out an existing widget in edit mode 
    //index = hovered widget
    const editWidgetsHover = (index) => {
        setHtml(editWidgetsHtml(index))
    }

    const editWidgetsHoverLeave = () => {
        setHtml(editWidgetsHtml())
    }

    const [html, setHtml] = useState(editWidgetsHtml());

    return (
        <div className='EditDashboard'>
            {html}
        </div>
    )
}

export default EditDashboard;
