import React from "react";
import PdfLayout from '../PdfLayout';
import PdfLayoutWidget from './PdfLayoutWidget';
import { Screen } from '../../services';

/** Class representing a PDF layout image widget. */
class FrameWidget extends PdfLayoutWidget {

    constructor(properties) {
        super({
            ...(FrameWidget.getDefaultOptions()),
            ...properties,
            type: PdfLayout.SHAPE,
            subType: PdfLayout.FRAME
        });
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 5, height: 7.5 },
            transform: { rotation: 0 },
            options: {
                style: {
                    backgroundColor: '#FFFFFF',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    borderColor: '#000000',
                    borderRadius: 0
                }
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            PdfLayout.OPTION_BACKGROUND_COLOR,
            PdfLayout.OPTION_BORDER_COLOR,
            PdfLayout.OPTION_BORDER_STYLE,
            PdfLayout.OPTION_BORDER_WIDTH,
            PdfLayout.OPTION_BORDER_RADIUS
        ]);
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        const { inEdition } = props;

        return (
            <div style={{
                top: Screen.cmToPx(this.position.y),
                left: Screen.cmToPx(this.position.x),
                width: Screen.cmToPx(this.dimensions.width),
                height: Screen.cmToPx(this.dimensions.height),
                backgroundColor: this.options?.style?.backgroundColor,
                border: `${this.options?.style?.borderWidth}px ${this.options?.style?.borderStyle} ${this.options?.style?.borderColor}`,
                borderRadius: `${this.options?.style?.borderRadius || 0}px`
            }} />
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'square-o';
    }
}

export default FrameWidget;
