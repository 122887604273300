import BaseModel from './BaseModel';
import { User } from '../models';
import moment from 'moment';

/** Class representing a user availability. */
class Availability extends BaseModel {

    constructor(properties) {
        super(properties);

        if (this.start_at)
            this.start_at = moment(this.start_at);

        if (this.stop_at)
            this.stop_at = moment(this.stop_at);

        if (this.user)
            this.user = new User(this.user);
    }
}

export default Availability;
