import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Icon from '@mdi/react';
import { fillin, stopFillin } from '../../../../../../redux';
import { mdiArrowUpBoldBox } from '@mdi/js';
import { Loader, MoneyValue } from '../../../../../../components';
import _ from 'lodash';

const FillinButton = ({ collector }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const [isModalVisible, showModal] = useState(false);
    const toggleModal = () => {
        if (!isModalVisible && !collector.isFilling())
            doFillin();

        showModal(!isModalVisible);
    };

    const filling = collector.state.lastFilling;

    const doFillin = () => {
        dispatch(fillin(collector.id));
    }

    const doStopFillin = () => {
        dispatch(stopFillin(collector.id));
        toggleModal();
    }

    return (
        <div className="FillinButton">
            <Button onClick={ toggleModal } className="operation" color="success" block>
                <div className="icon">
                    <Icon path={ mdiArrowUpBoldBox } size={ 3 } color="#FFFFFF" />{ ' ' }
                </div>
                <div className="title">
                    { t('collectors.fillin') }
                    <div className="subtitle">{ t('collectors.fillin_desc') }</div>
                </div>
            </Button>
            <Form className="operation-form fillin-form">
                <Modal isOpen={isModalVisible} toggle={toggleModal} size="lg">
                    <ModalHeader toggle={toggleModal}>
                        <Icon path={ mdiArrowUpBoldBox } size={ 1 } color="#FFFFFF" />{ ' ' }
                        { t('collectors.fillin_desc') }
                    </ModalHeader>
                    <ModalBody>
                        {(!filling || !filling.inserted) && (
                            <Loader />
                        )}
                        { filling && filling.inserted && (
                            <>
                                <div className="form-body">
                                    <h4>{ t('collectors.inserted') }</h4>
                                    <Row>
                                        {filling && filling.inserted && _.keys(filling.inserted).map(coin => (
                                            <Col key={ coin } sm="3">
                                                <FormGroup key={ coin }>
                                                    <MoneyValue
                                                        title={ `${coin} CHF` }
                                                        value={ filling.inserted[coin] }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>
                                    <h4>{ t('collectors.in_the_recycler') }</h4>
                                    <Row>
                                        {filling && filling.recycler && _.keys(filling.recycler).map(coin => (
                                            <Col key={ coin } sm="3">
                                                <FormGroup key={ coin }>
                                                    <MoneyValue
                                                        title={ `${coin} CHF` }
                                                        value={ filling.recycler[coin] }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>
                                    <h4>{ t('collectors.in_the_cashbox') }</h4>
                                    <Row>
                                        {filling && filling.cashbox && _.keys(filling.cashbox).map(bill => (
                                            <Col key={ bill } sm="3">
                                                <FormGroup className="form-group" key={ bill }>
                                                    <MoneyValue
                                                        title={ `${bill} CHF` }
                                                        value={ filling.cashbox[bill] }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                                <div className="text-center">
                                    <h3>Total: { filling.getTotal() }</h3>
                                </div>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={ () => { doStopFillin() }}>
                            <Icon path={ mdiArrowUpBoldBox } size={ 1 } color="#FFFFFF" />{ ' ' }
                            { t('collectors.validate_fillin') }
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>{ t('common.close') }</Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </div>
    );
}

export default FillinButton;
