import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';
import defaultTheme from '../theme';
import { merge } from 'lodash';
import { useSelector } from 'react-redux';

const BarChart = (props) => {
    const { KUserSettings } = useSelector(state => state.userSettings);
    const {
        data,
        keys,
        axisBottomLegend,
        axisBottomTickRotation,
        axisLeftLegend,
        axisLeftFormat,
        labelFormat,
        tooltipFormat,
        enableLabel,
        theme,
        scheme,
        layout,
        labelTextColor,
        label,
        noLegend,
        customMargin
    } = props;

    const axisBottom = {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: axisBottomTickRotation,
        legend: axisBottomLegend,
        legendPosition: 'middle',
        legendOffset: 45
    };

    const axisLeft = axisLeftLegend ? {
        tickSize: 5,
        tickPadding: 5,
        legend: axisLeftLegend,
        legendPosition: 'middle',
        legendOffset: -45,
        format: axisLeftFormat,
    } : null;

    const legends = [{
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemTextColor: KUserSettings.darkTheme ? '#fff' : '#000',
    }];

    const marginBottom      = axisBottomTickRotation === 0 ? 50 : 100;
    const chartTheme        = merge(KUserSettings.darkTheme ? defaultTheme.dark : defaultTheme.light, theme);
    const optional          = {};

    if (!noLegend)
        optional.legends = legends;

    return (

        <ResponsiveBar
            data={data}
            keys={keys}
            padding={0.3}
            margin={
            !customMargin ?
                {
                    top: 30,
                    right: 130,
                    bottom: marginBottom,
                    left: 60
                } :
                {
                    top: customMargin,
                    right: customMargin,
                    bottom: marginBottom,
                    left: customMargin
                }
            }
            colors={{ scheme: scheme }}
            theme={chartTheme}
            axisBottom={axisBottom}
            axisLeft={axisLeft}
            labelSkipWidth={12}
            labelSkipHeight={12}
            tooltipFormat={tooltipFormat}
            labelFormat={labelFormat}
            enableLabel={enableLabel}
            labelTextColor={labelTextColor}
            layout={layout}
            label={label}
            {...optional}
        />
    );
}

BarChart.defaultProps = {
    axisBottomLegend: null,
    axisBottomTickRotation: 0,
    axisBottomFormat: undefined,
    axisLeftLegend: null,
    axisLeftFormat: undefined,
    labelTextColor: "white",
    enableLabel: true,
    theme: {},
    scheme: 'category10',
};

BarChart.propTypes = {
    axisBottomLegend: PropTypes.string,
    axisBottomTickRotation: PropTypes.number,
    axisBottomFormat: PropTypes.func,
    axisLeftLegend: PropTypes.string,
    axisLeftFormat: PropTypes.func,
    enableLabel: PropTypes.bool,
    theme: PropTypes.object,
    scheme: PropTypes.string,
};

export default BarChart;
