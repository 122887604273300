import React, { useState, useEffect, useCallback }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import { Row, Col, Button } from "reactstrap";
import { resetShift, loadShift, createShift, updateShift } from '../../redux';
import ShiftForm from './ShiftForm';
import moment from 'moment';

import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import './Planner.css';

const DnDCalendar = withDragAndDrop(Calendar)

moment.locale('fr');
const localizer = momentLocalizer(moment);

const Event = ({ event, title }) => {
    const { t } = useTranslation();

    return (
        <div className="event">
            <div className="my-2">
                <b className="no-wrap">
                    <i className="fa fa-user" />&nbsp;
                    { event.shift.nb_assigned } / { event.shift.nb_volunteers }
                </b>
            </div>
        </div>
    );
}

const Planner = ({ resources, tasks, sectors, activities, currentDate }) => {
    const dispatch    = useDispatch();
    const { t, i18n } = useTranslation();

    const [ date, setDate ]                 = useState(currentDate);
    const [ currentEvent, setCurrentEvent ] = useState(null);

    const { shift, shifts, isLoadingOne, loadingErrorMessage } = useSelector(state => state.shifts);

    useEffect(() => {
        if (currentDate)
            setDate(currentDate)
    }, [ currentDate ]);

    const messages = React.useMemo(() => ({
        week: t('plan.calendar.week'),
        work_week: t('plan.calendar.work_week'),
        day: t('plan.calendar.day'),
        month: t('plan.calendar.month'),
        previous: t('plan.calendar.previous'),
        next: t('plan.calendar.next'),
        today: t('plan.calendar.today'),
        agenda: t('plan.calendar.agenda'),
        showMore: (total) => t('plan.calendar.showMore', { total })
    }), [i18n.language]);

    const eventPropGetter = useCallback(
        (event, start, end, isSelected) => ({
            ...((event.shift.nb_assigned === 0 && event.shift.nb_volunteers > 0) && {
                className: 'empty',
            }),
            ...((event.shift.nb_assigned > 0 && event.shift.nb_assigned < event.shift.nb_volunteers) && {
                className: 'filling',
            }),
            ...((event.shift.nb_assigned === event.shift.nb_volunteers) && {
                className: 'full',
            }),
        }),
        []
    )

    const onNavigate = (date, b, c) => {
        setDate(date);
    }

    const selectShift = shiftId => {
        dispatch(shiftId ? loadShift(shiftId) : resetShift());
    }

    const onSelect = event => {
        selectShift(null);
        setTimeout(() => selectShift(event.id), 100)
    };

    const onDragStart = data => {
        const { event } = data;
        selectShift(event.id);
    }

    const onEventResize = ({ start, end }) => {
        dispatch(updateShift({
            id: shift.id,
            start_at: start,
            stop_at: end
        }, (err, shift) => {}));
    };

    const onEventDrop = ({ start, end }) => {
        dispatch(updateShift({
            id: shift.id,
            start_at: start,
            stop_at: end
        }, (err, shift) => {}));
    };

    const updateEvent = (values) => {
        dispatch(updateShift({ ...values }));
    }

    const newEvent = React.useCallback(
        event => {
            // prevent creating a new event if the events details are open
            if (shift)
                return selectShift(null);

            const params = {
                start_at: event.start,
                stop_at: event.end,
                sector_id: event.resourceId,
                nb_volunteers: 0
            };
            if (activities?.length === 1)
                params.activity_id = activities[0].id;
            dispatch(createShift(params));
        },
        [dispatch, shift]
    );

    return (
        <div className="Planner">
            {loadingErrorMessage &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }

            <DnDCalendar
                culture='fr'
                localizer={ localizer }
                events={ tasks }
                startAccessor="start"
                endAccessor="end"
                defaultView={ Views.DAY }
                date={ date }
                showMultiDayTimes
                draggableAccessor={event => true}
                onNavigate={ onNavigate }
                onSelectEvent={ onSelect }
                onDragStart={ onDragStart }
                onEventResize={ onEventResize }
                onEventDrop={ onEventDrop }
                onSelectSlot={ newEvent }
                scrollToTime={ currentDate }
                step={ 15 }
                resizable
                selectable
                dayLayoutAlgorithm="no-overlap"
                messages={ messages }
                resources={ resources }
                components={{ event: Event }}
                eventPropGetter={ eventPropGetter }
            />
            { shift && (
                <div className="event-details-wrapper">
                    <div className="event-details-content">
                        <ShiftForm
                            onSubmit={ updateEvent }
                            onRemove={ err => !err && selectShift(null) }
                            sectors={ sectors }
                            activities={ activities }
                        />
                    </div>
                    <div className="event-details-closer">
                        <Button color="danger" size="md" onClick={() => selectShift(null) }>
                            <i className="fa fa-times" />
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Planner;
