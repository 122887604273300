import store from '..';
import { ApplicationTypes } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction,
    linkedResourcesReducer, linkedResourcesAction
} = createRestSlices(ApplicationTypes);

initialState.form = null;

const LOADING_APPLICATION_FORM         = 'kronos/tktInstances/LOADING_APPLICATION_FORM';
const LOADING_APPLICATION_FORM_SUCCESS = 'kronos/tktInstances/LOADING_APPLICATION_FORM_SUCCESS';
const LOADING_APPLICATION_FORM_FAILURE = 'kronos/tktInstances/LOADING_APPLICATION_FORM_FAILURE';
const reducer = (state = initialState, action) => {
    switch(action.type) {
        case LOADING_APPLICATION_FORM:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                form: null
            };
        case LOADING_APPLICATION_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                form: action.form
            };
        case LOADING_APPLICATION_FORM_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
                form: null
            };
        default:
            return state;
    }
};

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer,
        linkedResourcesReducer, reducer
    ]);
}

function loadingApplicationForm() { return { type: LOADING_APPLICATION_FORM } }
function loadingApplicationFormSuccess(form) { return { type: LOADING_APPLICATION_FORM_SUCCESS, form } }
function loadingApplicationFormFailure(err) { return { type: LOADING_APPLICATION_FORM_FAILURE, error: err } }
const loadApplicationFormAction = (application_type_id, tkt_instance_id) => {
    return (dispatch) => {
        dispatch(loadingApplicationForm());
        ApplicationTypes.getForm(application_type_id, tkt_instance_id)
            .then(data => {
                const { form } = data;
                dispatch(loadingApplicationFormSuccess(form));
            })
            .catch(err => {
                dispatch(loadingApplicationFormFailure(err))
            });
    }
};

/* Export CRUD actions */
export const startCreateApplicationType         = startCreateAction;
export const createApplicationType              = createAction;
export const loadApplicationType                = getAction;
export const loadApplicationTypes               = listAction;
export const updateApplicationType              = updateAction;
export const deleteApplicationType              = deleteAction;
export const duplicateApplicationType           = duplicateAction;
export const loadApplicationForm                = loadApplicationFormAction;
export const getApplicationTypesLinkedResources = linkedResourcesAction;
