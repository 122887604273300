import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';

import './PrinterProfilesField.css';

const PrinterProfilesField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.getPrinterProfiles().map(profile => {
        return {
            key: profile,
            text: t(`printers.profiles.${profile}`),
            value: profile
        };
    });

    return (
        <SelectField
            name={ name }
            options={ options }
        />
    );
}

export default PrinterProfilesField;
