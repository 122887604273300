import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import { Field } from 'redux-form';
import { Utils } from '../../../../../services';
import PercentageField from '../../../../PercentageField/PercentageField';
import PlacesField from '../../../../PlacesField/PlacesField';

const ProgramListSettings = ({ env, screen, widget, places }) => {
    const { t } = useTranslation();

    return (
        <>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.show_only_future_screenings') }
                </Label>
                <Col sm={ 8 }>
                <Field
                    component="select"
                    className="form-control"
                    type="text"
                    name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].onlyFuture` }
                    normalize={Utils.normalizeBoolean}
                >
                    <option key="false" value={false}>{t("common.no")}</option>
                    <option key="true" value={true}>{t("common.yes")}</option>
                </Field>
                </Col>
            </FormGroup>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.nb_max_days') }
                </Label>
                <Col sm={ 8 }>
                    <Field
                        component="input"
                        type="number"
                        className="form-control"
                        step={ 1 }
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].nbMaxDays` }
                    />
                </Col>
            </FormGroup>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.display_mode') }
                </Label>
                <Col sm={ 8 }>
                    <Field
                        component="select"
                        className="form-control"
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].renderer` }
                    >
                        <option value="">---</option>
                        <option value="landscape">
                            {t('settings.mobile.screens.widget.settings.renderer_landscape')}
                        </option>
                        <option value="portrait">
                            {t('settings.mobile.screens.widget.settings.renderer_portrait')}
                        </option>
                    </Field>
                </Col>
            </FormGroup>
            { (widget.renderer === 'portrait') && (
            <>
                <FormGroup row className="widget-setting">
                    <Label className="col-sm-4">
                        { t('settings.mobile.screens.widget.settings.image_width') }
                    </Label>
                    <Col sm={ 8 }>
                        <PercentageField
                            name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].imageWidth` }
                        />
                    </Col>
                </FormGroup>
                <FormGroup row className="widget-setting">
                    <Label className="col-sm-4">
                        { t('settings.mobile.screens.widget.settings.image_height_ratio') }
                    </Label>
                    <Col sm={ 8 }>
                        <Field
                            component="input"
                            type="number"
                            className="form-control"
                            step={ 0.01 }
                            name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].imageHeightRatio` }
                        />
                    </Col>
                </FormGroup>
            </>
            )}
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.filter_on_places') }
                </Label>
                <Col sm={ 8 }>
                    <PlacesField
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].places` }
                        places={ places }
                    />
                </Col>
            </FormGroup>
        </>
    );
}

export default ProgramListSettings;
