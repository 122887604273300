import store from '..';
import { Collector } from '../../models';
import { Collectors, SioBridge } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const { initialState, getReducer, listReducer, getAction, listAction } = createRestSlices(Collectors);

const LOADING         = `kronos/collector/LOADING`;
const LOADING_SUCCESS = `kronos/collector/LOADING_SUCCESS`;
const LOADING_FAILURE = `kronos/collector/LOADING_FAILURE`;

const reducer = (state, action) => {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
            };
        case LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false
            };
        case LOADING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
            };
        default:
            return state;
    }
};

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [ getReducer, listReducer, reducer ]);
}

function loading() { return { type: LOADING } }
function loadingSuccess(status) { return { type: LOADING_SUCCESS, status } }
function loadingFailure(err) { return { type: LOADING_FAILURE, error: err } }

/* Export CRUD actions */
export const loadCollector      = getAction;
export const loadCollectors     = listAction;
export const getCollectorStatus = (id) => {
    return (dispatch) => {
        dispatch(loading());
        return Collectors.getStatus(id)
            .then(data => {
                const status = { data };
                dispatch(loadingSuccess(status));
            })
            .catch(err => {
                dispatch(loadingFailure(err))
            });
    }
};
export const getCollectorStats = (id) => {
    return (dispatch) => {
        dispatch(loading());
        return Collectors.getStats(id)
            .then(data => {
                const stats = { data };
                dispatch(loadingSuccess(stats));
            })
            .catch(err => {
                dispatch(loadingFailure(err))
            });
    }
};

export const debit = (id, amount) => {
    return (dispatch) => {
        return Collectors.debit(id, amount)
            .then(data => {
            })
            .catch(err => {
            });
    }
};

export const startChange = (id, amount) => {
    return (dispatch) => {
        return Collectors.startChange(id, amount)
            .then(data => {
            })
            .catch(err => {
            });
    }
};

export const addChangeCoinsConfiguration = (id, coin, quantity) => {
    return (dispatch) => {
        return Collectors.addChangeCoinsConfiguration(id, coin, quantity)
            .then(data => {
            })
            .catch(err => {
            });
    }
};

export const addChangeBillsConfiguration = (id, bill, quantity) => {
    return (dispatch) => {
        return Collectors.addChangeBillsConfiguration(id, bill, quantity)
            .then(data => {})
            .catch(err => {
            });
    }
};

export const finishChange = (id) => {
    return (dispatch) => {
        return Collectors.finishChange(id)
            .then(data => {
            })
            .catch(err => {
            });
    }
};

export const refund = (id, amount) => {
    return (dispatch) => {
        return Collectors.refund(id, amount)
            .then(data => {
            })
            .catch(err => {
            });
    }
};

export const drainCoins = (id, coin, quantity) => {
    return (dispatch) => {
        return Collectors.drainCoins(id, coin, quantity)
            .then(data => {
            })
            .catch(err => {
            });
    }
};

export const drainBills = (id, bill, quantity) => {
    return (dispatch) => {
        return Collectors.drainBills(id, bill, quantity)
            .then(data => {})
            .catch(err => {
            });
    }
};

export const resetChest = (id) => {
    return (dispatch) => {
        return Collectors.resetChest(id)
            .then(data => {
            })
            .catch(err => {
            });
    }
};

export const unloadCoins = (id, coin, quantity) => {
    return (dispatch) => {
        return Collectors.unloadCoins(id, coin, quantity)
            .then(data => {
            })
            .catch(err => {
            });
    }
};

export const unloadBills = (id, bill, quantity) => {
    return (dispatch) => {
        return Collectors.unloadBills(id, bill, quantity)
            .then(data => {})
            .catch(err => {
            });
    }
};

export const fillin = (id) => {
    return (dispatch) => {
        return Collectors.fillin(id)
            .then(data => {})
            .catch(err => {
            });
    }
};

export const stopFillin = (id) => {
    return (dispatch) => {
        return Collectors.stopFillin(id)
            .then(data => {})
            .catch(err => {
            });
    }
};

export const autotest = (id) => {
    return (dispatch) => {
        return Collectors.autotest(id)
            .then(data => {
            })
            .catch(err => {
            });
    }
};

SioBridge.on('collectors_update', (data) => {
    let connectedCollectors = data.map(collector => new Collector(collector));

    const storeCollectors = (store.getState().collectors.collectors || []).map(collector => {
        collector.status = 'disconnected';
        connectedCollectors.forEach(connectedCollector => {
            if (connectedCollector._id === collector._id) {
                collector.status = 'connected';
                connectedCollectors = connectedCollectors.filter(connectedCollector => connectedCollector._id !== collector._id);
            }
        });

        return collector;
    });
    const collectors = storeCollectors.concat(connectedCollectors);
    store.dispatch({
        type: 'kronos/collectors/LOADING_ITEMS_SUCCESS',
        collectors
    });
});
