import React from "react";
import FormLayout from '../FormLayout';
import TextField from './TextField';
import { ToggleField } from '../../components';

/** Class representing a PDF layout custom text field. */
class BooleanField extends TextField {

    constructor(properties) {
        super({
            ...(BooleanField.getDefaultOptions()),
            ...properties,
        });

        this.type = FormLayout.BOOLEAN;
    }

    /**
     * Return the list of options this field handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            FormLayout.OPTION_ROWS
        ]);
    }

    /**
     * Return this field preview input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getInput(props) {
        return (
            <ToggleField name={ this.id } />
        );
    }

    /**
     * Render a value
     *
     * @param {Any} value
     * @param {Object} context: an object with the following keys:
     *                   - lang
     *                   - t
     *
     * @return {String}
     */
    renderValue(value, context) {
        if (!('t' in context))
            return !!value ? 'Yes' : 'No';

        return context.t(`common.${!!value ? 'yes' : 'no'}`);
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'thumbs-o-up';
    }
}

export default BooleanField;
