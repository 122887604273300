import React, { useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux'
import { Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ItemsList } from '../../../components';
import { reduxForm } from 'redux-form'
import _ from 'lodash'
import { ScreeningFormBuckets, ScreeningFormInfo, ScreeningFormLogs, ScreeningFormPricings, ScreeningFormBookings, ScreeningFormMap } from './ScreeningFormComponents';

import './ScreeningForm.css';

let ScreeningForm = ({ places, setting, tickets, logs, isLoading, handleSubmit, isCreation }) => {
    const { t }                     = useTranslation();
    const [selectKey, setSelectKey] = useState(0);
    const { screening }             = useSelector(state => state.screenings);

    useEffect(() => {
        makeList(selectKey);
    }, [selectKey]);

    useEffect(() => {
        makeList(0);
    }, []);

    const onSelect = (o) => {
        setSelectKey(o);
    }

    const makeList = (key) => {
        if (isCreation)
            return [];

        let itemList = [
            {
                active: (key === 0) ? true : false,
                depth: 0,
                icon: "address-card",
                key: "general",
                selection: 0,
                title: t("screenings.edit.itemlist.general")
            }, {
                active: (key === 1) ? true : false,
                depth: 0,
                icon: "bookmark",
                key: "bucket",
                selection: 1,
                title: t("screenings.edit.itemlist.bucket")
            }, {
                active: (key === 2) ? true : false,
                depth: 0,
                icon: "money",
                key: "pricing",
                selection: 2,
                title: t("screenings.edit.itemlist.pricing")
            }, {
                active: (key === 3) ? true : false,
                depth: 0,
                icon: "ticket",
                key: "booking",
                selection: 3,
                title: t("screenings.edit.itemlist.booking")
            }, {
                active: (key === 4) ? true : false,
                depth: 0,
                icon: "map",
                key: "map",
                selection: 4,
                title: t("places.place")
            }, {
                active: (key === 5) ? true : false,
                depth: 0,
                icon: "history",
                key: "history",
                selection: 5,
                title: t("screenings.edit.itemlist.history")
            }
        ];

        return itemList;
    };

    if (isCreation)
        return (
            <div className="ScreeningForm w-100">
                <Form onSubmit={handleSubmit}>
                    <ScreeningFormInfo isCreation={isCreation} />
                </Form>
            </div>
        );

    return (
        <div className="ScreeningForm w-100">
            <ItemsList
                horizontal
                items={makeList(selectKey)}
                onSelect={onSelect}
            />
            <Form onSubmit={handleSubmit}>
                { selectKey === 0 &&
                    <ScreeningFormInfo isLoading={isLoading} />
                }
            </Form>
            { selectKey === 1 &&
                <ScreeningFormBuckets buckets={screening.buckets} isLoading={isLoading} />
            }
            { selectKey === 2 &&
                <ScreeningFormPricings pricings={screening.pricings} isLoading={isLoading} />
            }
            { selectKey === 3 &&
                <ScreeningFormBookings tickets={tickets} screeningId={screening._id} setting={setting} />
            }
            { selectKey === 4 &&
                <ScreeningFormMap isLoading={isLoading} placeId={screening.cinema_hall._id} places={places.places} />
            }
            { selectKey === 5 &&
                <ScreeningFormLogs logs={logs} setting={setting} />
            }
        </div >
    );
}

const mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ScreeningForm = reduxForm({
    form: "ScreeningForm",
    enableReinitialize: true
})(ScreeningForm)

// You have to connect() to any reducers that you wish to connect to yourself
ScreeningForm = connect(
    state => ({
        initialValues: state.screenings.screening,
        screening: state.screenings.screening,
        places: state.places,
        destroyOnUnmount: false
    }), mapDispatchToProps
)(ScreeningForm)

export default ScreeningForm;
