import React from 'react';
import { useSelector } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import ApplicationsList from './ApplicationsList/ApplicationsList';
import ApplicationShow from './ApplicationShow/ApplicationShow';
import ApplicationDelete from './ApplicationDelete/ApplicationDelete';

const Applications = ({ match }) => {

    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.applications);

    return (
        <div className="Applications">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }

            <Route exact path={ match.path } component={ApplicationsList} />
            <Route exact path={ `${ match.url }/show/:applicationId` } component={ApplicationShow} />
            <Route exact path={ `${ match.url }/delete/:applicationId` } component={ApplicationDelete} />

        </div>
    );
}

export default Applications;

