import RestClient from './RestClient';
import { Place } from '../../models';

class Places extends RestClient {
    constructor() {
        super({
            "model": Place,
            "entryPoint": "place",
            "resource": "place",
            "resources": "places",
            "id_field": "_id"
        });
    }
}

const PlacesApi = new Places();

export default PlacesApi;
