import store from '..';
import { Applications } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const APPROVING_APPLICATION         = 'kronos/sectors/APPROVING_APPLICATION';
const APPROVING_APPLICATION_SUCCESS = 'kronos/sectors/APPROVING_APPLICATION_SUCCESS';
const APPROVING_APPLICATION_FAILURE = 'kronos/sectors/APPROVING_APPLICATION_FAILURE';

const REJECTING_APPLICATION         = 'kronos/sectors/REJECTING_APPLICATION';
const REJECTING_APPLICATION_SUCCESS = 'kronos/sectors/REJECTING_APPLICATION_SUCCESS';
const REJECTING_APPLICATION_FAILURE = 'kronos/sectors/REJECTING_APPLICATION_FAILURE';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction,
    linkedResourcesReducer, linkedResourcesAction
} = createRestSlices(Applications);

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case APPROVING_APPLICATION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case APPROVING_APPLICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                application: action.application,
                applications: (state.applications || []).map(a => {
                    if (a.id === action.application.id)
                        return action.application;
                    return a;
                })
            };
        case APPROVING_APPLICATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case REJECTING_APPLICATION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case REJECTING_APPLICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                application: action.application,
                applications: (state.applications || []).map(a => {
                    if (a.id === action.application.id)
                        return action.application;
                    return a;
                })
            };
        case REJECTING_APPLICATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer,
        linkedResourcesReducer, reducer
    ]);
}

/* Export CRUD actions */
export const startCreateApplication         = startCreateAction;
export const createApplication              = createAction;
export const loadApplication                = getAction;
export const loadApplications               = listAction;
export const updateApplication              = updateAction;
export const deleteApplication              = deleteAction;
export const duplicateApplication           = duplicateAction;
export const getApplicationsLinkedResources = linkedResourcesAction;

export function acceptApplication(id, callback) {
    return (dispatch) => {
        dispatch({ type: APPROVING_APPLICATION });
        Applications.accept(id)
            .then(data => {
                const { application } = data;
                dispatch({
                    type: APPROVING_APPLICATION_SUCCESS,
                    application
                });
                callback && callback(/*err*/null, application);
            })
            .catch(error => {
                dispatch({ type: APPROVING_APPLICATION_FAILURE, error });
                callback && callback(error);
            });
    }
}

export function rejectApplication(id, callback) {
    return (dispatch) => {
        dispatch({ type: REJECTING_APPLICATION });
        Applications.reject(id)
            .then(data => {
                const { application } = data;
                dispatch({
                    type: REJECTING_APPLICATION_SUCCESS,
                    application
                });
                callback && callback(/*err*/null, application);
            })
            .catch(error => {
                dispatch({ type: REJECTING_APPLICATION_FAILURE, error });
                callback && callback(error);
            });
    }
}

