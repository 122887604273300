import React from 'react';
import BaseModel from './BaseModel';
import moment from 'moment';
import { mdiHandOkay, mdiAlertCircleOutline } from '@mdi/js';
import ReactTimeAgo from 'react-time-ago';

/** Class representing a collector event. */
class CollectorModule extends BaseModel {
    constructor(properties) {
        super(properties);

        this.statusNumber = parseInt(this.statusNumber);

        if (this.createdAt)
            this.createdAt = moment(this.createdAt);
    }

    getIcon() {
        return this.statusNumber === 1 ? mdiHandOkay : mdiAlertCircleOutline;
    }

    getText() {
        return this.label;
    }

    getSubtext() {
        return this.statusLabel;
    }

    isOk() {
        return this.statusNumber === 1;
    }

    getCreatedAt() {
        return (
            <ReactTimeAgo
                date={ moment().toDate() }
                locale="fr-FR"
                timeStyle="round-minute"
            />
        );
    }
}

export default CollectorModule;
