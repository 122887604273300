import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableStats from '../../../components/TableStats/TableStats';
import { Utils, Filters } from '../../../services';
import StatsHeader from '../StatsHeader/StatsHeader'
import _ from 'lodash';
import i18n from '../../../locales/i18n';
import { useSelector } from 'react-redux';

import "./StatsDaily.css";

const StatsDaily = ({ filters }) => {
    const { t }     = useTranslation();
    const title     = t("statstypes.texts.income_daily")
    const { stats } = useSelector(state => state.stats);

    const data = stats.days;

    if (!data || !data.films)
        return null;

    const tempArray = data.films;
    const dataTable = [];
    Object.keys(tempArray).forEach(film => {
        dataTable.push({
            movie: tempArray[film]._id.title[i18n.language] ?? tempArray[film]._id.title.original,
            mon: (tempArray[film].stats["1"]) ? {
                total: tempArray[film].stats["1"].total,
                nbPaid: tempArray[film].stats["1"].nbPaid,
                nbFree: tempArray[film].stats["1"].nbFree,
                nbPass: tempArray[film].stats["1"].nbPass,
                nbTotal: tempArray[film].stats["1"].nbTotal,
            } : "",
            tue: (tempArray[film].stats["2"]) ? {
                total: tempArray[film].stats["2"].total,
                nbPaid: tempArray[film].stats["2"].nbPaid,
                nbFree: tempArray[film].stats["2"].nbFree,
                nbPass: tempArray[film].stats["2"].nbPass,
                nbTotal: tempArray[film].stats["2"].nbTotal,
            } : "",
            wed: (tempArray[film].stats["3"]) ? {
                total: tempArray[film].stats["3"].total,
                nbPaid: tempArray[film].stats["3"].nbPaid,
                nbFree: tempArray[film].stats["3"].nbFree,
                nbPass: tempArray[film].stats["3"].nbPass,
                nbTotal: tempArray[film].stats["3"].nbTotal,
            } : "",
            thu: (tempArray[film].stats["4"]) ? {
                total: tempArray[film].stats["4"].total,
                nbPaid: tempArray[film].stats["4"].nbPaid,
                nbFree: tempArray[film].stats["4"].nbFree,
                nbPass: tempArray[film].stats["4"].nbPass,
                nbTotal: tempArray[film].stats["4"].nbTotal,
            } : "",
            fri: (tempArray[film].stats["5"]) ? {
                total: tempArray[film].stats["5"].total,
                nbPaid: tempArray[film].stats["5"].nbPaid,
                nbFree: tempArray[film].stats["5"].nbFree,
                nbPass: tempArray[film].stats["5"].nbPass,
                nbTotal: tempArray[film].stats["5"].nbTotal,
            } : "",
            sat: (tempArray[film].stats["6"]) ? {
                total: tempArray[film].stats["6"].total,
                nbPaid: tempArray[film].stats["6"].nbPaid,
                nbFree: tempArray[film].stats["6"].nbFree,
                nbPass: tempArray[film].stats["6"].nbPass,
                nbTotal: tempArray[film].stats["6"].nbTotal,
            } : "",
            sun: (tempArray[film].stats["7"]) ? {
                total: tempArray[film].stats["7"].total,
                nbPaid: tempArray[film].stats["7"].nbPaid,
                nbFree: tempArray[film].stats["7"].nbFree,
                nbPass: tempArray[film].stats["7"].nbPass,
                nbTotal: tempArray[film].stats["7"].nbTotal,
            } : "",
            total: {
                total: tempArray[film].stats.total,
                nbPaid: tempArray[film].stats.nbPaid,
                nbFree: tempArray[film].stats.nbFree,
                nbPass: tempArray[film].stats.nbPass,
                nbTotal: tempArray[film].stats.nbTotal,
            }
        })
    });

    //Columns for stats per movies table
    const columns = [
        {
            Header: t("screenings.title"),
            accessor: "movie",
            filterMethod: Filters.textFilterMethod(`movie`),
            Filter: Filters.textFilter,
            filterAll: true,
            Footer: () => (<b>{t("statstypes.total")}</b>),
            className: "sticky",
            headerClassName: "sticky"
        },
        {
            Header: t("common.wednesday"),
            accessor: "wed",
            filterable: false,
            Cell: row => (row.value && (
                <label>{row.value.total?.toFixed(2)}&nbsp;{Utils.currency()}<br />
                    <small>
                        {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}<br />
                    </small>
                </label>
            )),
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: () => (
                data?.total && data.total[3] ?
                    <label>
                        <b>{data.total[3].total.toFixed(2)}&nbsp;{Utils.currency()}<br />
                            <small>
                                {data.total[3].nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                                {data.total[3].nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                                {data.total[3].nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                                {t('statstypes.total')}&nbsp;:&nbsp;{data.total[3].nbTotal}<br />
                            </small>
                        </b>
                    </label>
                    : "")
        },
        {
            Header: t("common.thursday"),
            accessor: "thu",
            filterable: false,
            Cell: row => (row.value && (
                <label>{row.value.total?.toFixed(2)}&nbsp;{Utils.currency()}<br />
                    <small>
                        {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}<br />
                    </small>
                </label>
            )),
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: () => (
                data?.total && data.total[4] ?
                    <label>
                        <b>{data.total[4].total.toFixed(2)}&nbsp;{Utils.currency()}<br />
                            <small>
                                {data.total[4].nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                                {data.total[4].nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                                {data.total[4].nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                                {t('statstypes.total')}&nbsp;:&nbsp;{data.total[4].nbTotal}<br />
                            </small>
                        </b>
                    </label>
                    : "")
        },
        {
            Header: t("common.friday"),
            accessor: "fri",
            filterable: false,
            Cell: row => (row.value && (
                <label>{row.value.total?.toFixed(2)}&nbsp;{Utils.currency()}<br />
                    <small>
                        {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}<br />
                    </small>
                </label>
            )),
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: () => (
                data?.total && data.total[5] ?
                    <label>
                        <b>{data.total[5].total.toFixed(2)}&nbsp;{Utils.currency()}<br />
                            <small>
                                {data.total[5].nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                                {data.total[5].nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                                {data.total[5].nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                                {t('statstypes.total')}&nbsp;:&nbsp;{data.total[5].nbTotal}<br />
                            </small>
                        </b>
                    </label>
                    : "")
        },
        {
            Header: t("common.saturday"),
            accessor: "sat",
            filterable: false,
            Cell: row => (row.value && (
                <label>{row.value.total?.toFixed(2)}&nbsp;{Utils.currency()}<br />
                    <small>
                        {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}<br />
                    </small>
                </label>
            )),
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: () => (
                data?.total && data.total[6] ?
                    <label>
                        <b>{data.total[6].total.toFixed(2)}&nbsp;{Utils.currency()}<br />
                            <small>
                                {data.total[6].nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                                {data.total[6].nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                                {data.total[6].nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                                {t('statstypes.total')}&nbsp;:&nbsp;{data.total[6].nbTotal}<br />
                            </small>
                        </b>
                    </label>
                    : "")
        },
        {
            Header: t("common.sunday"),
            accessor: "sun",
            filterable: false,
            Cell: row => (row.value && (
                <label>{row.value.total?.toFixed(2)}&nbsp;{Utils.currency()}<br />
                    <small>
                        {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}<br />
                    </small>
                </label>
            )),
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: () => (
                data?.total && data.total[7] ?
                    <label>
                        <b>{data.total[7].total.toFixed(2)}&nbsp;{Utils.currency()}<br />
                            <small>
                                {data.total[7].napaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                                {data.total[7].nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                                {data.total[7].nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                                {t('statstypes.total')}&nbsp;:&nbsp;{data.total[7].nbTotal}<br />
                            </small>
                        </b>
                    </label>
                    : "")
        },
        {
            Header: t("common.monday"),
            accessor: 'mon',
            filterable: false,
            Cell: row => (row.value && (
                <label>{row.value.total?.toFixed(2)}&nbsp;{Utils.currency()}<br />
                    <small>
                        {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}<br />
                    </small>
                </label>
            )),
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: () => (
                data?.total && data.total[1] ?
                    <label>
                        <b>{data.total[1].total.toFixed(2)}&nbsp;{Utils.currency()}<br />
                            <small>
                                {data.total[1].nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                                {data.total[1].nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                                {data.total[1].nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                                {t('statstypes.total')}&nbsp;:&nbsp;{data.total[1].nbTotal}<br />
                            </small>
                        </b>
                    </label>
                    : "")
        },
        {
            Header: t("common.tuesday"),
            accessor: "tue",
            filterable: false,
            Cell: row => (row.value && (
                <label>{row.value.total?.toFixed(2)}&nbsp;{Utils.currency()}<br />
                    <small>
                        {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}<br />
                    </small>
                </label>
            )),
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: () => (
                data?.total && data.total[2] ?
                    <label>
                        <b>{data.total[2].total.toFixed(2)}&nbsp;{Utils.currency()}<br />
                            <small>
                                {data.total[2].nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                                {data.total[2].nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                                {data.total[2].nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                                {t('statstypes.total')}&nbsp;:&nbsp;{data.total[2].nbTotal}<br />
                            </small>
                        </b>
                    </label>
                    : "")
        },
        {
            Header: t("statstypes.total"),
            accessor: "total",
            filterable: false,
            Cell: row => (row.value && (
                <label>{row.value.total?.toFixed(2)}&nbsp;{Utils.currency()}<br />
                    <small>
                        {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}<br />
                    </small>
                </label>
            )),
            maxWidth: 110,
            className: 'align-center-stats',
            Footer: () => (
                data?.total && data.total[3] ?
                    <label>
                        <b>{data?.total?.total?.toFixed(2)}&nbsp;{Utils.currency()}<br />
                            <small>
                                {data?.total?.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                                {data?.total?.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                                {data?.total?.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                                {t('statstypes.total')}&nbsp;:&nbsp;{data?.total?.nbTotal}<br />
                            </small>
                        </b>
                    </label>
                    : "")
        },
    ]

    const getExcelData = () => {
        if (columns && dataTable && data.total) {
            const excelData = [];
            let temp = [];

            //Header
            for (let i = 0; i < columns.length; i++) {
                if (i === 0)
                    temp.push(columns[i].Header);
                else {
                    temp.push(columns[i].Header + " " + t("statstypes.chart.income"));
                    temp.push(columns[i].Header + " " + t('statstypes.chart.paid_entries'));
                    temp.push(columns[i].Header + " " + t('statstypes.chart.free_entries'));
                    temp.push(columns[i].Header + " " + t('statstypes.chart.pass_entries'));
                    temp.push(columns[i].Header + " " + t('statstypes.entries'));
                }
            }
            excelData.push(temp);

            //body
            const keys = ['wed', 'thu', 'fri', 'sat', 'sun', 'mon', 'tue', 'total'];
            dataTable.forEach(row => {
                temp = []
                temp.push(row.movie)
                keys.map(key => {
                    temp.push(row[key].total);
                    temp.push(row[key].nbPaid);
                    temp.push(row[key].nbFree);
                    temp.push(row[key].nbPass);
                    temp.push(row[key].nbTotal);
                })
                excelData.push(temp);
            });

            temp = []
            temp.push(t("statstypes.total"))
            let days = [3, 4, 5, 6, 7, 1, 2]
            for (let i = 1; i < 8; i++) {
                if (data.total[days[i - 1]] && data.total[days[i - 1]]) {
                    temp.push(data.total[days[i - 1]].total)
                    temp.push(data.total[days[i - 1]].nbPaid)
                    temp.push(data.total[days[i - 1]].nbFree)
                    temp.push(data.total[days[i - 1]].nbPass)
                    temp.push(data.total[days[i - 1]].nbTotal)
                } else {
                    temp.push("")
                    temp.push("")
                }
            }
            temp.push(data.total.total)
            temp.push(data.total.nbPaid)
            temp.push(data.total.nbFree)
            temp.push(data.total.nbPass)
            temp.push(data.total.nbTotal)
            excelData.push(temp)

            return excelData
        }
    }

    return (
        <div className="StatsDaily">
            <div className="noPrint">
                <PageTitle icon="calendar" title={title} />
            </div>
            <StatsHeader
                filters={filters}
                title={<PageTitle
                    icon="calendar"
                    title={title}
                />}
            />
            {dataTable && (
                <TableStats
                    id="dailyStatsTable"
                    data={dataTable}
                    columns={columns}
                    getExcelData={getExcelData}
                    defaultSortDesc={true}
                    defaultSortMethod={Utils.sortOnProperty('total')}
                    print
                />
            )}
        </div>
    )
}

export default StatsDaily;
