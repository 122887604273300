import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import CollectorsList from './CollectorsList/CollectorsList';
import CollectorManagement from './CollectorManagement/CollectorManagement';

const Collectors = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Collectors">
            <Switch>
                <Route exact path={path}><CollectorsList /></Route>
                <Route exact path={`${path}/manage/:collectorId`}><CollectorManagement /></Route>
            </Switch>
        </div>
    );
}

export default Collectors;
