import React from 'react';
import { Field } from 'redux-form'
import { Checkbox } from 'semantic-ui-react'

import './ToggleField.css';

const ToggleField = ({ name, disabled, onSelectChange, ...rest }) => {
    const Component = (props) => {
        const { input } = props;
        const onChange = (e, data) => {
            input.onChange(data.checked);
        }

        return <Checkbox
            toggle
            name={ name }
            disabled={disabled}
            defaultValue={ input.value }
            onChange={ onChange }
            checked={ !!input.value }
            { ...rest }
        />;
    }

    return (
        <div className="ToggleField">
            <Field name={ name } component={ Component } onChange={onSelectChange} className="form-control" />
        </div>
    );
}

export default ToggleField;
