import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { Utils } from '../../../services/';
import { loadProgrammation, deleteProgrammation } from '../../../redux/modules/programmations';

import moment from 'moment';

class ProgrammationDelete extends Component<{}> {
    constructor(props) {
        super(props);
        this.goBack  = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    componentDidMount() {
        const programmationId = this.props.match.params.programmationId;
        // Load programmations on component mount
        this.props.dispatchLoadProgrammation(programmationId);
    }

    deleteProgrammation(programmationId) {
        this.props.dispatchDeleteProgrammation(programmationId);
    }

    render() {
        const { programmation, isLoading, programmationSuccessfullyDeleted } = this.props.programmations;
        const { t } = this.props;
        const default_lang = Utils.default_lang();

        let title = <span>{t("bulidings.programmation_plural")}</span>;
        if (programmation)
            title = (
                <span>
                    {t("programmations.programmation")} |&nbsp;
                    {programmation.events.length &&
                        <small>
                            {programmation.events.length && programmation.events.map((e) =>  (
                                e.title[default_lang]
                            )).join(', ')}
                        </small>
                    }
                </span>
            );

        const actions =
            <Button onClick={this.goBack} color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
            </Button>

        return (
            <div className="ProgrammationDelete">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {programmationSuccessfullyDeleted &&
                    <Redirect to="/programmations"/>
                }

                {programmation &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('programmations.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div>
                                            <b>{t("programmations.events")}</b>:&nbsp;
                                            {programmation.events.length && programmation.events.map((e) =>  (
                                                e.title[default_lang]
                                            )).join(', ')}
                                        </div>
                                        <div>
                                            <b>{t("programmations.hours")}</b>:&nbsp;
                                            {programmation.times.length && programmation.times.map((h) =>  (
                                                h
                                            )).join(', ')}
                                        </div>
                                        <div>
                                            <b>{t("programmations.start_at")}</b>:&nbsp;
                                            { moment(programmation.start_at).format('LL') }
                                        </div>
                                        <div>
                                            <b>{t("programmations.period")}</b>:&nbsp;
                                            { programmation.period_nb } { t('common.' + programmation.period_type) }
                                        </div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/programmations" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteProgrammation(programmation.id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadProgrammation: (programmationId) => loadProgrammation(programmationId),
    dispatchDeleteProgrammation: (programmationId) => deleteProgrammation(programmationId)
}

const mapStateToProps = state => ({
    programmations: state.programmations
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ProgrammationDelete))
