import React from 'react';
import BaseModel from './BaseModel';
import moment from 'moment';
import ReactTimeAgo from 'react-time-ago';
import _ from 'lodash';

/** Class representing a collector event. */
class CollectorFilling extends BaseModel {
    constructor(properties) {
        super(properties);

        if (this.createdAt)
            this.createdAt = moment(this.createdAt);
    }

    getTotal() {
        return (
            _.reduce(this.inserted, (memo, nb, key) => memo + (parseFloat(key) * nb), 0.00) +
            _.reduce(this.recycler, (memo, nb, key) => memo + (parseFloat(key) * nb), 0.00) +
            _.reduce(this.cashbox, (memo, nb, key) => memo + (parseFloat(key) * nb), 0.00)
        ).toFixed(2) + ' CHF';
    }

    getText() {
        return '#' + this.id + ' - ' + this.amount + ' CHF';
    }

    getCreatedAt() {
        return (
            <ReactTimeAgo
                date={ moment().toDate() }
                locale="fr-FR"
                timeStyle="round-minute"
            />
        );
    }
}

export default CollectorFilling;
