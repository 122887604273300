import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Button, Row, Col, Form } from 'reactstrap';
import Config from '../../../config/Config';
import i18n from '../../../locales/i18n';
import Widget from '../Widget';
import WidgetSharedSettings from '../WidgetSharedSettings/WidgetSharedSettings';
import './Weather.css';

const Weather = ({ props, saveSettings, index, onCancel, insert, name }) => {
    const { t } = useTranslation();
    const geo   = navigator.geolocation;

    //HTML content
    const [content, setContent]   = useState(undefined);
    //Does user want local time or specific zip code
    const [useLocal, setUseLocal] = useState(props?.isLocal !== undefined ? props.isLocal : true)

    const getWeather = (position, code) => {
        let apiPath = ""
        if (position) {
            //Local
            apiPath = `//api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=${Config.weather_api_appid}&lang=${i18n.language}&units=metric`;
        } else {
            //Zipcode
            apiPath = `//api.openweathermap.org/data/2.5/weather?zip=${code},CH&appid=${Config.weather_api_appid}&lang=${i18n.language}&units=metric`;
        }
        fetch(apiPath)
            .then(
                function (response) {
                    if (response.status !== 200) {
                        console.log('Looks like there was a problem. Status Code: ' + response.status);
                        return;
                    }
                    response.json().then(function (data) {
                        let temperature = data.main.temp.toFixed(1)
                        let imageLink   = `https://openweathermap.org/img/wn/${data.weather[0].icon}@2x.png`;
                        let temp        = [
                            //HTML content
                            <>
                                <Row key="rowPics">
                                    <Col  sm={6} md={4}>
                                        <img className="weatherImage" src={imageLink} alt="Weather description" />
                                    </Col>
                                    <Col  sm={6} md={8}>
                                        <div className='weatherDisplay'>
                                            <p className="temperature">{temperature + " °C"}</p>
                                            <p className="location">{data.name}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row key="rowNumbers">
                                    <Col sm={12}>
                                        <div className='text-center pt-4'>
                                            <i className="fa fa-tint tempIcons" style={{ color: "blue" }}></i>&nbsp;{data.main.humidity}&nbsp;%&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i className="fa fa-thermometer-full tempIcons" style={{ color: "red" }}></i>&nbsp;{data.main.temp_max}&nbsp;°C&nbsp;&nbsp;&nbsp;&nbsp;
                                            <i className="fa fa-thermometer-empty tempIcons" style={{ color: "blue" }}></i>&nbsp;{data.main.temp_min}&nbsp;°C
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        ]
                        setContent(temp)
                    });
                }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    useEffect(() => {
        //Don't compute if in settings
        if (!saveSettings) {
            if (props.isLocal) {
                geo.getCurrentPosition(getWeather);
            } else {
                getWeather(false, props.zipCode)
            }
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        //Props formatting
        //width and bgcolor are taken from WidgetSharedSettings child.
        let settings = {
            width: e.target.widgetWidth.value,
            bgColor: e.target.widgetBgTransparent.checked ? "rgb(0,0,0,0)": e.target.widgetBgColor.value,
            isLocal: e.target.location.checked,
            zipCode: e.target.zipCode.value
        }

        saveSettings([settings, index, insert, name]);
    }

    return (
        <div className='Weather'>
            {       //Normal display
                !saveSettings ?
                    <Widget
                        content={
                            <div className="weatherBox">
                                {content}
                            </div>
                        }
                        bgColor={props.bgColor}
                    />
                    ://Settings form
                    <Form onSubmit={handleSubmit} method="get">
                        <Row className='ml-0 mr-0 pb-2 border-bottom'>
                            <Col className='col-sm-12 col-md-12 col-lg-6'>
                                <Row className='text-center'>
                                    <h4 className='border-bottom pb-1 mb-2 w-100 mr-1'>{t("widgets.settings.parameters")}</h4>
                                </Row>
                                <Row>
                                    <Row>
                                        <Col>
                                            <div className='settingsFormLabel'>
                                                <label>{t("widgets.settings.use_current_location")}</label>
                                            </div>
                                        </Col>
                                        <Col className='col-3'>
                                            <div className='settingsFormInput'>
                                                <input
                                                    className='settingsInput'
                                                    type="checkbox"
                                                    name="location"
                                                    defaultChecked={useLocal}
                                                    onChange={e => setUseLocal(!useLocal)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ display: useLocal && "none" }}>
                                        <Col>
                                            <div className='settingsFormLabel'>
                                                <label>{t("widgets.settings.zipcode")}</label>
                                            </div>
                                        </Col>
                                        <Col className='col-3'>
                                            <div className='settingsFormInput'>
                                                <Input
                                                    className='settingsInput'
                                                    type="text"
                                                    name="zipCode"
                                                    defaultValue={props?.zipCode ? props.zipCode : ""}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Row>
                            </Col>
                            <Col className='col-sm-12 col-md-12 col-lg-6'>
                                <WidgetSharedSettings 
                                    width={props?.width ? props.width : 1} 
                                    bgColor={props?.bgColor ? props.bgColor : "#2A2A2A"} 
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="submitButtons">
                                    <Button onClick={() => onCancel()} className="mr-2" color="danger"><i className="fa fa-times"></i>&nbsp;{t("widgets.cancel")}</Button>&nbsp;
                                    <Button type="submit" color="success"><i className="fa fa-floppy-o"></i>&nbsp;{t("widgets.save")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
            }
        </div>
    )
}

export default Weather;
