import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import SalepointForm from '../SalepointForm/SalepointForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateSalepoint, createSalepoint } from '../../../redux/modules/salepoints';

class SalepointCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        // Reset salepoint in app state
        this.props.dispatchStartCreateSalepoint();
    }

    submit(values) {
        this.props.dispatchCreateSalepoint(values);
    }

    render() {
        const { salepointSuccessfullyCreated } = this.props.salepoints;
        const { t } = this.props;

        let title = <span>{t('salepoints.add')}</span>;

        const actions =
            <Button tag={Link} to="/salepoints" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="SalepointCreate">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {salepointSuccessfullyCreated &&
                    <Redirect to="/salepoints"/>
                }

                <SalepointForm onSubmit={this.submit} isCreation="true" />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateSalepoint: (salepoint) => startCreateSalepoint(salepoint),
    dispatchCreateSalepoint: (salepoint) => createSalepoint(salepoint)
}

const mapStateToProps = state => ({
    salepoints: state.salepoints,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(SalepointCreate))
