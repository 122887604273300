import React, { Component } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';

import './MoneyField.css';

const MoneyField = ({ name, ...rest }) => {
    const currency = Utils.currency();

    return (
        <div className="MoneyField">
            <InputGroup>
                <Field component="input" type="text" className="form-control" name={ name } pattern="-{0,1}[0-9]+([\.][0-9]{1,2})?" {...rest}/>
                <InputGroupAddon addonType="append">
                    <InputGroupText>{ currency }</InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </div>
    );
}

export default MoneyField;
