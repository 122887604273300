import React from 'react';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from "i18next";

const SectorField = ({ sectors, single, name }) => {
    const options = sectors.map((sector) => ({
        key: sector.id,
        text: Utils.localized_or_fallback(sector.name, i18n.language),
        value: sector.id
    }));

    return single ? (
        <SelectField name={ name } options={ options } />
    ) : (
        <MultiSelectField name={ name } options={ options } />
    );
}

export default SectorField;
