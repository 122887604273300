import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import DistributorForm from '../DistributorForm/DistributorForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadDistributor, updateDistributor } from '../../../redux/modules/distributors';

class DistributorEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const distributorId = this.props.match.params.distributorId;
        // Load distributors on component mount
        this.props.dispatchLoadDistributor(distributorId);
    }

    submit(values) {
        this.props.dispatchUpdateDistributor(values);
    }

    render() {
        const { t }           = this.props;
        const { distributor } = this.props.distributors;

        let title = <span>{t('distributors.distributor')}</span>;
        if (distributor)
            title = (
                <span>
                    {t('distributors.distributor')}
                    <small> |&nbsp;{ t('distributors.update_distributor', { name: distributor.name })} </small>
                </span>
            );

        const actions =
        <div>
            {distributor &&
            <ButtonGroup>
                <Button tag={Link} to={ `/distributors/show/${ distributor.id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="DistributorEdit">
                <PageTitle icon="money" title={ title } actions={ actions } />

                {distributor &&
                    <DistributorForm distributorId={distributor.id} onSubmit={this.submit} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadDistributor: (distributorId) => loadDistributor(distributorId),
    dispatchUpdateDistributor: (distributor) => updateDistributor(distributor)
}

const mapStateToProps = state => ({
    distributors: state.distributors,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(DistributorEdit))
