import BaseModel from './BaseModel';
import { v4 } from 'uuid';
import FormLayoutField from './FormLayoutField';
import {
    TextField, LongTextField,
    RichTextField, NumberField,
    BooleanField, DatetimeField,
    FileField, ListField,
    PasswordField, PhoneNumberField
} from './FormLayoutFields';
import _ from 'lodash';

/** Class representing a PDF layout. */
class FormLayout extends BaseModel {
    /* Types */
    static FIELD    = 'field';

    /* Fields types */
    static TEXT         = 'text';
    static LONGTEXT     = 'longtext';
    static RICHTEXT     = 'richtext';
    static PHONE_NUMBER = 'phone';
    static PASSWORD     = 'password';
    static NUMBER       = 'number';
    static DATETIME     = 'datetime';
    static BOOLEAN      = 'boolean';
    static LIST         = 'list';
    static FILE         = 'file';
    static IMAGE        = 'image';

    /* Options */
    static OPTION_NAME             = 'name';
    static OPTION_LABEL            = 'label';
    static OPTION_WIDTH            = 'width';
    static OPTION_REQUIRED         = 'required';
    static OPTION_ROWS             = 'rows';
    static OPTION_MIN              = 'min';
    static OPTION_MAX              = 'max';
    static OPTION_STEP             = 'step';
    static OPTION_WITH_TIME        = 'with_time';
    static OPTION_CHOICES          = 'choices';
    static OPTION_MULTIPLE         = 'multiple';
    static OPTION_EXPANDED         = 'expanded';
    static OPTION_EXTENSIONS       = 'extensions';

    static createField(properties) {
        switch (properties.type) {
            case FormLayout.TEXT:
                return new TextField(properties);
            case FormLayout.LONGTEXT:
                return new LongTextField(properties);
            case FormLayout.RICHTEXT:
                return new RichTextField(properties);
            case FormLayout.PHONE_NUMBER:
                return new PhoneNumberField(properties);
            case FormLayout.PASSWORD:
                return new PasswordField(properties);
            case FormLayout.NUMBER:
                return new NumberField(properties);
            case FormLayout.DATETIME:
                return new DatetimeField(properties);
            case FormLayout.BOOLEAN:
                return new BooleanField(properties);
            case FormLayout.FILE:
                return new FileField(properties);
            case FormLayout.LIST:
                return new ListField(properties);
        }

        return null;
    }

    static getAvailableFields(type) {
        return [
            new TextField(),
            new LongTextField(),
            new RichTextField(),
            new PhoneNumberField(),
            new PasswordField(),
            new NumberField(),
            new DatetimeField(),
            new BooleanField(),
            new FileField(),
            new ListField(),
        ];
    }

    static defaultLayout() {
        return {
            id: v4(),
            name: { fr: 'Formulaire',
                en: 'Form',
                de: 'Form'
            },
            fields: []
        };
    }

    constructor(properties) {
        super(properties);

        this.fields = (this.fields || []).map(f => {
            switch (f.type) {
                case FormLayout.TEXT:
                    return new TextField(f);
                case FormLayout.LONGTEXT:
                    return new LongTextField(f);
                case FormLayout.RICHTEXT:
                    return new RichTextField(f);
                case FormLayout.PHONE_NUMBER:
                    return new PhoneNumberField(f);
                case FormLayout.PASSWORD:
                    return new PasswordField(f);
                case FormLayout.NUMBER:
                    return new NumberField(f);
                case FormLayout.DATETIME:
                    return new DatetimeField(f);
                case FormLayout.BOOLEAN:
                    return new BooleanField(f);
                case FormLayout.FILE:
                    return new FileField(f);
                case FormLayout.LIST:
                    return new ListField(f);
                default:
                    return new FormLayoutField(f);
            }
        });
    }

    /**
     * Prepare this object for duplicate.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForDuplicate() {
        const prepared = _.cloneDeep(this);

        prepared.id = v4();

        prepared.fields = prepared.fields.map(field => {
            field.id      = v4();
            field.form_id = prepared.id;

            field.choices = (field.choices || []).map(choice => {
                choice.id       = v4();
                choice.field_id = field.id;

                return choice;
            });

            return field;
        });

        return prepared;
    }
}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
FormLayout.getListingFields = () => ['id', 'name'];

export default FormLayout;
