import RestClient from './RestClient';
import { Section } from '../../models';

class Sections extends RestClient {
    constructor() {
        super({
            "model": Section,
            "entryPoint": "section",
            "resource": "section",
            "resources": "sections"
        });
    }
}

const SectionsApi = new Sections();

export default SectionsApi;
