import React from "react";
import PdfLayout from '../PdfLayout';
import CustomTextWidget from './CustomTextWidget';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * Class representing a PDF layout widget showing
 * an information about the printing environment.
 */
class EnvironmentInfoWidget extends CustomTextWidget {

    constructor(properties) {
        super({
            ...(EnvironmentInfoWidget.getDefaultOptions()),
            ...properties,
        });

        this.type    = PdfLayout.TEXT;
        this.subType = PdfLayout.ENVIRONMENT_INFO;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 5, height: 0.75 },
            transform: { rotation: 0 },
            options: {
                field: 'printer',
                style: {
                    color: '#000000',
                    fontFamily: "Helvetica",
                    fontSize: 14,
                    lineHeight: 1,
                    textAlign: 'left',
                    textDecoration: 'none',
                    backgroundColor: '#FFFFFF00',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        const handledOptions = super.getHandledOptions().concat([
            PdfLayout.OPTION_ENVIRONMENT_FIELD
        ]).filter(o => o !== PdfLayout.OPTION_TEXT);

        if (this.options?.field === 'printed_at') {
            handledOptions.push(PdfLayout.OPTION_DATE_FORMAT);
            handledOptions.push(PdfLayout.OPTION_DATE_OFFSET);
        }

        return handledOptions;
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreviewText(props) {
        const { t } = useTranslation();

        switch (this.options.field) {
            case 'printer':
                return t('models.environment.printer');
            case 'salepoint':
                return t('models.environment.salepoint');
            case 'printed_at':
                return moment().locale(props?.lang || 'fr').format(this.options?.date_format || 'LLL');
            default:
                return '...';
        }
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'globe';
    }
}

export default EnvironmentInfoWidget;
