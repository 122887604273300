import React, { useState }  from "react";
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button, CardText } from "reactstrap";
import { FormLayout } from '../../../../../models';

const FormFieldsBrowser = ({ addField }) => {
    const { t } = useTranslation();

    return (
        <div className="wrapper-1 mr-1">
            <div className="">
                <CardText tag="div">
                    { FormLayout.getAvailableFields().map((field, index) => (
                        <Button key={ index } className="fieldBtn" color="outline" onClick={() => addField(field.getType())}>
                            <i className={ `fa fa-${field.getIcon()}` } />{' '}
                            {t(`forms.${field.getType()}`)}
                        </Button>
                    ))}
                </CardText>
            </div>
        </div>
    );
}

export default FormFieldsBrowser;
