import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import EventsList from './EventsList/EventsList';
import EventShow from './EventShow/EventShow';
import EventEdit from './EventEdit/EventEdit';
import EventCreate from './EventCreate/EventCreate';
import EventDelete from './EventDelete/EventDelete';

class Events extends Component<{}> {
    render() {
        const { match } = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.events;
        return (
            <div className="Events">

                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Switch>
                    <Route exact path={ `${ match.url }/:type` } component={EventsList} />
                    <Route exact path={ `${ match.url }/:type/new` } component={EventCreate} />
                    <Route exact path={ `${ match.url }/:type/show/:eventId` } component={EventShow} />
                    <Route exact path={ `${ match.url }/:type/edit/:eventId` } component={EventEdit} />
                    <Route exact path={ `${ match.url }/:type/delete/:eventId` } component={EventDelete} />
                </Switch>
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    events: state.events
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
