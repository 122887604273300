import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import i18n from '../../locales/i18n';
import _ from 'lodash';

import './EventsField.css';

const EventsField = ({ name, events }) => {
    let events_groups = [];
    if (events && events.length) {
        events_groups = _.uniq(events.map((e) => e.event_type)).map((t) => {
            return {
                label: Utils.event_types(t).label,
                events: _.sortBy(events.filter((e) => e.event_type === t), `title.${i18n.language}`)
            };
        });
    }

    const options = [];
    events_groups.forEach(group => {
        group.events.forEach(e => {
            options.push({
                key: e.id,
                text: Utils.localized_or_fallback(e.title, i18n.language),
                value: e.id,
            });
        });
    });

    return (
        <div className="EventsField">
            <SelectField
                name={ name }
                options={ options }
                fluid
                clearable
                selectOnNavigation
            />
        </div>
    );
}

export default EventsField;
