import React, { Component } from 'react';
import { connect } from 'react-redux'
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Filters } from '../../../services/';
import 'react-table-6/react-table.css'
import { loadResources } from '../../../redux/modules/resources';
import Table from '../../../components/Table/Table';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';

class ResourcesList extends Component<{}> {
    componentDidMount() {
        // Load resources on component mount
        this.props.dispatchLoadResources();
    }

    render() {
        const { t }                                               = this.props;
        const { resources, hasLoadingError, loadingErrorMessage } = this.props.resources;

        const columns = [{
            Header: t("resources.number"),
            accessor: "number",
            filterMethod: Filters.textFilterMethod('number'),
            Filter: Filters.textFilter,
            filterAll: true
        }, {
            Header: t("resources.type"),
            accessor: "type",
            filterMethod: Filters.textFilterMethod('type'),
            Filter: Filters.textFilter,
            filterAll: true
        }, {
            Header: t("resources.comment"),
            accessor: "comment",
            filterMethod: Filters.textFilterMethod('comment'),
            Filter: Filters.textFilter,
            filterAll: true
        }, {
            id: 'userName',
            Header: t("resources.user"),
            accessor: d => {return d.user ? `${d.user.firstname} ${d.user.lastname}` : ''},
            filterMethod: Filters.textFilterMethod('userName'),
            Filter: Filters.textFilter,
            filterAll: true
        }, {
            Header: t("common.actions"),
            accessor: "id",
            maxWidth: 110,
            filterable: false,
            Cell: row => (
                <TableButtons
                    id={row.value}
                    actions={["edit", "delete", "show"]}
                    module="resources"
                    owner={row.original.created_by}
                />
            )
        }];

        const actions =
            <CreateButton 
                module="resources"
                text="resources.add"
            />

        const title =
        <span>{t("resources.resource_plural")}</span>

        return (
            <div className="ResourcesList">
                <PageTitle icon="key" title={title} actions={ actions } />
                <Table id="resourcesTable" data={ resources } columns={ columns } />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadResources: () => loadResources()
}

const mapStateToProps = state => ({
    resources: state.resources
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ResourcesList))
