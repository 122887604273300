import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import { PageTitle, TableButtons, Table } from '../../../components';
import { Filters } from '../../../services/';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import { loadCustomers, loadInactiveCustomers } from '../../../redux/modules/customers';

import './CustomersList.css';

const CustomersList = props => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { customers } = useSelector(state => state.customers);

    useEffect(() => {
        // Load customers
        dispatch(loadCustomers());
    }, [dispatch]);

    const tableCustomers = customers?.map(customer => ({
        ...customer,
        fullname: _.filter([customer.contact.firstname, customer.contact.lastname]).join(' ')
    }));

    let columns = [{
        Header: t('customers.name'),
        accessor: "name",
        filterMethod: Filters.textFilterMethod("name"),
        Filter: Filters.textFilter,
        filterAll: true,
    }, {
        Header: t('customers.fullname'),
        accessor: "fullname",
        filterMethod: Filters.textFilterMethod("fullname"),
        Filter: Filters.textFilter,
        filterAll: true,
    }];

    columns = columns.concat([{
        Header: t('common.actions'),
        accessor: "_id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={['show', 'edit', 'delete']}
                module="customers"
            />
        )
    }]);

    return (
        <div className="CustomersList">
            <PageTitle icon="users" title={t('app.customers')} actions={[]} />
            <Table id="customersTable" data={ tableCustomers } columns={ columns } />
        </div>
    );
}

export default CustomersList;
