import React from 'react';
import { useSelector } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import PhasesList from './PhasesList/PhasesList';
import PhaseCreate from './PhaseCreate/PhaseCreate';
import PhaseEdit from './PhaseEdit/PhaseEdit';
import PhaseDelete from './PhaseDelete/PhaseDelete';

const Phases = ({ match }) => {

    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.phases);

    return (
        <div className="Phases">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }

            <Route exact path={ match.path } component={PhasesList} />
            <Route exact path={ `${ match.url }/new` } component={PhaseCreate} />
            <Route exact path={ `${ match.url }/edit/:phaseId` } component={PhaseEdit} />
            <Route exact path={ `${ match.url }/delete/:phaseId` } component={PhaseDelete} />

        </div>
    );
}

export default Phases;
