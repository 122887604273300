import React from "react";

const holdTime     = 500; // ms
// const holdDistance = 3 ** 2; // pixels squared



export default function Holdable({
    id,
    columnIndex,
    onHold,
    children,
    onClick,
}) {
    const [timer, setTimer] = React.useState(null);
    const [pos, setPos]     = React.useState([0, 0]);

    function onPointerDown(evt) {
        setPos([evt.clientX, evt.clientY]); // save position for later
        const event = { ...evt }; // convert synthetic event to real object
        const timeoutId = window.setTimeout(timesup.bind(null, event), holdTime);
        setTimer(timeoutId);
    }

    function onPointerUp() {
        if (timer) {
            window.clearTimeout(timer);
            setTimer(null);
            onClick({ id, columnIndex });
        }
    }

    function onPointerMove(evt) {
        // cancel hold operation if moved too much
        if (timer) {
            const d = (evt.clientX - pos[0]) ** 2 + (evt.clientY - pos[1]) ** 2;
            if (d > 0.05) {
                setTimer(null);
                window.clearTimeout(timer);
            }
        }
    }

    function timesup() {
        setTimer(null);
        onHold({ id, columnIndex });
    }

    return (
        <div
            onPointerDown={onPointerDown}
            onPointerUp={onPointerUp}
            onPointerMove={onPointerMove}
            id={ id }
        >
            { children }
        </div>
    );
}
