// Redux Tickets module
// see https://github.com/erikras/ducks-modular-redux

import { Tickets } from '../../services/';


const LOADING_TICKETS         = 'kronos/tickets/LOADING_TICKETS';
const LOADING_TICKETS_SUCCESS = 'kronos/tickets/LOADING_TICKETS_SUCCESS';
const LOADING_TICKETS_FAILURE = 'kronos/tickets/LOADING_TICKETS_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    tagSuccessfullyCreated: false,
    tickets: []
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_TICKETS:
            return {
                ...state,
                isLoading: true,
                tagSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_TICKETS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tickets: action.tickets
            };
        case LOADING_TICKETS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingTickets() { return { type: LOADING_TICKETS } }
function loadingTicketsSuccess(tickets) { return { type: LOADING_TICKETS_SUCCESS, tickets: tickets } }
function loadingTicketsFailure(err) { return { type: LOADING_TICKETS_FAILURE, error: err } }
export function loadTickets(params) {
    return (dispatch) => {
        dispatch(loadingTickets());
        Tickets.list(params)
            .then(data => {
                const tickets = data.tickets;
                dispatch(loadingTicketsSuccess(tickets));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingTicketsFailure(err))
            });
    }
}
