import React from 'react';
import { useTranslation } from 'react-i18next';
import GaugeChart from 'react-gauge-chart';
import _ from 'lodash';

import './CollectorSituation.css';

const CollectorSituation = ({ collector, className, ...rest }) => {
    const { t }     = useTranslation();
    const situation = collector.state.lastSituation;

    if (!situation)
        return null;

    const _className = (module) => {
        return module.stock <= module.minAlert ? 'danger' : (
            module.stock >= module.maxAlert ? 'warning' : 'ok'
        )
    }

    const recycler_total = parseFloat(
        situation.modules.withdrawal.coins.reduce((memo, coin) => memo + (parseFloat(coin.value) * coin.stock), 0) +
        situation.modules.withdrawal.bills.reduce((memo, bill) => memo + (parseFloat(bill.value) * bill.stock), 0)
    );
    const cashbox_total = (
        situation.modules.deposit.coins.reduce((memo, coin) => memo + (coin.value * coin.stock), 0) +
        situation.modules.deposit.bills.reduce((memo, bill) => memo + (bill.value * bill.stock), 0)
    );

    const recycler_gauges = _.uniqBy(_.concat(
        situation.modules.withdrawal.coins.filter(c => parseInt(c.state) === 1).reverse(),
        situation.modules.withdrawal.bills.filter(b => parseInt(b.state) === 1).reverse()
    ).map(coin => ({
        id: coin.id,
        className: _className(coin),
        title: t(`collectors.moneyTypes.${parseFloat(coin.value).toFixed(2) * 100}`),
        values: coin.stock + ' / ' + coin.max,
        stock: coin.stock,
        colors: ["#FF9400", "#28a745", "#BB191A"],
        arcsLength: [
            coin.minAlert / coin.max,
            (coin.maxAlert - coin.minAlert) / coin.max,
            (coin.max - coin.maxAlert) / coin.max
        ],
        percent: (coin.stock > 0 && coin.max > 0) ? (coin.stock / coin.max) : 0
    })), 'id');

    const deposit_gauges = _.uniqBy(_.concat(
        situation.modules.deposit.coins.filter(c => parseInt(c.state) === 1).reverse(),
        situation.modules.deposit.bills.filter(b => parseInt(b.state) === 1).reverse()
    ).filter(coin => coin.stock > 0).map(coin => ({
        id: coin.id,
        className: _className(coin),
        title: t(`collectors.moneyTypes.${parseFloat(coin.value).toFixed(2) * 100}`),
        values: coin.stock,
        stock: coin.stock,
        arcsLength: [
            0.33,
            0.44,
            0.33
        ],
        colors: ["#FF9400", "#28a745", "#BB191A"],
        percent: coin.stock / 100
    })), 'id');

    return (
        <div className={ `CollectorSituation ${className}` } { ...rest }>
            <div className="gauges recycler_gauges">
                <div className="gauge gauges-title">
                    <h3>
                        { recycler_total.toFixed(2) } CHF
                    </h3>
                    <small>
                        { t('collectors.in_recyclers') }
                    </small>
                </div>
                { recycler_gauges && recycler_gauges.map(gauge => (
                    <div key={ gauge.id } className={ `gauge ${gauge.className}` }>
                        <div className="gauge-inner">
                            <div className="gauge-title">
                                { gauge.title }
                            </div>
                            <GaugeChart className="gauge-chart" id={ `gauge-chart-${ gauge.id }` }
                                animate={ false }
                                nrOfLevels={ 1 }
                                percent={ gauge.percent }
                                arcsLength={ gauge.arcsLength}
                                colors={ gauge.colors }
                                hideText
                                needleColor="#FFFFFF"
                                needleBaseColor="#FFFFFF"
                            />
                            <div className="gauge-title">
                                <div className="values">
                                    { gauge.values }
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="gauges deposit_gauges">
                { deposit_gauges && deposit_gauges.map(gauge => (
                    <div key={ gauge.id } className={ `gauge ${gauge.className}` }>
                        <div className="gauge-inner">
                            <div className="gauge-title">
                                { gauge.title }
                            </div>
                            <GaugeChart className="gauge-chart" id={ `gauge-chart-${ gauge.id }` }
                                animate={ false }
                                nrOfLevels={ 1 }
                                percent={ gauge.percent }
                                arcsLength={ gauge.arcsLength}
                                colors={ gauge.colors }
                                hideText
                                needleColor="#FFFFFF"
                                needleBaseColor="#FFFFFF"
                            />
                            <div className="gauge-title">
                                <div className="values">
                                    { gauge.values }
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className="gauge gauges-title">
                    <h3>
                        { cashbox_total.toFixed(2) } CHF
                    </h3>
                    <small>
                        { t('collectors.in_cashbox') }
                    </small>
                </div>
            </div>
        </div>
    );
}

export default CollectorSituation;
