import React from 'react';
import i18n from '../../locales/i18n';
import { Badge } from 'reactstrap';

import './EventTitle.css'

const EventTitle = ({ titles, original, textSize, centered, badge }) => {
    if (original)
        titles.original = original;

    const title = titles[i18n.language] ?? titles.original ?? titles[Object.keys(titles)[0]] ?? "";

    return (
        <div className={`EventTitle${centered ? ' d-flex justify-content-center align-items-center' : ""}`}>
            {(titles[i18n.language] && titles.original && titles[i18n.language] !== titles.original) ?
                <>
                    <span style={{fontSize: `${textSize ? (textSize + 3).toString() : '15'}px`}}>{title}</span><br />
                    <span style={{fontSize: `${textSize ? textSize.toString() : '12'}px`}}>{titles.original}</span>
                </>
                :
                <span>{title}</span>
            }
            {badge && (
                <div className="opacity-80">
                    <Badge color="info" className="abs bottom-0 right-0">{badge}</Badge>
                </div>
            )}
        </div>
    )
}

export default EventTitle;
