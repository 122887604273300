import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form'
import { Dropdown } from 'semantic-ui-react';
import { loadTags, startCreateTag, createTag } from '../../redux/modules/tags';

import './TagsInput.css';

const TagsInput = ({ name, addTag, onChange, ...rest }) => {
    const [ isAddingTag, setIsAddingTag ] = useState(false);

    const { t }     = useTranslation();
    const dispatch  = useDispatch();

    const { tags, tag, tagSuccessfullyCreated } = useSelector(state => state.tags);

    const options = (tags || []).map(tag => {
        return {
            key: tag._id,
            text: tag.name,
            value: tag._id
        };
    });

    const onAddItem = (e, { value }) => {
        setIsAddingTag(true);
        dispatch(startCreateTag());
        dispatch(createTag({ name: value }));

        return false;
    };

    if (tag && isAddingTag && tagSuccessfullyCreated) {
        setIsAddingTag(false);
        const newTags = addTag(tag);
    }

    const _onChange = (e, data) => {
        onChange &&
        onChange(data.value.map(tagId => tags.find(t => tagId === t._id)));
    }

    return <Dropdown
        name={ name }
        options={options}
        onChange={ _onChange }
        search
        allowAdditions={ !!addTag }
        additionLabel={ t('tags.add_the_tag') + ' ' }
        onAddItem={ onAddItem }
        disabled={ isAddingTag }
        clearable
        multiple
        selection
        { ...rest }
    />;
}

export default TagsInput;
