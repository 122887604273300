import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Input } from 'reactstrap';
import { Link } from "react-router-dom";
import { CopyButton, PageTitle, Table, TableButtons, CreateButton } from '../../../components';
import matchSorter from 'match-sorter';
import 'react-table-6/react-table.css'
import { loadBucketlists, duplicateBucketlist } from '../../../redux/modules/bucketlists';

import './BucketlistsList.css';

const BucketlistsList = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { duplicatedBucketlist } = useSelector(state => state.bucketlists);

    useEffect(() => {
        dispatch(loadBucketlists());
    }, [dispatch, duplicatedBucketlist]);

    const onDuplicate = (bucketlist) => {
        dispatch(duplicateBucketlist(bucketlist));
    }

    const { bucketlists } = useSelector(state => state.bucketlists);

    const textFilter = ({ filter, onChange }) =>
        <Input
            type="text"
            onChange={event => onChange(event.target.value)}
        />;
    const textFilterMethod = (key) => (filter, rows) => matchSorter(rows, filter.value, { keys: [key] });

    const textOrIdFilterMethod = (filter, rows) => matchSorter(rows, filter.value, { keys: ["name", "_id"] });

    const columns = [{
        Header: t("bucketlists.name"),
        accessor: "name",
        filterMethod: textOrIdFilterMethod,
        Filter: textFilter,
        filterAll: true,
        Cell: row => (
            <Link to={`/bucketlists/edit/${row.original._id}`}>
                <div className="name-cell px-3 py-1 d-flex justify-content-between">
                    {row.original.name}
                    <CopyButton size="sm" badge color="info" textToCopy= { row.original._id } />
                </div>
            </Link>
        )
    }, {
        Header: t("bucketlists.nb_buckets"),
        accessor: "nb_buckets",
        filterMethod: textFilterMethod("number"),
        Filter: textFilter,
        Cell: row => <span>{Object.keys(row.original.buckets).length}&nbsp;{t()}</span>,
        filterable: false,
        filterAll: true
    }, {
        Header: t("common.actions"),
        accessor: "_id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete", "duplicate"]}
                module="bucketlists"
                onDuplicate={() => onDuplicate(row.original)}
            />
        )
    }];

    const actions =
        <CreateButton
            module="bucketlists"
            text="bucketlists.add"
        />

    const title =
        <span>{t("bucketlists.bucketlist_plural")}</span>

    return (
        <div className="BucketlistsList">
            <PageTitle icon="battery-half" title={title} actions={actions} />
            <Table id="bucketListsTable" data={bucketlists} columns={columns} />
        </div>
    );
}

export default BucketlistsList;
