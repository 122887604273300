import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import EftsList from './EftsList/EftsList';
import EftManagement from './EftManagement/EftManagement';

const Efts = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Efts">
            <Switch>
                <Route exact path={path}><EftsList /></Route>
                <Route exact path={`${path}/manage/:eftId`}><EftManagement /></Route>
            </Switch>
        </div>
    );
}

export default Efts;
