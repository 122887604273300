import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { autotest } from '../../../../../../redux';
import { Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiTuneVertical } from '@mdi/js';

const AutotestButton = ({ collector }) => {
    const { t }           = useTranslation();
    const dispatch        = useDispatch();

    const autotestCollector = () => {
        dispatch(autotest(collector.id));
    }

    return (
        <Button className="operation" color="danger" block onClick={ autotestCollector }>
            <div className="icon">
                <Icon path={ mdiTuneVertical } size={ 3 } color="#FFFFFF" />{ ' ' }
            </div>
            <div className="title">
                { t('collectors.autotest') }
                <div className="subtitle">{ t('collectors.autotest_desc') }</div>
            </div>
        </Button>
    );
}

export default AutotestButton;
