import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { addDeliveryType } from '../../redux/modules/settings';
import i18n from '../../locales/i18n';
import DeliveryTypeForm from './DeliveryTypeForm/DeliveryTypeForm';
import ItemsList from '../ItemsList/ItemsList';
import { Utils }  from '../../services';

import './DeliveryTypesForm.css';

const DeliveryTypesForm = () => {
    const { t } = useTranslation();
    const { delivery } = useSelector(state => state.settings.setting);

    const [ activeTypeIndex, setActiveTypeIndex ] = useState(0);

    const dispatch = useDispatch();
    const onSelect = index => {
        if (index === '__add')
            return dispatch(addDeliveryType());

        setActiveTypeIndex(index);
    };

    const icon = method => {
        switch (method.type) {
            case Utils.DELIVERY_TYPE_ONSITE:
                return 'cutlery';
            case Utils.DELIVERY_TYPE_TAKEAWAY:
                return 'shopping-bag';
            case Utils.DELIVERY_TYPE_PICKUP:
                return 'shopping-basket';
            case Utils.DELIVERY_TYPE_SHIPMENT:
                return 'truck';
            case Utils.DELIVERY_TYPE_VIRTUAL:
                return 'envelope-o';
        }
    }

    const indexedTypes = delivery?.types.map((type, index) => ({ ...type, index }));
    const items = [].concat(
        indexedTypes.filter(method => method.type === Utils.DELIVERY_TYPE_VIRTUAL),
        indexedTypes.filter(method => method.type === Utils.DELIVERY_TYPE_ONSITE),
        indexedTypes.filter(method => method.type === Utils.DELIVERY_TYPE_TAKEAWAY),
        indexedTypes.filter(method => method.type === Utils.DELIVERY_TYPE_PICKUP),
        indexedTypes.filter(method => method.type === Utils.DELIVERY_TYPE_SHIPMENT)
    ).map(type => ({
        key: type._id,
        selection: type.index,
        title: type.name[i18n.language],
        icon: icon(type),
        active: type.index === activeTypeIndex,
        depth: 0
    })).concat([{
        key: '__add',
        selection: '__add',
        title: t('settings.delivery.type.add'),
        icon: 'plus',
        isButton: true,
        depth: 0
    }]);

    return (
        <Row>
            <Col sm={12} lg={3}>
                <ItemsList
                    title={ t('settings.delivery.types') }
                    items={ items }
                    onSelect={ onSelect }
                />
            </Col>
            <Col sm={12} lg={9}>
                <DeliveryTypeForm name={ `delivery.types[${ activeTypeIndex }]` } typeIndex={ activeTypeIndex } type={ delivery?.types[activeTypeIndex] } />
            </Col>
        </Row>
    );
}

export default DeliveryTypesForm;
