// Redux Events module
// see https://github.com/erikras/ducks-modular-redux

import { Events } from '../../services/';
import { createRestSlices, applyReducers } from './rest';

const { initialState, linkedResourcesReducer, linkedResourcesAction } = createRestSlices(Events);

const LOADING_EVENTS           = 'kronos/events/LOADING_EVENTS';
const LOADING_EVENTS_SUCCESS   = 'kronos/events/LOADING_EVENTS_SUCCESS';
const LOADING_EVENTS_FAILURE   = 'kronos/events/LOADING_EVENTS_FAILURE';
const SEARCHING_EVENTS         = 'kronos/events/SEARCHING_EVENTS';
const SEARCHING_EVENTS_SUCCESS = 'kronos/events/SEARCHING_EVENTS_SUCCESS';
const SEARCHING_EVENTS_FAILURE = 'kronos/events/SEARCHING_EVENTS_FAILURE';
const LOADING_EVENT            = 'kronos/events/LOADING_EVENT';
const LOADING_EVENT_SUCCESS    = 'kronos/events/LOADING_EVENT_SUCCESS';
const LOADING_EVENT_FAILURE    = 'kronos/events/LOADING_EVENT_FAILURE';
const START_UPDATING_EVENT     = 'kronos/events/START_UPDATING_EVENT';
const UPDATING_EVENT           = 'kronos/events/UPDATING_EVENT';
const UPDATING_EVENT_SUCCESS   = 'kronos/events/UPDATING_EVENT_SUCCESS';
const UPDATING_EVENT_FAILURE   = 'kronos/events/UPDATING_EVENT_FAILURE';
const START_CREATING_EVENT     = 'kronos/events/START_CREATING_EVENT';
const CREATING_EVENT           = 'kronos/events/CREATING_EVENT';
const CREATING_EVENT_SUCCESS   = 'kronos/events/CREATING_EVENT_SUCCESS';
const CREATING_EVENT_FAILURE   = 'kronos/events/CREATING_EVENT_FAILURE';
const START_COPYING_EVENT      = 'kronos/events/START_COPYING_EVENT';
const COPYING_EVENT            = 'kronos/events/COPYING_EVENT';
const COPYING_EVENT_SUCCESS    = 'kronos/events/COPYING_EVENT_SUCCESS';
const COPYING_EVENT_FAILURE    = 'kronos/events/COPYING_EVENT_FAILURE';
const DELETING_EVENT           = 'kronos/events/DELETING_EVENT';
const DELETING_EVENT_SUCCESS   = 'kronos/events/DELETING_EVENT_SUCCESS';
const DELETING_EVENT_FAILURE   = 'kronos/events/DELETING_EVENT_FAILURE';

initialState.eventSuccessfullyCreated = false
initialState.eventSuccessfullyCopied  = false
initialState.eventSuccessfullyUpdated = false
initialState.events                   = []
initialState.results                  = []
initialState.linkedResources          = {};
initialState.copied                   = null;

// Reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_EVENTS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_EVENTS_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                events: action.events
            };
        case LOADING_EVENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case SEARCHING_EVENTS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case SEARCHING_EVENTS_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                results: action.events
            };
        case SEARCHING_EVENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_EVENT:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_EVENT_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                event: action.event
            };
        case LOADING_EVENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_UPDATING_EVENT:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                eventSuccessfullyUpdated: false,
            };
        case UPDATING_EVENT:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                eventSuccessfullyUpdated: true,
                event: action.event
            };
        case UPDATING_EVENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_EVENT:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                eventSuccessfullyCreated: false,
                event: {}
            };
        case CREATING_EVENT:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                eventSuccessfullyCreated: true,
                event: action.event
            };
        case CREATING_EVENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                eventSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case START_COPYING_EVENT:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                eventSuccessfullyCopied: false,
                copied: null
            };
        case COPYING_EVENT:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                copied: null
            };
        case COPYING_EVENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                eventSuccessfullyCopied: true,
                copied: action.event
            };
        case COPYING_EVENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                eventSuccessfullyCopied: false,
                loadingErrorMessage: action.error.message,
                copied: null
            };
        case DELETING_EVENT:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_EVENT_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                eventSuccessfullyDeleted: true,
                event: action.event
            };
        case DELETING_EVENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                eventSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [ linkedResourcesReducer, reducer ]);
}

// Actions
function loadingEvents() { return { type: LOADING_EVENTS } }
function loadingEventsSuccess(events) { return { type: LOADING_EVENTS_SUCCESS, events: events } }
function loadingEventsFailure(err) { return { type: LOADING_EVENTS_FAILURE, error: err } }
export function loadEvents(eventType) {
    return (dispatch) => {
        dispatch(loadingEvents());
        Events.list(eventType)
            .then(data => {
                const events = data.events;
                dispatch(loadingEventsSuccess(events));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingEventsFailure(err))
            });
    }
}

function searchingEvents() { return { type: SEARCHING_EVENTS } }
function searchingEventsSuccess(events) { return { type: SEARCHING_EVENTS_SUCCESS, events: events } }
function searchingEventsFailure(err) { return { type: SEARCHING_EVENTS_FAILURE, error: err } }
export function searchEvents(eventType, search) {
    return (dispatch) => {
        dispatch(searchingEvents());
        Events.search(eventType, search)
            .then(data => {
                const events = data.events;
                dispatch(searchingEventsSuccess(events));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(searchingEventsFailure(err))
            });
    }
}

function loadingEvent() { return { type: LOADING_EVENT } }
function loadingEventSuccess(event) { return { type: LOADING_EVENT_SUCCESS, event: event } }
function loadingEventFailure(err) { return { type: LOADING_EVENT_FAILURE, error: err } }
export function loadEvent(eventType, eventId) {
    return (dispatch) => {
        dispatch(loadingEvent());
        Events.get(eventType, eventId)
            .then(data => {
                const event = data.event;
                dispatch(loadingEventSuccess(event));
            })
            .catch(err => {
                dispatch(loadingEventFailure(err))
            });
    }
}

function updatingEvent() { return { type: UPDATING_EVENT } }
function updatingEventSuccess(event) { return { type: UPDATING_EVENT_SUCCESS, event: event } }
function updatingEventFailure(err) { return { type: UPDATING_EVENT_FAILURE, error: err } }
export function startUpdateEvent() { return { type: START_UPDATING_EVENT } }
export function updateEvent(eventType, event) {
    return (dispatch) => {
        dispatch(updatingEvent());
        Events.update(eventType, event)
            .then(data => {
                const event = data.event;
                dispatch(updatingEventSuccess(event));
            })
            .catch(err => {
                dispatch(updatingEventFailure(err))
            });
    }
}

function creatingEvent() { return { type: CREATING_EVENT } }
function creatingEventSuccess(event) { return { type: CREATING_EVENT_SUCCESS, event: event } }
function creatingEventFailure(err) { return { type: CREATING_EVENT_FAILURE, error: err } }
export function startCreateEvent() { return { type: START_CREATING_EVENT } }
export function createEvent(eventType, event) {
    return (dispatch) => {
        dispatch(creatingEvent());
        Events.create(eventType, event)
            .then(data => {
                const event = data.event;
                dispatch(creatingEventSuccess(event));
            })
            .catch(err => {
                dispatch(creatingEventFailure(err))
            });
    }
}

function copyingEvent() { return { type: COPYING_EVENT } }
function copyingEventSuccess(event) { return { type: COPYING_EVENT_SUCCESS, event: event } }
function copyingEventFailure(err) { return { type: COPYING_EVENT_FAILURE, error: err } }
export function startCopyEvent() { return { type: START_COPYING_EVENT } }
export function copyEvent(eventType, id) {
    return (dispatch) => {
        dispatch(copyingEvent());
        Events.copy(eventType, id)
            .then(data => {
                const event = data.event;
                dispatch(copyingEventSuccess(event));
            })
            .catch(err => {
                dispatch(copyingEventFailure(err))
            });
    }
}

function deletingEvent() { return { type: DELETING_EVENT } }
function deletingEventSuccess() { return { type: DELETING_EVENT_SUCCESS } }
function deletingEventFailure(err) { return { type: DELETING_EVENT_FAILURE, error: err } }
export function deleteEvent(eventType, eventId) {
    return (dispatch) => {
        dispatch(deletingEvent());
        Events.delete(eventType, eventId)
            .then(data => {
                dispatch(deletingEventSuccess());
            })
            .catch(err => {
                dispatch(deletingEventFailure(err))
            });
    }
}

export const getEventsLinkedResources = linkedResourcesAction;
