import React from 'react';
import Icon from '@mdi/react';

import './CollectorTransactions.css';

const CollectorTransactions = ({ collector, className, ...rest }) => {
    const transactions = collector.state.lastTransactions;

    if (!transactions.length)
        return null;

    return (
        <div className={ `CollectorTransactions ${className}` } { ...rest }>
        { transactions && transactions.map((t, i) => (
            <div key={ i } className="event">
                <div className="left">
                    <div className="icon">
                        <Icon path={ t.getIcon() } size={ 1.8 } color="#FFFFFF" />
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        { t.getText() }
                    </div>
                    <div className="datetime">
                        { t.getCreatedAt() }
                    </div>
                </div>
            </div>
        ))}
        </div>
    );
}

export default CollectorTransactions;
