import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import FileUpload from '../../../components/FileUpload/FileUpload';
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import PostForm from '../PostForm/PostForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { startCreatePost, createPost } from '../../../redux/modules/posts';

const PostCreate = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startCreatePost());
    }, [dispatch])

    const submit = (values) => {
        dispatch(createPost(values));
    }

    const { postSuccessfullyCreated } = useSelector(state => state.posts);

    let title = <span>{t('posts.add')}</span>;

    const actions =
        <Button tag={Link} to="/posts" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="PostCreate">
            <PageTitle icon="th" title={ title } actions={ actions } />

            { postSuccessfullyCreated &&
                <Redirect to="/posts"/>
            }

            <PostForm onSubmit={ submit } isCreation="true" />
        </div>
    );
}

export default PostCreate
