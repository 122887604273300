import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';
import { Utils } from '../../../../services';
import CountriesField from '../../../CountriesField/CountriesField';
import MoneyField from '../../../MoneyField/MoneyField';
import PercentageField from '../../../PercentageField/PercentageField';

import './PaymentMethodRuleForm.css';

const PaymentMethodRuleForm = ({ name, rule, method }) => {
    const { t }        = useTranslation();

    const { isMobile, isTablet } = useSelector(state => state.ui);

    return (
        <div className="PaymentMethodRuleForm">
            <Row>
                <Col sm={12} xl={6}>
                    <FormGroup row>
                        <Label for="name" className={ !isMobile && !isTablet ? "text-right" : ""} sm={6}>{t('settings.payment.method.price')}</Label>
                        <Col sm={6}>
                            <MoneyField name={ `${name}.price.${Utils.currency()}` } />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="name" className={ !isMobile && !isTablet ? "text-right" : ""} sm={6}>{t('settings.payment.method.percentage')}</Label>
                        <Col sm={6}>
                            <PercentageField name={ `${name}.price.percentage` } />
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={12} xl={6}>
                    <FormGroup row>
                        <Label for="name" className={ !isMobile && !isTablet ? "text-right" : ""} sm={6}>{t('settings.payment.method.min_cart_amount')}</Label>
                        <Col sm={6}>
                            <MoneyField name={ `${name}.min_cart_amount.${Utils.currency()}` } />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="countries" className={ !isMobile && !isTablet ? "text-right" : ""} sm={6} >{`${t("settings.payment.method.countries")}`}</Label>
                        <Col sm={6}>
                            <CountriesField name={ `${name}.countries` } />
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default PaymentMethodRuleForm;
