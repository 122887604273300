import React, { Component } from 'react';

import './PageTitle.css';

class PageTitle extends Component {
    render() {
        const Tag           = this.props.tag || 'h3';
        const iconClassname = "fa fa-" + this.props.icon;
        return (
            <Tag className="PageTitle">
                <div className="clearfix" style={{ padding: '.5rem' }}>
                    <div className="float-left">
                        <i className={ iconClassname }></i>&nbsp;
                        { this.props.title }
                    </div>
                    <div className="float-right">
                        { this.props.actions}
                    </div>
                </div>
                { this.props.children }
            </Tag>
        );
    }
}

export default PageTitle;
