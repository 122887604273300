import React, { useState } from 'react';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';

import './PlusMinusInput.css';

const PlusMinusInput = (props) => {
    const {
        title, defaultValue, min, max,
        className, onChange, hideButtons,
        ...rest
    } = props;
    const [value, setValue] = useState(defaultValue);

    const isMinusButtonDisabled = (value <= (min || 0));
    const isPlusButtonDisabled  = (max && value >= max);

    const increment = () => { changed(value + 1); };
    const decrement = () => { changed(value - 1); };

    const changed = (newValue) => {
        setValue(newValue);
        onChange && onChange(newValue);
    };

    return (
        <div className={ `PlusMinusInput ${className}` } { ...rest }>
            <div className="title">
                { title }
            </div>
            <div className="inputs">
                <InputGroup>
                    {!hideButtons && (
                        <>
                            <InputGroupAddon addonType="prepend">
                                { isMinusButtonDisabled && <Button color="dark" disabled>-</Button> }
                                { !isMinusButtonDisabled && <Button color="primary" onClick={ decrement }>-</Button> }
                            </InputGroupAddon>
                            <Input type="number" size="3" value={ value } onChange={(e) => changed(e.target.value)}/>
                            <InputGroupAddon addonType="append">
                                { isPlusButtonDisabled && <Button color="dark" disabled>+</Button> }
                                { !isPlusButtonDisabled && <Button color="primary" onClick={ increment }>+</Button> }
                            </InputGroupAddon>
                        </>
                    )}
                    {hideButtons && (
                    <Input type="number" size="3" value={ value } readonly />
                    )}
                </InputGroup>
            </div>
        </div>
    );
}

export default PlusMinusInput;
