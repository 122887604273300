import RestClient from './RestClient';
import { EmailLayout } from '../../models';
import _ from 'lodash';

class EmailLayouts extends RestClient {
    constructor() {
        super({
            "model": EmailLayout,
            "entryPoint": "layout",
            "resource": "layout",
            "resources": "layouts",
            "id_field": "_id",
        });
    }

    list(params) {
        // remove empty filter values
        params = _.pickBy(params, _.identity) || {};
        params.layout_type = 'email';
        return super.list(params);
    }
}

const EmailLayoutsApi = new EmailLayouts();

export default EmailLayoutsApi;
