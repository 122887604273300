import BaseModel from './BaseModel';
import _ from 'lodash';

/** Class representing a bucket. */
class Bucket extends BaseModel {
    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = _.cloneDeep(this);

        prepared.rules = prepared.rules || {};

        if ((prepared.total_capacity + "").match(/^[\d]+$/))
            prepared.total_capacity = parseInt(prepared.total_capacity);

        [ 'not_before', 'not_after' ].map(property => {
            if (_.isEmpty(prepared.rules[property]))
                delete(prepared.rules[property]);
        });

        return prepared;
    }
}

export default Bucket;
