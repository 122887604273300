import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Field, FieldArray, submit } from 'redux-form';
import { Row, Col, Button, CardBody, Card, UncontrolledTooltip } from 'reactstrap';
import { Utils } from '../../services';
import { Availability } from '../../models';
import { DateTimePicker } from '../../components';
import moment from 'moment';
import _ from 'lodash';

import './AvailabilitiesField.css';

const AvailabilitiesField = ({ name, preventSubmit, phases, ...rest }) => {
    const dispatch    = useDispatch();
    const { t, i18n } = useTranslation();

    const Component = ({ fields, meta: { form }}) => {

        const availabilities = (fields?.getAll() || []).map(props => new Availability(props));

        const remove = (index) => {
            fields.remove(index);
            !preventSubmit && setTimeout(() => dispatch(submit(form)), 10);
        };

        const add = (availability) => {
            let start_at = moment().add(1, 'day').startOf('day').add(10, 'hours');
            let stop_at = moment().add(1, 'day').startOf('day').add(18, 'hours');

            const availabilities = (fields?.getAll() || []).map(props => new Availability(props));
            if (availabilities?.length > 0) {
                const last = availabilities[availabilities.length - 1];
                start_at = last.start_at.add(1, 'day');
                stop_at = last.stop_at.add(1, 'day');
            }
            fields.push(new Availability({ start_at, stop_at }));
            !preventSubmit && setTimeout(() => dispatch(submit(form)), 10);
        };

        const isValidDate = (currentDate) => {
            if (!phases?.length)
                return true;

            let result = false;

            phases.forEach(phase => {
                const min = moment(phase.start_at);
                min.startOf('day');

                const max = moment(phase.stop_at);
                max.endOf('day');

                result = result || (
                    !currentDate.isBefore(min) &&
                    !currentDate.isAfter(max)
                );
            });

            return result;
        };

        return (
            <div className="AvailabilitiesField">
                <Row>
                    <Col sm={12}>
                        {availabilities.sort((a, b) => a.start_at.isBefore(b.start_at) ? 1 : -1).map((a, index) => (
                            <div key={index} className="availability">
                                <span>{ t('availabilities.from') }</span>
                                <Field name={`availabilities.${index}.start_at`} props={{ validate: isValidDate }} component={DateTimePicker} />
                                <span>{ t('availabilities.to') }</span>
                                <Field name={`availabilities.${index}.stop_at`} props={{ validate: isValidDate }} component={DateTimePicker} />
                                <Button color="danger" onClick={() => remove(index)}>
                                    <i className="fa fa-trash"></i>&nbsp;
                                </Button>
                            </div>
                        ))}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Button color="info" onClick={add}>
                            <i className="fa fa-plus"></i>&nbsp;
                            {t('availabilities.add_an_availability')}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <FieldArray name={ name } component={ Component } />
    );
}

export default AvailabilitiesField;
