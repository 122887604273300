import React from "react";
import ZoneSeat from './ZoneSeat';

const BlankSeat = ({ rowIndex, columnIndex, onClick }) => {
    return (
        <ZoneSeat
            style={{width: 30, height: 30}}
            key={ rowIndex }
            onClick={ () => { onClick(rowIndex,columnIndex) }}
        />
    )
};

export default BlankSeat;
