import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import PostsTable from './PostsTable/PostsTable';
import PostcategoriesBrowser from './PostcategoriesBrowser/PostcategoriesBrowser';
import PageTitle from '../../../components/PageTitle/PageTitle';
import SearchBloc from '../../../components/SearchBloc/SearchBloc';
import 'react-table-6/react-table.css'
import { loadPostcategories } from '../../../redux/modules/post_categories';
import { loadPosts, setCurrentCategory } from '../../../redux/modules/posts';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import { loadTags } from '../../../redux/modules/tags';
import CreateButton from '../../../components/CreateButton';

import './PostsList.css';

const PostsList = () => {
    const dispatch            = useDispatch();
    const { t }               = useTranslation();
    const { salepoints }      = useSelector(state => state.salepoints);
    const { tags }            = useSelector(state => state.tags);
    const { post_categories } = useSelector(state => state.post_categories);
    const textSearch          = useSelector(state => state.search.text.scopes.posts);
    const tagsSearch          = useSelector(state => state.search.tags.scopes.posts) || [];

    const {
        posts, currentCategory,
        hasLoadingError, loadingErrorMessage,
    } = useSelector(state => state.posts);

    useEffect(() => {
        dispatch(loadPostcategories());
        dispatch(loadTags());
        dispatch(loadSalepoints());
    }, [dispatch]);

    const selectCategory = (category) => {
        dispatch(setCurrentCategory(category));
    }

    useEffect(() => {
        (textSearch || tagsSearch.length > 0 || currentCategory) && dispatch(loadPosts({
            'search': textSearch,
            'tags': tagsSearch.map(tag => tag._id).join(','),
            'category_ids': currentCategory ? currentCategory.id : null
        }));
    }, [dispatch, textSearch, tagsSearch.length, currentCategory]);

    const posts_title = (<span>{t("posts.post_plural")}</span>);
    const posts_actions =
        <CreateButton
            module={`posts`}
            text="posts.add"
        />

    return (
        <div className="PostsList">
            <PageTitle icon="list-ul" title={posts_title} actions={ posts_actions } />
            <Row>
                <Col className="post_categories-browser" sm="3">
                    <div className="filters-wrapper">
                        <SearchBloc
                            scope="posts"
                            title={ t('posts.search') }
                            textTitle={ t('posts.title') }
                            textPlaceholder={ t('posts.search_placeholder') }
                            tags={ tags }
                        />
                    </div>
                    { post_categories && post_categories.length > 0 && (
                    <div className="filters-wrapper">
                        <PostcategoriesBrowser
                            post_categories={ post_categories }
                            active={ currentCategory }
                            onSelect={ selectCategory }
                        />
                    </div>
                    )}
                </Col>
                <Col className="posts-list" sm="9">
                    { post_categories && post_categories.length === 0&& (
                    <Link className="message create-category-text" tag={Link} to="/post_categories/new" color="success" size="sm">
                        <i className="fa fa-plus"></i>&nbsp;{t("posts.please_create_category")}
                    </Link>
                    )}
                    { post_categories && post_categories.length > 0 && !currentCategory && !textSearch && !tagsSearch.length > 0 && (
                    <h4 className="message choose-category-text">
                        <i className="fa fa-arrow-circle-left"></i>
                        { t('posts.please_choose_category_or_make_a_search') }
                    </h4>
                    )}
                    { (currentCategory || textSearch || tagsSearch.length > 0) && (
                    <PostsTable
                        posts={ posts }
                        salepoints={ salepoints }
                        hasLoadingError={ hasLoadingError }
                        loadingErrorMessage={ loadingErrorMessage }
                    />
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default PostsList;
