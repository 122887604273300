import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { TranslatableField } from '../TranslatableField';

import './EmailField.css';

const EmailField = ({ name, placeholder, translatable }) => {
    const { t } = useTranslation();

    return (
        <div className='EmailField'>
            {translatable ?
                <TranslatableField
                    component='input'
                    name={name}
                    type="email"
                    className='form-control email'
                    onInvalid={e => e.target.setCustomValidity(t("users.invalid_email"))}
                    onInput={e => e.target.setCustomValidity(t(""))}
                    placeholder={placeholder ?? t('users.email_placeholder')}
                />
                :
                <Field
                    component='input'
                    name={name}
                    type="email"
                    className='form-control email'
                    onInvalid={e => e.target.setCustomValidity(t("users.invalid_email"))}
                    onInput={e => e.target.setCustomValidity(t(""))}
                    placeholder={placeholder ?? t('users.email_placeholder')}
                />
            }
        </div>
    )
}

export default EmailField;
