import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { ButtonGroup, Button,  Card, CardImg, CardBody, CardFooter } from 'reactstrap';
import ImageTypesField from '../ImageTypesField/ImageTypesField';
import LangsField from '../LangsField/LangsField';
import { Field } from 'redux-form'

import './InlineImageField.css';

const InlineImageField = ({ name, image, hideOptions, onDuplicate, onRemove }) => {
    const { t } = useTranslation();
    const [ data, setData ] = useState(image?.rfc2397_data);

    useEffect(() => {
        setData(image?.rfc2397_data);
    }, [ image ]);

    let fieldOnChange;
    const dataComponent = ({ input }) => {
        fieldOnChange = input.onChange;
        return <input type="hidden" name={ input.name } value={ data } />;
    };

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const dataUrl = reader.result;
                setData(dataUrl);
                if (fieldOnChange)
                    fieldOnChange(dataUrl);
            }
            reader.readAsDataURL(file)
        })
    }, [])

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ onDrop });

    return (
        <div className="InlineImageField">
            <Card>
                <div className="img-wrapper">
                    <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <p>{ t('common.drag_one_file') }</p>
                        { data && (
                            <CardImg top width="100%" src={ data } alt="Card image cap" />
                        )}
                    </div>
                    <Field component={ dataComponent } type="hidden" className="form-control" name={ `${name}.rfc2397_data` } />
                </div>
                { !hideOptions && (
                <CardBody>
                    <ImageTypesField width="100%" component="input" type="text" className="form-control" name={`${name}.type`} usage="setting" />
                    <LangsField name={`${name}.lang`} />
                </CardBody>
                )}
                { (onDuplicate || onRemove) && (
                <CardFooter>
                    <ButtonGroup>
                        { onDuplicate && (
                        <Button color="info" size="sm" onClick={ onDuplicate }>
                            <i className="fa fa-copy"></i>&nbsp;{t("common.duplicate")}
                        </Button>
                        )}
                        { onRemove && (
                        <Button color="danger" size="sm" onClick={ onRemove }>
                            <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                        </Button>
                        )}
                    </ButtonGroup>
                </CardFooter>
                )}
            </Card>
        </div>
    );
}

export default InlineImageField;
