import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { Field, submit } from 'redux-form'
import { Utils } from '../../services/';
import { withTranslation } from 'react-i18next';

import './DaysField.css';

class DaysField extends Component<{}> {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    toggle(index) {
        const { input, days, meta: { form }, dispatchSubmitForm } = this.props;

        if (days.indexOf(index) !== -1) {
            days.splice(days.indexOf(index), 1);
        }
        else {
            days.push(index);
        }
        input.value = [];
        days.forEach((d) => input.value.push(d));
        this.setState({});
    }

    render() {
        const { t, fields, days, onDelete } = this.props;

        return (
            <div className="DaysField">
                <div className="day-choices">
                    { Utils.days().map((day) => (
                        <span
                            key={day.index}
                            onClick={(e) => { this.toggle(day.index) }}
                            className={"day-choice" + (days.indexOf(day.index) != -1 ? " active" : "")}
                        >
                            <span className="day">
                                { t('common.' + day.label) }
                            </span>
                        </span>
                    ))}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchSubmitForm: (form) => submit(form)
}
const mapStateToProps = state => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(DaysField))
