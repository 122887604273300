import React from 'react';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from "i18next";

const UsersField = ({ users, single, name }) => {

    const default_lang = Utils.default_lang();

    const options = users.sort((a, b) => a.fullname.localeCompare(b.fullname)).map((user) => ({
        key: user.id,
        text: `${user.fullname} (${user.email})`,
        value: user.id
    }));

    return single ? (
        <SelectField name={ name } options={ options } />
    ) : (
        <MultiSelectField name={ name } options={ options } />
    );
}

export default UsersField;
