import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import PostForm from '../PostForm/PostForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Utils } from '../../../services';

import { loadPost, updatePost } from '../../../redux/modules/posts';

const PostEdit = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const postId = match.params.postId;
        // Load postId on component mount
        dispatch(loadPost(postId));
    }, [dispatch])

    const submit = (values) => {
        dispatch(updatePost(values));
    }

    const { post } = useSelector(state => state.posts);

    let title = <span>{t('posts.update_post')}</span>;

    if (post && post.title)
        title = (
            <span>
                {t('posts.post')}
                <small> |&nbsp;{ t('posts.update_post', { name: post.title[Utils.default_lang()] })} </small>
            </span>
        );

    const actions =
        <div>
            {post &&
            <ButtonGroup>
                <Button  tag={Link} to="/posts" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="PostEdit">
                <PageTitle icon="tags" title={ title } actions={ actions } />

                {post &&
                    <PostForm postId={post._id} onSubmit={ submit } />
                }
            </div>
        );
}

export default PostEdit;
