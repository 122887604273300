import RestClient from './RestClient';
import { ApplicationType, FormLayout } from '../../models';

class ApplicationTypes extends RestClient {
    constructor() {
        super({
            "model": ApplicationType,
            "entryPoint": "application_type",
            "resource": "application_type",
            "resources": "application_types",
            "id": "id"
        });
    }

    getForm(application_type_id, tkt_instance_id) {
        return this.GET(`/application_type/form/id/${application_type_id}/tkt_instance/${tkt_instance_id}`)
            .then(response => response.json())
            .then(json => {
                if (json.form) {
                    json.form = new FormLayout(json.form);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const ApplicationTypesApi = new ApplicationTypes();

export default ApplicationTypesApi;
