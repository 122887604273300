import BaseModel from './BaseModel';

/** Class representing an post. */
class Post extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
Post.getListingFields = () => [];

export default Post;
