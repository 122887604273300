import React from 'react';
import { Row, Col } from 'reactstrap';

import './Widget.css';

const Widget = ({ clickable, border, roundBorder, content, noCenter }) => {
    return (
        <div
            className={`Widget ${clickable && "widgetClickable"} ${!noCenter && "widgetCenter"}`}
            style={{ border: border && `${border} 1px`, borderRadius: roundBorder && "6px" }}
        >
            {content}
        </div>
    )
}

export default Widget;
