import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import TktInstanceForm from '../TktInstanceForm/TktInstanceForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateTktInstance, createTktInstance } from '../../../redux/modules/tktInstances';

class TktInstanceCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        // Reset uer in app state
        this.props.dispatchStartCreateTktInstance();
    }

    submit(values) {
        this.props.dispatchCreateTktInstance(values);
    }

    render() {
        const { tktInstanceSuccessfullyCreated } = this.props.tktInstances;
        const { t } = this.props;

        let title = <span>{t('tktInstances.add')}</span>;

        const actions =
            <Button tag={Link} to="/tktInstances" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="TktInstanceCreate">
                <PageTitle icon="tktInstance-o" title={ title } actions={ actions } />

                {tktInstanceSuccessfullyCreated &&
                    <Redirect to="/tktInstances"/>
                }

                <TktInstanceForm onSubmit={this.submit} isCreation />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateTktInstance: (tktInstance) => startCreateTktInstance(tktInstance),
    dispatchCreateTktInstance: (tktInstance) => createTktInstance(tktInstance)
}

const mapStateToProps = state => ({
    tktInstances: state.tktInstances,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(TktInstanceCreate))
