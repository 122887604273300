import React, { useState } from 'react';
import { Row, Col, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import _ from 'lodash';

import './HoursField.css';

const HoursField = ({ input }) => {
    const [newTime, setNewTime] = useState('');

    const handle_key_down = (e) => {
        const key = e.key

        if (key === 'Enter') {
            e.preventDefault();
            add();
            return;
        }

        if (/^\d|:$/.test(key) && newTime.length < 5)
        {
            let newString = newTime + key

            if (newString.length === 2 && newString.search(/:/) === -1)
                newString += ":";

            if (newString.length === 3 && newString.search(/:/) === -1)
                newString = newString.slice(0, 2) + ":" + newString.slice(2);

            setNewTime(newString)

        } else if(key === "Backspace" && newTime.length > 0) {
            setNewTime(newTime.slice(0, -1))

        } else {
            e.preventDefault();
            return false;
        }
    }

    const add = () => {
        // check time format
        const regex    = /([0-1][0-9]?|[2][0-3])[:][0-5][0-9]/;
        let   tempTime = newTime;
        const values   = input.value;

        if (/^\d:\d{2}$/.test(tempTime))
            tempTime = "0" + tempTime;

        switch (tempTime.length) {
            case 1:
                tempTime = "0" + tempTime + ":00";
                break;
            case 2:
                tempTime += ":00"
                break;
            case 3:
                tempTime += "00"
                break;
            case 4:
                tempTime += "0"
                break;
        }
        const matches = tempTime.match(regex);
        if (matches) {
            values.push(tempTime);
            input.onChange(values.map(v => v));
        }
        setNewTime("");
    }

    const remove = (i) => {
        const temp = _.cloneDeep(input.value);
        temp.splice(i, 1);
        input.onChange(temp);
        setNewTime("");
    }

    return (
        <div className="HoursField">
            <Row>
                <Col>
                    <InputGroup>
                        <Input onKeyDown={handle_key_down} value={newTime} placeholder="17:30"/>
                        <InputGroupAddon addonType="append">
                            <Button type="button" color="info" size="sm" onClick={add}>
                                <span> <i className="fa fa-plus"></i>&nbsp;</span>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className="times">
                    {input?.value && input.value.map((t, i) =>
                        <a key={i} onClick={(e) => {remove(i)}}>
                            <span className="icon"><i className="fa fa-trash"></i>&nbsp;</span>
                            <span className="time">{t}</span>
                        </a>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default HoursField;
