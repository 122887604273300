// Redux Phases module
// see https://github.com/erikras/ducks-modular-redux

import { Phases } from '../../services';
import _ from 'lodash';

const LOADING_PHASES         = 'kronos/phases/LOADING_PHASES';
const LOADING_PHASES_SUCCESS = 'kronos/phases/LOADING_PHASES_SUCCESS';
const LOADING_PHASES_FAILURE = 'kronos/phases/LOADING_PHASES_FAILURE';
const LOADING_PHASE          = 'kronos/phases/LOADING_PHASE';
const LOADING_PHASE_SUCCESS  = 'kronos/phases/LOADING_PHASE_SUCCESS';
const LOADING_PHASE_FAILURE  = 'kronos/phases/LOADING_PHASE_FAILURE';
const UPDATING_PHASE         = 'kronos/phases/UPDATING_PHASE';
const UPDATING_PHASE_SUCCESS = 'kronos/phases/UPDATING_PHASE_SUCCESS';
const UPDATING_PHASE_FAILURE = 'kronos/phases/UPDATING_PHASE_FAILURE';
const START_CREATING_PHASE   = 'kronos/phases/START_CREATING_PHASE';
const CREATING_PHASE         = 'kronos/phases/CREATING_PHASE';
const CREATING_PHASE_SUCCESS = 'kronos/phases/CREATING_PHASE_SUCCESS';
const CREATING_PHASE_FAILURE = 'kronos/phases/CREATING_PHASE_FAILURE';
const DELETING_PHASE         = 'kronos/phases/DELETING_PHASE';
const DELETING_PHASE_SUCCESS = 'kronos/phases/DELETING_PHASE_SUCCESS';
const DELETING_PHASE_FAILURE = 'kronos/phases/DELETING_PHASE_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    phaseSuccessfullyCreated: false,
    phases: [],
    phase: {}
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_PHASES:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_PHASES_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                phases: action.phases
            };
        case LOADING_PHASES_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_PHASE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_PHASE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                phase: action.phase
            };
        case LOADING_PHASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_PHASE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_PHASE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                phase: action.phase
            };
        case UPDATING_PHASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_PHASE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                phaseSuccessfullyCreated: false,
                phase: {}
            };
        case CREATING_PHASE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_PHASE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                phaseSuccessfullyCreated: true,
                phase: action.phase
            };
        case CREATING_PHASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                phaseSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_PHASE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_PHASE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                phaseSuccessfullyDeleted: true,
                phase: action.phase
            };
        case DELETING_PHASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                phaseSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingPhases() { return { type: LOADING_PHASES } }
function loadingPhasesSuccess(phases) { return { type: LOADING_PHASES_SUCCESS, phases: phases } }
function loadingPhasesFailure(err) { return { type: LOADING_PHASES_FAILURE, error: err } }
export function loadPhases() {
    return (dispatch) => {
        dispatch(loadingPhases());
        Phases.list()
            .then(data => {
                const phases = data.phases;
                dispatch(loadingPhasesSuccess(phases));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingPhasesFailure(err))
            });
    }
}

function loadingPhase() { return { type: LOADING_PHASE } }
function loadingPhaseSuccess(phase) { return { type: LOADING_PHASE_SUCCESS, phase: phase } }
function loadingPhaseFailure(err) { return { type: LOADING_PHASE_FAILURE, error: err } }
export function loadPhase(phaseId) {
    return (dispatch) => {
        dispatch(loadingPhase());
        Phases.get(phaseId)
            .then(data => {
                const phase = data.phase;
                dispatch(loadingPhaseSuccess(phase));
            })
            .catch(err => {
                dispatch(loadingPhaseFailure(err))
            });
    }
}

function updatingPhase() { return { type: UPDATING_PHASE } }
function updatingPhaseSuccess(phase) { return { type: UPDATING_PHASE_SUCCESS, phase: phase } }
function updatingPhaseFailure(err) { return { type: UPDATING_PHASE_FAILURE, error: err } }
export function updatePhase(phase) {
    return (dispatch) => {
        dispatch(updatingPhase());
        Phases.update(phase)
            .then(data => {
                const phase = data.phase;
                dispatch(updatingPhaseSuccess(phase));
            })
            .catch(err => {
                dispatch(updatingPhaseFailure(err))
            });
    }
}

function creatingPhase() { return { type: CREATING_PHASE } }
function creatingPhaseSuccess(phase) { return { type: CREATING_PHASE_SUCCESS, phase: phase } }
function creatingPhaseFailure(err) { return { type: CREATING_PHASE_FAILURE, error: err } }
export function startCreatePhase() { return { type: START_CREATING_PHASE } }
export function createPhase(phase) {
    return (dispatch) => {
        dispatch(creatingPhase());
        Phases.create(phase)
            .then(data => {
                const phase = data.phase;
                dispatch(creatingPhaseSuccess(phase));
                dispatch(loadingPhaseSuccess(phase));
            })
            .catch(err => {
                dispatch(creatingPhaseFailure(err))
            });
    }
}

function deletingPhase() { return { type: DELETING_PHASE } }
function deletingPhaseSuccess() { return { type: DELETING_PHASE_SUCCESS } }
function deletingPhaseFailure(err) { return { type: DELETING_PHASE_FAILURE, error: err } }
export function deletePhase(phaseId) {
    return (dispatch) => {
        dispatch(deletingPhase());
        Phases.delete(phaseId)
            .then(data => {
                dispatch(deletingPhaseSuccess());
            })
            .catch(err => {
                dispatch(deletingPhaseFailure(err))
            });
    }
}
