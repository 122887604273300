import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { Utils } from '../../services';
import _ from 'lodash';

import './TranslatableField.css';

const TranslatableFieldWithInput = ({ name, placeholder, onChange, type }) => {
    const langs                           = Utils.langs();
    const [selectedLang, setSelectedLang] = useState(langs[0]);
    const [inputText, setInputText]       = useState(name);

    const select_lang = (lang) => {
        setSelectedLang(lang);
    }

    const changeValue = (event) => {
        let temp = _.cloneDeep(inputText);
        temp[selectedLang] = event.target.value;
        setInputText(temp);
    }

    useEffect(() => {
        onChange(inputText)
    }, [inputText])

    return (
        <div className="TranslatableField">

            <div className="lang-switchers">
                {langs.length > 1 && langs.map((l) => (
                    <a key={l} onClick={(e) => { select_lang(l); }} className={"lang-switcher" + (selectedLang === l ? " active" : "")}>
                        {l.toUpperCase()}
                    </a>
                ))}
            </div>

            {langs.length && langs.map((l, i) => (
                <div key={l} className="translatable-field-wrapper" style={{ display: selectedLang === l ? 'initial' : 'none' }}>
                    <Input defaultValue={inputText[l]} placeholder={placeholder} onChange={changeValue} type={type} />
                </div>
            ))}

        </div>
    );
}

export default TranslatableFieldWithInput;
