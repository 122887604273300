import React, { useMemo } from 'react';
import { Utils } from '../../../../services/';
import _ from 'lodash';
import moment from 'moment';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css'

import './TasksListResume.css';
import { useTranslation } from 'react-i18next';

function TasksListResume({ tasks, filters }) {
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();
    const users        = _.sortBy(_.uniqBy(tasks.filter((t) => !!t.user).map((t) => t.user), (u) => u.id), (u) => u.fullname);
    const activities   = _.sortBy(_.uniqBy(tasks.map((t) => t.activity), (a) => a.id), (a) => a.name[default_lang]);

    const rows = useMemo(() => {
        let result = {};
        if (tasks.length > 0)
            tasks.map((t) => {
                if (!t.user)
                    return;

                const { activity, user } = t;

                if (!result[user.id])
                    result[user.id] = {};

                const duration = moment.duration(moment(t.stop_at).diff(moment(t.start_at))).asHours();

                result.total       = (result.total || 0) + 1;
                result.total_hours = (result.total_hours || 0) + duration;

                result[user.id].total       = (result[user.id].total || 0) + 1;
                result[user.id].total_hours = (result[user.id].total_hours || 0) + duration;

                if (!result[activity.id])
                    result[activity.id] = {};
                result[activity.id].total       = (result[activity.id].total || 0) + 1;
                result[activity.id].total_hours = (result[activity.id].total_hours || 0) + duration;

                if (!result[user.id][activity.id])
                    result[user.id][activity.id] = {};
                result[user.id][activity.id].total       = (result[user.id][activity.id].total || 0) + 1;
                result[user.id][activity.id].total_hours = (result[user.id][activity.id].total_hours || 0) + duration;
            });
        return result;
    }, [tasks])

    const headers = useMemo(() => {
        let result = []
        result.push({
            Header: t('users.user'),
            filterable: false,
            accessor: "user",
            Cell: row => (row.value ? <>{row.value}</> : <></>),
            Footer: <b>Total</b>
        });
        activities.map(a => {
            result.push({
                Header: a.name[default_lang],
                filterable: false,
                accessor: a.id,
                Cell: row => (row.value ? <>{row.value}</> : <></>),
                Footer: <b>{rows[a.id]?.total ?? '0'} ({(rows[a.id]?.total_hours ?? 0).toFixed(1)}h)</b>
            })
        })
        result.push({
            Header: t('statstypes.total'),
            filterable: false,
            accessor: "total",
            Cell: row => (row.value ? <>{row.value}</> : <></>),
            Footer: row => <b>{rows?.total ?? '0'} ({(rows?.total_hours ?? 0).toFixed(1)})</b>
        });
        return result;
    }, [rows]);

    const data = useMemo(() => {
        let result = [];
        if (Object.keys(rows).length > 1) {
            users.map((user, i) => {
                result[i] = {}
                result[i].user = user.fullname;
                activities.map(a => {
                    result[i][a.id] = `${rows[user.id][a.id]?.total || '0'} (${(rows[user.id][a.id]?.total_hours || 0).toFixed(1)}h)`;
                })
                result[i].total = `${rows[user.id].total || 0} (${(rows[user.id].total_hours || 0).toFixed(1)}h)`;
            })
        }
        return result;
    }, [rows]);

    return (
        <div className="TasksListResume">
            {(data && headers) &&
                <ReactTable
                    columns={headers}
                    data={data}
                    showPagination={false}
                    pageSize={data.length}
                    sortable={false}
                />
            }
            <ul className="mt-2">
                <li>
                    <b>Période</b>: {moment(filters.start_at_gte).format('DD/MM/Y')} - {moment(filters.start_at_lte).format('DD/MM/Y')}
                </li>
                {filters.status && (
                    <li>
                        <b>{t('tasks.status')}</b>: {t('tasks.status_' + filters.status)}
                    </li>
                )}
            </ul>
        </div>
    )
}

export default TasksListResume;
