import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardFooter } from 'reactstrap';
import { FormGroup, Label, Jumbotron } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../../../services';
import TranslatableField from '../../../../components/TranslatableField/TranslatableField';
import MoneyField from '../../../../components/MoneyField/MoneyField';
import ArticleAttributeChoiceField from './ArticleAttributeChoiceField/ArticleAttributeChoiceField';
import classnames from 'classnames';

import {
    addArticleExtra, removeArticleExtra,
    addArticleExtraChoice, removeArticleExtraChoice
} from '../../../../redux/modules/articles';

import './ArticleExtras.css';

const ArticleExtras = ({ attributes, fields, submit, ...rest }) => {
    const { t }        = useTranslation();
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();
    const currency     = Utils.currency();

    const { article } = useSelector(state => state.articles);

    const add = () => {
        dispatch(addArticleExtra());
        submit();
    }

    const remove = (extraIndex) => {
        dispatch(removeArticleExtra(extraIndex));
        submit();
    }

    const addChoice = (extraIndex) => {
        dispatch(addArticleExtraChoice(extraIndex));
        submit();
    }

    const removeChoice = (extraIndex, choiceIndex) => {
        dispatch(removeArticleExtraChoice(extraIndex, choiceIndex));
        submit();
    }

    return (
        <Row>
            <Col>
                <Card className="ArticleExtras">
                    <CardBody>
                        { attributes && attributes.map((a, i) => !a.isExtra() ? null : (
                        <Jumbotron key={i} className="mb-4 extra">
                            <div className="extra-header">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Col sm={12} className="col-bottom-align">
                                                <Label for="name">{t("articles.extra.name")}</Label>
                                            </Col>
                                            <Col sm={12}>
                                                <TranslatableField component="input" required name={`attributes[${i}].name`} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Col sm={12} lg={12} className="col-bottom-align mt-4">
                                                <Label for="min_nb_choices">{t("articles.extra.min_nb_choices")}</Label>
                                            </Col>
                                            <Col sm={12}>
                                                <Field component="input" type="number" className="form-control" name={`attributes[${i}].min_nb_choices`} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Col sm={12} lg={12} className="col-bottom-align mt-4">
                                                <Label for="max_nb_choices">{t("articles.extra.max_nb_choices")}</Label>
                                            </Col>
                                            <Col sm={12}>
                                                <Field component="input" type="number" className="form-control" name={`attributes[${i}].max_nb_choices`} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <div className="extra-body">
                                <FormGroup row>
                                    <Col sm={12} lg={3} className="col-center-align pb-3">
                                        <Label for="choices">{t("articles.extra.choices")}</Label>
                                        <Button color="info" size="sm" onClick={ () => addChoice(i) }>
                                            <i className="fa fa-plus"></i>&nbsp;{t("articles.extra.add_choice")}
                                        </Button>
                                    </Col>
                                    { attributes[i].choices.length > 0 && (
                                    <Col sm={12} lg={9}>
                                        { attributes[i].choices.map((choice, j) => (
                                        <Row key={ j } className="extra-choice">
                                            <Col sm={12} lg={6}>
                                            { j + 1 } - <TranslatableField className="extra-choice-input" component="input" required name={`attributes[${i}].choices[${j}].name`} />
                                            </Col>
                                            <Col sm={12} lg={2} className="col-bottom-align">
                                                <MoneyField required name={`attributes[${i}].choices[${j}].price[${currency}]`} />
                                            </Col>
                                            <Col sm={12} lg={2} className="col-bottom-align">
                                                <Field component="select" className="form-control" type="text" name={`attributes[${i}].choices[${j}].default`} normalize={Utils.normalizeBoolean}>
                                                    <option>{t("articles.extra.default_choice")}</option>
                                                    <option value={false}>{t("common.no")}</option>
                                                    <option value={true}>{t("common.yes")}</option>
                                                </Field>
                                            </Col>
                                            <Col sm={12} lg={2} className="col-bottom-align">
                                                <Button color="danger" size="sm" onClick={ () => { removeChoice(i, j); }}>
                                                    <i className="fa fa-trash"></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                        ))}
                                    </Col>
                                    )}
                                </FormGroup>
                            </div>
                            <div className="extra-footer">
                                <Row className="text-center">
                                    <Col>
                                        <ButtonGroup>
                                            <Button color="danger" size="sm" onClick={ () => { remove(i); }}>
                                                <i className="fa fa-trash"></i>&nbsp;{t("articles.delete_extra")}
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Jumbotron>
                        )) }
                        <Row>
                            <Col className="text-center">
                                <ButtonGroup>
                                    <Button color="info" size="sm" onClick={ () => { add(); }}>
                                        <i className="fa fa-plus"></i>&nbsp;{t("articles.add_extra")}
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default ArticleExtras;
