import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardImg, CardFooter, CardTitle, CardText } from 'reactstrap';
import { Field, submit } from 'redux-form'
import { withTranslation } from 'react-i18next';

class ArticlePosters extends Component<{}> {
    remove(i) {
        const { fields, posters, meta: { form }, dispatchSubmitForm } = this.props;

        fields.remove(i);
        posters.splice(i, 1);
        dispatchSubmitForm(form);
    }

    render() {
        const { t, fields, posters, onDelete } = this.props;

        return (
            <div className="ArticlePosters">
                <Row>
                    {posters.map((p, i) =>
                    <Col key={i} xs="6" sm="4" md="3">
                        <Card>
                            <CardImg top width="100%" src={p.url} alt="Card image cap" />
                            <CardFooter>
                                <ButtonGroup>
                                    <Button color="danger" size="sm" onClick={ () => { this.remove(i); }}>
                                        <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                    </Button>
                                </ButtonGroup>
                                <Field component="input" type="hidden" className="form-control" name={`posters[${i}].id`} />
                            </CardFooter>
                        </Card>
                    </Col>
                    )}
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchSubmitForm: (form) => submit(form)
}
const mapStateToProps = state => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ArticlePosters))
