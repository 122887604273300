import RestClient from './RestClient';
import { Activity } from '../../models';

class Activities extends RestClient {
    constructor() {
        super({
            "model": Activity,
            "entryPoint": "activity",
            "resource": "activity",
            "resources": "activities"
        });
    }
}

const ActivitiesApi = new Activities();

export default ActivitiesApi;
