import BaseModel from './BaseModel';

/** Class representing a font. */
class Font extends BaseModel {
    boldVariant() {
        return this.variants?.bold?.name;
    }

    italicVariant() {
        return this.variants?.italic?.name;
    }

    boldItalicVariant() {
        return this.variants?.boldItalic?.name;
    }

    hasAnyVariant() {
        return (
            !!this.boldVariant() ||
            !!this.italicVariant() ||
            !!this.boldItalicVariant()
        );
    }
}

export default Font;
