import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { change } from 'redux-form';
import { Input, Row, Col } from 'reactstrap';
import _ from "lodash";
import { useDispatch } from 'react-redux';
import { TranslatableField } from '../TranslatableField';

const StringBoolean = ({ name, defaultValue, form, selectText, children }) => {
    const { t }               = useTranslation();
    const [ value, setValue ] = useState(defaultValue);
    const dispatch            = useDispatch();

    const handleValue = (e) => {
        switch (e) {
            case true:
            case "true":
                setValue(true);
                break;
            case false:
            case "false":
                setValue(false);
                break;
            case "other":
            default:
                setValue("other");
                break;
        }
    }

    useEffect(() => {
        handleValue(defaultValue);
    }, [defaultValue])

    useEffect(() => {
        if (value !== "other") {
            dispatch(change(form, name, value));
        }
    }, [dispatch, form, name, value])

    return (
        <div>
            <Row>
                <Col>
                    <Input
                        type="select"
                        className="form-control"
                        onChange={e => handleValue(e.target.value)}
                        value={value}
                    >
                        <option value={true}>
                            {selectText && selectText[0] ? t(selectText[0]) : t("common.yes")}
                        </option>
                        <option value={false}>
                            {selectText && selectText[1] ? t(selectText[1]) : t("common.no")}
                        </option>
                        <option value="other">
                            {selectText && selectText[2] ? t(selectText[2]) : t("common.other")}
                        </option>
                    </Input>
                </Col>
            </Row>
            {!_.isBoolean(value) &&
                <Row>
                    <Col>
                        { children ?? <TranslatableField name={name} component="input" value={value}/> }
                    </Col>
                </Row>
            }
        </div>
    );
};

export default StringBoolean;
