import React from 'react';
import Icon from '@mdi/react';

import './CollectorEvents.css';

const CollectorEvents = ({ collector, className, ...rest }) => {
    const events = collector.state.lastEvents;

    if (!events.length)
        return null;

    return (
        <div className={ `CollectorEvents ${className}` } { ...rest }>
        { events && events.map((e, i) => (
            <div key={ i } className="event">
                <div className="left">
                    <div className="icon">
                        <Icon path={ e.getIcon() } size={ 1.8 } color="#FFFFFF" />
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        { e.getText() }
                    </div>
                    <div className="datetime">
                        { e.getCreatedAt() }
                    </div>
                </div>
            </div>
        ))}
        </div>
    );
}

export default CollectorEvents;
