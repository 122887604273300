import React from "react";
import Wysiwyg from '../Wysiwyg/Wysiwyg';

import './WysiwygField.css';

const WysiwygField = ({ input }) => {
    return (
        <Wysiwyg defaultValue={input?.value} onChange={ input?.onChange } />
    );
}

export default WysiwygField;

