import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useParams, Link } from "react-router-dom";
import _ from 'lodash';
import 'react-table-6/react-table.css'
import TicketackUserForm from '../TicketackUserForm/TicketackUserForm';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import { loadTktUser, updateTktUser } from '../../../redux';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Button } from 'reactstrap';

const TicketackUserEdit = () => {
    const { t }          = useTranslation();
    const dispatch       = useDispatch();
    const { userId }     = useParams();
    const { salepoints } = useSelector(state => state.salepoints);
    const { tktuser }    = useSelector(state => state.tktusers);

    useEffect(() => {
        dispatch(loadSalepoints());
            dispatch(loadTktUser(userId))
    }, [])

    const handleSubmit = (values) => {
        const existing_salepoints_ids = salepoints.map(s => s._id);
        values.salepoints = _.intersection(values.salepoints, existing_salepoints_ids);
        dispatch(updateTktUser(values));
    }

    const title = tktuser &&
        <span>{tktuser?.name}</span>

    const actions =
        <div className="BackBtn">
            <Button tag={Link} to={"/ticketack_users/show/" + userId} color="danger"><i className="fa fa-times"></i>&nbsp;{t("widgets.cancel")}</Button>
        </div>

    return (
        <div className="TicketackUserEdit">
            <PageTitle title={title} actions={actions} />
            <TicketackUserForm userId={userId && userId} onSubmit={handleSubmit} />
        </div>
    );
}

export default TicketackUserEdit
