import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Widget from '../Widget/Widget';

import './MyLastEvents.css';

class MyLastEvents extends Component<{}> {
    render() {
        return (
            <Widget title="Mes derniers films" icon="film">
            </Widget>
        );
    }
}

export default MyLastEvents;

