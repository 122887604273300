import RestClient from './RestClient';
import { Bucketlist } from '../../models';

class Bucketlists extends RestClient {
    constructor() {
        super({
            "model": Bucketlist,
            "entryPoint": "bucketlist",
            "resource": "bucketlist",
            "resources": "bucketlists",
            "id_field": "_id",
            "sortBy": "name"
        });
    }
}

const BucketlistsApi = new Bucketlists();

export default BucketlistsApi;
