import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import GaugeChart from 'react-gauge-chart';
import _ from 'lodash';

import './CollectorStats.css';

const CollectorStats = ({ collector, className, ...rest }) => {
    const { t } = useTranslation();
    const statsObject = collector.state.lastStats;

    if (!statsObject)
        return null;

    const stats = [];
    _.mapKeys(statsObject, (values, module) => {
        stats.push({ module, ...values });
    });
    console.log(stats);

    return (
        <div className={ `CollectorStats ${className}` } { ...rest }>
            <Card className="card-outline">
                <CardHeader>
                    <CardTitle tag="h6">
                        { t('collectors.stats') }
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <div className="gauges">
                    { stats && stats.map(stat => (
                        <div className="gauge">

                            <div className="gauge-title">
                                { stat.module }
                                <div className="values">
                                    { stat.inter } / { stat.total }
                                </div>
                            </div>
                            <GaugeChart id={ `gauge-chart-${ module }` }
                                nrOfLevels={20}
                                percent={ stat.inter / stat.total  }
                                colors={ ["#FF0000", "#00FF00"] }
                            />
                        </div>
                    ))}
                    </div>
                </CardBody>
            </Card>
        </div>
    );
}

export default CollectorStats;
