import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import TicketackUsersList from './TicketackUsersList/TicketackUsersList';
import TicketackUserShow from './TicketackUserShow/TicketackUserShow';
import TicketackUserEdit from './TicketackUserEdit/TicketackUserEdit';
import TicketackUserCreate from './TicketackUserCreate/TicketackUserCreate';
import TicketackUserDelete from './TicketackUserDelete/TicketackUserDelete';

const TicketackUsers = ({ match }) => {
    return (
        <div className="TicketackUsers">

            <Route exact path={match.path} component={TicketackUsersList} />
            <Route exact path={`${match.url}/show/:userId/`} component={TicketackUserShow} />
            <Route exact path={`${match.url}/edit/:userId/`} component={TicketackUserEdit} />
            <Route exact path={ `${ match.url }/delete/:userId` } component={TicketackUserDelete} />
            <Route exact path={`${match.url}/new/`} component={TicketackUserCreate} />
        </div>
    );
}

export default TicketackUsers;
