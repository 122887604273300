import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadArticle, deleteArticle, resetDeleteArticle } from '../../../redux/modules/articles';

const ArticleDelete = ({ match }) => {
    const { t }        = useTranslation();
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();

    const { article, isLoading, articleSuccessfullyDeleted } = useSelector(state => state.articles);

    useEffect(() => {
        const { articleId } = match.params;
        dispatch(loadArticle(articleId));
    }, [dispatch]);

    useEffect(() => {
        if (articleSuccessfullyDeleted)
            dispatch(resetDeleteArticle());

    }, [dispatch, articleSuccessfullyDeleted]);

    const _deleteArticle = articleId => {
        dispatch(deleteArticle(articleId));
    }

    let title = <span>{t("bulidings.article_plural")}</span>;
    if (article)
        title = (
            <span>
                {t("articles.article")}
                <small> | { article.name[default_lang] } </small>
            </span>
        );

    const actions =
    <div>
        <ButtonGroup>
            <Button tag={Link} to="/articles" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
            </Button>
        </ButtonGroup>
    </div>

    return (
        <div className="ArticleDelete">
            <PageTitle icon="th" title={ title } actions={ actions } />

            {articleSuccessfullyDeleted &&
                <Redirect to="/articles"/>
            }

            {article &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('articles.confirm_delete')}</CardTitle>
                                <CardText tag="div">
                                    <div><b>{t("articles.name")}</b>: { article.name[default_lang] }</div>
                                    <Row>
                                        <Col className="text-center">
                                            <Button tag={Link} to="/articles" color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => _deleteArticle(article._id)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        { t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default ArticleDelete;
