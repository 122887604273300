import React from "react";
import EmailLayout from '../EmailLayout';
import EmailLayoutWidget from './EmailLayoutWidget';

import imagePlaceholder from '../../assets/images/imagePlaceholder.png';

/** Class representing a PDF layout image widget. */
class ImageWidget extends EmailLayoutWidget {

    constructor(properties) {
        super({
            ...(ImageWidget.getDefaultOptions()),
            ...properties,
            type: EmailLayout.IMAGE,
            subType: EmailLayout.CUSTOM
        });
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            options: {
                style: {
                    align: 'center',
                    objectFit: "contain",
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            EmailLayout.OPTION_IMAGE,
            EmailLayout.OPTION_OBJECT_FIT,
            EmailLayout.OPTION_ALIGNMENT,
            EmailLayout.OPTION_PADDING,
        ]);
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {EmailLayout} layout
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        const { inEdition, setting, layout } = props;
        let imageStyle = {};
        if (this.options.style?.objectFit === 'cover') {
            imageStyle = {
                width: '100%',
                height: '100%',
                borderRadius: `${this.options?.style?.borderRadius || 0}px`,
                objectFit: this.options.style?.objectFit
            };
        } else if (this.options.style?.objectFit === 'contain') {
            imageStyle = {
                width: '100%',
                height: '100%',
                borderRadius: `${this.options?.style?.borderRadius || 0}px`,
                objectFit: this.options.style?.objectFit,
            };
        } else if (this.options.style?.objectFit === 'none') {
            imageStyle = {
                width: '100%',
                height: '100%',
                borderRadius: `${this.options?.style?.borderRadius || 0}px`,
            };
        }

        return (
            <div style={{
                width: '100%',
                height: 'auto',
                backgroundColor: this.options?.style?.backgroundColor,
                border: `${this.options?.style?.borderWidth}px ${this.options?.style?.borderStyle} ${this.options?.style?.borderColor}`,
                borderRadius: `${this.options?.style?.borderRadius || 0}px`,
                paddingTop: `${this.options?.style?.padding?.top || 0}px`,
                paddingRight: `${this.options?.style?.padding?.right || 0}px`,
                paddingBottom: `${this.options?.style?.padding?.bottom || 0}px`,
                paddingLeft: `${this.options?.style?.padding?.left || 0}px`,
            }}>
                <>
                    { this.options?.rfc2397_data && (
                        <img ref={props.ref} src={ this.options.rfc2397_data } style={ imageStyle } />
                    )}

                    { !this.options?.rfc2397_data && (
                        <div>
                            <img src={ imagePlaceholder } style={{ width: '100%', height: '100%' }} />
                        </div>
                    )}
                </>
            </div>
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'picture-o';
    }
}

export default ImageWidget;
