import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button, Input } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import matchSorter from 'match-sorter';
import { withTranslation } from 'react-i18next';
import 'react-table-6/react-table.css'

import { loadTktInstances } from '../../../redux/modules/tktInstances';

import Table from '../../../components/Table/Table';

class TktInstancesList extends Component<{}> {
    componentDidMount() {
        // Load tktInstances on component mount
        this.props.dispatchLoadTktInstances();
    }

    render() {
        const { tktInstances } = this.props.tktInstances;

        const { t } = this.props;

        const textFilter = ({ filter, onChange }) =>
            <Input
                type="text"
                onChange={event => onChange(event.target.value)}
            />;
        const textFilterMethod = (key) => (filter, rows) => matchSorter(rows, filter.value, { keys: [key] });

        const columns = [{
            Header: t("tktInstances.name"),
            accessor: "name",
            filterMethod: textFilterMethod("name"),
            Filter: textFilter,
            filterAll: true
        }, {
            Header: t("common.actions"),
            accessor: "id",
            maxWidth: 110,
            filterable: false,
            Cell: row => (
                <ButtonGroup>
                    <Button tag={Link} to={"/tktInstances/show/" + row.value} color="primary" title={t("common.see")} size="sm">
                        <i className="fa fa-eye"></i>
                    </Button>
                    <Button tag={Link} to={"/tktInstances/edit/" + row.value} color="info" title={t("common.edit")} size="sm">
                        <i className="fa fa-edit"></i>
                    </Button>
                    <Button tag={Link} to={"/tktInstances/delete/" + row.value}  color="danger" title={t("common.delete")} size="sm">
                        <i className="fa fa-trash"></i>
                    </Button>
                </ButtonGroup>
            )
        }];

        const actions =
        <Button tag={Link} to="/tktInstances/new" color="success" size="sm">
            <i className="fa fa-plus"></i>&nbsp;{t("tktInstances.add")}
        </Button>;

        const title =
        <span>{t("tktInstances.tktInstance_plural")}</span>

        return (
            <div className="TktInstancesList">
                <PageTitle icon="ticket" title={title} actions={ actions } />
                <Table id="tktInstancesTable" data={ tktInstances } columns={ columns } /> 
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadTktInstances: () => loadTktInstances()
}

const mapStateToProps = state => ({
    tktInstances: state.tktInstances
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(TktInstancesList))
