import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap';
import "./Placeholder.css";

let Placeholder = () => {
    return (
        <Row>
            <Col className="divPlaceholder col-6 offset-3">Coming soon...</Col>
        </Row>
    )
}


export default Placeholder;
