import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom";
import ScreeningForm from '../ScreeningForm/ScreeningForm';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { loadScreening, updateScreening } from '../../../redux/modules/screenings';
import { loadLogs } from '../../../redux/modules/logs';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadPlaces, loadTickets } from '../../../redux/modules';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ScreeningEdit = () => {
    const dispatch                 = useDispatch();
    const { t }                    = useTranslation();
    const { screening, isLoading } = useSelector(state => state.screenings);
    const { places }               = useSelector(state => state.places);
    const { logs }                 = useSelector(state => state.logs);
    const { setting }              = useSelector(state => state.settings);
    const { tickets }              = useSelector(state => state.tickets);
    const { screeningId }          = useParams();

    useEffect(() => {
        dispatch(loadScreening(screeningId));
        dispatch(loadLogs(screeningId));
        dispatch(loadTickets({ "having_booked_screening_id": screeningId }));
        dispatch(loadPlaces());
    }, []);

    const submit = values => {
        const payload = {}
        Object.keys(values).map(k => {
            switch (k) {
                case '_id':
                case 'description':
                case 'start_at':
                case 'stop_at':
                case 'title':
                case 'opaque':
                case 'note':
                    payload[k] = values[k];
                    break;
            }
        })
        dispatch(updateScreening(payload));
    };

    const actions =
        <Button tag={Link} color="warning" to='/screenings' >
            <i className="fa fa-arrow-left"></i>&nbsp;retour
        </Button>;

    const title =
        screening ? <span>{t("screenings.screening_plural")} - {screening?.title?.original}</span> : <></>;

    return (
        <div className="ScreeningEdit">
            <PageTitle icon="th" title={title} actions={actions} />
            {(Object.keys(screening).length > 0 && logs && tickets) ?
                <ScreeningForm
                    onSubmit={(values) => submit(values)}
                    logs={logs}
                    isLoading={isLoading}
                    setting={setting}
                    places={places}
                    tickets={tickets}
                />
                :
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
        </div>
    );
}

export default ScreeningEdit;
