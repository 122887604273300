import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { Sector } from '../../../models';
import { WysiwygField, TranslatableField, UsersFieldk, SectorsField, UsersField } from '../../../components';
import { loadSectors, getSectorsLinkedResources } from '../../../redux';

import moment from 'moment';
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('fr');
momentLocalizer();

let SectorForm = (props) => {
    const { isCreation, handleSubmit, pristine, submitting } = props;

    const { t, i18n } = useTranslation();
    const dispatch    = useDispatch();

    const { isLoading, sector, sectors, linkedResources } = useSelector(state => state.sectors);
    const users  = linkedResources.users ?? [];

    useEffect(() => {
        dispatch(loadSectors());
        dispatch(getSectorsLinkedResources());
    }, [])

    const parents = React.useMemo(() => {
        const eligible = (sectors || []).filter(s => {
            if (!sector)
                return true;

            if (s.id === sector?.id)
                return false;

            return s.parent_id !== sector.id;
        });

        return Sector.buildTree(eligible, i18n.language);
    }, [ sector, sectors ]);

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("sectors.informations")}</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t("sectors.name")}</Label>
                                            <Col sm={12}>
                                            <TranslatableField name="name" placeholder={t("sectors.name_placeholder")} component="input" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="parent_id" sm={12}>{t("sectors.parent")}</Label>
                                            <Col sm={12}>
                                                <SectorsField single name="parent_id" className="form-control" sectors={ parents } />
                                            </Col>
                                        </FormGroup>
                                        { !isCreation && (
                                            <FormGroup row>
                                                <Label for="admin_ids" sm={12}>{t("sectors.admins")}</Label>
                                                <Col sm={12}>
                                                    <UsersField name="admin_ids" className="form-control" users={ users } />
                                                </Col>
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="description" sm={12}>{t("sectors.description")}</Label>
                                            <Col sm={12}>
                                            <TranslatableField name="description" placeholder={t("sectors.description_placeholder")} component={WysiwygField} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <br/>
                <Row>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
        </Form>
    );

}

SectorForm = reduxForm({
  form: 'sectorForm',
  enableReinitialize: true
})(SectorForm);

SectorForm = connect(
  state => ({
    initialValues: state.sectors.sector,
    Sectors: state.sectors,
}), {}
)(SectorForm)

export default SectorForm;
