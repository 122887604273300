import React, { useState } from 'react';
import { Button, Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './CopyButton.css';

const CopyButton = ({textToCopy, title, size, color, badge, rightComponent, className, ...rest}) => {
    const { t } = useTranslation();

    const [hasCopied, setHasCopied] = useState(false);

    return (
        <CopyToClipboard text={ textToCopy } onCopy={ () => setHasCopied(true) }>
            {badge ? (
                <div
                    className={`btn-${size || 'md'} m-0 p-0 cursor-pointer`}
                    onClick={ e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTimeout(() => setHasCopied(false), 1000);
                    }}
                >
                    <Badge
                        size={ size || 'md' }
                        color={ color || 'info'}
                        className={`CopyButton badge p-2 ${className || ''}`}
                        { ...rest }
                    >
                        <i className={`fa fa-${hasCopied ? 'check' : 'clipboard'}`} />&nbsp;
                        { title || textToCopy }
                        { rightComponent ?? null }
                    </Badge>
                </div>
            ) : (
                <Button
                    size={ size || 'md' }
                    color={ color || 'info'}
                    className={`CopyButton badge p-2 ${className || ''}`}
                    onClick={ e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setTimeout(() => setHasCopied(false), 1000);
                    }}
                    { ...rest }
                >
                    <i className={`fa fa-${hasCopied ? 'check' : 'clipboard'}`} />&nbsp;
                    { title || textToCopy }
                    { rightComponent ?? null }
                </Button>
            )}
        </CopyToClipboard>
    );
}

export default CopyButton;
