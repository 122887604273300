import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import CashregisterForm from '../CashregisterForm/CashregisterForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateCashregister, createCashregister } from '../../../redux/modules/cashregisters';

class CashregisterCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        // Reset cashregister in app state
        this.props.dispatchStartCreateCashregister();
    }

    submit(values) {
        this.props.dispatchCreateCashregister(values);
    }

    render() {
        const { cashregisterSuccessfullyCreated } = this.props.cashregisters;
        const { t } = this.props;

        let title = <span>{t('cashregisters.add')}</span>;

        const actions =
            <Button tag={Link} to="/cashregisters" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="CashregisterCreate">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {cashregisterSuccessfullyCreated &&
                    <Redirect to="/cashregisters"/>
                }

                <CashregisterForm onSubmit={this.submit} isCreation="true" />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateCashregister: (cashregister) => startCreateCashregister(cashregister),
    dispatchCreateCashregister: (cashregister) => createCashregister(cashregister)
}

const mapStateToProps = state => ({
    cashregisters: state.cashregisters,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(CashregisterCreate))
