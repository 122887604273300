import Device from './Device';

/** Class representing an eft. */
class Eft extends Device {
    //  Define the closing modes
    static CLOSING_MODE_NEVER  = 'never';
    static CLOSING_MODE_YES    = 'yes';
    static CLOSING_MODE_NO     = 'no';
    static CLOSING_MODE_ALWAYS = 'always';

    getSanitizedId() {
        // efts ids contain some / and other characters that are not
        // suitable for being used in urls (like coffee-island-1:/dev/ttyUSB0).
        // Let's clean them in a reversible way
        return this._id.replaceAll(/\//g, 'ZZZZZ');
    }

    static getOriginalId(sanitized) {
        // revert the sanitize function
        return sanitized.replaceAll(/ZZZZZ/g, '/');
    }
}

export default Eft;
