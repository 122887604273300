import RestClient from './RestClient';
import { LegacyScreening } from '../../models';

class LegacyScreenings extends RestClient {
    constructor() {
        super({
            "model": LegacyScreening,
            "entryPoint": "legacy_screening",
            "resource": "screening",
            "resources": "screenings"
        });

        this.TKTSYNC_PATH = '/tktsync';
    }

    list(programmation_id) {
        let url = '/legacy_screening/list';
        if (programmation_id)
            url += `/programmation_id/${programmation_id}`;
        return this.GET(url)
            .then(response => response.json())
            .then(json => {
                if (json.screenings)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    get(screeningId) {
        let url = '/legacy_screening/get/id/' + screeningId;
        return this.GET(url)
            .then(response => response.json())
            .then(json => {
                if (json.screening)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    update(legacy_screening) {
        let url = `/legacy_screening/update/id/${legacy_screening.id}`;
        return this.PUT(url, { 'screening': legacy_screening })
            .then(response => response.json())
            .then(json => {
                if (json.screening)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    delete(legacy_screening_id) {
        let url = `/legacy_screening/delete/id/${legacy_screening_id}`;
        return this.POST(url, {})
            .then(response => response.json())
            .then(json => {
                if (json.screening)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    tktsync() {
        return this.POST(`/legacy_screening/tktsync`, {})
            .then(response => response.json())
            .then(json => {
                if (json.imported)
                    return json;
                throw new Error(json.flash.error);
            });
    }
}

const LegacyScreeningsApi = new LegacyScreenings();

export default LegacyScreeningsApi;
