import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services';

import { loadArticle } from '../../../redux/modules/articles';

class ArticleShow extends Component<{}> {
    componentDidMount() {
        const articleId = this.props.match.params.articleId;
        // Load articles on component mount
        this.props.dispatchLoadArticle(articleId);
    }

    render() {
        const { article } = this.props.articles;

        const { t } = this.props;

        const default_lang = Utils.default_lang();

        let title = <span>{t("articles.article_plural")}</span>;
        if (article)
            title = (
                <span>
                    {t("articles.article")}
                    <small> | { article.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/articles" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {article &&
                    <Button tag={Link} to={ `/articles/edit/${ article._id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="ArticleShow">
                <PageTitle icon="th" title={ title } actions={ actions } />
                {article &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-article"></i>&nbsp;{t("articles.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("articles.name")}</b>: { article.name[default_lang] }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadArticle: (articleId) => loadArticle(articleId)
}

const mapStateToProps = state => ({
    articles: state.articles
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ArticleShow))
