import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './SalepointsField.css';

const SalepointsField = ({ name, salepoints, onChange, fixedWidth, single }) => {
    const { t } = useTranslation();

    if (!salepoints)
        return null;

    const options = salepoints.map(salepoint => {
        return {
            key: salepoint._id,
            text: Utils.localized_or_fallback(salepoint.name, i18n.language),
            value: salepoint._id
        };
    });

    const onFieldChange = (value) => {
        return onChange ? onChange(value) : true;
    }

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            placeholder={ t('salepoints.choose_a_salepoint') }
            onSelectChange={ onFieldChange }
            clearable
        />
    ) : (
        <MultiSelectField
            name={ name }
            options={ options }
            placeholder={ t('salepoints.choose_a_salepoint') }
            onSelectChange={ onFieldChange }
        />
    );
}

export default SalepointsField;
