import React, { Component } from 'react';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';

import './Crop.css';

class Crop extends Component<{}> {
    constructor(props) {
        super(props);
        this.state = {
            src: null,
            crop: {
                x: 0,
                y: 0,
                aspect: 924 / 520,
            }
        };
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () =>
                this.setState({
                    src: reader.result
                }),
                false
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = image => {
        this.setState({
            crop: makeAspectCrop({
                x: 10,
                y: 10,
                aspect: 924 / 520,
            }, image.width / image.height),
        });
    };

    onCropComplete = crop => {
        console.log("onCropComplete", crop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    render() {
        return (
            <div className="App">
                <div>
                    <input {...this.props} type="file" onChange={this.onSelectFile} />
                </div>
                {this.state.src && (
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                )}
            </div>
        );
    }
}

export default Crop;

