// Redux Articles module
// see https://github.com/erikras/ducks-modular-redux

import { Article, ArticleAttribute } from '../../models/';
import { Articles, Utils } from '../../services/';
import { v4 } from 'uuid';
import _ from 'lodash';

const LOADING_ARTICLES             = 'kronos/articles/LOADING_ARTICLES';
const LOADING_ARTICLES_SUCCESS     = 'kronos/articles/LOADING_ARTICLES_SUCCESS';
const LOADING_ARTICLES_FAILURE     = 'kronos/articles/LOADING_ARTICLES_FAILURE';
const LOADING_ARTICLE              = 'kronos/articles/LOADING_ARTICLE';
const LOADING_ARTICLE_SUCCESS      = 'kronos/articles/LOADING_ARTICLE_SUCCESS';
const LOADING_ARTICLE_FAILURE      = 'kronos/articles/LOADING_ARTICLE_FAILURE';
const UPDATING_ARTICLE             = 'kronos/articles/UPDATING_ARTICLE';
const UPDATING_ARTICLE_SUCCESS     = 'kronos/articles/UPDATING_ARTICLE_SUCCESS';
const UPDATING_ARTICLE_FAILURE     = 'kronos/articles/UPDATING_ARTICLE_FAILURE';
const START_CREATING_ARTICLE       = 'kronos/articles/START_CREATING_ARTICLE';
const CREATING_ARTICLE             = 'kronos/articles/CREATING_ARTICLE';
const CREATING_ARTICLE_SUCCESS     = 'kronos/articles/CREATING_ARTICLE_SUCCESS';
const CREATING_ARTICLE_FAILURE     = 'kronos/articles/CREATING_ARTICLE_FAILURE';
const DELETING_ARTICLE             = 'kronos/articles/DELETING_ARTICLE';
const DELETING_ARTICLE_SUCCESS     = 'kronos/articles/DELETING_ARTICLE_SUCCESS';
const DELETING_ARTICLE_FAILURE     = 'kronos/articles/DELETING_ARTICLE_FAILURE';
const DELETING_ARTICLE_RESET       = 'kronos/articles/DELETING_ARTICLE_RESET';
const ADD_ARTICLE_VARIANT          = 'kronos/articles/ADD_ARTICLE_VARIANT';
const REMOVE_ARTICLE_VARIANT       = 'kronos/articles/REMOVE_ARTICLE_VARIANT';
const DUPLICATE_ARTICLE_VARIANT    = 'kronos/articles/DUPLICATE_ARTICLE_VARIANT';
const ADD_ARTICLE_VARIANT_STOCK    = 'kronos/articles/ADD_ARTICLE_VARIANT_STOCK';
const ADD_ARTICLE_STOCK            = 'kronos/articles/ADD_ARTICLE_STOCK';
const REMOVE_ARTICLE_VARIANT_STOCK = 'kronos/articles/REMOVE_ARTICLE_VARIANT_STOCK';
const REMOVE_ARTICLE_STOCK         = 'kronos/articles/REMOVE_ARTICLE_STOCK';
const SET_CURRENT_TYPE             = 'kronos/articles/SET_CURRENT_TYPE';
const SET_CURRENT_CATEGORY         = 'kronos/articles/SET_CURRENT_CATEGORY';
const SET_SEARCH                   = 'kronos/articles/SET_SEARCH';
const ADD_ARTICLE_FEATURE          = 'kronos/articles/ADD_ARTICLE_FEATURE';
const REMOVE_ARTICLE_FEATURE       = 'kronos/articles/REMOVE_ARTICLE_FEATURE';
const ADD_ARTICLE_EXTRA            = 'kronos/articles/ADD_ARTICLE_EXTRA';
const REMOVE_ARTICLE_EXTRA         = 'kronos/articles/REMOVE_ARTICLE_EXTRA';
const ADD_ARTICLE_EXTRA_CHOICE     = 'kronos/articles/ADD_ARTICLE_EXTRA_CHOICE';
const REMOVE_ARTICLE_EXTRA_CHOICE  = 'kronos/articles/REMOVE_ARTICLE_EXTRA_CHOICE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    articleSuccessfullyCreated: false,
    articles: [],
    search: null,
    currentCategory: null,
    currentType: Article.TYPE_PRODUCT
}

const initialArticle = type => {
    return {
        "type": type,
        "name" : { "fr" : "", "en" : "", "de" : "" },
        "short_description" : { "fr" : "", "en" : "", "de" : "" },
        "description" : { "fr" : "", "en" : "", "de" : "" },
        "category" : {},
        "pos" : [],
        "stock_type" : "none",
        "stocks" : [],
        "supplier" : "",
        "posters" : [],
        "variants" : [{
            "name" : { "fr" : "", "en" : "", "de" : "" },
            "stock" : -1,
            "stocks" : [],
            "stock_factor" : 1,
            "gtin" : "",
            "sku" : "",
            "vat" : 0,
            "price" : { "CHF" : 0 },
            "value_like_price" : true,
            "value" : { "CHF" : 0 },
            "purchasing_price" : { "CHF" : 0 }
        }]
    };
};

const initialStock = {
    salepoint_ids: [],
    availability: 0
};

const initialVariant = () => {
    const v = {
        _id: v4(),
        name: {},
        price: {CHF: 0},
        value: {CHF: 0},
        purchasing_price: {CHF: 0},
        vat: 0,
        variable_price: false,
        value_like_price : true,
        gtin: "",
        sku: "",
        stock_factor: 0,
        stocks: [],
    };

    return v;
};

const initialFeature = new ArticleAttribute({
    'name': {},
    'max_nb_choices': 0,
    'choices': [{
        'name': {},
        'price': { 'CHF': 0 }
    }]
});
const initialExtra = new ArticleAttribute({
    'name': {},
    'choices': [{
        'name': {},
        'price': { 'CHF': 0 }
    }]
});
const initialExtraChoice = {
    'name': {},
    'price': { 'CHF': 0 }
};
Utils.langs().map(lang => {
    initialFeature.name[lang] = '-';
    initialFeature.choices[0].name[lang] = '-';
    initialExtra.name[lang] = '-';
    initialExtra.choices[0].name[lang] = '-';
    initialExtraChoice.name[lang] = '-';
});

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_ARTICLES:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
            };
        case LOADING_ARTICLES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                articles: action.articles,
            };
        case LOADING_ARTICLES_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_ARTICLE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_ARTICLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                article: action.article,
                articleSuccessfullyCreated: false,
            };
        case LOADING_ARTICLE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_ARTICLE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_ARTICLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                article: action.article
            };
        case UPDATING_ARTICLE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_ARTICLE:

            const article = initialArticle(action.articleType || Article.TYPE_PRODUCT);
            if (action.articleType === Article.TYPE_MANUAL_DISCOUNT) {
                article.variants[0].discount = {
                    "target" : Article.DISCOUNT_TARGET_CART,
                    "value" : {"percentage" : 0, "amount" : {}},
                    "rules" : {"only_for_roles": ["on-site", "admin"]}
                };
            } else if (action.articleType === Article.TYPE_CODE_DISCOUNT) {
                article.variants[0].discount = {
                    "target" : Article.DISCOUNT_TARGET_CART,
                    "code" : "",
                    "value" : {"percentage" : 0, "amount" : {}},
                    "rules" : {"only_for_roles": ["on-site", "admin"]}
                };
            }
 
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                articleSuccessfullyCreated: false,
                // Ensure sane default article for creation
                article
            };

        case CREATING_ARTICLE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_ARTICLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                articleSuccessfullyCreated: true,
                article: action.article
            };
        case CREATING_ARTICLE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                articleSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_ARTICLE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_ARTICLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                articleSuccessfullyDeleted: true,
                article: action.article
            };
        case DELETING_ARTICLE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                articleSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_ARTICLE_RESET:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                articleSuccessfullyDeleted: false,
                loadingErrorMessage: null
            };
        case ADD_ARTICLE_VARIANT_STOCK:
            state.article.variants[action.index].stocks.push(initialStock);
            return state;
        case ADD_ARTICLE_STOCK:
            state.article.stocks.push(initialStock);
            return state;
        case ADD_ARTICLE_VARIANT:
            const newVariant = {
                ...initialVariant(),
                vat: action.vat
            };
            action.langs.forEach(lang => {
                newVariant.name[lang] = 'new';
            });
            return {
                ...state,
                article: {
                    ...state.article,
                    variants: [
                        ...state.article.variants,
                        newVariant
                    ]
                }
            }
        case REMOVE_ARTICLE_VARIANT:
            return {
                ...state,
                article: {
                    ...state.article,
                    variants: state.article.variants.filter(v => v._id != action._id)
                }
            }
        case DUPLICATE_ARTICLE_VARIANT:
            const original = state.article.variants.find(v => v._id === action._id);
            if (original) {
                const newVariant = _.cloneDeep(original);
                newVariant._id = v4();
                return {
                    ...state,
                    article: {
                        ...state.article,
                        variants: [
                            ...state.article.variants,
                            newVariant
                        ]
                    }
                }
            }
        case REMOVE_ARTICLE_VARIANT_STOCK:
            state.article.variants[action.variant_index].stocks.splice(action.stock_index, 1);
            return state;
        case REMOVE_ARTICLE_STOCK:
            state.article.stocks.splice(action.index, 1);
            return state;
        case ADD_ARTICLE_FEATURE:
            state.article.addAttribute(initialFeature);
            return state;
        case REMOVE_ARTICLE_FEATURE:
            state.article.attributes.splice(action.index, 1);
            return state;
        case ADD_ARTICLE_EXTRA:
            state.article.addAttribute(initialExtra);
            return state;
        case REMOVE_ARTICLE_EXTRA:
            state.article.attributes.splice(action.index, 1);
            return state;
        case ADD_ARTICLE_EXTRA_CHOICE:
            state.article.attributes[action.index].choices.push(initialExtraChoice);
            return state;
        case REMOVE_ARTICLE_EXTRA_CHOICE:
            state.article.attributes[action.attributeIndex].choices.splice(action.choiceIndex, 1);
            return state;
        case SET_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategory: action.currentCategory
            };
        case SET_CURRENT_TYPE:
            return {
                ...state,
                currentType: action.currentType
            };
        case SET_SEARCH:
            return {
                ...state,
                search: action.search
            };
        default:
            return state;
    }
};

// Actions
function loadingArticles() { return { type: LOADING_ARTICLES } }
function loadingArticlesSuccess(articles) { return { type: LOADING_ARTICLES_SUCCESS, articles: articles } }
function loadingArticlesFailure(err) { return { type: LOADING_ARTICLES_FAILURE, error: err } }
export function loadArticles(params) {
    return (dispatch) => {
        dispatch(loadingArticles());
        Articles.list(params)
            .then(data => {
                const articles = data.articles;
                dispatch(loadingArticlesSuccess(articles));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingArticlesFailure(err))
            });
    }
}

function loadingArticle() { return { type: LOADING_ARTICLE } }
function loadingArticleSuccess(article) { return { type: LOADING_ARTICLE_SUCCESS, article: article } }
function loadingArticleFailure(err) { return { type: LOADING_ARTICLE_FAILURE, error: err } }
export function loadArticle(articleId) {
    return (dispatch) => {
        dispatch(loadingArticle());
        Articles.get(articleId)
            .then(data => {
                const article = data.article;
                dispatch(loadingArticleSuccess(article));
            })
            .catch(err => {
                dispatch(loadingArticleFailure(err))
            });
    }
}

function updatingArticle() { return { type: UPDATING_ARTICLE } }
function updatingArticleSuccess(article) { return { type: UPDATING_ARTICLE_SUCCESS, article: article } }
function updatingArticleFailure(err) { return { type: UPDATING_ARTICLE_FAILURE, error: err } }
export function updateArticle(article) {
    return (dispatch) => {
        dispatch(updatingArticle());
        Articles.update(article)
            .then(data => {
                const article = data.article;
                dispatch(updatingArticleSuccess(article));
            })
            .catch(err => {
                dispatch(updatingArticleFailure(err))
            });
    }
}

function creatingArticle() { return { type: CREATING_ARTICLE } }
function creatingArticleSuccess(article) { return { type: CREATING_ARTICLE_SUCCESS, article: article } }
function creatingArticleFailure(err) { return { type: CREATING_ARTICLE_FAILURE, error: err } }
export function startCreateArticle(articleType) { return { type: START_CREATING_ARTICLE, articleType } }
export function createArticle(article) {
    return (dispatch) => {
        dispatch(creatingArticle());
        Articles.create(article)
            .then(data => {
                const article = data.article;
                dispatch(creatingArticleSuccess(article));
                dispatch(loadingArticleSuccess(article));
            })
            .catch(err => {
                dispatch(creatingArticleFailure(err))
            });
    }
}

function deletingArticle() { return { type: DELETING_ARTICLE } }
function deletingArticleSuccess() { return { type: DELETING_ARTICLE_SUCCESS } }
function deletingArticleFailure(err) { return { type: DELETING_ARTICLE_FAILURE, error: err } }
export function deleteArticle(articleId) {
    return (dispatch) => {
        dispatch(deletingArticle());
        Articles.delete(articleId)
            .then(data => {
                dispatch(deletingArticleSuccess());
            })
            .catch(err => {
                dispatch(deletingArticleFailure(err))
            });
    }
}
export function resetDeleteArticle() {
    return dispatch => {
        dispatch({ type: DELETING_ARTICLE_RESET });
    };
};

export function addArticleVariantStock(i) { return { type: ADD_ARTICLE_VARIANT_STOCK, index: i } }
export function addArticleStock() { return { type: ADD_ARTICLE_STOCK } }
export function addArticleVariant() {
    const vat   = Utils.tax()?.vat_articles ?? 0;
    const langs = Utils.langs();
    return { type: ADD_ARTICLE_VARIANT, vat, langs };
}
export function removeArticleVariant(_id) { return { type: REMOVE_ARTICLE_VARIANT, _id } }
export function duplicateArticleVariant(_id) { return { type: DUPLICATE_ARTICLE_VARIANT, _id } }
export function removeArticleVariantStock(vi, si) { return { type: REMOVE_ARTICLE_VARIANT_STOCK, variant_index: vi, stock_index: si} }
export function removeArticleStock(i) { return { type: REMOVE_ARTICLE_STOCK, index: i} }
export function setCurrentCategory(currentCategory) { return { type: SET_CURRENT_CATEGORY, currentCategory } }
export function setCurrentType(currentType) { return { type: SET_CURRENT_TYPE, currentType } }
export function setSearch(search) { return { type: SET_SEARCH, search } }
export function addArticleFeature() { return { type: ADD_ARTICLE_FEATURE } }
export function removeArticleFeature(i) { return { type: REMOVE_ARTICLE_FEATURE, index: i} }
export function addArticleExtra() { return { type: ADD_ARTICLE_EXTRA } }
export function removeArticleExtra(i) { return { type: REMOVE_ARTICLE_EXTRA, index: i} }
export function addArticleExtraChoice(index) { return { type: ADD_ARTICLE_EXTRA_CHOICE, index } }
export function removeArticleExtraChoice(attributeIndex, choiceIndex) { return { type: REMOVE_ARTICLE_EXTRA_CHOICE, attributeIndex, choiceIndex } }
