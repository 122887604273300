import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form'
import { useTranslation } from 'react-i18next';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText, CardFooter } from 'reactstrap';
import { FormGroup, Label } from 'reactstrap';
import { Utils } from '../../../services';
import { removeDeliveryType, addDeliveryTypeRule, removeDeliveryTypeRule } from '../../../redux/modules/settings';
import DeliveryTypeRuleForm from './DeliveryTypeRuleForm/DeliveryTypeRuleForm';
import SelectField from '../../SelectField/SelectField';
import TranslatableField from '../../TranslatableField/TranslatableField';
import i18n from '../../../locales/i18n';

import './DeliveryTypeForm.css';

const typesOptions = Utils.deliveryTypes().map(type => {
    return {
        key: type,
        text: i18n.t(`settings.delivery.${type}`),
        value: type
    };
});

const DeliveryTypeForm = ({ name, type, typeIndex }) => {
    const { t }                  = useTranslation();
    const dispatch               = useDispatch();
    const { isMobile, isTablet } = useSelector(state => state.ui);

    const removeType = typeIndex => {
        dispatch(removeDeliveryType(typeIndex));
    };

    const addRule = () => {
        dispatch(addDeliveryTypeRule(typeIndex));
    };

    const removeRule = (ruleIndex) => {
        dispatch(removeDeliveryTypeRule(typeIndex, ruleIndex));
    };

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardTitle className="small">{ t('settings.delivery.type.general_informations') }</CardTitle>
                        <CardText tag="div">
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label for="name" className={"text-" + ( isMobile || isTablet ? "left" : "right")} sm={12} lg={3}>{t('settings.delivery.type.name')}</Label>
                                        <Col sm={12} lg={9}>
                                            <TranslatableField required component="input" className="form-control" type="text" name={ `${name}.name` } id="name" placeholder={t("settings.delivery.type.name_placeholder")} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="type" className={"text-" + ( isMobile || isTablet ? "left" : "right")}  sm={12} lg={3}>{t('settings.delivery.type.type')}</Label>
                                        <Col sm={12} lg={9}>
                                            <SelectField name={ `${name}.type` } options={ typesOptions } />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="_id"  sm={12} lg={3} className={"text-" + ( isMobile || isTablet ? "left" : "right")} >{t("settings.delivery.type._id")}</Label>
                                        <Col sm={12} lg={9} data-tip data-for='tktIdTip'>
                                            <Field required component="input" className="form-control" type="text" name={ `${name}._id` } id="_id" autoComplete="_id" placeholder={t("settings.delivery.type._id")} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardText>
                        <CardTitle className="small">
                            { t('settings.delivery.type.rules') }
                            <ButtonGroup className="pull-right">
                                <Button color="info" size="sm" onClick={ addRule }>
                                    <i className="fa fa-plus"></i>&nbsp;{t("settings.delivery.type.add_rule")}
                                </Button>
                            </ButtonGroup>
                        </CardTitle>
                        <CardText tag="div">
                            { (type?.rules || []).map((rule, index) => (
                            <Card key={ index } className="mb-3">
                                <CardBody>
                                    <CardText tag="div">
                                        <DeliveryTypeRuleForm name={ `${name}.rules[${index}]` } rule={ rule } type={ type.type } />
                                    </CardText>
                                </CardBody>
                                <CardFooter>
                                    <ButtonGroup className="pull-right">
                                        <Button color="danger" size="sm" onClick={ () => { removeRule(index) }}>
                                            <i className="fa fa-trash"></i>&nbsp;{t("settings.delivery.type.remove_this_rule")}
                                        </Button>
                                    </ButtonGroup>
                                </CardFooter>
                            </Card>
                            ))}
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default DeliveryTypeForm;
