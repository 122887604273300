import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services';
import { TranslatableField, ToggleField } from '../../../components';
import { loadSalepoints } from '../../../redux/modules/salepoints';

import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

class CashregisterForm extends Component<{}> {
    componentDidMount() {
        this.props.dispatchLoadSalepoints();
    }

    render() {
        const { salepoints }                            = this.props.salepoints;
        const { handleSubmit, pristine, submitting, t } = this.props;
        const { isLoading } = this.props.cashregisters;

        const default_lang = Utils.default_lang();

        return (
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("cashregisters.informations")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("cashregisters.name")}</Label>
                                            <Col sm={9}>
                                                <TranslatableField name="name" placeholder={t("cashregisters.name_placeholder")} component="input"/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="salepoint_id" sm={3}>{t("cashregisters.salepoint")}</Label>
                                            <Col sm={9}>
                                                <Field component="select" className="form-control" type="text" name="salepoint_id">
                                                    <option key="0" value="">---</option>
                                                    { salepoints && salepoints.map((s) => (
                                                        <option key={s._id} value={s._id}>{s.name[default_lang]}</option>)
                                                    )}
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="auto_closing" sm={3}>{t("cashregisters.auto_closing")}</Label>
                                            <Col sm={9}>
                                                <ToggleField name="auto_closing" />
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="text-center">
                            <br/>
                            <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-save"></i>&nbsp;
                                        {t("common.save")}
                                    </span>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadSalepoints: () => loadSalepoints()
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
CashregisterForm = reduxForm({
  form: 'cashregisterForm' // a unique identifier for this form
})(CashregisterForm)

// You have to connect() to any reducers that you wish to connect to yourself
CashregisterForm = connect(
  state => ({
    initialValues: state.cashregisters.cashregister, // pull initial values from cashregisters reducer
    cashregisters: state.cashregisters,
    salepoints: state.salepoints
}), mapDispatchToProps
)(CashregisterForm)

export default withTranslation('translations')(CashregisterForm)
