import React, { useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, Input, InputGroupAddon, InputGroupText, Button } from 'reactstrap';

const DurationCustomComponent = (props) => {
    const { input, sign, defaultValue, onDurationChange, onlyParts, setCustomFieldIsVisible, noGoBack } = props;
    const { t }     = useTranslation();

    const normalizer = value => {
        if (!value)
            return value;

        value = value.toString().replace(/([+-])/g, '');

        if (sign === '-') {
            value = '-' + value;
        }

        return value;
    };

    useEffect(() => {
        setTimeout(() => input.onChange(defaultValue), 0);
    }, [defaultValue]);

    useEffect(() => {
        input.onChange(normalizer(input.value));
    }, [sign]);

    const extractValue = key => {
        // Since M is used for months and for minutes, we use X for minutes...
        const regexp = key === 'X' ? new RegExp('[TH](\\d+)M') : (
            key === 'M' ? new RegExp('[PY](\\d+)M') : new RegExp(`(\\d+)${key}`)
        );
        const results = regexp.exec(input?.value);
        if (results && results.length > 1)
            return parseInt(results[1], 10);

        return 0;
    };

    let duration =
        'P' +
        extractValue('Y') + 'Y' +
        extractValue('M') + 'M' +
        extractValue('D') + 'D' +
        'T' +
        extractValue('H') + 'H' +
        extractValue('X') + 'M' +
        extractValue('S') + 'S';

    const onChange = (name, value) => {
        // Since M is used for months and for minutes, we use X for minutes...
        if (name === 'X') {
            let regexp = new RegExp('H\\d+M');
            duration = duration?.replace(regexp, value > 0 ? `H${value}M` : 'H');
            regexp = new RegExp('T\\d+M');
            duration = duration?.replace(regexp, value > 0 ? `T${value}M` : 'T');
        } else {
            const regexp = new RegExp(`\\d+${name}`);
            duration = duration?.replace(regexp, value > 0 ? `${value}${name}` : '');
        }

        const cleaned = duration?.replaceAll(/([^0-9])0[YMDHS]/gi, '$1').replaceAll(/([^0-9])0[YMDHS]/gi, '$1');
        input?.onChange && input.onChange(cleaned != 'PT' ? cleaned : duration);
    }

    const parts = [
        { key: 'Y', label: 'years', abbr: 'years_abbr' },
        { key: 'M', label: 'months', abbr: 'months_abbr' },
        { key: 'D', label: 'days', abbr: 'days_abbr' },
        { key: 'H', label: 'hours', abbr: 'hours_abbr' },
        // Since M is used for months and for minutes, we use X for minutes...
        { key: 'X', label: 'minutes', abbr: 'minutes_abbr' },
        { key: 'S', label: 'seconds', abbr: 'seconds_abbr' },
    ].filter(part => !onlyParts || onlyParts.includes(part.key));

    const backToPredefinedField = () => {
        input?.onChange && input.onChange(null);
        setCustomFieldIsVisible(false);
    };

    return (
        <InputGroup>
            <InputGroupAddon addonType="prepend">
                {noGoBack ? (
                <Button>
                    <i className="fa fa-calendar"></i>
                </Button>
                ) : (
                <Button onClick={ backToPredefinedField }>
                    <i className="fa fa-arrow-left"></i>
                </Button>
                )}
            </InputGroupAddon>
            {parts.map(part => (
                <Fragment key={ part.key }>
                    <Input value={ extractValue(part.key) } type="number" step={ 1 } size={ 2 } onChange={ e => onChange(part.key, e.target.value) } />
                    <InputGroupAddon addonType="append">
                        <InputGroupText title={ t(`common.duration.${part.label}`) }>{ t(`common.duration.${onlyParts?.length ? part.label : part.abbr}`) }</InputGroupText>
                    </InputGroupAddon>
                </Fragment>
            ))}
        </InputGroup>
    );
}

export default DurationCustomComponent;
