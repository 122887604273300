import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { WysiwygField } from '../../../components';
import { withTranslation } from 'react-i18next';
import { loadUsers } from '../../../redux/modules/users';
import { loadActivities } from '../../../redux/modules/activities';
import { Utils } from '../../../services/';

import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

class MessageForm extends Component<{}> {
    componentDidMount() {
        this.props.dispatchLoadUsers();
        this.props.dispatchLoadActivities();
    }

    render() {
        const { handleSubmit, pristine, submitting, t } = this.props;
        const { isLoading }                             = this.props.messages;
        const { users }                                 = this.props.users;
        const { activities }                            = this.props.activities;
        const default_lang                              = Utils.default_lang();

        return (
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("messages.informations")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="recipients" xs={12} md={2}>{t('messages.recipients')}</Label>
                                            <Col xs={12} md={5} className="pb-2">
                                                <Field component="select" className="form-control" type="select-multiple" name="recipients" id="recipients" multiple>
                                                    { users && users.map((u) => (
                                                        <option key={u.id} value={u.id}>{u.firstname} {u.lastname}</option>)
                                                    )}
                                                </Field>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <Field component="select" className="form-control" type="select-multiple" name="recipient_groups" id="recipient_groups" multiple>
                                                    { activities && activities.map((a) => (
                                                        <option key={a.id} value={a.id}>{a.name[default_lang]}</option>)
                                                    )}
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="message">
                                            <Label for="message" xs={12} md={2}>{t("messages.message")}</Label>
                                            <Col xs={12} md={10}>
                                            <Field name="message" placeholder={t("messages.message_placeholder")} component={WysiwygField} />
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="text-center">
                            <br/>
                            <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-send"></i>&nbsp;
                                        {t("messages.send")}
                                    </span>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadUsers: () => loadUsers(),
    dispatchLoadActivities: () => loadActivities()
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
MessageForm = reduxForm({
  form: 'messageForm', // a unique identifier for this form
})(MessageForm)

// You have to connect() to any reducers that you wish to connect to yourself
MessageForm = connect(
  state => ({
    initialValues: state.messages.message, // pull initial values from messages reducer
    messages: state.messages,
    users: state.users,
    activities: state.activities
}), mapDispatchToProps
)(MessageForm)

export default withTranslation('translations')(MessageForm)
