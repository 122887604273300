import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

class DistributorForm extends Component<{}> {
    render() {
        const { isLoading }                             = this.props.distributors;
        const { handleSubmit, pristine, submitting, t } = this.props;
        return (
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("distributors.informations")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t('distributors.name')}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className="form-control" type="text" name="name" id="name" placeholder={t('distributors.name_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="maccsbox_id" sm={3}>{t('distributors.maccsbox_id')}</Label>
                                            <Col sm={9}>
                                                <Field component="input" className="form-control" type="text" name="maccsbox_id" id="maccsbox_id" placeholder={t('distributors.maccsbox_id_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="report_mail" sm={3}>{t('distributors.report_mail')}</Label>
                                            <Col sm={9}>
                                                <Field component="input" className="form-control" type="text" name="report_mail" id="report_mail" placeholder={t('distributors.report_mail_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={12} lg={6}>
                            <div>
                                <Card>
                                    <CardBody>
                                        <CardTitle>
                                            <i className="fa fa-address-card"></i>&nbsp;
                                            {t('distributors.address')}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Label for="address" sm={3}>{t('distributors.address')}</Label>
                                                <Col sm={9}>
                                                    <Field component="input" className="form-control" type="text" name="address" id="address" autoComplete="address-line1" placeholder={t('distributors.address_placeholder')} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="zip" sm={3}>{t('distributors.zip')}</Label>
                                                <Col sm={9}>
                                                    <Field component="input" className="form-control" type="text" name="zip" id="zip" autoComplete="postal-code" placeholder={t('distributors.zip_placeholder')} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="city" sm={3}>{t('distributors.city')}</Label>
                                                <Col sm={9}>
                                                    <Field component="input" className="form-control" type="text" name="city" id="city" autoComplete="address-level2" placeholder={t('distributors.city_placeholder')} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="country" sm={3}>{t('distributors.country')}</Label>
                                                <Col sm={9}>
                                                    <Field component="input" className="form-control" type="text" name="country" id="country" autoComplete="country-name" placeholder={t('distributors.country_placeholder')} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="contact_mail" sm={3}>{t('distributors.contact_mail')}</Label>
                                                <Col sm={9}>
                                                    <Field component="input" className="form-control" type="text" name="contact_mail" id="contact_mail" placeholder={t('distributors.contact_mail_placeholder')} />
                                                </Col>
                                            </FormGroup>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col className="text-center">
                            <br/>
                            <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-save"></i>&nbsp;
                                        {t("common.save")}
                                    </span>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
DistributorForm = reduxForm({
  form: 'distributorForm' // a unique identifier for this form
})(DistributorForm)

// You have to connect() to any reducers that you wish to connect to yourself
DistributorForm = connect(
  state => ({
    initialValues: state.distributors.distributor, // pull initial values from distributors reducer
    distributors: state.distributors,
})
)(DistributorForm)

export default withTranslation('translations')(DistributorForm)
