import BaseModel from './BaseModel';
import ArticleAttributeChoice from './ArticleAttributeChoice';

/** Class representing an article attribute. */
class ArticleAttribute extends BaseModel {
    constructor(properties) {
        super(properties);

        this.choices = (this.choices || []).map(c => new ArticleAttributeChoice(c));
    }

    isFeature() {
        return this.max_nb_choices == 0;
    }

    isExtra() {
        return !('max_nb_choices' in this) || this.max_nb_choices > 0;
    }

    isRequiredExtra() {
        return this.isExtra() && this.min_nb_choices > 0;
    }
}

export default ArticleAttribute;
