import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form'
import { Dropdown } from 'semantic-ui-react';
import { loadTags, startCreateTag, createTag } from '../../redux/modules/tags';

import './TagsField.css';

const TagsField = ({ name, addTag }) => {
    const [ isAddingTag, setIsAddingTag ] = useState(false);

    const { t } = useTranslation();
    const { tags, tag, tagSuccessfullyCreated } = useSelector(state => state.tags);

    const options = (tags || []).map(tag => {
        return {
            key: tag._id,
            text: tag.name,
            value: tag._id
        };
    });

    const Component = (props) => {
        const { input } = props;
        const dispatch  = useDispatch();
        const onAddItem = (e, { value }) => {
            setIsAddingTag(true);
            dispatch(startCreateTag());
            dispatch(createTag({ name: value }));

            return false;
        };

        if (tag && isAddingTag && tagSuccessfullyCreated) {
            setIsAddingTag(false);
            const newTags = addTag(tag);

            input.onChange(newTags);
        }

        const onChange = (e, data) => {
            input.onChange(data.value.map(tagId => tags.find(t => tagId === t._id)));
        }

        return <Dropdown
            name={ name }
            options={options}
            value={
                (input.value || [])
                    .filter(t => t != undefined)
                    .map(t => t._id)
            }
            onChange={ onChange }
            search
            allowAdditions={ !!addTag }
            additionLabel={ t('tags.add_the_tag') + ' ' }
            onAddItem={ onAddItem }
            disabled={ isAddingTag }
            clearable
            fluid
            multiple
            selection
        />;
    }

    return (
        <div className="MultiSelectField">
            <Field multiple name={ name } component={ Component } className="form-control" />
        </div>
    );
}

export default TagsField;
