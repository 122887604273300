import BaseModel from './BaseModel';

/** Class representing an article category. */
class ArticleCategory extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
ArticleCategory.getListingFields = () => [
    '_id',
    'name',
    'pos',
    'sort_weight',
    'parent',
    'posters'
];

export default ArticleCategory;
