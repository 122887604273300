import React, { useEffect } from 'react';
import { useSelector, useDispatch }from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle  } from 'reactstrap';
import { Link } from "react-router-dom";
import { Utils, Filters } from '../../../services';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Table from '../../../components/Table/Table';
import 'react-table-6/react-table.css';
import { loadChannels } from '../../../redux/modules/channels';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import { loadCashregisters } from '../../../redux/modules/cashregisters';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';

import './ChannelsList.css';

const ChannelsList = () => {
    const { t }              = useTranslation();
    const { channels } = useSelector(state => state.channels);
    const salepoints = useSelector(state => state.salepoints.salepoints);
    const cashregisters = useSelector(state => state.cashregisters.cashregisters);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadChannels());
        dispatch(loadSalepoints());
        dispatch(loadCashregisters());
    }, [dispatch]);

    const default_lang = Utils.default_lang();

    const actions =
        <CreateButton
            module={`channels`}
            text="channels.add"
        />

    const title =
    <span>{t("channels.channels_plural")}</span>

    const tree = Utils.sortAndIndentCats(channels, 'id', 'parent_id');

    const columns = [{
        Header: t('channels.name'),
        accessor: "name",
        filterMethod: Filters.textFilterMethod('name'),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => (
                    <div className="channel_cell">
                        <Link to={`/channels/edit/${row.original.id}`}>
                            <Card>
                                <CardBody>
                                    <div className="title-wrapper">
                                        <CardTitle>
                                            { row.value }
                                            <small>
                                                { row.original.slug }
                                            </small>
                                        </CardTitle>
                                    </div>
                                </CardBody>
                            </Card>
                        </Link>
                    </div>
        )
    }, {
        Header: t("channels.salepoint"),
        accessor: "salepoint_id",
        filterMethod: Filters.textFilterMethod("salepoint_id"),
        Filter: Filters.posFilter(salepoints),
        filterAll: true,
        Cell: row => (
            <span >{ row.original.salepoint?.name[default_lang] }</span>
        )
    }, {
        Header: t("channels.cashregister"),
        accessor: "cashregister_id",
        filterMethod: Filters.textFilterMethod("cashregister_id"),
        Filter: Filters.posFilter(cashregisters),
        filterAll: true,
        Cell: row => (
            <span >{ row.original.cashregister?.name[default_lang] }</span>
        )
    }, {
        Header: t("common.actions"),
        accessor: "id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete"]}
                module="channels"
            />
        )
    }];

    return (
        <div className="ChannelsList">
            <PageTitle icon="th" title={title} actions={ actions } />
            <Table id="channelsTable" data={ tree } columns={ columns } />
        </div>
    );
}
export default ChannelsList
