import React from "react";
import FormLayout from '../FormLayout';
import FormLayoutField from '../FormLayoutField';
import { Field } from 'redux-form'
import { Utils } from '../../services';

/** Class representing a PDF layout custom text field. */
class TextField extends FormLayoutField {

    constructor(properties) {
        super({
            ...(TextField.getDefaultOptions()),
            ...properties,
        });

        this.type = FormLayout.TEXT;
    }

    /**
     * Get this field default options
     */
    static getDefaultOptions() {
        const properties = super.getDefaultOptions();
        Utils.langs().forEach(lang => { properties.label[lang] = "";  });

        return properties;
    }

    /**
     * Return this field preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {FormLayout} layout
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        return (
            <div className="field-wrapper form-group">
                { this.getPreviewLabel(props) }
                <div className="input">{ this.getPreviewInput(props) }</div>
            </div>
        );
    }

    /**
     * Render this field input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getInput(props) {
        return (
            <Field
                component="input"
                type="text"
                name={this.id}
                required={!!this.required}
                className="form-control"
            />
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'comment-o';
    }
}

export default TextField;
