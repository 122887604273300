import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadSalepoint } from '../../../redux/modules/salepoints';

class SalepointShow extends Component<{}> {
    componentDidMount() {
        const salepointId = this.props.match.params.salepointId;
        // Load salepoints on component mount
        this.props.dispatchLoadSalepoint(salepointId);
    }

    render() {
        const { salepoint } = this.props.salepoints;
        const default_lang = Utils.default_lang();

        const { t } = this.props;

        let title = <span>{t("salepoints.salepoint_plural")}</span>;
        if (salepoint)
            title = (
                <span>
                    {t("salepoints.salepoint")}
                    <small> | { salepoint.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/salepoints" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {salepoint &&
                    <Button tag={Link} to={ `/salepoints/edit/${ salepoint._id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="SalepointShow">
                <PageTitle icon="th" title={ title } actions={ actions } />
                {salepoint &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-salepoint"></i>&nbsp;{t("salepoints.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("salepoints.name")}</b>: { salepoint.name[default_lang] }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadSalepoint: (salepointId) => loadSalepoint(salepointId)
}

const mapStateToProps = state => ({
    salepoints: state.salepoints
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(SalepointShow))
