import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Button, ButtonGroup, Card, CardBody, CardTitle, CardText, CardFooter, FormGroup, Label } from 'reactstrap';
import { Field, submit } from 'redux-form'
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../../services';

class ScreeningEvents extends Component<{}> {
    remove(i) {
        const { fields, events, meta: { form }, dispatchSubmitForm } = this.props;

        fields.remove(i);
        setTimeout(() => { dispatchSubmitForm(form); });
    }

    onChangeAudio(i, e) {
        const { fields, events, meta: { form }, dispatchSubmitForm } = this.props;

        fields.get(i).audio = e.target.value;
        setTimeout(() => { dispatchSubmitForm(form); });
    }

    onChangeSubtitles(i, e) {
        const { fields, events, meta: { form }, dispatchSubmitForm } = this.props;

        fields.get(i).subtitles = [...e.target.options].filter(o => o.selected).map(o => o.value);
        setTimeout(() => { dispatchSubmitForm(form); });
    }

    render() {
        const { t, fields, events, onDelete, i18n } = this.props;
        const default_lang                          = Utils.default_lang();

        return (
            <div className="ScreeningEvents">
                <Row>
                    {fields.map((field, i) =>
                        <Col key={i} xs="6" sm="4" md="3">
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-center">{events[i].title[default_lang]}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Col>
                                                <Label for="audio">{t("programmations.audio")}</Label>
                                                <Field component="select" className="form-control" type="text" name={`${field}.audio`} onChange={(e) => { this.onChangeAudio(i, e) }}>
                                                    <option key="0" value="">---</option>
                                                    {
                                                        Utils.sorted_standard_languages(i18n.language).map(l => {
                                                            return <option key={l.code} value={l.code}>{l.language}</option>
                                                        })
                                                    }
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col>
                                            <Label for="subtitles">{t("programmations.subtitles")}</Label>
                                                <Field component="select" className="form-control" type="select-multiple" multiple name={`${field}.subtitles`} onChange={(e) => { this.onChangeSubtitles(i, e) }}>
                                                    {
                                                        Utils.sorted_standard_languages(i18n.language).map(l => {
                                                            return <option key={l.code} value={l.code}>{l.language}</option>
                                                        })
                                                    }
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                                <CardFooter className="text-center" style={{
                                    "backgroundImage": "url('" + (events[i].posters && events[i].posters.length ? events[i].posters[0].url : "") + "')",
                                    "backgroundPosition": "center center",
                                    "backgroundSize": "cover"
                                }}>
                                    <ButtonGroup>
                                        <Button color="danger" size="sm" onClick={ () => { this.remove(i); }}>
                                            <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                        </Button>
                                    </ButtonGroup>
                                    <Field component="input" type="hidden" className="form-control" name={`${field}.id`} />
                                </CardFooter>
                            </Card>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchSubmitForm: (form) => submit(form)
}
const mapStateToProps = state => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ScreeningEvents))
