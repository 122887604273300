import React from 'react';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Route, Redirect } from 'react-router-dom';
import UsersList from './UsersList/UsersList';
import UserShow from './UserShow/UserShow';
import UserEdit from './UserEdit/UserEdit';
import UserCreate from './UserCreate/UserCreate';
import UserDelete from './UserDelete/UserDelete';

const Users = ({match}) => {
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.users);
    const { t }                                               = useTranslation();

    // put redirect on specific page beacuse that one prevent an user to edit its profile
    return (
        <div className="Users">

            {isLoading &&
                <span className="loadingMessage">{t('common.loading')}</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }
            <Route exact path={ match.path } component={UsersList} />
            <Route exact path={ `${ match.url }/new` } component={UserCreate} />
            <Route exact path={ `${ match.url }/show/:userId` } component={UserShow} />
            <Route exact path={ `${ match.url }/edit/:userId` } component={UserEdit} />
            <Route exact path={ `${ match.url }/delete/:userId` } component={UserDelete} />
        </div>
    );
}

export default Users;
