import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import { Row, Col } from 'reactstrap';
import { Button, InputGroup } from 'reactstrap';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import _ from 'lodash';

import './SettingOverrideField.css';

const SettingOverrideField = ({ globalSettings, overridedSettings, onAdd }) => {
    const { t }                     = useTranslation();
    const isLoading                 = false;
    const [overrides, setOverrides] = useState([]);

    if (!globalSettings || !globalSettings._id)
        return null;

    const overridablePaths = Utils.getSettingsOverridablePaths();
    const options = overridablePaths.map(path => {
        const translation = t(`settings.${path.key}`) === `settings.${path.key}` ? t(`settings.${path.key}.title`) : t(`settings.${path.key}`);
        return {
            key: path.key,
            text: <div><i className={`fa fa-${path.icon}`} />&nbsp;{ translation }<br/><small><i>{path.key}</i></small></div>,
            value: path.key
        };
    });

    const onOverrideChange = overrides => {
        setOverrides(overridablePaths.filter(p => overrides.includes(p.key)));
    };

    const doOverride = () => {
        overrides.forEach(override => {
            const globalValue = Utils.getDotified(globalSettings, override.key);
            Utils.assignDotified(overridedSettings, override.key, globalValue);
        });
        onAdd && onAdd(overridedSettings);
        setOverrides([]);
    }

    return (
        <div className="SettingOverrideField">
            <Row>
                <Col>
                    <InputGroup>
                        <MultiSelectField
                            fixedWidth
                            clearable
                            filterable
                            options={ options }
                            placeholder={ t('settings.choose_a_setting_to_override') }
                            onSelectChange={ onOverrideChange }
                            value={overrides.map(o => o.key)}
                        />
                        <Button className="add_btn" color="info" onClick={ doOverride } disabled={overrides.length == 0} >
                            {isLoading ?
                            <span>
                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                            </span>
                            :
                            <span>
                                <i className="fa fa-plus"></i>&nbsp;
                                {t("settings.override_selected_fields")}
                            </span>
                            }
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    );
}

export default SettingOverrideField;
