import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Button, Dropdown } from 'semantic-ui-react';
import { updateUser } from '../../redux/modules/users';

import './InstanceSelector.css';

const InstanceSelector = ({ collapsed, onCollapseNavClick }) => {
    const { t }                         = useTranslation();
    const current_user                  = useSelector(state => state.auth.user);
    const tkt_instances                 = useSelector(state => state.tktInstances.tktInstances);
    const [needsReload, setNeedsReload] = useState(false);
    const dispatch                      = useDispatch();

    useEffect(() => {
        if (current_user && needsReload) {
            window.location.reload();
        }
    }, [current_user]);

    if (!current_user)
        return null;

    if (!tkt_instances)
        return null;

    const options = current_user.isSuperAdmin() ? tkt_instances
        .filter(tkt_instance => tkt_instance.is_active)
        .map(tkt_instance => {
            return {
                key: tkt_instance.id,
                text: tkt_instance.name,
                value: tkt_instance.id
            };
        }) : [];

    const onChange = (e, data) => {
        setNeedsReload(true);
        dispatch(updateUser({ ...current_user, tkt_instance_id: data.value }));
    }
    if (current_user.isSuperAdmin() || current_user.can('edit_templates', 'users') || current_user.can('editSelf', 'tktInstances'))
    return (
        <div className='InstanceSelector w-100'>
            {!collapsed && !current_user.isSuperAdmin() &&
                <div className="pad-instance">
                    <i className="fa fa-ticket"></i>&nbsp;
                    {current_user.tkt_instance.name}
                </div>
            }
            <Button.Group className="w-100">
                {(!collapsed && current_user.isSuperAdmin()) &&
                    <Dropdown
                        options={options}
                        defaultValue={current_user.tkt_instance_id}
                        onChange={onChange}
                        search
                        selection
                        className="dropdown-custom-width"
                    />
                }
                <Link onClick={onCollapseNavClick} to={`${current_user.isSuperAdmin() ? "/tktInstances" : "/tktInstances/edit/" + current_user.tkt_instance_id}`} className={`${collapsed ? "w-100" : ""}`}>
                    <Button className={`${collapsed ? "custom-btn-width-collapsed" : ""}`}>
                        <i className="fa fa-gear"></i>
                    </Button>
                </Link>
            </Button.Group>
        </div>
    );

    return null;
}

export default InstanceSelector;
