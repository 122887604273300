import Config from '../config/Config';
import socketIOClient from "socket.io-client";
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

class SioBridge {
    constructor() {
        this.listeners = {};
        this.messages  = [
            'connect', 'disconnect',
            'error', 'timeout',
            'printers_update',
            'efts_update',
            'collectors_update',
        ];
    }

    connect(apiKey, serverUrl) {
        this.serverUrl = serverUrl;
        this.apiKey   = apiKey;
        if (this.socket)
            return new Promise((resolve, reject) => {
                resolve(true);
            });

        return new Promise((resolve, reject) => {
            const url   = `${this.serverUrl}/pos?apikey=${this.apiKey}`;
            this.socket = socketIOClient(url, {
                transports: ['websocket'],
                timeout: 5000,
            });

            this.messages.forEach(msg => {
                this.socket.on(msg, (data, ack) => {
                    this.emit(msg, data);
                    ack && ack(200);
                });
            });

            resolve(true);
        });
    }

    sendMessage(msg, params) {
        return new Promise((resolve, reject) => {
            if (!this.socket)
                return reject(new Error('Not connected'));

            const callback = rsp => {
                const { headers, data } = rsp;
                if (headers?.status !== 200)
                    return reject(data.error);

                resolve(data);
            };

            if (params)
                this.socket.emit(msg, params, callback);
            else
                this.socket.emit(msg, callback);
        });
    }

    on(msg, listener) {
        if (!(msg in this.listeners))
            this.listeners[msg] = {};

        const id = uuidv4();
        this.listeners[msg][id] = listener;

        return () => {
            this.removeListener(msg, id);
        }
    }

    emit(msg, data) {
        if (!(msg in this.listeners))
            return;

        _.mapKeys(this.listeners[msg], (listener, id) => {
            listener(data, () => {
                this.removeListener(msg, id);
            });
        });
    }

    removeListener(msg, id) {
        delete(this.listeners[msg][id]);
    }
}

const SioBridgeApi = new SioBridge();

export default SioBridgeApi;
