import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import ActivitiesList from './ActivitiesList/ActivitiesList';
import ActivityShow from './ActivityShow/ActivityShow';
import ActivityEdit from './ActivityEdit/ActivityEdit';
import ActivityCreate from './ActivityCreate/ActivityCreate';
import ActivityDelete from './ActivityDelete/ActivityDelete';

class Activities extends Component<{}> {
    render() {
        const { match } = this.props;
        const { hasLoadingError, loadingErrorMessage } = this.props.activities;
        return (
            <div className="Activities">

                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}}
                    />
                }
                <Route exact path={ match.path } component={ActivitiesList} />
                <Route exact path={ `${ match.url }/new` } component={ActivityCreate} />
                <Route exact path={ `${ match.url }/show/:activityId` } component={ActivityShow} />
                <Route exact path={ `${ match.url }/edit/:activityId` } component={ActivityEdit} />
                <Route exact path={ `${ match.url }/delete/:activityId` } component={ActivityDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    activities: state.activities
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Activities));
