import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FieldArray, submit } from 'redux-form';
import { Row, Col, CardBody, Card, UncontrolledTooltip } from 'reactstrap';
import { Sector } from '../../models';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './SectorsField.css';

const SectorsField = ({ sectors, name, single, preventSubmit, ...rest }) => {
    const dispatch    = useDispatch();
    const { t, i18n } = useTranslation();

    if (single) {
        const default_lang = Utils.default_lang();

        const options = (sectors || []).map((sector) => ({
            key: sector.id,
            text: sector.name[i18n.language] ?? sector.name[default_lang],
            value: sector.id
        }));

        return <SelectField name={ name } options={ options } clearable />;
    }

    const Component = ({ fields, meta: { form }}) => {

        const selectedSectors = React.useMemo(() => fields.getAll() || [], [ fields.getAll() ]);


        const availableSectors = React.useMemo(() => {
            const selectedIds = selectedSectors.map(s => s.id);
            const available   = sectors?.filter(s => !selectedIds.includes(s.id));

            return Sector.buildTree(available, i18n.language);
        }, [ selectedSectors, i18n.language ]);

        const removeSector = (index) => {
            fields.remove(index);
            !preventSubmit && setTimeout(() => dispatch(submit(form)), 10);
        };

        const addSector = (sector) => {
            fields.push(sector);
            !preventSubmit && setTimeout(() => dispatch(submit(form)), 10);
        };

        return (
            <div className="SectorsField">
                <Row>
                    <Col sm={12} md={5}>
                        <Card className="border mb-3 h-100">
                            <CardBody>
                                <table className='w-100'>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>{t("sectors.available")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {availableSectors?.length > 0 && availableSectors.map((s) => (
                                            <tr key={s.id} className="text-center add" onClick={(e) => addSector(s)} >
                                                <td>
                                                    <span>{s.name[i18n.language]} <i className='fa fa-long-arrow-right '></i></span>
                                                    { s.description?.[i18n.language]?.length > 0 &&
                                                        <span id={`hover-availableSector-${s.id}`} className='mr-3' >
                                                            <i className="fa fa-info-circle"></i>
                                                            <UncontrolledTooltip target={`hover-availableSector-${s.id}`}>
                                                                {s.description[i18n.language].replace(/<\/?[^>]+(>|$)/g, "")}
                                                            </UncontrolledTooltip>
                                                        </span>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col sm={12} md={2} className='text-center'>
                        <i className='fa fa-exchange fa-2x'></i>
                    </Col>

                    <Col sm={12} md={5}>
                        <Card className="border mb-3 h-100">
                            <CardBody>
                                <table className='w-100'>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>{t("sectors.your_sectors")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedSectors.map((s, index) => (
                                            <tr key={s.id} className="text-center remove" onClick={(e) => removeSector(index)} >
                                                <td>
                                                    <span className="text-bold pr-5 ">
                                                        #{ index + 1 }&nbsp;-&nbsp;
                                                    </span>
                                                    <i className='fa fa-long-arrow-left '></i>{s.name[i18n.language]}
                                                    {s.description[i18n.language]?.length > 0 &&
                                                        <span id={`hover-selectedSectors-${s.id}`} className='ml-3' >
                                                            <i className="fa fa-info-circle"></i>
                                                            <UncontrolledTooltip target={`hover-selectedSectors-${s.id}`}>
                                                                { Utils.localized_or_fallback(s.description, i18n.language, '').replace(/<\/?[^>]+(>|$)/g, "")}
                                                            </UncontrolledTooltip>
                                                        </span>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <FieldArray name={ name } component={ Component } sectors={sectors || []} />
    );
}

export default SectorsField;
