import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { loadTags } from '../../../redux/modules/tags';
import { loadArticles } from '../../../redux/modules/articles';
import { loadArticlecategories } from '../../../redux/modules/articlecategories';
import { loadPostcategories } from '../../../redux/modules/post_categories';
import { loadTickettypes } from '../../../redux/modules/tickettypes';
import { loadPlaces } from '../../../redux/modules/places';
import { loadSetting } from '../../../redux/modules/settings';
import { updateSalepoint } from '../../../redux/modules/salepoints';
import DeliveryTypesField from '../../../components/DeliveryTypesField/DeliveryTypesField';
import PosLayoutsField from '../../../components/PosLayoutsField/PosLayoutsField';
import TranslatableField from '../../../components/TranslatableField/TranslatableField';
import SettingOverrideField from '../../../components/SettingOverrideField/SettingOverrideField';
import SettingForm from '../../../containers/Settings/SettingForm/SettingForm';
import SettingsBrowser from '../../../containers/Settings/SettingsBrowser/SettingsBrowser';

import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'
import { Utils } from '../../../services';

moment.locale('fr');
momentLocalizer();

let SalepointForm = ({ handleSubmit, pristine, submitting, isLoading }) => {
    const [activeKey, setActiveKey] = useState(null);
    const { t }                     = useTranslation();
    const dispatch                  = useDispatch();

    const { tickettypes }        = useSelector(state => state.tickettypes);
    const { articles }           = useSelector(state => state.articles);
    const { articlecategories }  = useSelector(state => state.articlecategories);
    const { post_categories }    = useSelector(state => state.post_categories);
    const { places }             = useSelector(state => state.places);
    const { salepoints }         = useSelector(state => state.salepoints);
    const { setting }            = useSelector(state => state.settings);
    const { delivery }           = useSelector(state => state.settings.setting);
    const { salepoint }          = useSelector(state => state.salepoints);

    useEffect(() => {
        dispatch(loadSetting('default'));
        dispatch(loadTags());
        dispatch(loadArticles());
        dispatch(loadArticlecategories());
        dispatch(loadPostcategories());
        //dispatch(loadSalepoints());
        dispatch(loadTickettypes());
        dispatch(loadPlaces());
        if (salepoint?.settings?.customer)
            setActiveKey('customer');
        else if (salepoint?.settings?.images)
            setActiveKey('images');
    }, []);

    const onAddOverridedSettings = (overridedSettings) => {
        salepoint.settings = overridedSettings;
        dispatch(updateSalepoint(salepoint));
        setActiveKey(Object.keys(salepoint.settings)[0] ?? null)
    }

    const onRemoveOverridedSetting = (overridedSettings) => {
        salepoint.settings = Utils.removeDotified(salepoint.settings, overridedSettings, /* cleanEmptyProperties */true);
        setActiveKey(Object.keys(salepoint.settings)[0] ?? null)
        dispatch(updateSalepoint(salepoint));
    }

    const onSaveSettings = (settings) => {
        salepoint.settings = settings;
        dispatch(updateSalepoint(salepoint));
    }

    const onAddImage = image => {
        salepoint.settings.images.push(image);
        dispatch(updateSalepoint(salepoint));
    }

    const onRemoveImage = i => {
        salepoint.settings.images.splice(i, 1);
        dispatch(updateSalepoint(salepoint));
    }

    const onDuplicateImage = image => {
        return onAddImage(image);
    }

    const overrides = salepoint?.settings;

    return (
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("salepoints.informations")}</CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Label for="name" sm={3}>{t("salepoints.name")}</Label>
                                        <Col sm={9}>
                                            <TranslatableField name="name" placeholder={t("salepoints.name_placeholder")} component="input"/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>{t("salepoints.delivery_types")}</Label>
                                        <Col sm={9}>
                                            <DeliveryTypesField name="delivery.types" types={ delivery?.types || [] } />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="name" sm={3}>{t("salepoints.pos_layout")}</Label>
                                        <Col sm={9}>
                                            <PosLayoutsField name="pos_layout" />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-gear"></i>&nbsp;{t("salepoints.settings")}</CardTitle>
                                <CardText tag="div">
                                    <SettingOverrideField
                                        globalSettings={ setting || {} }
                                        overridedSettings={ salepoint?.settings || {} }
                                        onAdd={onAddOverridedSettings}
                                    />
                                    <Row>
                                        <Col sm={ 12 }>
                                            <SettingsBrowser
                                                active={ activeKey }
                                                setting={ salepoint?.settings || {} }
                                                onSelect={ setActiveKey }
                                            />
                                        </Col>
                                        { (activeKey && !isLoading) && (
                                        <Col>
                                            <SettingForm
                                                allowRemoveProperties
                                                initialValues={ salepoint?.settings || {} }
                                                overrides={{
                                                    onAddImage,
                                                    onRemoveImage,
                                                    onDuplicateImage,
                                                    overrides
                                                }}
                                                setting={ salepoint?.settings || {} }
                                                onSubmit={ onSaveSettings }
                                                field={ activeKey || Object.keys(salepoint.settings)[0] }
                                                tickettypes={ tickettypes }
                                                salepoints={ salepoints }
                                                articles={ articles }
                                                articleCategories={ articlecategories}
                                                isLoading={ isLoading }
                                                postCategories={ post_categories }
                                                places={ places }
                                                onDeleteFromSalePoint={onRemoveOverridedSetting}
                                            />
                                        </Col>
                                        )}
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
    );
}

const mapDispatchToProps = {}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SalepointForm = reduxForm({
  form: 'salepointForm' // a unique identifier for this form
})(SalepointForm)

// You have to connect() to any reducers that you wish to connect to yourself
SalepointForm = connect(
  state => ({
    initialValues: state.salepoints.salepoint, // pull initial values from salepoints reducer
    salepoints: state.salepoints,
}), mapDispatchToProps
)(SalepointForm)

export default SalepointForm;
