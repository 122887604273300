import React, { useEffect } from 'react';
import moment from 'moment'
import 'react-table-6/react-table.css'
import { useSelector, useDispatch }from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Utils, Filters } from '../../../services';
import PageTitle from '../../../components/PageTitle/PageTitle';
import Table from '../../../components/Table';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';

import { loadPhases } from '../../../redux/modules/phases';

import './PhasesList.css';

const PhasesList = () => {
    const { t }        = useTranslation();
    const { phases }   = useSelector(state => state.phases);
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();

    useEffect(() => {
        dispatch(loadPhases());
    }, [dispatch]);

    const title = <span>{t("phases.phases_plural")}</span>;

    const actions = <CreateButton module={`phases`} text="phases.add" />;

    const columns = [{
        Header: t('phases.name'),
        accessor: "name",
        filterMethod: Filters.textFilterMethod("name." + default_lang),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => row.value && (
            <div className="name_cell">
                <Link to={`/phases/edit/${row.original.id}`}>
                    {row.original.name[default_lang]}
                </Link>
            </div>
        )
    }, {
        Header: t("phases.active_from") + ' / ' + t("phases.active_until"),
        Filter: Filters.dateFilter(false),
        filterMethod: Filters.dateFilterMethod('start_at'),
        filterAll: true,
        accessor: 'start_at',
        Cell: row => {
            const start_at = row.value;
            const stop_at  = row.original.stop_at;
            const duration = moment(stop_at).diff(moment(start_at), 'seconds', true)
            return (
                <>
                    <i className="fa fa-calendar"></i>&nbsp;{moment(start_at).format('llll')}<br />
                    <i className="fa fa-calendar"></i>&nbsp;{moment(stop_at).format('llll')}<br />
                    Durée : {moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format("hh:mm:ss")}
                </>
            )
        }
    }, {
        Header: t('common.actions'),
        accessor: "id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete"]}
                module="phases"
            />
        )
    }];

    return (
        <div className="PhasesList">
            <PageTitle icon="arrows-h" title={ title } actions={ actions } />
            <Table id="phasesTable" data={ phases } columns={ columns } />
        </div>
    );
}

export default PhasesList;
