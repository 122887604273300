import React from 'react';
import { useSelector } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import PostCreate from './PostCreate/PostCreate';
import PostsList from './PostList/PostsList';
import PostDelete from './PostDelete/PostDelete';
import PostEdit from './PostEdit/PostEdit';

const Posts = ({ match }) => {

    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.posts);

    return (
        <div className="Posts">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }
            <Route exact path={ `${ match.url }/new` } component={PostCreate} />
            <Route exact path={ match.path } component={PostsList} />
            <Route exact path={ `${ match.url }/Delete/:postId` } component={PostDelete} />
            <Route exact path={ `${ match.url }/edit/:postId` } component={PostEdit} />
        </div>
    );
}

export default Posts;