import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ActivityForm from '../ActivityForm/ActivityForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadActivity, updateActivity } from '../../../redux/modules/activities';

class ActivityEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const activityId = this.props.match.params.activityId;
        // Load activities on component mount
        this.props.dispatchLoadActivity(activityId);
    }

    submit(values) {
        this.props.dispatchUpdateActivity(values);
    }

    render() {
        const { t }           = this.props;
        const { activity } = this.props.activities;

        const default_lang = Utils.default_lang();

        let title = <span>{t('activities.activity')}</span>;
        if (activity)
            title = (
                <span>
                    {t('activities.activity')}
                    <small> |&nbsp;{ t('activities.update_activity', { name: activity.name[default_lang] })} </small>
                </span>
            );

        const actions =
        <div>
            {activity &&
            <ButtonGroup>
                <Button tag={Link} to={ `/activities/show/${ activity.id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="ActivityEdit">
                <PageTitle icon="id-badge" title={ title } actions={ actions } />

                {activity &&
                    <ActivityForm activityId={activity.id} onSubmit={this.submit} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadActivity: (activityId) => loadActivity(activityId),
    dispatchUpdateActivity: (activity) => updateActivity(activity)
}

const mapStateToProps = state => ({
    activities: state.activities,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ActivityEdit))
