import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import { Field } from 'redux-form';
import TranslatableField from '../../../../TranslatableField/TranslatableField';
import PlacesField from '../../../../PlacesField/PlacesField';
import { Utils } from '../../../../..//services';

const OnScreenMoviesSettings = ({ env, screen, widget, places }) => {
    const { t } = useTranslation();

    return (
        <>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.title') }
                </Label>
                <Col sm={ 8 }>
                    <TranslatableField
                        type="text"
                        component="input"
                        className="form-control"
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].title` }
                    />
                </Col>
            </FormGroup>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.nbDays') }
                </Label>
                <Col sm={ 8 }>
                    <Field
                        type="number"
                        component="input"
                        className="form-control"
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].nbDays` }
                    />
                </Col>
            </FormGroup>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.hideMoviesTitles') }
                </Label>
                <Col sm={ 8 }>
                    <Field
                        component="select"
                        className="form-control"
                        type="text"
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].hideTitles` }
                        normalize={ Utils.normalizeBoolean }
                    >
                        <option key={ false } value={false}>{t("common.no")}</option>
                        <option key={ true } value={true}>{t("common.yes")}</option>
                    </Field>
                </Col>
            </FormGroup>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.filter_on_places') }
                </Label>
                <Col sm={ 8 }>
                    <PlacesField
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].places` }
                        places={ places }
                    />
                </Col>
            </FormGroup>
        </>
    );
}

export default OnScreenMoviesSettings;
