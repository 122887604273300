import BaseModel from './BaseModel';

/** Class representing an post category. */
class PostCategory extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
PostCategory.getListingFields = () => [];

export default PostCategory;
