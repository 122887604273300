import RestClient from './RestClient';
import SioBridge from '../SioBridge';
import { Eft } from '../../models';
import _ from 'lodash';

class Efts extends RestClient {
    constructor() {
        super({
            model: Eft,
            entryPoint: 'efts',
            resource:   'eft',
            resources:  'efts'
        });
    }

    list() {
        // by sending all_efts instead of efts, we are
        // asking even the disconnected efts
        return SioBridge.sendMessage('all_efts')
            .then(efts => {
                if (Array.isArray(efts)) {
                    efts = efts.map(c => new Eft(c));

                    if (this.sortBy)
                        efts = _.sortBy(efts, this.sortBy);

                    return { efts };
                }

                throw new Error();
            });
    }

    get(id) {
        return SioBridge.sendMessage('eft', { id })
            .then(eft => {
                if (eft) {
                    eft = new Eft(eft);

                    return { eft };
                }

                throw new Error();
            }).catch(error => {
                throw new Error(error);
            });
    }

    update(eft) {
        const { _id, options } = eft;
        return SioBridge.sendMessage('save_eft_options', { _id, options })
            .then(eft => {
                if (eft) {
                    eft = new Eft(eft);

                    return { eft };
                }

                throw new Error();
            }).catch(error => {
                throw new Error(error);
            });
    }
}

const EftsApi = new Efts();

export default EftsApi;
