import React from "react";
import PdfLayout from '../PdfLayout';
import CustomTextWidget from './CustomTextWidget';

/** Class representing a PDF layout link widget. */
class LinkWidget extends CustomTextWidget {

    constructor(properties) {
        super({
            ...(LinkWidget.getDefaultOptions()),
            ...properties
        });

        this.type    = PdfLayout.TEXT;
        this.subType = PdfLayout.LINK;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 5, height: 0.75 },
            transform: { rotation: 0 },
            options: {
                link: {},
                text: {},
                style: {
                    color: '#000000',
                    fontFamily: "Helvetica",
                    fontSize: 14,
                    lineHeight: 1,
                    textAlign: 'left',
                    textDecoration: 'none',
                    backgroundColor: '#FFFFFF00',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            PdfLayout.OPTION_LINK
        ]);
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     */
    getPreview(props) {
        // We override parent getPreview method to tell it
        // to not escape the html content
        return super.getPreview({
            ...props,
            interpretHtmlPreview: true
        });
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreviewText(props) {
        const { lang } = props;

        if (!this.options?.link || !this.options?.link[lang])
            return super.getPreviewText(props);

        return (
            <a target="_blank" href={ this.options?.link[lang] } dangerouslySetInnerHTML={{__html: super.getPreviewText(props) }} />
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'link';
    }
}

export default LinkWidget;
