import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Dropdown } from 'semantic-ui-react';
import DurationCustomComponent from '../DurationField/DurationCustomComponent';

import './BookingPolicyField.css';

const BookingPolicyField = ({ name, defaultValue, predefinedValues }) => {
    const { t } = useTranslation();

    const [sign, setSign] = useState(defaultValue?.toString().charAt(0) === '-' ? '-' : '+');

    const questions = [
        { value: '+', text: t('settings.pos.booking_policy_question_positive') },
        { value: '-', text: t('settings.pos.booking_policy_question_negative') }
    ].map(v => ({
        text: v.text,
        key: v.value,
        value: v.value
    }));

    // show only minutes and hours
    const onlyParts = ['X', 'H'];

    return (
        <div className="BookingPolicyField">
            <div className="row">
                <div className="col-12 col-lg-2">
                    <div className="d-flex h-100 align-items-center">
                        { t('settings.pos.booking_policy_allow_until') }
                    </div>
                </div>
                <div className="col-12 col-lg-5">
                    <Field className="form-control" name={ name } component={
                        props => <DurationCustomComponent
                            onlyParts={onlyParts}
                            noGoBack
                            sign={ sign }
                            defaultValue={defaultValue}
                            {...props}
                        />
                    }/>
                </div>
                <div className="col-12 col-lg-5">
                    <Dropdown
                        className="form-control"
                        options={ questions }
                        onChange={ (e, data) => { setSign(data.value); }}
                        value={ sign }
                        search
                        selection
                    />
                </div>
            </div>
        </div>
    );
}

export default BookingPolicyField;
