import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Button, Row, Col, InputGroup, FormFeedback, FormGroup } from 'reactstrap';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import { Utils } from '../../../../services';
import { updateTktInstance } from '../../../../redux/modules/tktInstances';
import { useDispatch } from 'react-redux';

let TktInstanceAbilitiesTemplateCreate = ({ instance }) => {
    const { t }                           = useTranslation();
    const [templateName, setTemplateName] = useState("")
    const [error, setError]               = useState("")
    const dispatch                        = useDispatch();

    const handleInput = (e) => setTemplateName(e.target.value);

    const createTemplate = () => {
        if (templateName) {
            let flag = true
            instance.abilities_templates.map(i => {
                Object.keys(i).map(k => {
                    if (i[k] === templateName) {
                        setError(t("abilities.create_name_already_used"));
                        flag = false;
                    };
                })
            })
            if (flag) {
                let inst = _.cloneDeep(instance);
                const baseTemplate = Utils.generateAbilities(templateName);
                inst.abilities_templates.push(baseTemplate);
                dispatch(updateTktInstance(inst));
                setError("")
            }
        } else (
            setError(t("abilities.create_empty_field_name"))
        )
    }

    return (
        <div className="TktInstanceAbilitiesTemplateList">
            <Row>
                <Col sm={12} md={3}>
                    <FormGroup>
                        <InputGroup>
                            <Input type="text" value={templateName} onChange={handleInput} invalid={error ? "true" : undefined}></Input>
                            <FormFeedback tooltip invalid={error ? "true" : undefined}>
                                {error}
                            </FormFeedback>
                            <Button color="success" onClick={() => createTemplate()}><i className="fa fa-plus"></i>&nbsp;{t("common.add")}</Button>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default TktInstanceAbilitiesTemplateCreate
