import RestClient from './RestClient';
import { PostCategory } from '../../models';

class Postcategories extends RestClient {
    constructor() {
        super({
			"model": PostCategory,
			"entryPoint": "post_category",
			"resource": "post_category",
			"resources": "post_categories",
			"id_field": "id"
        });
    }
}

const PostcategoriesApi = new Postcategories();

export default PostcategoriesApi;
