import BaseModel from './BaseModel';
import Bucket from './Bucket';
import _ from 'lodash';
import { v4 } from 'uuid';

/** Class representing an bucketlist. */
class Bucketlist extends BaseModel {
    constructor(properties) {
        super(properties);

        this.buckets = (this.buckets || []).map(bucket => new Bucket(bucket));
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();

        prepared.buckets = prepared.buckets.map(bucket => bucket.prepareForUpdate());

        if (!('_id' in prepared))
            prepared._id = v4();

        return prepared;
    }

    /**
     * Prepare this object for duplicate.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForDuplicate() {
        const prepared = _.cloneDeep(this);

        prepared._id   = v4();
        prepared.name += ' copy';

        return prepared;
    }
}

export default Bucketlist;
