import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import EventForm from '../EventForm/EventForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadEvent, startUpdateEvent, updateEvent } from '../../../redux/modules/events';

class EventEdit extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const { eventId, type } = this.props.match.params;
        // Load events on component mount
        this.props.dispatchLoadEvent(type, eventId);
        this.props.dispatchStartUpdateEvent(type, eventId);
    }

    submit(values) {
        const type = this.props.match.params.type;
        this.props.dispatchUpdateEvent(type, values);
    }

    render() {
        const { event, eventSuccessfullyUpdated, eventSuccessfullyCreated }    = this.props.events;
        const { t, match } = this.props;
        const type         = match.params.type;
        const default_lang = Utils.default_lang();

        let title = <span>{t('events.event')}</span>;
        if (event)
            title = (
                <span>
                    {t('events.event')}
                    <small> |&nbsp;{ t('events.update_event', { title: Utils.localized_or_fallback(event.title, default_lang) })} </small>
                </span>
            );

        const actions =
        <div>
            {event &&
            <ButtonGroup>
                <Button tag={Link} to={ `/events/${type}` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="EventEdit">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {event &&
                    <EventForm eventId={event.id} onSubmit={this.submit} type={type}/>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadEvent: (eventType, eventId) => loadEvent(eventType, eventId),
    dispatchStartUpdateEvent: (eventType, event) => startUpdateEvent(eventType, event),
    dispatchUpdateEvent: (eventType, event) => updateEvent(eventType, event)
}

const mapStateToProps = state => ({
    events: state.events,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(EventEdit))
