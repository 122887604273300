import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../../services/';
import { Article } from '../../../../models';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import _ from 'lodash';

import './TypeSelector.css';

const TypeSelector = ({ active, onSelect }) => {
    const { t }       = useTranslation();
    const defaultLang = Utils.default_lang();

    const items = [
        Article.TYPE_PRODUCT,
        Article.TYPE_MANUAL_DISCOUNT,
        Article.TYPE_CODE_DISCOUNT
    ].map(type => {
        return {
            key: type,
            title: t(`articles.type_${type}`),
            icon: 'th',
            active: (active && (active === type)),
            depth: 0,
            selection: type
        }
    });

    return (
        <div className="TypeSelector">
            <ItemsList
                title={ t('articles.filter_by_type') }
                items={ items }
                onSelect={ onSelect }
            />
        </div>
    );
}

export default TypeSelector;
