import React, { useMemo } from 'react';
import { useSelector } from 'react-redux'
import _ from 'lodash';

const Ability = ({ can, anyModuleIn, owner, children }) => {
    const user = useSelector(state => state.auth.user);

    const checkforAny = (mod) => {
        let returnValue = false;
        for (const key in mod) {
            if (mod[key]) {
                returnValue = true;
                break;
            }
        }
        return returnValue;
    }

    const getAbility = () => {
        if (user.abilities.superadmin)
            return true;
        let returnValue = false
        if (user?.abilities)
            if (anyModuleIn) {
                if (!Array.isArray(anyModuleIn))
                    anyModuleIn = [anyModuleIn]
                anyModuleIn.forEach(a => {
                    if (!returnValue && checkforAny(user.abilities[a]))
                        returnValue = true;
                })
            } else if (can) {
                const mod = can.split(':')[0];
                const actions = can.split(':')[1];
                if ((mod in user.abilities))
                    switch (actions) {
                        case 'read':
                            if (((owner && user.abilities[mod].readSelf && user.id === owner) || user.abilities[mod].readOther) || 
                                (!owner && (user.abilities[mod].readSelf || user.abilities[mod].readOther)))
                                returnValue = true;
                            break;
                        case 'edit':
                            if (((owner && user.abilities[mod].editSelf && user.id === owner) || user.abilities[mod].editOther) || 
                                (!owner && (user.abilities[mod].editSelf || user.abilities[mod].editOther)))
                                returnValue = true;
                            break;
                        case 'create':
                            if (user.abilities[mod].create)
                                returnValue = true;
                            break;
                        case 'delete':
                            if (((owner && user.abilities[mod].deleteSelf && user.id === owner) || user.abilities[mod].deleteOther) || 
                                (!owner && (user.abilities[mod].deleteSelf || user.abilities[mod].deleteOther)))
                                returnValue = true;
                            break;
                        case 'any':
                            if (Object.values(user.abilities[mod]).find(actions => actions))
                                returnValue = true;
                            break;
                        default:
                            returnValue = user.can(actions, mod);
                            break;
                    }
            }
        return returnValue;
    }

    const result = getAbility();

    return (
        result ?
            <>{children}</> :
            <></>
    )
}

export default Ability;
