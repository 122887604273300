import BaseModel from './BaseModel';

/** Class representing an event. */
class Event extends BaseModel {
    static TYPE_MOVIE       = 'movie';
    static TYPE_DRAMA       = 'drama';
    static TYPE_MUSIC_GROUP = 'music_group';
    static TYPE_SERVICE     = 'service';

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();
        Object.keys(prepared.title).map(t => {
            prepared.title[t] = prepared.title[t].trim();
        })

        if ('year' in prepared) {
            if (!prepared.year)
                prepared.year = null;
        }

        return prepared;
    }

    static getTypeIcon(type) {
        switch (type) {
            case Event.TYPE_MOVIE:
                return 'film';
            case Event.TYPE_DRAMA:
                return 'puzzle-piece';
            case Event.TYPE_MUSIC_GROUP:
                return 'music';
            case Event.TYPE_SERVICE:
                return 'gift';
        }
    }
}

export default Event;
