import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadBucketlist, deleteBucketlist } from '../../../redux/modules/bucketlists';

class BucketlistDelete extends Component<{}> {
    componentDidMount() {
        const bucketlistId = this.props.match.params.bucketlistId;
        // Load bucketlists on component mount
        this.props.dispatchLoadBucketlist(bucketlistId);
    }

    deleteBucketlist(bucketlistId) {
        this.props.dispatchDeleteBucketlist(bucketlistId);
    }

    render() {
        const { bucketlist, isLoading, bucketlistSuccessfullyDeleted } = this.props.bucketlists;
        const { t } = this.props;

        let title = <span>{t("bulidings.bucketlist_plural")}</span>;
        if (bucketlist)
            title = (
                <span>
                    {t("bucketlists.bucketlist")}
                    <small> | { bucketlist.name } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/bucketlists" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="BucketlistDelete">
                <PageTitle icon="battery-half" title={ title } actions={ actions } />

                {bucketlistSuccessfullyDeleted &&
                    <Redirect to="/bucketlists"/>
                }

                {bucketlist &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('bucketlists.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("bucketlists.name")}</b>: { bucketlist.name }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/bucketlists" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteBucketlist(bucketlist._id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadBucketlist: (bucketlistId) => loadBucketlist(bucketlistId),
    dispatchDeleteBucketlist: (bucketlistId) => deleteBucketlist(bucketlistId)
}

const mapStateToProps = state => ({
    bucketlists: state.bucketlists
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(BucketlistDelete))
