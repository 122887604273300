// Redux Auth module
// see https://github.com/erikras/ducks-modular-redux

import { Auth } from '../../services';

const LOG_IN = 'kronos/auth/LOG_IN';
const LOG_IN_SUCCESS = 'kronos/auth/LOG_IN_SUCCESS';
const LOG_IN_FAILURE = 'kronos/auth/LOG_IN_FAILURE';
const UPDATE_AUTH_USER = 'kronos/auth/UPDATE_AUTH_USER';
const LOG_OUT = 'kronos/auth/LOG_OUT';

const initialState = {
    user: {},
    isAuthenticating: false,

    logInError: false,
    logInErrorMessage: ''
}

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOG_IN:
            return {
                ...state,
                isAuthenticating: true,
                logInError: false
            };
        case LOG_IN_SUCCESS:
            return {
                isAuthenticating: false,
                user: action.user
            };
        case LOG_IN_FAILURE:
            return {
                ...state,
                isAuthenticating: false,
                logInError: true,
                logInErrorMessage: action.error.message
            };
        case UPDATE_AUTH_USER:
            return {
                ...state,
                user: action.user
            };
        case LOG_OUT:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};


// Actions
function logIn() { return { type: LOG_IN } }
function logInSuccess(user) { return { type: LOG_IN_SUCCESS, user: user } }
function logInFailure(err) { return { type: LOG_IN_FAILURE, error: err } }

export function getLastConnectedUser() {
    return (dispatch) => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            Auth.checkApiKey(user.api_key)
                .then(user => {
                    user && dispatch(logInSuccess(user));
                });
        }
    }
};

export function authenticate(username, password) {
    return (dispatch) => {
        dispatch(logIn());
        Auth.logIn(username, password)
            .then(data => {
                const user = data.user;
                dispatch(logInSuccess(user));
            })
            .catch(err => {
                localStorage.setItem('user', null);
                dispatch(logInFailure(err))
            });
    }
}

function updateAuthUserSuccess(user) { return { type: UPDATE_AUTH_USER, user: user } }

export function updateAuthUser(user) {
    return (dispatch) => {
        const current_user = JSON.parse(localStorage.getItem('user'));
        if (user.id === current_user.id) {
            dispatch(updateAuthUserSuccess(user));
        }
    }
}

function logout() { return { type: LOG_OUT } }

export function logOut() {
    return (dispatch) => {
        localStorage.setItem('user', null);
        dispatch(logout());
    }
}