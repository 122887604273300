import store from '..';
import { FormLayouts } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction
} = createRestSlices(FormLayouts);

const SUBMIT_FORM         = 'kronos/tktInstances/SUBMIT_FORM';
const SUBMIT_FORM_SUCCESS = 'kronos/tktInstances/SUBMIT_FORM_SUCCESS';
const SUBMIT_FORM_FAILURE = 'kronos/tktInstances/SUBMIT_FORM_FAILURE';
const reducer = (state = initialState, action) => {
    switch(action.type) {
        case SUBMIT_FORM:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                result: null
            };
        case SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                result: action.result
            };
        case SUBMIT_FORM_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
                result: null
            };
        default:
            return state;
    }
};

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer,
        reducer
    ]);
}

function submittingForm() { return { type: SUBMIT_FORM } }
function submittingFormSuccess(result) { return { type: SUBMIT_FORM_SUCCESS, result } }
function submittingFormFailure(err) { return { type: SUBMIT_FORM_FAILURE, error: err } }
const submitFormAction = (form_id, values, tkt_instance_id) => {
    return (dispatch) => {
        dispatch(submittingForm());
        FormLayouts.submit(form_id, values, tkt_instance_id)
            .then(data => {
                const { result } = data;
                dispatch(submittingFormSuccess(result));
            })
            .catch(err => {
                dispatch(submittingFormFailure(err))
            });
    }
};

/* Export CRUD actions */
export const startCreateFormLayout = startCreateAction;
export const createFormLayout      = createAction;
export const loadFormLayout        = getAction;
export const loadFormLayouts       = listAction;
export const updateFormLayout      = updateAction;
export const deleteFormLayout      = deleteAction;
export const duplicateFormLayout   = duplicateAction;
export const submitForm            = submitFormAction;
