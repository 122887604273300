import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import DurationCustomComponent from './DurationCustomComponent';
import DurationPredefinedComponent from './DurationPredefinedComponent';

import './DurationField.css';

const DurationField = ({ name, predefinedValues, onlyParts, onDurationChange, placeholder, ...rest }) => {
    const [customFieldIsVisible, setCustomFieldIsVisible] = useState(!predefinedValues);
    const [durationValue, setDurationValue] = useState(null);

    useEffect(() => {
        if (durationValue && !predefinedValues?.map(v => v.value).includes(durationValue))
            setCustomFieldIsVisible(true);
    }, [durationValue]);

    return (
        <div className="DurationField">

            {customFieldIsVisible && (
                <Field className="form-control" name={ name } component={
                    props => <DurationCustomComponent
                        onDurationChange={onDurationChange}
                        onlyParts={onlyParts}
                        setCustomFieldIsVisible={setCustomFieldIsVisible}
                        {...props}
                    />
                }/>
            )}

            {!customFieldIsVisible && (
                <Field className="form-control" name={ name } component={
                    props => <DurationPredefinedComponent
                        predefinedValues={predefinedValues}
                        onDurationChange={onDurationChange}
                        setDurationValue={setDurationValue}
                        setCustomFieldIsVisible={setCustomFieldIsVisible}
                        {...props}
                    />
                }/>
            )}
        </div>
    );
}

export default DurationField;
