import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import { Field } from 'redux-form';

const FeaturedScreeningSettings = ({ env, screen, widget }) => {
    const { t } = useTranslation();

    return (
        <FormGroup row className="widget-setting">
            <Label className="col-sm-4">
                { t('settings.mobile.screens.widget.settings.featured_screening_id') }
            </Label>
            <Col sm={ 8 }>
                <Field
                    type="text"
                    component="input"
                    className="form-control"
                    name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].screening` }
                />
            </Col>
        </FormGroup>
    );
}

export default FeaturedScreeningSettings;
