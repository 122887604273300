import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import {BarChart} from '../../../components/Chart/';
import { Utils } from '../../../services';
import "./StatsCarts.css";

const StatsCarts = () => {
    const { t }        = useTranslation();
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();
    const title        = t("statstypes.texts.shopping_carts")
    return (
        <div>
            <PageTitle icon="film" title={title}/>
        </div>
    )
}

export default StatsCarts;
