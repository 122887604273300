import React, { useState } from 'react';
import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap';

import './PaddingInput.css';

const PaddingInput = (props) => {
    const { defaultValue, className, onChange, step, ...rest } = props;

    const [padding, setPadding] = useState(defaultValue);

    const changed = (key, newValue) => {
        const newPadding = {
            ...padding,
            [key]: Number(newValue)
        };
        setPadding(newPadding);
        onChange && onChange(newPadding);
    };

    return (
        <div className={ `PaddingInput ${className}` } { ...rest }>
            <div className="inputs">
                { ['top', 'left', 'right', 'bottom'].map(position => (
                    <Input
                        key={ position }
                        className={ position }
                        min={ 0 }
                        type="number"
                        size="3"
                        step={ step || 1}
                        defaultValue={ padding ? padding[position] : 0 }
                        onChange={(e) => changed(position, e.target.value)}
                    />
                ))}
            </div>
        </div>
    );
}

export default PaddingInput;
