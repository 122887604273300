import RestClient from './RestClient';
import { Phase } from '../../models';

class Phases extends RestClient {
    constructor() {
        super({
            "model": Phase,
            "entryPoint": "phase",
            "resource": "phase",
            "resources": "phases",
            "id": "_id"
        });
    }
}

const PhasesApi = new Phases();

export default PhasesApi;
