import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink } from 'reactstrap';

import  './Switch.css';

const types = [
    { "type": "Movie", "label": "Films", "icon": "film" },
    { "type": "Artist", "label": "Artistes", "icon": "user" },
    { "type": "Author", "label": "Auteurs", "icon": "book" }
];

class Switch extends Component<{}> {
    constructor(props) {
        super(props);

        this.state = {
            current: types[0].type
        };
    }

    render() {
        return (
            <div className="Switch">
                <Nav fill tabs justified>
                {types.map((t) =>
                    <NavItem active={t.type == this.state.current} key={t.type}>
                        <NavLink to={`/events/${t.type}`}>
                            <i className={`fa fa-${t.icon}`} />&nbsp;
                            {t.label}
                        </NavLink>
                    </NavItem>
                )}
                </Nav>
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    events: state.events
})

export default connect(mapStateToProps, mapDispatchToProps)(Switch);
