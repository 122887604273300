import React from 'react';
import { Route } from 'react-router';
import Instance from './Instance/Instance';

import './Public.css';

const Public = (props) => {
    return (
        <div className="Public">
            <div className="wrapper">
                <Route path="/public/:slug" component={Instance} />
           </div>
        </div>
    );
}

export default Public;
