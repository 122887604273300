import React from 'react';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from "i18next";

const UsersField = ({ activities, single, name }) => {

    const options = activities.sort((a, b) => {
        return Utils.localized_or_fallback(a.fullname, i18n.language).localeCompare(
            Utils.localized_or_fallback(b.fullname, i18n.language)
        );
    }).map((activity) => ({
        key: activity.id,
        text: Utils.localized_or_fallback(activity.name, i18n.language),
        value: activity.id
    }));

    return single ? (
        <SelectField name={ name } options={ options } />
    ) : (
        <MultiSelectField name={ name } options={ options } />
    );
}

export default UsersField;
