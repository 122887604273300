import React, { useState, useRef, useEffect } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import "./ResponsiveNav.css"

const ResponsiveNav = ({ data, onChangeTab }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeTab, setActiveTab]           = useState(0);
    const containerRef                        = useRef(null);
    const [isScrollable, setIsScrollable]     = useState(false);

    const toggle = (key) => {
        onChangeTab(key);
        setActiveTab(key);
    }

    const handleScroll = (event) => {
        setScrollPosition(event.target.scrollLeft);
    };

    useEffect(() => {
        const containerElement = containerRef.current;

        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                if (entry.target === containerElement) {
                    setIsScrollable(containerElement.scrollWidth > containerElement.clientWidth);
                }
            }
        });

        if (containerElement) {
            setIsScrollable(containerElement.scrollWidth > containerElement.clientWidth);
            resizeObserver.observe(containerElement);
        }

        return () => {
            if (containerElement) {
                resizeObserver.unobserve(containerElement);
            }
        };
    }, []);

    let canScrollRight     = true
    let containerElement = containerRef.current;
    if (containerElement) {
        if (scrollPosition === containerElement.scrollWidth - containerElement.clientWidth)
            canScrollRight = false;
    }

    const scroll = (amount) => {
        containerElement.scrollLeft += amount;
    }

    return (
        <div className="ResponsiveNav">
            {/* {(isScrollable && scrollPosition !== 0) &&
                <i className="pad pad-left fa fa-chevron-left" onClick={() => scroll(-80)}></i>
            } */}
            <div className="nav-box" ref={containerRef} onScroll={handleScroll}>
                <Nav className={`p-0${isScrollable ? ' scrollable' : ''}`} tabs>
                    {data.map((v, i) => 
                        <NavItem key={i}>
                            <NavLink
                                className={classnames({ active: activeTab === i })}
                                onClick={() => { toggle(i); }}
                            >
                                {v.icon}&nbsp;{v.text}
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
            </div>
            {/* {(isScrollable && canScrollRight) &&
                <i className="pad pad-right fa fa-chevron-right" onClick={() => scroll(80)}></i>
            } */}
        </div>
    )
}

export default ResponsiveNav;