import React, { useState }  from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ButtonGroup,
    Button,
    CardText,
    CardFooter,
} from 'reactstrap';
import { PdfLayout } from '../../../../../models';
import { PaddingInput, TranslatableWysiwyg, TranslatableFieldWithInput } from '../../../../../components';
import { Checkbox } from 'semantic-ui-react'
import { ChromePicker } from 'react-color';

import imagePlaceholder from '../../../../../assets/images/imagePlaceholder.png';
import { set } from "lodash";
import { Screen } from "../../../../../services";

const PdfWidgetOptions = (props) => {
    const {
        widget, currentGroup, onSubmit, onGroupChange, onDelete,
        onDuplicate, onBackward, onForward, onClose,
        // the widgetRef is a ref to the widget main content and can
        // be set or not, depending on the widget type...
        widgetRef,
    } = props;

    const dimensions = React.useMemo(() => {
        if (!widgetRef)
            return null;

        if (!widget?.type === PdfLayout.IMAGE)
            return null;

        return {
            width: widgetRef.naturalWidth,
            height: widgetRef.naturalHeight
        };
    }, [widgetRef]);

    const mustShowResetBtn = () => {
        if (widget?.type != PdfLayout.IMAGE)
            return false;

        if (!dimensions || !widget.dimensions)
            return false;

        if (pixelsToCentimeters(dimensions.width) == widget.dimensions.width && pixelsToCentimeters(dimensions.height) == widget.dimensions.height)
            return false;

        return true;
    };

    const { fonts }    = useSelector(state => state.fonts);
    const { isMobile } = useSelector(state => state.ui);

    const { t } = useTranslation();

    const [group, setGroup]                   = useState(currentGroup || "position");
    const [showResetImage, setShowResetImage] = useState(mustShowResetBtn());

    const setPosition  = (axis, value) => { widget.position[axis] = parseFloat(value, 10); saneSubmit(); }
    const setDimension = (axis, value) => {
        const newValue = parseFloat(value, 10);

        if (widget.options?.lockRatio) {
            const other = axis === 'width' ? 'height' : 'width';
            const ratio = widget.dimensions[axis] / widget.dimensions[other];
            widget.dimensions[other] = newValue / ratio;
        }

        widget.dimensions[axis] = newValue;
        saneSubmit();
        setShowResetImage(mustShowResetBtn());
    }
    const setOption    = (option, value) => { widget.options[option] = value; saneSubmit(); }
    const setTransform = (transformation, value) => { widget.transform[transformation] = parseFloat(value); saneSubmit(); }

    const handleStyle = (property, value) => setOption('style', { ...(widget.options?.style || {}), [property]: value });
    const setFontFamily = (fontFamily) => {
        widget.options.style.fontFamily = fontFamily;
        delete(widget.options.style.fontFamilyVariant);
        saneSubmit();
    }

    const saneSubmit = () => {
        // remove Widget instance prototype...
        onSubmit(widget.toPlainObject());
    }

    const showGroup = group => {
        setGroup(group);
        onGroupChange(group);
    }

    const handleColorWithTransparency = (color, type) => {
        if (color.rgb.a === 1)
            return handleStyle(type, color.hex);

        const percent = color.rgb.a * 100;
        const intValue = Math.round(percent / 100 * 255); // map percent to nearest integer (0 - 255)
        const hexValue = intValue.toString(16); // get hexadecimal representation
        const hexTransparency = hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters

        handleStyle(type, `${color.hex}${hexTransparency}`);
    };

    const selectImage = e => {
        const file = e.target.files[0];
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            setOption('rfc2397_data', fileReader.result);
        }
        fileReader.readAsDataURL(file)
    };

    const hasOptions = widget => {
        return (
            widget.handleOption(PdfLayout.OPTION_CONTACT_FIELD) ||
            widget.handleOption(PdfLayout.OPTION_SCREENING_FIELD) ||
            widget.handleOption(PdfLayout.OPTION_TICKET_FIELD) ||
            widget.handleOption(PdfLayout.OPTION_TICKETTYPE_FIELD) ||
            widget.handleOption(PdfLayout.OPTION_CUSTOMER_FIELD) ||
            widget.handleOption(PdfLayout.OPTION_ENVIRONMENT_FIELD) ||
            widget.handleOption(PdfLayout.OPTION_IMAGE_TYPE) ||
            widget.handleOption(PdfLayout.OPTION_DATE_FORMAT) ||
            widget.handleOption(PdfLayout.OPTION_DATE_OFFSET) ||
            widget.handleOption(PdfLayout.OPTION_TEXT) ||
            widget.handleOption(PdfLayout.OPTION_LINK) ||
            widget.handleOption(PdfLayout.OPTION_IMAGE) ||
            widget.handleOption(PdfLayout.OPTION_OBJECT_FIT)
        );
    }

    const selectedFont = fonts && widget.options?.style?.fontFamily ?
        fonts.find(f => f.name === widget.options.style.fontFamily) :
        null;

    function pixelsToCentimeters(pixels) {
        return Screen.pxToCm(pixels).toFixed(2);
    }

    function resetDimentionImageActive() {
        if (dimensions) {
            widget.dimensions.width  = pixelsToCentimeters(dimensions.width);
            widget.dimensions.height = pixelsToCentimeters(dimensions.height);
            saneSubmit();
            setShowResetImage(false);
        }
    }

    return (
        <div className="wrapper-1 mr-1">
            <div className="">
                <CardText tag="div" className={"mb-4" + (isMobile ? " d-flex flex-column-reverse" : "")}>
                    <ButtonGroup>
                        <Button color={group === "position" ? 'primary' : 'dark'} onClick={() => showGroup("position")}>
                            <i className="fa fa-arrows-alt" />{' '}
                            { t('layouts.widget_options.groups.position') }
                        </Button>
                        <Button color={group === "options" ? 'primary' : 'dark'} onClick={() => showGroup("options")}>
                            <i className="fa fa-cog" />{' '}
                            { t('layouts.widget_options.groups.options') }
                        </Button>
                        <Button color={group === "style" ? 'primary' : 'dark'} onClick={() => showGroup("style")}>
                            <i className="fa fa-pencil" />{' '}
                            { t('layouts.widget_options.groups.style') }
                        </Button>
                    </ButtonGroup>
                    <Button type="button" color="danger" className="pull-right" onClick = { () => onClose() }>
                        <span>
                            <i className="fa fa-times"></i>
                        </span>
                    </Button>
                </CardText>

                { group === "options" && !hasOptions(widget) && (
                    <CardText tag="div" className="mb-3 text-center">
                        <b>
                            <i className="fa fa-info-circle"></i>&nbsp;
                            { t('layouts.no_options') }
                        </b>
                    </CardText>
                )}

                { group === "options" && hasOptions(widget) && (
                <CardText tag="div">
                    {(
                        widget.handleOption(PdfLayout.OPTION_CONTACT_FIELD) ||
                        widget.handleOption(PdfLayout.OPTION_SCREENING_FIELD) ||
                        widget.handleOption(PdfLayout.OPTION_TICKET_FIELD) ||
                        widget.handleOption(PdfLayout.OPTION_TICKETTYPE_FIELD) ||
                        widget.handleOption(PdfLayout.OPTION_ENVIRONMENT_FIELD) ||
                        widget.handleOption(PdfLayout.OPTION_CUSTOMER_FIELD)
                        ) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.field_to_show")}</Label>
                            <Input defaultValue={widget.options?.field} type="select" onChange={ e => setOption('field', e.target.value) }>
                                <option>{t("layouts.field_placeholder")}</option>
                                { widget.handleOption(PdfLayout.OPTION_CONTACT_FIELD) && PdfLayout.contactFields().map(field => (
                                    <option key={ field } value={ field }>
                                        {t(`layouts.contact_field_${field}`)}
                                    </option>
                                ))}
                                { widget.handleOption(PdfLayout.OPTION_SCREENING_FIELD) && PdfLayout.screeningFields().map(field => (
                                    <option key={ field } value={ field }>
                                        {t(`layouts.screening_field_${field}`)}
                                    </option>
                                ))}
                                { widget.handleOption(PdfLayout.OPTION_TICKET_FIELD) && PdfLayout.ticketFields().map(field => (
                                    <option key={ field } value={ field }>
                                        {t(`layouts.ticket_field_${field}`)}
                                    </option>
                                ))}
                                { widget.handleOption(PdfLayout.OPTION_TICKETTYPE_FIELD) && PdfLayout.tickettypeFields().map(field => (
                                    <option key={ field } value={ field }>
                                        {t(`layouts.tickettype_field_${field}`)}
                                    </option>
                                ))}
                                { widget.handleOption(PdfLayout.OPTION_CUSTOMER_FIELD) && PdfLayout.customerFields().map(field => (
                                    <option key={ field } value={ field }>
                                        {t(`layouts.customer_field_${field}`)}
                                    </option>
                                ))}
                                { widget.handleOption(PdfLayout.OPTION_ENVIRONMENT_FIELD) && PdfLayout.environmentFields().map(field => (
                                    <option key={ field } value={ field }>
                                        {t(`layouts.environment_field_${field}`)}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(PdfLayout.OPTION_IMAGE_TYPE) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.image_type")}</Label>
                            <Input defaultValue={widget.options?.type} type="select" onChange={ e => setOption('type', e.target.value) }>
                                <option>{t("layouts.image_type_placeholder")}</option>
                                {PdfLayout.imageTypes().map(type => (
                                    <option key={ type } value={ type }>
                                        {t(`common.image_types.${type}`)}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(PdfLayout.OPTION_DATE_FORMAT) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.date_format")}</Label>
                            <Input defaultValue={widget.options?.date_format ?? "lll"} type="select" onChange={ e => setOption('date_format', e.target.value) }>
                                <option>{t("layouts.date_format_placeholder")}</option>
                                {PdfLayout.dateFormats().map(format => (
                                    <option key={ format } value={ format }>
                                        {t(`layouts.date_format_${format}`)}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(PdfLayout.OPTION_DATE_OFFSET) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.date_offset")}</Label>
                            <Input defaultValue={widget.options?.date_offset ?? "PT0M"} type="text" onChange={ e => setOption('date_offset', e.target.value) } />
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(PdfLayout.OPTION_TEXT) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.text")}</Label>
                            <TranslatableWysiwyg defaultValue={ widget.options?.text } onChange={ text => setOption('text', text) }/>
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(PdfLayout.OPTION_LINK) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.link")}</Label>
                            <TranslatableFieldWithInput name={widget.options?.link} type="textarea" onChange={ link => setOption('link', link) }/>
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(PdfLayout.OPTION_IMAGE) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.click_to_choose_image")}</Label>
                            <div className="imageOption">
                                <img
                                    src={ widget.options?.rfc2397_data ? widget.options.rfc2397_data : imagePlaceholder }
                                />
                                <input className="file_input" type="file" accept="image/*" onChange={ e => selectImage(e) } />
                            </div>
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(PdfLayout.OPTION_OBJECT_FIT) && (widget.options?.rfc2397_data || widget.options?.type || widget.subType === PdfLayout.OWNER_CONTACT_IMAGE) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.object_fit")}</Label>
                            <Input defaultValue={widget.options?.style?.objectFit} type="select" onChange={ e => handleStyle('objectFit', e.target.value) }>
                                <option>{t("layouts.object_fit_placeholder")}</option>
                                {PdfLayout.objectFitValues().map(value => (
                                    <option key={ value } value={ value }>
                                        { t(`layouts.object_fit_${value}`) }
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                    )}
                </CardText>
                )}
                { group === "style" && (
                <CardText tag="div">
                    { widget.handleOption(PdfLayout.OPTION_ALIGNMENT) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.text_alignment")}</Label>
                            <Input defaultValue={widget.options?.style?.align} type="select" onChange={ e => handleStyle('align', e.target.value) }>
                                <option>{t("layouts.text_alignment_placeholder")}</option>
                                <option value={ 'left' }> {t('layouts.text_alignment_left')} </option>
                                <option value={ 'center' }> {t('layouts.text_alignment_center')} </option>
                                <option value={ 'right' }> {t('layouts.text_alignment_right')} </option>
                            </Input>
                        </Col>
                    </Row>
                    )}
                    { (widget.handleOption(PdfLayout.OPTION_FONT_FAMILY) || widget.handleOption(PdfLayout.OPTION_FONT_SIZE)) && (
                    <>
                        <Row>
                            { widget.handleOption(PdfLayout.OPTION_FONT_FAMILY) && (
                                <Col sm={6}>
                                    <Label> {t("layouts.font_family")}</Label>
                                    <Input defaultValue={widget.options?.style?.fontFamily} type="select" onChange={ e => (setFontFamily(e.target.value)) }>
                                        <option value="">{t("layouts.font_family_placeholder")}</option>
                                        {fonts?.map(font => (
                                            <option key={ font.name } value={ font.name }>
                                                { font.name }
                                            </option>
                                        ))}
                                    </Input>
                                    { selectedFont && selectedFont.hasAnyVariant() && (
                                    <>
                                        <Label> {t("layouts.font_family_variant")}</Label>
                                        <Input defaultValue={widget.options?.style?.fontFamilyVariant} type="select" onChange={ e => handleStyle('fontFamilyVariant', e.target.value) }>
                                            <option value="">{t("layouts.font_family_variant_placeholder")}</option>
                                            {selectedFont && selectedFont.boldVariant() && (
                                                <option key={ 'bold' } value={ 'bold' }>
                                                    { t("layouts.font_family_variant_bold") }
                                                </option>
                                            )}
                                            {selectedFont && selectedFont.italicVariant() && (
                                                <option key={ 'italic' } value={ 'italic' }>
                                                    { t("layouts.font_family_variant_italic") }
                                                </option>
                                            )}
                                            {selectedFont && selectedFont.boldItalicVariant() && (
                                                <option key={ 'boldItalic' } value={ 'boldItalic' }>
                                                    { t("layouts.font_family_variant_boldItalic") }
                                                </option>
                                            )}
                                        </Input>
                                    </>
                                    )}
                                </Col>
                            )}
                            { widget.handleOption(PdfLayout.OPTION_FONT_SIZE) && (
                                <Col sm={6}>
                                    <Row>
                                        <Col sm={12}>
                                            <Label> {t("layouts.font_size")}</Label>
                                            <Input
                                                type="number"
                                                min={ 0 }
                                                defaultValue={ widget.options?.style?.fontSize }
                                                onChange={ e => handleStyle('fontSize', parseFloat(e.target.value, 10))}
                                            />
                                        </Col>
                                        <Col sm={12}>
                                            <Label> {t("layouts.line_height")}</Label>
                                            <Input
                                                type="number"
                                                min={ 0 }
                                                step={ 0.1 }
                                                defaultValue={ widget.options?.style?.lineHeight || 1 }
                                                onChange={ e => handleStyle('lineHeight', e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </>
                    )}
                    { (widget.handleOption(PdfLayout.OPTION_TEXT_ALIGNMENT) || widget.handleOption(PdfLayout.OPTION_ALIGNMENT)) && (
                    <Row>
                        { widget.handleOption(PdfLayout.OPTION_TEXT_ALIGNMENT) && (
                            <Col sm={6}>
                                <Label> {t("layouts.text_alignment")}</Label>
                                <Input defaultValue={widget.options?.style?.textAlign} type="select" onChange={ e => handleStyle('textAlign', e.target.value) }>
                                    <option>{t("layouts.text_alignment_placeholder")}</option>
                                    <option value={ 'left' }> {t('layouts.text_alignment_left')} </option>
                                    <option value={ 'center' }> {t('layouts.text_alignment_center')} </option>
                                    <option value={ 'right' }> {t('layouts.text_alignment_right')} </option>
                                    <option value={ 'justify' }> {t('layouts.text_alignment_justify')} </option>
                                </Input>
                            </Col>
                        )}
                        { widget.handleOption(PdfLayout.OPTION_TEXT_DECORATION) && (
                            <Col sm={6}>
                                <Label> {t("layouts.text_decoration")}</Label>
                                <Input defaultValue={widget.options?.style?.textDecoration} type="select" onChange={ e => handleStyle('textDecoration', e.target.value) }>
                                    <option>{t("layouts.text_decoration_placeholder")}</option>
                                    {PdfLayout.textDecorationValues().map(value => (
                                        <option key={ value } value={ value }>
                                            { t(`layouts.text_decoration_${value}`) }
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                        )}
                        { widget.handleOption(PdfLayout.OPTION_TEXT_TRANSFORM) && (
                            <Col sm={6}>
                                <Label> {t("layouts.text_transform")}</Label>
                                <Input defaultValue={widget.options?.style?.textTransform} type="select" onChange={ e => handleStyle('textTransform', e.target.value) }>
                                    <option>{t("layouts.text_transform_placeholder")}</option>
                                    {PdfLayout.textTransformValues().map(value => (
                                        <option key={ value } value={ value }>
                                            { t(`layouts.text_transform_${value}`) }
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                        )}
                    </Row>
                    )}
                    { (widget.handleOption(PdfLayout.OPTION_COLOR) || widget.handleOption(PdfLayout.OPTION_BACKGROUND_COLOR) || widget.handleOption(PdfLayout.OPTION_BORDER_COLOR)) && (
                        <Row>
                        { widget.handleOption(PdfLayout.OPTION_COLOR) && (
                            <Col sm={6}>
                                <Label> {t("layouts.color")}</Label>
                                <ChromePicker
                                    color={ widget.options?.style?.color }
                                    onChange={ color => handleStyle('color', color.hex)  }
                                />
                            </Col>
                        )}
                        { widget.handleOption(PdfLayout.OPTION_BACKGROUND_COLOR) && (
                            <Col sm={6}>
                                <Label> {t("layouts.background_color")}</Label>
                                <ChromePicker
                                    color={ widget.options?.style?.backgroundColor }
                                    onChange={ color => handleColorWithTransparency(color, 'backgroundColor') }
                                />
                            </Col>
                        )}
                        { widget.handleOption(PdfLayout.OPTION_BORDER_COLOR) && (
                            <Col sm={6}>
                                <Label> {t("layouts.border_color")}</Label>
                                <ChromePicker
                                    color={ widget.options?.style?.borderColor }
                                    onChange={ color => handleStyle('borderColor', color.hex)  }
                                />
                            </Col>
                        )}
                        </Row>
                    )}
                    { (widget.handleOption(PdfLayout.OPTION_BORDER_WIDTH) || widget.handleOption(PdfLayout.OPTION_BORDER_STYLE)) && (
                    <Row>
                        { widget.handleOption(PdfLayout.OPTION_BORDER_WIDTH) && (
                        <Col sm={6}>
                          <Label>{t('layouts.border_width')}</Label>
                          <Input
                              type="number"
                              min = { 0 }
                              defaultValue={ widget.options?.style?.borderWidth }
                              onChange={ e => handleStyle('borderWidth', e.target.value)}
                          />
                        </Col>
                        )}
                        { widget.handleOption(PdfLayout.OPTION_BORDER_STYLE) && (
                        <Col sm={6}>
                            <Label> {t("layouts.border_style")}</Label>
                            <Input defaultValue={widget.options?.style?.borderStyle} type="select" onChange={ e => handleStyle('borderStyle', e.target.value) }>
                                <option>{t("layouts.border_style_placeholder")}</option>
                                {PdfLayout.borderStyleValues().map(value => (
                                    <option key={ value } value={ value }>
                                        { t(`layouts.border_style_${value}`) }
                                    </option>
                                ))}
                            </Input>
                        </Col>
                        )}
                    </Row>
                    )}
                    { widget.handleOption(PdfLayout.OPTION_BORDER_RADIUS) && (
                    <Row>
                        <Col>
                          <Label>{ t('layouts.border_radius') }</Label>
                          <Input
                              type="number"
                              min = { 0 }
                              defaultValue={ widget.options?.style?.borderRadius || 0 }
                              onChange={ e => handleStyle('borderRadius', e.target.value)}
                          />
                        </Col>
                    </Row>
                    )}
                </CardText>
                )}
                { group === "position" && (
                <CardText tag="div">
                    <Row>
                        { widget.handleOption(PdfLayout.OPTION_X) && (
                        <Col className={isMobile ? "p-1" : ""}>
                            <Label>{ t('layouts.x') }</Label>
                            <InputGroup>
                                <Input
                                    type="number"
                                    step={ 0.01 }
                                    min={ 0 }
                                    value={ widget.position?.x }
                                    onChange={ e => setPosition('x', e.target.value)}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        )}
                        { widget.handleOption(PdfLayout.OPTION_Y) && (
                        <Col className={isMobile ? "p-1" : ""}>
                            <Label>{ t('layouts.y') }</Label>
                            <InputGroup>
                                <Input
                                    type="number"
                                    step={ 0.01 }
                                    min={ 0 }
                                    value={ widget.position?.y }
                                    onChange={ e => setPosition('y', e.target.value)}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        )}
                    </Row>
                    <Row>
                        { widget.handleOption(PdfLayout.OPTION_WIDTH) && (
                        <Col className={isMobile ? "p-1" : ""}>
                            <Label>{ t('layouts.width') }</Label>
                            <InputGroup>
                                <Input
                                    type="number"
                                    step={ 0.01 }
                                    min={ 0 }
                                    value={ widget.dimensions?.width }
                                    onChange={ e => setDimension('width', e.target.value)}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        )}
                        { widget.handleOption(PdfLayout.OPTION_HEIGHT) && (
                        <Col className={isMobile ? "p-1" : ""}>
                            <Label>{ t("layouts.height") }</Label>
                            <InputGroup>
                                <Input
                                    type="number"
                                    step={ 0.01 }
                                    min={ 0 }
                                    value={ widget.dimensions?.height }
                                    onChange={ e => setDimension('height', e.target.value)}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>cm</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        )}
                    </Row>
                    {showResetImage &&
                    <Row>
                        <Col md={8}>
                            <Label className="pr-1">{t("layouts.reset_image")}</Label>
                            <span>({dimensions && pixelsToCentimeters(dimensions.width) }x{dimensions && pixelsToCentimeters(dimensions.height)})</span>
                        </Col>
                        <Col md={4}>
                            <Button type="button" color="dark" onClick = { resetDimentionImageActive }>
                                <span><i className="fa fa-undo text-light"></i></span>
                            </Button>
                        </Col>
                    </Row>
                    }
                    { widget.handleOption(PdfLayout.OPTION_LOCK_RATIO) && (
                    <Row>
                        <Col className={isMobile ? "p-1" : ""}>
                            <Row>
                                <Col md={8}>
                                    <Label>{t("layouts.lockRatio")}</Label>
                                </Col>
                                <Col md={4}>
                                    <Checkbox
                                        toggle
                                        checked={!!widget?.options?.lockRatio}
                                        onChange={ (e, data) => setOption('lockRatio', data.checked) } />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    )}
                    { widget.handleOption(PdfLayout.OPTION_PADDING) && (
                    <Row>
                        <Col>
                            <Label> {t("layouts.padding")}</Label>
                            <PaddingInput
                                defaultValue={ widget.options?.style?.padding || {}}
                                step={ 0.01 }
                                onChange={padding => handleStyle('padding', padding) }
                            />
                        </Col>
                    </Row>
                    )}
                    <Row>
                        { widget.handleOption(PdfLayout.OPTION_ROTATION) && (
                        <Col>
                            <Label>{t('layouts.rotation')}</Label>
                            <div className="rangeWrapper">
                                <div className="range">
                                    <i className="fa fa-undo" />
                                    <Input
                                        type="range"
                                        min={ -180 }
                                        max={ 180 }
                                        step={ 1 }
                                        value={ widget.transform.rotation }
                                        onChange={ e => setTransform('rotation', e.target.value)}
                                    />
                                    <i className="fa fa-repeat" />
                                </div>
                                <div className="rangeInfos">
                                    <Input
                                        type="number"
                                        min={ -180 }
                                        max={ 180 }
                                        step={ 1 }
                                        value={ widget.transform.rotation }
                                        onChange={ e => setTransform('rotation', e.target.value)}
                                    />
                                    °
                                </div>
                            </div>
                        </Col>
                        )}
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <ButtonGroup>
                                <Button type="button" color="dark" onClick = { onBackward }>
                                    <span>
                                        <i className="fa fa-arrow-down"></i>&nbsp;{t("layouts.move_backward")}
                                    </span>
                                </Button>
                                <Button type="button" color="dark" onClick = { onForward }>
                                    <span>
                                        <i className="fa fa-arrow-up"></i>&nbsp;{t("layouts.move_forward")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </CardText>
                )}
                <CardFooter>
                    <Row>
                        <Col className={"text-" + (isMobile ? "center m-1": "left")}>
                            <ButtonGroup>
                                <Button type="button" color="danger" onClick = { () => onDelete() }>
                                    <span>
                                        <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col className={"text-" + (isMobile ? "center m-1": "right")}>
                            <ButtonGroup>
                                <Button type="button" color="info" onClick = { () => onDuplicate() }>
                                    <span>
                                        <i className="fa fa-copy"></i>&nbsp;{t("common.duplicate")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </CardFooter>
            </div>
        </div>
    );
}

export default PdfWidgetOptions;
