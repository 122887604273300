import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import SelectField from '../SelectField/SelectField';
import i18n from '../../locales/i18n';

import './PostLinksField.css';

const PostLinksField = ({ name }) => {
    const [ type, setType ] = useState(null);

    const { t }   = useTranslation();
    const options = Utils.link_types().map(type => {
        return {
            key: type,
            text: t(`common.link_types.${type}`),
            value: type
        };
    });

    const placeholderByType = () => {
        switch (type) {
            case Utils.LINK_TYPE_CATEGORY:
                return t("posts.links_placeholder_category");
            case Utils.LINK_TYPE_ARTICLE:
                return t("posts.links_placeholder_article");
            case Utils.LINK_TYPE_URL:
                return t("posts.links_placeholder_url");
            case Utils.LINK_TYPE_MOVIE:
                return t("posts.links_placeholder_movie");
            case Utils.LINK_TYPE_SCREENING:
                return t("posts.links_placeholder_screening");
            default:
        }
    }
    return (
        <Row>
            <Col sm={4}>
                <SelectField
                    name={ `${name}.type` }
                    options={ options }
                    placeholder={ t("posts.please_create_link") }
                    clearable
                    onSelectChange={ (type) => { setType(type); } }
                />
            </Col>
            { type && (
            <Col sm={8}>
                <Field
                    name={ `${name}.value` }
                    optional
                    component="input"
                    className="form-control"
                    type="text"
                    placeholder={ placeholderByType() }
                />
            </Col>
            )}
        </Row>
    );
}

export default PostLinksField;
