import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import TickettypeForm from '../TickettypeForm/TickettypeForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { startCreateTickettype, createTickettype } from '../../../redux/modules/tickettypes';

const TickettypeCreate = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startCreateTickettype());
    }, [dispatch])

    const submit = (values) => {
        dispatch(createTickettype(values));
    }

    const { tickettypeSuccessfullyCreated } = useSelector(state => state.tickettypes);

    let title = <span>{t('tickettypes.add')}</span>;

    const actions =
        <Button tag={Link} to="/tickettypes" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="TickettypeCreate">
            <PageTitle icon="tags" title={ title } actions={ actions } />

            {tickettypeSuccessfullyCreated &&
                <Redirect to="/tickettypes"/>
            }

            <TickettypeForm onSubmit={ submit } isCreation="true" />
        </div>
    );
}

export default TickettypeCreate;
