import React from "react";
import BaseModel from '../BaseModel';
import EmailLayout from '../EmailLayout';
import _ from 'lodash';

/** Class representing a PDF layout widget. */
class EmailLayoutWidget extends BaseModel {
    constructor(properties) {
        super(properties || {});

        if (!this.id)
            this.id = _.uniqueId('widget_');
    }

    getType() {
        return this.type;
    }

    getSubType() {
        return this.subType;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            options: {
                style: {}
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return [
            EmailLayout.OPTION_BACKGROUND_COLOR,
            EmailLayout.OPTION_BORDER_COLOR,
            EmailLayout.OPTION_BORDER_STYLE,
            EmailLayout.OPTION_BORDER_WIDTH,
            EmailLayout.OPTION_BORDER_RADIUS,
        ];
    }

    /**
     * Check if this widget handles the provided option
     */
    handleOption(option) {
        return this.getHandledOptions().includes(option);
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {EmailLayout} layout
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        return <div>{`${this.type} - ${this.subType}`}</div>;
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'cube';
    }

    /**
     * Is this widget resizable ?
     **/
    isResizable = () => true;

    /**
     * Is this widget resizable only horizontally ?
     **/
    isResizableHorizontallyOnly = () => false;

    /**
     * Is this widget editable ?
     **/
    isEditable = () => true;

    /**
     * Set one of this widget option
     */
    setOption = (option, value) => {
        this.options = {
            ...(this.options || {}),
            [option]: value
        };
    };

    /**
     * Convert this widget instance to a plain object
     */
    toPlainObject = () => JSON.parse(JSON.stringify(this));
}

export default EmailLayoutWidget;
