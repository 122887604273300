import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadTickettype, deleteTickettype } from '../../../redux/modules/tickettypes';

class TickettypeDelete extends Component<{}> {
    componentDidMount() {
        const tickettypeId = this.props.match.params.tickettypeId;
        // Load tickettypes on component mount
        this.props.dispatchLoadTickettype(tickettypeId);
    }

    deleteTickettype(tickettypeId) {
        this.props.dispatchDeleteTickettype(tickettypeId);
    }

    render() {
        const { tickettype, isLoading, tickettypeSuccessfullyDeleted } = this.props.tickettypes;
        const { t } = this.props;

        const default_lang = Utils.default_lang();

        let title = <span>{t("bulidings.tickettype_plural")}</span>;
        if (tickettype && tickettype.name)
            title = (
                <span>
                    {t("tickettypes.tickettype")}
                    <small> | { tickettype.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/tickettypes" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="TickettypeDelete">
                <PageTitle icon="tags" title={ title } actions={ actions } />

                {tickettypeSuccessfullyDeleted &&
                    <Redirect to="/tickettypes"/>
                }

                {tickettype && tickettype.name &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('tickettypes.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("tickettypes.name")}</b>: { tickettype.name[default_lang] }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/tickettypes" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteTickettype(tickettype._id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadTickettype: (tickettypeId) => loadTickettype(tickettypeId),
    dispatchDeleteTickettype: (tickettypeId) => deleteTickettype(tickettypeId)
}

const mapStateToProps = state => ({
    tickettypes: state.tickettypes
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(TickettypeDelete))
