import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import TasksList from './TasksList/TasksList';
import TaskEdit from './TaskEdit/TaskEdit';
import TaskCreate from './TaskCreate/TaskCreate';
import TaskDelete from './TaskDelete/TaskDelete';

class Tasks extends Component<{}> {
    render() {
        const { match } = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.tasks;
        return (
            <div className="Tasks">
                {isLoading &&
                    <span className="loadingMessage">
                        <i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...
                    </span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={TasksList} />
                <Route exact path={ `${ match.url }/new/:screening_id?` } component={TaskCreate} />
                <Route exact path={ `${ match.url }/edit/:taskId` } component={TaskEdit} />
                <Route exact path={ `${ match.url }/delete/:taskId` } component={TaskDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    tasks: state.tasks
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks));
