import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { Utils } from '../../../services';
import { loadTickettypes } from '../../../redux';
import { ToggleField, TranslatableField, TickettypesField, PricingsField, SelectField } from '../../../components';
import { withTranslation } from 'react-i18next';

import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

let ActivityForm = props => {
    const { tickettypes }         = useSelector(state => state.tickettypes);
    const { activity, isLoading } = useSelector(state => state.activities);

    const { handleSubmit, pristine, submitting, t } = props;

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadTickettypes());
    }, []);

    const pricings = React.useMemo(() => {
        const pricings = [];

        if (!tickettypes || !activity?.reward?.ticket?.type)
            return pricings;

        const type = tickettypes.find(t => t._id === activity.reward.ticket.type);

        Object.keys(type?.pricings || {}).forEach(id => {
            pricings.push({
                ...(type.pricings[id]),
                id
            });
        });

        return pricings;
    }, [activity, tickettypes]);

    const yesNoField = name => {
        const options = [{
            text: t('common.yes'),
            value: true
        }, {
            text: t('common.no'),
            value: false
        }];
        return (

            <SelectField
                name={ name }
                options={ options }
                clearable
            />
        );
    };

    return (
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("activities.informations")}</CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Label for="name" sm={3}>{t("activities.name")}</Label>
                                        <Col sm={9}>
                                            <TranslatableField name="name" placeholder={t("activities.name_placeholder")} component="input"/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="is_exclusive" sm={3}>{t("activities.is_exclusive")}</Label>
                                        <Col sm={9}>
                                            <ToggleField name="is_exclusive" />
                                        </Col>
                                    </FormGroup>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-trophy"></i>&nbsp;{t("activities.reward.title")}</CardTitle>
                                <CardText tag="div">
                                    <FormGroup row>
                                        <Label for="name" sm={3}>{t("activities.reward.ticket.type")}</Label>
                                        <Col sm={9}>
                                            <TickettypesField name="reward.ticket.type" tickettypes={tickettypes} single />
                                            <small>{t("activities.reward.ticket.type_desc")}</small>
                                        </Col>
                                    </FormGroup>
                                    {activity?.reward?.ticket?.type && (
                                    <FormGroup row>
                                        <Label for="name" sm={3}>{t("activities.reward.ticket.pricing")}</Label>
                                        <Col sm={9}>
                                            <PricingsField name="reward.ticket.pricing" pricings={ pricings } />
                                            <small>{t("activities.reward.ticket.pricing_desc")}</small>
                                        </Col>
                                    </FormGroup>
                                    )}
                                </CardText>
                                {activity?.reward?.ticket?.type && (
                                    <CardText tag="div">
                                        <CardTitle>
                                            <small>{t("activities.reward.bookings.title")}</small>
                                        </CardTitle>
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("activities.reward.bookings.nb_hours")}</Label>
                                            <Col sm={9}>
                                                <Field component="input" type="number" className="form-control" name="reward.bookings.nb_hours" />
                                                <small>{t("activities.reward.bookings.nb_hours_desc")}</small>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("activities.reward.bookings.at_the_end")}</Label>
                                            <Col sm={9}>
                                                { yesNoField('reward.bookings.at_the_end') }
                                                <small>{t("activities.reward.ticket.pricing_desc")}</small>
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                )}
                                {activity?.reward?.ticket?.type && (
                                    <CardText tag="div">
                                        <CardTitle>
                                            <small>{t("activities.reward.wallet.title")}</small>
                                        </CardTitle>
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("activities.reward.wallet.nb_hours", {currency: Utils.currency()})}</Label>
                                            <Col sm={9}>
                                                <Field component="input" type="number" className="form-control" name="reward.wallet.nb_hours" />
                                                <small>{t("activities.reward.wallet.nb_hours_desc", {currency: Utils.currency()})}</small>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("activities.reward.wallet.at_the_end")}</Label>
                                            <Col sm={9}>
                                                { yesNoField('reward.wallet.at_the_end') }
                                                <small>{t("activities.reward.wallet.at_the_end_desc", { currency: Utils.currency() })}</small>
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
    );
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ActivityForm = reduxForm({
  form: 'activityForm' // a unique identifier for this form
})(ActivityForm)

// You have to connect() to any reducers that you wish to connect to yourself
ActivityForm = connect(
  state => ({
    initialValues: state.activities.activity, // pull initial values from activities reducer
    activities: state.activities,
})
)(ActivityForm)

export default withTranslation('translations')(ActivityForm)
