import _ from 'lodash';
import { Utils } from '../services/';

/** Base class for all models. */
class BaseModel {
    constructor(properties) {
        Object.assign(this, properties || {});
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = _.cloneDeep(this);

        //clean general information on translatable
        Utils.cleanTranslatableProperty(prepared, "name");
        Utils.cleanTranslatableProperty(prepared, "description");

        //clean for posts translatable
        Utils.cleanTranslatableProperty(prepared, "title");
        Utils.cleanTranslatableProperty(prepared, "sub-title");
        Utils.cleanTranslatableProperty(prepared, "content");

        return Utils.trimProperties(prepared);
    }

    /**
     * Prepare this object for duplicate.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForDuplicate(options) {
        const prepared = _.cloneDeep(this);
        return prepared;
    }
}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
BaseModel.getListingFields = () => [];

export default BaseModel;
