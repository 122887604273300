import React, { useEffect } from 'react';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link, useParams } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadTktUser, deleteTktUser } from '../../../redux/modules/tktusers';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const TicketackUserDelete = () => {
    const { t }                                           = useTranslation();
    const dispatch                                        = useDispatch();
    const { userId }                                      = useParams();
    const { tktuser, isLoading, tktuserSuccessfullyDeleted } = useSelector(state => state.tktusers);

    useEffect(() => {
        dispatch(loadTktUser(userId));
    }, [])

    const delTktUser = (id) => {
        dispatch(deleteTktUser(id));
    }

    let title = <span>{t("tktUsers.tktUsers")}</span>;
        if (tktuser)
            title = (
                <span>
                    {t("tktUsers.tktUsers")}
                    <small> |&nbsp;{tktuser.name}</small>
                </span>
            );

    const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/ticketack_users" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

    return (
        <div className="TicketackUserDelete">
            {tktuserSuccessfullyDeleted &&
                <Redirect to="/ticketack_users" />
            }

            <PageTitle icon="id-badge" title={title} actions={actions} />

            {tktuser &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{t('tktUsers.delete_tktUser')}</CardTitle>
                                <CardText tag="div">
                                            <div><b>{t("tktUsers.name")}</b>: {tktuser.name}</div>
                                    <Row>
                                        <Col className="text-center">
                                            <Button tag={Link} to="/ticketack_users" color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => delTktUser(userId)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        {t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default TicketackUserDelete;
