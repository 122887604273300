import React, { Fragment, useMemo } from 'react';
import { Button, ButtonGroup } from "reactstrap";
import { Link } from "react-router-dom"
import './TableButtons.css';
import { useTranslation } from 'react-i18next';
import Ability from '../Ability';

const TableButtons = ({ id, actions, module, baseModule, onDuplicate, customClass, owner }) => {
    const { t } = useTranslation();
    let mod = baseModule ?? module;

    const actionsBtns =
        <Fragment key={module ?? baseModule}>
            {actions.includes("show") &&
                <Ability owner={owner} can={`${mod}:read`}>
                    <Button key="showBtn" className={customClass && customClass} tag={Link} to={`/${module}/show/${id}`} color="primary" title={t("common.see")} size="sm">
                        <i className="fa fa-eye"></i>
                    </Button>
                </Ability>
            }
            {actions.includes("edit") &&
                <Ability owner={owner} can={`${mod}:edit`}>
                    <Button key="editBtn" className={customClass && customClass} tag={Link} to={`/${module}/edit/${id}`} color="info" title={t("common.edit")} size="sm">
                        <i className="fa fa-edit"></i>
                    </Button>
                </Ability>
            }
            {actions.includes("duplicate") &&
                <Ability owner={owner} can={`${mod}:create`}>
                    <Button key="createBtn" className={customClass && customClass} onClick={() => onDuplicate(id)} color="warning" title={t("common.duplicate")} size="sm">
                        <i className="fa fa-copy"></i>
                    </Button>
                </Ability>
            }
            {actions.includes("delete") &&
                <Ability owner={owner} can={`${mod}:delete`}>
                    <Button key="deleteBtn" className={customClass && customClass} tag={Link} to={`/${module}/delete/${id}`} color="danger" title={t("common.delete")} size="sm">
                        <i className="fa fa-trash"></i>
                    </Button>
                </Ability>
            }
        </Fragment>

    const component = actions.length > 1 ?
        [
            <ButtonGroup key={module ?? baseModule} className="TableButtons">
                {actionsBtns}
            </ButtonGroup>
        ] :
        [
            actionsBtns
        ]

        return (
            <div className="TableButtons">
                {component}
            </div>
        );
}


export default TableButtons;
