import RestClient from './RestClient';
import { Stat } from '../../models';
import _ from 'lodash';

class Stats extends RestClient {
    constructor() {
        super({
            "model"      : Stat,
            "entryPoint" : "stat",
            "resource"   : "stat",
            "resources"  : "stats",
            "id_field"   : "_id",
            "sortBy"     : "films"
        });
    }

    get(type, params) {
        // remove empty filter values
        params = _.pickBy(params, _.identity);

        return this.GET(`/stats/${type}`, params)
            .then(response => response.json())
            .then(json => {
                if (json.stats) {
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const StatsApi = new Stats();

export default StatsApi;
