// Redux Posts module
// see https://github.com/erikras/ducks-modular-redux

import { Posts } from '../../services/';

const LOADING_POSTS         = 'kronos/posts/LOADING_POSTS';
const LOADING_POSTS_SUCCESS = 'kronos/posts/LOADING_POSTS_SUCCESS';
const LOADING_POSTS_FAILURE = 'kronos/posts/LOADING_POSTS_FAILURE';
const LOADING_POST          = 'kronos/posts/LOADING_POST';
const LOADING_POST_SUCCESS  = 'kronos/posts/LOADING_POST_SUCCESS';
const LOADING_POST_FAILURE  = 'kronos/posts/LOADING_POST_FAILURE';
const UPDATING_POST         = 'kronos/posts/UPDATING_POST';
const UPDATING_POST_SUCCESS = 'kronos/posts/UPDATING_POST_SUCCESS';
const UPDATING_POST_FAILURE = 'kronos/posts/UPDATING_POST_FAILURE';
const START_CREATING_POST   = 'kronos/posts/START_CREATING_POST';
const CREATING_POST         = 'kronos/posts/CREATING_POST';
const CREATING_POST_SUCCESS = 'kronos/posts/CREATING_POST_SUCCESS';
const CREATING_POST_FAILURE = 'kronos/posts/CREATING_POST_FAILURE';
const DELETING_POST         = 'kronos/posts/DELETING_POST';
const DELETING_POST_SUCCESS = 'kronos/posts/DELETING_POST_SUCCESS';
const DELETING_POST_FAILURE = 'kronos/posts/DELETING_POST_FAILURE';
const SET_CURRENT_CATEGORY         = 'kronos/articles/SET_CURRENT_CATEGORY';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    postSuccessfullyCreated: false,
    posts: [],
    currentCategory: null,
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_POSTS:
            return {
                ...state,
                isLoading: true,
                postSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_POSTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                posts: action.posts
            };
        case LOADING_POSTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_POST:
            return {
                ...state,
                post: null,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                post: action.post
            };
        case LOADING_POST_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                post: null,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_POST:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                post: action.post
            };
        case UPDATING_POST_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_POST:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                postSuccessfullyCreated: false,
                post: {
                    "published" : true,
                }
            };
        case CREATING_POST:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                postSuccessfullyCreated: true,
                post: action.post
            };
        case CREATING_POST_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                postSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_POST:
            return {
                ...state,
                isLoading: true,
                postSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case DELETING_POST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                postSuccessfullyDeleted: true,
                post: action.post
            };
        case DELETING_POST_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                postSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case SET_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategory: action.currentCategory
            };
        default:
            return state;
    }
};

// Actions
function loadingPosts() { return { type: LOADING_POSTS } }
function loadingPostsSuccess(posts) { return { type: LOADING_POSTS_SUCCESS, posts: posts } }
function loadingPostsFailure(err) { return { type: LOADING_POSTS_FAILURE, error: err } }
export function loadPosts(params) {
    return (dispatch) => {
        dispatch(loadingPosts());
        Posts.list(params)
            .then(data => {
                const posts = data.posts;
                dispatch(loadingPostsSuccess(posts));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingPostsFailure(err))
            });
    }
}

function loadingPost() { return { type: LOADING_POST } }
function loadingPostSuccess(post) { return { type: LOADING_POST_SUCCESS, post: post } }
function loadingPostFailure(err) { return { type: LOADING_POST_FAILURE, error: err } }
export function loadPost(postId) {
    return (dispatch) => {
        dispatch(loadingPost());
        Posts.get(postId)
            .then(data => {
                const post = data.post;
                dispatch(loadingPostSuccess(post));
            })
            .catch(err => {
                dispatch(loadingPostFailure(err))
            });
    }
}

function updatingPost() { return { type: UPDATING_POST } }
function updatingPostSuccess(post) { return { type: UPDATING_POST_SUCCESS, post: post } }
function updatingPostFailure(err) { return { type: UPDATING_POST_FAILURE, error: err } }
export function updatePost(post) {
    return (dispatch) => {
        dispatch(updatingPost());
        Posts.update(post)
            .then(data => {
                const post = data.post;
                dispatch(updatingPostSuccess(post));
            })
            .catch(err => {
                dispatch(updatingPostFailure(err))
            });
    }
}

function creatingPost() { return { type: CREATING_POST } }
function creatingPostSuccess(post) { return { type: CREATING_POST_SUCCESS, post: post } }
function creatingPostFailure(err) { return { type: CREATING_POST_FAILURE, error: err } }
export function startCreatePost() { return { type: START_CREATING_POST } }
export function createPost(post) {
    return (dispatch) => {
        dispatch(creatingPost());
        Posts.create(post)
            .then(data => {
                const post = data.post;
                dispatch(creatingPostSuccess(post));
                dispatch(loadingPostSuccess(post));
            })
            .catch(err => {
                dispatch(creatingPostFailure(err))
            });
    }
}

function deletingPost() { return { type: DELETING_POST } }
function deletingPostSuccess() { return { type: DELETING_POST_SUCCESS } }
function deletingPostFailure(err) { return { type: DELETING_POST_FAILURE, error: err } }
export function deletePost(postId) {
    return (dispatch) => {
        dispatch(deletingPost());
        Posts.delete(postId)
            .then(data => {
                dispatch(deletingPostSuccess());
            })
            .catch(err => {
                dispatch(deletingPostFailure(err))
            });
    }
}
export function setCurrentCategory(currentCategory) { return { type: SET_CURRENT_CATEGORY, currentCategory } }
