import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadLegacyScreening } from '../../../redux/modules/legacyScreenings';

class OldScreeningShow extends Component<{}> {
    componentDidMount() {
        const screeningId = this.props.match.params.screeningId;
        // Load screenings on component mount
        this.props.dispatchLoadScreening(screeningId);
    }

    render() {
        const { legacyScreening } = this.props.legacyScreenings;

        const { t } = this.props;

        let title = <span>{t("screenings.screening_plural")}</span>;
        if (legacyScreening)
            title = (
                <span>
                    {t("screenings.screening")}
                    <small> | { legacyScreening.name } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/oldscreenings" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {legacyScreening &&
                    <Button tag={Link} to={ `/oldscreenings/edit/${ legacyScreening.id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="OldScreeningShow">
                <PageTitle icon="th" title={ title } actions={ actions } />
                {legacyScreening &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-screening"></i>&nbsp;{t("screenings.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("screenings.name")}</b>: { legacyScreening.name }</div>
                                            <div><b>{t("screenings.description")}</b>: { legacyScreening.description }</div>
                                            <div><b>{t("screenings.capacity")}</b>: { legacyScreening.capacity }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadLegacyScreening: (screeningId) => loadLegacyScreening(screeningId)
}

const mapStateToProps = state => ({
    legacyScreenings: state.legacyScreenings
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(OldScreeningShow))
