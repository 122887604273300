import React from 'react';
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import TickettypesList from './TickettypesList/TickettypesList';
import TickettypeEdit from './TickettypeEdit/TickettypeEdit';
import TickettypeCreate from './TickettypeCreate/TickettypeCreate';
import TickettypeDelete from './TickettypeDelete/TickettypeDelete';

const Tickettypes = ({ match }) => {
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.tickettypes);

    return (
        <div className="Tickettypes">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }
            <Route exact path={ match.path } component={TickettypesList} />
            <Route exact path={ `${ match.url }/new` } component={TickettypeCreate} />
            <Route exact path={ `${ match.url }/edit/:tickettypeId` } component={TickettypeEdit} />
            <Route exact path={ `${ match.url }/delete/:tickettypeId` } component={TickettypeDelete} />
        </div>
    );
}

export default Tickettypes;
