import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Input } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import matchSorter from 'match-sorter';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';
import 'react-table-6/react-table.css'
import { loadActivities } from '../../../redux/modules/activities';
import Table from '../../../components/Table/Table';

import './ActivitiesList.css';

class ActivitiesList extends Component<{}> {
    componentDidMount() {
        // Load activities on component mount
        this.props.dispatchLoadActivities();
    }

    render() {
        const { t }                                                = this.props;
        const { activities, hasLoadingError, loadingErrorMessage } = this.props.activities;
        const default_lang = Utils.default_lang();

        const textFilter = ({ filter, onChange }) =>
            <Input
                type="text"
                onChange={event => onChange(event.target.value)}
            />;
        const textFilterMethod = (key) => (filter, rows) => matchSorter(rows, filter.value, { keys: [key] });

        const columns = [{
            Header: t("activities.name"),
            accessor: "name",
            filterMethod: textFilterMethod(`name.${default_lang}`),
            Filter: textFilter,
            filterAll: true,
            Cell: row => row.value && (
                <div className="name_cell">
                    <Link to={`/activities/edit/${row.original.id}`}>
                        {row.original.name[default_lang]}
                    </Link>
                </div>
            )
        }, {
            Header: t("activities.is_exclusive"),
            accessor: "is_exclusive",
            filterMethod: textFilterMethod("is_exclusive"),
            Filter: textFilter,
            filterAll: true,
            Cell: row => (
                <div className="is_exclusive_cell">
                    {row.original.is_exclusive ? 'Oui' : 'Non'}
                </div>
            )
        }, {
            Header: t("common.actions"),
            accessor: "id",
            maxWidth: 110,
            filterable: false,
            Cell: row => (
                <TableButtons
                    id={row.value}
                    actions={["edit", "delete", "show"]}
                    module="activities"
                    owner={row.original.created_by}
                />
            )
        }];

        const actions =
            <CreateButton 
                module="activities"
                text="activities.add"
            />

        const title =
        <span>{t("activities.activity_plural")}</span>

        return (
            <div className="ActivitiesList">
                <PageTitle icon="id-badge" title={title} actions={ actions } />
                <Table id="activitiesTable" data={ activities } columns={ columns } />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadActivities: () => loadActivities()
}

const mapStateToProps = state => ({
    activities: state.activities
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ActivitiesList))
