import RestClient from './RestClient';
import { FormLayoutField } from '../../models';

class FormLayoutFields extends RestClient {
    constructor() {
        super({
            "model": FormLayoutField,
            "entryPoint": "field",
            "resource": "field",
            "resources": "fields",
            "id_field": "id",
        });
    }
}

const FormLayoutFieldsApi = new FormLayoutFields();

export default FormLayoutFieldsApi;
