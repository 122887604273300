import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PageTitle, Loader } from '../../../../components';
import { Eft } from '../../../../models';
import { loadEft, updateEft } from '../../../../redux';
import { Row, Col, Badge, Button } from 'reactstrap';
import { Card, CardHeader, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import eftImage from '../../../../assets/images/eft.png';
import Icon from '@mdi/react';
import { useSelector } from 'react-redux';
import {
    mdiSlotMachine, mdiArrowLeftCircle, mdiWall,
    mdiAndroidMessages, mdiBankTransfer
} from '@mdi/js';
import EftForm from './EftForm/EftForm';

import './EftManagement.css';

const EftManagement = () => {
    const { t }     = useTranslation();
    const dispatch  = useDispatch();
    const { eftId } = useParams();

    const { connected } = useSelector(state => state.sioBridge);
    const { eft, isLoadingOne, loadingOneErrorMessage } = useSelector(state => state.efts);

    useEffect(() => {
        if (connected) {
            dispatch(loadEft(Eft.getOriginalId(eftId)));
        }
    }, [dispatch, connected, eftId]);

    const saveOptions = eft => {
        if (('only_for_pos' in eft.options) && !eft.options.only_for_pos.length)
            delete eft.options.only_for_pos;
        dispatch(updateEft(eft));
    }

    const actions = <Link to="/devices/efts">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    return (
        <div className="EftManagement">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('efts.manage_title', { name: eft?.name }) }
                        icon="credit-card"
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    { loadingOneErrorMessage && (
                        <div className="alert alert-danger">
                            { loadingOneErrorMessage }
                        </div>
                    )}
                    {(isLoadingOne) && (
                        <Loader />
                    )}

                    {!isLoadingOne && eft && (
                    <div>
                        <Row>
                            <Col>
                                <Card className="card-outline">
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            <i className="fa fa-info-circle" />{' '}
                                            { t('efts.infos') }
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="info">
                                            <label>{ t('efts.id') }</label>
                                            <span>{ eft?.id }</span>
                                        </div>
                                        <div className="info">
                                            <label>{ t('efts.name') }</label>
                                            <span>{ eft?.name }</span>
                                        </div>
                                        <div className="info">
                                            <label>{ t('efts.host') }</label>
                                            <span>{ eft?.host } <small>({ eft?.host_id})</small></span>
                                        </div>
                                        <div className="info">
                                            <label>{ t('efts.last_connexion') }</label>
                                            <span>{ eft?.getLastConnectedAt() }</span>
                                        </div>
                                        <div className="info">
                                            <label>{ t('efts.status') }</label>
                                            <span>
                                                <Badge color={ eft.isConnected() ? 'success' : 'danger' }>
                                                    { t(`efts.${eft.isConnected() ? 'connected' : 'disconnected'}`) }<br/>
                                                </Badge>
                                            </span>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="card-outline">
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            <i className="fa fa-gear" />{' '}
                                            { t('efts.options') }
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <EftForm initialValues={ eft } onSubmit={ saveOptions } isLoading={ isLoadingOne }/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default EftManagement;
