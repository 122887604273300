import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Col, Input } from 'reactstrap';
import { Utils } from '../../../services';
import Widget from '../Widget';
import WidgetSharedSettings from '../WidgetSharedSettings/WidgetSharedSettings';
import './WidgetSeparator.css';


const WidgetSeparator = ({ props, saveSettings, index, onCancel, border, insert, name }) => {
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    //Use this function to format props to send to parent
    //Index of edited widget is passed here then back to parent so we need it
    const handleSubmit = (e) => {
        //Prevent refresh on form submit
        e.preventDefault();

        let settings = { width: e.target.widgetWidth.value, }

        //IN ARRAY!
        saveSettings([settings, index, insert, name]);
    }

    return (
        <div className='WidgetSeparator'>
            {
                (!saveSettings) ?
                    <Widget
                        content={<></>}
                        border={border && border}
                    />
                    : // Do your widget settings form here
                    // TODO : Bind settings from state hook to HTML form tags
                    <Form onSubmit={handleSubmit}>

                        <Row className='ml-0 mr-0 pb-2 border-bottom'>
                                <WidgetSharedSettings 
                                    width={props?.width ? props.width : 1} 
                                    bgColor={props?.bgColor ? props.bgColor : "rgb(0,0,0,0)"} 
                                />
                        </Row>
                        <Row>
                            <Col>
                                <div className="submitButtons">
                                    <Button onClick={() => onCancel()} className="mr-2" color="danger"><i className="fa fa-times"></i>&nbsp;{t("widgets.cancel")}</Button>&nbsp;
                                    <Button type="submit" color="success"><i className="fa fa-floppy-o"></i>&nbsp;{t("widgets.save")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
            }
        </div>
    )
}

export default WidgetSeparator;
