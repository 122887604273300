import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { FieldArray, Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { TabContent, TabPane } from 'reactstrap';
import { Form, FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import {
    WysiwygField, DeliveryTypesField, FileUpload,
    TranslatableField, SalepointsField, CategoriesField,
    SortWeightField, TagsField, ResponsiveNav
} from '../../../components';
import { Article } from '../../../models';
import { Utils } from '../../../services';
import ArticlePosters from './ArticlePosters/ArticlePosters';
import ArticleStocks from './ArticleStocks/ArticleStocks';
import ArticleExtras from './ArticleExtras/ArticleExtras';
import ArticleFeatures from './ArticleFeatures/ArticleFeatures';
import ArticleVariants from './ArticleVariants/ArticleVariants';
import { loadArticlecategories } from '../../../redux/modules/articlecategories';
import { loadTags } from '../../../redux/modules/tags';
import { loadSetting } from '../../../redux/modules/settings';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import { loadTickettypes } from '../../../redux/modules/tickettypes';
import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

import './ArticleForm.css'

moment.locale('fr');
momentLocalizer();

let ArticleForm = ({ handleSubmit, pristine, submitting, isCreation }) => {
    const dispatch     = useDispatch();
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    const [activeTab, setActiveTab] = useState(0);
    const [stockType, setStockType] = useState('none');

    const { isLoading, article, articles } = useSelector(state => state.articles);
    const { tags }                         = useSelector(state => state.tags);
    const { salepoints }                   = useSelector(state => state.salepoints);
    const { articlecategories }            = useSelector(state => state.articlecategories);
    const { delivery }                     = useSelector(state => state.settings?.setting);

    const variants = article ? article.variants : [];

    useEffect(() => {
        article && setStockType(article.stock_type);
    }, [article]);

    useEffect(() => {
        dispatch(loadTags());
        dispatch(loadSalepoints());
        dispatch(loadArticlecategories());
        dispatch(loadTickettypes());
        dispatch(loadSetting('default'));
    }, [dispatch]);

    const onChangeTab = (tab) => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    const onChangeStockType = (e) => {
        setStockType(e.target.value);
    }

    const addTag = (tag) => {
        article.tags.push(tag);
        return article.tags;
    }

    if (!article)
        return null;

    const values = [
        {
            text: t("articles.informations"),
            icon: <i className="fa fa-info-circle"></i>,
            isCreation: true
        },
        {
            text: t("articles.variants"),
            icon: <i className="fa fa-barcode"></i>,
            isCreation: true
        },
        {
            text: (t("articles.features") + " - " + (article.features?.length || 0)),
            icon: <i className="fa fa-hashtag"></i>,
            isCreation: false
        },
        {
            text: (t("articles.extras") + " - " + (article.extras?.length || 0)),
            icon: <i className="fa fa-cart-plus"></i>,
            isCreation: false
        },
        {
            text: (t("articles.posters") + " - " + (article.posters && article.posters.length || 0)),
            icon: <i className="fa fa-camera"></i>,
            isCreation: false
        },
        {
            text: t("articles.stocks.name"),
            icon: <i className="fa fa-battery-three-quarters"></i>,
            isCreation: true
        },
        {
            text: (t("articles.delivery") + " - " + (article?.delivery?.types && article.delivery.types.length || 0)),
            icon: <i className="fa fa-truck"></i>,
            isCreation: false
        },
    ]

    return (
        <div className="ArticleForm">
            <Form onSubmit={handleSubmit}>
                <ResponsiveNav
                    data={isCreation ? values.filter(v => v.isCreation) : values}
                    onChangeTab={onChangeTab}
                />
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={0}>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("articles.informations")}</CardTitle>
                                        <CardText tag="div">
                                            {article && article.type !== Article.TYPE_CODE_DISCOUNT && (
                                                <FormGroup row>
                                                    <Label for="name" sm={3}>{t("articles.name")}</Label>
                                                    <Col sm={9}>
                                                        <TranslatableField name="name" placeholder={t("articles.name_placeholder")} component="input" />
                                                    </Col>
                                                </FormGroup>
                                            )}
                                            <FormGroup row>
                                                <Label for="short_description" sm={3}>{t("articles.short_description")}</Label>
                                                <Col sm={9}>
                                                    <TranslatableField optional name="short_description" placeholder={t("articles.short_description_placeholder")} component="input" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="description" sm={3}>{t("articles.description")}</Label>
                                                <Col sm={9}>
                                                    <TranslatableField name="description" placeholder={t("articles.description_placeholder")} component={WysiwygField} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="name" sm={3}>{t("articles.category")}</Label>
                                                <Col sm={9}>
                                                    <CategoriesField name="category._id" categories={articlecategories} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="name" sm={3}>{t("articles.sort_weight")}</Label>
                                                <Col sm={9}>
                                                    <SortWeightField />
                                                </Col>
                                            </FormGroup>
                                            {!isCreation && (
                                                <FormGroup row>
                                                    <Label for="name" sm={3}>{t("articles.tags")}</Label>
                                                    <Col sm={9}>
                                                        <TagsField name="tags" tags={tags} addTag={addTag} />
                                                    </Col>
                                                </FormGroup>
                                            )}
                                            <FormGroup row>
                                                <Label for="name" sm={3}>{t("articles.pos")}</Label>
                                                <Col sm={9}>
                                                    <SalepointsField name="pos" salepoints={salepoints} />
                                                </Col>
                                            </FormGroup>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-barcode"></i>&nbsp;{t("articles.variants")}</CardTitle>
                                        <CardText tag="div">
                                            <FieldArray
                                                name="variants"
                                                component={ArticleVariants}
                                                stock_type={article ? article.stock_type : 'none'}
                                                variants={article ? article.variants : []}
                                                isCreation={isCreation}
                                            />
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
                {!isCreation &&
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={2}>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-hashtag"></i>&nbsp;{t("articles.features")}</CardTitle>
                                            <CardText tag="div">
                                                <FieldArray
                                                    name="attributes"
                                                    component={ArticleFeatures}
                                                    attributes={article ? article.attributes : []}
                                                    submit={handleSubmit}
                                                />
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                }
                {!isCreation &&
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={3}>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-cart-plus"></i>&nbsp;{t("articles.extras")}</CardTitle>
                                            <CardText tag="div">
                                                <FieldArray
                                                    name="attributes"
                                                    component={ArticleExtras}
                                                    attributes={article ? article.attributes : []}
                                                    submit={handleSubmit}
                                                />
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                }
                {!isCreation &&
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={4}>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-camera"></i>&nbsp;{t("articles.posters")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="new_poster" sm={3}>{`${t("articles.add_poster")}`}</Label>
                                                    <Col sm={9}>
                                                        <Field component={FileUpload} type="file" className="form-control" name="new_poster" id="new_poster" />
                                                        <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoading}>
                                                            {isLoading ?
                                                                <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                                                :
                                                                <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                                                            }
                                                        </Button>
                                                    </Col>
                                                </FormGroup>
                                                {article &&
                                                    article.posters &&
                                                    article.posters.length > 0 &&
                                                    <FieldArray name="posters" component={ArticlePosters} posters={article.posters} />
                                                }
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                }
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={5}>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-battery-three-quarters"></i>&nbsp;{t("articles.stocks.name")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Label for="stock_type" sm={3}>{t("articles.stocks.stock_type")}</Label>
                                                <Col sm={9}>
                                                    <Field component="select" className="form-control" name="stock_type" onChange={onChangeStockType}>
                                                        <option key="none" value="none">{t('articles.stocks.type_none')}</option>
                                                        <option key="article" value="article">{t('articles.stocks.type_article')}</option>
                                                        <option key="variant" value="variant">{t('articles.stocks.type_variant')}</option>
                                                    </Field>
                                                </Col>
                                            </FormGroup>
                                            <Row>
                                                {stockType == 'article' && (
                                                    <Col>
                                                        <h4>{article.name[default_lang]}</h4>
                                                        <FieldArray
                                                            name="stocks"
                                                            component={ArticleStocks}
                                                            stock_type={stockType}
                                                            stocks={article ? article.stocks : []}
                                                            salepoints={salepoints ? salepoints : []}
                                                        />
                                                    </Col>
                                                )
                                                }
                                                {stockType == 'variant' && variants.map((v, i) => (
                                                    <Col key={i} sm={6}>
                                                        <h4>{`${t('articles.variant')} ${i + 1} - ${v.name[default_lang]}`}</h4>
                                                        <FieldArray
                                                            name={`variants[${i}].stocks`}
                                                            component={ArticleStocks}
                                                            stock_type={stockType}
                                                            variant_index={i}
                                                            stocks={v.stocks}
                                                            salepoints={salepoints ? salepoints : []}
                                                        />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={6}>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-truck"></i>&nbsp;{t("articles.delivery_types")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Label for="name" sm={3}>{t("articles.delivery_types")}</Label>
                                                <Col sm={9}>
                                                    <DeliveryTypesField name="delivery.types" types={delivery?.types || []} />
                                                </Col>
                                            </FormGroup>
                                        </CardText>

                                        {article?.delivery?.types && article.delivery.types.length > 0 && (
                                            <>
                                                <CardTitle><i className="fa fa-cube"></i>&nbsp;{t("articles.packaging")}</CardTitle>
                                                <CardText tag="div">
                                                    <FormGroup row>
                                                        <Label for="name" sm={3}>{t("articles.delivery_width")}</Label>
                                                        <Col sm={9}>
                                                            <InputGroup>
                                                                <Field
                                                                    component="input"
                                                                    type="number"
                                                                    step={1}
                                                                    name="delivery.width"
                                                                    placeholder={t('articles.delivery_width_placeholder')}
                                                                    className="form-control"
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>{t('common.centimeters')}</InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="name" sm={3}>{t("articles.delivery_length")}</Label>
                                                        <Col sm={9}>
                                                            <InputGroup>
                                                                <Field
                                                                    component="input"
                                                                    type="number"
                                                                    step={1}
                                                                    name="delivery.length"
                                                                    placeholder={t('articles.delivery_length_placeholder')}
                                                                    className="form-control"
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>{t('common.centimeters')}</InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="name" sm={3}>{t("articles.delivery_height")}</Label>
                                                        <Col sm={9}>
                                                            <InputGroup>
                                                                <Field
                                                                    component="input"
                                                                    type="number"
                                                                    step={1}
                                                                    name="delivery.height"
                                                                    placeholder={t('articles.delivery_height_placeholder')}
                                                                    className="form-control"
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>{t('common.centimeters')}</InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label for="name" sm={3}>{t("articles.delivery_weight")}</Label>
                                                        <Col sm={9}>
                                                            <InputGroup>
                                                                <Field
                                                                    component="input"
                                                                    type="number"
                                                                    step={0.01}
                                                                    name="delivery.weight"
                                                                    placeholder={t('articles.delivery_weight_placeholder')}
                                                                    className="form-control"
                                                                />
                                                                <InputGroupAddon addonType="append">
                                                                    <InputGroupText>{t('common.grammes')}</InputGroupText>
                                                                </InputGroupAddon>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </CardText>
                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
                <br />
                <Row>
                    <Col className="text-center">
                        <br />
                        <Button type="submit" color="success" disabled={submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

const mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ArticleForm = reduxForm({
    form: 'articleForm', // a unique identifier for this form
    enableReinitialize: true
})(ArticleForm)

// You have to connect() to any reducers that you wish to connect to yourself
ArticleForm = connect(
    state => ({
        initialValues: state.articles.article && {
            ...state.articles.article,
            pos: state.articles.article.pos.map((p) => p._id),
            //tags: state.articles.article.tags.map((t) => t._id),
        }, // pull initial values from articles reducer
        articles: state.articles,
        articlecategories: state.articlecategories,
        salepoints: state.salepoints,
        destroyOnUnmount: false
    }), mapDispatchToProps
)(ArticleForm)

export default ArticleForm;
