import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form'
import { Dropdown } from 'semantic-ui-react';

import './TextArrayField.css';

const TextArrayField = ({ name }) => {
    const { t } = useTranslation();

    const Component = (props) => {
        const options = (props.input.value || []).filter(v => !!v).map(text => {
            return {
                key: text,
                text: text,
                value: text,
            };
        });
        const { input } = props;

        const onChange = (e, data) => {
            input.onChange(data.value);
        }

        return <Dropdown
            name={name}
            options={options}
            value={input.value || []}
            onChange={onChange }
            allowAdditions={true}
            additionLabel={t('common.add') + ' ' }
            noResultsMessage=""
            search
            clearable
            fluid
            multiple
            selection
        />;
    }

    return (
        <div className="TextArrayField">
            <Field multiple name={ name } component={ Component } className="form-control" />
        </div>
    );
}

export default TextArrayField;
