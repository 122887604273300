// Redux Tags module
// see https://github.com/erikras/ducks-modular-redux

import { Tags } from '../../services/';


const LOADING_TAGS         = 'kronos/tags/LOADING_TAGS';
const LOADING_TAGS_SUCCESS = 'kronos/tags/LOADING_TAGS_SUCCESS';
const LOADING_TAGS_FAILURE = 'kronos/tags/LOADING_TAGS_FAILURE';
const LOADING_TAG          = 'kronos/tags/LOADING_TAG';
const LOADING_TAG_SUCCESS  = 'kronos/tags/LOADING_TAG_SUCCESS';
const LOADING_TAG_FAILURE  = 'kronos/tags/LOADING_TAG_FAILURE';
const UPDATING_TAG         = 'kronos/tags/UPDATING_TAG';
const UPDATING_TAG_SUCCESS = 'kronos/tags/UPDATING_TAG_SUCCESS';
const UPDATING_TAG_FAILURE = 'kronos/tags/UPDATING_TAG_FAILURE';
const START_CREATING_TAG   = 'kronos/tags/START_CREATING_TAG';
const CREATING_TAG         = 'kronos/tags/CREATING_TAG';
const CREATING_TAG_SUCCESS = 'kronos/tags/CREATING_TAG_SUCCESS';
const CREATING_TAG_FAILURE = 'kronos/tags/CREATING_TAG_FAILURE';
const DELETING_TAG         = 'kronos/tags/DELETING_TAG';
const DELETING_TAG_SUCCESS = 'kronos/tags/DELETING_TAG_SUCCESS';
const DELETING_TAG_FAILURE = 'kronos/tags/DELETING_TAG_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    tagSuccessfullyCreated: false,
    tags: []
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_TAGS:
            return {
                ...state,
                isLoading: true,
                tagSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_TAGS_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                tags: action.tags
            };
        case LOADING_TAGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_TAG:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TAG_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                tag: action.tag
            };
        case LOADING_TAG_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_TAG:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_TAG_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                tag: action.tag
            };
        case UPDATING_TAG_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_TAG:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tagSuccessfullyCreated: false,
                tag: null
            };
        case CREATING_TAG:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_TAG_SUCCESS:
            const tags = state.tags || [];
            tags.push(action.tag);
            return {
                isLoading: false,
                hasLoadingError: false,
                tagSuccessfullyCreated: true,
                tag: action.tag,
                tags: tags
            };
        case CREATING_TAG_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                tagSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_TAG:
            return {
                ...state,
                isLoading: true,
                tagSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case DELETING_TAG_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                tagSuccessfullyDeleted: true,
                tag: action.tag
            };
        case DELETING_TAG_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                tagSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingTags() { return { type: LOADING_TAGS } }
function loadingTagsSuccess(tags) { return { type: LOADING_TAGS_SUCCESS, tags: tags } }
function loadingTagsFailure(err) { return { type: LOADING_TAGS_FAILURE, error: err } }
export function loadTags(tagname, password) {
    return (dispatch) => {
        dispatch(loadingTags());
        Tags.list()
            .then(data => {
                const tags = data.tags;
                dispatch(loadingTagsSuccess(tags));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingTagsFailure(err))
            });
    }
}

function loadingTag() { return { type: LOADING_TAG } }
function loadingTagSuccess(tag) { return { type: LOADING_TAG_SUCCESS, tag: tag } }
function loadingTagFailure(err) { return { type: LOADING_TAG_FAILURE, error: err } }
export function loadTag(tagId) {
    return (dispatch) => {
        dispatch(loadingTag());
        Tags.get(tagId)
            .then(data => {
                const tag = data.tag;
                dispatch(loadingTagSuccess(tag));
            })
            .catch(err => {
                dispatch(loadingTagFailure(err))
            });
    }
}

function updatingTag() { return { type: UPDATING_TAG } }
function updatingTagSuccess(tag) { return { type: UPDATING_TAG_SUCCESS, tag: tag } }
function updatingTagFailure(err) { return { type: UPDATING_TAG_FAILURE, error: err } }
export function updateTag(tag) {
    return (dispatch) => {
        dispatch(updatingTag());
        Tags.update(tag)
            .then(data => {
                const tag = data.tag;
                dispatch(updatingTagSuccess(tag));
            })
            .catch(err => {
                dispatch(updatingTagFailure(err))
            });
    }
}

function creatingTag() { return { type: CREATING_TAG } }
function creatingTagSuccess(tag) { return { type: CREATING_TAG_SUCCESS, tag: tag } }
function creatingTagFailure(err) { return { type: CREATING_TAG_FAILURE, error: err } }
export function startCreateTag() { return { type: START_CREATING_TAG } }
export function createTag(tag) {
    return (dispatch) => {
        dispatch(creatingTag());
        Tags.create(tag)
            .then(data => {
                // reload tags since we need the new created one just after creation
                dispatch(loadTags());
                const tag = data.tag;
                dispatch(creatingTagSuccess(tag));
                dispatch(loadingTagSuccess(tag));
            })
            .catch(err => {
                dispatch(creatingTagFailure(err))
            });
    }
}

function deletingTag() { return { type: DELETING_TAG } }
function deletingTagSuccess() { return { type: DELETING_TAG_SUCCESS } }
function deletingTagFailure(err) { return { type: DELETING_TAG_FAILURE, error: err } }
export function deleteTag(tagId) {
    return (dispatch) => {
        dispatch(deletingTag());
        Tags.delete(tagId)
            .then(data => {
                dispatch(deletingTagSuccess());
            })
            .catch(err => {
                dispatch(deletingTagFailure(err))
            });
    }
}
