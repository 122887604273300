import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './CashregistersField.css';

const CashregistersField = ({ name, cashregisters, onChange, fixedWidth, single }) => {
    const { t } = useTranslation();

    const options = cashregisters.map(cashregister => {
        return {
            key: cashregister._id,
            text: Utils.localized_or_fallback(cashregister.name, i18n.language),
            value: cashregister._id
        };
    });

    const onFieldChange = (value) => {
        return onChange ? onChange(value) : false;
    }

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            placeholder={ t('cashregisters.choose_a_cashregister') }
            onSelectChange={ onFieldChange }
            fixedWidth={ fixedWidth }
            clearable
        />
    ) : (
        <MultiSelectField
            name={ name }
            options={ options }
            placeholder={ t('cashregisters.choose_a_cashregister') }
            onChange={ onFieldChange }
            fixedWidth={ fixedWidth }
        />
    );
}

export default CashregistersField;
