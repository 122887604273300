import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import CashregisterForm from '../CashregisterForm/CashregisterForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadCashregister, updateCashregister } from '../../../redux/modules/cashregisters';

class CashregisterEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const cashregisterId = this.props.match.params.cashregisterId;
        // Load cashregisters on component mount
        this.props.dispatchLoadCashregister(cashregisterId);
    }

    submit(values) {
        this.props.dispatchUpdateCashregister(values);
    }

    render() {
        const { cashregister } = this.props.cashregisters;
        const { t } = this.props;

        const default_lang = Utils.default_lang();

        let title = <span>{t('cashregisters.cashregister')}</span>;
        if (cashregister)
            title = (
                <span>
                    {t('cashregisters.cashregister')}
                    <small> |&nbsp;{ t('cashregisters.update_cashregister', { name: cashregister.name[default_lang] })} </small>
                </span>
            );

        const actions =
        <div>
            {cashregister &&
            <ButtonGroup>
                <Button tag={Link} to={ `/cashregisters/show/${ cashregister._id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="CashregisterEdit">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {cashregister &&
                    <CashregisterForm cashregisterId={cashregister._id} onSubmit={this.submit} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadCashregister: (cashregisterId) => loadCashregister(cashregisterId),
    dispatchUpdateCashregister: (cashregister) => updateCashregister(cashregister)
}

const mapStateToProps = state => ({
    cashregisters: state.cashregisters,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(CashregisterEdit))
