import React from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { Button, Row, Col, Form, FormGroup, Label } from 'reactstrap';

import './FormRenderer.css';

let FormRenderer = props => {
    const { handleSubmit, pristine, submitting }                      = props;
    const { formLayout, isLoading, className, preFields, postFields } = props;

    const { t, i18n } = useTranslation();
    const { result }  = useSelector(state => state.formLayouts);

    const context = React.useMemo(() => ({
        lang: i18n.language
    }), [i18n.language]);

    return (
        <div className={`FormRenderer ${className || ''}`}>
            <h3 className="mb-3">
                { formLayout.name[i18n.language] }
            </h3>
            <Form onSubmit={handleSubmit}>
                <Row>
                    { preFields }
                    {formLayout.fields.sort((a, b) => a.position - b.position).map(field => (
                        <Col key={field.id} sm={12} md={Math.round(field.width * 12)} >
                            <FormGroup row>
                                <Col sm={12} className="text-left font-weight-bold">
                                    { field.getLabel(context) }
                                </Col>
                                <Col sm={12}>
                                    { field.getInput(context) }
                                </Col>
                            </FormGroup>
                        </Col>
                    ))}
                    { postFields }
                </Row>
                <FormGroup row className="mt-3">
                    <Col>
                        <Button type="submit" color="info">
                            <i className={`fa ${isLoading ? 'fa-spinner fa-spin' : 'fa-save'}`}></i>&nbsp;
                            { t('common.save') }
                        </Button>
                    </Col>
                </FormGroup>
            </Form>
        </div>
    );
}

FormRenderer = reduxForm({
    form: 'formRenderer',
    enableReinitialize: true
})(FormRenderer);

FormRenderer = connect(
    state => ({
        initialValues: {},
    }), {}
)(FormRenderer)

export default FormRenderer;
