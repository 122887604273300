import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap';

import  './SearchForm.css';

class SearchForm extends Component<{}> {
    render() {
        return (
            <div className="SearchForm">
                <Form>
                    <FormGroup>
                        <InputGroup>
                            <Input type="text" name="search" placeholder="Rechercher ..." />
                            <InputGroupAddon addonType="append">
                                <Button color="info">Rechercher</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    events: state.events
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
