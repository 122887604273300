import RestClient from './RestClient';
import { FormLayout } from '../../models';

class FormLayouts extends RestClient {
    constructor() {
        super({
            "model": FormLayout,
            "entryPoint": "form",
            "resource": "form",
            "resources": "forms",
            "id_field": "id",
        });
    }

    submit(form_id, values, tkt_instance_id) {
        return this.POST(`/application/create/tkt_instance/${tkt_instance_id}`, values)
            .then(response => response.json())
            .then(json => {
                if (json.result) {
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const FormLayoutsApi = new FormLayouts();

export default FormLayoutsApi;
