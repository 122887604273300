import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Button, ButtonGroup, Card, CardBody, CardTitle, CardSubtitle, CardText, CardFooter } from 'reactstrap';
import { Field, submit } from 'redux-form'
import { withTranslation } from 'react-i18next';
import FileUpload from '../../../../components/FileUpload/FileUpload';
import { Utils } from '../../../../services';
import _ from 'lodash';

import './ProgrammationTasks.css';

class ProgrammationTasks extends Component<{}> {
    constructor(props) {
        super(props);

        this.onChangeActivity = this.onChangeActivity.bind(this);
        this.onChangeStatus   = this.onChangeStatus.bind(this);
        this.onChangeUser     = this.onChangeUser.bind(this);
        this.add              = this.add.bind(this);
        this.state = {
            current_task_activity_id: null,
            current_task_status: null,
            current_task_user_id: null
        };
    }

    onChangeStatus(e) {
        this.setState({
            current_task_status: e.target.value
        });
    }

    onChangeActivity(e) {
        this.setState({
            current_task_activity_id: e.target.value
        });
    }

    onChangeUser(e) {
        this.setState({
            current_task_user_id: e.target.value
        });
    }

    add(e) {
        const { meta: { form }, dispatchSubmitForm } = this.props;
        dispatchSubmitForm(form);
        this.setState({
            current_task_activity_id: null,
            current_task_status: null,
            current_task_user_id: null
        });
    }

    remove(i) {
        const { fields, tasks, meta: { form }, dispatchSubmitForm } = this.props;

        fields.remove(i);
        tasks.splice(i, 1);
        dispatchSubmitForm(form);
    }

    render() {
        const { t, fields, tasks, activities, users, onDelete } = this.props;
        const default_lang = Utils.default_lang();

        const isLoading = false;
        const filtered_users = this.state.current_task_activity_id ?
            _.filter(users, (u) => _.filter(u.activities, (a) => a.id == this.state.current_task_activity_id).length) :
            users;
        return (
            <div className="ProgrammationTasks">
                <Row>
                    <Col>
                        <CardBody>
                            <Row>
                                <Col sm={3}>
                                    <Field onChange={this.onChangeActivity} component="select" className="form-control" type="text" name="new_task['activity_id']" id="new_task_activity_id">
                                        <option key="0" value="">---</option>
                                        {
                                            activities.map((a) => <option key={a.id} value={a.id}>{a.name[default_lang]}</option>)
                                        }
                                    </Field>
                                </Col>
                                {this.state.current_task_activity_id &&
                                <Fragment>
                                    <Col sm={3}>
                                        <Field onChange={this.onChangeStatus} component="select" className="form-control" type="text" name="new_task['status']" id="new_task_status">
                                            <option value="">---</option>
                                            <option value={Utils.TASK_STATUS_NEW}>{t('tasks.status_new')}</option>
                                            <option value={Utils.TASK_STATUS_FREE}>{t('tasks.status_free')}</option>
                                            <option value={Utils.TASK_STATUS_ASSIGNED}>{t('tasks.status_assigned')}</option>
                                        </Field>
                                    </Col>
                                    {this.state.current_task_status === Utils.TASK_STATUS_ASSIGNED &&
                                    <Col sm={3}>
                                        <Field  onChange={this.onChangeUser} component="select" className="form-control" type="text" name="new_task['user_id']" id="new_task_user_id">
                                            <option key="" value="">---</option>
                                            {
                                                filtered_users.map((u) => <option key={u.id} value={u.id}>{u.firstname} {u.lastname}</option>)
                                            }
                                        </Field>
                                    </Col>
                                    }
                                    {this.state.current_task_status && (
                                        this.state.current_task_status != Utils.TASK_STATUS_ASSIGNED || this.state.current_task_user_id) &&
                                    <Col sm={3}>
                                        <Button type="button" onClick={this.add} color="info">
                                            {isLoading ?
                                                <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                                :
                                                <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                                            }
                                        </Button>
                                    </Col>
                                    }
                                </Fragment>
                                }
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
                <Row>
                    {tasks.map((task, i) =>
                        <Col key={i} className="task-wrapper" xs="12" sm="12" md="4">
                            <Card className="task">
                                <CardTitle>
                                    <div className="text-center">
                                        <h5>{ task.activity.name[default_lang] }</h5>
                                    </div>
                                </CardTitle>
                                <CardBody>
                                    <div className="text-center">
                                        {task.user &&
                                            <h4>{ task.user.firstname } { task.user.lastname }</h4>
                                        }
                                    </div>
                                </CardBody>
                                <CardFooter className="text-center">
                                    <ButtonGroup>
                                        <Button color="danger" size="sm" onClick={ () => { this.remove(i);  }}>
                                            <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                        </Button>
                                    </ButtonGroup>
                                    <Field component="input" type="hidden" className="form-control" name={`task[${i}].activity`} />
                                    <Field component="input" type="hidden" className="form-control" name={`task[${i}].firstname`} />
                                    <Field component="input" type="hidden" className="form-control" name={`task[${i}].lastname`} />
                                </CardFooter>
                            </Card>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchSubmitForm: (form) => submit(form)
}
const mapStateToProps = state => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ProgrammationTasks))
