import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import { Field } from 'redux-form';
import TranslatableField from '../../../../TranslatableField/TranslatableField';
import WysiwygField from '../../../../WysiwygField/WysiwygField';

const RichTextSettings = ({ env, screen, widget }) => {
    const { t } = useTranslation();

    return (
        <FormGroup row className="widget-setting">
            <Col sm={ 12 }>
                <TranslatableField
                    component={ WysiwygField }
                    name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].text` }
                />
            </Col>
        </FormGroup>
    );
}

export default RichTextSettings;
