import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css'
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
import { updateTableState } from '../../redux/modules/tables';
import ExcelExportButton from '../ExcelExportButton/ExcelExportButton';

const TableStats = ({ id, data, columns, customTitle, getExcelData, fullSize, print, overridePageSize,  ...rest }) => {
    const { t }              = useTranslation();
    const dispatch           = useDispatch();
    const { page, pageSize } = useSelector(state => state.tables[id]);

    const onPageChanged = page => {
        dispatch(updateTableState(id, { page }));
    };

    const onPageSizeChanged = pageSize => {
        dispatch(updateTableState(id, { pageSize }));
    };

    return (
        <div className='TableStats mt-2'>
            <Row>
                <Col>
                    {customTitle}
                </Col>
                <Col className="text-right noPrint">
                    <ButtonGroup>
                        {print &&
                            <Button color='success' onClick={() => window.print()}><i className='fa fa-print'></i>&nbsp;{t("statstypes.print")}</Button>
                        }
                        <ExcelExportButton csvData={getExcelData ? getExcelData() : data} fileName="data"/>
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col className='table-wrapper'>
                    {data?.length > 0 &&
                        <ReactTable
                            data={data}
                            columns={columns}
                            filterable
                            minRows={1}
                            defaultPage={page}
                            defaultPageSize={overridePageSize ?? pageSize}
                            className="-striped -highlight"
                            previousText={t("app.table_prev_button")}
                            nextText={t("app.table_next_button")}
                            pageText={t("app.table_page_label")}
                            ofText={t("app.table_of_label")}
                            rowsText={t("app.table_rows_label")}
                            noDataText={t("app.table_no_data_message")}
                            onPageChange={onPageChanged}
                            onPageSizeChange={onPageSizeChanged}
                            {...rest}
                        />
                    }
                </Col>
            </Row>
        </div>
    );
}

export default TableStats;
