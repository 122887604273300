import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadResource } from '../../../redux/modules/resources';

class ResourceShow extends Component<{}> {
    componentDidMount() {
        const resourceId = this.props.match.params.resourceId;
        // Load resources on component mount
        this.props.dispatchLoadResource(resourceId);
    }

    render() {
        const { t }   = this.props;
        const { resource } = this.props.resources;

        let title = <span>{t("resources.resource_plural")}</span>;
        if (resource)
            title = (
                <span>
                    {t("resources.resource")}
                    <small> | { resource.number } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/resources" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {resource &&
                    <Button tag={Link} to={ `/resources/edit/${ resource.id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="ResourceShow">
                <PageTitle icon="key" title={ title } actions={ actions } />
                {resource &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("resources.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><strong>{t("resources.number")}</strong>: {resource.number}</div>
                                            <div><strong>{t("resources.type")}</strong>: {resource.type}</div>
                                            {resource.user &&
                                                <div><strong>{t("resources.user")}</strong>: {`${resource.user.firstname} ${resource.user.lastname}`}</div>
                                            }
                                            <div><strong>{t("resources.comment")}</strong>: {resource.comment}</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadResource: (resourceId) => loadResource(resourceId)
}

const mapStateToProps = state => ({
    resources: state.resources
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ResourceShow))
