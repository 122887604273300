import React from 'react';
import { useSelector } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import ChannelCreate from './ChannelCreate/ChannelCreate';
import ChannelsList from './ChannelsList/ChannelsList';
import ChannelEdit from './ChannelEdit/ChannelEdit';
import ChannelDelete from './ChannelDelete/ChannelDelete';

const Channels = ({ match }) => {

    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.post_categories);

    return (
        <div className="Channels">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }
            <Route exact path={ match.path } component={ChannelsList} />
            <Route exact path={ `${ match.url }/new` } component={ChannelCreate} />
            <Route exact path={ `${ match.url }/edit/:channelId` } component={ChannelEdit} />
            <Route exact path={ `${ match.url }/delete/:channelId` } component={ChannelDelete} />

        </div>
    );
}

export default Channels;
