import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import TaskForm from '../TaskForm/TaskForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { startCreateTask, createTask } from '../../../redux/modules/tasks';

const TaskCreate = ({ }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { task, taskSuccessfullyCreated } = useSelector(state => state.tasks);

    useEffect(() => {
        dispatch(startCreateTask())
    }, [])

    const submit = (values) => {
        dispatch(createTask(values));
    }

    let title = <span>{t('tasks.add')}</span>;

    const actions =
        <Button tag={Link} to="/tasks" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
        </Button>

    return (
        <div className="TaskCreate">
            <PageTitle icon="id-badge" title={title} actions={actions} />

            {taskSuccessfullyCreated &&
                <Redirect to="/tasks" />
            }

            {task &&
                <TaskForm onSubmit={submit} isCreation="true" />
            }
        </div>
    );
}

export default TaskCreate;
