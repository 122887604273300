import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services';

import { loadArticlecategory } from '../../../redux/modules/articlecategories';

class ArticlecategoryShow extends Component<{}> {
    componentDidMount() {
        const articlecategoryId = this.props.match.params.articlecategoryId;
        // Load articlecategories on component mount
        this.props.dispatchLoadArticlecategory(articlecategoryId);
    }

    render() {
        const { articlecategory } = this.props.articlecategories;

        const { t } = this.props;

        const default_lang = Utils.default_lang();

        let title = <span>{t("articlecategories.articlecategory_plural")}</span>;
        if (articlecategory)
            title = (
                <span>
                    {t("articlecategories.articlecategory")}
                    <small> | { articlecategory && articlecategory.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/articlecategories" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {articlecategory &&
                    <Button tag={Link} to={ `/articlecategories/edit/${ articlecategory._id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="ArticlecategoryShow">
                <PageTitle icon="th" title={ title } actions={ actions } />
                {articlecategory &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-articlecategory"></i>&nbsp;{t("articlecategories.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("articlecategories.name")}</b>: { articlecategory.name[default_lang] }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadArticlecategory: (articlecategoryId) => loadArticlecategory(articlecategoryId)
}

const mapStateToProps = state => ({
    articlecategories: state.articlecategories
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ArticlecategoryShow))
