import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SpecialSelectField = ({input, onSelectChange}) => {
    const { t }        = useTranslation();
    const { isMobile } = useSelector(state => state.ui);

    const value = input.value;

    const change = (val) => {
        onSelectChange(val);
        input.onChange(val);
    }

    if (!value)
        change('youtube');

    const newTrailerType = [
        {
            key: 'youtube',
            text: 'Youtube',
            value: 'youtube'
        },
        {
            key: 'vimeo',
            text: 'Vimeo',
            value: 'vimeo'
        },
        {
            key: 'mp4',
            text: 'MP4',
            value: 'mp4'
        },
    ]

    return (
        <div className='d-flex'>
            {
                {
                    'youtube': <div className='trailer youtube'><i className='fa fa-play'></i></div>,
                    'vimeo': <div className='trailer vimeo'><i className='fa fa-play'></i></div>,
                    'mp4': <div className='trailer other'><i className='fa fa-play'></i></div>
                }[input.value]
            }
            <select className={`form-control rounded-0 select-trailer-type${isMobile ? ' mobile-select-trailer' : ""}`} value={value} onChange={e => change(e.target.value)}>
                {newTrailerType.map(tt =>
                    <option value={tt.value} key={tt.key}>{tt.text}</option>
                )}
            </select>
        </div>
    )
};

export default SpecialSelectField;