import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageTitle, PrinterResume, Loader } from '../../../../components';
import { loadPrinters } from '../../../../redux';
import { Row, Col, Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiArrowLeftCircle } from '@mdi/js';

const PrintersList = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { connected }  = useSelector(state => state.sioBridge);
    const { printers } = useSelector(state => state.printers, shallowEqual);
    const { isLoading }  = useSelector(state => state.printers);

    useEffect(() => {
        if (connected)
            dispatch(loadPrinters());
    }, [dispatch, connected]);

    const actions = <Link to="/devices">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    return (
        <div className="PrintersList">
            <PageTitle
                title={ t('printers.title') }
                icon="print"
                actions={ actions }
            />

            {isLoading && (
                <Loader />
            )}

            {!isLoading && (
            <div className="mt-3">
                <Row>
                { printers && printers.filter(p => p.isConnected()).map(printer => (
                    <Col key={ printer.id } lg={ 2 } md={ 4 } sm={ 6 } xs={ 12 }>
                        <PrinterResume printer={ printer } className="mb-3" />
                    </Col>
                ))}
                { printers && printers.filter(p => !p.isConnected()).map(printer => (
                    <Col key={ printer.id } lg={ 2 } md={ 4 } sm={ 6 } xs={ 12 }>
                        <PrinterResume printer={ printer } className="mb-3" />
                    </Col>
                ))}
                </Row>
            </div>
            )}
        </div>
    );
}

export default PrintersList;
