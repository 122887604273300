import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ProgrammationForm from '../ProgrammationForm/ProgrammationForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadProgrammation, updateProgrammation } from '../../../redux/modules/programmations';

class ProgrammationEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit  = this.submit.bind(this);
        this.goBack  = this.goBack.bind(this);
    }

    componentDidMount() {
        const programmationId = this.props.match.params.programmationId;
        // Load programmations on component mount
        this.props.dispatchLoadProgrammation(programmationId);
    }

    submit(values) {
        this.props.dispatchUpdateProgrammation(values);
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const { programmation } = this.props.programmations;
        const { t } = this.props;

        let title = <span>{t('programmations.programmation')}</span>;
        if (programmation)
            title = (
                <span>
                    {t('programmations.programmation')}
                    <small> |&nbsp;{ t('programmations.update_programmation', {  })} </small>
                </span>
            );

        const actions =
        <div>
            {programmation &&
            <ButtonGroup>
                <Button onClick={this.goBack} color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="ProgrammationEdit">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {programmation &&
                    <ProgrammationForm
                        programmationId={programmation.id}
                        onSubmit={this.submit}
                    />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadProgrammation: (programmationId) => loadProgrammation(programmationId),
    dispatchUpdateProgrammation: (programmation) => updateProgrammation(programmation)
}

const mapStateToProps = state => ({
    programmations: state.programmations,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ProgrammationEdit))
