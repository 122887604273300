import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { authenticate } from '../../redux/modules/auth';
import {
    Form,
    FormGroup,
    Input,
    Button
} from 'reactstrap';

import logo  from '../../assets/images/Kronos.svg';
import back1 from '../../assets/images/back_login1.jpg';
import back2 from '../../assets/images/back_login2.jpg';
import back3 from '../../assets/images/back_login3.jpg';

import './Login.css';

// Random background
const rand = parseInt(Math.random() * 1000, 10);
const back = rand % 3 === 1 ? back1 : (rand % 3 === 2 ? back2 : back3);

class Login extends Component<{}> {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { email, password } = this.state;
        this.props.dispatchAuthenticate(email, password);
    }

    render() {
        const {
            logInErrorMessage,
            isAuthenticating,
            logInError,
            t
        } = this.props;

        return (
            <div className="Login text-center">
                <div className="wrapper">
                    <div className="background" style={{ backgroundImage: "url(" + back + ")"}} ></div>
                    <Form onSubmit={this.handleSubmit} className="form-signin">
                        <img className="logo" src={logo} alt="logo" />
                        <br/>
                        <FormGroup>
                            <Input required type="email" autoComplete="username email" name="email" id="email" placeholder={t("login.email")} value={this.state.email} onChange={ this.handleChange } />
                        </FormGroup>
                        <FormGroup>
                            <Input required type="password" autoComplete="current-password" name="password" id="password" placeholder={t("login.password")} value={this.state.password} onChange={ this.handleChange }/>
                        </FormGroup>

                        <Button className="btn btn-lg btn-block btn-dark">
                            {isAuthenticating ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>{t('app.login')}</span>
                            }
                        </Button>

                        {logInError &&
                            <div>
                                <br/>
                                <div className="alert alert-danger">
                                    <i className="fa fa-warning"></i>&nbsp;
                                    {logInErrorMessage}
                                </div>
                            </div>
                        }
                    </Form>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchAuthenticate: (email, password) => authenticate(email, password)
}

const mapStateToProps = state => ({
    isAuthenticating: state.auth.isAuthenticating,
    logInError: state.auth.logInError,
    logInErrorMessage: state.auth.logInErrorMessage,
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Login))
