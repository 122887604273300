// Redux Postcategories module
// see https://github.com/erikras/ducks-modular-redux

import { Postcategories } from '../../services/';

const LOADING_POSTCATEGORIES         = 'kronos/post_categories/LOADING_POSTCATEGORIES';
const LOADING_POSTCATEGORIES_SUCCESS = 'kronos/post_categories/LOADING_POSTCATEGORIES_SUCCESS';
const LOADING_POSTCATEGORIES_FAILURE = 'kronos/post_categories/LOADING_POSTCATEGORIES_FAILURE';
const LOADING_POSTCATEGORY           = 'kronos/post_categories/LOADING_POSTCATEGORY';
const LOADING_POSTCATEGORY_SUCCESS   = 'kronos/post_categories/LOADING_POSTCATEGORY_SUCCESS';
const LOADING_POSTCATEGORY_FAILURE   = 'kronos/post_categories/LOADING_POSTCATEGORY_FAILURE';
const UPDATING_POSTCATEGORY          = 'kronos/post_categories/UPDATING_POSTCATEGORY';
const UPDATING_POSTCATEGORY_SUCCESS  = 'kronos/post_categories/UPDATING_POSTCATEGORY_SUCCESS';
const UPDATING_POSTCATEGORY_FAILURE  = 'kronos/post_categories/UPDATING_POSTCATEGORY_FAILURE';
const START_CREATING_POSTCATEGORY    = 'kronos/post_categories/START_CREATING_POSTCATEGORY';
const CREATING_POSTCATEGORY          = 'kronos/post_categories/CREATING_POSTCATEGORY';
const CREATING_POSTCATEGORY_SUCCESS  = 'kronos/post_categories/CREATING_POSTCATEGORY_SUCCESS';
const CREATING_POSTCATEGORY_FAILURE  = 'kronos/post_categories/CREATING_POSTCATEGORY_FAILURE';
const DELETING_POSTCATEGORY          = 'kronos/post_categories/DELETING_POSTCATEGORY';
const DELETING_POSTCATEGORY_SUCCESS  = 'kronos/post_categories/DELETING_POSTCATEGORY_SUCCESS';
const DELETING_POSTCATEGORY_FAILURE  = 'kronos/post_categories/DELETING_POSTCATEGORY_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    post_categorySuccessfullyCreated: false,
    post_categories: []
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_POSTCATEGORIES:
            return {
                ...state,
                isLoading: true,
                post_categorySuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_POSTCATEGORIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                post_categories: action.post_categories
            };
        case LOADING_POSTCATEGORIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_POSTCATEGORY:
            return {
                ...state,
                post_category: null,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_POSTCATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                post_category: action.post_category
            };
        case LOADING_POSTCATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                post_category: null,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_POSTCATEGORY:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_POSTCATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                post_category: action.post_category
            };
        case UPDATING_POSTCATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_POSTCATEGORY:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                post_categorySuccessfullyCreated: false,
                post_category: {
                    name: {
                        fr: '',
                        en: '',
                        de: '',
                        it: ''
                    }
                }
            };
        case CREATING_POSTCATEGORY:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_POSTCATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                post_categorySuccessfullyCreated: true,
                post_category: action.post_category
            };
        case CREATING_POSTCATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                post_categorySuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_POSTCATEGORY:
            return {
                ...state,
                isLoading: true,
                post_categorySuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case DELETING_POSTCATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                post_categorySuccessfullyDeleted: true,
                post_category: action.post_category
            };
        case DELETING_POSTCATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                post_categorySuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingPostcategories() { return { type: LOADING_POSTCATEGORIES } }
function loadingPostcategoriesSuccess(post_categories) { return { type: LOADING_POSTCATEGORIES_SUCCESS, post_categories: post_categories } }
function loadingPostcategoriesFailure(err) { return { type: LOADING_POSTCATEGORIES_FAILURE, error: err } }
export function loadPostcategories(post_categoryname, password) {
    return (dispatch) => {
        dispatch(loadingPostcategories());
        Postcategories.list()
            .then(data => {
                const post_categories = data.post_categories;
                dispatch(loadingPostcategoriesSuccess(post_categories));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingPostcategoriesFailure(err))
            });
    }
}

function loadingPostcategory() { return { type: LOADING_POSTCATEGORY } }
function loadingPostcategorySuccess(post_category) { return { type: LOADING_POSTCATEGORY_SUCCESS, post_category: post_category } }
function loadingPostcategoryFailure(err) { return { type: LOADING_POSTCATEGORY_FAILURE, error: err } }
export function loadPostcategory(post_categoryId) {
    return (dispatch) => {
        dispatch(loadingPostcategory());
        Postcategories.get(post_categoryId)
            .then(data => {
                const post_category = data.post_category;
                dispatch(loadingPostcategorySuccess(post_category));
            })
            .catch(err => {
                dispatch(loadingPostcategoryFailure(err))
            });
    }
}

function updatingPostcategory() { return { type: UPDATING_POSTCATEGORY } }
function updatingPostcategorySuccess(post_category) { return { type: UPDATING_POSTCATEGORY_SUCCESS, post_category: post_category } }
function updatingPostcategoryFailure(err) { return { type: UPDATING_POSTCATEGORY_FAILURE, error: err } }
export function updatePostcategory(post_category) {
    return (dispatch) => {
        dispatch(updatingPostcategory());
        Postcategories.update(post_category)
            .then(data => {
                const post_category = data.post_category;
                dispatch(updatingPostcategorySuccess(post_category));
            })
            .catch(err => {
                dispatch(updatingPostcategoryFailure(err))
            });
    }
}

function creatingPostcategory() { return { type: CREATING_POSTCATEGORY } }
function creatingPostcategorySuccess(post_category) { return { type: CREATING_POSTCATEGORY_SUCCESS, post_category: post_category } }
function creatingPostcategoryFailure(err) { return { type: CREATING_POSTCATEGORY_FAILURE, error: err } }
export function startCreatePostcategory() { return { type: START_CREATING_POSTCATEGORY } }
export function createPostcategory(post_category) {
    return (dispatch) => {
        dispatch(creatingPostcategory());
        Postcategories.create(post_category)
            .then(data => {
                const post_category = data.post_category;
                dispatch(creatingPostcategorySuccess(post_category));
                dispatch(loadingPostcategorySuccess(post_category));
            })
            .catch(err => {
                dispatch(creatingPostcategoryFailure(err))
            });
    }
}

function deletingPostcategory() { return { type: DELETING_POSTCATEGORY } }
function deletingPostcategorySuccess() { return { type: DELETING_POSTCATEGORY_SUCCESS } }
function deletingPostcategoryFailure(err) { return { type: DELETING_POSTCATEGORY_FAILURE, error: err } }
export function deletePostcategory(post_categoryId) {
    return (dispatch) => {
        dispatch(deletingPostcategory());
        Postcategories.delete(post_categoryId)
            .then(data => {
                dispatch(deletingPostcategorySuccess());
            })
            .catch(err => {
                dispatch(deletingPostcategoryFailure(err))
            });
    }
}
