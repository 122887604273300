import React, { useState, useEffect, useLayoutEffect } from 'react';
import TopBar from './TopBar/TopBar';
import SideBar from './SideBar/SideBar';
import { useLocation } from 'react-router-dom'
import { Utils } from '../../../services';
import { Button, NavbarToggler } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { updateUi } from '../../../redux';
import { useDispatch, useSelector } from 'react-redux';

import './Nav.css';

const Nav = ({ }) => {
    const {
        collapsedSideBar,
        isMobile,
        isTablet,
        showNav,
        showChangelog
    }                           = useSelector(state => state.ui);
    const { t }                 = useTranslation();
    const dispatch              = useDispatch();
    const { pathname }          = useLocation()
    const [section, setSection] = useState(Utils.SECTION_OPERATION);
    const width                 = useWindowSize();

    useEffect(() => {
        setSection(Utils.getSectionFromPath(pathname));
    }, [pathname]);

    // If width < 768 we're on mobile display
    // If 768 <= width <= 1024 we're on tablet display
    useEffect(() => {
    // check if we need to update the state
        const newIsMobile = (width < 768);
        const newIsTablet = (width >= 768) && (width <= 1024);
        const newShowNav  = (width >= 768);
        const needsUpdate = (
            isMobile != newIsMobile ||
            isTablet != newIsTablet ||
            showNav != newShowNav
        );

        if (!needsUpdate)
            return;

        dispatch(updateUi({
            isMobile: newIsMobile,
            isTablet: newIsTablet,
            showNav: newShowNav
        }));
    }, [width]);


    const handleSideBarCollapse = () => {
        dispatch(updateUi({ collapsedSideBar: !collapsedSideBar }));
    }

    //width eventlistener
    function useWindowSize() {
        const [width, setWidth] = useState(0);
        useLayoutEffect(() => {
            function updateSize() {
                setWidth(window.innerWidth);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return width;
    }

    const handleShowNav = () => {
        if (isMobile)
            dispatch(updateUi({showNav : false}))
    }

    return (
        <div className="Nav">
            {!showChangelog &&
                <NavbarToggler className={`navToggle ${showNav ? "position-fixed" : "position-absolute"}`} onClick={() => dispatch(updateUi({showNav : !showNav}))}>
                    <i className="fa fa-bars"></i>
                </NavbarToggler>
            }
            {showNav &&
                <>
                    <TopBar section={section} onSectionSelected={setSection} onCollapseNavClick={handleShowNav} />
                    <div className={`${collapsedSideBar ? "reducedNav" : "expandedNav"}`}>
                        <SideBar section={section} collapsed={collapsedSideBar} onCollapseNavClick={handleShowNav}>
                            <Button onClick={() => handleSideBarCollapse()} className="btn text-center rounded-0 w-100 sidebar-btn"><i className={`fa fa-${collapsedSideBar ? "chevron-right" : "chevron-left"}`}></i>{collapsedSideBar ? "" : " " + t('app.hide_sidebar')}</Button>
                        </SideBar>
                    </div>
                </>
            }
        </div>
    );
}

export default Nav;
