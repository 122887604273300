import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

const DurationPredefinedComponent = (props) => {
    const { input, sign, setDurationValue, setCustomFieldIsVisible, onDurationChange, predefinedValues } = props;
    const { t } = useTranslation();

    const normalizer = value => {
        if (!value)
            return value;

        value = value.replace(/([+-])/g, '');

        if (sign === '-') {
            value = '-' + value;
        }

        return value;
    };

    useEffect(() => {
        setDurationValue(input.value);
    }, [input.value]);

    useEffect(() => {
        input.onChange(normalizer(input.value));
    }, [sign]);

    const onChange = (e, data) => {
        input.onChange(data.value === 'custom' ? 'P0Y0M0DT0M0S' : data.value);
        setCustomFieldIsVisible(data.value === 'custom');
        onDurationChange && onDurationChange(data.value === 'custom' ? 'P0Y0M0DT0M0S' : data.value);
    }

    const options = (predefinedValues || []).map(v => ({
        text: v.text,
        key: v.value,
        value: normalizer(v.value)
    }));
    options.unshift({ text: t('common.duration.custom'), key: -1, value: 'custom' });
    options.unshift({ text: t('common.duration.none'), key: 0, value: null});

    return <Dropdown
        name={ input.name }
        className="form-control"
        options={ options }
        defaultValue={ input.value }
        onChange={ onChange }
        placeholder={ t('common.duration.none') }
        search
        selection
    />;
}

export default DurationPredefinedComponent;
