import React from "react";
import {SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { FormLayout } from '../../../../../models';
import "./SortableList.css";

const DragHandle = SortableHandle(() => (
    <span className="drag-handle">
        <i className="fa fa-bars" />
    </span>
));

const SortableItem = SortableElement(({ value, width }) => {
    return (
        <li style={{ width: `${width * 100 }%` }}>
            <DragHandle />
            { value }
        </li>
    );
});

const SortableItems = SortableContainer(({ items, widths }) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} width={widths[index]}/>
            ))}
        </ul>
        );
});

const SortableList = props => {
    const { layout, fields, fonts, currentField, onClick, onSort } = props;

    const onSortEnd = ({oldIndex, newIndex}) => {
        onSort && onSort(oldIndex, newIndex);
    };

    const editableContent = (content) => {
        return (
            <div className="editable-content" >{ content }</div>
        );
    };

    const items = fields.map((data, index) => {
        const field = FormLayout.createField(data);

        const fieldContent = (
            <div>
                { field?.getPreview({
                    layout,
                    inEdition: props.inEdition,
                    lang: props.lang,
                    setting: props.setting,
                    fonts,
                    programmation: props.programmation,
                    place: props.place,
                    tickettype: props.tickettype,
                    pricing: props.pricing
                })}
            </div>
        );

        return (
            <div onClick={ () => { onClick(field, index); }} tabIndex={ -1 } className={ `${currentField?.id === field?.id ? 'active' : ''}` } style={{position: 'relative'}}>
                { props.inEdition ? editableContent(fieldContent) : fieldContent }
            </div>
        );
    });

    const widths = fields.map(field => field.width);

    return (
        <div className="SortableList">
            <SortableItems items={ items } widths={ widths } onSortEnd={ onSortEnd } useDragHandle />
        </div>
    );
};

export default SortableList;
