import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Provider } from 'react-redux';
import store, { swInstalled, swHasUpdate } from './redux';
import App from './containers/App/App';
import registerServiceWorker from './registerServiceWorker';

import i18n from './locales/i18n';

import { BrowserRouter as Router } from 'react-router-dom'

import 'semantic-ui-css/semantic.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';

import TimeAgo from 'javascript-time-ago';
import fr from 'javascript-time-ago/locale/fr';
import en from 'javascript-time-ago/locale/en';
import de from 'javascript-time-ago/locale/de';
TimeAgo.addDefaultLocale(fr)
TimeAgo.addLocale(en)
TimeAgo.addLocale(de)

Sentry.init({
    dsn: "https://6ae1af01001143ee94236369cf89b63a@o1159998.ingest.sentry.io/6275822",
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.75,
});

ReactDOM.render(
    <Provider store={store}>
        <Router store={store}>
            <App/>
        </Router>
    </Provider>,
    document.getElementById('root')
);

registerServiceWorker({
    reset: false,
    onInstall: reg => store.dispatch(swHasUpdate(reg)),
    onUpdate: reg => store.dispatch(swHasUpdate(reg)),
});
