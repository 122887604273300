import React from 'react';
import BaseModel from './BaseModel';
import moment from 'moment';
import { mdiAlertOutline } from '@mdi/js';
import ReactTimeAgo from 'react-time-ago';

/** Class representing a collector event. */
class CollectorEvent extends BaseModel {
    constructor(properties) {
        super(properties);

        if (this.createdAt)
            this.createdAt = moment(this.createdAt);
    }

    getIcon() {
        return mdiAlertOutline;
    }

    getText() {
        return this.label;
    }

    getCreatedAt() {
        return (
            <ReactTimeAgo
                date={ this.createdAt.toDate() }
                locale="fr-FR"
                timeStyle="round-minute"
            />
        );
    }
}

export default CollectorEvent;
