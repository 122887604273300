import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ApplicationTypeForm from '../ApplicationTypeForm/ApplicationTypeForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Utils } from '../../../services';
import { loadApplicationType, updateApplicationType } from '../../../redux/modules/applicationTypes';

const ApplicationTypeEdit = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const { applicationTypeId } = match.params;
        dispatch(loadApplicationType(applicationTypeId));
    }, [dispatch])

    const submit = (values) => {
        dispatch(updateApplicationType(values));
    }

    const { application_type } = useSelector(state => state.applicationTypes);

    let title = <span>{t('applicationTypes.update_application_type')}</span>;

    if (application_type && application_type.name)
        title = (
            <span>
                {t('applicationTypes.applicationType')}
                <small> |&nbsp;{ t('applicationTypes.update_application_type', { name: application_type.name[Utils.default_lang()] })} </small>
            </span>
        );

    const actions =
        <div>
            {application_type &&
            <ButtonGroup>
                <Button  tag={Link} to="/application_types" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="ApplicationTypeEdit">
                <PageTitle icon="address-book" title={ title } actions={ actions } />

                {application_type &&
                    <ApplicationTypeForm applicationTypeId={application_type._id} onSubmit={ submit } />
                }
            </div>
        );
}

export default ApplicationTypeEdit;
