import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { loadSalepoints, loadArticlecategories } from '../../../../../redux';
import { CategoriesField, SalepointsField, PpdsField } from '../../../../../components';

let EftForm = ({ handleSubmit, pristine, submitting, isLoading, initialValues }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { eft, isLoadingOne, loadingOneErrorMessage } = useSelector(state => state.efts);
    const { salepoints }         = useSelector(state => state.salepoints);
    const { articlecategories }  = useSelector(state => state.articlecategories);

    useEffect(() => {
        dispatch(loadArticlecategories());
        dispatch(loadSalepoints());
    }, []);

    return (
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="name" sm={3}>{t("efts.terminal_id")}</Label>
                            <Col sm={9}>
                                <Field component="input" type="text" name="options.terminal_id" className="form-control" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={3}>{t("efts.only_for_pos")}</Label>
                            <Col sm={9}>
                                <SalepointsField name="options.only_for_pos" salepoints={salepoints}/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
    );
}

const mapDispatchToProps = {}
EftForm = reduxForm({ form: 'eftForm' })(EftForm)
EftForm = connect(state => ({}), mapDispatchToProps)(EftForm)

export default EftForm;
