import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Row, Col, FormGroup, Label, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import ToggleField from '../../ToggleField/ToggleField';

import logo from '../../../assets/images/integrations/maccsbox.png';

const MaccsboxFields = () => {
    const { t }                  = useTranslation();
    const { isMobile, isTablet } = useSelector(state => state.ui);

    return (
        <div className="MaccsboxFields">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="small">
                                <img src={logo} className="img rounded" />
                                { t('settings.integrations.maccsbox.title') }
                            </CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.maccsbox.ftp.pasv")}</Label>
                                            <Col lg={10} >
                                            <ToggleField name="integrations.maccsbox.ftp.pasv" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.maccsbox.ftp.hostname")}</Label>
                                            <Col lg={10} >
                                                <Field required component="input" className="form-control" type="text" name="integrations.maccsbox.ftp.hostname" id="hostname" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                        <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.maccsbox.ftp.directory")}</Label>
                                            <Col lg={10} >
                                                <Field required component="input" className="form-control" type="text" name="integrations.maccsbox.ftp.directory" id="directory" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.maccsbox.ftp.username")}</Label>
                                            <Col lg={10} >
                                                <Field required component="input" className="form-control" type="text" name="integrations.maccsbox.ftp.username" id="_id" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.maccsbox.ftp.password")}</Label>
                                            <Col lg={10} >
                                                <Field required component="input" className="form-control" type="text" name="integrations.maccsbox.ftp.password" id="password" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default MaccsboxFields;
