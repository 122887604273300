import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import ArticlesList from './ArticlesList/ArticlesList';
import ArticleShow from './ArticleShow/ArticleShow';
import ArticleEdit from './ArticleEdit/ArticleEdit';
import ArticleCreate from './ArticleCreate/ArticleCreate';
import ArticleDelete from './ArticleDelete/ArticleDelete';

class Articles extends Component<{}> {
    render() {
        const { match }     = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.articles;
        return (
            <div className="Articles">

                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={ArticlesList} />
                <Route exact path={ `${ match.url }/new` } component={ArticleCreate} />
                <Route exact path={ `${ match.url }/new/:articleType` } component={ArticleCreate} />
                <Route exact path={ `${ match.url }/show/:articleId` } component={ArticleShow} />
                <Route exact path={ `${ match.url }/edit/:articleId` } component={ArticleEdit} />
                <Route exact path={ `${ match.url }/delete/:articleId` } component={ArticleDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    articles: state.articles
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Articles));
