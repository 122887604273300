import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import ResourcesList from './ResourcesList/ResourcesList';
import ResourceShow from './ResourceShow/ResourceShow';
import ResourceEdit from './ResourceEdit/ResourceEdit';
import ResourceCreate from './ResourceCreate/ResourceCreate';
import ResourceDelete from './ResourceDelete/ResourceDelete';

class Resources extends Component<{}> {
    render() {
        const { match } = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.resources;
        return (
            <div className="Resources">

                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={ResourcesList} />
                <Route exact path={ `${ match.url }/new` } component={ResourceCreate} />
                <Route exact path={ `${ match.url }/show/:resourceId` } component={ResourceShow} />
                <Route exact path={ `${ match.url }/edit/:resourceId` } component={ResourceEdit} />
                <Route exact path={ `${ match.url }/delete/:resourceId` } component={ResourceDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    resources: state.resources
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Resources));
