import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import UserForm from '../UserForm/UserForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { startCreateUser, createUser } from '../../../redux/modules/users';

const UserCreate = ({}) => {
    const dispatch                          = useDispatch();
    const { t }                             = useTranslation();
    const { userSuccessfullyCreated, user } = useSelector(state => state.users);
    const authUser                          = useSelector(state => state.auth.user)

    useEffect(() => {
        dispatch(startCreateUser());
    }, [])

    const submit = (values) => {
        dispatch(createUser(values));
    }

    if (!authUser)
        return null;

    const title = <span>Ajout d'un collaborateur</span>;

    const actions =
        <Button tag={Link} to="/users" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
        </Button>

        return (
            <div className="UserCreate">
                <PageTitle icon="users" title={ title } actions={ actions } />

                {userSuccessfullyCreated &&
                    <Redirect to={`/users/edit/${user.id}`} />
                }

                <UserForm
                    onSubmit={submit}
                    authUser={authUser}
                    user={user}
                    _activeTab={'1'}
                    isCreation
                />
            </div>
        );
}

export default UserCreate;
