import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import OldScreeningForm from '../OldScreeningForm/OldScreeningForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useTranslation, withTranslation } from 'react-i18next';
import { Utils } from '../../../services';

import { loadLegacyScreening, updateLegacyScreening, tktsyncLegacyScreenings } from '../../../redux/modules/legacyScreenings';

const OldScreeningEdit = ({ }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const default_lang = Utils.default_lang();
    const [needsSynchronization, setNeedsSynchronization] = useState(false)
    const { screeningId } = useParams();
    const { legacyScreening } = useSelector(state => state.legacyScreenings)

    const goBack = () => {
        window.history.back();
    }

    useEffect(() => {
        dispatch(loadLegacyScreening(screeningId));
    }, [])

    const submit = (values) => {
        dispatch(updateLegacyScreening(values));
        setNeedsSynchronization(true);
    }

    const tktsync = () => {
        dispatch(tktsyncLegacyScreenings());
        setNeedsSynchronization(false);
    }

    let title = <span>{t('screenings.screening')}</span>;
    if (legacyScreening?.title)
        title = (
            <span>
                {t('screenings.screening')}
                <small> |&nbsp;{t('screenings.update_screening', { title: legacyScreening.title[default_lang] })} </small>
            </span>
        );

    const actions =
        <Button onClick={goBack} color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
        </Button>

    return (
        <div className="ScreeningEdit">
            <PageTitle icon="th" title={title} actions={actions} />

            {Object.keys(legacyScreening).length > 0 &&
                <OldScreeningForm
                    screeningId={legacyScreening.id}
                    onSubmit={submit}
                    handleTktSync={tktsync}
                    needsSynchronization={needsSynchronization}
                />
            }
        </div>
    );
}

export default OldScreeningEdit;
