import React from 'react';
import { Input } from 'reactstrap';
import matchSorter from 'match-sorter';
import { Utils } from './';
import i18n from '../locales/i18n';
import _ from 'lodash';
import moment from 'moment';

class Filters { }

Filters.textFilter = ({ filter, onChange }) =>
    <Input
        type="text"
        value={filter ? filter.value : ""}
        onChange={event => onChange(event.target.value)}
    />;

Filters.dateFilter = (inFutur) => ({ filter, onChange }) => {
    return (
        <input
            type="date"
            id="dateInput"
            min={inFutur ? moment().startOf('day').format("YYYY[-]MM[-]DD") : ""}
            onChange={e => onChange(e.target.value)}
            defaultValue={filter ? filter.value : ""}
            className="w-100 text-center form-control"
        />
    );
}

Filters.rolesFilter = ({ filter, onChange }) =>
    <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option value="all">Tous</option>
        {Utils.roles().map((r) =>
            <option key={r.role} value={r.role}>{r.label}</option>)
        }
    </select>;

Filters.eventsFilter = (events_groups) => ({ filter, onChange }) => {
    const default_lang = Utils.default_lang();
    return <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {events_groups.map((g) => (
            <optgroup key={g.label} label={g.label}>{
                g.events.map((e) => <option key={e.id} value={e.id}>{e.title ? Utils.ucfirst(Utils.localized_or_fallback(e.title, default_lang)) : '---'}</option>)
            }
            </optgroup>
        ))}
    </select>;
}

Filters.daysFilter = ({ filter, onChange }) =>
    <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {Utils.days().map((p) =>
            <option key={p.index} value={p.index}>{i18n.t('common.' + p.label)}</option>
        )}
    </select>;

Filters.tktInstancesFilter = (tktInstances) => ({ filter, onChange }) =>
    <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {tktInstances && tktInstances.map((t) =>
            <option key={t.id} value={t.id}>{t.name}</option>
        )}
    </select>;

Filters.placesFilter = (places) => ({ filter, onChange }) => {
    if (!places)
        return null;

    let result = {};
    places.forEach(p => {
        if (p.cinema) {
            if (!result[p.cinema])
                result[p.cinema] = [];
            result[p.cinema].push({
                'name': p.name,
                '_id': p._id
            });
        } else {
            result['other'].push({
                'name': p.name,
                '_id': p._id
            })
        }
    })

    result = Utils.sort_object_keys(result);

    return <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {Object.keys(result).map((key) => 
            <optgroup label={key} key={key}>
                {result[key]
                    .sort(Utils.sortOnProperty('name'))
                    .map(p =>
                        <option key={p._id} value={p._id}>{`${p.name}`}</option>
                    )
                }
            </optgroup>
        )}
    </select>;
}

Filters.categoriesFilter = (categories) => ({ filter, onChange }) => {
    const default_lang = Utils.default_lang();
    return <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {categories && categories.map((c) =>
            <option key={c._id} value={c._id}>{c.name[default_lang]}</option>
        )}
    </select>;
}

Filters.posFilter = (pos) => ({ filter, onChange }) => {
    const default_lang = Utils.default_lang();
    return <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {pos && pos.map((p) =>
            <option key={p._id} value={p._id}>{p.name[default_lang]}</option>
        )}
    </select>;
}

Filters.usersFilter = (users) => ({ filter, onChange }) =>
    <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {users && _.sortBy(users, (u) => u.fullname).map((u) => u && u.id &&
            <option key={u.id} value={u.id}>{u.fullname}</option>
        )}
    </select>;

Filters.activitiesFilter = (activities) => ({ filter, onChange }) => {
    const default_lang = Utils.default_lang();
    return <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {activities && activities.map((a) =>
            <option key={a.id} value={a.id}>{a.name[default_lang]}</option>
        )}
    </select>;
}

Filters.sectorsFilter = sectors => ({ filter, onChange }) => {
    const default_lang = Utils.default_lang();
    return <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {sectors && sectors.map((a) =>
            <option key={a.id} value={a.id}>{a.name[default_lang]}</option>
        )}
    </select>;
}

Filters.taskStatusFilter = () => ({ filter, onChange }) =>
    <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {Utils.taskStatus().map((s) =>
            <option key={s} value={s}>{i18n.t('tasks.status_' + s)}</option>
        )}
    </select>;

Filters.selectFilter = (values) => ({ onChange }) => {
    return (
        <select
            className="form-control"
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%", height: '100%' }}
        >
            <option key="0" value="">---</option>
            {values.map(v =>
                <option value={v.value} key={v.key}>{v.text}</option>
            )}
        </select>
    )
}

Filters.articleStockTypesFilter = (stock_types) => ({ filter, onChange }) =>
    <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="0" value="">---</option>
        {stock_types.map(s =>
            <option key={s} value={s}>{i18n.t('articles.stocks.type_' + s)}</option>
        )}
    </select>;

Filters.cashregisterClosingStatusFilter = () => ({ filter, onChange }) =>
    <select
        className="form-control"
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : ""}
    >
        <option key="-1" value="">---</option>
        <option key="1" value={true}>{i18n.t('cashregisters.auto_closing_enabled')}</option>
        <option key="0" value={false}>{i18n.t('cashregisters.auto_closing_disabled')}</option>
    </select>;

Filters.textFilterMethod = (key) => (filter, rows) => {
    return matchSorter(rows, filter.value, { keys: (Array.isArray(key) ? key : [key]) });
};

Filters.dateFilterMethod = () => (filter, row) => {
    return filter.value === "" ?
        true :
        row.filter((d) => moment(filter.value).isBetween(moment(d._original.start_at).format("YYYY[-]MM[-]DD"), moment(d._original.stop_at).format("YYYY[-]MM[-]DD"), "day", []))
}

Filters.sameDayDateFilterMethod = (key) => (filter, row) => {
    return filter.value === "" ?
        true :
        row.filter((date) => moment(date[key]).isSame(filter.value, 'day'));
}

Filters.usersActivitiesFilterMethod = (filter, row) => {
    return filter.value === "" ?
        true :
        row.activities.filter((a) => a.id === filter.value).length > 0;
}

Filters.sectorsFilterMethod = (filter, rows) => {
    console.log({ filter, rows });
    return filter.value === "" ?
        true :
        rows.filter(row => row.sectors.filter((a) => a.id === filter.value).length > 0);
}

Filters.eventsFilterMethod = (filter, rows) => matchSorter(rows, filter.value, { keys: ['events.0.id'] });

Filters.daysFilterMethod = (filter, rows) => matchSorter(rows, filter.value, { keys: ['days'] });

Filters.rolesFilterMethod = (filter, row) => filter.value === "all" ? true : row[filter.id] === Utils.roles(filter.value).label;

export default Filters;
