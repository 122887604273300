import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText, CardFooter } from 'reactstrap';
import TickettypeFieldForm from '../TickettypeFieldForm/TickettypeFieldForm';
import { Utils } from '../../services';

const FieldsForm = ({ salepoints, remove }) => {
    const { t }           = useTranslation();
    const dispatch        = useDispatch();
    const { tickettype }  = useSelector(state => state.tickettypes);

    const salepointName = (id) => {
        return id === "default" ? t('salepoints.default') : salepoints.filter((item) => item._id == id)[0]?.name.fr;
    }
    return (
        <>
            <Row>
                { Object.keys((tickettype.opaque?.fields || {})).map(salepoint => (
                <Col xs="6" key={ salepoint } className="mb-3">
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <i className="fa fa-map-marker"></i>{' '}
                                { salepointName(salepoint) }
                            </CardTitle>
                            <CardText tag="div">
                                <TickettypeFieldForm salepointId={ salepoint } />
                            </CardText>
                        </CardBody>
                        { salepoint != "default" && (
                        <CardFooter className="text-right">
                            <ButtonGroup>
                                <Button color="danger" size="sm" onClick={ () => { remove(salepoint) }}>
                                    <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                </Button>
                            </ButtonGroup>
                        </CardFooter>
                        )}
                    </Card>
                </Col>
                ))}
            </Row>
        </>
    );
}

export default FieldsForm;
