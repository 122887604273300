import React, { useEffect } from 'react';
import { useSelector, useDispatch }from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from "react-router-dom";
import { Utils, Filters } from '../../../services';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';
import 'react-table-6/react-table.css';
import { loadPostcategories } from '../../../redux/modules/post_categories';
import Table from '../../../components/Table/Table';

import './PostcategoriesList.css';

const PostcategoriesList = () => {
    const { t }              = useTranslation();
    const { post_categories } = useSelector(state => state.post_categories);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadPostcategories());
    }, [dispatch]);

    const default_lang = Utils.default_lang();

    const actions =
        <CreateButton
            module={`post_categories`}
            text="post_categories.add"
        />

    const title =
        <span>{t("post_categories.post_categories_plural")}</span>

    const tree = Utils.sortAndIndentCats(post_categories, 'id', 'parent_id');

    const columns = [{
        Header: t('post_categories.name'),
        accessor: "name",
        filterMethod: Filters.textFilterMethod(`name.${default_lang}`),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => (
                    <div className="post_category_cell">
                        <Link to={`/post_categories/edit/${row.original.id}`}>
                            <Card>
                                <CardBody>
                                    <div className="title-wrapper">
                                        <CardTitle>
                                            {Utils.localized_or_fallback(row.value, default_lang)}
                                        </CardTitle>
                                    </div>
                                </CardBody>
                            </Card>
                        </Link>
                    </div>
        )
    }, {
        Header: t("common.actions"),
        accessor: "id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete"]}
                module="post_categories"
            />
        )
    }];

    return (
        <div className="PostcategoriesList">
            <PageTitle icon="th" title={ title } actions={ actions } />
            <Table id="postCategoriesTable"data={ tree } columns={ columns } />
        </div>
    );
}
export default PostcategoriesList
