// Redux Settings module
// see https://github.com/erikras/ducks-modular-redux

import { Settings, Utils } from '../../services/';
import { PdfLayout } from '../../models/';
import { v4 } from 'uuid';
import i18n from '../../locales/i18n';
import _ from 'lodash';

const LOADING_SETTING            = 'kronos/settings/LOADING_SETTING';
const LOADING_SETTING_SUCCESS    = 'kronos/settings/LOADING_SETTING_SUCCESS';
const LOADING_SETTING_FAILURE    = 'kronos/settings/LOADING_SETTING_FAILURE';
const CHANGE_SETTING             = 'kronos/settings/CHANGE_SETTING';
const UPDATING_SETTING           = 'kronos/settings/UPDATING_SETTING';
const UPDATING_SETTING_SUCCESS   = 'kronos/settings/UPDATING_SETTING_SUCCESS';
const UPDATING_SETTING_FAILURE   = 'kronos/settings/UPDATING_SETTING_FAILURE';
const ADD_DELIVERY_TYPE          = 'kronos/settings/ADD_DELIVERY_TYPE';
const REMOVE_DELIVERY_TYPE       = 'kronos/settings/REMOVE_DELIVERY_TYPE';
const ADD_DELIVERY_TYPE_RULE     = 'kronos/settings/ADD_DELIVERY_TYPE_RULE';
const REMOVE_DELIVERY_TYPE_RULE  = 'kronos/settings/REMOVE_DELIVERY_TYPE_RULE';
const ADD_PAYMENT_METHOD         = 'kronos/settings/ADD_PAYMENT_METHOD';
const REMOVE_PAYMENT_METHOD      = 'kronos/settings/REMOVE_PAYMENT_METHOD';
const ADD_PAYMENT_METHOD_RULE    = 'kronos/settings/ADD_PAYMENT_METHOD_RULE';
const REMOVE_PAYMENT_METHOD_RULE = 'kronos/settings/REMOVE_PAYMENT_METHOD_RULE';
const ADD_IMAGE                  = 'kronos/settings/ADD_IMAGE';
const REMOVE_IMAGE               = 'kronos/settings/REMOVE_IMAGE';
const COPY_MOBILE_SETTINGS       = 'kronos/settings/COPY_MOBILE_SETTINGS';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    settingSuccessfullyCreated: false,
    settings: [],
    setting: {},
}

const initialDeliveryTypeRule = (currency) => {
    return {
        price: { [currency]: 0 },
        countries: [ 'CH' ]
    }
};

const initialDeliveryType = (currency, languages) => {
    const type = {
        _id: v4(),
        type: Utils.DELIVERY_TYPE_SHIPMENT,
        name: {},
        rules: [initialDeliveryTypeRule(currency)],
    };

    languages.map(language => {
        type.name[language] = i18n.t('settings.delivery.type.new_method');
    });

    return type;
};

const initialPaymentMethodRule = (currency) => {
    return {
        price: {
            [currency]: 0,
            percentage: 0
        },
        countries: [ 'CH' ]
    }
};

const initialImage = source => {
    return {
        type: source?.type || Utils.IMAGE_TYPE_LOGO,
        lang: source?.lang || null,
        rfc2397_data: source?.rfc2397_data || null
    }
};

const initialLayout = () => PdfLayout.defaultLayout();

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_SETTING:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_SETTING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                setting: action.setting
            };
        case LOADING_SETTING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case CHANGE_SETTING:
            return {
                ...state,
                setting: action.setting
            };
        case COPY_MOBILE_SETTINGS:
            console.log({action});
            return {
                ...state,
                setting: {
                    ...state.setting,
                    mobile: {
                        ...state.setting.mobile,
                        [action.destination]: { ...state.setting.mobile[action.src] }
                    }
                }
            };
        case UPDATING_SETTING:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_SETTING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                setting: action.setting
            };
        case UPDATING_SETTING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case ADD_DELIVERY_TYPE:
            const types = [].concat(state.setting.delivery.types);
            types.push(initialDeliveryType(action.currency, action.languages));
            return {
                ...state,
                setting: {
                    ...state.setting,
                    delivery: {
                        ...state.setting.delivery,
                        types
                    }
                }
            };
        case REMOVE_DELIVERY_TYPE:
            const newTypes = [].concat(state.setting.delivery.types);
            newTypes.splice(action.typeIndex, 1);
            return {
                ...state,
                setting: {
                    ...state.setting,
                    delivery: {
                        ...state.setting.delivery,
                        types: newTypes
                    }
                }
            };
            return state;
        case ADD_DELIVERY_TYPE_RULE:
            const typesWithNewRule = [].concat(state.setting.delivery.types);
            typesWithNewRule[action.typeIndex].rules.push(initialDeliveryTypeRule(action.currency));
            return {
                ...state,
                setting: {
                    ...state.setting,
                    delivery: {
                        ...state.setting.delivery,
                        types: typesWithNewRule
                    }
                }
            };
        case REMOVE_DELIVERY_TYPE_RULE:
            const updatedTypes = state.setting.delivery.types.concat([]);
            updatedTypes[action.typeIndex].rules.splice(action.ruleIndex, 1);
            return {
                ...state,
                setting: {
                    ...state.setting,
                    delivery: {
                        ...state.setting.delivery,
                        types: updatedTypes
                    }
                }
            };
        case ADD_PAYMENT_METHOD:
            return state;
        case REMOVE_PAYMENT_METHOD:
            return state;
        case ADD_PAYMENT_METHOD_RULE:
            const methodsWithNewRule = [].concat(state.setting.payment.methods);
            methodsWithNewRule[action.methodIndex].rules.push(initialPaymentMethodRule(action.currency));
            return {
                ...state,
                setting: {
                    ...state.setting,
                    payment: {
                        ...state.setting.payment,
                        methods: methodsWithNewRule
                    }
                }
            };
            return state;
        case REMOVE_PAYMENT_METHOD_RULE:
            const updatedMethods = state.setting.payment.methods.concat([]);
            updatedMethods[action.methodIndex].rules.splice(action.ruleIndex, 1);
            return {
                ...state,
                setting: {
                    ...state.setting,
                    payment: {
                        ...state.setting.payment,
                        methods: updatedMethods
                    }
                }
            };
            return state;
        case ADD_IMAGE:
            const images = [].concat(state.setting.images);
            images.push(initialImage(action.source));
            return {
                ...state,
                setting: {
                    ...state.setting,
                    images
                }
            };
        case REMOVE_IMAGE:
            const newImages = [].concat(state.setting.images);
            newImages.splice(action.imageIndex, 1);
            return {
                ...state,
                setting: {
                    ...state.setting,
                   images: newImages
                }
            };
            return state;
        default:
            return state;
    }
};

// Actions
function loadingSetting() { return { type: LOADING_SETTING } }
function loadingSettingSuccess(setting) { return { type: LOADING_SETTING_SUCCESS, setting: setting } }
function loadingSettingFailure(err) { return { type: LOADING_SETTING_FAILURE, error: err } }
export function loadSetting(settingId) {
    return (dispatch) => {
        dispatch(loadingSetting());
        Settings.get(settingId)
            .then(data => {
                const { setting } = data;
                dispatch(loadingSettingSuccess(setting));
            })
            .catch(err => {
                dispatch(loadingSettingFailure(err))
            });
    }
}

function updatingSetting() { return { type: UPDATING_SETTING } }
function updatingSettingSuccess(setting) { return { type: UPDATING_SETTING_SUCCESS, setting: setting } }
function updatingSettingFailure(err) { return { type: UPDATING_SETTING_FAILURE, error: err } }
export function updateSetting(setting) {
    return (dispatch) => {
        dispatch(updatingSetting());
        Settings.update(setting)
            .then(data => {
                const setting = data.setting;
                dispatch(updatingSettingSuccess(setting));
            })
            .catch(err => {
                dispatch(updatingSettingFailure(err))
            });
    }
}

export function addDeliveryType() {
    const languages = Utils.langs();
    const currency  = Utils.currency();
    return (dispatch) => {
        return dispatch({
            type: ADD_DELIVERY_TYPE,
            currency,
            languages
        });
    };
}
export function removeDeliveryType(typeIndex) {
    return (dispatch) => {
        return dispatch({ type: REMOVE_DELIVERY_TYPE, typeIndex });
    };
}

export function addDeliveryTypeRule(typeIndex) {
    const currency  = Utils.currency();
    return (dispatch) => {
        return dispatch({ type: ADD_DELIVERY_TYPE_RULE, typeIndex, currency });
    };
}
export function removeDeliveryTypeRule(typeIndex, ruleIndex) {
    return (dispatch) => {
        return dispatch({ type: REMOVE_DELIVERY_TYPE_RULE, typeIndex, ruleIndex });
    };
}

export function addPaymentMethod() {
    const languages = Utils.langs();
    const currency  = Utils.currency();
    return (dispatch) => {
        return dispatch({
            type: ADD_PAYMENT_METHOD,
            currency,
            languages
        });
    };
}
export function removePaymentMethod(methodIndex) {
    return (dispatch) => {
        return dispatch({ type: REMOVE_PAYMENT_METHOD, methodIndex });
    };
}

export function addPaymentMethodRule(methodIndex) {
    const currency  = Utils.currency();
    return (dispatch) => {
        return dispatch({ type: ADD_PAYMENT_METHOD_RULE, methodIndex, currency });
    };
}
export function removePaymentMethodRule(methodIndex, ruleIndex) {
    return (dispatch) => {
        return dispatch({ type: REMOVE_PAYMENT_METHOD_RULE, methodIndex, ruleIndex });
    };
}

export function addImage(source) {
    return (dispatch) => {
        return dispatch({ type: ADD_IMAGE, source });
    };
}
export function removeImage(imageIndex) {
    return (dispatch) => {
        return dispatch({ type: REMOVE_IMAGE, imageIndex });
    };
}

export function changeSetting(setting) {
    return (dispatch) => {
        return dispatch({ type: CHANGE_SETTING, setting });
    };
}

export function copyMobileSettings(src, destination) {
    return (dispatch) => {
        return dispatch({ type: COPY_MOBILE_SETTINGS, src, destination });
    };
}
