import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import ScreeningsList from './ScreeningsList/ScreeningsList';
import ScreeningEdit from './ScreeningEdit/ScreeningEdit';
import ScreeningDelete from './ScreeningDelete/ScreeningDelete';
import ScreeningCreate from './ScreeningCreate/ScreeningCreate';

const Screenings = ({ match }) => {
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.screenings);

    return (
        <div className="Screenings">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{ __html: loadingErrorMessage }} />
            }
            <Route exact path={match.path} component={ScreeningsList} />
            <Route exact path={ `${ match.url }/new` } component={ScreeningCreate} />
            <Route exact path={`${match.url}/programmation/:programmationId?`} component={ScreeningsList} />
            <Route exact path={`${match.url}/edit/:screeningId`} component={ScreeningEdit} />
            <Route exact path={`${match.url}/delete/:screeningId`} component={ScreeningDelete} />
        </div>
    );
}

export default Screenings;
