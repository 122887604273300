import RestClient from './RestClient';
import { Ticket } from '../../models';

class Tickets extends RestClient {
    constructor() {
        super({
            "model": Ticket,
            "entryPoint": "ticket",
            "resource": "ticket",
            "resources": "tickets",
            "id_field": "_id"
        });
    }
}

const TicketsApi = new Tickets();

export default TicketsApi;
