import RestClient from './RestClient';
import { Article } from '../../models';

class Articles extends RestClient {
    constructor() {
        super({
            "model": Article,
            "entryPoint": "article",
            "resource": "article",
            "resources": "articles",
            "id_field": "_id"
        });
    }
}

const ArticlesApi = new Articles();

export default ArticlesApi;
