import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PrintersList from './PrintersList/PrintersList';
import PrinterManagement from './PrinterManagement/PrinterManagement';

const Printers = () => {
    const { path } = useRouteMatch();
    return (
        <div className="Printers">
            <Switch>
                <Route exact path={path}><PrintersList /></Route>
                <Route exact path={`${path}/manage/:printerId`}><PrinterManagement /></Route>
            </Switch>
        </div>
    );
}

export default Printers;
