import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './UserDataField.css';

const UserDataField = ({ name }) => {
    const { t } = useTranslation();

    const options = Utils.userDataFields().map(f => {
        return {
            key: f,
            text: t(`user_data.${f}`),
            value: f
        };
    });

    return <MultiSelectField name={ name } options={ options } />
}

export default UserDataField;
