import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from "react-router-dom";
import _ from 'lodash';
import { Button } from 'reactstrap';
import 'react-table-6/react-table.css'
import TicketackUserForm from '../TicketackUserForm/TicketackUserForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { startCreateTktUser, createTktUser } from '../../../redux';

const TicketackUserCreate = () => {
    const { t }                          = useTranslation();
    const dispatch                       = useDispatch();
    const { tktuserSuccessfullyCreated } = useSelector(state => state.tktusers)
    const { tktuser }                    = useSelector(state => state.tktusers)

    useEffect(() => {
        dispatch(startCreateTktUser())
    }, [])

    const handleSubmit = (values) => {
        dispatch(createTktUser(values));
    }

    const title = <span>{t("tktUsers.add_a_ticketack_user")}</span>

    const actions =
        <div className="BackBtn">
            <Button tag={Link} to={"/ticketack_users/"} color="danger"><i className="fa fa-times"></i>&nbsp;{t("widgets.cancel")}</Button>
        </div>

    return (
        <div className="TicketackUserEdit">
            <PageTitle icon="users" title={title} actions={actions} />
            <TicketackUserForm onSubmit={handleSubmit} isCreation />
            {(tktuserSuccessfullyCreated && tktuser?._id) &&
                <Redirect to={"/ticketack_users/edit/" + tktuser._id} />
            }
        </div>
    );
}

export default TicketackUserCreate;
