import store from '..';
import { FormLayoutFields } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction
} = createRestSlices(FormLayoutFields);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer
    ]);
}

/* Export CRUD actions */
export const startCreateFormLayoutField = startCreateAction;
export const createFormLayoutField      = createAction;
export const loadFormLayoutField        = getAction;
export const loadFormLayoutFields       = listAction;
export const updateFormLayoutField      = updateAction;
export const deleteFormLayoutField      = deleteAction;
export const duplicateFormLayoutField   = duplicateAction;
