import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router';
import { useParams, useRouteMatch } from 'react-router-dom';
import { loadTktInstanceInfos } from '../../../redux';
import { Loader } from '../../../components';
import { Nav, Navbar, NavbarBrand } from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ApplicationForm } from './ApplicationForm';

import './Instance.css';

const Instance = (props) => {
    const dispatch          = useDispatch();
    const { t, i18n }       = useTranslation();
    const { slug }          = useParams();
    const { instanceInfos } = useSelector(state => state.tktInstances);
    const current_lng       = i18n.language;

    const match = useRouteMatch();

    useEffect(() => {
        if (slug)
            dispatch(loadTktInstanceInfos(slug));
    }, [slug]);

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    if (!instanceInfos)
        return (
            <div className="loader-wrapper center light">
                <Loader color="#454545" />
            </div>
        );

    return (
        <div className="Instance text-center">
            <Navbar className="TopBar fixed-top flex-md-nowrap p-0 shadow" expand="md">
                <Nav className={`ml-auto langsNav mr-3`} navbar>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            <i className="fa fa-globe"></i>&nbsp;{t(`languages.${current_lng}`)}
                        </DropdownToggle>
                        <DropdownMenu right>
                            {['en', 'fr', 'de'].map((lng, i) =>
                                <DropdownItem key={lng} className={current_lng === lng ? 'active' : ''} onClick={() => {changeLanguage(lng); }}>
                                    {t(`languages.${lng}`)}
                                </DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Navbar>
            <div className="wrapper">
                <div className="container">
                    <img src={instanceInfos.customer?.logo?.rfc2397_data} className="logo" alt="logo" />
                </div>
                <Route
                    exact
                    path={ `${ match.url }/application/:applicationTypeId` }
                    render={ routeProps => <ApplicationForm {...routeProps} tkt_instance_id={ instanceInfos.id } /> }
                />
            </div>
        </div>
    );
}

export default Instance;
