import store from '..';
import { Eft } from '../../models';
import { Efts, SioBridge } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer,
    getAction, listAction, updateAction
} = createRestSlices(Efts);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [ getReducer, listReducer, updateReducer ]);
}

/* Export actions */
export const loadEft   = getAction;
export const loadEfts  = listAction;
export const updateEft = updateAction;

SioBridge.on('efts_update', (data) => {
    let connectedEfts = data.map(eft => new Eft(eft));

    const storeEfts = (store.getState().efts.efts || []).map(eft => {
        eft.status = 'disconnected';
        connectedEfts.forEach(connectedEft => {
            if (connectedEft._id === eft._id) {
                eft.status = 'connected';
                connectedEfts = connectedEfts.filter(connectedEft => connectedEft._id !== eft._id);
            }
        });

        return eft;
    });
    const efts = storeEfts.concat(connectedEfts);
    store.dispatch({
        type: 'kronos/efts/LOADING_ITEMS_SUCCESS',
        efts
    });
});
