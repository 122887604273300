import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import PhaseForm from '../PhaseForm/PhaseForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Utils } from '../../../services';
import { loadPhase, updatePhase } from '../../../redux/modules/phases';

const PhaseEdit = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const phaseId = match.params.phaseId;
        // Load phase on component mount
        dispatch(loadPhase(phaseId));
    }, [dispatch])

    const submit = (values) => {
        dispatch(updatePhase(values));
    }

    const { phase } = useSelector(state => state.phases);

    let title = <span>{t('phases.update_phase')}</span>;

    if (phase && phase.name)
        title = (
            <span>
                {t('phases.phase')}
                <small> |&nbsp;{ t('phases.update_phase', { name: phase.name[Utils.default_lang()] })} </small>
            </span>
        );

    const actions =
        <div>
            {phase &&
            <ButtonGroup>
                <Button  tag={Link} to="/phases" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="PhaseEdit">
                <PageTitle icon="arrows-h" title={ title } actions={ actions } />

                {phase &&
                    <PhaseForm phaseId={phase._id} onSubmit={ submit } />
                }
            </div>
        );
}

export default PhaseEdit;
