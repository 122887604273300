import React, { useState }  from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Label,
    Input,
    ButtonGroup,
    Button,
    CardText,
    CardFooter,
} from 'reactstrap';
import { FormLayout, FormLayoutField } from '../../../../../models';
import { TranslatableFieldWithInput } from '../../../../../components';
import { ChoicesField } from './ChoicesField';
import { Checkbox } from 'semantic-ui-react';

const FormFieldOptions = (props) => {
    const { field, onSubmit, onDelete, onDuplicate, onClose, } = props;

    const { isMobile } = useSelector(state => state.ui);

    const { t } = useTranslation();

    const setOption = (option, value) => { field[option] = value; saneSubmit(); }

    const addChoice = () => {
        field.choices = field.choices || [];
        field.choices.push(FormLayoutField.createChoice());
        saneSubmit();
    };

    const editChoice = (index, label) => {
        field.choices = field.choices || [];
        field.choices[index].label = label;
        saneSubmit();
    };

    const removeChoice = (index) => {
        field.choices = (field.choices || []).filter((choice, i) => index !== i);
        saneSubmit();
    };

    const saneSubmit = () => {
        // remove Field instance prototype...
        onSubmit(field.toPlainObject());
    }

    const hasOptions = field => {
        return (
            field.handleOption(FormLayout.OPTION_NAME) ||
            field.handleOption(FormLayout.OPTION_LABEL) ||
            field.handleOption(FormLayout.OPTION_WIDTH) ||
            field.handleOption(FormLayout.OPTION_REQUIRED) ||
            field.handleOption(FormLayout.OPTION_ROWS) ||
            field.handleOption(FormLayout.OPTION_MIN) ||
            field.handleOption(FormLayout.OPTION_MAX) ||
            field.handleOption(FormLayout.OPTION_STEP) ||
            field.handleOption(FormLayout.OPTION_WITH_TIME) ||
            field.handleOption(FormLayout.OPTION_MULTIPLE) ||
            field.handleOption(FormLayout.OPTION_EXPANDED) ||
            field.handleOption(FormLayout.OPTION_EXTENSIONS)
        );
    }

    return (
        <div className="wrapper-1 mr-1">
            <div className="closeButtonWrapper">
                <CardText tag="div" className={"closeButton clearfix" + (isMobile ? " d-flex flex-column-reverse" : "")}>
                    <Button type="button" color="danger" className="pull-right" onClick = { () => onClose() }>
                        <span>
                            <i className="fa fa-times"></i>
                        </span>
                    </Button>
                </CardText>

                { !hasOptions(field) && (
                    <CardText tag="div" className="mb-3 text-center">
                        <b>
                            <i className="fa fa-info-circle"></i>&nbsp;
                            { t('forms.no_options') }
                        </b>
                    </CardText>
                )}

                { hasOptions(field) && (
                    <CardText tag="div">
                        { field.handleOption(FormLayout.OPTION_NAME) && (
                            <Row>
                                <Col sm={12}>
                                    <Label> {t("forms.name")}</Label>
                                    <Input
                                        type="text"
                                        defaultValue={ field.name }
                                        onChange={ e => setOption('name', e.target.value)}
                                    />
                                    <small> {t("forms.name_description")}</small>
                                </Col>
                            </Row>
                        )}
                        { field.handleOption(FormLayout.OPTION_LABEL) && (
                            <Row>
                                <Col sm={12}>
                                    <Label> {t("forms.label")}</Label>
                                    <TranslatableFieldWithInput
                                        name={field.label}
                                        type="text"
                                        onChange={ label => setOption('label', label) }
                                    />
                                    <small> {t("forms.label_description")}</small>
                                </Col>
                            </Row>
                        )}
                        { field.handleOption(FormLayout.OPTION_REQUIRED) && (
                            <Row className="toggleRow">
                                <Col sm={12}>
                                    <Label> {t("forms.required")}</Label>
                                    <Checkbox
                                        toggle
                                        checked={ !!field.required }
                                        onChange={ (e, { checked }) => { setOption('required', !!checked) }}
                                    />
                                </Col>
                            </Row>
                        )}
                        { field.handleOption(FormLayout.OPTION_WIDTH) && (
                            <Row>
                                <Col sm={12}>
                                    <Label> {t("forms.width")}</Label>
                                    <Input defaultValue={field.width} type="select" onChange={ e => setOption('width', e.target.value) }>
                                        <option value={ 1 }>100 %</option>
                                        <option value={ 0.67 }>2 / 3</option>
                                        <option value={ 1 / 2 }>1 / 2</option>
                                        <option value={ 0.33 }>1 / 3</option>
                                    </Input>
                                    <small> {t("forms.width_description")}</small>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            { field.handleOption(FormLayout.OPTION_MIN) && (
                                <Col sm={field.handleOption(FormLayout.OPTION_MAX) ? 6 : 12}>
                                    <Label> {t("forms.min")}</Label>
                                    <Input
                                        type="number"
                                        defaultValue={ field.min }
                                        onChange={ e => setOption('min', e.target.value)}
                                    />
                                </Col>
                            )}
                            { field.handleOption(FormLayout.OPTION_MAX) && (
                                <Col sm={field.handleOption(FormLayout.OPTION_MIN) ? 6 : 12}>
                                    <Label> {t("forms.max")}</Label>
                                    <Input
                                        type="number"
                                        defaultValue={ field.max }
                                        onChange={ e => setOption('max', e.target.value)}
                                    />
                                </Col>
                            )}
                        </Row>
                        { field.handleOption(FormLayout.OPTION_STEP) && (
                            <Row>
                                <Col sm={12}>
                                    <Label> {t("forms.step")}</Label>
                                    <Input
                                        type="number"
                                        defaultValue={ field.step }
                                        onChange={ e => setOption('step', e.target.value)}
                                    />
                                </Col>
                            </Row>
                        )}
                        { field.handleOption(FormLayout.OPTION_WITH_TIME) && (
                            <Row className="toggleRow">
                                <Col sm={12}>
                                    <Label> {t("forms.with_time")}</Label>
                                    <Checkbox
                                        toggle
                                        checked={ !!field.with_time }
                                        onChange={ (e, { checked }) => { setOption('with_time', !!checked) }}
                                    />
                                </Col>
                            </Row>
                        )}
                        { field.handleOption(FormLayout.OPTION_EXTENSIONS) && (
                            <Row>
                                <Col sm={12}>
                                    <Label> {t("forms.extensions")}</Label>
                                    <Input
                                        type="text"
                                        placeholder="pdf,txt,docx"
                                        defaultValue={ field.extensions?.join(',') }
                                        onChange={ e => setOption('extensions', e.target.value.split(',').map(e => e?.trim()))}
                                    />
                                </Col>
                            </Row>
                        )}
                        { field.handleOption(FormLayout.OPTION_EXPANDED) && (
                            <Row className="toggleRow">
                                <Col sm={12}>
                                    <Label> {t("forms.expanded")}</Label>
                                    <Checkbox
                                        toggle
                                        checked={ !!field.expanded }
                                        onChange={ (e, { checked }) => { setOption('expanded', !!checked) }}
                                    />
                                </Col>
                            </Row>
                        )}
                        { field.handleOption(FormLayout.OPTION_MULTIPLE) && (
                            <Row className="toggleRow">
                                <Col sm={12}>
                                    <Label> {t("forms.multiple")}</Label>
                                    <Checkbox
                                        toggle
                                        checked={ !!field.multiple }
                                        onChange={ (e, { checked }) => { setOption('multiple', !!checked) }}
                                    />
                                </Col>
                            </Row>
                        )}
                        { field.handleOption(FormLayout.OPTION_CHOICES) && (
                            <Row>
                                <Col sm={12}>
                                    <Label> {t("forms.choices")}</Label>
                                    <ChoicesField
                                        choices={ field.choices || [] }
                                        onAdd={ addChoice }
                                        onEdit={ editChoice }
                                        onRemove={ removeChoice }
                                    />
                                </Col>
                            </Row>
                        )}
                    </CardText>
                )}

                <CardFooter>
                    <Row>
                        <Col className={"text-" + (isMobile ? "center m-1": "left")}>
                            <ButtonGroup>
                                <Button type="button" color="danger" onClick = { () => onDelete() }>
                                    <span>
                                        <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                        <Col className={"text-" + (isMobile ? "center m-1": "right")}>
                            <ButtonGroup>
                                <Button type="button" color="info" onClick = { () => onDuplicate() }>
                                    <span>
                                        <i className="fa fa-copy"></i>&nbsp;{t("common.duplicate")}
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </CardFooter>
            </div>
        </div>
    );
}

export default FormFieldOptions;
