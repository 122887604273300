import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Utils } from '../../../services';
import "./MainDashboard.css";
import widgetsList from "../WidgetsList.js";
import _ from "lodash";

const MainDashboard = ({ board, width }) => {
    const default_lang = Utils.default_lang();

    //Normal display of widgets
    const mainWidgetsHtml = () => {
        let temp = [[]];

        //Display widgets board loop
        for (let i = 0; i < board.length; i++) {
            temp.push(
                <Col
                    sm={12}
                    md={board[i][1]?.width ? board[i][1].width * width: width}
                    key={i}
                >
                    {React.createElement(widgetsList[board[i][0]].component, { props: board[i][1], key:{i} })}
                </Col>
            )
        }
        let tempWithRow = <Row>{temp}</Row>
        return tempWithRow;
    }

    const [html, setHtml] = useState(mainWidgetsHtml());

    return (
        <div className="MainDashboard">
            {html}
        </div>
    )
}

export default MainDashboard;
