import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import LayoutsList from './LayoutsList/LayoutsList';

const EmailLayouts = ({ match }) => {
    const { t }                                               = useTranslation();
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.layouts);

    return (
        <div className="EmailLayouts">
            {isLoading &&
                <span className="loadingMessage">{t('common.loading')}</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{ __html: loadingErrorMessage }} />
            }
            <Route exact path={match.path} component={LayoutsList} />
            <Route exact path={ `${ match.url }/edit/:layoutId` } component={LayoutsList} />
        </div>
    );
}

export default EmailLayouts;
