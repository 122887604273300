import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormLayout, Sector, Phase } from '../../../../models';
import { Row, Col, FormGroup, Label } from 'reactstrap';
import { getApplicationsLinkedResources, loadApplicationForm, createApplication } from '../../../../redux';
import { Loader, FormRenderer, SectorsField, AvailabilitiesField } from '../../../../components';
import moment from 'moment';

import './ApplicationForm.css';

const ApplicationForm = props => {
    const dispatch              = useDispatch();
    const { t, i18n }           = useTranslation();
    const { tkt_instance_id }   = props;
    const { form, isLoading }   = useSelector(state => state.applicationTypes);
    const { applicationTypeId } = useParams();

    const [ error, setError ]  = useState(undefined);

    const {
        application, isLoadingOne,
        linkedResources, loadingErrorMessage
    } = useSelector(state => state.applications);

    useEffect(() => {
        if (applicationTypeId && tkt_instance_id) {
            dispatch(getApplicationsLinkedResources(['activities', 'sectors'], { tkt_instance_id }));
            dispatch(loadApplicationForm(
                applicationTypeId,
                tkt_instance_id
            ));
        }
    }, [tkt_instance_id]);

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    const submit = (values) => {
        if (!values.sectors?.length)
            return setError(t('applications.please_choose_sector'));

        if (!values.availabilities?.length)
            return setError(t('applications.please_set_availabilities'));
        setError(undefined);

        return dispatch(createApplication({
            ...values,
            type_id: applicationTypeId,
        }, {
            tkt_instance: tkt_instance_id
        }));
    }

    const preFields  = React.useMemo(() => <></>, []);
    const postFields = React.useMemo(() => (
        <Col sm={12} md={12} >
            <hr />
            <Row>
                <Col sm={12} md={12} >
                    { error && (
                        <div className="bg-danger text-white rounded my-2 p-2">
                            { error }
                        </div>
                    )}
                    <FormGroup row>
                        <Col sm={12} className="text-left font-weight-bold">
                            <Label className="required">
                                { t('sectors.sectors_description') }
                            </Label>
                        </Col>
                        <Col sm={12}>
                            <SectorsField
                                sectors={ Sector.buildTree(linkedResources?.sectors || [], i18n.language)}
                                preventSubmit
                                name="sectors"
                            />
                        </Col>
                    </FormGroup>
                    <hr />
                    {linkedResources?.phases?.length > 0 && (
                        <FormGroup row>
                            <Col sm={12} className="text-left font-weight-bold">
                                <Label>
                                    { t('availabilities.phases_description') }
                                </Label>
                            </Col>
                            <Col sm={12}>
                                <dl className="text-center">
                                { linkedResources.phases.map(phase => new Phase(phase)).map(phase => (
                                    <Fragment key={phase.id}>
                                        <dt>{ phase.name.fr }</dt>
                                        <dd>
                                            {t('phases.from_to', {
                                                from: phase.start_at.format('LLL'),
                                                to: phase.stop_at.format('LLL')
                                            })}
                                        </dd>
                                    </Fragment>
                                ))}
                                </dl>
                            </Col>
                        </FormGroup>
                    )}
                    <FormGroup row>
                        <Col sm={12} className="text-left font-weight-bold">
                            <Label className="required">
                                { t('availabilities.availabilities_description') }
                            </Label>
                        </Col>
                        <Col sm={12}>
                            <AvailabilitiesField
                                preventSubmit
                                name="availabilities"
                                phases={ linkedResources?.phases }
                            />
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        </Col>
    ), [ error, linkedResources, i18n.language ]);

    if (isLoading)
        return (
            <div className="loader-wrapper center light">
                <Loader color="#454545" />
            </div>
        );

    if (!form)
        return null;

    return (
        <div className="ApplicationForm">
            <div className="wrapper">
                <div className="container border rounded p-3 bg-white">
                    {loadingErrorMessage && (
                        <div className="alert alert-danger">
                            <span dangerouslySetInnerHTML={{__html: loadingErrorMessage}}></span>
                        </div>
                    )}
                    {application ? (
                        <div className="success-message">
                            { t('applications.successfully_submitted') }
                        </div>
                    ) : (
                        <FormRenderer
                            formLayout={ form }
                            onSubmit={ submit }
                            isLoading={ isLoadingOne }
                            preFields={ preFields }
                            postFields={ postFields }
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default ApplicationForm;
