import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment';

import "./StatsHeader.css"

export const StatsHeader = ({filters, title}) => {
    const {t} = useTranslation();

    return (
        <div className="StatsHeader onlyPrint pb-3">
            {title}
            <p>
                {filters.cinema === null ?
                    t("statstypes.form.all_cinemas") :
                    filters.cinema
                }
                &nbsp;/&nbsp;
                {filters.room === null ?
                    t("statstypes.form.all_halls") :
                    filters.room
                }
                &nbsp;{t("statstypes.form.from")}&nbsp;
                {moment(filters.start_date).format("LLL")}
                &nbsp;{t("statstypes.form.to")}&nbsp;
                {moment(filters.end_date).format("LLL")}
            </p>
        </div>
    )
}

export default StatsHeader;
