import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import { Setting } from '../../../models';
import ItemsList from '../../../components/ItemsList/ItemsList';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import './SettingsBrowser.css';

const SettingsBrowser = ({ setting, active, onSelect }) => {
    const { t }    = useTranslation();
    const { user } = useSelector(state => state.auth)

    if (!setting)
        return null;

    const items = [];

    _.sortBy(
        Object.keys(setting).filter(key => {
            if (['_id', 'created_at', 'updated_at', '_version'].includes(key))
                return false;

            const { role  } = Setting.propertyForm(t, setting, key);
            return (user?.isSuperAdmin() || (!role && user.can('editSelf', 'settings')))
        }),
        key => {
            const { order } = Setting.propertyForm(t, setting, key);
            return order;
        }
    ).map(key => {
        items.push({
            key: key,
            title: t(`settings.${key}.title`),
            icon: Setting.propertyIcon(key, 'caret-right'),
            active: active && (active && (active === key)),
            depth: 0,
            selection: key
        });
    });

    return (
        <div className="SettingsBrowser">
            <ItemsList
                horizontal
                items={ items }
                onSelect={ onSelect }
            />
        </div>
    );
}

export default SettingsBrowser;
