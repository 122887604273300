import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './DeliveryTypesField.css';

const DeliveryTypesField = ({ name, types, onChange, fixedWidth, single }) => {
    const { t } = useTranslation();

    if (!types)
        return null;

    const options = types.map(type => {
        return {
            key: type._id,
            text: Utils.localized_or_fallback(type.name, i18n.language),
            value: type._id
        };
    });

    const onFieldChange = (value) => {
        return onChange ? onChange(value) : false;
    }

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            onSelectChange={ onFieldChange }
            fixedWidth={ fixedWidth }
            clearable
        />
    ) : (
        <MultiSelectField
            name={ name }
            options={ options }
            onChange={ onFieldChange }
            fixedWidth={ fixedWidth }
        />
    );
}

export default DeliveryTypesField;
