import BaseModel from './BaseModel';

/** Class representing an broadcast channel. */
class Channel extends BaseModel {}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
Channel.getListingFields = () => [];

export default Channel;
