import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { Field } from 'redux-form';
import { useTranslation } from 'react-i18next';
import SpecialSelectField from './SelectField/SpecialSelectField';

import './VideoField.css'

const VideoField = ({ inputName, selectName, pristine, submitting, isLoading }) => {
    const { t }                         = useTranslation();
    const { isMobile }                  = useSelector(state => state.ui);
    const [selectValue, setSelectValue] = useState();

    const onChange = (val) => {
        setSelectValue(val);
    }

    return (
        <div className="VideoField">
            {isMobile ?
                <>
                    <Field component={SpecialSelectField} name={selectName} onSelectChange={(v) => onChange(v)} />
                    <Field component="input" type="text" className="form-control mt-4" name={inputName} placeholder={t(`events.new_trailer_${selectValue}_placeholder`)} />
                    <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoading} className='w-100 mt-4'>
                        {isLoading ?
                            <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                            :
                            <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                        }
                    </Button>
                </>
                :
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                        <Field component={SpecialSelectField} name={selectName} onSelectChange={(v) => onChange(v)} />
                    </InputGroupAddon>
                    <Field component="input" type="text" className="form-control" name={inputName} placeholder={t(`events.new_trailer_${selectValue}_placeholder`)} />
                    <InputGroupAddon addonType='append'>
                        <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                :
                                <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                            }
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            }
        </div>
    )
}

export default VideoField;