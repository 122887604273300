import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import CustomerForm from '../CustomerForm/CustomerForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { startCreateCustomer, createCustomer } from '../../../redux/modules/customers';

class CustomerCreate extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        // Reset uer in app state
        this.props.dispatchStartCreateCustomer();
    }

    submit(values) {
        this.props.dispatchCreateCustomer(values);
    }

    render() {
        const { t } = this.props;
        const { customer: { customerSuccessfullyCreated }} = this.props.customers;

        let title = <span>Ajout d'un client</span>;

        const actions =
            <Button tag={Link} to="/customers" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
            </Button>

        return (
            <div className="CustomerCreate">
                <PageTitle icon="users" title={ title } actions={ actions } />

                {customerSuccessfullyCreated &&
                    <Redirect to="/customers"/>
                }

                <CustomerForm onSubmit={this.submit} isCreation="true" />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateCustomer: (customer) => startCreateCustomer(customer),
    dispatchCreateCustomer: (customer) => createCustomer(customer)
}

const mapStateToProps = state => ({
    customers: state.customers,
    form: state.form
})

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(CustomerCreate));
