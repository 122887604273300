import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { connect, disconnect, receivedMsg } from '../../redux';

const SioConnector = ({apiKey, serverUrl, children}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(connect(apiKey, serverUrl, {
            onConnect:     () => {  },
            onDisconnect:  () => { dispatch(disconnect()) },
            onReceivedMsg: (msg) => { dispatch(receivedMsg(msg)); }
        }))
    }, [dispatch]);

    return children;
}

export default SioConnector;
