import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Redirect, Link } from "react-router-dom";
import { Button } from 'reactstrap';

import ScreeningForm from '../ScreeningForm/ScreeningForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { startCreateScreening, createScreening } from '../../../redux/modules/screenings';
import { loadPlaces } from '../../../redux';

const ScreeningCreate = () => {
    const { t }                            = useTranslation();
    const dispatch                         = useDispatch();
    const { screeningSuccessfullyCreated, screening } = useSelector(state => state.screenings);
    const { places }                                  = useSelector(state => state.places);

    useEffect(() => {
        dispatch(startCreateScreening());
        dispatch(loadPlaces());
    }, [])

    const title = <span>{t("screenings.add")}</span>
    const actions =
        <Button tag={Link} to="/screenings" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    const cinemaHallProperties = (_id) => {
        const place = places.find(p => p._id === _id);

        return {
            "_id": place._id,
            "cinema": place.name,
            "name": place.name,
            "city": place.city,
            "opaque": { "capacity": place.opaque.capacity }
        }
    }

    const submit = values => {
        const payload = {};
        Object.keys(values).map(k => {
            switch (k) {
                case 'title':
                    payload[k] = values[k];
                    payload['films'] = [{ "title": values[k]}];
                    break;
                case 'cinema_hall':
                    payload[k] = cinemaHallProperties(values[k]);
                    break;
                case '_id':
                case 'description':
                case 'start_at':
                case 'stop_at':
                case 'opaque':
                case 'note':
                    payload[k] = values[k];
                break;
            }
        })
        dispatch(createScreening(payload));
    };

    return (
        <div className="ScreeningsCreate">
            <PageTitle icon="television" title={title} actions={actions}/>
            {(screeningSuccessfullyCreated && screening) &&
                <Redirect to={`/screenings/edit/${screening._id}`} />
            }
            <ScreeningForm screening={screening} isCreation={true} onSubmit={(values) => submit(values)} />
        </div>
    );
}

export default ScreeningCreate;
