import store from '../redux';

const getUser = () => {
    return store.getState().auth.user;
}

export const isLoggedIn = () => {
    let user = getUser();
    return (user && ('id' in user));
}

export const hasModule = (module) => {
    console.warn('DEPRECATED Abilities.hasModule is deprecated, please use TktInstance.hasModule() instead');
    const tkt_instance = getUser().tkt_instance;

    return tkt_instance && tkt_instance.options.modules &&
           tkt_instance.options.modules.includes(module);
}
