import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form'
import { loadLayouts, addEmailLayout, removeEmailLayout } from '../../redux';
import { Utils } from '../../services';
import { Row, Col, Card, CardBody, CardText, CardTitle, Button, InputGroup, InputGroupAddon } from "reactstrap";
import { PricingsField } from '..';

import './EmailLayoutMappings.css';

const EmailLayoutMappings = ({ name, id, pricingName }) => {
    const [currentPricingId, setCurrentPricingId] = useState(null);

    const { layouts }               = useSelector(state => state.layouts);
    const { tickettype, isLoading } = useSelector(state => state.tickettypes);

    const dispatch    = useDispatch();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        dispatch(loadLayouts());
    }, []);

    const hooks = ['creation', 'activation'];

    const onRemoveLayout = id => {
        dispatch(removeEmailLayout(id));
    }

    const sortedLayouts = React.useMemo(() => {
        return layouts?.filter(l => l.layout_type === 'email')
            .sort((a, b) => Utils.localized_or_fallback(a.name, i18n.language).localeCompare(Utils.localized_or_fallback(b.name, i18n.language)));
    }, [ layouts ]);

    const pricingsToAdd = React.useMemo(() => {
        if (!Object.keys(tickettype?.pricings || {}).length)
            return [];

        return Object.keys(tickettype?.pricings)
            .filter(pricing => !(pricing in (tickettype?.opaque?.emails || {})))
            .map(pricing => ({
                id: pricing,
                name: tickettype.pricings[pricing].name
            }));
    }, [tickettype])

    const addEmail = () => {
        if (!currentPricingId)
            return;

        dispatch(addEmailLayout(currentPricingId))
    }

    return (
        <div className="EmailLayoutMappings">
            <Row>
                <Col>
                    <InputGroup className='mt-4 w-50'>
                        <PricingsField pricings={pricingsToAdd || []} onChange={ setCurrentPricingId } />
                        <InputGroupAddon addonType="append">
                            <Button color="success" disabled={!currentPricingId || isLoading} onClick={addEmail} >
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-plus"></i>&nbsp;
                                        {t('layouts.add_pricing_emails')}
                                    </span>
                                }
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>
            {Object.keys(tickettype?.opaque?.emails || {}).filter(k => k !== 'default').map(pricingId =>
                <Row key={pricingId} className="mt-2">
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    {t('layouts.on_pricing', { pricing: Utils.localized_or_fallback(tickettype.pricings[pricingId]?.name, i18n.language)})}
                                    <Button type="button" className="float-right" color="danger" onClick={() => onRemoveLayout(pricingId)}>
                                        <i className="fa fa-times"></i>
                                    </Button>
                                </CardTitle>
                                <Row>
                                    {hooks?.map(hook => (
                                        <Col key={hook} className="PdfLayoutMapping" sm={6}>
                                            <Card className="h-auto inner-card">
                                                <CardBody>
                                                    <CardTitle className="small">
                                                        {t(`layouts.hook_${hook}`)}
                                                    </CardTitle>
                                                    <CardText tag="div">
                                                        <Field component="select" name={`opaque.emails.${pricingId}.${hook}`} className="form-control">
                                                            <option value="">{t('layouts.please_choose_a_layout')}</option>
                                                            {sortedLayouts?.map(layout => (
                                                                <option key={layout._id} value={layout._id}>{layout.name[i18n.language]}</option>
                                                            ))}
                                                        </Field>
                                                    </CardText>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            <Card>
                <CardBody>
                    <CardTitle>
                        { Object.keys(tickettype?.opaque?.emails || {}).length > 1 ?t('layouts.on_other_pricings') : t('layouts.on_any_pricing')}
                    </CardTitle>
                    <Row>
                        {hooks?.map(hook => (
                            <Col key={hook} className="PdfLayoutMapping" sm={6}>
                                <Card className="h-auto inner-card">
                                    <CardBody>
                                        <CardTitle className="small">
                                            {t(`layouts.hook_${hook}`)}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <Field component="select" name={`${name}.${hook}`} className="form-control">
                                                <option value="">{t('layouts.please_choose_a_layout')}</option>
                                                {sortedLayouts?.map(layout => (
                                                    <option key={layout._id} value={layout._id}>{layout.name[i18n.language]}</option>
                                                ))}
                                            </Field>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </CardBody>
            </Card>
        </div >
    );
}

export default EmailLayoutMappings;
