import React from "react";
import PdfLayout from '../PdfLayout';
import CustomTextWidget from './CustomTextWidget';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

/**
 * Class representing a PDF layout widget showing
 * an information about the ticket owner.
 */
class OwnerInfoWidget extends CustomTextWidget {

    constructor(properties) {
        super({
            ...(OwnerInfoWidget.getDefaultOptions()),
            ...properties,
        });

        this.type    = PdfLayout.TEXT;
        this.subType = PdfLayout.OWNER_INFO;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 5, height: 0.75 },
            transform: { rotation: 0 },
            options: {
                field: 'fullname',
                style: {
                    color: '#000000',
                    fontFamily: "Helvetica",
                    fontSize: 14,
                    lineHeight: 1,
                    textAlign: 'left',
                    textDecoration: 'none',
                    backgroundColor: '#FFFFFF00',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        const handledOptions = super.getHandledOptions().concat([
            PdfLayout.OPTION_CONTACT_FIELD
        ]).filter(o => o !== PdfLayout.OPTION_TEXT);

        if (this.options?.field === 'birthdate') {
            handledOptions.push(PdfLayout.OPTION_DATE_FORMAT);
            handledOptions.push(PdfLayout.OPTION_DATE_OFFSET);
        }

        return handledOptions;
    }

    /**
     * Return this widget preview text as string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreviewText(props) {
        const {t} = useTranslation();

        switch (this.options.field) {
            case 'title':
                return t('models.owner_info.title');
            case 'company':
                return t('models.owner_info.company');
            case 'firstname':
                return t('models.owner_info.firstname');
            case 'lastname':
                return t('models.owner_info.lastname');
            case 'fullname':
                return t('models.owner_info.fullname');
            case 'fullname-reversed':
                return t('models.owner_info.fullname_reversed');
            case 'email':
                return t('models.owner_info.email');
            case 'age':
                return t('models.owner_info.age');
            case 'birthdate':
                return this.options?.date_format ? moment('01/01/1970').locale( props?.lang || 'fr').format(this.options.date_format) : '01/12/1970';
            case 'sex':
                return t('models.owner_info.sex');
            case 'phone':
                return t('models.owner_info.phone');
            case 'cellphone':
                return t('models.owner_info.cellphone');
            case 'language':
                return t('models.owner_info.language');
            case 'address.street':
                return t('models.owner_info.address.street');
            case 'address.city':
                return t('models.owner_info.address.city');
            case 'address.zip':
                return t('models.owner_info.address.zip');
            case 'address.country':
                return t('models.owner_info.address.country');
            default:
                return '...';
        }
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'user';
    }
}

export default OwnerInfoWidget;
