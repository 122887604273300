import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import SalepointsList from './SalepointsList/SalepointsList';
import SalepointShow from './SalepointShow/SalepointShow';
import SalepointEdit from './SalepointEdit/SalepointEdit';
import SalepointCreate from './SalepointCreate/SalepointCreate';
import SalepointDelete from './SalepointDelete/SalepointDelete';

class Salepoints extends Component<{}> {
    render() {
        const { match }     = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.salepoints;
        return (
            <div className="Salepoints">

                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={SalepointsList} />
                <Route exact path={ `${ match.url }/new` } component={SalepointCreate} />
                <Route exact path={ `${ match.url }/show/:salepointId` } component={SalepointShow} />
                <Route exact path={ `${ match.url }/edit/:salepointId` } component={SalepointEdit} />
                <Route exact path={ `${ match.url }/delete/:salepointId` } component={SalepointDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    salepoints: state.salepoints
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Salepoints));
