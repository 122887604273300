import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import _ from 'lodash';
import { arrayMoveMutable } from 'array-move';
import BucketForm from './../BucketForm';
import DraggableComponent from '../Draggable';
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import { Utils } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { setSmallDraggableCards } from '../../redux';

import './BucketsForm.css';

const BucketsForm = ({ buckets, remove, updateBucketOrder, isLoading }) => {
    const dispatch = useDispatch();
    const { t }                   = useTranslation()
    const [collapse, setCollapse] = useState([]);
    const currency                = Utils.currency();

    const { KUserSettings } = useSelector(state => state.userSettings);
    const smallCards        = !!KUserSettings.smallDraggableCards;

    const onCollapse = (id) => {
        let newValues = collapse;
        newValues[id] = !newValues[id];
        setCollapse(newValues);
    }

    const setUseSmallCards = useSmallCards => {
        dispatch(setSmallDraggableCards(!!useSmallCards));
    };

    const removeBucketAtKey = (key, id) => {
        let temp = _.cloneDeep(collapse)
        temp.splice(id, 1);
        setCollapse(temp);
        remove(key);
    }

    const sortedBuckets = useMemo(() => buckets, [buckets]);

    useEffect(() => {
        setCollapse(Object.keys(buckets).map(p => { return false }));
    }, []);

    const buildPreview = bucketsArray => {
        const DeleteActions = (key, id) => {
            return (
                <Button color="danger" size="sm" onClick={() => { removeBucketAtKey(key, id); }}>
                    <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                </Button>
            );
        };

        const SortableTab = SortableElement(({ bucket, idx, sortIndex }) => {
            return (
                <Col sm={smallCards ? 6 : 12} lg={smallCards ? 3 : 6} key={bucket._id} className="mb-4" >
                    <DraggableComponent
                        id={idx}
                        onCollapse={onCollapse}
                        collapsed={collapse[idx]}
                        cardbody={
                            <BucketForm
                                bucket={bucket}
                                smallCard={!smallCards}
                                isLoading={isLoading}
                                bucketId={bucket._id}
                                bucketIndex={sortIndex}
                                bucketIds={buckets.map(b => b._id)}
                            />
                        }
                        title={
                            <>
                                <div>
                                    <b>{bucket._id}</b>
                                </div>
                                <i>
                                    <small> {t('bucketlists.bucket.capacity')} {bucket.total_capacity}</small>
                                </i>
                            </>
                        }
                        actions={DeleteActions(bucket._id, idx)}
                    />
                </Col>)
        });

        const SortableGrid = SortableContainer(({ bucketsArray }) => {
            return (
                <Row className="sortable-grid">
                    {(bucketsArray || []).map((bucket, index) => {
                        return <SortableTab key={`item-${index}`} index={index} idx={index} bucket={bucket} sortIndex={index} />;
                    })}
                </Row>
            );
        });

        const onSortEnd = ({ oldIndex, newIndex }) => {
            let buckets = [...sortedBuckets];
            let newCollapse = collapse;
            arrayMoveMutable(newCollapse, oldIndex, newIndex);
            arrayMoveMutable(buckets, oldIndex, newIndex);
            updateBucketOrder(buckets);
            setCollapse(newCollapse);
        };

        return <SortableGrid
            bucketsArray={bucketsArray}
            axis="xy"
            onSortEnd={onSortEnd}
            useDragHandle={true}
            useWindowAsScrollContainer
            helperClass="sortableHelper"
        />
    };

    const bucketComponent = useMemo(() => {
        return buildPreview(sortedBuckets)
    }, [sortedBuckets, smallCards])

    return (
        <div className="BucketsForm">
            <Row>
                <Col>
                    <ButtonGroup className="float-right">
                        <Button style={{height: "33.5px"}} color={smallCards ? 'primary' : 'dark'} onClick={() => setUseSmallCards(true)}>
                            <i className='fa fa-th'></i>
                        </Button>
                        <Button style={{height: "33.5px"}} color={!smallCards ? 'primary' : 'dark'} onClick={() => setUseSmallCards(false)}>
                            <i className='fa fa-th-large'></i>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            {sortedBuckets.length > 0 &&
                bucketComponent
            }
        </div>
    );
}

export default BucketsForm;
