import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import SelectField from '../SelectField/SelectField';

import './SexField.css';

const SexField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.sexFields().map(sex => {
        return {
            key: sex,
            text: t(`common.sex.${sex}`),
            value: sex,
        };
    });

    return (
        <SelectField
            name={ name }
            options={ options }
            clearable
        />
    );
}

export default SexField;
