import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadArticlecategory, deleteArticlecategory } from '../../../redux/modules/articlecategories';

class ArticlecategoryDelete extends Component<{}> {
    componentDidMount() {
        const articlecategoryId = this.props.match.params.articlecategoryId;
        // Load articlecategories on component mount
        this.props.dispatchLoadArticlecategory(articlecategoryId);
    }

    deleteArticlecategory(articlecategoryId) {
        this.props.dispatchDeleteArticlecategory(articlecategoryId);
    }

    render() {
        const { articlecategory, isLoading, articlecategorySuccessfullyDeleted } = this.props.articlecategories;
        const { t } = this.props;

        const default_lang = Utils.default_lang();

        let title = <span>{t("bulidings.articlecategory_plural")}</span>;
        if (articlecategory)
            title = (
                <span>
                    {t("articlecategories.articlecategory")}
                    <small> | { articlecategory.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/articlecategories" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="ArticlecategoryDelete">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {articlecategorySuccessfullyDeleted &&
                    <Redirect to="/articlecategories"/>
                }

                {articlecategory &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('articlecategories.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("articlecategories.name")}</b>: { articlecategory.name[default_lang] }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/articlecategories" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteArticlecategory(articlecategory._id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadArticlecategory: (articlecategoryId) => loadArticlecategory(articlecategoryId),
    dispatchDeleteArticlecategory: (articlecategoryId) => deleteArticlecategory(articlecategoryId)
}

const mapStateToProps = state => ({
    articlecategories: state.articlecategories
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ArticlecategoryDelete))
