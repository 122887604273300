import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import PhaseForm from '../PhaseForm/PhaseForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { startCreatePhase, createPhase } from '../../../redux/modules/phases';

const PhaseCreate = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startCreatePhase());
    }, [dispatch])

    const submit = (values) => {
        dispatch(createPhase(values));
    }

    const { phaseSuccessfullyCreated } = useSelector(state => state.phases);

    let title = <span>{t('phases.add')}</span>;

    const actions =
        <Button tag={Link} to="/phases" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="PhaseCreate">
            <PageTitle icon="arrows-h" title={ title } actions={ actions } />

            {phaseSuccessfullyCreated &&
                <Redirect to="/phases"/>
            }

            <PhaseForm onSubmit={ submit } isCreation="true" />
        </div>
    );
}

export default PhaseCreate
