import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { Utils } from '../../../services';
import PageTitle from '../../../components/PageTitle/PageTitle';
import PricingsForm from '../../../components/PricingsForm/PricingsForm';
import { addPricing, removePricing, updatePricinglistPricings, updatePricingsKeys } from '../../../redux/modules/pricinglists';
import HelpTooltips from '../../../components/HelpTooltips/HelpTooltips';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

let PricinglistForm = ({ isCreation, handleSubmit, submitting, pricinglist }) => {
    const { t }         = useTranslation();
    const dispatch      = useDispatch();
    const { isLoading } = useSelector(state => state.pricinglists);

    const updatePricingOrder = (pricings) => {
        const newPricings = _.cloneDeep(pricinglist.pricings);
        pricings.forEach((pricing, index) => {
            newPricings[pricing.key].opaque.eshop_sort_weight = index;
        });
        dispatch(updatePricinglistPricings(newPricings));
    }

    const add = () => {
        let names = {};
        Utils.langs().map(l => names[l] = t("pricinglists.new_pricing", { lng: l }))
        dispatch(addPricing(names, Utils.currency()));
    }

    const remove = (_id) => {
        dispatch(removePricing(_id));
    }

    const changeId = (values) => {
        dispatch(updatePricingsKeys(values))
    }

    const showPricingsForm = React.useMemo(() => {
        return <PricingsForm
                    pricings={(pricinglist || {}).pricings}
                    remove={remove}
                    updatePricingOrder={updatePricingOrder}
                    changeId={changeId}
                />;
    }, [pricinglist]);

    const currency = Utils.currency()

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("pricinglists.informations")}</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row >
                                            <Label for="name" sm={3}>{t("pricinglists.name")}</Label>
                                            <Col sm={9}>
                                                <Field data-tip data-for='nametip' required component="input" className="form-control" type="text" name="name" id="name" autoComplete="name" placeholder={t("pricinglists.name_placeholder")} />
                                                <HelpTooltips id='nameTip' value='name' />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row>
                                            <Label for="_id" sm={3} className="text-right" >{t("pricinglists._id")}</Label>
                                            <Col sm={9} data-tip data-for='tktIdTip'>
                                                <Field 
                                                    required
                                                    component="input"
                                                    className="form-control"
                                                    type="text"
                                                    name="_id"
                                                    id="_id"
                                                    autoComplete="_id"
                                                    disabled={!isCreation}
                                                    placeholder={t("pricinglists._id")}
                                                    onInvalid={e => e.target.setCustomValidity(t("common.invalid_id_input"))}
                                                    onInput={e => e.target.setCustomValidity(t(""))}
                                                    pattern={isCreation ? "[A-Za-z0-9_]+" : ".+"}
                                                />
                                            </Col>
                                            <HelpTooltips id='tktIdTip' value='tkt_id' />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {(!isCreation) &&
                <PageTitle tag="h5" icon="usd" title={t('pricinglists.pricings')} />
            }
            {(!isCreation && pricinglist?.pricings) &&
                showPricingsForm
            }
            <Row>
                <Col className="text-center">
                    <br />
                    <ButtonGroup>
                        {!isCreation &&
                        <Button color="info" disabled={submitting || isLoading} onClick={add}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-plus"></i>&nbsp;
                                    {t("pricinglists.add_pricing")}
                                </span>
                            }
                        </Button>
                        }
                        <Button type="submit" color="success" disabled={submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PricinglistForm = reduxForm({
    form: 'pricinglistForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: false
})(PricinglistForm)

// You have to connect() to any reducers that you wish to connect to yourself
PricinglistForm = connect(
    state => ({
        initialValues: state.pricinglists.pricinglist, // pull initial values from pricinglists reducer
        pricinglists: state.pricinglists,
    }), {}
)(PricinglistForm)

export default PricinglistForm;
