import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from '../../locales/i18n';

import './ChannelsField.css';

const ChannelsField = ({ name, channels, onChange, fixedWidth, single }) => {
    const { t } = useTranslation();

    const options = channels.map(channel => {
        return {
            key: channel.id,
            text: channel.name,
            value: channel.id
        };
    });

    const onFieldChange = (value) => {
        return onChange ? onChange(value) : false;
    }

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            placeholder={ t('channels.choose_a_channel') }
            onSelectChange={ onFieldChange }
            fixedWidth={ fixedWidth }
            clearable
        />
    ) : (
        <MultiSelectField
            name={ name }
            options={ options }
            placeholder={ t('channels.choose_a_channel') }
            onChange={ onFieldChange }
            fixedWidth={ fixedWidth }
        />
    );
}

export default ChannelsField;
