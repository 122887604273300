import RestClient from './RestClient';
import { Setting } from '../../models';

class Settings extends RestClient {
    constructor() {
        super({
            "model": Setting,
            "entryPoint": "settings",
            "resource": "setting",
            "resources": "settings",
            "id_field": "_id"
        });
    }
}

const SettingsApi = new Settings();

export default SettingsApi;
