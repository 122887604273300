import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Badge, Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiCogTransferOutline } from '@mdi/js';
import collectorImage from '../../assets/images/collector.png';

import './CollectorResume.css';

const CollectorResume = ({ collector, hideDetails, className, ...rest }) => {
    const { t } = useTranslation();
    const { connected, status } = collector.state;
    return (
        <div className={ `CollectorResume ${className}` } { ...rest }>
            <Card className="card-outline">
                { !hideDetails && (
                <CardHeader>
                    <CardTitle tag="h6">
                        { collector.name }
                    </CardTitle>
                </CardHeader>
                )}
                <CardImg
                    top
                    width="100%"
                    src={ collectorImage }
                    alt="Collector image cap"
                    className={ connected ? 'connected' : 'disconnected' }
                />
                { !hideDetails && (
                <CardBody>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">
                        <Badge color={ connected ? 'success' : 'danger' }>
                            { t(`collectors.${connected ? 'connected' : 'disconnected'}`) }<br/>
                        </Badge>
                        { connected && (
                        <Badge className="ml-2" color={ 'info' }>
                            { t(`collectors.statuses.${status}`) }<br/>
                        </Badge>
                        )}
                    </CardSubtitle>
                    <Button tag={ Link } to={`/devices/collectors/manage/${collector.id}`} color={ 'info' } block>
                        <Icon path={ mdiCogTransferOutline } color="white" size={ 1 } />
                        { t('collectors.manage') }
                    </Button>
                </CardBody>
                )}
            </Card>
        </div>
    );
}

export default CollectorResume;
