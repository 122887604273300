import RestClient from './RestClient';
import { Salepoint } from '../../models';

class Salepoints extends RestClient {
    constructor() {
        super({
            "model": Salepoint,
            "entryPoint": "salepoint",
            "resource": "salepoint",
            "resources": "salepoints",
            "id_field": "_id"
        });
    }
}

const SalepointsApi = new Salepoints();

export default SalepointsApi;
