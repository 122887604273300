import RestClient from './RestClient';
import { Customer } from '../../models';

class Customers extends RestClient {
    constructor() {
        super({
            "model": Customer,
            "entryPoint": "customers",
            "resource": "customer",
            "resources": "customers",
            "id_field": "_id"
        });
    }
    list() {
        return this.GET('/customers/list')
            .then(response => response.json())
            .then(json => {
                if (json.customers)
                    return {
                        ...json,
                        customers: json.customers.map(c => new Customer(c))
                    };
            throw new Error(json.flash.error);
        });
    }
}
const CustomersApi = new Customers();

export default CustomersApi;
