import moment from 'moment';
import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Badge } from 'reactstrap';
import { loadTickettypes } from '../../../../redux/modules/tickettypes';
import { Utils, Filters } from '../../../../services';
import i18n from '../../../../locales/i18n';
import { Table } from '../../../../components';

const ScreeningFormLogs = ({ logs, setting }) => {
    const { t }           = useTranslation();
    const { tickettypes } = useSelector(state => state.tickettypes);
    const dispatch        = useDispatch();

    useEffect(() => {
        dispatch(loadTickettypes());
    }, []);

    const selectValues = useMemo(() => {
        const temp = {
            tickettypes: [],
            actions: [],
            by: []
        };
        if (tickettypes && logs) {
            (tickettypes).map(t => {
                if (logs.find(l => l.ticket.type._id === t._id))
                    temp.tickettypes.push({
                        key: t._id,
                        value: t._id,
                        text: Utils.localized_or_fallback(t.name, i18n.language)
                    });
            });
            logs.map(l => {
                if (!temp.actions.find(t => t.key === l.type))
                    temp.actions.push({
                        key: l.type,
                        value: l.type,
                        text: t("screenings.edit.form.logs." + l.type),
                    });
                if (!temp.by.find(t => t.key === l.user.name))
                    temp.by.push({
                        key: l.user.name,
                        value: l.user.name,
                        text: l.user.name,
                    });
            })
        }
        return temp;
    }, [tickettypes, logs])

    const logColumns = useMemo(() => [
        {
            Header: t("tickettypes.tickettype"),
            filterAll: true,
            filterMethod: Filters.textFilterMethod('tickettype'),
            Filter: Filters.selectFilter(selectValues.tickettypes),
            accessor: "tickettype",
            Cell: ((row) => {
                if (tickettypes.length > 0) {
                    return (
                        <u>
                            <a className="link-pass" href={setting?.eshop?.uri + "/ticket/view/?uuid=" + row.original.ticket_id}>
                                <i className="fa fa-ticket"></i>&nbsp;
                                {Utils.localized_or_fallback(tickettypes.find(t => t._id === row.value)?.name, i18n.language)}
                            </a>
                        </u>
                    )}
                return <></>
            })
        },
        {
            Header: t("screenings.edit.form.logs.date"),
            Filter: Filters.dateFilter(false),
            filterMethod: Filters.sameDayDateFilterMethod('date'),
            filterAll: true,
            accessor: "date",
            Cell: (row => moment(row.value).format('lll'))
        },
        {
            Header: t("screenings.edit.form.logs.action"),
            filterAll: true,
            filterMethod: Filters.textFilterMethod('action'),
            Filter: Filters.selectFilter(selectValues.actions),
            accessor: "action",
            Cell: row => `${t("screenings.edit.form.logs." + row.value)} ${row.original.seat}`
        },
        {
            Header: t("screenings.edit.form.logs.by"),
            filterAll: true,
            filterMethod: Filters.textFilterMethod('by'),
            Filter: Filters.selectFilter(selectValues.by),
            className: "align-center-table",
            accessor: "by",
            Cell: (row => <Badge color="success">{row.value}</Badge>)
        },
    ], [logs, tickettypes]);

    const logData = useMemo(() =>
        logs.map((l) => {
            return {
                tickettype: l.ticket.type._id,
                ticket_id: l.ticket._id,
                date: l.created_at,
                action: l.type,
                by: l.user.name,
                seat: l.booking?.seat ?? ""
            }
        }
    ), [logs, setting]);

    return (
        <div className="ScreeningFormLogs">
            {logData && logColumns &&
                <Table
                    data={logData}
                    columns={logColumns}
                    defaultPageSize={logData.length < 20 ? logData.length : 20}
                    showPagination={logData.length > 10}
                    filterable
                    id="logTable"
                    collapseOnDataChange
                />
            }
        </div>
    )
}

export default ScreeningFormLogs;
