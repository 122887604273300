import React, { Component } from 'react';
import { connect } from 'react-redux'
import { FieldArray, Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services';
import { WysiwygField, FileUpload, TranslatableField, CategoriesField, SalepointsField, SortWeightField } from '../../../components';
import ArticlecategoryPosters from './ArticlecategoryPosters/ArticlecategoryPosters';
import classnames from 'classnames';
import { loadArticlecategories } from '../../../redux/modules/articlecategories';
import { loadSalepoints } from '../../../redux/modules/salepoints';

import moment from 'moment'
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

class ArticlecategoryForm extends Component<{}> {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = {
            activeTab: '1'
        };
    }

    componentDidMount() {
        // We do not load all categories (to choose parent)
        // here because it causes some problems (doesn't reset
        // the articlecategory in store). We assume that the user
        // was on the listing page before...
        //this.props.dispatchLoadArticlecategories();
        this.props.dispatchLoadSalepoints();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { salepoints }                                        = this.props.salepoints;
        const { handleSubmit, pristine, submitting, t, isCreation } = this.props;
        const { isLoading, articlecategory, articlecategories } = this.props.articlecategories;

        const default_lang = Utils.default_lang();
        const parent_categories = articlecategories.filter((c) => {
            return !articlecategory || c._id != articlecategory._id;
        });

        return (
                <Form onSubmit={handleSubmit}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                <i className="fa fa-info-circle"></i>&nbsp;
                                { t("articlecategories.informations") }
                            </NavLink>
                        </NavItem>
                        {!isCreation &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                <i className="fa fa-camera"></i>&nbsp;
                                { t("articlecategories.posters") } ({articlecategory.posters && articlecategory.posters.length || 0})
                            </NavLink>
                        </NavItem>
                        }
                    </Nav>
                    <TabContent activeTab={ this.state.activeTab }>
                        <TabPane tabId="1">
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("articlecategories.informations")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="name" sm={3}>{t("articlecategories.name")}</Label>
                                                    <Col sm={9}>
                                                        <TranslatableField name="name" placeholder={t("articlecategories.name_placeholder")} component="input"/>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="description" sm={3}>{t("articlecategories.description")}</Label>
                                                    <Col sm={9}>
                                                    <TranslatableField name="description" placeholder={t("articlecategories.description_placeholder")} component={WysiwygField} />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="name" sm={3}>{t("articlecategories.parent")}</Label>
                                                    <Col sm={9}>
                                                        <CategoriesField name="parent" categories={ parent_categories } />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="name" sm={3}>{t("articles.sort_weight")}</Label>
                                                    <Col sm={9}>
                                                        <SortWeightField />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="name" sm={3}>{t("articlecategories.pos")}</Label>
                                                    <Col sm={9}>
                                                        <SalepointsField name="pos" salepoints={ salepoints } />
                                                    </Col>
                                                </FormGroup>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    {!isCreation &&
                    <TabContent activeTab={ this.state.activeTab }>
                        <TabPane tabId="2">
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle><i className="fa fa-camera"></i>&nbsp;{t("articlecategories.posters")}</CardTitle>
                                            <CardText tag="div">
                                                <FormGroup row>
                                                    <Label for="new_poster" sm={3}>{`${t("articlecategories.add_poster")}`}</Label>
                                                    <Col sm={9}>
                                                        <Field component={FileUpload} type="file" className="form-control" name="new_poster" id="new_poster" />
                                                        <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoading}>
                                                            {isLoading ?
                                                                <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                                                :
                                                                <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                                                            }
                                                        </Button>
                                                    </Col>
                                                </FormGroup>
                                                {articlecategory &&
                                                 articlecategory.posters &&
                                                 articlecategory.posters.length > 0 &&
                                                    <FieldArray name="posters" component={ArticlecategoryPosters} posters={articlecategory.posters}/>
                                                }
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    }
                    <br/>
                    <Row>
                        <Col className="text-center">
                            <br/>
                            <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-save"></i>&nbsp;
                                        {t("common.save")}
                                    </span>
                                }
                            </Button>
                        </Col>
                    </Row>
                </Form>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadArticlecategories: () => loadArticlecategories(),
    dispatchLoadSalepoints: () => loadSalepoints()
};
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ArticlecategoryForm = reduxForm({
  form: 'articlecategoryForm', // a unique identifier for this form
  enableReinitialize: true
})(ArticlecategoryForm)

// You have to connect() to any reducers that you wish to connect to yourself
ArticlecategoryForm = connect(
  state => ({
    initialValues: state.articlecategories.articlecategory, // pull initial values from articlecategories reducer
    initialValues: state.articlecategories.articlecategory && {
        ...state.articlecategories.articlecategory,
        pos: state.articlecategories.articlecategory.pos.map((p) => p._id),
    }, // pull initial values from articlecategories reducer
    articlecategories: state.articlecategories,
    salepoints: state.salepoints
}), mapDispatchToProps
)(ArticlecategoryForm)

export default withTranslation('translations')(ArticlecategoryForm)
