import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { useParams } from "react-router-dom";
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadCustomer, deleteCustomer } from '../../../redux/modules/customers';

const CustomerDelete = props => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { customerId } = useParams();
    useEffect(() => {
        if (customerId)
            dispatch(loadCustomer(customerId));
    }, [dispatch, customerId]);

    const doDeleteCustomer = customerId => {
        dispatch(deleteCustomer(customerId));
    };

    const { customer, isLoading, customerSuccessfullyDeleted } = useSelector(state => state.customers);

    if (!customer)
        return null;

    const title = (
        <span>
            {t('customers.customer')}
            <small> | {t('customers.delete_customer', {firstname: customer.contact?.firstname, lastname: customer.contact?.lastname})}</small>
        </span>
    );

    const actions =
    <div>
        <ButtonGroup>
            <Button tag={Link} to="/customers" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;
                {t('common.back')}
            </Button>
        </ButtonGroup>
    </div>


    if (customerSuccessfullyDeleted)
        return (
            <Redirect to="/customers"/>
        );

    const confirmation = t('customers.confirm_delete_this_customer');
    return (
        <div className="CustomerDelete">
            <PageTitle icon="users" title={ title } actions={ actions } />
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <i className="fa fa-warning"></i>&nbsp;
                                { confirmation }
                            </CardTitle>
                            <CardText tag="div">
                                <div><b>{t('customers.name')}</b>: { customer.name }</div>
                                <div><b>{t('customers.firstname')}</b>: { customer.contact?.firstname }</div>
                                <div><b>{t('customers.lastname')}</b>: { customer.contact?.lastname }</div>
                                <Row>
                                    <Col className="text-center">
                                        <Button tag={Link} to="/customers" color="warning">
                                            <i className="fa fa-arrow-left"></i>&nbsp;
                                            {t('common.cancel')}
                                        </Button>&nbsp;
                                        <Button color="danger" onClick={() => doDeleteCustomer(customer._id)} disabled={isLoading}>
                                            {isLoading ?
                                                <span>
                                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                </span>
                                                :
                                                <span>
                                                    <i className="fa fa-trash"></i>&nbsp;
                                                    {t('common.delete')}
                                                </span>
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CustomerDelete;
