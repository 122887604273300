import RestClient from './RestClient';
import { Tag } from '../../models';

class Tags extends RestClient {
    constructor() {
        super({
            "model": Tag,
            "entryPoint": "tag",
            "resource": "tag",
            "resources": "tags",
            "id_field": "_id"
        });
    }
}

const TagsApi = new Tags();

export default TagsApi;
