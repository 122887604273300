import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import ActivityForm from '../ActivityForm/ActivityForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateActivity, createActivity } from '../../../redux/modules/activities';

class ActivityCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.props.dispatchStartCreateActivity();
    }

    submit(values) {
        this.props.dispatchCreateActivity(values);
    }

    render() {
        const { t }                              = this.props;
        const { activitySuccessfullyCreated } = this.props.activities;

        let title = <span>{t('activities.add')}</span>;

        const actions =
            <Button tag={Link} to="/activities" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="ActivityCreate">
                <PageTitle icon="id-badge" title={ title } actions={ actions } />

                {activitySuccessfullyCreated &&
                    <Redirect to="/activities"/>
                }

                <ActivityForm onSubmit={this.submit} isCreation="true" />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateActivity: (activity) => startCreateActivity(activity),
    dispatchCreateActivity: (activity) => createActivity(activity)
}

const mapStateToProps = state => ({
    activities: state.activities,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ActivityCreate))
