import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label } from 'reactstrap';
import { Utils } from '../../services';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './TickettypeFieldForm.css';

const TickettypeFieldForm = ({ change, tickettype, salepointId}) => {
    const { t } = useTranslation();

    const options = Utils.passOwnerFields().map(f => {
        return {
            key: f,
            text: t(`tickettypes.available_fields.${ f }`),
            value: f
        };
    });

    return (
        <div className="TickettypeFieldForm">
            <div className="row">
                <div className="col-sm-6">
                    <Label for={ `opaque.fields.${ salepointId }.requested[]` }>{t("tickettypes.available_fields.requested_description") }</Label>
                    <Col className="selectedfields">
                        <MultiSelectField name={ `opaque.fields.${salepointId}.requested` } options={ options } />
                    </Col>
                </div>
                <div className="col-sm-6">
                    <Label for={ `opaque.fields.${ salepointId }.required[]` }>{t("tickettypes.available_fields.required_description") }</Label>
                    <Col className="selectedfields">
                        <MultiSelectField name={ `opaque.fields.${salepointId}.required` } options={ options } />
                    </Col>
                </div>
            </div>
        </div>
    );
}

export default TickettypeFieldForm;
