import React, { useEffect } from 'react';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Utils } from '../../../services';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadPostcategory, deletePostcategory } from '../../../redux/modules/post_categories';

const PostcategoryDelete = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();
    const default_lang = Utils.default_lang();

    useEffect(() => {
        const postCategoryId = match.params.post_categoryId;
        dispatch(loadPostcategory(postCategoryId));
    }, [dispatch])

    const _deletePostCategory = (postCategoryId) => {
        dispatch(deletePostcategory(postCategoryId));
    }

    const { post_category, isLoading, post_categorySuccessfullyDeleted } = useSelector(state => state.post_categories);

    let title = <span>{t("bulidings.postcategories_plural")}</span>;
    if (post_category && post_category.name)
        title = (
            <span>
                {t("post_categories.post_category")}
                <small> | { post_category.name[default_lang] } </small>
            </span>
        );

    const actions =
    <div>
        <ButtonGroup>
            <Button tag={Link} to="/post_categories" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
            </Button>
        </ButtonGroup>
    </div>

    return (
        <div className="PostcategoryDelete">
            <PageTitle icon="tags" title={ title } actions={ actions } />
            {post_categorySuccessfullyDeleted &&
                <Redirect to="/post_categories"/>
            }

            {post_category && post_category.name &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('post_categories.confirm_delete')}</CardTitle>
                                <CardText tag="div">
                                    <div><b>{t("post_categories.name")}</b>: { post_category.name[default_lang] }</div>
                                    <Row>
                                        <Col className="text-center">
                                            <Button tag={Link} to="/post_categories" color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => _deletePostCategory(post_category.id)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        { t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
            </div>
    );
}

export default PostcategoryDelete
