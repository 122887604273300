import React from 'react';
import BaseModel from './BaseModel';
import moment from 'moment';
import { mdiBankTransfer } from '@mdi/js';
import ReactTimeAgo from 'react-time-ago';

/** Class representing a collector event. */
class CollectorTransaction extends BaseModel {
    constructor(properties) {
        super(properties);

        if (this.createdAt)
            this.createdAt = moment(this.createdAt);
    }

    getIcon() {
        return mdiBankTransfer;
    }

    getText() {
        return '#' + this.id + ' ' + this.amount.toFixed(2) + ' CHF';
    }

    getCreatedAt() {
        return (
            <ReactTimeAgo
                date={ moment().toDate() }
                locale="fr-FR"
                timeStyle="round-minute"
            />
        );
    }
}

export default CollectorTransaction;
