import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Utils } from '../../../services';
import { useTranslation } from 'react-i18next';
import "./ShowWidgetProps.css";
import widgetsList from "../WidgetsList.js";
import _ from "lodash";

const ShowWidgetProps = ({ board, onChangeSettings, settingsIndex, name, insert, cancelSettings }) => {
    const default_lang = Utils.default_lang();
    const { t }        = useTranslation();

    //returns HTML for widget settings at index : settingsIndex
    const showWidgetSettings = () => {
        let temp = []
        temp.push(
            //Widget settings header
            <div className="widgetSettings" key="widgetSettingsList">
                <h3>{t(widgetsList[name].title)}<i className={"fa fa-" + widgetsList[name].icon}></i></h3>
                {React.createElement(
                    widgetsList[name].component, { 
                        props: (insert === "editProps") ? board[settingsIndex][1] : undefined, 
                        saveSettings: getSettings, 
                        onCancel: () => cancelSettings(), 
                        index: settingsIndex, 
                        name:name, 
                        insert:insert 
                    })}
            </div>
        )
        let tempWithRow =
            <Row className='pt-3'>
                <Col className="col-md-10 offset-md-1 col-xl-8 offset-xl-2">{temp}</Col>
            </Row>
        return tempWithRow;
    }

    //Gets settings from widget child settings form
    const getSettings = (propsFromChild) => {
        //Send them to dashboard
        onChangeSettings(propsFromChild)
    }

    const html = showWidgetSettings()

    return (
        <div className="ShowWidgetProps">
            {html}
        </div>
    )
}

export default ShowWidgetProps;
