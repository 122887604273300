import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './DonationModesField.css';

const DonationModesField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.donationModes().map(e => {
        return {
            key: e,
            text: t(`donation.${e}`),
            value: e
        };
    });

    return <MultiSelectField name={ name } options={ options } />;
}

export default DonationModesField;
