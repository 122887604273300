import BaseModel from './BaseModel';
import Pricing from './Pricing';
import _ from 'lodash';
import { v4 } from 'uuid';

/** Class representing an pricinglist. */
class Pricinglist extends BaseModel {
    constructor(properties) {
        super(properties);

        this.pricings = this.pricings || {};
        _.mapKeys(this.pricings, (pricing, key) => {
            this.pricings[key] = new Pricing(pricing);
        });
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();

        _.mapKeys(this.pricings, (pricing, key) => {
            prepared.pricings[key] = pricing.prepareForUpdate();
        });

        if (!('_id' in prepared))
            prepared._id = v4();

        return prepared;
    }

    /**
     * Prepare this object for duplicate.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForDuplicate() {
        const prepared = _.cloneDeep(this);

        prepared._id   = v4();
        prepared.name += ' copy';

        return prepared;
    }
}

export default Pricinglist;
