import React, { useEffect } from 'react';
import moment from 'moment'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services';
import { Filters } from '../../../services';
import { loadLegacyScreenings } from '../../../redux/modules/legacyScreenings';
import { loadPlaces } from '../../../redux/modules/places';
import _ from 'lodash';
import Table from '../../../components/Table/Table';
import 'react-table-6/react-table.css'
import { TableButtons } from '../../../components';
import { getProgrammationsLinkedResources } from '../../../redux';

import './OldScreeningsList.css'


const OldScreeningsList = ({ }) => {
    const dispatch            = useDispatch();
    const { programmationId } = useParams();
    const places              = useSelector(state => state.programmations.linkedResources.places);
    const { t }               = useTranslation();
    const default_lang        = Utils.default_lang();
    const {
        legacyScreenings,
        hasLoadingError,
        loadingErrorMessage
    } = useSelector(state => state.legacyScreenings);

    const goBack = () => {
        window.history.back();
    }

    useEffect(() => {
        dispatch(getProgrammationsLinkedResources(['places']));
        dispatch(loadLegacyScreenings(programmationId));
    }, [])

    let events = [];
    legacyScreenings && legacyScreenings.map((s) => {
        events = events.concat(s.events);
    });
    events = _.uniqBy(events, 'id');

    let events_groups = [];
    if (events && events.length) {
        events_groups = _.uniq(events.map((e) => e.type)).map((t) => {
            return {
                label: Utils.event_types(t).label,
                events: _.sortBy(events.filter((e) => e.type === t), `title.${default_lang}`)
            };
        });
    }

    const columns = [{
        Header: t("screenings.title"),
        accessor: "events",
        filterMethod: Filters.eventsFilterMethod,
        Filter: Filters.eventsFilter(events_groups),
        filterAll: true,
        Cell: row => row.value && (
            <div className="events_cell">
                <Link to={`/oldscreenings/edit/${row.original.id}`}>
                    <Card>
                        <CardBody style={{
                            "backgroundImage": "url('" + (
                                row.value && row.value.length &&
                                    row.value[0].posters && row.value[0].posters.length ?
                                    row.value[0].posters[0].url : ""
                            ) + "')",
                            "backgroundPosition": "center center",
                            "backgroundSize": "cover"
                        }}>
                            <div className="title-wrapper">
                                <CardTitle>{row.original.title[default_lang]}</CardTitle>
                            </div>
                        </CardBody>
                    </Card>
                </Link>
            </div>
        )
    }, {
        Header: t("screenings.start_at"),
        accessor: "start_at",
        Cell: row => (
            <span>{moment(row.value).format('LLL')}</span>
        ),
        filterMethod: Filters.textFilterMethod("start_at"),
        Filter: Filters.textFilter,
        filterAll: true
    }, {
        Header: t("screenings.stop_at"),
        accessor: "stop_at",
        Cell: row => (
            <span>{moment(row.value).format('LLL')}</span>
        ),
        filterMethod: Filters.textFilterMethod("stop_at"),
        Filter: Filters.textFilter,
        filterAll: true
    }, {
        Header: t("places.place"),
        accessor: "place_id",
        filterMethod: Filters.textFilterMethod("place_id"),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => row.value && places && places.length > 0 && (
            <span>
                {places.find((p) => p._id === row.value) && places.find((p) => p._id === row.value).name}
            </span>
        )
    }, {
        Header: t("common.actions"),
        accessor: "id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete"]}
                module="oldscreenings"
                baseModule='programmations'
            />
        )
    }];

    const actions =
        <Button onClick={goBack} color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
        </Button>

    const title =
        <span>{t("screenings.screening_plural")}</span>

    return (
        <div className="OldScreeningsList">
            <PageTitle icon="th" title={title} actions={actions} />
            <Table id="screeningsTable" data={legacyScreenings} columns={columns} />
        </div>
    );
}

export default OldScreeningsList
