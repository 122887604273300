import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';

import './ImageTypesField.css';
const ImageTypesField = ({ name, usage }) => {
    const { t }   = useTranslation();
    const options = Utils.image_types(usage).map(type => {
        return {
            key: type,
            text: t(`common.image_types.${type}`),
            value: type
        };
    });

    return (
        <SelectField
            name={ name }
            options={ options }
            clearable
        />
    );
}

export default ImageTypesField;
