import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import { Field } from 'redux-form';
import TranslatableField from '../../../../TranslatableField/TranslatableField';

const OnScreenMoviesSettings = ({ env, screen, widget }) => {
    const { t } = useTranslation();

    return (
        <>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.genre_id') }
                </Label>
                <Col sm={ 8 }>
                    <Field
                        type="text"
                        component="input"
                        className="form-control"
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].genre.id` }
                    />
                </Col>
            </FormGroup>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.genre_name') }
                </Label>
                <Col sm={ 8 }>
                    <TranslatableField
                        component="input"
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].genre` }
                    />
                </Col>
            </FormGroup>
        </>
    );
}

export default OnScreenMoviesSettings;
