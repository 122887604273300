import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import EventForm from '../EventForm/EventForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateEvent, createEvent } from '../../../redux/modules/events';

class EventCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        // Reset uer in app state
        this.props.dispatchStartCreateEvent();
    }

    submit(values) {
        const type = this.props.match.params.type;
        this.props.dispatchCreateEvent(type, values);
    }

    render() {
        const { t, match }                        = this.props;
        const type                                = match.params.type;
        const { eventSuccessfullyCreated, event } = this.props.events;

        let title = <span>{t('events.add.event')}</span>;

        const actions =
            <Button tag={Link} to={ `/events/${type}` } color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="EventCreate">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {eventSuccessfullyCreated &&
                    <Redirect to={`/events/${type}/edit/${event.id}`} />
                }

                <EventForm onSubmit={this.submit} isCreation="true" type={type} />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateEvent: (event) => startCreateEvent(event),
    dispatchCreateEvent: (eventType, event) => createEvent(eventType, event)
}

const mapStateToProps = state => ({
    events: state.events,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(EventCreate))
