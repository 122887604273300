import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadSection, deleteSection } from '../../../redux/modules/sections';

class SectionDelete extends Component<{}> {
    componentDidMount() {
        const sectionId = this.props.match.params.sectionId;
        // Load sections on component mount
        this.props.dispatchLoadSection(sectionId);
    }

    deleteSection(sectionId) {
        this.props.dispatchDeleteSection(sectionId);
    }

    render() {
        const { t }                                                      = this.props;
        const { section, isLoading, sectionSuccessfullyDeleted } = this.props.sections;

        const default_lang = Utils.default_lang();

        let title = <span>{t("bulidings.section_plural")}</span>;
        if (section)
            title = (
                <span>
                    {t("sections.section")}
                    <small> | { section.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/sections" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="SectionDelete">
                <PageTitle icon="indent" title={ title } actions={ actions } />

                {sectionSuccessfullyDeleted &&
                    <Redirect to="/sections"/>
                }

                {section &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('sections.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("sections.name")}</b>: { section.name[default_lang] }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/sections" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteSection(section.id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadSection: (sectionId) => loadSection(sectionId),
    dispatchDeleteSection: (sectionId) => deleteSection(sectionId)
}

const mapStateToProps = state => ({
    sections: state.sections
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(SectionDelete))
