import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadSalepoint, deleteSalepoint } from '../../../redux/modules/salepoints';

class SalepointDelete extends Component<{}> {
    componentDidMount() {
        const salepointId = this.props.match.params.salepointId;
        // Load salepoints on component mount
        this.props.dispatchLoadSalepoint(salepointId);
    }

    deleteSalepoint(salepointId) {
        this.props.dispatchDeleteSalepoint(salepointId);
    }

    render() {
        const { salepoint, isLoading, salepointSuccessfullyDeleted } = this.props.salepoints;
        const { t } = this.props;
        const default_lang = Utils.default_lang();

        let title = <span>{t("bulidings.salepoint_plural")}</span>;
        if (salepoint)
            title = (
                <span>
                    {t("salepoints.salepoint")}
                    <small> | { salepoint.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/salepoints" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="SalepointDelete">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {salepointSuccessfullyDeleted &&
                    <Redirect to="/salepoints"/>
                }

                {salepoint &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('salepoints.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("salepoints.name")}</b>: { salepoint.name[default_lang] }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/salepoints" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteSalepoint(salepoint._id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadSalepoint: (salepointId) => loadSalepoint(salepointId),
    dispatchDeleteSalepoint: (salepointId) => deleteSalepoint(salepointId)
}

const mapStateToProps = state => ({
    salepoints: state.salepoints
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(SalepointDelete))
