import Client from './Client';
import { User } from '../models';


const LOGIN_PATH       = '/kuser/login';
const LOGOUT_PATH      = '/kuser/logout';
const GET_PROFILE_PATH = '/kuser/profile';

class Auth extends Client {
    checkApiKey(apiKey) {
        return this.GET(GET_PROFILE_PATH, {}, {
            "X-API-Key": apiKey
        })
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    localStorage.setItem('user', JSON.stringify(json.user));
                    return new User(json.user);
                }
                return null;
            });
    }

    logIn(email, password) {
        return this.POST(LOGIN_PATH, {
            email,
            password
        })
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    localStorage.setItem('user', JSON.stringify(json.user));
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    logOut() {
        return this.POST(LOGOUT_PATH).then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Déconnexion impossible");
        });
    }
}

const AuthApi = new Auth();

export default AuthApi;
