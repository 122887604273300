import RestClient from './RestClient';
import { TktUser } from '../../models';

class TktUsers extends RestClient {
    constructor() {
        super({
            "model": TktUser,
            "entryPoint": "user",
            "resource": "user",
            "resources": "users",
            "id_field": "_id"
        });
    }

    list() {
        return this.GET('/user/list')
            .then(response => response.json())
            .then(json => {
                if (json.users)
                    return {
                        ...json,
                        tktusers: json.users
                        .map(c => new TktUser(c))
                        .filter(u => !u.isCustomer() && !u.isPihyve())
                    };
            throw new Error(json.flash.error);
        });
    }

    create(payload) {
        payload = new TktUser(payload);

        if ('prepareForUpdate' in payload)
            payload = payload.prepareForUpdate();

        return this.POST(`/${this.entryPoint}${this.CREATE_PATH}`, { [this.resource]: payload })
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.tktuser = new TktUser(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}
const TktUsersApi = new TktUsers();

export default TktUsersApi;
