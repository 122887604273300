import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import DistributorsList from './DistributorsList/DistributorsList';
import DistributorShow from './DistributorShow/DistributorShow';
import DistributorEdit from './DistributorEdit/DistributorEdit';
import DistributorCreate from './DistributorCreate/DistributorCreate';
import DistributorDelete from './DistributorDelete/DistributorDelete';

class Distributors extends Component<{}> {
    render() {
        const { match } = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.distributors;
        return (
            <div className="Distributors">

                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={DistributorsList} />
                <Route exact path={ `${ match.url }/new` } component={DistributorCreate} />
                <Route exact path={ `${ match.url }/show/:distributorId` } component={DistributorShow} />
                <Route exact path={ `${ match.url }/edit/:distributorId` } component={DistributorEdit} />
                <Route exact path={ `${ match.url }/delete/:distributorId` } component={DistributorDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    distributors: state.distributors
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Distributors));
