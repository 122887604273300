import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from '../SelectField/SelectField';

import './YesNoField.css';

const YesNoField = ({ name }) => {
    const { t } = useTranslation();

    const options = [{
        text: t('common.no'),
        value: false
    }, {
        text: t('common.yes'),
        value: true
    }];

    return (
        <SelectField
            name={ name }
            options={ options }
        />
    );
}

export default YesNoField;
