import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import BucketlistForm from '../BucketlistForm/BucketlistForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { loadBucketlist, updateBucketlist } from '../../../redux/modules/bucketlists';

const BucketlistEdit = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();
    const { bucketlist } = useSelector(state => state.bucketlists, shallowEqual);

    useEffect(() => {
        const bucketlistId = match.params.bucketlistId;
        dispatch(loadBucketlist(bucketlistId));
    }, [])

    const submit = (values) => {
        dispatch(updateBucketlist(values));
    }

    let title = <span>{t('bucketlists.bucketlist')}</span>;
    if (bucketlist)
        title = (
            <span>
                {t('bucketlists.bucketlist')}
                <small> |&nbsp;{ t('bucketlists.update_bucketlist', { name: bucketlist.name })} </small>
            </span>
        );

    const actions =
    <div>
        {bucketlist &&
        <ButtonGroup>
            <Button tag={Link} to="/bucketlists" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
            </Button>
        </ButtonGroup>
        }
    </div>

    return (
        <div className="BucketlistEdit">
            <PageTitle icon="battery-half" title={ title } actions={ actions } />

            {bucketlist &&
                <BucketlistForm bucketlist={bucketlist} onSubmit={ submit } />
            }
        </div>
    );
}

export default BucketlistEdit;
