import React from "react";
import BaseModel from '../BaseModel';
import PdfLayout from '../PdfLayout';
import _ from 'lodash';

/** Class representing a PDF layout widget. */
class PdfLayoutWidget extends BaseModel {
    constructor(properties) {
        super(properties || {});

        this.position   = this.position || {};
        this.position.x = Math.round(this.position.x * 100) / 100;
        this.position.y = Math.round(this.position.y * 100) / 100;

        this.dimensions        = this.dimensions || {};
        this.dimensions.width  = Math.round(this.dimensions.width * 100) / 100;
        this.dimensions.height = Math.round(this.dimensions.height * 100) / 100;

        if (!this.id)
            this.id = _.uniqueId('widget_');
    }

    getType() {
        return this.type;
    }

    getSubType() {
        return this.subType;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 20, y: 200, z: 0 },
            dimensions: { width: 120, height: 120},
            transform: { rotation: 0 },
            options: {
                style: {}
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return [
            PdfLayout.OPTION_X,
            PdfLayout.OPTION_Y,
            PdfLayout.OPTION_Z,
            PdfLayout.OPTION_WIDTH,
            PdfLayout.OPTION_HEIGHT,
            PdfLayout.OPTION_LOCK_RATIO,
            PdfLayout.OPTION_ROTATION,
            PdfLayout.OPTION_BACKGROUND_COLOR,
            PdfLayout.OPTION_BORDER_COLOR,
            PdfLayout.OPTION_BORDER_STYLE,
            PdfLayout.OPTION_BORDER_WIDTH,
            PdfLayout.OPTION_BORDER_RADIUS,
        ];
    }

    /**
     * Check if this widget handles the provided option
     */
    handleOption(option) {
        return this.getHandledOptions().includes(option);
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        return <div>{`${this.type} - ${this.subType}`}</div>;
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'cube';
    }

    /**
     * Is this widget resizable ?
     **/
    isResizable = () => true;

    /**
     * Is this widget resizable only horizontally ?
     **/
    isResizableHorizontallyOnly = () => false;

    /**
     * Is this widget editable ?
     **/
    isEditable = () => true;

    /**
     * Set one of this widget option
     */
    setOption = (option, value) => {
        this.options = {
            ...(this.options || {}),
            [option]: value
        };
    };

    /**
     * Set this widget dimension on one axis
     */
    setWidth     = width => this.setDimension('width', width);
    setHeight    = height => this.setDimension('height', height);
    setDimension = (axis, value) => {
        this.dimensions = this.dimensions || {};
        this.dimensions[axis] = Math.round(value * 100) / 100;
    };

    /**
     * Set this widget position on one axis
     */
    setX = x => this.setPosition('x', x);
    setY = y => this.setPosition('y', y);
    setPosition = (axis, value) => {
        this.position = {
            ...(this.position || {}),
            [axis]: Math.round(value * 100) / 100
        };
    };

    /**
     * Convert this widget instance to a plain object
     */
    toPlainObject = () => JSON.parse(JSON.stringify(this));
}

export default PdfLayoutWidget;
