import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadActivity, deleteActivity } from '../../../redux/modules/activities';

class ActivityDelete extends Component<{}> {
    componentDidMount() {
        const activityId = this.props.match.params.activityId;
        // Load activities on component mount
        this.props.dispatchLoadActivity(activityId);
    }

    deleteActivity(activityId) {
        this.props.dispatchDeleteActivity(activityId);
    }

    render() {
        const { t }                                                      = this.props;
        const { activity, isLoading, activitySuccessfullyDeleted } = this.props.activities;

        const default_lang = Utils.default_lang();

        let title = <span>{t("bulidings.activity_plural")}</span>;
        if (activity)
            title = (
                <span>
                    {t("activities.activity")}
                    <small> | { activity.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/activities" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="ActivityDelete">
                <PageTitle icon="id-badge" title={ title } actions={ actions } />

                {activitySuccessfullyDeleted &&
                    <Redirect to="/activities"/>
                }

                {activity &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('activities.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("activities.name")}</b>: { activity.name[default_lang] }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/activities" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteActivity(activity.id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadActivity: (activityId) => loadActivity(activityId),
    dispatchDeleteActivity: (activityId) => deleteActivity(activityId)
}

const mapStateToProps = state => ({
    activities: state.activities
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ActivityDelete))
