import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { getDataSet, reduce } from "iso3166-2-db";
import i18n from '../../locales/i18n';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

const CountriesField = ({ name, single, onChange, fixedWidth }) => {
    const { t } = useTranslation();

    const countries = reduce(getDataSet(), i18n.language.substring(0, 2));
    const options = Object.keys(countries)
        .sort((a, b) => countries[a].name > countries[b].name ? 1 : -1)
        .map(isoCode => {
            return {
                key: isoCode,
                text: countries[isoCode].name,
                value: isoCode
            };
        });

    const onFieldChange = (value) => {
        return onChange ? onChange(value) : true;
    }

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            onSelectChange={ onFieldChange }
            fixedWidth={ fixedWidth }
            clearable
        />
    ) : (
        <MultiSelectField
            name={ name }
            options={ options }
            onSelectChange={ onFieldChange }
            fixedWidth={ fixedWidth }
            clearable
        />
    );
}

export default CountriesField;
