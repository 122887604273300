import React from 'react';
import { useSelector } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import SectorsList from './SectorsList/SectorsList';
import SectorCreate from './SectorCreate/SectorCreate';
import SectorEdit from './SectorEdit/SectorEdit';
import SectorDelete from './SectorDelete/SectorDelete';

const Sectors = ({ match }) => {

    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.sectors);

    return (
        <div className="Sectors">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }

            <Route exact path={ match.path } component={SectorsList} />
            <Route exact path={ `${ match.url }/new` } component={SectorCreate} />
            <Route exact path={ `${ match.url }/edit/:sectorId` } component={SectorEdit} />
            <Route exact path={ `${ match.url }/delete/:sectorId` } component={SectorDelete} />

        </div>
    );
}

export default Sectors;

