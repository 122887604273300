import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";

const GridParams = ({ onChange, gridParamsObj }) => {
    const [gridParams, setGridParams] = useState(gridParamsObj);
    const { t } = useTranslation();

   useEffect(()=> {
      setGridParams(gridParamsObj)
   },[gridParamsObj])

    return (
        <div className="GridParams">
            <h3>{t("places.map.gridParamsTitle")}</h3>
            <Row>
                <Col sm={12} lg={6}>
                    <FormGroup row>
                        <Label for="nbColumns" sm={12}>
                            {t("places.map.nbColumns")}
                        </Label>
                        <Col sm={"auto"}>
                            <Input
                                type="number"
                                value={gridParams.nbColumns}
                                max = {100}
                                onChange={(event) => {
                                    const gridParmsObj = {
                                        ...gridParams,
                                        nbColumns: event.target.value,
                                    };
                                    setGridParams(gridParmsObj);
                                    onChange(gridParmsObj);
                                }}
                            />
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={12} lg={6}>
                    <FormGroup row>
                        <Label for="nbRows" sm={12}>
                            {t("places.map.nbRows")}
                        </Label>
                        <Col sm={"auto"}>
                            <Input
                                type="number"
                                value={gridParams.nbRows}
                                max = {100}
                                onChange={(event) => {
                                    const gridParmsObj = {
                                        ...gridParams,
                                        nbRows: event.target.value,
                                    };
                                    setGridParams(gridParmsObj);
                                    onChange(gridParmsObj);
                                }}
                            />
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup row>
                <Label for="stage_position" sm={12}>
                    {t("places.map.stagePosition")}
                </Label>
                <Col sm={"auto"}>
                    <Input
                        type="select"
                        value={gridParams.stagePosition}
                        onChange={(event) => {
                            const gridParmsObj = {
                                ...gridParams,
                                stagePosition: event.target.value,
                            };
                            setGridParams(gridParmsObj);
                            onChange(gridParmsObj);
                        }}
                    >
                        <option value="top">{t("places.map.stagePositions.top")}</option>
                        <option value="bottom">
                            {t("places.map.stagePositions.bottom")}
                        </option>
                    </Input>
                </Col>
            </FormGroup>
        </div>
    );
};

export default GridParams;