import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Row, Col, FormGroup, Label, Card, CardBody, CardTitle, CardText } from 'reactstrap';

import logo from '../../../assets/images/integrations/shift72.png';

const ShiftFields = () => {
    const { t }                  = useTranslation();
    const { isMobile, isTablet } = useSelector(state => state.ui);

    return (
        <div className="ShiftFields">
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="small">
                                <img src={logo} className="img rounded" />
                                { t('settings.integrations.shift72.title') }
                            </CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                        <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.shift72.endpoint")}</Label>
                                            <Col lg={10} >
                                                <Field required component="input" className="form-control" type="text" name="integrations.shift72.endpoint" id="endpoint" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label lg={2} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.integrations.shift72.api_key")}</Label>
                                            <Col lg={10} >
                                                <Field required component="input" className="form-control" type="text" name="integrations.shift72.api_key" id="api_key" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ShiftFields;
