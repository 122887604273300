import Clock from "../../components/Widget/Clock/Clock"
import Weather from "../../components/Widget/Weather/Weather"
import WidgetSeparator from "../../components/Widget/WidgetSeparator/WidgetSeparator"
import Shortcut from "../../components/Widget/Shortcut/Shortcut"
import GraphBoxOffice from "../../components/Widget/GraphBoxOffice/GraphBoxOffice"
import LastEvents from "../../components/Widget/LastEvents/LastEvents"

const widgetsList = {
    "widgetSeparator": {
        "title": "widgets.title_desc.separator",
        "description": "widgets.title_desc.separator_desc",
        "icon": "arrows",
        "component": WidgetSeparator
    },
    "clock": {
        "title": "widgets.title_desc.clock",
        "description": "widgets.title_desc.clock_desc",
        "icon": "clock-o",
        "component": Clock,
    },
    "weather": {
        "title": "widgets.title_desc.weather",
        "description": "widgets.title_desc.weather_desc",
        "icon": "umbrella",
        "component": Weather
    },
    "shortcut": {
        "title": "widgets.title_desc.shortcut",
        "description": "widgets.title_desc.shortcut_desc",
        "icon": "link",
        "component": Shortcut
    },
    "graphBoxOffice": {
        "title": "widgets.title_desc.graphBoxOffice",
        "description": "widgets.title_desc.graphBoxOffice_desc",
        "icon": "star",
        "component": GraphBoxOffice
    },
    "lastEvents": {
        "title": "widgets.title_desc.lastEvents",
        "description": "widgets.title_desc.lastEvents_desc",
        "icon": "calendar",
        "component": LastEvents
    },
}


export default widgetsList