import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardText, CardTitle } from 'reactstrap';

const Forbidden = ({}) => {
    const { t } = useTranslation();

    return (
        <div className='d-flex justify-content-center'>
            <Card className='mt-5'>
                <CardBody>
                    <CardTitle>
                        <i className="fa fa-warning"></i>&nbsp;{t('abilities.forbidden')}
                    </CardTitle>
                    <CardText>
                        {t('abilities.forbidden_message')}
                    </CardText>
                    <CardFooter>
                        <ButtonGroup className='float-right'>
                            <Button color="warning" onClick={() => window.history.back()}><i className='fa fa-arrow-left'></i>&nbsp;{t('common.back')}</Button>
                            <Button tag={Link} color="info" to="/"><i className='fa fa-home'></i>&nbsp;{t('abilities.home')}</Button>
                        </ButtonGroup>
                    </CardFooter>
                </CardBody>
            </Card>
        </div>
    )
}

export default Forbidden;
