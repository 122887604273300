import Client from './Client';

class Mailer extends Client {
    /**
     * @param {Object} payload: An object like :
     *  {
     *      layout_id: UUID,
     *      subject: String,
     *      to: {
     *          name: String,
     *          address: String
     *      },
     *      lang: String,
     *      context: {
     *          ticket_id: UUID
     *      }
     *  }
     */
    send(payload) {
        return this.POST('/mailer/send', payload)
            .then(response => response.json())
            .then(json => {
                if (json.result)
                    return json;

                throw new Error(json.flash.error);
            });
    }
}

const MailerApi = new Mailer();

export default MailerApi;
