import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Utils } from '../../services';

import './LanguageSelector.css';

const LanguageSelector = ({ onChange }) => {
    const languages = Utils.langs();
    const [current, setCurrent] = useState(languages[0]);

    useEffect(() => {
        onChange(current);
    }, [current]);

    return (
        <ButtonGroup className="LanguageSelector">
        {languages.length > 1 && languages.map((l) => (
            <Button key={l} size="sm" onClick={(e) => { setCurrent(l); }} className="lang-switcher" color={current === l ? "primary" : "dark"}>
                {l.toUpperCase()}
            </Button>
        ))}
        </ButtonGroup>
    );
}

export default LanguageSelector;
