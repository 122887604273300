import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

import './RolesField.css';

const RolesField = ({ name, useUserRoles, placeholder }) => {
    const { t } = useTranslation();
    const options = (useUserRoles ? Utils.tktUserRoles() : Utils.pricingRoles()).map(r => {
        return {
            key: r,
            text: t(`roles.${r}`),
            value: r
        };
    });

    return <MultiSelectField name={ name } options={ options } placeholder={ placeholder } clearable />
}

export default RolesField;
