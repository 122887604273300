import React from 'react';
import { Field } from 'redux-form'
import { Dropdown } from 'semantic-ui-react';

import './MultiSelectField.css';

const MultiSelectField = ({ name, value, options, placeholder, fixedWidth, onSelectChange, ...rest }) => {
    const Component = (props) => {
        const { input } = props;
        const onChange = (e, data) => {
            input.onChange(data.value);
            if ('onChange' in rest)
                rest.onChange(e, data);
        }

        const otherProps = {};
        if (value)
            otherProps.value = value;
        if ('clearable' in rest)
            otherProps.clearable = rest.clearable;

        return <Dropdown
            name={ name }
            options={options}
            defaultValue={ input.value }
            onChange={ onChange }
            placeholder={ placeholder }
            fluid={ !fixedWidth || fixedWidth === 'undefined' }
            multiple
            selection
            search
            {...otherProps}
        />;
    }

    return (
        <div className="MultiSelectField">
            <Field multiple name={ name } component={ Component } onChange={ onSelectChange } className="form-control" />
        </div>
    );
}

export default MultiSelectField;
