import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import { Setting } from '../../../models';

import './ConfigSettings.css';

const ConfigSettings = (props) => {
    const { t } = useTranslation();
    const user = useSelector(state => state.auth.user);
    const { env, tickettypes, salepoints, articles, articleCategories, postCategories } = props;

    if (!props.setting)
        return null;

    const keys = [
       'edition',
       'kronosUri', 'kronosApiKey',
       'apiKey', 'engineUri',
       'salepointId'
    ];

    const renderSetting = key => {
        const name = `mobile.${env}.${key}`;
        const { role, hideLabel, input } = Setting.propertyForm(t, props.setting, name, tickettypes, salepoints, articles, articleCategories, postCategories);

        return (
            <>
                {(user?.isSuperAdmin() || (!role && user?.can('editSelf', 'settings'))) &&
                    <FormGroup row>
                        {!hideLabel && (
                            <Label for="_id" sm={3}>{t(`settings.mobile.${key}`)}</Label>
                        )}
                        <Col sm={hideLabel ? 12 : 9}>
                            { input }
                        </Col>
                    </FormGroup>
                }
            </>
        );
    }

    return (
        <div className="ConfigSettings">
            { keys.map(key => renderSetting(key)) }
        </div>
    );
}

export default ConfigSettings;
