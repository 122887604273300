import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadSection } from '../../../redux/modules/sections';

class SectionShow extends Component<{}> {
    componentDidMount() {
        const sectionId = this.props.match.params.sectionId;
        // Load sections on component mount
        this.props.dispatchLoadSection(sectionId);
    }

    render() {
        const { t }           = this.props;
        const { section } = this.props.sections;

        const default_lang = Utils.default_lang();

        let title = <span>{t("sections.section_plural")}</span>;
        if (section)
            title = (
                <span>
                    {t("sections.section")}
                    <small> | { section.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/sections" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {section &&
                    <Button tag={Link} to={ `/sections/edit/${ section.id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="SectionShow">
                <PageTitle icon="indent" title={ title } actions={ actions } />
                {section &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("sections.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("sections.name")}</b>: { section.name[default_lang] }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadSection: (sectionId) => loadSection(sectionId)
}

const mapStateToProps = state => ({
    sections: state.sections
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(SectionShow))
