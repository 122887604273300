import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import ApplicationTypeForm from '../ApplicationTypeForm/ApplicationTypeForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { startCreateApplicationType, createApplicationType } from '../../../redux/modules/applicationTypes';

const ApplicationTypeCreate = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startCreateApplicationType());
    }, [dispatch])

    const submit = (values) => {
        dispatch(createApplicationType(values));
    }

    const { application_typeSuccessfullyCreated } = useSelector(state => state.applicationTypes);

    let title = <span>{t('applicationTypes.add')}</span>;

    const actions =
        <Button tag={Link} to="/application_types" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="ApplicationTypeCreate">
            <PageTitle icon="address-book" title={ title } actions={ actions } />

            {application_typeSuccessfullyCreated &&
                <Redirect to="/application_types"/>
            }

            <ApplicationTypeForm onSubmit={ submit } isCreation="true" />
        </div>
    );
}

export default ApplicationTypeCreate
