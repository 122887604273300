import React, { useEffect } from 'react';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';
import { useDispatch, useSelector } from 'react-redux'
import { Badge } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Filters } from '../../../services/';
import 'react-table-6/react-table.css'
import { loadSalepoints } from '../../../redux/modules/salepoints';
import Table from '../../../components/Table/Table';
import i18n from '../../../locales/i18n';
import { loadSetting } from '../../../redux';

import './SalepointsList.css'

const SalepointsList = () => {
    const { t }          = useTranslation();
    const dispatch       = useDispatch();
    const { salepoints } = useSelector(state => state.salepoints);
    const { delivery }   = useSelector(state => state.settings.setting);

    useEffect(() => {
        dispatch(loadSalepoints());
        dispatch(loadSetting('default'));
    }, [dispatch])
    
    const deliveryTypeName = _id => {
        const type = (delivery?.types || []).find(d => d._id === _id);
        return type?.name[i18n.language] ?? null;
    }
    
    const deliveryBadges = _ids => {
        return (
            <div className='delivery'>
                { _ids.map(_id => (
                    <Badge className="mr-2" key={_id} color="success">
                        { deliveryTypeName(_id) }
                     </Badge>
                 ))}
             </div>
        );
    }

    let columns = []
    if (salepoints && delivery)
        columns = [{
            Header: t("salepoints.name"),
            accessor: "name",
            filterMethod: Filters.textFilterMethod(`name.${i18n.language}`),
            Filter: Filters.textFilter,
            filterAll: true,
            Cell: row => row.value && (row.value[i18n.language] ?? row.value[Object.keys(row.value)[0]])
        }, {
            Header: t("salepoints.delivery_types"),
            accessor: "delivery.types",
            filterMethod: Filters.textFilterMethod(`delivery.types`),
            Filter: Filters.textFilter,
            style: {
                overflow: "visible"
            },
            filterAll: true,
            Cell: row => row.value && deliveryBadges(row.value)
        }, {
            Header: t("salepoints.pos_layout"),
            accessor: "pos_layout",
            filterMethod: Filters.textFilterMethod(`pos_layout`),
            Filter: Filters.textFilter,
            filterAll: true,
        }, {
            Header: t("common.actions"),
            accessor: "_id",
            maxWidth: 110,
            filterable: false,
            Cell: row => (
                <TableButtons
                    id={row.value}
                    actions={["edit", "delete", "show"]}
                    module="salepoints"
                />
            )
        }]

        const actions =
            <CreateButton 
                module="salepoints"
                text="salepoints.add"
            />

    const title =
        <span>{t("salepoints.salepoint_plural")}</span>

    return (
        <div className="SalepointsList">
            <PageTitle icon="th" title={title} actions={actions} />
            <Table id="salepointsTable" data={salepoints} columns={columns} />
        </div>
    );

}

export default SalepointsList
