import React from 'react';
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText, CardFooter } from 'reactstrap';
import WindowForm from '../WindowForm/WindowForm';
import { Utils } from '../../services';

const WindowsForm = ({ windows, remove }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const currency = Utils.currency();
    return (
        <>
            <Row>
                { (windows || []).map((window, idx) => (
                <Col xs="6" key={ idx } className="mb-3">
                    <Card>
                        <CardBody>
                            <CardText tag="div">
                                <WindowForm window={ window } idx={ idx } />
                            </CardText>
                        </CardBody>
                        <CardFooter className="text-right">
                            <ButtonGroup>
                                <Button color="danger" size="sm" onClick={ () => { remove(idx); }}>
                                    <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                </Button>
                            </ButtonGroup>
                        </CardFooter>
                    </Card>
                </Col>
                ))}
            </Row>
        </>
    );
}

export default WindowsForm;
