import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { TabContent, TabPane } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { TranslatableField, WysiwygField, FileUpload, ChannelsField, PostCategoriesField, PostLinksField, TagsField, VideoField } from '../../../components';
import PostPosters from './PostPosters/PostPosters';
import PostTrailers from './PostTrailers/PostTrailers';
import { reduxForm, Field, FieldArray } from 'redux-form';
import classnames from 'classnames';
import { Utils } from '../../../services';
import { loadPostcategories } from '../../../redux/modules/post_categories';
import { loadTags } from '../../../redux/modules/tags';
import { loadChannels } from '../../../redux/modules/channels';
import moment from 'moment';
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('fr');
momentLocalizer();

let PostForm = ({ handleSubmit, pristine, submitting, isCreation }) => {
    const dispatch                   = useDispatch();
    const { isLoading, post, posts } = useSelector(state => state.posts);
    const { tags }                   = useSelector(state => state.tags);
    const { post_categories }        = useSelector(state => state.post_categories);
    const { channels }               = useSelector(state => state.channels);
    const { t }                      = useTranslation();

    useEffect(() => {
        dispatch(loadChannels());
        dispatch(loadTags());
        dispatch(loadPostcategories());
    }, [dispatch]);

    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    const addTag = (tag) => {
        post.tags.push(tag);
        return post.tags;
    }


    const categories = Utils.sortAndIndentCats(post_categories, 'id', 'parent_id');

    if (!post)
        return null;

    return (
        <Form onSubmit={handleSubmit}>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        <i className="fa fa-info-circle"></i>&nbsp;
                        {t("posts.informations")}
                    </NavLink>
                </NavItem>
                {!isCreation &&
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            <i className="fa fa-camera"></i>&nbsp;
                            {t("posts.posters")} ({post.images && post.images.length || 0})
                        </NavLink>
                    </NavItem>
                }
                {!isCreation &&
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >
                            <i className="fa fa-film"></i>&nbsp;
                            {t("posts.trailers")} ({post.videos && post.videos.length || 0})
                        </NavLink>
                    </NavItem>
                }

            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("posts.informations")}</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t("posts.title")}</Label>
                                            <Col sm={12}>
                                                <TranslatableField name="title" placeholder={t("posts.title_placeholder")} component="input" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t("posts.sub_title")}</Label>
                                            <Col sm={12}>
                                                <TranslatableField optional component="input" className="form-control" type="text" name="sub_title" id="sub_title" autoComplete="sub_title" placeholder={t("posts.sub_title_placeholder")} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t("posts.category")}</Label>
                                            <Col sm={12}>
                                                <PostCategoriesField name="category_id" postCategories={categories} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t("posts.tags")}</Label>
                                            <Col sm={12}>
                                                <TagsField name="tags" tags={tags} addTag={addTag} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup row>
                                    <Label for="content" sm={12}>{t("posts.content")}</Label>
                                    <Col sm={12}>
                                        <TranslatableField component={WysiwygField} className="form-control" type="text" name="content" id="content" autoComplete="content" placeholder={t("posts.content_placeholder")} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="name" sm={12}>{t("posts.links")}</Label>
                                    <Col sm={12}>
                                        <PostLinksField name="link" PostLinks={post.links} />
                                    </Col>
                                </FormGroup>
                                <Row>
                                    <Col sm={3}>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t("posts.published")}</Label>
                                            <Col sm={12}>
                                                <Field component="select" className="form-control" type="text" name="published" normalize={Utils.normalizeBoolean}>
                                                    <option key="false" value={false}>{t("common.no")}</option>
                                                    <option key="true" value={true}>{t("common.yes")}</option>
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup row>
                                            <Label for="archived" sm={12}>{t("posts.archived")}</Label>
                                            <Col sm={12}>
                                                <Field component="select" className="form-control" type="text" name="archived" normalize={Utils.normalizeBoolean}>
                                                    <option key="false" value={false}>{t("common.no")}</option>
                                                    <option key="true" value={true}>{t("common.yes")}</option>
                                                </Field>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    {!isCreation && (
                                        <Col sm={6}>
                                            {channels && (
                                                <FormGroup row>
                                                    <Label for="name" sm={12}>{t("posts.channels")}</Label>
                                                    <Col sm={12}>
                                                        <ChannelsField name="channels_ids" channels={channels} />
                                                    </Col>
                                                </FormGroup>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </TabPane>
                {!isCreation &&
                    //upload images
                    <TabPane tabId="2">
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-camera"></i>&nbsp;{t("posts.posters")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Label for="new_poster" sm={12}>{`${t("posts.add_poster")}`}</Label>
                                                <Col sm={12}>
                                                    <Field component={FileUpload} type="file" className="form-control" name="new_poster" id="new_poster" />
                                                    <Button type="submit" color="info" size="sm" disabled={pristine || submitting || isLoading}>
                                                        {isLoading ?
                                                            <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                                            :
                                                            <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                                                        }
                                                    </Button>
                                                </Col>
                                            </FormGroup>
                                            {post &&
                                                post.images &&
                                                post.images.length > 0 &&
                                                <FieldArray name="posters" component={PostPosters} posters={post.images} />
                                            }
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                }
                {!isCreation &&
                    //upload videos
                    <TabPane tabId="3">
                        <Row>
                            <Col sm={12}>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-film"></i>&nbsp;{t("events.trailers")}</CardTitle>
                                        <CardText tag="div">
                                            <FormGroup row>
                                                <Label for="new_trailer" md={3}>{`${t("events.add_trailer")}`}</Label>
                                                <Col md={9}>
                                                    <VideoField
                                                        inputName="new_trailer"
                                                        selectName="new_trailer_type"
                                                        isLoading={isLoading}
                                                        submitting={submitting}
                                                        pristine={pristine}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardText>
                                        {post.videos.length > 0 &&
                                            <FieldArray name="trailers" component={PostTrailers} trailers={post.videos} />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                }
            </TabContent>
            <br />
            <Row>
                <Col className="text-center">
                    <br />
                    <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                        {isLoading ?
                            <span>
                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                            </span>
                            :
                            <span>
                                <i className="fa fa-save"></i>&nbsp;
                                {t("common.save")}
                            </span>
                        }
                    </Button>
                </Col>
            </Row>
        </Form>
    );

}


// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PostForm = reduxForm({
    form: 'postForm', // a unique identifier for this form
    enableReinitialize: true
})(PostForm);

PostForm = connect(
    state => ({
        initialValues: state.posts.post, // pull initial values from posts reducer
        posts: state.posts,
    }), {}
)(PostForm)


export default PostForm;
