import TktUser from './TktUser';

/** Class representing a customer. */
class Customer extends TktUser {
    constructor(properties) {
        super(properties);

        this.roles = this.roles || [];
        if (!this.roles.includes('customer'))
            this.roles.push('customer');
    }
}

export default Customer;
