// Redux Sections module
// see https://github.com/erikras/ducks-modular-redux

import { Sections } from '../../services';

const LOADING_SECTIONS         = 'kronos/sections/LOADING_SECTIONS';
const LOADING_SECTIONS_SUCCESS = 'kronos/sections/LOADING_SECTIONS_SUCCESS';
const LOADING_SECTIONS_FAILURE = 'kronos/sections/LOADING_SECTIONS_FAILURE';
const LOADING_SECTION          = 'kronos/sections/LOADING_SECTION';
const LOADING_SECTION_SUCCESS  = 'kronos/sections/LOADING_SECTION_SUCCESS';
const LOADING_SECTION_FAILURE  = 'kronos/sections/LOADING_SECTION_FAILURE';
const UPDATING_SECTION         = 'kronos/sections/UPDATING_SECTION';
const UPDATING_SECTION_SUCCESS = 'kronos/sections/UPDATING_SECTION_SUCCESS';
const UPDATING_SECTION_FAILURE = 'kronos/sections/UPDATING_SECTION_FAILURE';
const START_CREATING_SECTION   = 'kronos/sections/START_CREATING_SECTION';
const CREATING_SECTION         = 'kronos/sections/CREATING_SECTION';
const CREATING_SECTION_SUCCESS = 'kronos/sections/CREATING_SECTION_SUCCESS';
const CREATING_SECTION_FAILURE = 'kronos/sections/CREATING_SECTION_FAILURE';
const DELETING_SECTION         = 'kronos/sections/DELETING_SECTION';
const DELETING_SECTION_SUCCESS = 'kronos/sections/DELETING_SECTION_SUCCESS';
const DELETING_SECTION_FAILURE = 'kronos/sections/DELETING_SECTION_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    sectionSuccessfullyCreated: false,
    sections: [],
    section: null
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_SECTIONS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_SECTIONS_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                sections: action.sections
            };
        case LOADING_SECTIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_SECTION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_SECTION_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                section: action.section
            };
        case LOADING_SECTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_SECTION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_SECTION_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                section: action.section
            };
        case UPDATING_SECTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_SECTION:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                sectionSuccessfullyCreated: false,
                section: {}
            };
        case CREATING_SECTION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_SECTION_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                sectionSuccessfullyCreated: true,
                section: action.section
            };
        case CREATING_SECTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                sectionSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_SECTION:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_SECTION_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                sectionSuccessfullyDeleted: true,
                section: action.section
            };
        case DELETING_SECTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                sectionSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingSections() { return { type: LOADING_SECTIONS } }
function loadingSectionsSuccess(sections) { return { type: LOADING_SECTIONS_SUCCESS, sections: sections } }
function loadingSectionsFailure(err) { return { type: LOADING_SECTIONS_FAILURE, error: err } }
export function loadSections(sectionname, password) {
    return (dispatch) => {
        dispatch(loadingSections());
        Sections.list()
            .then(data => {
                const sections = data.sections;
                dispatch(loadingSectionsSuccess(sections));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingSectionsFailure(err))
            });
    }
}

function loadingSection() { return { type: LOADING_SECTION } }
function loadingSectionSuccess(section) { return { type: LOADING_SECTION_SUCCESS, section: section } }
function loadingSectionFailure(err) { return { type: LOADING_SECTION_FAILURE, error: err } }
export function loadSection(sectionId) {
    return (dispatch) => {
        dispatch(loadingSection());
        Sections.get(sectionId)
            .then(data => {
                const section = data.section;
                dispatch(loadingSectionSuccess(section));
            })
            .catch(err => {
                dispatch(loadingSectionFailure(err))
            });
    }
}

function updatingSection() { return { type: UPDATING_SECTION } }
function updatingSectionSuccess(section) { return { type: UPDATING_SECTION_SUCCESS, section: section } }
function updatingSectionFailure(err) { return { type: UPDATING_SECTION_FAILURE, error: err } }
export function updateSection(section) {
    return (dispatch) => {
        dispatch(updatingSection());
        Sections.update(section)
            .then(data => {
                const section = data.section;
                dispatch(updatingSectionSuccess(section));
            })
            .catch(err => {
                dispatch(updatingSectionFailure(err))
            });
    }
}

function creatingSection() { return { type: CREATING_SECTION } }
function creatingSectionSuccess(section) { return { type: CREATING_SECTION_SUCCESS, section: section } }
function creatingSectionFailure(err) { return { type: CREATING_SECTION_FAILURE, error: err } }
export function startCreateSection() { return { type: START_CREATING_SECTION } }
export function createSection(section) {
    return (dispatch) => {
        dispatch(creatingSection());
        Sections.create(section)
            .then(data => {
                const section = data.section;
                dispatch(creatingSectionSuccess(section));
                dispatch(loadingSectionSuccess(section));
            })
            .catch(err => {
                dispatch(creatingSectionFailure(err))
            });
    }
}

function deletingSection() { return { type: DELETING_SECTION } }
function deletingSectionSuccess() { return { type: DELETING_SECTION_SUCCESS } }
function deletingSectionFailure(err) { return { type: DELETING_SECTION_FAILURE, error: err } }
export function deleteSection(sectionId) {
    return (dispatch) => {
        dispatch(deletingSection());
        Sections.delete(sectionId)
            .then(data => {
                dispatch(deletingSectionSuccess());
            })
            .catch(err => {
                dispatch(deletingSectionFailure(err))
            });
    }
}
