import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Badge, Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiCogTransferOutline } from '@mdi/js';
import eftImage from '../../assets/images/eft.png';

import './EftResume.css';

const EftResume = ({ eft, className, hideHost, hideDetails, ...rest }) => {
    const { t } = useTranslation();
    const connected = eft.isConnected();

    return (
        <div className={ `EftResume ${className}` } { ...rest }>
            <Card className="card-outline">
                <CardHeader>
                    <CardTitle tag="h6">
                        { eft.name }{ !hideHost ? '@' + eft.host : '' }
                        { hideDetails && (
                        <Button tag={ Link } to={`/devices/efts/manage/${eft.getSanitizedId()}`} color={ 'link' }>
                            <Icon path={ mdiCogTransferOutline } color="white" size={ 1 } />
                        </Button>
                        )}
                    </CardTitle>
                </CardHeader>
                <CardImg
                    top
                    width="100%"
                    src={ eftImage }
                    alt="Eft image cap"
                    className={ connected ? 'connected' : 'disconnected' }
                />
                { !hideDetails && (
                <CardBody>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">
                        <Badge color={ connected ? 'success' : 'danger' }>
                            { t(`efts.${eft.connected ? 'connected' : 'disconnected'}`) }<br/>
                        </Badge>{' '}
                        { eft?.options?.terminal_id && (
                        <Badge color="info">
                            { eft.options.terminal_id }
                        </Badge>
                        )}
                    </CardSubtitle>
                    <Button tag={ Link } to={`/devices/efts/manage/${eft.getSanitizedId()}`} color={ 'info' } block>
                        <Icon path={ mdiCogTransferOutline } color="white" size={ 1 } />
                        { t('efts.manage') }
                    </Button>
                </CardBody>
                )}
            </Card>
        </div>
    );
}

export default EftResume;
