import React from "react";
import PdfLayout from '../PdfLayout';
import CustomTextWidget from './CustomTextWidget';
import { Utils } from '../../services';
import { useTranslation } from 'react-i18next';

/**
 * Class representing a PDF layout widget showing
 * an information about the tickettype.
 */
class TickettypeInfoWidget extends CustomTextWidget {

    constructor(properties) {
        super({
            ...(TickettypeInfoWidget.getDefaultOptions()),
            ...properties,
        });

        this.type    = PdfLayout.TEXT;
        this.subType = PdfLayout.TICKETTYPE_INFO;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 5, height: 0.75 },
            transform: { rotation: 0 },
            options: {
                field: 'name',
                style: {
                    color: '#000000',
                    fontFamily: "Helvetica",
                    fontSize: 14,
                    lineHeight: 1,
                    textAlign: 'left',
                    textDecoration: 'none',
                    backgroundColor: '#FFFFFF00',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        const handledOptions = super.getHandledOptions().concat([
            PdfLayout.OPTION_TICKETTYPE_FIELD
        ]);

        return handledOptions.filter(o => o !== PdfLayout.OPTION_TEXT);
    }

    /**
     * Return this widget preview text as string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreviewText(props) {
        const {tickettype} = props;
        const {t}          = useTranslation();

        switch (this.options.field) {
            case 'name':
                return  Utils.localized_or_fallback(tickettype.name, props.lang, t('models.tickettype.name'));
            case 'description':
                return  Utils.localized_or_fallback(tickettype.description, props.lang, t('models.tickettype.descripion'));
            default:
                return '...';
        }
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'tags';
    }
}

export default TickettypeInfoWidget;
