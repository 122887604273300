import React, { useEffect, useState } from 'react';
import CKWysiwyg from '../CKWysiwyg/CKWysiwyg';
import { Utils } from '../../services';
import _ from 'lodash';

import './TranslatableWysiwyg.css';

const TranslatableWysiwyg = (props) => {
    const {
        defaultValue, placeholder, onChange,
        withShortcodes, withColors, withImages, withTables
    } = props;

    const langs                           = Utils.langs();
    const [selectedLang, setSelectedLang] = useState(langs[0]);
    const [inputText, setInputText]       = useState(defaultValue);

    const changeValue = (value) => {
        let temp = _.cloneDeep(inputText);
        temp[selectedLang] = value || '';
        setInputText(temp);
        onChange(temp)
    }

    if (!langs?.length)
        return null;

    return (
        <div className="TranslatableWysiwyg">

            <div className="lang-switchers">
                {langs.map(l => (
                    <a key={l} onClick={e => setSelectedLang(l)} className={`lang-switcher ${selectedLang === l ? "active" : ""}`}>
                        {l.toUpperCase()}
                    </a>
                ))}
            </div>

            {langs.map((l, i) => (
                <div key={l} className="translatable-field-wrapper" style={{ display: selectedLang === l ? 'initial' : 'none' }}>
                    <CKWysiwyg
                        defaultValue={inputText[l]}
                        onChange={changeValue}
                        withShortcodes={withShortcodes}
                        withColors={withColors}
                        withImages={withImages}
                        withTables={withTables}
                    />
                </div>
            ))}

        </div>
    );
}

export default TranslatableWysiwyg;
