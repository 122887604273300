import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { loadSalepoints, loadArticlecategories } from '../../../../../redux';
import { CategoriesField, SalepointsField, PrinterProfilesField, YesNoField } from '../../../../../components';

let PrinterForm = ({ handleSubmit, pristine, submitting, isLoading, initialValues }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { printer, isLoadingOne, loadingOneErrorMessage } = useSelector(state => state.printers);
    const { salepoints }         = useSelector(state => state.salepoints);
    const { articlecategories }  = useSelector(state => state.articlecategories);

    useEffect(() => {
        dispatch(loadArticlecategories());
        dispatch(loadSalepoints());
    }, []);

    return (
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="options.profile" sm={3}>{t("printers.ppd")}</Label>
                            <Col sm={9}>
                                <PrinterProfilesField name="options.profile" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="options.open_cash_drawer" sm={3}>{t("printers.open_cash_drawer")}</Label>
                            <Col sm={9}>
                                <YesNoField name="options.open_cash_drawer" />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={3}>{t("printers.only_for_pos")}</Label>
                            <Col sm={9}>
                                <SalepointsField name="options.only_for_pos" salepoints={salepoints}/>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" sm={3}>{t("printers.automatic_printing")}</Label>
                            <Col sm={9}>
                                <CategoriesField name="options.automatic_printing.articlecategories" categories={articlecategories} multi/>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
            </Form>
    );
}

const mapDispatchToProps = {}
PrinterForm = reduxForm({ form: 'printerForm' })(PrinterForm)
PrinterForm = connect(state => ({}), mapDispatchToProps)(PrinterForm)

export default PrinterForm;
