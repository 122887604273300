import React, { useEffect, useState } from 'react';

import './FillBar.css';

//Credit : https://dev.to/ramonak/react-how-to-create-a-custom-progress-bar-component-in-5-minutes-2lcl
const FillBar = ({ bars, clickable, label }) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = (val) => {
        if (clickable)
            setIsClicked(val);
    }

    useEffect(() => {
      setIsClicked(false)
    }, [bars])

    //Bars format :
    //[
    //    {
    //        width : Percentage of the bar width out of 100, (Sum of all objects width should be 100)
    //        color: Color of the bar,
    //        text: Text on bar
    //        baseInt : integer (Number from which % width is calculated)
    //    }
    //]
    return (
        <div className="FillBar">
            {!isClicked &&
                <div className="bar" onClick={() => handleClick(true)}>
                    {label && <label className="d-flex w-25 pre-label">{label}</label>}
                    {bars.filter(b => b.width > 0).map((b, index) =>
                        <div key={index} className="filler" style={{ width: `${b.width}%`, backgroundColor: b.color }}>
                            <span>{`${b.baseInt} ${b.text}`}</span>
                        </div>
                    )}
                </div>
            }
            {isClicked &&
                <div className="bar" onClick={() => handleClick(false)}>
                    {label && <label className="d-flex w-25 pre-label">{label}</label>}
                    {bars.map((b, index) =>
                        <div key={index} className="filler" style={{ width: `${100 / bars.length}%`, backgroundColor: b.color }}>
                            <span key={index}>{`${b.baseInt} ${b.text}`}</span>
                        </div>
                    )}
                </div>
            }
        </div>
    );
};

export default FillBar;
