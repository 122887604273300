import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ArticlecategoryForm from '../ArticlecategoryForm/ArticlecategoryForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadArticlecategory, updateArticlecategory } from '../../../redux/modules/articlecategories';

class ArticlecategoryEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const articlecategoryId = this.props.match.params.articlecategoryId;
        // Load articlecategories on component mount
        this.props.dispatchLoadArticlecategory(articlecategoryId);
    }

    submit(values) {
        // Ensure pos is an array of objects
        values.pos = values.pos.map((v) => {
            return v._id ? v : {'_id': v }
        });

        this.props.dispatchUpdateArticlecategory(values);
    }

    render() {
        const { articlecategory } = this.props.articlecategories;
        const { t } = this.props;

        const default_lang = Utils.default_lang();

        let title = <span>{t('articlecategories.articlecategory')}</span>;
        if (articlecategory)
            title = (
                <span>
                    {t('articlecategories.articlecategory')}
                    <small> |&nbsp;{ t('articlecategories.update_articlecategory', { name: articlecategory.name ? articlecategory.name[default_lang] : "" })} </small>
                </span>
            );

        const actions =
        <div>
            {articlecategory &&
            <ButtonGroup>
                <Button tag={Link} to={ `/articlecategories/show/${ articlecategory._id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="ArticlecategoryEdit">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {articlecategory &&
                    <ArticlecategoryForm articlecategoryId={articlecategory._id} onSubmit={this.submit} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadArticlecategory: (articlecategoryId) => loadArticlecategory(articlecategoryId),
    dispatchUpdateArticlecategory: (articlecategory) => updateArticlecategory(articlecategory)
}

const mapStateToProps = state => ({
    articlecategories: state.articlecategories,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ArticlecategoryEdit))
