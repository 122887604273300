import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, FormGroup, CardTitle } from 'reactstrap';
import { Setting } from '../../../models';
import { Field } from 'redux-form';
import ScreenWidgetsSettings from './ScreenWidgetsSettings/ScreenWidgetsSettings';
import SettingField from '../../SettingField/SettingField';
import ItemsList from '../../ItemsList/ItemsList';
import HomeSettings from './HomeSettings/HomeSettings';
import ProgramSettings from './ProgramSettings/ProgramSettings';
import NewsSettings from './NewsSettings/NewsSettings';
import TicketsSettings from './TicketsSettings/TicketsSettings';
import ShopSettings from './ShopSettings/ShopSettings';
import CartSettings from './CartSettings/CartSettings';
import FavoritesSettings from './FavoritesSettings/FavoritesSettings';
import RatingsSettings from './RatingsSettings/RatingsSettings';
import { Utils } from '../../../services';

import './ScreensSettings.css';

const ScreensSettings = (props) => {
    const { t } = useTranslation();

    const [activeScreen, showScreen] = useState('home');

    if (!props.setting)
        return null;

    const { env, setting } = props;

    const items = [
        { id: 'home', icon: 'home' },
        { id: 'program', icon: 'calendar' },
        { id: 'news', icon: 'info' },
        { id: 'tickets', icon: 'tag' },
        { id: 'shop', icon: 'shopping-basket' },
        { id: 'cart', icon: 'shopping-cart' },
        { id: 'favorites', icon: 'heart' },
        { id: 'ratings', icon: 'star' },
    ].map(screen => ({
        key: screen.id,
        selection: screen.id,
        title: t(`settings.mobile.screens.${screen.id}`),
        icon: screen.icon,
        active: screen.id === activeScreen,
        depth: 0
    }));

    const screenSpecificSettings = screen => {
        switch (screen) {
            case 'home': return <HomeSettings { ...props } env={ env } />
            case 'program': return <ProgramSettings { ...props } env={ env } />
            case 'news': return <NewsSettings { ...props } env={ env } />
            case 'tickets': return <TicketsSettings { ...props } env={ env } />
            case 'shop': return <ShopSettings { ...props } env={ env } />
            case 'cart': return <CartSettings { ...props } env={ env } />
            case 'favorites': return <FavoritesSettings { ...props } env={ env } />
            case 'ratings': return <RatingsSettings { ...props } env={ env } />
        }

        return null;
    };

    return (
        <div className="ScreensSettings">
            <Row>
                <Col sm={ 3 }>
                    <ItemsList
                        title={ t('settings.mobile.screens.choose_a_screen') }
                        items={ items }
                        onSelect={ showScreen }
                    />
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <div className="ScreenWidgetsSettings">
                                <CardTitle className="small">
                                    { t('settings.mobile.screens.settings.title') }
                                </CardTitle>
                                { /* Common settings */ }
                                <FormGroup row className="widget-setting">
                                    <Label className="col-sm-4">
                                        { t('settings.mobile.screens.settings.fixed_navigation') }
                                    </Label>
                                    <Col sm={ 8 }>
                                        <Field
                                            component="select"
                                            className="form-control"
                                            type="text"
                                            name={ `mobile.${env}.screens.${activeScreen}.fixedNavigation` }
                                            normalize={Utils.normalizeBoolean}
                                        >
                                            <option key="false" value={false}>{t("common.no")}</option>
                                            <option key="true" value={true}>{t("common.yes")}</option>
                                        </Field>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="widget-setting">
                                    <Label className="col-sm-4">
                                        { t('settings.mobile.screens.settings.screen_background') }
                                    </Label>
                                    <Col sm={ 8 }>
                                        <Field
                                            component="select"
                                            className="form-control"
                                            type="text"
                                            name={ `mobile.${env}.screens.${activeScreen}.useBackgroundImage` }
                                            normalize={Utils.normalizeBoolean}
                                        >
                                            <option key="false" value={false}>{t('settings.mobile.screens.settings.background_color')}</option>
                                            <option key="true" value={true}>{t('settings.mobile.screens.settings.background_image')}</option>
                                        </Field>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="widget-setting">
                                    <Label className="col-sm-4">
                                        { t('settings.mobile.screens.settings.allow_scroll') }
                                    </Label>
                                    <Col sm={ 8 }>
                                        <Field
                                            component="select"
                                            className="form-control"
                                            type="text"
                                            name={ `mobile.${env}.screens.${activeScreen}.noScroll` }
                                            normalize={Utils.normalizeBoolean}
                                        >
                                            <option key="true" value={true}>{t("common.no")}</option>
                                            <option key="false" value={false}>{t("common.yes")}</option>
                                        </Field>
                                    </Col>
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                    { screenSpecificSettings(activeScreen) }
                    <Row>
                        <Col>
                            <ScreenWidgetsSettings
                                { ...props }
                                env={ env }
                                screen={ activeScreen }
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default ScreensSettings;
