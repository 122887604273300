import React from 'react';
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import Map from './Map/Map';
import Collectors from './Collectors/Collectors';
import Printers from './Printers/Printers';
import Efts from './Efts/Efts';
import { SioConnector } from '../../components';

import './Devices.css';

const Devices = ({ match }) => {
    const { user } = useSelector(state => state.auth);
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.settings);

    const { api_key, engine_url } = user.tkt_instance;
    return (
        <div className="Devices">
            <SioConnector apiKey={api_key} serverUrl={engine_url}>
                <>
                    {isLoading &&
                        <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                    }

                    {hasLoadingError &&
                        <div
                            className="alert alert-danger"
                            dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                    }

                    <Route path="/devices/collectors"><Collectors /></Route>
                    <Route path="/devices/printers"><Printers /></Route>
                    <Route path="/devices/efts"><Efts /></Route>
                    <Route path="/devices/list"><Dashboard /></Route>
                    <Route exact path={ `${ match.url }/` } component={Map} />
                </>
            </SioConnector>
        </div>
    );
}

export default Devices;
