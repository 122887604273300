import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import i18n from '../../locales/i18n';
import PaymentMethodForm from './PaymentMethodForm/PaymentMethodForm';
import ItemsList from '../ItemsList/ItemsList';

import './PaymentMethodsForm.css';

const PaymentMethodsForm = ({ setting }) => {
    const { t } = useTranslation();

    const { payment } = setting;
    const [ activeMethodIndex, setActiveMethodIndex ] = useState(0);

    const onSelect = index => {
        setActiveMethodIndex(index);
    };

    const icon = method => {
        return method.enabled ? 'chevron-right' : 'times';
    }

    const indexedMethods = payment?.methods.map((method, index) => ({ ...method, index }));
    const items = [].concat(indexedMethods).map(method => ({
        key: method._id,
        selection: method.index,
        title: method.name[i18n.language],
        icon: icon(method),
        active: method.index === activeMethodIndex,
        depth: 0
    }));

    return (
        <Row>
            <Col sm={12} lg={3}>
                <ItemsList
                    title={ t('settings.payment.methods.title') }
                    items={ items }
                    onSelect={ onSelect }
                />
            </Col>
            <Col sm={12} lg={9}>
                <PaymentMethodForm name={ `payment.methods[${ activeMethodIndex }]` } methodIndex={ activeMethodIndex } method={ payment?.methods[activeMethodIndex] } />
            </Col>
        </Row>
    );
}

export default PaymentMethodsForm;
