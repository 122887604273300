import React, { useState, useEffect } from 'react';
import { loadStats, loadStat, resetStat } from '../../../redux/modules/stats';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Col, Input } from 'reactstrap';
import Widget from '../Widget';
import WidgetSharedSettings from '../WidgetSharedSettings/WidgetSharedSettings';
import { BarChart } from '../../../components/Chart/';
import './GraphBoxOffice.css';
import moment from 'moment';
import _ from 'lodash';

const GraphBoxOffice = ({ props, saveSettings, index, insert, name, onCancel }) => {
    const { t }                      = useTranslation();
    const dispatch                   = useDispatch();
    const { stat, stats, isLoading } = useSelector(state => state.stats);
    const [dataGraph, setDataGraph]  = useState([])
    const [legend, setLegend]        = useState("")

    useEffect(() => {
        if (!saveSettings) {
            switch (props.timeWindow) {
                case "daily":
                    props.typeStats === "nbTotal" ?
                        setLegend(t("widgets.24h_entries")) :
                        setLegend(t("widgets.24h_revenue"))
                    break;
                case "weekly":
                    props.typeStats === "nbTotal" ?
                        setLegend(t("widgets.7d_entries")) :
                        setLegend(t("widgets.7d_revenue"))
                    break;
            }
            if (!props.noCompute) {
                let settings = {
                    start_date: null,
                    end_date: null,
                    cinema: null,
                    room: null,
                    movie: null
                }
                switch (props.timeWindow) {
                    case "daily":
                        settings.start_date = moment().subtract(1, "days").add(1, "hours").toISOString();
                        settings.end_date = moment().add(1, "hours").toISOString();
                        dispatch(loadStat("box_office", settings));
                        break;
                    case "weekly":
                        settings.start_date = moment().subtract(1, "weeks").add(1, "hours").toISOString();
                        settings.end_date = moment().add(1, "hours").toISOString();
                        dispatch(loadStats("box_office", settings));
                        break;
                }
            }
        }
    }, [])

    const ellipse = string => {
        if (string.length < 22)
            return string;

        return string.substring(0, 19) + '...';
    }

    const formatDataGraph = (data) => {
        try {
            let temp = Object.keys(data.films).map(film => {
                let total = data?.films[film]?.stats[props.typeStats];
                return { id: ellipse(film), total };
            })

            let temp2 = _.orderBy(temp, ["total"], ["desc"])
            temp = _.slice(temp2, 0, props?.maxEntries)

            setDataGraph(temp)
        } catch { }
    }

    useEffect(() => {
        if (!saveSettings && props.timeWindow === "weekly" && !props.noCompute) {
            formatDataGraph(stats)
        }
    }, [stats])

    useEffect(() => {
        if (!saveSettings && props.timeWindow === "daily" && !props.noCompute) {
            formatDataGraph(stat)
        }

        return function cleanup() {
            dispatch(resetStat())
        }
    }, [stat])

    //Use this function to format props to send to parent
    //Index of edited widget is passed here then back to parent so we need it
    const handleSubmit = (e) => {
        //Prevent refresh on form submit
        e.preventDefault();

        let settings = {
            width: e.target.widgetWidth.value,
            bgColor: e.target.widgetBgTransparent.checked ? "rgb(0,0,0,0)": e.target.widgetBgColor.value,
            timeWindow: e.target.selectTimeWindow.value,
            maxEntries: e.target.selectMaxEntries.value,
            typeStats: e.target.selectTypeStats.value
        }

        //IN ARRAY!
        saveSettings([settings, index, insert, name]);
    }

    return (
        <div className='GraphBoxOffice'>
            {
                (!saveSettings) ?
                    <Widget content={
                        (dataGraph && !props.noCompute) ?
                            <div style={{ height: "100%", width: "100%" }}>
                                <div className='h-10 border-bottom'>
                                    <h5>{legend}</h5>
                                </div>
                                <div style={{ height: '300px' }}>
                                    <BarChart
                                        data={dataGraph}
                                        keys={["total"]}
                                        axisLeftLegend=""
                                        axisBottomLegend=""
                                        axisBottomTickRotation={ -45 }
                                        tooltipFormat="$"
                                        enableLabel
                                        label={d => props?.typeStats === "total" ? d.value.toFixed(2) + " CHF" : d.value}
                                        noLegend
                                        customMargin={10}
                                    />
                                </div>
                            </div>
                            :
                            <div className="noComputeWrapper">
                                <div className='h-10 border-bottom'>
                                    <h5>{legend}</h5>
                                </div>
                                <div className='noCompute'>
                                    <h1><i className="fa fa-bar-chart fa-3x"></i></h1>
                                </div>
                            </div>
                    }
                        bgColor={props.bgColor}
                        noCenter={props.noCompute ? true : false}
                    />
                    : // Do your widget settings form here
                    // TODO : Bind settings from state hook to HTML form tags
                    <Form onSubmit={handleSubmit}>
                        <Row className='ml-0 mr-0 pb-2 border-bottom'>
                            <Col className='col-sm-12 col-md-12 col-lg-6'>
                                <Row className='text-center'>
                                    <h4 className='border-bottom pb-1 mb-2 w-100 mr-1'>{t("widgets.settings.parameters")}</h4>
                                </Row>
                                <Row>
                                    {/** Stats type (revenue or entries box office) */}
                                    <Col>
                                        <div className='settingsFormLabel'>
                                            {t('statstypes.statstype')}
                                        </div>
                                    </Col>
                                    <Col className='col-3'>
                                        <div className='settingsFormInput'>
                                            <Input
                                                className='settingsInput'
                                                name="selectTypeStats"
                                                type="select"
                                                defaultValue={props?.typeStats ? props.typeStats : "nbTotal"}
                                            >
                                                <option value="nbTotal">{t("statstypes.entries")}</option>
                                                <option value="total">{t("statstypes.chart.revenue")}</option>
                                            </Input>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {/** Time window */}
                                    <Col>
                                        <div className='settingsFormLabel'>
                                            {t('widgets.settings.time_window')}
                                        </div>
                                    </Col>
                                    <Col className='col-3'>
                                        <div className='settingsFormInput'>
                                            <Input
                                                className='settingsInput'
                                                name="selectTimeWindow"
                                                type="select"
                                                defaultValue={props?.timeWindow ? props.timeWindow : "daily"}
                                            >
                                                <option value="daily">{t("widgets.settings.daily")}</option>
                                                <option value="weekly">{t("widgets.settings.weekly")}</option>
                                            </Input>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {/**Number of items */}
                                    <Col>
                                        <div className='settingsFormLabel'>
                                            {t('widgets.settings.entries_max')}
                                        </div>
                                    </Col>
                                    <Col className='col-3'>
                                        <div className='settingsFormInput'>
                                            <Input
                                                className='settingsInput'
                                                name="selectMaxEntries"
                                                type="select"
                                                defaultValue={props?.maxEntries ? props.maxEntries : "5"}
                                            >
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                            </Input>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            {/* Widget global display parameters */}
                            <Col className='col-sm-12 col-md-12 col-lg-6'>
                                <WidgetSharedSettings
                                    width={props?.width ? props.width : 1}
                                    bgColor={props?.bgColor ? props.bgColor : "rgb(0,0,0,0)"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="submitButtons">
                                    <Button onClick={() => onCancel()} className="mr-2" color="danger"><i className="fa fa-times"></i>&nbsp;{t("widgets.cancel")}</Button>&nbsp;
                                    <Button type="submit" color="success"><i className="fa fa-floppy-o"></i>&nbsp;{t("widgets.save")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
            }
        </div>
    )
}

export default GraphBoxOffice;
