import RestClient from './RestClient';
import { Log } from '../../models';

class Logs extends RestClient {
    constructor() {
        super({
            "model": Log,
            "entryPoint": "log",
            "resource": "log",
            "resources": "logs",
            "id_field": "_id"
        });
    }
}

const LogsApi = new Logs();

export default LogsApi;
