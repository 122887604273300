import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';

import './ClearableTextInput.css';

const ClearableTextInput = ({name, onChange}) => {
    const [value, setValue] = useState("")

    const clear = () => {
        setValue("");
        onChange("");
    }

    return (
        <div className="ClearableTextInput">
            {value !== "" &&
                <Button className="clearbtn" onClick={clear}><i className="fa fa-times"></i></Button>
            }
            <Input
                type="text"
                name={name ?? ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }} 
                value={value}
            />
        </div>
    )
}

export default ClearableTextInput;
