import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field } from 'redux-form';
import { withTranslation } from 'react-i18next';

import "./RenounceModal.css"

class RenounceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            text: ""
        };

        this.toggle         = this.toggle.bind(this);
        this.on_change_text = this.on_change_text.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    on_change_text(e) {
        this.setState({
            text: e.target.value
        });
    }

    render() {
        const { task, isOpen, onClose, onSubmit, t } = this.props;

        return (
            <div className="RenounceModal">
                <Modal isOpen={isOpen} toggle={onClose} className={this.props.className} contentClassName="modalCustomClass">
                    <ModalHeader toggle={onClose}>
                        {t('tasks.renounce_modal.title')}
                    </ModalHeader>
                    <ModalBody>
                        <textarea required onChange={this.on_change_text} rows="5" className="form-control"></textarea>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            disabled={this.state.text.length == 0}
                            onClick={(e) => {
                                onSubmit(this.state.text);
                                this.setState({text: "" });
                            }}>
                            <i className="fa fa-save"></i>&nbsp;
                            {t('common.save')}
                        </Button>&nbsp;
                        <Button color="secondary" onClick={onClose}>
                            <i className="fa fa-close"></i>&nbsp;
                            {t('common.cancel')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withTranslation("translations")(RenounceModal);
