import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import ArticlecategoryForm from '../ArticlecategoryForm/ArticlecategoryForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateArticlecategory, createArticlecategory } from '../../../redux/modules/articlecategories';

class ArticlecategoryCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        // Reset articlecategory in app state
        this.props.dispatchStartCreateArticlecategory();
    }

    submit(values) {
        // Ensure pos is an array of objects
        values.pos = values.pos.map((v) => {
            return v._id ? v : {'_id': v }
        });

        this.props.dispatchCreateArticlecategory(values);
    }

    render() {
        const { articlecategorySuccessfullyCreated } = this.props.articlecategories;
        const { t } = this.props;

        let title = <span>{t('articlecategories.add')}</span>;

        const actions =
            <Button tag={Link} to="/articlecategories" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="ArticlecategoryCreate">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {articlecategorySuccessfullyCreated &&
                    <Redirect to="/articlecategories"/>
                }

                <ArticlecategoryForm onSubmit={this.submit} isCreation="true" />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateArticlecategory: (articlecategory) => startCreateArticlecategory(articlecategory),
    dispatchCreateArticlecategory: (articlecategory) => createArticlecategory(articlecategory)
}

const mapStateToProps = state => ({
    articlecategories: state.articlecategories,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ArticlecategoryCreate))
