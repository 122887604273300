import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, FormGroup, CardTitle } from 'reactstrap';
import { Field } from 'redux-form';
import { Utils } from '../../../services';
import InlineImageField from '../../InlineImageField/InlineImageField';
import SettingField from '../../SettingField/SettingField';

import './ThemeSettings.css';

const ThemeSettings = (props) => {
    const { t } = useTranslation();
    const { env } = props;

    if (!props.setting)
        return null;

    return (
        <div className="ThemeSettings">
            <Row>
                <Col>
                    <div className="SettingField">
                        <CardTitle className="small">
                            { t('settings.mobile.theme.logo') }
                        </CardTitle>
                        <InlineImageField
                            name={ `mobile.${env}.theme.logo` }
                            image={ props.setting.mobile[env]?.theme?.logo }
                            hideOptions
                        />
                    </div>
                </Col>
                <Col>
                    <div className="SettingField">
                        <CardTitle className="small">
                            { t('settings.mobile.theme.splash') }
                        </CardTitle>
                        <InlineImageField
                            name={ `mobile.${env}.theme.splash` }
                            image={ props.setting.mobile[env]?.theme?.splash }
                            hideOptions
                        />
                    </div>
                </Col>
                <Col>
                    <div className="SettingField">
                        <CardTitle className="small">
                            { t('settings.mobile.theme.background_image') }
                        </CardTitle>
                        <InlineImageField
                            name={ `mobile.${env}.theme.backgroundImage` }
                            image={ props.setting.mobile[env]?.theme?.backgroundImage }
                            hideOptions
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="SettingField">
                        <CardTitle className="small">
                            { t('settings.mobile.theme.style.title') }
                        </CardTitle>
                        <FormGroup row className="widget-setting">
                            <Label className="col-sm-4">
                                { t('settings.mobile.theme.style.border_radius') }
                            </Label>
                            <Col sm={ 8 }>
                                <Field
                                    component="input"
                                    className="form-control"
                                    type="number"
                                    step={ 1 }
                                    min={ 0 }
                                    max={ 45 }
                                    name={ `mobile.${env}.theme.style.borderRadius` }
                                    normalize={Utils.normalizeInt}
                                />
                            </Col>
                        </FormGroup>
                    </div>
                </Col>
            </Row>
            <SettingField { ...props } showTitle name={`mobile.${env}.theme.colors`} hideLabel={ false } />
            <SettingField { ...props } showTitle name={`mobile.${env}.images`} hideLabel={ false } />
        </div>
    );
}

export default ThemeSettings;
