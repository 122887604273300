import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ChannelForm from '../ChannelForm/ChannelForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Utils } from '../../../services';

import { loadChannel, updateChannel } from '../../../redux/modules/channels';

const PostCategoryEdit = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const channelId = match.params.channelId;
        dispatch(loadChannel(channelId));
    }, [dispatch])

    const submit = (values) => {
        dispatch(updateChannel(values));
    }

    const { channel } = useSelector(state => state.channels);

    let title = <span>{t('channels.update_channel')}</span>;

    if (channel && channel.name)
        title = (
            <span>
                {t('channels.channel')}
                <small> |&nbsp;{ t('channels.update_channel', { name: channel.name })} </small>
            </span>
        );

    const actions =
        <div>
            {channel &&
            <ButtonGroup>
                <Button  tag={Link} to="/channels" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="PostCategoryEdit">
                <PageTitle icon="tags" title={ title } actions={ actions } />

                {channel &&
                    <ChannelForm onSubmit={ submit } />
                }
            </div>
        );
}

export default PostCategoryEdit;
