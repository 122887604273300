import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { Button, Form, Row, Col, Input } from 'reactstrap';
import Widget from '../Widget';
import WidgetSharedSettings from '../WidgetSharedSettings/WidgetSharedSettings';
import './Shortcut.css';

const Shortcut = ({ props, saveSettings, index, onCancel, insert, name }) => {
    const { t } = useTranslation();

    //[title, link, fontawesome 4 icon]
    //Add first index to option picker in JSX!
    const shortcutList = [
        ["widgets.settings.chose_shortcut", "", ""],
        ["programmations.add", "/programmations/new/", "calendar-plus-o"],
        ["events.add_movie", "/events/movie/new", "film"],
        ["events.add_artist", "/events/music_group/new", "music"],
        ["events.add_play", "/events/drama/new", "puzzle-piece"],
        ["events.add_service", "/events/service/new", "gift"],
    ];

    const getOptions = () => {
        let temp = []
        for (let i = 0; i < shortcutList.length; i++) {
            temp.push(<option key={i} value={i}>{t(shortcutList[i][0])}</option>);
        };
        return temp
    }

    const optionsHtml = getOptions();

    //Use this function to format props to send to parent
    //Index of edited widget is passed here then back to parent so we need it
    const handleSubmit = (e) => {
        //Prevent refresh on form submit
        e.preventDefault();
        if (e.target.selectLink.value !== "0") {
            let settings = {
                width: e.target.widgetWidth.value,
                shortcut: [
                    shortcutList[e.target.selectLink.value][0],
                    shortcutList[e.target.selectLink.value][1],
                    shortcutList[e.target.selectLink.value][2],
                    e.target.selectLink.value
                ]
            }
            //IN ARRAY!
            saveSettings([settings, index, insert, name]);
        } else alert(t("widgets.settings.chose_shortcut_alert"))
    };

    return (
        <div className="Shortcut">
            {
                (!saveSettings) ?
                    <Link
                        className='shortcut m-0 pb-0'
                        to={props.shortcut[1]}
                    >
                        <Widget content=
                            {
                                <div>
                                    <p>{t(props.shortcut[0])}</p>
                                    <i className={"fa fa-" + props.shortcut[2]}></i>
                                </div>
                            }
                            border="solid darkgray"
                            roundBorder
                            clickable
                        />
                    </Link>
                    : // Do your widget settings form here
                    // TODO : Bind settings from state hook to HTML form tags
                    <Form onSubmit={handleSubmit}>
                        <Row className='ml-0 mr-0 pb-2 border-bottom'>
                            <Col className='col-sm-12 col-md-12 col-lg-6'>
                                <Row className='text-center'>
                                    <h4 className='border-bottom pb-1 mb-2 w-100 mr-1'>{t("widgets.settings.parameters")}</h4>
                                </Row>
                                <Row>
                                    {/** Handle widget shortcut */}
                                    <Col>
                                        <div className='settingsFormLabel'>
                                            {t('widgets.settings.shortcut_target')}
                                        </div>
                                    </Col>
                                    <Col className='col-6'>
                                        <div className='settingsFormInput'>
                                            <Input
                                                className='settingsInput'
                                                name="selectLink"
                                                type="select"
                                                defaultValue={props?.shortcut ? props.shortcut[3] : 0}
                                            >
                                                <option key={0} value={0} hidden>{t(shortcutList[0][0])}</option>
                                                <option key={1} value={1}>{t(shortcutList[1][0])}</option>
                                                <option key={2} value={2}>{t(shortcutList[2][0])}</option>
                                                <option key={3} value={3}>{t(shortcutList[3][0])}</option>
                                                <option key={4} value={4}>{t(shortcutList[4][0])}</option>
                                            </Input>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className='col-sm-12 col-md-12 col-lg-6'>
                                <WidgetSharedSettings
                                    width={props?.width ? props?.width : 1}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="submitButtons">
                                    <Button onClick={() => onCancel()} color="danger"><i className="fa fa-times"></i>&nbsp;{t("widgets.cancel")}</Button>&nbsp;
                                    <Button type="submit" color="success"><i className="fa fa-floppy-o"></i>&nbsp;{t("widgets.save")}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
            }
        </div>
    )
}

export default Shortcut;
