import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageTitle, EftResume, Loader } from '../../../../components';
import { loadEfts } from '../../../../redux';
import { Row, Col, Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiArrowLeftCircle } from '@mdi/js';

const EftsList = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { connected }  = useSelector(state => state.sioBridge);
    const { efts } = useSelector(state => state.efts, shallowEqual);
    const { isLoading }  = useSelector(state => state.efts);

    useEffect(() => {
        if (connected)
            dispatch(loadEfts());
    }, [dispatch, connected]);

    const actions = <Link to="/devices">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    return (
        <div className="EftsList">
            <PageTitle
                title={ t('efts.title') }
                icon="credit-card"
                actions={ actions }
            />

            {isLoading && (
                <Loader />
            )}

            {!isLoading && (
            <div className="mt-3">
                <Row>
                { efts && efts.filter(eft => eft.isConnected()).map(eft => (
                    <Col key={ eft.id } lg={ 2 } md={ 4 } sm={ 6 } xs={ 12 }>
                        <EftResume eft={ eft } className="mb-3" />
                    </Col>
                ))}
                { efts && efts.filter(eft => !eft.isConnected()).map(eft => (
                    <Col key={ eft.id } lg={ 2 } md={ 4 } sm={ 6 } xs={ 12 }>
                        <EftResume eft={ eft } className="mb-3" />
                    </Col>
                ))}
                </Row>
            </div>
            )}
        </div>
    );
}

export default EftsList;
