import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './FlashInfo.css';

const FlashInfo = ({ className }) => {
    const { t } = useTranslation();

    const title    = t('flash_info.title');
    const subtitle = t('flash_info.subtitle');
    const content  = t('flash_info.content');

    const info = () => {
        if (!title?.length || !content?.length)
            return null;

        return (
            <div className="alert alert-info">
                <h4>
                    <i className="fa fa-bolt" />&nbsp;
                    {title}
                </h4>
                <div className="subtitle">{subtitle}</div>
                <div className="content">{content}</div>
            </div>
        );
    }

    return (
        <div className={`FlashInfo ${className || ''}`}>
            { info() }
        </div>
    );
}

export default FlashInfo;
