import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';

const LocalesField = ({ name, single }) => {
    const { t }   = useTranslation();
    const options = Utils.locales().map(locale => {
        return {
            key: locale,
            text: t('locales.' + locale),
            value: locale
        };
    });

    return single ? (
        <SelectField name={ name } options={ options } />
    ) : (
        <MultiSelectField name={ name } options={ options } />
    );
}

export default LocalesField;
