import React, { Component } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';

import './BarcodeField.css';

const BarcodeField = ({ name, ...rest }) => {
    const currency = Utils.currency();

    return (
        <div className="BarcodeField">
            <InputGroup>
                <Field component="input" type="text" className="form-control" name={ name } { ...rest } />
                <InputGroupAddon addonType="append">
                    <InputGroupText>
                        <i className="fa fa-barcode" />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </div>
    );
}

export default BarcodeField;
