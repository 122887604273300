import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import TktInstancesList from './TktInstancesList/TktInstancesList';
import TktInstanceShow from './TktInstanceShow/TktInstanceShow';
import TktInstanceEdit from './TktInstanceEdit/TktInstanceEdit';
import TktInstanceCreate from './TktInstanceCreate/TktInstanceCreate';
import TktInstanceDelete from './TktInstanceDelete/TktInstanceDelete';

class TktInstances extends Component<{}> {
    render() {
        const {
            isLoading,
            hasLoadingError,
            loadingErrorMessage
        }               = this.props.tktInstances;
        const { match } = this.props;
        const user      = this.props.auth.user;

        return (
            <div className="TktInstances">
                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{ __html: loadingErrorMessage }} />
                }
                <Route exact path={match.path} component={TktInstancesList} />
                <Route exact path={`${match.url}/new`} component={TktInstanceCreate} />
                <Route exact path={`${match.url}/show/:tktInstanceId`} component={TktInstanceShow} />
                <Route exact path={`${match.url}/delete/:tktInstanceId`} component={TktInstanceDelete} />
                <Route exact path={`${match.url}/edit/:tktInstanceId`} component={TktInstanceEdit} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    tktInstances: state.tktInstances,
    auth: state.auth
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TktInstances));
