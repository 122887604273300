import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Input } from 'reactstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import matchSorter from 'match-sorter';
import { withTranslation } from 'react-i18next';
import 'react-table-6/react-table.css'
import { loadDistributors } from '../../../redux/modules/distributors';
import Table from '../../../components/Table/Table';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';

class DistributorsList extends Component<{}> {
    componentDidMount() {
        // Load distributors on component mount
        this.props.dispatchLoadDistributors();
    }

    render() {
        const { t }            = this.props;
        const { distributors } = this.props.distributors;

        const textFilter = ({ filter, onChange }) =>
            <Input
                type="text"
                onChange={event => onChange(event.target.value)}
            />;
        const textFilterMethod = (key) => (filter, rows) => matchSorter(rows, filter.value, { keys: [key] });

        const columns = [{
            Header: t("distributors.name"),
            accessor: "name",
            filterMethod: textFilterMethod("name"),
            Filter: textFilter,
            filterAll: true
        }, {
            Header: t("distributors.maccsbox_id"),
            accessor: "maccsbox_id",
            filterMethod: textFilterMethod("maccsbox_id"),
            Filter: textFilter,
            filterAll: true
        }, {
            Header: t("common.actions"),
            accessor: "id",
            maxWidth: 110,
            filterable: false,
            Cell: row => (
                <TableButtons
                    id={row.value}
                    actions={["show", "edit", "delete"]}
                    module="distributors"
                    owner={row.original.created_by}
                />
            )
        }];

        const actions =
            <CreateButton
                module={`distributors`}
                text="distributors.add"
            />

        const title =
        <span>{t("distributors.distributor_plural")}</span>

        return (
            <div className="DistributorsList">
                <PageTitle icon="money" title={ title } actions={ actions } />
                <Table id="distributorsTable" data={ distributors } columns={ columns } defaultPageSize={7} />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadDistributors: () => loadDistributors()
}

const mapStateToProps = state => ({
    distributors: state.distributors
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(DistributorsList))
