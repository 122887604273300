import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form'
import { loadLayouts, loadSetting } from '../../redux';
import { Row, Col, Card, CardBody, CardText, CardTitle, Button } from "reactstrap";
import _ from "lodash";

import './PdfLayoutMappings.css';

const PdfLayoutMappings = ({ name, type, removeLayout, id, pricingName }) => {
    const storedLayouts = useSelector(state => state.layouts.layouts);

    const dispatch    = useDispatch();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        dispatch(loadLayouts());
        dispatch(loadSetting('default'));
    }, []);

    const layouts = React.useMemo(() => {
        return (storedLayouts || []).filter(l => l.type === type);
    }, [type, storedLayouts]);

    const targets = React.useMemo(() => _.uniq(layouts.map(l => l.target)), [layouts]);

    const onRemoveLayout = () => {
        removeLayout(id);
    }

    return (
        <div className="PdfLayoutMappings">
            <Card>
                <CardBody>
                    <CardTitle>
                        {id ?? t('salepoints.default')}{pricingName && ` - ${pricingName}`}
                        {removeLayout &&
                            <Button type="button" className="float-right" color="danger" onClick={onRemoveLayout}><i className="fa fa-times"></i></Button>
                        }
                    </CardTitle>
                    <Row>
                        {targets?.map(target => (
                            <Col key={target} className="PdfLayoutMapping" sm={3}>
                                <Card className="h-auto inner-card">
                                    <CardBody>
                                        <CardTitle className="small">
                                            {t(`layouts.target_${target}`)}
                                        </CardTitle>
                                        <CardText tag="div">
                                            <Field component="select" name={`${name}.${target}`} className="form-control">
                                                <option value="">{t('layouts.please_choose_a_layout')}</option>
                                                {layouts?.filter(l => l.target === target).map(layout => (
                                                    <option key={layout._id} value={layout._id}>{layout.name[i18n.language]}</option>
                                                ))}
                                            </Field>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </CardBody>
            </Card>
        </div >
    );
}

export default PdfLayoutMappings;
