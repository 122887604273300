// Redux Places module
// see https://github.com/erikras/ducks-modular-redux

import { Places } from '../../services/';


const LOADING_PLACES         = 'kronos/places/LOADING_PLACES';
const LOADING_PLACES_SUCCESS = 'kronos/places/LOADING_PLACES_SUCCESS';
const LOADING_PLACES_FAILURE = 'kronos/places/LOADING_PLACES_FAILURE';
const LOADING_PLACE          = 'kronos/places/LOADING_PLACE';
const LOADING_PLACE_SUCCESS  = 'kronos/places/LOADING_PLACE_SUCCESS';
const LOADING_PLACE_FAILURE  = 'kronos/places/LOADING_PLACE_FAILURE';
const UPDATING_PLACE         = 'kronos/places/UPDATING_PLACE';
const UPDATING_PLACE_SUCCESS = 'kronos/places/UPDATING_PLACE_SUCCESS';
const UPDATING_PLACE_FAILURE = 'kronos/places/UPDATING_PLACE_FAILURE';
const START_CREATING_PLACE   = 'kronos/places/START_CREATING_PLACE';
const CREATING_PLACE         = 'kronos/places/CREATING_PLACE';
const CREATING_PLACE_SUCCESS = 'kronos/places/CREATING_PLACE_SUCCESS';
const CREATING_PLACE_FAILURE = 'kronos/places/CREATING_PLACE_FAILURE';
const DELETING_PLACE         = 'kronos/places/DELETING_PLACE';
const DELETING_PLACE_SUCCESS = 'kronos/places/DELETING_PLACE_SUCCESS';
const DELETING_PLACE_FAILURE = 'kronos/places/DELETING_PLACE_FAILURE';
const START_CREATING_MAP     = 'kronos/places/START_CREATING_MAP';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    placeSuccessfullyCreated: false,
    places: []
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_PLACES:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_PLACES_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                places: action.places
            };
        case LOADING_PLACES_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_PLACE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_PLACE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                place: action.place
            };
        case LOADING_PLACE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_PLACE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_PLACE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                place: action.place
            };
        case UPDATING_PLACE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_PLACE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                placeSuccessfullyCreated: false,
                place: {}
            };
        case CREATING_PLACE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_PLACE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                placeSuccessfullyCreated: true,
                place: action.place
            };
        case CREATING_PLACE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                placeSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_PLACE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_PLACE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                placeSuccessfullyDeleted: true,
                place: action.place
            };
        case DELETING_PLACE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                placeSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_MAP:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                placeSuccessfullyCreated: false,
                place: {
                    ...state.place,
                    map: {
                        dim: { x: 20, y: 20, z: 1 },
                        seats: [],
                        stage_position: 'top'
                    }
                }
            };
        default:
            return state;
    }
};

// Actions
function loadingPlaces() { return { type: LOADING_PLACES } }
function loadingPlacesSuccess(places) { return { type: LOADING_PLACES_SUCCESS, places: places } }
function loadingPlacesFailure(err) { return { type: LOADING_PLACES_FAILURE, error: err } }
export function loadPlaces(placename, password) {
    return (dispatch) => {
        dispatch(loadingPlaces());
        Places.list()
            .then(data => {
                const places = data.places;
                dispatch(loadingPlacesSuccess(places));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingPlacesFailure(err))
            });
    }
}

function loadingPlace() { return { type: LOADING_PLACE } }
function loadingPlaceSuccess(place) { return { type: LOADING_PLACE_SUCCESS, place: place } }
function loadingPlaceFailure(err) { return { type: LOADING_PLACE_FAILURE, error: err } }
export function loadPlace(placeId) {
    return (dispatch) => {
        dispatch(loadingPlace());
        Places.get(placeId)
            .then(data => {
                const place = data.place;
                dispatch(loadingPlaceSuccess(place));
            })
            .catch(err => {
                dispatch(loadingPlaceFailure(err))
            });
    }
}

function updatingPlace() { return { type: UPDATING_PLACE } }
function updatingPlaceSuccess(place) { return { type: UPDATING_PLACE_SUCCESS, place: place } }
function updatingPlaceFailure(err) { return { type: UPDATING_PLACE_FAILURE, error: err } }
export function updatePlace(place) {
    return (dispatch) => {
        dispatch(updatingPlace());
        Places.update(place)
            .then(data => {
                const place = data.place;
                dispatch(updatingPlaceSuccess(place));
            })
            .catch(err => {
                dispatch(updatingPlaceFailure(err))
            });
    }
}

function creatingPlace() { return { type: CREATING_PLACE } }
function creatingPlaceSuccess(place) { return { type: CREATING_PLACE_SUCCESS, place: place } }
function creatingPlaceFailure(err) { return { type: CREATING_PLACE_FAILURE, error: err } }
export function startCreatePlace() { return { type: START_CREATING_PLACE } }
export function createPlace(place) {
    return (dispatch) => {
        dispatch(creatingPlace());
        Places.create(place)
            .then(data => {
                const place = data.place;
                dispatch(creatingPlaceSuccess(place));
                dispatch(loadingPlaceSuccess(place));
            })
            .catch(err => {
                dispatch(creatingPlaceFailure(err))
            });
    }
}

function deletingPlace() { return { type: DELETING_PLACE } }
function deletingPlaceSuccess() { return { type: DELETING_PLACE_SUCCESS } }
function deletingPlaceFailure(err) { return { type: DELETING_PLACE_FAILURE, error: err } }
export function deletePlace(placeId) {
    return (dispatch) => {
        dispatch(deletingPlace());
        Places.delete(placeId)
            .then(data => {
                dispatch(deletingPlaceSuccess());
            })
            .catch(err => {
                dispatch(deletingPlaceFailure(err))
            });
    }
}

export function startCreateMap() { return { type: START_CREATING_MAP }}
