import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../redux/modules/users';
import { Utils } from '../../services';
import MainDashboard from "./MainDashboard/MainDashboard";
import EditDashboard from './EditDashboard/EditDashboard';
import ShowWidgetList from './ShowWidgetList/ShowWidgetList';
import ShowWidgetProps from './ShowWidgetProps/ShowWidgetProps';
import "./Dashboards.css";
import baseBoards from "./baseBoards.json";
import _ from "lodash";
import UserEdit from '../Users/UserEdit/UserEdit';

const Dashboards = ({ }) => {
    //Translation / Language
    const { t }         = useTranslation();
    const default_lang  = Utils.default_lang();
    //bootstrap col
    const widgetWidth   = 3
    //View const
    const MAIN_VIEW     = "mainWidgetsView";
    const EDIT_VIEW     = "editWidgetsView"
    const LIST_VIEW     = "listWidgetsView"
    const SETTINGS_VIEW = "widgetSettingsView"

    //State
    //HTML content of page
    const [widgetsHtml, setWidgetsHtml] = useState(null);
    //Header state based on dashboard state
    const [headerState, setHeaderState] = useState(0)
    //BoardIndex, page title initial value
    const init = () => {
        switch (window.location.pathname) {
            case ("/"):
            case ("/admin"):
                return ["boardAdmin", t("app.dashboard") + " - " + t("app.section_admin"), "cog"];
            case ("/operation"):
                return ["boardOperation", t("app.dashboard") + " - " + t("app.section_operation"), "rocket"];
            case ("/analysis"):
                return ["boardAnalysis", t("app.dashboard") + " - " + t("app.section_analysis"), "line-chart"];
        }
    }
    const boardIndex = init()[0];

    //Redux
    const dispatch                    = useDispatch()
    const user                        = useSelector(state => state.auth.user);
    const board                       = useSelector(state => state.auth.user.settings[boardIndex]) || baseBoards[boardIndex];
    const { userSuccessfullyUpdated } = useSelector(state => state.users);
    const [needReload, setNeedReload] = useState(false)

    useEffect(() => {
        toggleView(MAIN_VIEW);
    }, [])

    //Dispatch / store based on path
    const dispatchHelp = (data) => {
        let temp                  = user;
        temp.settings[boardIndex] = data;
        dispatch(updateUser(temp));
    }

    const resetBoard = () => {
        if (window.confirm(t("widgets.reset_confirm"))) {
            let temp = user;
            temp.settings[boardIndex] = undefined;
            board.splice(0, board.length);
            setNeedReload(true)
            dispatch(updateUser(temp));
            toggleView(MAIN_VIEW)
        }
    }

    useEffect(() => {
        if (userSuccessfullyUpdated === true && needReload === true) {
            setNeedReload(false);
            window.location.reload();
        }
    }, [userSuccessfullyUpdated])

    //Displays widget list.
    //Index param is needed to know where we will add the widget in the board
    const listWidgets = (params) => {
        toggleView(LIST_VIEW, params)
    }

    //Displays settings of widget
    //props = [widget index, {existing props}]
    const showWidgetsSettings = (props) => {
        toggleView(SETTINGS_VIEW, props)
    }

    //when user adds a widget from widget list.
    //Sends user to widget settings 
    const addWidgetFromList = (index, name, insert) => {
        //Send user to settings of new widget
        toggleView(SETTINGS_VIEW, [index, name, insert]);
    }

    //Remove widget at index
    const deleteWidget = (index) => {
        if (window.confirm(t('widgets.delete_confirm'))) {
            board.splice(index, 1);
            dispatchHelp(board);
        }
    }

    //Function that adds modified props to widget
    const AddWidget = (propsFromChild) => {
        let props  = propsFromChild[0];
        let index  = propsFromChild[1];
        let insert = propsFromChild[2];
        let name   = propsFromChild[3];
        if (insert === "left") {
            board.splice(index, 0, [name, props])
        } else if (insert === "right") {
            board.splice(index + 1, 0, [name, props])
        } else if (insert = "editProps") {
            board.splice(index, 1, [name, props])
        } else {
            board.push([name, props])
        }
        dispatchHelp(board);
        toggleView(EDIT_VIEW);
    }

    //Handles different page state (stateID)
    //We sometimes need to pass widgetId and other params such as props or widget name between views and components
    const toggleView = (stateId, param) => {
        switch (stateId) {
            case MAIN_VIEW:
                setWidgetsHtml(
                    <MainDashboard
                        board={board}
                        key="mainWidgetsHtml"
                        width={widgetWidth}
                    />);
                setHeaderState(0);
                break;
            case EDIT_VIEW:
                setWidgetsHtml(
                    <EditDashboard
                        board={board}
                        key="editWidgetsHtml"
                        width={widgetWidth}
                        onDeleteWidget={deleteWidget}
                        showWidgetsList={listWidgets}
                        showWidgetsSettings={showWidgetsSettings}
                    />);
                setHeaderState(1);
                break;
            case LIST_VIEW:
                setWidgetsHtml(
                    <ShowWidgetList
                        addIndex={param[0]}
                        onAddWidget={addWidgetFromList}
                        insert={param[1]}
                    />);
                setHeaderState(1);
                break;
            case SETTINGS_VIEW:
                setWidgetsHtml(
                    <ShowWidgetProps
                        board={board}
                        onChangeSettings={AddWidget}
                        cancelSettings={() => toggleView(EDIT_VIEW)}
                        settingsIndex={param[0]}
                        name={param[1]}
                        insert={param[2] ? param[2] : false}
                    />
                );
                setHeaderState(1);
                break;
        }
    }

    return (
        <div className='Dashboards'>
            <PageTitle
                icon={init()[2]}
                title={init()[1]}
                actions=
                {
                    {
                        0:
                            <Button className="editViewBtn" color="info" onClick={() => toggleView(EDIT_VIEW)}>
                                <i className="fa fa-pencil-square-o"></i>&nbsp;{t("widgets.edit_dashboard")}
                            </Button>,
                        1:
                            <div>
                                <Button color="info" onClick={() => resetBoard()}>
                                    <i className="fa fa-undo"></i>&nbsp;{t("widgets.reset")}
                                </Button>&nbsp;
                                <Button color="danger" onClick={() => toggleView(MAIN_VIEW)}>
                                    <i className="fa fa-times"></i>&nbsp;{t("widgets.back")}
                                </Button>
                            </div>,
                        2:
                            <Button color="danger" onClick={() => toggleView(MAIN_VIEW)}>
                                <i className="fa fa-times"></i>&nbsp;{t("widgets.back")}
                            </Button>,
                    }[headerState]
                }
            />
            {widgetsHtml}
        </div>
    )
}

export default Dashboards;
