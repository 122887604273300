import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Label, FormGroup, ButtonGroup, Button, Card, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Setting } from '../../../models';
import SelectField from '../../SelectField/SelectField';
import TranslatableField from '../../TranslatableField/TranslatableField';
import SettingField from '../../SettingField/SettingField';
import { changeSetting } from '../../../redux/modules/settings';
import { Utils } from '../../../services';
import { arrayMoveMutable } from 'array-move';
import i18n from '../../../locales/i18n';

import './NavigationSettings.css';

const NavigationSettings = (props) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();
    const { env, tickettypes, salepoints, articles, articleCategories, postCategories } = props;

    const [chooserIsVisible, showChooser] = useState(false);

    if (!props.setting)
        return null;

    const tabs = (props.setting.mobile[env])?.navigation?.tabs || [];

    const DragHandle  = SortableHandle(() => <i className="drag-handle fa fa-bars" />);
    const SortableTab = SortableElement(({ tab, sortIndex }) => (
        <Col sm={ 3 }>
            <Card className="tab mt-3">
                <DragHandle />
                <CardTitle>{ tab.target }</CardTitle>
                <CardBody>
                    <TranslatableField component="input" type="text" name={ `mobile.${env}.navigation.tabs[${ sortIndex }].name` } />
                </CardBody>
                <CardFooter>
                    <ButtonGroup>
                        <Button color="danger" size="sm" onClick={ () => onRemove(sortIndex) }>
                            <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                        </Button>
                    </ButtonGroup>
                </CardFooter>
            </Card>
        </Col>
    ));

    const SortableGrid = SortableContainer(({ tabs }) => {
        return (
            <Row>
                { (tabs || []).map((tab, index) => <SortableTab key={ `item-${ index }` } index={ index } tab={ tab } sortIndex={ index }/>)}
            </Row>
        );
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
        const newSetting = { ...props.setting };
        arrayMoveMutable((newSetting.mobile[env])?.navigation?.tabs || [], oldIndex, newIndex);
        dispatch(changeSetting(newSetting));
    };

    const onRemove = (index) => {
        const newSetting = { ...props.setting };
        const newTabs = [].concat((newSetting.mobile[env])?.navigation?.tabs || []);
        newTabs.splice(index, 1);
        newSetting.mobile[env].navigation.tabs = newTabs;
        dispatch(changeSetting(newSetting));
    }

    const onAdd = (target) => {
        const newSetting = { ...props.setting };
        const newTabs = [].concat((newSetting.mobile[env])?.navigation?.tabs || []);
        newTabs.push(availableTabs.find(tab => tab.target === target));
        newSetting.mobile = (newSetting.mobile || {});
        newSetting.mobile[env] = (newSetting.mobile[env] || {});
        newSetting.mobile[env].navigation = (newSetting.mobile[env].navigation || {});
        newSetting.mobile[env].navigation.tabs = newTabs;
        newSetting.mobile[env].navigation.tabs = newTabs;
        dispatch(changeSetting(newSetting));
        showChooser(false);
    }

    const availableTabs = [
        { name: {fr: 'Accueil', en: 'Home', de: 'Home'}, icon: { type: 'logo' }, target: 'Home' },
        { name: {fr: 'Billets', en: 'Tickets', de: 'Tickets'}, icon: { name: 'ticket', type: 'explorit' }, target: 'Ticket' },
        { name: {fr: 'Favoris', en: 'Favorites', de: 'Favoriten'}, icon: { name: 'heart', type: 'font-awesome' }, target: 'Favoris' },
        { name: {fr: 'Shop', en: 'Shop', de: 'Shop'}, icon: { name: 'shopping-basket', type: 'font-awesome' }, target: 'SteamShop' },
        { name: {fr: 'News', en: 'News', de: 'News'}, icon: { name: 'news', type: 'explorit' }, target: 'News' },
        { name: {fr: 'Panier', en: 'Cart', de: 'Warenkorb'}, icon: { name: 'cart', type: 'explorit' }, target: 'Panier' },
        { name: {fr: 'Programme', en: 'Program', de: 'Programm'}, icon: { name: 'agenda', type: 'explorit' }, target: 'Programme' },
        { name: {fr: 'Votes', en: 'Votes', de: 'Abstimmen'}, icon: { name: 'star', type: 'font-awesome' }, target: 'Votes' },
    ];

    return (
        <div className="NavigationSettings">
            <div className="small card-title">
                { t('settings.mobile.navigation.tabs') }
                <Button className="pull-right btn btn-success" onClick={ () => showChooser(true) }>
                    <i className="fa fa-plus"></i>&nbsp;
                    { t('settings.mobile.navigation.add_tab') }
                </Button>
            </div>
            <p className="desc">
                { t('settings.mobile.navigation.tabs_desc') }
            </p>
            <SortableGrid
                tabs={ tabs }
                axis="xy"
                onSortEnd={ onSortEnd }
                useDragHandle={ true }
                lockToContainerEdges={ true }
            />
                { tabs && tabs.length > 0 && (
            <>
                <div className="small card-title">
                    { t('settings.mobile.navigation.active_tab') }
                </div>
                <p className="desc">
                    { t('settings.mobile.navigation.active_tab_desc') }
                </p>
                <FormGroup row>
                    <Col>
                        <SelectField
                            name={`mobile.${env}.navigation.initialTab`}
                            options={ tabs.map(tab => ({
                                key: tab.target,
                                text: Utils.localized_or_fallback(tab.name, i18n.language),
                                value: tab.target
                            }))}
                        />
                    </Col>
                </FormGroup>
            </>
            )}

            { chooserIsVisible && (
            <div className="tab-chooser-backdrop">
                <div className="tab-chooser">
                    <Button className="close-btn" color="link" onClick={ () => showChooser(false)}>
                        <i className="fa fa-times" />
                    </Button>
                    <div className="small card-title">
                        { t('settings.mobile.navigation.choose_a_tab') }
                    </div>
                    <div className="tab-choices">
                        { availableTabs.map(tab => (
                        <div className="tab-choice" onClick={() => onAdd(tab.target)}>
                            <div className="tab-choice-title">
                                { tab.name[i18n.language] }
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
            )}
        </div>
    );
}

export default NavigationSettings;
