import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableStats from '../../../components/TableStats/TableStats';
import { Utils, Filters } from '../../../services';
import StatsHeader from '../StatsHeader/StatsHeader'
import _ from 'lodash';
import moment from 'moment';
import i18n from '../../../locales/i18n';
import { useSelector } from 'react-redux';

import "./StatsBoxOffice.css";

const StatsBoxOffice = ({ filters }) => {
    const { t } = useTranslation();
    const title = t("statstypes.texts.box_office")
    const { stats } = useSelector(state => state.stats)

    const data = stats.box_office;

    if (!data || !data.films)
        return null;

    const tempArray = data.films;

    const columns = [
        {
            Header: t('screenings.title'),
            accessor: "title",
            filterMethod: Filters.textFilterMethod(`title`),
            Filter: Filters.textFilter,
            filterAll: true,
            minWidth: 200,
            Footer: () => (
                <b>{t("statstypes.total")}</b>
            ),
            className: "sticky",
            headerClassName: "sticky"
        },
        {
            Header: t("screenings.start_at"),
            accessor: 'start',
            filterable: false,
            minWidth: 160,
            className: 'align-center-stats'
        },
        {
            Header: t("screenings.week"),
            accessor: "week",
            filterable: false,
            maxWidth: 130,
            className: 'align-center-stats'
        },
        {
            Header: t("statstypes.screenings"),
            accessor: "screenings",
            filterable: false,
            maxWidth: 130,
            className: 'align-center-stats'
        },
        {
            Header: t("statstypes.chart.paid_entries"),
            accessor: "pay",
            filterable: false,
            maxWidth: 130,
            className: 'align-center-stats',
            Footer: () => (
                <b>
                    {data?.total?.stats?.nbPaid + data?.total?.stats?.nbPaidPass}
                </b>
            )
        },
        {
            Header: t("statstypes.chart.free_entries"),
            accessor: "free",
            filterable: false,
            maxWidth: 130,
            className: 'align-center-stats',
            Footer: () => (
                <b>
                    {data?.total?.stats?.nbFree + data?.total?.stats?.nbFreePass}
                </b>
            )
        },
        {
            Header: t("statstypes.entries"),
            accessor: "entries",
            filterable: false,
            maxWidth: 130,
            className: 'align-center-stats',
            Footer: () => (
                <b>
                    {data?.total?.stats?.nbTotal}
                </b>
            )
        },
        {
            Header: t("statstypes.chart.income"),
            accessor: "revenue",
            filterable: false,
            maxWidth: 130,
            Cell: row => (
                row?.value?.toFixed(2) + " " + Utils.currency()
            ),
            className: 'align-center-stats',
            Footer: () => (
                <b>
                    {data?.total?.stats?.total ? data.total.stats.total.toFixed(2) + ' ' + Utils.currency() : '0 ' + Utils.currency()}
                </b>
            )
        },
    ]

    const dataTable = Object.keys(tempArray).map(film => {
        return {
            title: tempArray[film]._id.title[i18n.language] ? tempArray[film]._id.title[i18n.language] : tempArray[film]._id.title.original,
            start: moment(tempArray[film]._id.start_at).format("DD/MM/YYYY [-] HH:mm"),
            week: tempArray[film]._id.week,
            screenings: tempArray[film].screenings.length,
            pay: tempArray[film].stats.nbPaid + tempArray[film].stats.nbPaidPass,
            free: tempArray[film].stats.nbFree + tempArray[film].stats.nbFreePass,
            entries: tempArray[film].stats.nbTotal,
            revenue: tempArray[film].stats.total
        }
    });

    return (
        <div className="StatsBoxOffice">
            <div className="noPrint">
                <PageTitle icon="star" title={title} />
            </div>
            <StatsHeader
                filters={filters}
                title={<PageTitle
                    icon="star"
                    title={title}
                />}
            />
            {dataTable && (
                <TableStats id="boxStatsTable" data={dataTable} columns={columns}></TableStats>
            )}
        </div>
    )
}

export default StatsBoxOffice;
