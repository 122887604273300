import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadTask, deleteTask } from '../../../redux/modules/tasks';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const TaskDelete = () => {
    const { taskId }                                   = useParams();
    const dispatch                                     = useDispatch();
    const { t }                                        = useTranslation();
    const default_lang                                 = Utils.default_lang();
    const { task, isLoading, taskSuccessfullyDeleted } = useSelector(state => state.tasks);

    useEffect(() => {
        dispatch(loadTask(taskId));
    }, []);

    const submit = (taskId) => {
        dispatch(deleteTask(taskId));
    };

    let title = <span>{t("bulidings.task_plural")}</span>;
    if (task)
        title = (
            <span>
                {t("tasks.task")}
                <small> |&nbsp;{t('tasks.confirm_delete', { name: task.activity?.name[default_lang] })}</small>
            </span>
        );

    const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/tasks" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

    return (
        <div className="TaskDelete">
            <PageTitle icon="id-badge" title={title} actions={actions} />

            {taskSuccessfullyDeleted &&
                <Redirect to="/tasks" />
            }

            {task &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{t('tasks.confirm_delete')}</CardTitle>
                                <CardText tag="div">
                                    <div><b>{t("tasks.activity")}</b>: {task.activity?.name[default_lang]}</div>
                                    <div><b>{t("tasks.start_at")}</b>: {moment(task.start_at).format('LLL')}</div>
                                    <div><b>{t("tasks.stop_at")}</b>: {moment(task.stop_at).format('LL')}</div>
                                    <div><b>{t("tasks.user")}</b>: {task.user && task.user?.fullname}</div>
                                    <div><b>{t("tasks.status")}</b>: {t('tasks.status_' + task.status)}</div>
                                    <Row>
                                        <Col className="text-center">
                                            <Button tag={Link} to="/tasks" color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => submit(task.id)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        {t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default TaskDelete;
