// Redux Articlecategories module
// see https://github.com/erikras/ducks-modular-redux

import { Articlecategories } from '../../services/';


const LOADING_ARTICLECATEGORIES         = 'kronos/articlecategories/LOADING_ARTICLECATEGORIES';
const LOADING_ARTICLECATEGORIES_SUCCESS = 'kronos/articlecategories/LOADING_ARTICLECATEGORIES_SUCCESS';
const LOADING_ARTICLECATEGORIES_FAILURE = 'kronos/articlecategories/LOADING_ARTICLECATEGORIES_FAILURE';
const LOADING_ARTICLECATEGORY          = 'kronos/articlecategories/LOADING_ARTICLECATEGORY';
const LOADING_ARTICLECATEGORY_SUCCESS  = 'kronos/articlecategories/LOADING_ARTICLECATEGORY_SUCCESS';
const LOADING_ARTICLECATEGORY_FAILURE  = 'kronos/articlecategories/LOADING_ARTICLECATEGORY_FAILURE';
const UPDATING_ARTICLECATEGORY         = 'kronos/articlecategories/UPDATING_ARTICLECATEGORY';
const UPDATING_ARTICLECATEGORY_SUCCESS = 'kronos/articlecategories/UPDATING_ARTICLECATEGORY_SUCCESS';
const UPDATING_ARTICLECATEGORY_FAILURE = 'kronos/articlecategories/UPDATING_ARTICLECATEGORY_FAILURE';
const START_CREATING_ARTICLECATEGORY   = 'kronos/articlecategories/START_CREATING_ARTICLECATEGORY';
const CREATING_ARTICLECATEGORY         = 'kronos/articlecategories/CREATING_ARTICLECATEGORY';
const CREATING_ARTICLECATEGORY_SUCCESS = 'kronos/articlecategories/CREATING_ARTICLECATEGORY_SUCCESS';
const CREATING_ARTICLECATEGORY_FAILURE = 'kronos/articlecategories/CREATING_ARTICLECATEGORY_FAILURE';
const DELETING_ARTICLECATEGORY         = 'kronos/articlecategories/DELETING_ARTICLECATEGORY';
const DELETING_ARTICLECATEGORY_SUCCESS = 'kronos/articlecategories/DELETING_ARTICLECATEGORY_SUCCESS';
const DELETING_ARTICLECATEGORY_FAILURE = 'kronos/articlecategories/DELETING_ARTICLECATEGORY_FAILURE';
const SET_CURRENT_CATEGORY         = 'kronos/articles/SET_CURRENT_CATEGORY';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    articlecategorySuccessfullyCreated: false,
    articlecategories: [],
    currentCategory: null,
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_ARTICLECATEGORIES:
            return {
                ...state,
                isLoading: true,
                articlecategorySuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_ARTICLECATEGORIES_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                articlecategories: action.articlecategories
            };
        case LOADING_ARTICLECATEGORIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_ARTICLECATEGORY:
            return {
                ...state,
                articlecategory: null,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_ARTICLECATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                articlecategory: action.articlecategory
            };
        case LOADING_ARTICLECATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                articlecategory: null,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_ARTICLECATEGORY:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_ARTICLECATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                articlecategory: action.articlecategory
            };
        case UPDATING_ARTICLECATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_ARTICLECATEGORY:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                articlecategorySuccessfullyCreated: false,
                articlecategory: {
                    description: {
                        fr: '',
                        en: '',
                        de: '',
                        it: ''
                    },
                    pos: []
                }
            };
        case CREATING_ARTICLECATEGORY:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_ARTICLECATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                articlecategorySuccessfullyCreated: true,
                articlecategory: action.articlecategory
            };
        case CREATING_ARTICLECATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                articlecategorySuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_ARTICLECATEGORY:
            return {
                ...state,
                isLoading: true,
                articlecategorySuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case DELETING_ARTICLECATEGORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                articlecategorySuccessfullyDeleted: true,
                articlecategory: action.articlecategory
            };
        case DELETING_ARTICLECATEGORY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                articlecategorySuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case SET_CURRENT_CATEGORY:
            return {
                ...state,
                currentCategory: action.currentCategory
            };
        default:
            return state;
    }
};

// Actions
function loadingArticlecategories() { return { type: LOADING_ARTICLECATEGORIES } }
function loadingArticlecategoriesSuccess(articlecategories) { return { type: LOADING_ARTICLECATEGORIES_SUCCESS, articlecategories: articlecategories } }
function loadingArticlecategoriesFailure(err) { return { type: LOADING_ARTICLECATEGORIES_FAILURE, error: err } }
export function loadArticlecategories(params) {
    return (dispatch) => {
        dispatch(loadingArticlecategories());
        Articlecategories.list(params)
            .then(data => {
                const articlecategories = data.articlecategories;
                dispatch(loadingArticlecategoriesSuccess(articlecategories));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingArticlecategoriesFailure(err))
            });
    }
}

function loadingArticlecategory() { return { type: LOADING_ARTICLECATEGORY } }
function loadingArticlecategorySuccess(articlecategory) { return { type: LOADING_ARTICLECATEGORY_SUCCESS, articlecategory: articlecategory } }
function loadingArticlecategoryFailure(err) { return { type: LOADING_ARTICLECATEGORY_FAILURE, error: err } }
export function loadArticlecategory(articlecategoryId) {
    return (dispatch) => {
        dispatch(loadingArticlecategory());
        Articlecategories.get(articlecategoryId)
            .then(data => {
                const articlecategory = data.articlecategory;
                dispatch(loadingArticlecategorySuccess(articlecategory));
            })
            .catch(err => {
                dispatch(loadingArticlecategoryFailure(err))
            });
    }
}

function updatingArticlecategory() { return { type: UPDATING_ARTICLECATEGORY } }
function updatingArticlecategorySuccess(articlecategory) { return { type: UPDATING_ARTICLECATEGORY_SUCCESS, articlecategory: articlecategory } }
function updatingArticlecategoryFailure(err) { return { type: UPDATING_ARTICLECATEGORY_FAILURE, error: err } }
export function updateArticlecategory(articlecategory) {
    return (dispatch) => {
        dispatch(updatingArticlecategory());
        Articlecategories.update(articlecategory)
            .then(data => {
                const articlecategory = data.articlecategory;
                dispatch(updatingArticlecategorySuccess(articlecategory));
            })
            .catch(err => {
                dispatch(updatingArticlecategoryFailure(err))
            });
    }
}

function creatingArticlecategory() { return { type: CREATING_ARTICLECATEGORY } }
function creatingArticlecategorySuccess(articlecategory) { return { type: CREATING_ARTICLECATEGORY_SUCCESS, articlecategory: articlecategory } }
function creatingArticlecategoryFailure(err) { return { type: CREATING_ARTICLECATEGORY_FAILURE, error: err } }
export function startCreateArticlecategory() { return { type: START_CREATING_ARTICLECATEGORY } }
export function createArticlecategory(articlecategory) {
    return (dispatch) => {
        dispatch(creatingArticlecategory());
        Articlecategories.create(articlecategory)
            .then(data => {
                const articlecategory = data.articlecategory;
                dispatch(creatingArticlecategorySuccess(articlecategory));
                dispatch(loadingArticlecategorySuccess(articlecategory));
            })
            .catch(err => {
                dispatch(creatingArticlecategoryFailure(err))
            });
    }
}

function deletingArticlecategory() { return { type: DELETING_ARTICLECATEGORY } }
function deletingArticlecategorySuccess() { return { type: DELETING_ARTICLECATEGORY_SUCCESS } }
function deletingArticlecategoryFailure(err) { return { type: DELETING_ARTICLECATEGORY_FAILURE, error: err } }
export function deleteArticlecategory(articlecategoryId) {
    return (dispatch) => {
        dispatch(deletingArticlecategory());
        Articlecategories.delete(articlecategoryId)
            .then(data => {
                dispatch(deletingArticlecategorySuccess());
            })
            .catch(err => {
                dispatch(deletingArticlecategoryFailure(err))
            });
    }
}

export function setCurrentCategory(currentCategory) { return { type: SET_CURRENT_CATEGORY, currentCategory } }
