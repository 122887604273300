import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Badge, Row, Col, ButtonGroup, Button, Card, CardImg, CardText } from 'reactstrap';
import { Link, Redirect } from "react-router-dom";
import { loadEvent, startCopyEvent, copyEvent } from '../../../redux';
import { Event } from '../../../models';
import { PageTitle, Loader } from '../../../components';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

const EventShow = props => {
    const { t, i18n } = useTranslation();
    const dispatch    = useDispatch();

    const { eventId, type } = useParams();
    const { user }          = useSelector(state => state.auth);

    const {
        event,
        copied,
        isLoading,
        eventSuccessfullyCopied
    } = useSelector(state => state.events);

    useEffect(() => {
        dispatch(startCopyEvent(type, eventId));
        dispatch(loadEvent(type, eventId));
    }, []);

    if (!event)
        return <Loader />;

    const title = (
        <span>
            {t(`events.subevent.${type}`)}
            <small> | { Utils.localized_or_fallback(event.title, i18n.language) } </small>
        </span>
    );

    const copy = () => {
        dispatch(copyEvent(type, eventId));
    };

    const actions = (
        <div>
            <ButtonGroup>
                <Button tag={Link} to={ `/events/${type}` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {(user?.tkt_instance_id === event.tkt_instance_id) ? (
                    <Button tag={Link} to={ `/events/edit/${ event.id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                ) : (
                    <Button onClick={copy} color="info" size="sm">
                        <i className="fa fa-clone"></i>&nbsp;{t(`common.copy_to_mine.${type}`)}
                    </Button>
                )}
            </ButtonGroup>
        </div>
    );

    if (eventSuccessfullyCopied && copied)
        return <Redirect to={`/events/${type}/edit/${copied.id}`} />

    return (
        <div className="EventShow">
            <PageTitle icon={ Event.getTypeIcon(type) } title={title} actions={actions} />
            <Card className="p-3">
                <Row>
                    <Col xs={12} md={3}>
                        <div>
                            {event.trailers.map(trailer => (
                                <CardText key={trailer.url}>
                                    <iframe
                                        width="100%"
                                        src={trailer.url}
                                        frameBorder="0"
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                    ></iframe>
                                </CardText>
                            ))}
                            {event.posters.map(poster => (
                                <CardImg
                                    className="mb-2"
                                    key={poster.url}
                                    top
                                    width="100%"
                                    src={poster.url}
                                    alt="Card image cap"
                                />
                            ))}
                        </div>
                    </Col>
                    <Col xs={12} md={9}>
                        <h3>
                            { Utils.localized_or_fallback(event.title, i18n.language) }&nbsp;
                            <small>
                                | { event.original_title }
                                { event.suisa && <span> ({event.suisa})</span> }
                            </small>
                        </h3>
                        <p>
                            <i>
                                {[
                                    event.distributor?.name,
                                    event.year,
                                    event.countries?.join(',').toUpperCase(),
                                    event.original_language?.toUpperCase(),
                                    event.genre,
                                    `${event.duration} ${t('common.min')}`,
                                ].filter(Boolean).join(' - ')}
                            </i>
                            <br />
                        </p>
                        <Row>
                            <Col xs={12} md={9}>
                                <h5 className="d-flex column gap-2 justify-start items-start">
                                    {event.people.map(p => (
                                        <Badge key={p.lastname} color="primary">
                                            <b>{ t(`events.activity_${p.activity}`) }</b>&nbsp;:&nbsp;
                                            { [p.firstname, p.lastname].filter(Boolean).join(' ') }
                                            <br/>
                                        </Badge>
                                    ))}
                                </h5>
                            </Col>
                            <Col xs={12} md={3}>
                                <h5 className="text-right d-flex column gap-2 justify-start items-end">
                                    {event.l_min_age && (
                                        <Badge color="info">
                                            { t('events.l_min_age') }
                                            <b>{ event.l_min_age }</b>
                                        </Badge>
                                    )}
                                    {event.s_min_age && (
                                        <Badge color="info">
                                            { t('events.s_min_age') }
                                            <b>{ event.s_min_age }</b>
                                        </Badge>
                                    )}
                                    {event.a_min_age && (
                                        <Badge color="info">
                                            { t('events.a_min_age') }
                                            <b>{ event.a_min_age }</b>
                                        </Badge>
                                    )}
                                </h5>
                            </Col>
                        </Row>
                        <p className="text-lg text-justify" dangerouslySetInnerHTML={{
                            __html: Utils.localized_or_fallback(event.description, i18n.language)
                        }}/>
                        <Row>
                            <Col>
                                {(user?.tkt_instance_id != event.tkt_instance_id) && (
                                    <Button onClick={copy} color="info" size="sm">
                                        <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'clone'}`}></i>&nbsp;{t(`common.copy_to_mine.${type}`)}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default EventShow;
