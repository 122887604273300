import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import PostcategoryForm from '../PostcategoryForm/PostcategoryForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Utils } from '../../../services';

import { loadPostcategory, updatePostcategory } from '../../../redux/modules/post_categories';

const PostCategoryEdit = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        const postCategoryId = match.params.post_categoryId;
        // Load postCategoryId on component mount
        dispatch(loadPostcategory(postCategoryId));
    }, [dispatch])
    
    const submit = (values) => {
        dispatch(updatePostcategory(values));
    }

    const { post_category } = useSelector(state => state.post_categories);

    let title = <span>{t('post_categories.update_postcategory')}</span>;

    if (post_category && post_category.name)
        title = (
            <span>
                {t('post_categories.post_category')}
                <small> |&nbsp;{ t('post_categories.update_postcategory', { name: post_category.name[Utils.default_lang()] })} </small>
            </span>
        );

    const actions =
        <div>
            {post_category &&
            <ButtonGroup>
                <Button  tag={Link} to="/post_categories" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="PostCategoryEdit">
                <PageTitle icon="tags" title={ title } actions={ actions } />

                {post_category &&
                    <PostcategoryForm postCategoryId={post_category._id} onSubmit={ submit } />
                }
            </div>
        );
}

export default PostCategoryEdit;
