import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Button } from 'reactstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Filters, Utils } from '../../../services/';
import TableButtons from '../../../components/TableButtons';
import { Link } from "react-router-dom"
import CreateButton from '../../../components/CreateButton';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import { loadUsers, loadInactiveUsers } from '../../../redux/modules/users';
import { loadTktInstances } from '../../../redux/modules/tktInstances';
import Table from '../../../components/Table/Table';

import './UsersList.css';
import user_avatar from '../../../assets/images/user_avatar.jpg';

const UsersList = ({ }) => {
    const {
        users,
        inactive_users
    }                                 = useSelector(state => state.users);
    const authUser                    = useSelector(state => state.auth.user);
    const { tktInstances }            = useSelector(state => state.tktInstances);
    const dispatch                    = useDispatch();
    const { t }                       = useTranslation();
    const default_lang                = Utils.default_lang();
    const [showActive, setShowActive] = useState(true);
    const users_to_show               = showActive ? users : inactive_users;

    const toggle_active = (active) => {
        setShowActive(active);
    }

    useEffect(() => {
        dispatch(loadUsers());
        dispatch(loadInactiveUsers());
        authUser.abilities.superAdmin && dispatch(loadTktInstances());
    }, [])

    let activities = [];
    if (users_to_show) {
        activities = _.uniqBy(_.flatten(users_to_show.map((u) => u.activities)), 'id');
        activities = _.sortBy(activities, (a) => a.name[default_lang]);
    }

    let columns = [{
        Header: t('users.fullname'),
        accessor: "rfullname",
        filterMethod: Filters.textFilterMethod("rfullname"),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => row.value && (
            <div className="user_cell">
                <div className="img_wrapper">
                    <img src={row.original.avatar || user_avatar} />
                </div>
                <div>
                    <Link to={`/users/edit/${row.original.id}`}>
                        <span className="fullname">{row.original.fullname}</span>
                    </Link>
                </div>
            </div>
        )
    }, {
        Header: t('users.activities'),
        accessor: "activities",
        filterMethod: Filters.usersActivitiesFilterMethod,
        Filter: Filters.activitiesFilter(activities),
        Cell: row => (
            <div>
                {row.original.activities &&
                    <h5>
                        {row.original.activities.map((a) => <span key={a.id} className="badge badge-light mr-2"> {a.name[default_lang]}</span>)}
                    </h5>
                }
            </div>
        )
    }, {
        Header: t('users.email'),
        accessor: "email",
        filterMethod: Filters.textFilterMethod("email"),
        Filter: Filters.textFilter,
        filterAll: true,
    }];

    if (authUser?.isSuperAdmin() && tktInstances) {
        columns = columns.concat([{
            Header: t('users.tkt_instance'),
            accessor: "tkt_instance_id",
            filterMethod: Filters.textFilterMethod("tkt_instance_id"),
            Filter: Filters.tktInstancesFilter(tktInstances),
            filterAll: true,
            Cell: row => row.value && tktInstances.length > 0 && (
                <span>
                    {tktInstances.find((t) => t.id === row.value) && tktInstances.find((t) => t.id === row.value).name}
                </span>
            )
        }]);
    }

    columns = columns.concat([{
        Header: t('common.actions'),
        accessor: "id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete", "show"]}
                module="users"
                owner={row.original.created_by}
            />
        )
    }]);

    const actions =
        <div>
            {(authUser?.isSuperAdmin() || authUser?.can('edit_templates', 'users')) &&
                <Button tag={Link} color="primary" to={`/tktInstances/edit/${authUser.tkt_instance.id}`} size="sm" className="mr-2">
                    <i className="fa fa-edit"></i>&nbsp;
                    {t('abilities.abilities_template')}
                </Button>
            }
            <ButtonGroup>
                <Button color={showActive ? 'info' : 'secondary'} onClick={(e) => toggle_active(true)} size="sm">{t('users.active')}</Button>
                <Button color={!showActive ? 'info' : 'secondary'} onClick={(e) => toggle_active(false)} size="sm">{t('users.inactive')}</Button>
            </ButtonGroup>&nbsp;
            <CreateButton
                module="users"
                text="users.add_a_user"
            />
        </div>;

    return (
        <div className="UsersList">
            <PageTitle icon="users" title={t('users.user_plural')} actions={actions} />
            <Table id="usersTable" data={users_to_show} columns={columns} />
        </div>
    );
}

export default UsersList;
