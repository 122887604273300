import BaseModel from './BaseModel';

/** Class representing an user. */
class TktUser extends BaseModel {
    constructor(properties) {
        super(properties);
    }

    isCustomer = () => {
        return this.roles.includes("customer");
    }

    isPihyve = () => {
        return this.roles.includes("pihyve");
    }
}

export default TktUser;
