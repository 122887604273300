import React, { useEffect } from 'react';
import Config from '../../../config/Config';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Row, Col, ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ArticlesTable from './ArticlesTable/ArticlesTable';
import ArticlecategoriesBrowser from './ArticlecategoriesBrowser/ArticlecategoriesBrowser';
import TypeSelector from './TypeSelector/TypeSelector';
import { Article } from '../../../models';
import PageTitle from '../../../components/PageTitle/PageTitle';
import SearchBloc from '../../../components/SearchBloc/SearchBloc';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css'

import { loadArticlecategories } from '../../../redux/modules/articlecategories';
import { loadArticles, setCurrentCategory, setCurrentType } from '../../../redux/modules/articles';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import { loadTags } from '../../../redux/modules/tags';

import './ArticlesList.css';

const ArticlesList = () => {
    const dispatch               = useDispatch();
    const { t }                  = useTranslation();
    const { salepoints }         = useSelector(state => state.salepoints);
    const { tags }               = useSelector(state => state.tags);
    const { articlecategories }  = useSelector(state => state.articlecategories);
    const { user }               = useSelector(state => state.auth);
    const { isMobile, isTablet } = useSelector(state => state.ui);
    const textSearch = useSelector(state => state.search.text.scopes.articles);
    const tagsSearch = useSelector(state => state.search.tags.scopes.articles) || [];
    const {
        articles, currentCategory, currentType,
        hasLoadingError, loadingErrorMessage,
    } = useSelector(state => state.articles);

    const isResponsive = isMobile || isTablet;

    useEffect(() => {
        dispatch(loadArticlecategories());
        dispatch(loadTags());
        dispatch(loadSalepoints());
    }, [dispatch]);

    const selectType = (type) => {
        dispatch(setCurrentType(type));
    }

    const selectCategory = (category) => {
        dispatch(setCurrentCategory(category));
    }

    useEffect(() => {
        (textSearch || tagsSearch.length > 0 || currentCategory) && dispatch(loadArticles({
            'search': textSearch,
            'tags': tagsSearch.map(tag => tag._id).join(','),
            'types': currentType,
            'category_ids': currentCategory ? currentCategory._id : null
        }));
    }, [dispatch, textSearch, tagsSearch.length, currentType, currentCategory]);

    const articles_title = (<span>{t("articles.article_plural")}</span>);
    const articles_actions =
        <>
            {(user?.can && user.can("read", "articles")) &&
            <ButtonGroup size="sm" className={`mr-3 ${isResponsive ? " w-100" : ""}`}>
                <a target="_blank" href={`${Config.api_end_point}/article/export_stocks?x-api-key=${user.api_key}`} className="btn btn-info btn-sm">
                    <i className="fa fa-download"></i>&nbsp;{t("articles.export_stocks")}
                </a>
            </ButtonGroup>
            }
            {(user?.can && user.can("create", "articles")) &&
                <ButtonGroup size="sm" className={`mr-3 ${isResponsive ? " w-100" : ""}`}>
                    <Button tag={Link} to={`/articles/new/${Article.TYPE_CODE_DISCOUNT}`} color="success" size="sm">
                        <i className="fa fa-plus"></i>&nbsp;{t("articles.add_discount_code")}
                    </Button>
                    <Button tag={Link} to={`/articles/new/${Article.TYPE_MANUAL_DISCOUNT}`} color="success" size="sm">
                        <i className="fa fa-plus"></i>&nbsp;{t("articles.add_manual_discount")}
                    </Button>
                    <Button tag={Link} to={`/articles/new/${Article.TYPE_PRODUCT}`} color="success" size="sm">
                        <i className="fa fa-plus"></i>&nbsp;{t("articles.add_product")}
                    </Button>
                </ButtonGroup>
            }
        </>;

    return (
        <div className="ArticlesList">
            <PageTitle icon="list-ul" title={articles_title} actions={ articles_actions } />
            <Row>
                <Col className="articlecategories-browser" lg="3">
                    <div className="filters-wrapper">
                        <SearchBloc
                            scope="articles"
                            title={ t('articles.search') }
                            textTitle={ t('articles.name') }
                            textPlaceholder={ t('articles.search_placeholder') }
                            tags={ tags }
                        />
                    </div>
                    <div className="filters-wrapper">
                        <TypeSelector
                            active={ currentType }
                            onSelect={ selectType }
                        />
                    </div>
                    { articlecategories && articlecategories.length > 0 && (
                    <div className="filters-wrapper">
                        <ArticlecategoriesBrowser
                            articlecategories={ articlecategories }
                            active={ currentCategory }
                            onSelect={ selectCategory }
                        />
                    </div>
                    )}
                </Col>
                <Col className="articles-list" lg="9">
                    { articlecategories && articlecategories.length === 0&& (
                    <Link className="message create-category-text" tag={Link} to="/articlecategories/new" color="success" size="sm">
                        <i className="fa fa-plus"></i>&nbsp;{t("articles.please_create_category")}
                    </Link>
                    )}
                    { articlecategories && articlecategories.length > 0 && !currentCategory && !textSearch && !tagsSearch.length > 0 && (
                    <h4 className="message choose-category-text">
                        <i className="fa fa-arrow-circle-left"></i>
                        { t('articles.please_choose_category_or_make_a_search') }
                    </h4>
                    )}
                    { (currentCategory || textSearch || tagsSearch.length > 0) && (
                    <ArticlesTable
                        articles={ articles }
                        salepoints={ salepoints }
                        hasLoadingError={ hasLoadingError }
                        loadingErrorMessage={ loadingErrorMessage }
                    />
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default ArticlesList;
