import RestClient from './RestClient';
import { TktInstance } from '../../models';

class TktInstances extends RestClient {
    constructor() {
        super({
            "model": TktInstance,
            "entryPoint": "tkt_instance",
            "resource": "tkt_instance",
            "resources": "tkt_instances",
            "sortBy": "name"
        });
    }

    infos(slug) {
        return this.GET(`/tkt_instance/infos/slug/${slug}`)
            .then(response => response.json())
            .then(json => {
                if (json.infos)
                    return json;
                throw new Error(json.flash.error);
            });
    }
}

const TktInstancesApi = new TktInstances();

export default TktInstancesApi;
