import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import SettingForm from '../SettingForm/SettingForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadTags } from '../../../redux/modules/tags';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import { loadArticles } from '../../../redux/modules/articles';
import { loadArticlecategories } from '../../../redux/modules/articlecategories';
import { loadPostcategories } from '../../../redux/modules/post_categories';
import { loadTickettypes } from '../../../redux/modules/tickettypes';
import { loadPlaces } from '../../../redux/modules/places';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Utils } from '../../../services';

import SettingsBrowser from '../SettingsBrowser/SettingsBrowser';

import { loadSetting, updateSetting } from '../../../redux/modules/settings';

const SettingEdit = ({ match }) => {
    const [activeKey, setActiveKey] = useState(null);

    const dispatch = useDispatch();

    const { settingId, field }   = match.params;
    const { tickettypes }        = useSelector(state => state.tickettypes);
    const { articles }           = useSelector(state => state.articles);
    const { articlecategories }  = useSelector(state => state.articlecategories);
    const { post_categories }    = useSelector(state => state.post_categories);
    const { places }             = useSelector(state => state.places);
    const { salepoints }         = useSelector(state => state.salepoints);
    const { setting, isLoading } = useSelector(state => state.settings);
    const { t } = useTranslation()

    const default_lang = Utils.default_lang();

    useEffect(() => {
        dispatch(loadSetting(settingId));
        dispatch(loadTags());
        dispatch(loadArticles());
        dispatch(loadArticlecategories());
        dispatch(loadPostcategories());
        dispatch(loadSalepoints());
        dispatch(loadTickettypes());
        dispatch(loadPlaces());
        setActiveKey('customer');
    }, [])

    const submit = values => {
        dispatch(updateSetting(values));
    };

    const selectKey = key => {
        setActiveKey(key);
    }

    let title = <span>{t('settings.setting')}</span>;
    if (setting)
        title = (
            <span>
                {t('settings.setting')}
            </span>
        );

    const actions = null;

    return (
        <div className="SettingEdit">
            <PageTitle icon="gear" title={ title } actions={ actions } />

            <Container fluid>
                <Row>
                    <Col sm={ 12 }>
                        <SettingsBrowser
                            active={ activeKey }
                            setting={ setting }
                            onSelect={ selectKey }
                        />
                    </Col>
                    <Col>
                        {setting &&
                            <SettingForm
                                initialValues={ setting }
                                setting={ setting }
                                onSubmit={ submit }
                                field={ activeKey }
                                tickettypes={ tickettypes }
                                salepoints={ salepoints }
                                articles={ articles }
                                articleCategories={ articlecategories}
                                isLoading={ isLoading }
                                postCategories={ post_categories }
                                places={ places }
                            />
                        }
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export default SettingEdit;
