import RestClient from './RestClient';
import { Screening } from '../../models';

class Screenings extends RestClient {
    constructor() {
        super({
            "model": Screening,
            "id_field": "_id",
            "entryPoint": "screening",
            "resource": "screening",
            "resources": "screenings"
        });

        this.TKTSYNC_PATH = '/tktsync';
    }

    tktsync() {
        return this.POST(`/${this.entryPoint}${this.TKTSYNC_PATH}`, {})
            .then(response => response.json())
            .then(json => {
                if (json.imported)
                    return json;
                throw new Error(json.flash.error);
            });
    }

    updateScreeningProperty(payload) {
        return this.PUT(
            `/screening/update/id/${payload._id}`,
            { "screening": payload }
        ).then(response => response.json())
        .then(json => {
            if (json[this.resource]) {
                json[this.resource] = new this.model(json[this.resource]);
                return json;
            }
            throw new Error(json.flash.error);
        });
    }
}

const ScreeningsApi = new Screenings();

export default ScreeningsApi;
