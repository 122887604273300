import React, { useEffect } from 'react';
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Utils } from '../../../services';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadPost, deletePost } from '../../../redux/modules/posts';

const PostDelete = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();
    const default_lang = Utils.default_lang();

    useEffect(() => {
        const postId = match.params.postId;
        dispatch(loadPost(postId));
    }, [dispatch])

    const _deletePost = (postId) => {
        dispatch(deletePost(postId));
    }

    const { post, isLoading, postSuccessfullyDeleted } = useSelector(state => state.posts);

    let title = <span>{t("posts.posts_plural")}</span>;
    if (post && post.title)
        title = (
            <span>
                {t("posts.post")}
                <small> | { post.title[default_lang] } </small>
            </span>
        );

    const actions =
    <div>
        <ButtonGroup>
            <Button tag={Link} to="/posts" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
            </Button>
        </ButtonGroup>
    </div>

    return (
        <div className="PostDelete">
            <PageTitle icon="tags" title={ title } actions={ actions } />
            {postSuccessfullyDeleted &&
                <Redirect to="/posts"/>
            }

            {post && post.title &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('posts.confirm_delete')}</CardTitle>
                                <CardText tag="div">
                                    <div><b>{t("posts.name")}</b>: { post.title[default_lang] }</div>
                                    <Row>
                                        <Col className="text-center">
                                            <Button tag={Link} to="/posts" color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => _deletePost(post.id)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        { t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
            </div>
    );
}

export default PostDelete
