import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import PricinglistForm from '../PricinglistForm/PricinglistForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { loadPricinglist, updatePricinglist } from '../../../redux/modules/pricinglists';

const PricinglistEdit = ({ match }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();
    const { pricinglist } = useSelector(state => state.pricinglists, shallowEqual);

    useEffect(() => {
        const pricinglistId = match.params.pricinglistId;
        dispatch(loadPricinglist(pricinglistId));
    }, [])

    const submit = (values) => {
        dispatch(updatePricinglist(values));
    }

    let title = <span>{t('pricinglists.pricinglist')}</span>;
    if (pricinglist)
        title = (
            <span>
                {t('pricinglists.pricinglist')}
                <small> |&nbsp;{ t('pricinglists.update_pricinglist', { name: pricinglist.name })} </small>
            </span>
        );

    const actions =
    <div>
        {pricinglist &&
        <ButtonGroup>
            <Button tag={Link} to="/pricinglists" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
            </Button>
        </ButtonGroup>
        }
    </div>

    return (
        <div className="PricinglistEdit">
            <PageTitle icon="usd" title={ title } actions={ actions } />

            {pricinglist &&
                <PricinglistForm pricinglist={pricinglist} onSubmit={ submit } />
            }
        </div>
    );
}

export default PricinglistEdit;
