import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadDistributor, deleteDistributor } from '../../../redux/modules/distributors';

class DistributorDelete extends Component<{}> {
    componentDidMount() {
        const distributorId = this.props.match.params.distributorId;
        // Load distributors on component mount
        this.props.dispatchLoadDistributor(distributorId);
    }

    deleteDistributor(distributorId) {
        this.props.dispatchDeleteDistributor(distributorId);
    }

    render() {
        const { t }                                                      = this.props;
        const { distributor, isLoading, distributorSuccessfullyDeleted } = this.props.distributors;

        let title = <span>{t("bulidings.distributor_plural")}</span>;
        if (distributor)
            title = (
                <span>
                    {t("distributors.distributor")}
                    <small> | { distributor.url } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/distributors" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="DistributorDelete">
                <PageTitle icon="money" title={ title } actions={ actions } />

                {distributorSuccessfullyDeleted &&
                    <Redirect to="/distributors"/>
                }

                {distributor &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('distributors.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("distributors.name")}</b>: { distributor.name }</div>
                                        <div><b>{t("distributors.maccsbox_id")}</b>: { distributor.maccsbox_id }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/distributors" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteDistributor(distributor.id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadDistributor: (distributorId) => loadDistributor(distributorId),
    dispatchDeleteDistributor: (distributorId) => deleteDistributor(distributorId)
}

const mapStateToProps = state => ({
    distributors: state.distributors
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(DistributorDelete))
