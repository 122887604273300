import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Button, ButtonGroup, Card, CardImg, CardBody, CardText, CardFooter } from 'reactstrap';
import { Field, submit } from 'redux-form'
import { withTranslation } from 'react-i18next';
import FileUpload from '../../../../components/FileUpload/FileUpload';

import './EventTrailers.css';

class EventTrailers extends Component<{}> {
    remove(i) {
        const { fields, trailers, meta: { form }, dispatchSubmitForm } = this.props;

        fields.remove(i);
        trailers.splice(i, 1);
        dispatchSubmitForm(form);
    }

    render() {
        const { t, trailers } = this.props;

        return (
            <div className="EventTrailers">
                <Row>
                    {trailers.map((tr, i) =>
                        <Col key={i} xs="6" sm="4" md="3">
                            <Card>
                                <CardBody>
                                    <CardText>
                                        <iframe width="100%" src={tr.url} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                                    </CardText>
                                    <CardText>
                                        <CardImg top width="100%" src={tr.image} alt="Card image cap" />
                                        <Field component={FileUpload} type="file" name={`trailers[${i}]_new_poster`} />
                                    </CardText>
                                </CardBody>
                                <CardFooter>
                                    <ButtonGroup>
                                        <Button color="danger" size="sm" onClick={ () => { this.remove(i);  }}>
                                            <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                        </Button>
                                    </ButtonGroup>
                                    <Field component="input" type="hidden" className="form-control" name={`trailers[${i}].url`} />
                                    <Field component="input" type="hidden" className="form-control" name={`trailers[${i}].image`} />
                                </CardFooter>
                            </Card>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchSubmitForm: (form) => submit(form)
}
const mapStateToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(EventTrailers))
