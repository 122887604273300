import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { loadEvents } from '../../../redux/modules/events';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button, Form, Row, Col, Input, CardGroup, CardImg, CardBody, Card, CardTitle } from 'reactstrap';
import Widget from '../Widget';
import { Link } from 'react-router-dom'
import WidgetSharedSettings from '../WidgetSharedSettings/WidgetSharedSettings';
import './LastEvents.css';
import _ from "lodash"
import i18n from '../../../locales/i18n';

const LastEvents = ({ props, saveSettings, index, insert, name, onCancel }) => {
    const { t }                       = useTranslation();
    const { events, isLoading }       = useSelector(state => state.events);
    const dispatch                    = useDispatch();
    let content;
    const [tempEvents, setTempEvents] = useState()

    const getTitle = () => {
        if (!saveSettings) {
            switch (props.selectEventType) {
                case "movie":
                    return [t("widgets.settings.eventTypes.latest_movies"), "film"];
                case "drama":
                    return [t("widgets.settings.eventTypes.latest_dramas"), "puzzle-piece"];
                case "music_group":
                    return [t("widgets.settings.eventTypes.latest_groups"), "music"];
                case "service":
                    return [t("widgets.settings.eventTypes.latest_services", "gift")];
            }
        }
    }
    const title = getTitle()[0];

    useEffect(() => {
        if (!saveSettings && !props.noCompute)
            dispatch(loadEvents(props.selectEventType))
    }, [])

    useEffect(() => {
        if (events && events[0]?.type === props.selectEventType && !tempEvents) {
            setTempEvents(events);
        }
    }, [events])

    //Use this function to format props to send to parent
    //Index of edited widget is passed here then back to parent so we need it
    const handleSubmit = (e) => {
        //Prevent refresh on form submit
        e.preventDefault();

        //Props formatting
        if (e.target.selectEventType.value !== "0") {
            let settings = {
                width: e.target.widgetWidth.value,
                bgColor: e.target.widgetBgTransparent.checked ? "rgb(0,0,0,0)": e.target.widgetBgColor.value,
                selectEventType: e.target.selectEventType.value,
                selectEventNumber: e.target.selectEventNumber.value
            }
            //IN ARRAY!
            saveSettings([settings, index, insert, name]);
        } else {
            alert(t("widgets.settings.eventTypes.chose_event_alert"))
        }
    }

    if (!saveSettings && !props.noCompute) {
        let eventsArray = _.orderBy(tempEvents, "created_at", "desc").slice(0, props.selectEventNumber)
        content = eventsArray.map((element, index) => {
            return (
                <Col sm={12} md={3} className='w-100' key={index}>
                    <Card className='w-100'>
                        <Link to={"/events/" + props.selectEventType + "/edit/" + element.id}>
                            {(element?.posters || [])[0] ? 
                                <CardImg
                                    src={element.posters[0].url}
                                    className="cardPic"
                                />
                                :
                            <span className="fa-stack fa-lg noPicWrapper">
                                <i className="fa fa-picture-o fa-stack-1x"></i>
                                <i className="fa fa-ban fa-stack-2x text-danger"></i>
                            </span>
                            }
                        </Link>
                        <CardBody className='p-2'>
                            <CardTitle className="title" tag="h5">
                            {
                                element?.title[i18n.language] ? 
                                    <span>{element.title[i18n.language]}</span>
                                :
                                    <span>No title</span>
                            }
                            </CardTitle>
                            <ButtonGroup style={{display: "flex"}} className="pt-2">
                                <Link className="linkBtn pr-1 btn btn-dark" to={"/events/" + props.selectEventType + "/edit/" + element.id}>
                                    <i className="fa fa-eye"></i>&nbsp;
                                    {t("widgets.settings.eventTypes.show_event")}
                                </Link>
                                <Link className="linkBtn pl-1 btn btn-info" to={"/programmations/new/" + element.event_id}>
                                    <i className="fa fa-calendar-plus-o"></i>&nbsp;
                                    {t("widgets.settings.eventTypes.new_programmation")}
                                </Link>
                            </ButtonGroup>
                        </CardBody>
                    </Card>
                </Col>
            )
        });

        content =
            <Row className='w-100'>
                <div className='cardWrapper'>
                    <h4 className="legend">{title}</h4>
                    <CardGroup className='cardGrp'>
                        {content}
                    </CardGroup>
                </div>
            </Row>
        ;
    } else if (props.noCompute) {
        content = (
            <div className="noComputeWrapper">
                <div className='h-10 border-bottom'>
                    <h5>{title}</h5>
                </div>
                <div className='noCompute'>
                    <h1><i className={"fa fa-" + getTitle()[1] + " fa-3x"}></i></h1>
                </div>
            </div>
        );
    }

    return (
        <div className='LastEvent'>
            { !saveSettings && (
                <Widget content={ content } bgColor={props.bgColor} />
            )}

            { saveSettings && (
                // Do your widget settings form here
                // TODO : Bind settings from state hook to HTML form tags
                <Form onSubmit={handleSubmit}>
                    <Row className='ml-0 mr-0 pb-2 border-bottom'>
                        <Col className='col-sm-12 col-md-12 col-lg-6'>
                            <Row className='text-center'>
                                <h4 className='border-bottom pb-1 mb-2 w-100 mr-1'>{t("widgets.settings.parameters")}</h4>
                            </Row>
                            <Row>
                                {/** Handle widget event type */}
                                <Col>
                                    <div className='settingsFormLabel'>
                                        {t('widgets.settings.eventTypes.chose_eventType')}
                                    </div>
                                </Col>
                                <Col className='col-6'>
                                    <div className='settingsFormInput'>
                                        <Input
                                            className='settingsInput'
                                            name="selectEventType"
                                            type="select"
                                            defaultValue={props?.selectEventType ? props.selectEventType : 0}
                                        >
                                            <option key={"eventType0"} value={0} hidden>{t("widgets.settings.eventTypes.chose_eventType")}</option>
                                            <option key={"eventTypeMovie"} value={"movie"}>{t("widgets.settings.eventTypes.movies")}</option>
                                            <option key={"eventTypeDrama"} value={"drama"}>{t("widgets.settings.eventTypes.dramas")}</option>
                                            <option key={"eventTypeMusicGroup"} value={"music_group"}>{t("widgets.settings.eventTypes.music_groups")}</option>
                                            <option key={"eventTypeService"} value={"service"}>{t("widgets.settings.eventTypes.services")}</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {/** Handle widget event type */}
                                <Col>
                                    <div className='settingsFormLabel'>
                                        {t('widgets.settings.eventTypes.chose_eventNumber')}
                                    </div>
                                </Col>
                                <Col className='col-6'>
                                    <div className='settingsFormInput'>
                                        <Input
                                            className='settingsInput'
                                            name="selectEventNumber"
                                            type="select"
                                            defaultValue={props?.selectEventNumber ? props.selectEventNumber : 1}
                                        >
                                            <option key={"eventNumber1"} value={1}>{1}</option>
                                            <option key={"eventNumber2"} value={2}>{2}</option>
                                            <option key={"eventNumber3"} value={3}>{3}</option>
                                            <option key={"eventNumber4"} value={4}>{4}</option>
                                            <option key={"eventNumber5"} value={5}>{5}</option>
                                        </Input>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {/* Widget global display parameters */}
                        <Col className='col-sm-12 col-md-12 col-lg-6'>
                            <WidgetSharedSettings 
                                width={props?.width ? props.width : 1} 
                                bgColor={props?.bgColor ? props.bgColor : "#2A2A2A"} 
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="submitButtons">
                                <Button onClick={() => onCancel()} className="mr-2"  color="danger"><i className="fa fa-times"></i>&nbsp;{t("widgets.cancel")}</Button>&nbsp;
                                <Button type="submit" color="success"><i className="fa fa-floppy-o"></i>&nbsp;{t("widgets.save")}</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </div>
    )
}

export default LastEvents;
