import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import { Field } from 'redux-form';

const Program = ({ screen, widget }) => {
    const { t } = useTranslation();

    return null;
}

export default Program;
