import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Utils } from '../../../services';
import { updateArticlecategory } from '../../../redux/modules/articlecategories';
import { ArticleCategory } from '../../../models/';
import ArticlecategoriesBrowser from '../../Articles/ArticlesList/ArticlecategoriesBrowser/ArticlecategoriesBrowser';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveMutable } from 'array-move';
import _ from 'lodash';
import { loadArticlecategories, setCurrentCategory } from '../../../redux/modules/articlecategories';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';

import './ArticlecategoriesList.css';

const ArticlecategoriesList = () => {
    const dispatch                                              = useDispatch();
    const history                                               = useHistory();
    const default_lang                                          = Utils.default_lang();
    const { t }                                                 = useTranslation();
    const [sortedArticlecategories, setSortedArticlecategories] = useState([]);
    const [visibleCategories, setVisibleCategories]             = useState([]);
    const [mode, setMode]                                       = useState('list');
    const { articlecategories, currentCategory }                = useSelector(state => state.articlecategories);

    useEffect(() => {
        dispatch(loadArticlecategories());
    }, []);

    useEffect(() => {
        setSortedArticlecategories(_.sortBy(visibleCategories, 'sort_weight'));
    }, [visibleCategories]);

    useEffect(() => {
        setVisibleCategories(articlecategories.filter(c => (!currentCategory && !c.parent) || c.parent === currentCategory?._id));
    }, [articlecategories, currentCategory]);

    const selectCategory = (category) => {
        dispatch(setCurrentCategory(category));
    }

    const editCategory = (category) => {
        if (category)
            history.push(`/articlecategories/edit/${category._id}`);
    }

    const buildPreview = articlecategories => {
        const DragHandle  = SortableHandle(() => <i className="drag-handle fa fa-bars" />);
        const SortableTab = SortableElement(({ articlecategory, sortIndex }) => <Col  sm={3} lg={2} className="mt-3"> { renderCategory(articlecategory, /*draggable*/true) } </Col>);
        const SortableGrid = SortableContainer(({ articlecategories }) => {
            return (
                <Row className="sortable-grid">
                    {(articlecategories || []).map((articlecategory, index) => <SortableTab key={`item-${index}`} index={index} articlecategory={articlecategory} sortIndex={index} />)}
                </Row>
            );
        });

        const renderCategory = (articlecategory, draggable) => {
            return (
                <Card className="articlecategory-card">
                    {draggable && <DragHandle />}
                    <CardBody style={{
                        "backgroundImage": "url('" + (
                            articlecategory.posters && articlecategory.posters.length ?
                                articlecategory.posters[0].url : ""
                        ) + "')",
                        "backgroundPosition": "center center",
                        "backgroundSize": "cover"
                    }}>
                        <div className="title-wrapper">
                            <Link className="articlecategory" to={`/articlecategories/edit/${articlecategory._id}`}>
                                <CardTitle>
                                    <span className="pt-3">{articlecategory.name[default_lang]}</span>
                                </CardTitle>
                            </Link>
                        </div>
                        <TableButtons
                            id={articlecategory._id}
                            customClass="deleteCategoryBtn"
                            actions={["delete"]}
                            module="articlecategories"
                            owner={articlecategory.created_by}
                        />
                    </CardBody>
                </Card>
            )
        }

        const onSortEnd = ({ oldIndex, newIndex }) => {
            const articlecategories = [...sortedArticlecategories];
            arrayMoveMutable(articlecategories, oldIndex, newIndex);
            setSortedArticlecategories(articlecategories);

            articlecategories.forEach((props, index) => {
                // if the articlecategory didn't move
                if (props.sort_weight == index)
                    // do nada
                    return;

                // build and update a new articlecategory
                const articlecategory = new ArticleCategory({
                    ...props,
                    sort_weight: index
                });
                dispatch(updateArticlecategory(articlecategory));
            });
        };

        return (
            <>
                {currentCategory ? renderCategory(currentCategory, /*draggable*/false) : null}
                {(sortedArticlecategories?.length > 0) && (
                    <SortableGrid
                        articlecategories={sortedArticlecategories}
                        axis="xy"
                        onSortEnd={onSortEnd}
                        useDragHandle={true}
                        lockToContainerEdges={true}
                    />
                )}
            </>
        );
    };

    const actions =
        <CreateButton
            module={`articlecategories`}
            text="articlecategories.add"
        />

    const title =
        <span>{t("articlecategories.articlecategory_plural")}</span>

    return (
        <div className="ArticlecategoriesList">
            <PageTitle icon="th" title={title} actions={actions} />
            <Row>
                <Col className="articlecategories-browser"  sm={12} lg={3}>
                    { articlecategories && articlecategories.length > 0 && (
                    <div className="filters-wrapper">
                        <ArticlecategoriesBrowser
                            articlecategories={ articlecategories }
                            active={ currentCategory }
                            onSelect={ selectCategory }
                            onEdit={ editCategory }
                        />
                    </div>
                    )}
                </Col>
                {(currentCategory || sortedArticlecategories?.length > 0) && (
                <Col className="articlecategories-list"  sm={12} lg={9}>
                    { buildPreview() }
                </Col>
                )}
            </Row>
        </div>
    );
}

export default ArticlecategoriesList
