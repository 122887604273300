import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ButtonGroup, Button } from 'reactstrap';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { FormGroup, Label, Jumbotron } from 'reactstrap';
import { Field } from 'redux-form'
import { Article } from '../../../../../models';
import { Utils } from '../../../../../services';
import TranslatableField from '../../../../../components/TranslatableField/TranslatableField';
import BarcodeField from '../../../../../components/BarcodeField/BarcodeField';
import SelectField from '../../../../../components/SelectField/SelectField';
import ToggleField from '../../../../../components/ToggleField/ToggleField';
import MoneyField from '../../../../../components/MoneyField/MoneyField';
import PercentageField from '../../../../../components/PercentageField/PercentageField';
import RolesField from '../../../../../components/RolesField/RolesField';
import TickettypesField from '../../../../../components/TickettypesField/TickettypesField';
import { Accordion, Icon } from 'semantic-ui-react';

import './ArticleVariant.css';

const ArticleVariant = ({ variant, index, tickettypes, stock_type, isCreation, onRemove, onDuplicate }) => {
    const { t } = useTranslation();
    const currency = Utils.currency();

    const [activeIndex, setActiveIndex] = useState(-1);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index

        setActiveIndex(newIndex);
    }

    return (
        <Col key={index} className="ArticleVariant" sm={12} lg={6}>
            <Card>
                <CardBody>
                    {variant.discount && ('code' in variant.discount) && (
                        <FormGroup row>
                            <Label for="discount_code" sm={3}>{t("articles.variant_discount.code")}</Label>
                            <Col sm={9}>
                                <Field component="input" type="text" className="form-control" name={`variants[${index}].discount.code`} />
                            </Col>
                        </FormGroup>
                    )}

                    {(!variant.discount || !('code' in variant.discount)) && (
                        <FormGroup row>
                            <Label for="name" sm={3}>{t("articles.variant_name")}</Label>
                            <Col sm={9}>
                                <TranslatableField name={`variants[${index}].name`} placeholder={t("articles.variant_name_placeholder")} component="input" />
                            </Col>
                        </FormGroup>
                    )}

                    {!variant.discount && (
                        <>
                            <FormGroup row>
                                <Label for="price" sm={3}>{t("articles.variant_price")}</Label>
                                <Col sm={9}>
                                    <MoneyField name={`variants[${index}].price[${currency}]`} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="variable_price" sm={3}>{t("articles.variant_variable_price")}</Label>
                                <Col sm={9}>
                                    <ToggleField name={`variants[${index}].variable_price`} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="value_like_price" sm={3}>{t("articles.variant_value_like_price")}</Label>
                                <Col sm={9}>
                                    <ToggleField name={`variants[${index}].value_like_price`} />
                                </Col>
                            </FormGroup>
                            {!variant.value_like_price && (
                            <FormGroup row>
                                <Label for="value" sm={3}>{t("articles.variant_value")}</Label>
                                <Col sm={9}>
                                    <MoneyField name={`variants[${index}].value[${currency}]`} />
                                </Col>
                            </FormGroup>
                            )}
                            <FormGroup row>
                                <Label for="purchasing_price" sm={3}>{t("articles.variant_purchasing_price")}</Label>
                                <Col sm={9}>
                                    <MoneyField name={`variants[${index}].purchasing_price[${currency}]`} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="vat" sm={3}>{t("articles.variant_vat")}</Label>
                                <Col sm={9}>
                                    <PercentageField name={`variants[${index}].vat`} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="gtin" sm={3}>{t("articles.variant_gtin")}</Label>
                                <Col sm={9}>
                                    <BarcodeField name={`variants[${index}].gtin`} />
                                </Col>
                            </FormGroup>
                        </>
                    )}

                    {variant.discount && (
                        <>
                            <FormGroup row>
                                <Label for="discount_value" sm={3}>{t("articles.variant_discount.value_amount")}</Label>
                                <Col sm={9}>
                                    <MoneyField name={`variants[${index}].discount.value.amount[${currency}]`} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="discount_value" sm={3}>{t("articles.variant_discount.value_percentage")}</Label>
                                <Col sm={9}>
                                    <PercentageField name={`variants[${index}].discount.value.percentage`} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="discount_value" sm={3}>{t("articles.variant_discount.target")}</Label>
                                <Col sm={9}>
                                    <SelectField
                                        name={`variants[${index}].discount.target`}
                                        options={Article.discountTargets().map((target, i) => ({
                                            key: i,
                                            text: t(`articles.variant_discount.${target}_target`),
                                            value: target
                                        }))}
                                    />
                                </Col>
                            </FormGroup>
                            <Accordion inverted>
                                <Accordion.Title active={activeIndex === 0} index={0} onClick={handleClick}>
                                    <Icon name='dropdown' />
                                    Afficher les regles
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 0}>
                                    <Jumbotron>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t('articles.variant_discount.only_for_roles')}</Label>
                                            <Col sm={12}>
                                                <RolesField name={`variants[${index}].discount.rules.only_for_roles`} />
                                            </Col>
                                        </FormGroup>
                                        {tickettypes && (
                                            <>
                                                <FormGroup row>
                                                    <Label for="name" sm={12}>{t('articles.variant_discount.only_for_tickettypes')}</Label>
                                                    <Col sm={12}>
                                                        <TickettypesField
                                                            name={`variants[${index}].discount.rules.only_for_tickettypes`}
                                                            tickettypes={tickettypes}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="name" sm={12}>{t('articles.variant_discount.exclude_tickettypes')}</Label>
                                                    <Col sm={12}>
                                                        <TickettypesField
                                                            name={`variants[${index}].discount.rules.exclude_tickettypes`}
                                                            tickettypes={tickettypes}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </>
                                        )}
                                    </Jumbotron>
                                </Accordion.Content>
                            </Accordion>
                        </>
                    )}
                    <FormGroup row className={stock_type !== 'article' ? 'd-none' : ''}>
                        <Label for="stock_factor" sm={3}>{t("articles.variant_stock_factor")}</Label>
                        <Col sm={9}>
                            <Field component="input" type="text" className="form-control" name={`variants[${index}].stock_factor`} />
                        </Col>
                    </FormGroup>
                    {!variant.discount && (
                        <FormGroup row>
                            <Label for="sku" sm={3}>{t("articles.variant_sku")}</Label>
                            <Col sm={9}>
                                <Field component="input" type="text" className="form-control" name={`variants[${index}].sku`} />
                            </Col>
                        </FormGroup>
                    )}
                </CardBody>
                {!isCreation && (
                    <CardFooter className="text-right">
                        <ButtonGroup>
                            <Button color="info" size="sm" onClick={() => { onDuplicate(variant._id); }}>
                                <i className="fa fa-copy"></i>&nbsp;{t("common.duplicate")}
                            </Button>
                            <Button color="danger" size="sm" onClick={() => { onRemove(variant._id); }}>
                                <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                            </Button>
                        </ButtonGroup>
                    </CardFooter>
                )}
            </Card>
        </Col>
    );
}

export default ArticleVariant;
