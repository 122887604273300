const UPDATE_UI         = 'kronos/ui/UPDATE_UI';

const initialState = {
    collapsedSidebar: false,
    isMobile: window.innerWidth <= 767 ? true : false,
    isTablet: window.innerWidth > 767 && window.innerWidth <= 1024 ? true : false,
    showNav: window.innerWidth > 767 ? false : true,
    showChangelog: false
};

/* Export reducer */
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_UI:
            return {
                ...state,
                ...action.update
            };
        default:
            return state;
    };
};

/* Actions */
export function updateUi(update) {
    return (dispatch) => {
        dispatch({type : UPDATE_UI, update});
    }
}
