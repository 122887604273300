import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom';
import ArticlecategoriesList from './ArticlecategoriesList/ArticlecategoriesList';
import ArticlecategoryShow from './ArticlecategoryShow/ArticlecategoryShow';
import ArticlecategoryEdit from './ArticlecategoryEdit/ArticlecategoryEdit';
import ArticlecategoryCreate from './ArticlecategoryCreate/ArticlecategoryCreate';
import ArticlecategoryDelete from './ArticlecategoryDelete/ArticlecategoryDelete';

class Articlecategories extends Component<{}> {
    render() {
        const { match }     = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.articlecategories;
        return (
            <div className="Articlecategories">

                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={ArticlecategoriesList} />
                <Route exact path={ `${ match.url }/new` } component={ArticlecategoryCreate} />
                <Route exact path={ `${ match.url }/show/:articlecategoryId` } component={ArticlecategoryShow} />
                <Route exact path={ `${ match.url }/edit/:articlecategoryId` } component={ArticlecategoryEdit} />
                <Route exact path={ `${ match.url }/delete/:articlecategoryId` } component={ArticlecategoryDelete} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    articlecategories: state.articlecategories
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Articlecategories));
