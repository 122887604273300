import RestClient from './RestClient';
import { Post } from '../../models';

class Posts extends RestClient {
    constructor() {
        super({
			"model": Post,
			"entryPoint": "post",
			"resource": "post",
			"resources": "posts",
			"id_field": "id"
        });
    }
}

const PostsApi = new Posts();

export default PostsApi;
