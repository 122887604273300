import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Form, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { unloadCoins, unloadBills } from '../../../../../../redux';
import Icon from '@mdi/react';
import { mdiArrowDownBoldBox } from '@mdi/js';
import { Loader, PlusMinusInput, CollectorSituationResume } from '../../../../../../components';
import _ from 'lodash';

import './UnloadButton.css';

const UnloadButton = ({ collector }) => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const [isModalVisible, showModal] = useState(false);
    const toggleModal = useCallback(() => { showModal(!isModalVisible) }, [isModalVisible]);

    const defaultCoinsToUnload = {
        '0.05': 0, '0.10': 0, '0.20': 0, '0.50': 0, '1.00': 0, '2.00': 0, '5.00': 0
    };
    const defaultBillsToUnload = {
        '10.00': 0, '20.00': 0, '50.00': 0, '100.00': 0, '200.00': 0, '1000.00': 0
    };
    const [coinsToUnload, setCoinsToUnload] = useState(defaultCoinsToUnload);
    const [billsToUnload, setBillsToUnload] = useState(defaultBillsToUnload);
    const [mustDoUnload, setMustDoUnload] = useState(false);

    useEffect(() => {
        if (!collector.isIdle())
            return;

        if (!mustDoUnload)
            return;

        const coin = _.find(_.keys(coinsToUnload), coin => coinsToUnload[coin] > 0);
        const bill = _.find(_.keys(billsToUnload), bill => billsToUnload[bill] > 0);

        if (coin) {
            dispatch(unloadCoins(collector.id, parseFloat(coin), coinsToUnload[coin]));
            setTimeout(() => {
                setCoinsToUnload({
                    ...coinsToUnload,
                    [coin]: 0
                });
            }, 500);
        } else if (bill) {
            dispatch(unloadBills(collector.id, parseFloat(bill), billsToUnload[bill]));
            setTimeout(() => {
                setBillsToUnload({
                    ...billsToUnload,
                    [bill]: 0
                });
            }, 500);
        } else {
            toggleModal();
            setMustDoUnload(false);
        }

    }, [dispatch, toggleModal, collector, coinsToUnload, billsToUnload, mustDoUnload]);

    console.log('collector', collector);
    return (
        <div className="UnloadButton">
            <Button onClick={ toggleModal } className="operation" color="secondary" block>
                <div className="icon">
                    <Icon path={ mdiArrowDownBoldBox } size={ 3 } color="#FFFFFF" />{ ' ' }
                </div>
                <div className="title">
                    { t('collectors.unload') }
                    <div className="subtitle">{ t('collectors.unload_desc') }</div>
                </div>
            </Button>
            <Form className="operation-form unload-form">
                <Modal isOpen={isModalVisible} toggle={toggleModal} size="lg">
                    <ModalHeader toggle={toggleModal}>
                        <Icon path={ mdiArrowDownBoldBox } size={ 1 } color="#FFFFFF" />{ ' ' }
                        { t('collectors.unload_desc') }
                    </ModalHeader>
                    <ModalBody>
                        { collector.isLoading() && (
                            <Loader />
                        )}
                        { !collector.isLoading() && (
                        <div className="form-body">
                            <CollectorSituationResume
                                collector={ collector }
                                className="in-modal"
                            />
                            <br/>
                            <h4>{ t('collectors.coins') }</h4>
                            <Row>
                                {_.keys(coinsToUnload).map(coin => (
                                    <Col key={ coin } sm="3">
                                        <FormGroup key={ coin }>
                                            <PlusMinusInput
                                                title={ `${coin} CHF` }
                                                min={ 0 }
                                                defaultValue={ 0 }
                                                onChange={(nb) => {
                                                    setCoinsToUnload({
                                                        ...coinsToUnload,
                                                        [coin] : parseInt(nb)
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                ))}
                            </Row>
                            <h4>{ t('collectors.bills') }</h4>
                            <Row>
                                {_.keys(billsToUnload).map(bill => (
                                    <Col key={ bill } sm="3">
                                        <FormGroup className="form-group" key={ bill }>
                                            <PlusMinusInput
                                                title={ `${bill} CHF` }
                                                min={ 0 }
                                                defaultValue={ 0 }
                                                onChange={(nb) => {
                                                    setBillsToUnload({
                                                        ...billsToUnload,
                                                        [bill] : parseInt(nb)
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={ () => { setMustDoUnload(true) }}>
                            <Icon path={ mdiArrowDownBoldBox } size={ 1 } color="#FFFFFF" />{ ' ' }
                            { t('collectors.do_unload') }
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>{ t('common.close') }</Button>
                    </ModalFooter>
                </Modal>
            </Form>
        </div>
    );
}

export default UnloadButton;
