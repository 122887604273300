import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import MessageForm from '../MessageForm/MessageForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateMessage, createMessage } from '../../../redux/modules/messages';

class MessageCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        // Reset message in app state
        this.props.dispatchStartCreateMessage();
    }

    submit(values) {
        this.props.dispatchCreateMessage(values);
    }

    render() {
        const { messageSuccessfullyCreated, successMessage } = this.props.messages;
        const { t } = this.props;

        let title = <span>{t('messages.send_message')}</span>;

        const actions =
            <Button tag={Link} to="/messages" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="MessageCreate">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {messageSuccessfullyCreated &&
                    <div
                        className="alert alert-success"
                        dangerouslySetInnerHTML={{__html: `<div>${successMessage}</div>`}} />
                }

                <MessageForm onSubmit={this.submit} isCreation="true" />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateMessage: (message) => startCreateMessage(message),
    dispatchCreateMessage: (message) => createMessage(message)
}

const mapStateToProps = state => ({
    messages: state.messages,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(MessageCreate))
