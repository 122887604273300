import React from 'react';
import SelectField from '../SelectField/SelectField';

import './SortWeightField.css';

const SortWeightField = ({ name }) => {
    const options = [...Array(100).keys()].map(n => {
        return { key: n, text: n, value: n };
    });

    return (
        <SelectField
            name={ name || 'sort_weight' }
            options={ options }
            clearable
        />
    );
}

export default SortWeightField;
