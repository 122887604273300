// Redux Messages module
// see https://github.com/erikras/ducks-modular-redux

const LOAD_USER_SETTINGS        = 'kronos/userSettings/LOAD_USER_SETTINGS';
const ENABLE_HELPTOOLTIP        = 'kronos/userSettings/ENABLE_HELPTOOLTIP';
const DISMISS_HELPTOOLTIP       = 'kronos/userSettings/DISMISS_HELPTOOLTIP';
const ENABLE_DARK_THEME         = 'kronos/userSettings/ENABLE_DARK_THEME';
const DISABLE_DARK_THEME        = 'kronos/userSettings/DISABLE_DARK_THEME';
const USE_SMALL_DRAGGABLE_CARDS = 'kronos/userSettings/USE_SMALL_DRAGGABLE_CARDS';

const initialState = {
    KUserSettings: window.localStorage.getItem('KUserSettings') ?
        JSON.parse(window.localStorage.getItem('KUserSettings')) :
        {
            displayTooltips: true,
            darkTheme: true,
            smallDraggableCards: false
        }
}

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_USER_SETTINGS:
            return {
                ...state,
                KUserSettings: action.KUserSettings
            };
        case ENABLE_HELPTOOLTIP:
            return {
                ...state,
                KUserSettings: {
                    ...state.KUserSettings,
                    displayTooltips: true
                }
            };
        case DISMISS_HELPTOOLTIP:
            return {
                ...state,
                KUserSettings: {
                    ...state.KUserSettings,
                    displayTooltips: false
                }
            };
        case ENABLE_DARK_THEME:
            return {
                ...state,
                KUserSettings: {
                    ...state.KUserSettings,
                    darkTheme: true
                }
            };
        case DISABLE_DARK_THEME:
            return {
                ...state,
                KUserSettings: {
                    ...state.KUserSettings,
                    darkTheme: false
                }
            };
        case USE_SMALL_DRAGGABLE_CARDS:
            return {
                ...state,
                KUserSettings: {
                    ...state.KUserSettings,
                    smallDraggableCards: !!action.smallDraggableCards
                }
            };
        default:
            return state;
    }
};

// Actions
function loadUserSettings(settings) { return { type: LOAD_USER_SETTINGS, settings: settings } }
export function onLoadUserSettings(settings) {
    return (dispatch) => {
        dispatch(loadUserSettings(settings));
    }
}

function enableHelpTooltips() { return { type: ENABLE_HELPTOOLTIP } }
export function showTooltips() {
    return (dispatch) => {
        dispatch(enableHelpTooltips());
    }
}

function dissmissHelpTooltips() { return { type: DISMISS_HELPTOOLTIP } }
export function hideTooltips() {
    return (dispatch) => {
        dispatch(dissmissHelpTooltips());
    }
}

function enableDarkTheme() { return { type: ENABLE_DARK_THEME } }
export function showDarkTheme() {
    return (dispatch) => {
        dispatch(enableDarkTheme());
    }
}

function disableDarkTheme() { return { type: DISABLE_DARK_THEME } }
export function hideDarkTheme() {
    return (dispatch) => {
        dispatch(disableDarkTheme());
    }
}

function _setSmallDraggableCards(smallDraggableCards) { return { type: USE_SMALL_DRAGGABLE_CARDS, smallDraggableCards } }
export function setSmallDraggableCards(smallDraggableCards) {
    return (dispatch) => {
        dispatch(_setSmallDraggableCards(smallDraggableCards));
    }
}
