import React, { useState } from 'react';
import { Field } from 'redux-form';
import { Button } from 'reactstrap';
import { ChromePicker } from 'react-color';

import './ColorPickerField.css';

const ColorPickerField = ({ name, optional, onChange }) => {
    const [visible, setVisible] = useState(false);

    const renderField = field => {
        return (
            <div className="input-row">
                <Button style={{ backgroundColor: field.input.value }} onClick={ () => setVisible(true) }>
                    <span className="colorValue">
                        { field.input.value }
                    </span>
                </Button>
                { visible && (
                    <div className="popover">
                        <div className="cover" onClick={ () => setVisible(false) }/>
                        <ChromePicker
                            color={ field.input.value }
                            onChange={ color => field.input.onChange(color.hex)  }
                        />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className="ColorPickerField">
            <Field
                required={ !optional }
                component={ renderField }
                className="form-control"
                type="text"
                name={ name }
                id={ name }
                onChange={ onChange }
            />
        </div>
    );
}

export default ColorPickerField;
