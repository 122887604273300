import React from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableStats from '../../../components/TableStats/TableStats';
import { Utils } from '../../../services';
import StatsHeader from '../StatsHeader/StatsHeader'
import _ from 'lodash';
import i18n from '../../../locales/i18n';
import { useSelector } from 'react-redux';

import "./StatsPrices.css";

const StatsPrices = ({ filters }) => {
    const { t }     = useTranslation();
    const title     = t("statstypes.texts.prices")
    const { stats } = useSelector(state => state.stats);

    const data = stats.prices;

    if (!data || !data.films)
        return null;

    const getDataTable = () => {
        const paidData    = [];
        const freeData    = [];
        let   entriesPay  = 0;
        let   percPay     = 0;
        let   totalPay    = 0.00;
        let   entriesFree = 0;
        let   percFree    = 0;
        let   totalFree   = 0.00;
        Object.keys(data.films).forEach(tickettype => {
            Object.keys(data.films[tickettype]).forEach(name => {
                Object.keys(data.films[tickettype][name]).forEach(centsAmount => {
                    const infos = data.films[tickettype][name][centsAmount];

                    if (!infos || !_.isPlainObject(infos) || !('stats' in infos))
                        return;

                    let percentage = infos.stats.nbTotal / data.total.stats.nbTotal * 100;
                    let rounded_percentage = Math.round((infos.stats.nbTotal / data.total.stats.nbTotal) * 10000) / 100;

                    if (infos.value[Utils.currency()] > 0) {
                        entriesPay += infos.stats.nbTotal;
                        percPay += percentage;
                        totalPay += infos.stats.total_net1;
                        paidData.push({
                            name: infos.name[i18n.language],
                            price: infos.value[Utils.currency()].toFixed(2) + " " + Utils.currency(),
                            nb_entries: infos.stats.nbTotal,
                            percentage: rounded_percentage + "%",
                            revenue: infos.stats.total_net1.toFixed(2) + " " + Utils.currency()
                        });
                    } else {
                        entriesFree += infos.stats.nbTotal;
                        percFree += (percentage);
                        totalFree += infos.stats.total_net1;
                        freeData.push({
                            name: infos.name[i18n.language],
                            price: infos.value[Utils.currency()].toFixed(2) + " " + Utils.currency(),
                            nb_entries: infos.stats.nbTotal,
                            percentage: rounded_percentage + "%",
                            revenue: infos.stats.total_net1.toFixed(2) + " " + Utils.currency()
                        })
                    }
                });
            });
        });

        const sortedPaidData = _.orderBy(paidData, ['percentage'], ['desc']);
        const sortedFreeData = _.orderBy(freeData, ['percentage'], ['desc']);

        sortedPaidData.push({
            name: <b class="custom-table-footer">{`${t('statstypes.total')} - ${t('statstypes.chart.paid_entries')}`}</b>,
            price: <b class="custom-table-footer"></b>,
            nb_entries: <b class="custom-table-footer">{entriesPay}</b>,
            percentage: <b class="custom-table-footer">{(Math.round(percPay * 100) / 100) + "%"}</b>,
            revenue: <b class="custom-table-footer">{totalPay.toFixed(2) + " " + Utils.currency()}</b>,
        });
        sortedFreeData.push({
            name: <b class="custom-table-footer">{`${t('statstypes.total')} - ${t('statstypes.chart.free_entries')}`}</b>,
            price: <b class="custom-table-footer"></b>,
            nb_entries: <b class="custom-table-footer">{entriesFree}</b>,
            percentage: <b class="custom-table-footer">{(Math.round(percFree * 100) / 100) + "%"}</b>,
            revenue: <b class="custom-table-footer">{totalFree.toFixed(2) + " " + Utils.currency()}</b>,
        });

        return sortedPaidData.concat(sortedFreeData);
    }

    const columnsData = [
        {
            Header: t("statstypes.ticket"),
            accessor: "name",
            filterable: false,
            minWidth: 200,
            Footer: row => (<b>{t("statstypes.total")} {t("statstypes.chart.free_entries")} + {t("statstypes.chart.paid_entries")}</b>),
            className: "sticky",
            headerClassName: "sticky"
        },
        {
            Header: t("statstypes.price"),
            accessor: 'price',
            filterable: false,
            className: 'align-center-stats',
            Footer: ""
        },
        {
            Header: t("statstypes.entries"),
            accessor: "nb_entries",
            filterable: false,
            className: 'align-center-stats',
            Footer: row => (data?.total?.stats?.nbTotal ? <b>{data.total.stats.nbTotal}</b> : "")
        },
        {
            Header: t("statstypes.percentage"),
            accessor: "percentage",
            filterable: false,
            className: 'align-center-stats',
            Footer: ""
        },
        {
            Header: t("statstypes.chart.income"),
            accessor: "revenue",
            filterable: false,
            Footer: row => (data?.total?.stats?.total ? <b>{data.total.stats.total.toFixed(2) + Utils.currency()}</b> : ""),
            className: 'align-center-stats'
        },
    ]

    const dataTable = getDataTable();

    return (
        <div className="StatsPrices">
            <div className='noPrint'>
                <PageTitle icon="tag" title={title} />
            </div>
            <StatsHeader
                filters={filters}
                title={<PageTitle
                    icon="tag"
                    title={title}
                />}
            />
            <TableStats
                id="pricesStatsTable"
                data={dataTable}
                columns={columnsData}
                print
                showPagination={false}
                sortable={false}
                overridePageSize={dataTable.length}
            />
        </div>
    )
}

export default StatsPrices;
