import React, { useState, Component } from 'react';
import { Field } from 'redux-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import ReactTooltip from "react-tooltip";

import './HelpTooltips.css';

const HelpTooltips = ({ id, value, place }) => {
    place                     = place || 'bottom';
    const { t }               = useTranslation();
    const definition          = "definition." + `${value}`;
    const { KUserSettings }   = useSelector(state => state.userSettings);

    return (
        <div className="HelpTooltips">
        { KUserSettings.displayTooltips === true && (
            <ReactTooltip id={`${id}`} className="tooltip-display" place={ place } effect="solid">
                {t(`${definition}`)}
            </ReactTooltip>
        )}
        </div>
    );
}

export default HelpTooltips;
