import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css'
import { updateTableState } from '../../redux/modules/tables';

const Table = ({ id, data, columns, csv, columnsSortOrder, ...rest }) => {
    const { t }                         = useTranslation();
    const dispatch                      = useDispatch();
    const {
        page,
        pageSize,
        sortState,
        filters
    } = useSelector(state => state.tables[id]);

    let sorting = [];

    if (sortState?.length > 0)
        sorting = sortState;
    else if (columnsSortOrder)
        sorting = columnsSortOrder.map(c => {
            return {id: c, desc: false}
        })

    const onPageChanged = page => {
        dispatch(updateTableState(id, { page }));
    }

    const sortedChange = (sortState) => {
        dispatch(updateTableState(id, { sortState }))
    }

    const onPageSizeChanged = pageSize => {
        dispatch(updateTableState(id, { pageSize }));
    }

    const onFilteredChange = filters => {
        dispatch(updateTableState(id, { filters }));
    }

    const headers = "";

    if (csv) {
        headers = columns.map(object => object.Header).join(";")
    }

    return (
        <>
            {data?.length > 0 &&
                <ReactTable
                    data={data}
                    columns={columns}
                    filterable
                    defaultPage={page}
                    defaultPageSize={pageSize}
                    minRows={1}
                    className="-striped -highlight"
                    previousText={t("app.table_prev_button")}
                    nextText={t("app.table_next_button")}
                    pageText={t("app.table_page_label")}
                    ofText={t("app.table_of_label")}
                    rowsText={t("app.table_rows_label")}
                    noDataText={t("app.table_no_data_message")}
                    onPageChange={onPageChanged}
                    onPageSizeChange={onPageSizeChanged}
                    defaultSorted={sorting}
                    onSortedChange={sortedChange}
                    onFilteredChange={onFilteredChange}
                    filtered={filters}
                    {...rest}
                />
            }
        </>
    );
}

export default Table;
