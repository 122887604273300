import store from '..';
import { Fonts } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const { initialState, listReducer, listAction } = createRestSlices(Fonts);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [ listReducer ]);
}

/* Export actions */
export const loadFonts  = listAction;
