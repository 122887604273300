import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import PricinglistForm from '../PricinglistForm/PricinglistForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { startCreatePricinglist, createPricinglist } from '../../../redux/modules/pricinglists';

const PricinglistCreate = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();
    const { 
        pricinglistSuccessfullyCreated,
        pricinglist 
    } = useSelector(state => state.pricinglists);

    useEffect(() => {
        dispatch(startCreatePricinglist());
    }, [dispatch])

    const submit = (values) => {
        dispatch(createPricinglist(values));
    }

    let title = <span>{t('pricinglists.add')}</span>;

    const actions =
        <Button tag={Link} to="/pricinglists" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="PricinglistCreate">
            <PageTitle icon="usd" title={ title } actions={ actions } />

            {(pricinglistSuccessfullyCreated && pricinglist) &&
                <Redirect to={`/pricinglists/edit/${pricinglist._id}`}/>
            }

            {pricinglist &&
                <PricinglistForm pricinglist={pricinglist} onSubmit={submit} isCreation="true" />}  
        </div>
    );
}

export default PricinglistCreate;
