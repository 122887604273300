import React, { Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { Utils } from '../../services/';
import InlineImageField from '../InlineImageField/InlineImageField';
import { addImage, removeImage } from '../../redux/modules/settings';

import './SettingsImagesField.css';

const SettingsImagesField = (props) => {
    const { name, onAddImage, onRemoveImage, onDuplicateImage, overrides } = props;

    const { t }    = useTranslation();
    const dispatch = useDispatch();
    const globalSettings = useSelector(state => state.settings.setting);

    const settings = overrides ? overrides : globalSettings;

    if (!settings)
        return null;

    const _onAddImage = e => {
        const newImage = {
            type: Utils.IMAGE_TYPE_LOGO,
            lang: null,
            rfc2397_data: null
        };

        if (onAddImage)
            return onAddImage(newImage);

        dispatch(addImage(newImage));
    }

    const _onRemoveImage = i => {
        if (onRemoveImage)
            return onRemoveImage(i);

        dispatch(removeImage(i));
    }

    const _onDuplicateImage = i => {
        const source = settings.images[i];
        if (onDuplicateImage)
            return onDuplicateImage(source);

        dispatch(addImage(source));
    }

    return (
        <div className="SettingsImagesField">
            <Row>
            { (settings.images || []).map((image, i) => (
                <Col key={ i } sm={12} md={6} lg={4} xl={3}>
                    <InlineImageField
                        name={ `${name}[${i}]` }
                        image={ settings.images[i] }
                        onDuplicate={ e => _onDuplicateImage(i) }
                        onRemove={ e => _onRemoveImage(i) }
                    />
                </Col>
                ))}
                <Col key={ -1 } sm={12} md={6} lg={4} xl={3}>
                    <div className="add-button-wrapper" onClick={ _onAddImage }>
                        <div className="add-button">
                            <i className="fa fa-plus"></i>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default SettingsImagesField;
