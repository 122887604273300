import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { Utils } from '../../../services';
import { useTranslation } from 'react-i18next';
import "./ShowWidgetList.css";
import widgetsList from "../WidgetsList.js";
import _ from "lodash";
import Widget from "../../../components/Widget/Widget"

const ShowWidgetList = ({ onAddWidget, addIndex, insert }) => {
    const default_lang = Utils.default_lang();
    const { t }        = useTranslation();

    //Called when user clicks on an empty widget spot.
    //Gets the widget list and displays it instead of the dashboard
    const showWidgetList = () => {
        let temp = []
        Object.keys(widgetsList).map((widget, index) => {
            temp.push(
                <Col
                    sm={12}
                    md={4}
                    className='addWidget'
                    onClick={() => onAddWidget(addIndex, widget, insert)}
                    key={index}
                >
                    <Widget
                        content=
                        {
                            <>
                                <div className="textDiv pr-3 pl-3">
                                    <h2>{t(widgetsList[widget].title)}</h2>
                                    <h4>{t(widgetsList[widget].description)}</h4>
                                </div>
                                <i className={"fa fa-" + widgetsList[widget].icon}></i>
                            </>
                        }
                        border="solid darkgray"
                        roundBorder
                        bgColor={"#2A2A2A"}
                        clickable
                    />
                </Col>
            )
        })
        let tempWithRow =
            <Row>
                {temp}
            </Row>
        return tempWithRow;
    }

    const html = showWidgetList();

    return (
        <div className="ShowWidgetList">
            {html}
        </div>
    )
}

export default ShowWidgetList;
