import { Mailer } from '../../services';
import { applyReducers } from './rest';

const initialState = {
    isSendingEmail: false,
    result: null,
    hasSendingEmailError: false,
    sendingEmailErrorMessage: null
};

const SEND_EMAIL         = 'kronos/mailer/SEND_EMAIL';
const SEND_EMAIL_SUCCESS = 'kronos/mailer/SEND_EMAIL_SUCCESS';
const SEND_EMAIL_FAILURE = 'kronos/mailer/SEND_EMAIL_FAILURE';
const sendEmailReducer = (state = initialState, action) => {
    switch(action.type) {
        case SEND_EMAIL:
            return {
                ...state,
                isSendingEmail: true,
                hasSendingEmailError: false,
                result: null
            };
        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                isSendingEmail: false,
                hasSendingEmailError: false,
                result: action.result
            };
        case SEND_EMAIL_FAILURE:
            return {
                ...state,
                isSendingEmail: false,
                hasSendingEmailError: true,
                sendingEmailErrorMessage: action.error.message,
                result: null
            };
        default:
            return state;
    }
};

function _sendEmail() { return { type: SEND_EMAIL } }
function _sendEmailSuccess(result) { return { type: SEND_EMAIL_SUCCESS, result } }
function _sendEmailFailure(err) { return { type: SEND_EMAIL_FAILURE, error: err } }

/**
 * @param {Object} params: An object like :
 *  {
 *      layout_id: UUID,
 *      subject: String,
 *      to: {
 *          name: String,
 *          address: String
 *      },
 *      lang: String,
 *      context: {
 *          ticket_id: UUID
 *      }
 *  }
 */
export function sendEmail(params) {
    return (dispatch) => {
        dispatch(_sendEmail());
        Mailer.send(params)
            .then(data => {
                const result = data.result;
                dispatch(_sendEmailSuccess(result));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(_sendEmailFailure(err))
            });
    }
}

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        sendEmailReducer
    ]);
}
