import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../../components';
import { Button, ButtonGroup } from "reactstrap";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { updateEmailLayout } from '../../../redux';
import EmailLayouts from './EmailLayouts';

import './LayoutsList.css';

const LayoutsList = props => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const handle       = useFullScreenHandle();
    const { isMobile } = useSelector(state => state.ui);

    const onSubmit = values => {
        dispatch(updateEmailLayout(values));
    };

    const actions = (
        <ButtonGroup className={!isMobile ? "pull-right" : "col-12 p-0"}>
            { !handle.active && (
                <Button  className={isMobile ? "col-3" : ""} onClick={ handle.enter } type="button" color="info">
                    <i className="fa fa-arrows-alt"></i>
                </Button>
            )}
            { handle.active && (
                <Button onClick={ handle.exit } type="button" color="danger">
                    <i className="fa fa-times"></i>
                </Button>
            )}
        </ButtonGroup>
    );

    return (
        <div className="LayoutsList">
            <FullScreen handle={ handle }>
                <PageTitle icon="file-envelope-o" title={t('app.email_layouts')} actions={actions} />
                <EmailLayouts onSubmit={ onSubmit } />
            </FullScreen>
        </div>
    );
}

export default LayoutsList;
