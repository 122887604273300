import React from 'react';
import { Button } from 'reactstrap';

import './ModalComponent.css';

const ModalComponent = ({ title, children, onClose }) => {

    return (
        <div className="ModalComponent">
            <div className='modal-backdrop'>
                <div className="modal-chooser">
                    <Button className="close-btn" color="link" onClick={onClose}>
                        <i className="fa fa-times" />
                    </Button>
                    <div className="small card-title">
                        {title}
                    </div>
                    <div className="tab-choices">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalComponent;