import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import CategoriesField from '../../../../CategoriesField/CategoriesField';

const ShopSettings = ({ env, screen, widget, articlecategories }) => {
    const { t } = useTranslation();

    return (
        <FormGroup row className="widget-setting">
            <Label className="col-sm-4">
                { t('settings.mobile.screens.settings.shop_category_id') }
            </Label>
            <Col sm={ 8 }>
                <CategoriesField
                    categories={ articlecategories }
                    name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].rootCategoryId` }
                />
            </Col>
        </FormGroup>
    );
}

export default ShopSettings;
