import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import { Setting } from '../../../models';
import { useSelector } from 'react-redux';

import './FeaturesSettings.css';

const FeaturesSettings = (props) => {
    const {
        tickettypes,
        salepoints,
        articles,
        articleCategories,
        postCategories
    }           = props;
    const { t } = useTranslation();
    const user  = useSelector(state => state.auth.user);

    if (!props.setting)
        return null;

    const keys = [ 'disableUserAccount', 'disableVotes', 'disableFavorites' ];

    const renderSetting = key => {
        const name = `mobile.${props.env}.${key}`;
        const { role, hideLabel, input } = Setting.propertyForm(t, props.setting, name, tickettypes, salepoints, articles, articleCategories, postCategories);

        return (
            <>
                {(user?.isSuperAdmin() || (!role && user?.can('editSelf', 'settings'))) &&
                    <FormGroup row>
                        {!hideLabel && (
                            <Label for="_id" sm={3}>{t(`settings.mobile.${key}`)}</Label>
                        )}
                        <Col sm={hideLabel ? 12 : 9}>
                            { input }
                        </Col>
                    </FormGroup>
                }
            </>
        );
    }

    return (
        <div className="FeaturesSettings">
            { keys.map(key => renderSetting(key)) }
        </div>
    );
}

export default FeaturesSettings;
