import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { Badge, Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiCogTransferOutline } from '@mdi/js';
import hostImage from '../../assets/images/host.png';

import './HostResume.css';

const HostResume = ({ host, className, hideDetails, ...rest }) => {
    const { t } = useTranslation();
    const connected = host.isConnected();

    return (
        <div className={ `HostResume ${className}` } { ...rest }>
            <Card className="card-outline">
                <CardHeader>
                    <CardTitle tag="h6">
                        { host.name }
                    </CardTitle>
                </CardHeader>
                <CardImg
                    top
                    width="100%"
                    src={ hostImage }
                    alt="Host image cap"
                    className={ connected ? 'connected' : 'disconnected' }
                />
                { !hideDetails && (
                <CardBody>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">
                        <Badge color={ connected ? 'success' : 'danger' }>
                            { t(`hosts.${host.connected ? 'connected' : 'disconnected'}`) }<br/>
                        </Badge>{' '}
                        { host?.options?.terminal_id && (
                        <Badge color="info">
                            { host.options.terminal_id }
                        </Badge>
                        )}
                    </CardSubtitle>
                    <Button tag={ Link } to={`/devices/hosts/manage/${host._id}`} color={ 'info' } block>
                        <Icon path={ mdiCogTransferOutline } color="white" size={ 1 } />
                        { t('hosts.manage') }
                    </Button>
                </CardBody>
                )}
            </Card>
        </div>
    );
}

export default HostResume;
