import React, { useState } from 'react';
import { connect } from 'react-redux'
import { Form, FormGroup, Card, CardTitle, CardBody, Row, Col, Button } from 'reactstrap';
import { reduxForm, Field } from 'redux-form'
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import { DateTimePicker } from '../../../../components';
import moment from 'moment';

let ScreeningsFilters = ({ isLoading, handleSubmit }) => {
    const { t } = useTranslation();
    const [showFilters, setShowFilters] = useState(true)

    return (
        <Form className="mb-2" onSubmit={handleSubmit}>
            <Card>
                <CardBody>
                    <CardTitle>
                        <i className="fa fa-filter"></i>{t('statstypes.form.filters')}
                        <Button className="btn btn-outline-success float-right" onClick={() => setShowFilters(!showFilters)}><i className={`fa fa-${showFilters ? "minus" : "plus"}`}></i></Button>
                    </CardTitle>
                    {showFilters &&
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <label>{t("statstypes.form.date_start")}</label><br />
                                    <Field
                                        component={DateTimePicker}
                                        name="start_at_gte"
                                        className="statsFormPicker"
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <label>{t("statstypes.form.date_end")}</label><br />
                                    <Field
                                        component={DateTimePicker}
                                        name="stop_at_lte"
                                        initialViewDate={moment().endOf('day')}
                                        className="statsFormPicker"
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <Button type="submit" color="success" className="float-right" disabled={isLoading}>
                                    {isLoading ?
                                        <span>
                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                        </span>
                                        :
                                        <span>
                                            {t('statstypes.form.show')}&nbsp;
                                            <i className="fa fa-arrow-circle-right"></i>
                                        </span>
                                    }
                                </Button>
                            </Col>
                        </Row>
                    }
                </CardBody>
            </Card>
        </Form>
    )
}

const mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ScreeningsFilters = reduxForm({
    form: "ScreeningsFilters",
    enableReinitialize: true
})(ScreeningsFilters)

// You have to connect() to any reducers that you wish to connect to yourself
ScreeningsFilters = connect(
    state => ({
        initialValues: state.screenings.filters,
        filters: state.screenings.filters,
        destroyOnUnmount: false
    }), mapDispatchToProps
)(ScreeningsFilters)

export default ScreeningsFilters
