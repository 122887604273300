import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import SectionForm from '../SectionForm/SectionForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadSection, updateSection } from '../../../redux/modules/sections';

class SectionEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const sectionId = this.props.match.params.sectionId;
        // Load sections on component mount
        this.props.dispatchLoadSection(sectionId);
    }

    submit(values) {
        this.props.dispatchUpdateSection(values);
    }

    render() {
        const { t }           = this.props;
        const { section } = this.props.sections;

        const default_lang = Utils.default_lang();

        let title = <span>{t('sections.section')}</span>;
        if (section)
            title = (
                <span>
                    {t('sections.section')}
                    <small> |&nbsp;{ t('sections.update_section', { name: section.name[default_lang] })} </small>
                </span>
            );

        const actions =
        <div>
            {section &&
            <ButtonGroup>
                <Button tag={Link} to={ `/sections/show/${ section.id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="SectionEdit">
                <PageTitle icon="indent" title={ title } actions={ actions } />

                {section &&
                    <SectionForm sectionId={section.id} onSubmit={this.submit} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadSection: (sectionId) => loadSection(sectionId),
    dispatchUpdateSection: (section) => updateSection(section)
}

const mapStateToProps = state => ({
    sections: state.sections,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(SectionEdit))
