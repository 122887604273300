import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import ProgrammationsList from './ProgrammationsList/ProgrammationsList';
import ProgrammationShow from './ProgrammationShow/ProgrammationShow';
import ProgrammationEdit from './ProgrammationEdit/ProgrammationEdit';
import ProgrammationCreate from './ProgrammationCreate/ProgrammationCreate';
import ProgrammationDelete from './ProgrammationDelete/ProgrammationDelete';
import OldScreeningList from '../OldScreenings/OldScreeningsList/OldScreeningsList';

import './Programmations.css';

class Programmations extends Component<{}> {
    render() {
        const { match }     = this.props;
        const { isLoading, hasLoadingError, loadingErrorMessage } = this.props.programmations;
        return (
            <div className="Programmations">
                {isLoading &&
                    <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
                }
                {hasLoadingError &&
                    <div
                        className="alert alert-danger"
                        dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
                }
                <Route exact path={ match.path } component={ProgrammationsList} />
                <Route exact path={ `${ match.url }/new/:events_ids?` } component={ProgrammationCreate} />
                <Route exact path={ `${ match.url }/show/:programmationId` } component={ProgrammationShow} />
                <Route exact path={ `${ match.url }/edit/:programmationId` } component={ProgrammationEdit} />
                <Route exact path={ `${ match.url }/delete/:programmationId` } component={ProgrammationDelete} />
                <Route exact path={ `${ match.url }/show/:programmationId/oldscreenings` } component={OldScreeningList} />
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    programmations: state.programmations
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Programmations));
