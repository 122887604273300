import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import TableStats from '../../../components/TableStats/TableStats';
import { BarChart } from '../../../components/Chart/';
import { Utils, Filters } from '../../../services';
import StatsHeader from '../StatsHeader/StatsHeader'
import { Label } from 'reactstrap';
import i18n from '../../../locales/i18n';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import "./StatsHourly.css";

const StatsHourly = ({ filters }) => {
    const { t }     = useTranslation();
    const title     = t("statstypes.texts.income_hourly");
    const { stats } = useSelector(state => state.stats);

    const data = stats.hours;

    if (!data || !data.films)
        return null;

    const tempArray = data.films;

    const tableHeader  = [];
    const tableData    = [];
    let chartData      = [];
    const hoursKeys    = [];
    const dataGraphOne = [];
    const dataGraphTwo = [];

    //First header is static
    tableHeader.push({
        Header: t("statstypes.movie"),
        accessor: "movie",
        filterMethod: Filters.textFilterMethod(`movie`),
        Filter: Filters.textFilter,
        filterAll: true,
        Footer: () => (<b>{t("statstypes.total")}</b>),
        className: "sticky hours-col-" + (data.hours.length + 2),
        headerClassName: "sticky hours-col-" + (data.hours.length + 2)
    });

    //Number of hours is variable
    data.hours.forEach(element => {
        tableHeader.push({
            Header: element,
            accessor: element,
            filterable: false,
            Cell: row => (row.value && (
                <label>{row.value.total?.toFixed(2)}&nbsp;<Label className="expander">{Utils.currency()}</Label><br />
                    <small>
                        {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}
                    </small>
                </label>
            )),
            maxWidth: 90,
            headerClassName: "hours-col-" + (data.hours.length + 2),
            className: 'align-center-stats hours-col-' + (data.hours.length + 2),
            Footer: row => (
                <label>
                    <b>{data.total[element].total.toFixed(2)}&nbsp;<Label className="expander"><Label className="expander">{Utils.currency()}</Label></Label><br />
                        <small>
                            {data.total[element].nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                            {data.total[element].nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                            {data.total[element].nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                            {t('statstypes.total')}&nbsp;:&nbsp;{data.total[element].nbTotal}
                        </small>
                    </b>
                </label>
            )
        });
        hoursKeys.push(element);
    })

    //Last header is static
    tableHeader.push({
        Header: "total",
        accessor: "total",
        filterable: false,
        Cell: row => (row.value && (
            <label>{row.value.total?.toFixed(2)}&nbsp;<Label className="expander">{Utils.currency()}</Label><br />
                <small>
                    {row.value.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                    {row.value.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                    {row.value.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                    {row.value.nbScreenings}&nbsp;{t('statstypes.screenings')}<br />
                    <b>{t('statstypes.total')}&nbsp;:&nbsp;{row.value.nbTotal}</b>
                </small>
            </label>
        )),
        maxWidth: 90,
        className: "sticky align-center-stats hours-col-" + (data.hours.length + 2),
        headerClassName: "sticky hours-col-" + (data.hours.length + 2),
        Footer: () => (
            <label>
                <b>
                    {data.total.total?.toFixed(2)}&nbsp;<Label className="expander">{Utils.currency()}</Label><br />
                    <small>
                        {data.total.nbPaid}&nbsp;{t('statstypes.chart.paid_entries')}<br />
                        {data.total.nbFree}&nbsp;{t('statstypes.chart.free_entries')}<br />
                        {data.total.nbPass}&nbsp;{t('statstypes.chart.pass_entries')}<br />
                        {t('statstypes.total')}&nbsp;:&nbsp;{data.total.nbTotal}
                    </small>
                </b>
            </label>
        )
    });

    Object.keys(tempArray).forEach((film, key) => {
        //Static data
        tableData[key] = {
            movie: tempArray[film]._id.title[i18n.language] ?? tempArray[film]._id.title.original,
            total: {
                total: tempArray[film].stats.total,
                nbPaid: tempArray[film].stats.nbPaid,
                nbFree: tempArray[film].stats.nbFree,
                nbPass: tempArray[film].stats.nbPass,
                nbScreenings: tempArray[film].screenings.length,
                nbTotal: tempArray[film].stats.nbTotal,
            }
        };

        //Dynamic data
        for (let i = 0; i < hoursKeys.length; i++) {
            tempArray[film].stats[hoursKeys[i]] ?  //this test checks if the movie has a screening at the hour we loop in
                tableData[key][hoursKeys[i]] = {
                    total: tempArray[film].stats[hoursKeys[i]].total,
                    nbPaid: tempArray[film].stats[hoursKeys[i]].nbPaid,
                    nbFree: tempArray[film].stats[hoursKeys[i]].nbFree,
                    nbPass: tempArray[film].stats[hoursKeys[i]].nbPass,
                    nbTotal: tempArray[film].stats[hoursKeys[i]].nbTotal,
                } :
                tableData[key][hoursKeys[i]] = "";
        };
    });

    //Data for chart : entries per hours
    hoursKeys.forEach(key => {
        chartData.push({
            id: key,
            [t("statstypes.chart.free_entries")]: data.total[key].nbFree,
            [t("statstypes.chart.pass_entries")]: data.total[key].nbPass,
            [t("statstypes.chart.paid_entries")]: data.total[key].nbPaid,
        });
    });

    //Data for chart : income per hours
    chartData = []
    hoursKeys.forEach(key => {
        chartData.push({
            id: key,
            [t("statstypes.chart.income")]: data.total[key].total?.toFixed(2)
        });
    });

    const getExcelData = () => {
        if (tableHeader && tableData && data.total && data.hours) {
            let excelData = [];
            let temp = [];

            //Header
            for (let i = 0; i < tableHeader.length; i++) {
                if (i === 0)
                    temp.push(tableHeader[i].Header);
                else {
                    temp.push(tableHeader[i].Header + " " + t("statstypes.chart.income"));
                    temp.push(tableHeader[i].Header + " " + t('statstypes.chart.paid_entries'));
                    temp.push(tableHeader[i].Header + " " + t('statstypes.chart.free_entries'));
                    temp.push(tableHeader[i].Header + " " + t('statstypes.chart.pass_entries'));
                    temp.push(tableHeader[i].Header + " " + t('statstypes.entries'));
                }
            }
            excelData.push(temp);

            //body
            for (let i = 0; i < tableData.length; i++) {
                temp = [];
                temp.push(tableData[i].movie);
                Object.keys(tableData[i]).map(key => {
                    if (key !== "movie" && key !== "total") {
                        temp.push(tableData[i][key].total);
                        temp.push(tableData[i][key].nbPaid);
                        temp.push(tableData[i][key].nbFree);
                        temp.push(tableData[i][key].nbPass);
                        temp.push(tableData[i][key].nbTotal);
                    };
                });
                temp.push(tableData[i].total.total);
                temp.push(tableData[i].total.nbPaid);
                temp.push(tableData[i].total.nbFree);
                temp.push(tableData[i].total.nbPass);
                temp.push(tableData[i].total.nbTotal);
                excelData.push(temp);
            }

            let keys = data.hours.map(k => {
                return k;
            });
            temp = [];
            temp.push(t("statstypes.total"));
            keys.forEach(h => {
                temp.push(data.total[h].total);
                temp.push(data.total[h].nbPaid);
                temp.push(data.total[h].nbFree);
                temp.push(data.total[h].nbPass);
                temp.push(data.total[h].nbTotal);
            })
            temp.push(data.total.total);
            temp.push(data.total.nbPaid);
            temp.push(data.total.nbFree);
            temp.push(data.total.nbPass);
            temp.push(data.total.nbTotal);

            excelData.push(temp);

            return excelData
        }
    }

    return (
        <div className="StatsHourly">
            <div className="noPrint">
                <PageTitle icon="clock-o" title={title} />
            </div>
            <StatsHeader
                filters={filters}
                title={<PageTitle
                    icon="clock-o"
                    title={title}
                />}
            />
            {tableData && (
                <TableStats
                    id="hourlyStatsTable"
                    data={tableData}
                    columns={tableHeader}
                    getExcelData={getExcelData}
                    defaultSortDesc={true}
                    defaultSortMethod={Utils.sortOnProperty('total')}
                    print
                ></TableStats>
            )}
            <Row className='noPrint'>
                <Col sm={12} lg={6} style={{ height: "400px" }}>
                    <BarChart
                        data={dataGraphOne}
                        keys={[t("statstypes.chart.free_entries"), t("statstypes.chart.pass_entries"), t("statstypes.chart.paid_entries")]}
                        axisLeftLegend=""
                        axisBottomLegend=""
                        axisBottomTickRotation={35}
                        tooltipFormat="$"
                        enableLabel={false}
                    />
                </Col>
                <Col sm={12} lg={6} style={{ height: "400px" }}>
                    <BarChart
                        data={dataGraphTwo}
                        keys={[t("statstypes.chart.income")]}
                        axisLeftLegend=""
                        axisBottomLegend=""
                        axisBottomTickRotation={35}
                        tooltipFormat="$"
                        enableLabel={false}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default StatsHourly;
