import React from 'react';
import { useTranslation } from 'react-i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomEditor from 'ckeditor5-custom-build';
import 'ckeditor5-custom-build/build/translations/fr';
import 'ckeditor5-custom-build/build/translations/de';

import './CKWysiwyg.css';

const CKWysiwyg = ({ defaultValue, onChange, withShortcodes, withColors, withImages, withTables }) => {
    const { i18n } = useTranslation();

    const items = React.useMemo(() => {
        const items = withShortcodes ? ['shortcodes', '|'] : [];
        items.push('heading');
        items.push('|');
        items.push('bold');
        items.push('italic');
        items.push('underline');
        items.push('link');

        if (withColors) {
            items.push('fontColor');
            items.push('fontBackgroundColor');
        }

        items.push('alignment');
        items.push('|');
        items.push('horizontalLine');
        items.push('|');
        items.push('bulletedList');
        items.push('numberedList');
        items.push('outdent');
        items.push('indent');

        if (withImages || withTables)
            items.push('|');

        if ( withTables)
            items.push('insertTable');

        if (withImages)
            items.push('imageUpload');

        items.push('|');
        items.push('strikethrough');
        items.push('superscript');
        items.push('blockQuote');
        items.push('|');
        items.push('sourceEditing');
        items.push('removeFormat');
        items.push('|');
        items.push('undo');
        items.push('redo');
        items.push('|');
        items.push('-');
        items.push('|');

        return items;
    }, [withShortcodes, withImages, withTables]);

    return (
        <div className="CKWysiwyg">
            <CKEditor
                editor={ CustomEditor }
                data={ defaultValue }
                config={{
                    language: i18n.language,
                    toolbar: {
                        items,
                        shouldNotGroupWhenFull: false
                    },
                }}
                onReady={ editor => {}}
                onChange={(event, editor) => onChange && onChange(editor.data.get())}
                onBlur={(event, editor) => {}}
                onFocus={(event, editor) => {}}
            />
        </div>
    );
}

export default CKWysiwyg;

