import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import ResourceForm from '../ResourceForm/ResourceForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadResource, updateResource } from '../../../redux/modules/resources';

class ResourceEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const resourceId = this.props.match.params.resourceId;
        // Load resources on component mount
        this.props.dispatchLoadResource(resourceId);
    }

    submit(values) {
        this.props.dispatchUpdateResource(values);
    }

    render() {
        const { t }           = this.props;
        const { resource } = this.props.resources;

        let title = <span>{t('resources.resource')}</span>;
        if (resource)
            title = (
                <span>
                    {t('resources.resource')}
                    <small> |&nbsp;{ t('resources.update_resource', { number: resource.number })} </small>
                </span>
            );

        const actions =
        <div>
            {resource &&
            <ButtonGroup>
                <Button tag={Link} to={ `/resources/show/${ resource.id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="ResourceEdit">
                <PageTitle icon="key" title={ title } actions={ actions } />

                {resource &&
                    <ResourceForm resourceId={resource.id} onSubmit={this.submit} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadResource: (resourceId) => loadResource(resourceId),
    dispatchUpdateResource: (resource) => updateResource(resource)
}

const mapStateToProps = state => ({
    resources: state.resources,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(ResourceEdit))
