import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    PageTitle, Loader,
    CollectorSituation, CollectorEvents, CollectorSituationResume,
    CollectorModules, CollectorTransactions
} from '../../../../components';
import { loadCollector, getCollectorStatus } from '../../../../redux';
import { Row, Col, Badge, Button } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import Icon from '@mdi/react';
import { useSelector } from 'react-redux';
import {
    mdiSlotMachine, mdiArrowLeftCircle, mdiWall,
    mdiAndroidMessages, mdiBankTransfer
} from '@mdi/js';
import {
    CollectButton, RefundButton, ChangeButton, AutotestButton,
    FillinButton, UnloadButton, DrainButton, ResetChestButton
} from './Buttons';

import './CollectorManagement.css';

const CollectorManagement = () => {
    const { t }           = useTranslation();
    const dispatch        = useDispatch();
    const { collectorId } = useParams();

    const { connected } = useSelector(state => state.sioBridge);
    const { collector } = useSelector(state => state.collectors, shallowEqual);
    const { isLoading } = useSelector(state => state.collectors);

    useEffect(() => {
        if (connected) {
            dispatch(loadCollector(collectorId));
            dispatch(getCollectorStatus(collectorId));
        }
    }, [dispatch, connected, collectorId]);

    const actions = <Link to="/devices/collectors">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    return (
        <div className="CollectorManagement">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('collectors.manage_title', { name: collector?.name }) }
                        icon="money"
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {(isLoading || !collector) && (
                        <Loader />
                    )}

                    {!isLoading && collector && (
                    <div>
                        <Row>
                            <Col>
                                <CollectorSituation collector={ collector } />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} xs={12}>
                                <div className="top-badges mt-3">
                                    <h4>
                                        <Badge color={ collector.state.connected ? 'success' : 'danger' }>
                                            { t(`collectors.${collector.state.connected ? 'connected' : 'disconnected'}`) }<br/>
                                        </Badge>
                                    </h4>
                                    <h4>
                                    { collector.state.connected && (
                                    <Badge className="ml-2" color={ 'info' }>
                                        { t(`collectors.statuses.${collector.state.status}`) }<br/>
                                    </Badge>
                                    )}
                                    { collector.state.lastStatus && (
                                    <Badge className="ml-2" color={ collector.isOpen() ? 'danger' : 'dark' }>
                                        { t(`collectors.acceptation.${ collector.isOpen() ? 'opened' : 'closed' }`) }<br/>
                                    </Badge>
                                    )}
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="mt-3 mb-3">
                                    <CardBody>
                                        <Row>
                                            <Col sm="12" md="3">
                                                <CollectButton collector={ collector } />
                                            </Col>
                                            <Col sm="12" md="3">
                                                <RefundButton collector={ collector } />
                                            </Col>
                                            <Col sm="12" md="3">
                                                <ChangeButton collector={ collector } />
                                            </Col>
                                            <Col sm="12" md="3">
                                                <AutotestButton collector={ collector } />
                                            </Col>
                                        </Row>
                                        <br/>
                                        <Row>
                                            <Col sm="12" md="3">
                                                <FillinButton collector={ collector } />
                                            </Col>
                                            <Col sm="12" md="3">
                                                <UnloadButton collector={ collector } />
                                            </Col>
                                            <Col sm="12" md="3">
                                                <DrainButton collector={ collector } />
                                            </Col>
                                            <Col sm="12" md="3">
                                                <ResetChestButton collector={ collector } />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ size: 4 }} sm={ 6 } xs={ 12 }>
                                <h4 className="col-title mt-3">
                                    <Icon path={ mdiWall } size={ 1.5 } color="#FFFFFF" />{ ' ' }
                                    { t('collectors.modules.title') }
                                </h4>
                                <Card className="mb-3">
                                    <CardBody>
                                        <CollectorModules collector={ collector } />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={{ size: 4 }} sm={ 6 } xs={ 12 }>
                                <h4 className="col-title mt-3">
                                    <Icon path={ mdiAndroidMessages } size={ 1.5 } color="#FFFFFF" />{ ' ' }
                                    { t('collectors.lastEvents') }
                                </h4>
                                <Card className="mb-3">
                                    <CardBody>
                                        <CollectorEvents collector={ collector } />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={ 4 } sm={ 6 } xs={ 12 }>
                                <h4 className="col-title mt-3">
                                    <Icon path={ mdiBankTransfer } size={ 1.5 } color="#FFFFFF" />{ ' ' }
                                    { t('collectors.lastTransactions') }
                                </h4>
                                <Card className="mb-3">
                                    <CardBody>
                                        <CollectorTransactions collector={ collector } />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default CollectorManagement;
