import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardFooter } from 'reactstrap';
import { FormGroup, Label, Jumbotron } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../../../services';
import { TranslatableField, WysiwygField, MoneyField } from '../../../../components';
import classnames from 'classnames';

import { addArticleFeature, removeArticleFeature } from '../../../../redux/modules/articles';

import './ArticleFeature.css';

const ArticleFeature = ({ feature, name, onRemove }) => {
    const { t }        = useTranslation();

    return (
        <div className="ArticleFeature">
            <Jumbotron className="mb-4 feature">
                <FormGroup row className="feature-header">
                    <Col sm={12} lg={3} className="col-bottom-align">
                        <Label for="name">{t("articles.feature.name")}</Label>
                    </Col>
                    <Col sm={9}>
                        <TranslatableField component="input" required name={ `${name}.name` } />
                    </Col>
                </FormGroup>
                <FormGroup row className="feature-body">
                    <Col sm={12} lg={3} className="col-center-align pb-3">
                        <Label for="choices">{t("articles.feature.choices")}</Label>
                    </Col>
                    { feature.choices.length > 0 && (
                    <Col sm={12} lg={9}>
                        { feature.choices.map((choice, j) => (
                        <Row key={ j } className="feature-choice">
                            <Col sm={12} lg={12}>
                            <TranslatableField
                                className="feature-choice-input"
                                component={ WysiwygField }
                                required
                                name={`${name}.choices[${j}].name`}
                            />
                            </Col>
                        </Row>
                        ))}
                    </Col>
                    )}
                </FormGroup>
                <Row className="feature-footer text-center">
                    <Col>
                        <ButtonGroup>
                            <Button color="danger" size="sm" onClick={ () => { onRemove(); }}>
                                <i className="fa fa-trash"></i>&nbsp;{t("articles.delete_feature")}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Jumbotron>
        </div>
    );
}

export default ArticleFeature;
