import store from '..';
import { Sectors } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction,
    linkedResourcesReducer, linkedResourcesAction
} = createRestSlices(Sectors);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer,
        linkedResourcesReducer
    ]);
}

/* Export CRUD actions */
export const startCreateSector         = startCreateAction;
export const createSector              = createAction;
export const loadSector                = getAction;
export const loadSectors               = listAction;
export const updateSector              = updateAction;
export const deleteSector              = deleteAction;
export const duplicateSector           = duplicateAction;
export const getSectorsLinkedResources = linkedResourcesAction;
