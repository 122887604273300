import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Filters } from '../../../services';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';
import 'react-table-6/react-table.css'
import { loadCashregisters } from '../../../redux/modules/cashregisters';
import { loadSalepoints } from '../../../redux/modules/salepoints';

import Table from '../../../components/Table/Table';

class CashregistersList extends Component<{}> {
    componentDidMount() {
        // Load salepoints && cashregisters on component mount
        this.props.dispatchLoadSalepoints();
        this.props.dispatchLoadCashregisters();
    }

    render() {
        const { salepoints }    = this.props.salepoints;
        const { cashregisters } = this.props.cashregisters;

        const { t } = this.props;

        const default_lang = Utils.default_lang();

        cashregisters.forEach(cashregister => {
            const salepoint = salepoints.find(salepoint => salepoint._id === cashregister.salepoint_id);
            cashregister.salepoint = salepoint;
        });

        const columns = [{
            Header: t("cashregisters.name"),
            accessor: "name",
            filterMethod: Filters.textFilterMethod(`name.${default_lang}`),
            Filter: Filters.textFilter,
            filterAll: true,
            Cell: row => row.value && row.original.name[default_lang]
        }, {
            Header: t("cashregisters.salepoint"),
            accessor: "salepoint",
            filterMethod: Filters.textFilterMethod("salepoint._id"),
            Filter: Filters.posFilter(salepoints),
            filterAll: true,
            Cell: row => row.original.salepoint && row.original.salepoint.name[default_lang]
        }, {
            Header: t("cashregisters.auto_closing"),
            accessor: "auto_closing",
            filterMethod: Filters.textFilterMethod("auto_closing"),
            Filter: Filters.cashregisterClosingStatusFilter(),
            filterAll: true,
            Cell: row => row.original.auto_closing ? t('cashregisters.auto_closing_enabled') : t('cashregisters.auto_closing_disabled')
        }, {
            Header: t("common.actions"),
            accessor: "_id",
            maxWidth: 110,
            filterable: false,
            Cell: row => (
                <TableButtons
                    id={row.value}
                    actions={["edit", "delete", "show"]}
                    module="cashregisters"
                />
            )
        }];

        const actions =
            <CreateButton 
                module="cashregisters"
                text="cashregisters.add"
            />

        const title =
        <span>{t("cashregisters.cashregister_plural")}</span>

        return (
            <div className="CashregistersList">
                <PageTitle icon="th" title={ title } actions={ actions } />
                <Table id="cashregistersTable" data={ cashregisters } columns={ columns } />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadCashregisters: () => loadCashregisters(),
    dispatchLoadSalepoints: () => loadSalepoints()
}

const mapStateToProps = state => ({
    cashregisters: state.cashregisters,
    salepoints: state.salepoints,
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(CashregistersList))
