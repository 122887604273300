import PdfLayout from '../PdfLayout';
import CustomTextWidget from './CustomTextWidget';
import { Utils } from '../../services';
import { useTranslation } from 'react-i18next';

/**
 * Class representing a PDF layout widget showing
 * an information about the customer.
 */
class CustomerInfoWidget extends CustomTextWidget {

    constructor(properties) {
        super({
            ...(CustomerInfoWidget.getDefaultOptions()),
            ...properties,
        });

        this.type    = PdfLayout.TEXT;
        this.subType = PdfLayout.CUSTOMER_INFO;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 5, height: 0.75 },
            transform: { rotation: 0 },
            options: {
                field: 'name',
                style: {
                    color: '#000000',
                    fontFamily: "Helvetica",
                    fontSize: 14,
                    lineHeight: 1,
                    textAlign: 'left',
                    textDecoration: 'none',
                    backgroundColor: '#FFFFFF00',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            PdfLayout.OPTION_CUSTOMER_FIELD
        ]).filter(o => o !== PdfLayout.OPTION_TEXT);
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreviewText(props) {

        const { t }   = useTranslation();
        const setting = props.setting;
        const lang    = props?.lang?.length != 0 ? props.lang : 'fr';


        switch (this.options.field) {
            case 'name':
                return Utils.localized_or_fallback(setting.customer.name, lang, t('models.customer.name'));
            case 'name_short':
                return Utils.localized_or_fallback(setting.customer.name_short, lang, t('models.customer.name_short'));
            case 'address':
                return Utils.localized_or_fallback(setting.customer.address, lang, t('models.customer.address'));
            case 'phone':
                return Utils.localized_or_fallback(setting.customer.phone, lang, t('models.customer.phone'));
            case 'email':
                return Utils.localized_or_fallback(setting.customer.email.mail, lang, t('models.customer.email'));
            case 'vat':
                return Utils.localized_or_fallback(setting.customer.vat, lang, t('models.customer.vat'));
            default:
                return '...';
        }
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'gear';
    }
}

export default CustomerInfoWidget;
