import store from '..';
import { EmailLayouts } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction
} = createRestSlices(EmailLayouts);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer
    ]);
}

/* Export CRUD actions */
export const startCreateEmailLayout = startCreateAction;
export const createEmailLayout      = createAction;
export const loadEmailLayout        = getAction;
export const loadEmailLayouts       = listAction;
export const updateEmailLayout      = updateAction;
export const deleteEmailLayout      = deleteAction;
export const duplicateEmailLayout   = duplicateAction;
