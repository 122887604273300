const theme = {
    dark: {
        axis: {
            legend: {
                text: {
                    fill: '#fff',
                }
            },
            tickColor: '#fff',
            ticks: {
                text: {
                    fill: '#fff',
                }
            }
        }, 
        background: '#171616',
        tooltip: {
            container: {
                background: '#000'
            },
        }
    },
    light: {
        axis: {
            legend: {
                text: {
                    fill: '#000',
                }
            },
            tickColor: '#000',
            ticks: {
                text: {
                    fill: '#000',
                }
            }
        }, 
        background: '#f8f9fa',
        tooltip: {
            container: {
                background: '#f8f9fa'
            },
        }
    },
};

export default theme;