import RestClient from './RestClient';
import { Distributor } from '../../models';

class Distributors extends RestClient {
    constructor() {
        super({
            "model": Distributor,
            "entryPoint": "distributor",
            "resource": "distributor",
            "resources": "distributors",
            "sortBy": "name"
        });
    }
}

const DistributorsApi = new Distributors();

export default DistributorsApi;
