import RestClient from './RestClient';
import { ArticleCategory } from '../../models';

class Articlecategories extends RestClient {
    constructor() {
        super({
            "model": ArticleCategory,
            "entryPoint": "articlecategory",
            "resource": "articlecategory",
            "resources": "articlecategories",
            "id_field": "_id"
        });
    }
}

const ArticlecategoriesApi = new Articlecategories();

export default ArticlecategoriesApi;
