import React, { useState } from 'react';
import { Field } from 'redux-form'
import { Utils } from '../../services/';

import './TranslatableField.css';

const TranslatableField = ({ name, placeholder, component, optional, original, ...props }) => {
    const dataFor = props["data-for"];
    const langs = original ? ['original'].concat(Utils.langs()) : Utils.langs();
    const [selectedLang, setSelectedLang] = useState(langs[0]);

    const select_lang = (lang) => {
        setSelectedLang(lang);
    }

    return (
        <div className="TranslatableField">

            <div className="lang-switchers">
                {langs.length > 1 && langs.map((l) => (
                    <a key={l} onClick={(e) => { select_lang(l); }} className={"lang-switcher" + (selectedLang === l ? " active" : "")}>
                        {l.toUpperCase()}
                    </a>
                ))}
            </div>

            {langs.length && langs.map((l, i) => (
                <div key={l} className="translatable-field-wrapper" style={{ display: selectedLang === l ? 'initial' : 'none' }}>
                    <Field data-tip data-for={dataFor} required={!optional && i == 0} component={component} className="form-control" type="text" name={name + "." + l} id={name + "." + l} autoComplete={name + "." + l} placeholder={placeholder} />
                </div>
            ))}
        </div>
    );
}

export default TranslatableField;
