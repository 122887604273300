import React from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';

import './EnvsField.css';

const EnvsField = ({ name }) => {
    const { t }   = useTranslation();
    const options = Utils.envs().map(e => {
        return {
            key: e,
            text: t(`envs.${e}`),
            value: e
        };
    });

    return <SelectField name={ name } options={ options } />;
}

export default EnvsField;
