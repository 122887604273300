import React, { useEffect, useMemo } from 'react';
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locales/i18n';
import _ from 'lodash';
import 'react-table-6/react-table.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { loadScreenings, updateFilters } from '../../../redux/modules/screenings';
import Table from '../../../components/Table/Table';
import EventTitle from '../../../components/EventTitle/EventTitle';
import { Filters, Utils } from '../../../services';
import ProgressBarScreenings from './ProgressBarScreenings/ProgressBarScreenings';
import ScreeningsFilters from './ScreeningFilters/ScreeningsFilters';
import { TableButtons } from '../../../components';
import CreateButton from '../../../components/CreateButton';

import './ScreeningsList.css';

moment.locale(i18n.language);

const ScreeningsList = ({ }) => {
    const dispatch                           = useDispatch();
    const { t }                              = useTranslation();
    const { screenings, isLoading, filters } = useSelector(state => state.screenings)
    const { isMobile }                       = useSelector(state => state.ui);

    const submit = (values) => {
        dispatch(updateFilters(values));
        loadSceeningsWithFilters(values);
    }

    useEffect(() => {
        loadSceeningsWithFilters(filters)
    }, [dispatch])

    const loadSceeningsWithFilters = (values) => {
        dispatch(loadScreenings({
            start_at_gte: (values?.start_at_gte || moment('startof_day')).toISOString(),
            stop_at_lte: (values?.stop_at_lte || moment('endof_day')).toISOString(),
            places_ids: (values?.places_ids || [])
        }));
    }

    const title =
        <span>{t("screenings.screening_plural")}</span>

    const processedScreenings = useMemo(() => {
        if (screenings.length > 0) {
            return screenings.map(s => {
                let temp = s;
                let totalConfirmed = 0;
                s.buckets.map(b => {
                    totalConfirmed += b.confirmed ?? 0
                })
                s.buckets['totalConfirmed'] = totalConfirmed;
                return temp
            })
        }
        else return [];
    }, [screenings])

    const places = useMemo(() => {
        const result = [];
        if (screenings)
            screenings.forEach(s => {
                if (!result.find(r => r._id === s.cinema_hall._id))
                    result.push(s.cinema_hall);
            })
        return result;
    }, [screenings]);

    const columns = useMemo(() => {
        if (processedScreenings && places && processedScreenings.length > 0 && places.length > 0) {
            if (isMobile)
                return (
                    [
                        {
                            Header: t("app.screenings"),
                            filterAll: true,
                            accessor: '_id',
                            filterMethod: Filters.textFilterMethod([
                                `_original.title.original`,
                                `_original.title.${i18n.language}`,
                                `_original.cinema_hall.name`,
                                `_original.cinema_hall.cinema`
                            ]),
                            Filter: Filters.textFilter,
                            Cell: row => {
                                if (row.value) {
                                    const currentScreening = row.original;
                                    const start_at = currentScreening.start_at;
                                    const stop_at = currentScreening.stop_at;
                                    const duration = moment(stop_at).diff(moment(start_at), 'seconds', true)
                                    return (
                                        <Card className='w-100'>
                                            <CardHeader className="p-0">
                                                <div className="screening_cell">
                                                    <Link to={`/screenings/edit/${currentScreening._id}`}>
                                                        <Card>
                                                            <CardBody style={{
                                                                "backgroundImage": "url('" + (
                                                                    currentScreening?.opaque?.posters && currentScreening?.opaque?.posters?.length > 0 ?
                                                                        currentScreening.opaque.posters[0].url : ""
                                                                ) + "')",
                                                                "backgroundPosition": "center center",
                                                                "backgroundSize": "cover"
                                                            }}>
                                                                <EventTitle centered textSize="30" titles={currentScreening.title} />
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <h4 className='text-center'>
                                                    {currentScreening.cinema_hall.name.trim() == currentScreening.cinema_hall.cinema.trim() ?
                                                        currentScreening.cinema_hall.name :
                                                        <span>
                                                            {currentScreening.cinema_hall.name}
                                                            <br />
                                                            {currentScreening.cinema_hall.cinema}
                                                        </span>
                                                    }
                                                </h4>
                                                <Row>
                                                    <Col xs={3}>
                                                        {t("programmations.start_at")}
                                                    </Col>
                                                    <Col xs={9} className='text-right'>
                                                        {moment(start_at).format('llll')}
                                                    </Col>
                                                    <Col xs={3}>
                                                        {t("programmations.stop_at")}
                                                    </Col>
                                                    <Col xs={9} className='text-right'>
                                                        {moment(stop_at).format('llll')}
                                                    </Col>
                                                    <Col xs={3}>
                                                        {t("screenings.duration")}
                                                    </Col>
                                                    <Col xs={9} className='text-right'>
                                                        {moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format("hh:mm:ss")}
                                                    </Col>
                                                    <Col xs={3}>
                                                        {t("screenings.starts")}
                                                    </Col>
                                                    <Col xs={9} className='text-right'>
                                                        {moment(start_at).fromNow()}
                                                    </Col>
                                                </Row>
                                                <ProgressBarScreenings data={currentScreening.buckets} />
                                            </CardBody>
                                            <CardFooter className='d-flex align-items-center justify-content-end'>
                                                <TableButtons
                                                    actions={['edit', 'delete']}
                                                    module='screenings'
                                                    id={currentScreening._id}
                                                />
                                            </CardFooter>
                                        </Card>
                                    )
                                }
                            }
                        }
                    ]
                );
            else
                return (
                    [
                        {
                            Header: t("screenings.name"),
                            accessor: 'title',
                            filterMethod: Filters.textFilterMethod([`title.${i18n.language}`, 'title.original']),
                            Filter: Filters.textFilter,
                            filterAll: true,
                            Cell: row => row.value &&
                                <div className="screening_cell">
                                    <Link to={`/screenings/edit/${row.original._id}`}>
                                        <Card>
                                            <CardBody style={{
                                                "backgroundImage": "url('" + (
                                                    row?.original?.opaque?.posters && row?.original?.opaque?.posters?.length > 0 ?
                                                        row.original.opaque.posters[0].url : ""
                                                ) + "')",
                                                "backgroundPosition": "center center",
                                                "backgroundSize": "cover"
                                            }}>
                                                <EventTitle titles={row.value} />
                                            </CardBody>
                                        </Card>
                                    </Link>
                                </div>
                        }, {
                            Header: t("screenings.place"),
                            accessor: 'cinema_hall',
                            filterMethod: Filters.textFilterMethod("cinema_hall._id"),
                            Filter: Filters.placesFilter(places),
                            filterAll: true,
                            Cell: row => row.value &&
                                <span>
                                    {row.value.name} - {row.value.cinema}
                                </span>
                        },
                        {
                            Header: t("programmations.start_at") + ' / ' + t("programmations.stop_at"),
                            Filter: Filters.dateFilter(false),
                            filterMethod: Filters.dateFilterMethod('start_at'),
                            filterAll: true,
                            accessor: 'start_at',
                            Cell: row => {
                                const start_at = row.value;
                                const stop_at = row.original.stop_at;
                                const duration = moment(stop_at).diff(moment(start_at), 'seconds', true)
                                return (
                                    <>
                                        <i className="fa fa-calendar"></i>&nbsp;{moment(start_at).format('llll')}<br />
                                        <i className="fa fa-calendar"></i>&nbsp;{moment(stop_at).format('llll')}<br />
                                        Durée : {moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format("hh:mm:ss")} - {moment(start_at).fromNow()}
                                    </>
                                )
                            }
                        },
                        {
                            Header: t("screenings.buckets"),
                            width: 525,
                            accessor: 'buckets',
                            filterable: false,
                            sortable: true,
                            sortMethod: Utils.sortOnProperty('totalConfirmed'),
                            Cell: row =>
                                <ProgressBarScreenings data={row.value} />
                        }, {
                            Header: t("common.actions"),
                            accessor: '_id',
                            className: "actions-cell",
                            maxWidth: 90,
                            filterable: false,
                            sortable: false,
                            Cell: row =>
                                <TableButtons
                                    actions={['edit', 'delete']}
                                    module='screenings'
                                    id={row.value}
                                />
                        }
                    ]
                );
        }
    }, [screenings, places, isMobile])

    const actions =
        <CreateButton module="screenings" text="screenings.add" />

    return (
        <div className="ScreeningsList">
            <PageTitle icon="th" title={title} actions={actions}/>
            <ScreeningsFilters places={places ?? {}} onSubmit={submit} isLoading={isLoading} />
            {(processedScreenings?.length > 0 && columns?.length > 0) &&
                <Table
                    id="screeningsTable"
                    data={processedScreenings}
                    columns={columns}
                    columnsSortOrder={["start_at"]}
                />
            }
        </div>
    );
}

export default ScreeningsList
