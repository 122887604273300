// Redux Resources module
// see https://github.com/erikras/ducks-modular-redux

import { Resources } from '../../services';

const LOADING_RESOURCES         = 'kronos/resources/LOADING_RESOURCES';
const LOADING_RESOURCES_SUCCESS = 'kronos/resources/LOADING_RESOURCES_SUCCESS';
const LOADING_RESOURCES_FAILURE = 'kronos/resources/LOADING_RESOURCES_FAILURE';
const LOADING_RESOURCE          = 'kronos/resources/LOADING_RESOURCE';
const LOADING_RESOURCE_SUCCESS  = 'kronos/resources/LOADING_RESOURCE_SUCCESS';
const LOADING_RESOURCE_FAILURE  = 'kronos/resources/LOADING_RESOURCE_FAILURE';
const UPDATING_RESOURCE         = 'kronos/resources/UPDATING_RESOURCE';
const UPDATING_RESOURCE_SUCCESS = 'kronos/resources/UPDATING_RESOURCE_SUCCESS';
const UPDATING_RESOURCE_FAILURE = 'kronos/resources/UPDATING_RESOURCE_FAILURE';
const START_CREATING_RESOURCE   = 'kronos/resources/START_CREATING_RESOURCE';
const CREATING_RESOURCE         = 'kronos/resources/CREATING_RESOURCE';
const CREATING_RESOURCE_SUCCESS = 'kronos/resources/CREATING_RESOURCE_SUCCESS';
const CREATING_RESOURCE_FAILURE = 'kronos/resources/CREATING_RESOURCE_FAILURE';
const DELETING_RESOURCE         = 'kronos/resources/DELETING_RESOURCE';
const DELETING_RESOURCE_SUCCESS = 'kronos/resources/DELETING_RESOURCE_SUCCESS';
const DELETING_RESOURCE_FAILURE = 'kronos/resources/DELETING_RESOURCE_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    resourceSuccessfullyCreated: false,
    resources: [],
    resource: null
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_RESOURCES:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_RESOURCES_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                resources: action.resources
            };
        case LOADING_RESOURCES_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_RESOURCE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_RESOURCE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                resource: action.resource
            };
        case LOADING_RESOURCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_RESOURCE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_RESOURCE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                resource: action.resource
            };
        case UPDATING_RESOURCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_RESOURCE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                resourceSuccessfullyCreated: false,
                resource: {
                    resource_type: 'key'
                }
            };
        case CREATING_RESOURCE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_RESOURCE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                resourceSuccessfullyCreated: true,
                resource: action.resource
            };
        case CREATING_RESOURCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                resourceSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_RESOURCE:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_RESOURCE_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                resourceSuccessfullyDeleted: true,
                resource: action.resource
            };
        case DELETING_RESOURCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                resourceSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingResources() { return { type: LOADING_RESOURCES } }
function loadingResourcesSuccess(resources) { return { type: LOADING_RESOURCES_SUCCESS, resources: resources } }
function loadingResourcesFailure(err) { return { type: LOADING_RESOURCES_FAILURE, error: err } }
export function loadResources(resourcename, password) {
    return (dispatch) => {
        dispatch(loadingResources());
        Resources.list()
            .then(data => {
                const resources = data.resources;
                dispatch(loadingResourcesSuccess(resources));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingResourcesFailure(err))
            });
    }
}

function loadingResource() { return { type: LOADING_RESOURCE } }
function loadingResourceSuccess(resource) { return { type: LOADING_RESOURCE_SUCCESS, resource: resource } }
function loadingResourceFailure(err) { return { type: LOADING_RESOURCE_FAILURE, error: err } }
export function loadResource(resourceId) {
    return (dispatch) => {
        dispatch(loadingResource());
        Resources.get(resourceId)
            .then(data => {
                const resource = data.resource;
                dispatch(loadingResourceSuccess(resource));
            })
            .catch(err => {
                dispatch(loadingResourceFailure(err))
            });
    }
}

function updatingResource() { return { type: UPDATING_RESOURCE } }
function updatingResourceSuccess(resource) { return { type: UPDATING_RESOURCE_SUCCESS, resource: resource } }
function updatingResourceFailure(err) { return { type: UPDATING_RESOURCE_FAILURE, error: err } }
export function updateResource(resource) {
    return (dispatch) => {
        dispatch(updatingResource());
        Resources.update(resource)
            .then(data => {
                const resource = data.resource;
                dispatch(updatingResourceSuccess(resource));
            })
            .catch(err => {
                dispatch(updatingResourceFailure(err))
            });
    }
}

function creatingResource() { return { type: CREATING_RESOURCE } }
function creatingResourceSuccess(resource) { return { type: CREATING_RESOURCE_SUCCESS, resource: resource } }
function creatingResourceFailure(err) { return { type: CREATING_RESOURCE_FAILURE, error: err } }
export function startCreateResource() { return { type: START_CREATING_RESOURCE } }
export function createResource(resource) {
    return (dispatch) => {
        dispatch(creatingResource());
        Resources.create(resource)
            .then(data => {
                const resource = data.resource;
                dispatch(creatingResourceSuccess(resource));
                dispatch(loadingResourceSuccess(resource));
            })
            .catch(err => {
                dispatch(creatingResourceFailure(err))
            });
    }
}

function deletingResource() { return { type: DELETING_RESOURCE } }
function deletingResourceSuccess() { return { type: DELETING_RESOURCE_SUCCESS } }
function deletingResourceFailure(err) { return { type: DELETING_RESOURCE_FAILURE, error: err } }
export function deleteResource(resourceId) {
    return (dispatch) => {
        dispatch(deletingResource());
        Resources.delete(resourceId)
            .then(data => {
                dispatch(deletingResourceSuccess());
            })
            .catch(err => {
                dispatch(deletingResourceFailure(err))
            });
    }
}
