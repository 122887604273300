import React from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody } from 'reactstrap';
import { Utils } from '../../../../services';
import ArticleFeature from '../ArticleFeature/ArticleFeature';

import { addArticleFeature, removeArticleFeature } from '../../../../redux/modules/articles';

import './ArticleFeatures.css';

const ArticleFeatures = ({ attributes, fields, submit, ...rest }) => {
    const { t }        = useTranslation();
    const dispatch     = useDispatch();
    const default_lang = Utils.default_lang();
    const currency     = Utils.currency();

    const { article } = useSelector(state => state.articles);

    const add = () => {
        dispatch(addArticleFeature());
        submit();
    }

    const remove = (featureIndex) => {
        dispatch(removeArticleFeature(featureIndex));
        submit();
    }

    return (
        <Row>
            <Col>
                <Card className="ArticleFeatures">
                    <CardBody>
                        <Row>
                        { attributes && attributes.map((f, i) => !f.isFeature() ? null : (
                            <Col sm={ 12 } lg={ 6 } key={ i }>
                                <ArticleFeature feature={ f } name={`${fields.name}[${i}]`} onRemove={ () => remove(i) } />
                            </Col>
                        )) }
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <ButtonGroup>
                                    <Button color="info" size="sm" onClick={ add }>
                                        <i className="fa fa-plus"></i>&nbsp;{t("articles.add_feature")}
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default ArticleFeatures;
