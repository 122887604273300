import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Dropdown } from 'semantic-ui-react';
import DurationCustomComponent from '../DurationField/DurationCustomComponent';
import DurationPredefinedComponent from '../DurationField/DurationPredefinedComponent';
import OverlapQuestionComponent from './OverlapQuestionComponent';

import './OverlapField.css';

const OverlapField = ({ name, defaultValue, predefinedValues, onlyParts }) => {
    const { t } = useTranslation();

    const [duration, setDuration] = useState(defaultValue);
    const [customFieldIsVisible, setCustomFieldIsVisible] = useState(!predefinedValues);
    const [sign, setSign] = useState(defaultValue?.charAt(0) === '-' ? '-' : '+')

    useEffect(() => {
        if (duration && !predefinedValues?.map(v => v.value).includes(duration.replace(/([+-])/g, '')))
            setCustomFieldIsVisible(true);
    }, [duration]);

    const normalizer = value => {
        if (!value)
            return value;

        value = value.replace(/([+-])/g, '');

        if (sign === '-') {
            value = '-' + value;
        }

        return value;
    };

    useEffect(() => {
        if (!duration)
            return;

        setDuration(normalizer(duration));
    }, [sign]);

    const questions = [
        { value: '---', text: '---' },
        { value: '+', text: t('tickettypes.window.overlap_question_positive') },
        { value: '-', text: t('tickettypes.window.overlap_question_negative') }
    ].map(v => ({
        text: v.text,
        key: v.value,
        value: v.value
    }));

    const onDurationChange = value => {
        setDuration(normalizer(value));
    };

    return (
        <div className="OverlapField">
            <Dropdown
                className="form-control"
                options={ questions }
                onChange={ (e, data) => { setSign(data.value); }}
                value={ sign }
                search
                selection
            />

            {customFieldIsVisible && (
                <Field className="form-control" name={ name } component={
                    props => <DurationCustomComponent
                        onDurationChange={onDurationChange}
                        onlyParts={onlyParts}
                        setCustomFieldIsVisible={setCustomFieldIsVisible}
                        normalize={ normalizer }
                        sign={ sign }
                        {...props}
                    />
                }/>
            )}

            {!customFieldIsVisible && (
                <Field className="form-control" name={ name } component={
                    props => <DurationPredefinedComponent
                        predefinedValues={predefinedValues}
                        onDurationChange={onDurationChange}
                        setDurationValue={setDuration}
                        setCustomFieldIsVisible={setCustomFieldIsVisible}
                        normalize={ normalizer }
                        sign={ sign }
                        {...props}
                    />
                }/>
            )}
        </div>
    );
}

export default OverlapField;
