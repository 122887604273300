import React from "react";
import FormLayout from '../FormLayout';
import TextField from './TextField';
import { Field } from 'redux-form';
import { WysiwygField } from '../../components';

/** Class representing a PDF layout custom text field. */
class RichTextField extends TextField {

    constructor(properties) {
        super({
            ...(RichTextField.getDefaultOptions()),
            ...properties,
        });

        this.type = FormLayout.RICHTEXT;
    }

    /**
     * Return this field preview input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getInput(props) {
        return (
            <Field
                component={ WysiwygField }
                name={this.id}
                required={!!this.required}
                className="form-control"
            />
        );
    }


    /**
     * Render a value
     *
     * @param {Any} value
     * @param {Object} context: an object with the following keys:
     *                   - lang (mandatory)
     *                   - t (mandatory)
     *                   - format (optional)
     *
     * @return {String}
     */
    renderValue(value, context) {
        return <span dangerouslySetInnerHTML= {{__html: value}}></span>;
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'align-center';
    }
}

export default RichTextField;
