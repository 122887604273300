import BaseModel from './BaseModel';
import CollectorEvent from './CollectorEvent';
import CollectorFilling from './CollectorFilling';
import CollectorModule from './CollectorModule';
import CollectorTransaction from './CollectorTransaction';

/** Class representing an user. */
class Collector extends BaseModel {
    static MONEY_TYPE_COINS = 'coins';
    static MONEY_TYPE_BILLS = 'bills';

    static MODE_IDLE                 = 'idle';
    static MODE_DRAINING             = 'draining';
    static MODE_FILLING              = 'filling';
    static MODE_CANCELLATION         = 'cancellation';
    static MODE_ERROR_AUTO_TREATMENT = 'treatment error';
    static MODE_UN                   = 'NU';
    static MODE_SELF_TEST            = 'autotest';
    static MODE_CHEST_RESET          = 'reset';
    static MODE_EXCHANGE             = 'exchange';
    static MODE_UNLOAD               = 'unload';
    static MODE_TICKET_DISPLAY       = 'ticket';
    static MODE_PAYING               = 'payment';

    constructor(properties) {
        super(properties);

        if (this.state.lastEvents?.length > 0)
            this.state.lastEvents = this.state.lastEvents.map(e => new CollectorEvent(e));

        if (this.state.lastTransactions?.length > 0)
            this.state.lastTransactions = this.state.lastTransactions.map(t => new CollectorTransaction(t));

        if (this.state.modules?.length > 0)
            this.state.modules = this.state.modules.map(m => new CollectorModule(m));

        if (this.state.lastFilling?.inserted)
            this.state.lastFilling = new CollectorFilling(this.state.lastFilling);
    }

    isConnected() {
        return !!this.state?.connected;
    }

    isLoading() {
        return [
            Collector.MODE_DRAINING,
            Collector.MODE_FILLING,
            Collector.MODE_CANCELLATION,
            Collector.MODE_SELF_TEST,
            Collector.MODE_CHEST_RESET,
            Collector.MODE_EXCHANGE,
            Collector.MODE_UNLOAD,
            Collector.MODE_PAYING
        ].includes(this.state.status);
    }

    isIdle() {
        return this.state.status === Collector.MODE_IDLE;
    }

    isFilling() {
        return this.state.status === Collector.MODE_FILLING;
    }

    /**
     * Is this collector accepting money
     */
    isOpen() {
        return this.state.lastStatus?.pilotage === 0;
    }
}

export default Collector;
