import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Filters, Utils } from '../../../services';
import 'react-table-6/react-table.css'
import { loadEvents, searchEvents, setTextSearch } from '../../../redux';
import { Badge, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { Event } from '../../../models';
import { PageTitle, Table, TableButtons, CreateButton, EventTitle, ItemsList } from '../../../components';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import _ from 'lodash';

import './EventsList.css';

const EventsList = () => {
    const [ tab, setTab ] = useState('mine');
    const textInput       = useRef(null);
    const textValue       = useSelector(state => state.search.text.scopes.events);
    const { user }        = useSelector(state => state.auth);
    const { t }           = useTranslation()
    const { type }        = useParams();
    const dispatch        = useDispatch();
    const default_lang    = Utils.default_lang();

    const { events, results, isLoading } = useSelector(state => state.events);

    useEffect(() => {
        if (type && (tab === 'mine'))
            dispatch(loadEvents(type))
        else if (textValue)
            dispatch(searchEvents(type, textValue))
    }, [type, tab])

    useEffect(() => {
        if (textInput.current) {
            textInput.current.focus();
            if (textValue)
                textInput.current.value = textValue;
        }
    }, [tab])

    useEffect(() => {
        if (textValue)
            dispatch(searchEvents(type, textValue))
    }, [textValue]);

    const textChanged = _.debounce((value) => {
        dispatch(setTextSearch('events', value));
    }, 250);

    const clearText = () => {
        textInput.current.value = "";
        dispatch(setTextSearch('events', ""));
    };

    const tabs = [{
        key: 'mine',
        title: t(`events.subevents.mine_${type}`),
        icon: 'user',
        active: tab === 'mine',
        depth: 0,
        selection: 'mine'
    }, {
        key: 'all',
        title: t(`events.subevents.all_${type}`),
        icon: 'database',
        active: tab === 'all',
        depth: 0,
        selection: 'all'
    }];

    const isMine = React.useCallback(event => event.tkt_instance_id === user?.tkt_instance_id, [
        user
    ]);
    const columns = useMemo(() => {
        const temp = [{
            Header: t("events.title"),
            accessor: "title",
            filterMethod: Filters.textFilterMethod([`title.${default_lang}`, '_original.original_title']),
            Filter: Filters.textFilter,
            filterAll: true,
            maxWidth: 300,
            Cell: row => row.value && (
                <div className="event_cell">
                    <Link to={`/events/${type}/${isMine(row.original) ? 'edit' : 'show'}/${row.original.id}`}>
                        <Card>
                            <CardBody style={{
                                "backgroundImage": "url('" + (
                                    row.original.posters && row.original.posters.length ?
                                        row.original.posters[0].url : ""
                                ) + "')",
                                "backgroundPosition": "center center",
                                "backgroundSize": "cover"
                            }}>
                                <EventTitle badge={isMine(row.original) ? null : row.original.tkt_instance_name} titles={row.value} original={row.original.original_title} />
                            </CardBody>
                        </Card>
                    </Link>
                </div>
            )
        }];

        if (type === Utils.EVENT_TYPE_MOVIE) {
            temp.push({
                Header: t("events.suisa"),
                accessor: 'suisa',
                filterMethod: Filters.textFilterMethod('suisa'),
                Filter: Filters.textFilter,
                filterAll: true,
            });
        }

        const second_column = type === Utils.EVENT_TYPE_SERVICE ? "service_type" : "genre";
        temp.push({
            Header: t("events." + second_column),
            accessor: second_column,
            Cell: row => (
                type === Utils.EVENT_TYPE_SERVICE ? Utils.service_types(row.value).label : row.value
            ),
            filterMethod: Filters.textFilterMethod(second_column),
            Filter: Filters.textFilter,
            filterAll: true,
        }, {
            Header: t("common.actions"),
            accessor: "id",
            maxWidth: 110,
            filterable: false,
            Cell: row => {
                return (
                    <TableButtons
                        id={row.value}
                        actions={isMine(row.original) ? ["edit", "delete"] : ['show']}
                        module={`events/${type}`}
                        baseModule="events"
                        owner={row.original.created_by}
                    />
                )
            }
        });

        return temp;
    }, [events])

    const actions =
        <CreateButton
            module={`events/${type}`}
            baseModule="events"
            text={`events.add.${type}`}
        />

    const title =
        <span>{t(`events.subevents.${type}`)}</span>

    return (
        <div className="EventsList">
            <PageTitle icon={ Event.getTypeIcon(type) } title={title} actions={actions} />
            <ItemsList
                horizontal
                items={ tabs }
                onSelect={ setTab }
            />
            { (tab === 'mine') && (
                <Table id="eventsTable" data={events} columns={columns} />
            )}
            { (tab === 'all') && (
                <>
                    <div className="input text-input">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className={`fa fa-${isLoading ? 'spinner fa-spin' : 'search'}`} />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                innerRef={ textInput }
                                placeholder={ t(`events.search_placeholder.${type}`) }
                                onChange={ e => textChanged(e.target.value) }
                            />
                        </InputGroup>
                        {textValue && (
                            <i className="fa fa-times" onClick={ clearText }></i>
                        )}
                    </div>
                    {textValue && (
                        <Table id="eventsSearchTable" data={results} columns={columns} />
                    )}
                </>
            )}
        </div>
    );
}

export default EventsList;
