import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import OldScreeningsList from './OldScreeningsList/OldScreeningsList';
import OldScreeningsTktSync from './OldScreeningsTktSync/OldScreeningsTktSync';
import OldScreeningShow from './OldScreeningShow/OldScreeningShow';
import OldScreeningEdit from './OldScreeningEdit/OldScreeningEdit';
import OldScreeningDelete from './OldScreeningDelete/OldScreeningDelete';

const OldScreenings = ({ match }) => {
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.legacyScreenings);

    return (
        <div className="OldScreenings">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{ __html: loadingErrorMessage }} />
            }
            <Route exact path={match.path} component={OldScreeningsList} />
            <Route exact path={`${match.url}/programmation/:programmationId?`} component={OldScreeningsList} />
            <Route exact path={`${match.url}/show/:screeningId`} component={OldScreeningShow} />
            <Route exact path={`${match.url}/edit/:screeningId`} component={OldScreeningEdit} />
            <Route exact path={`${match.url}/delete/:screeningId/:programmationId`} component={OldScreeningDelete} />
            <Route exact path={`${match.url}/tktsync`} component={OldScreeningsTktSync} />
        </div>
    );
}

export default OldScreenings;
