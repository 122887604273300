import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiSitemap } from '@mdi/js';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadCollectors, loadPrinters, loadEfts } from '../../../redux';
import { CollectorResume, PrinterResume, EftResume } from '../../../components';

import './Dashboard.css';

const Dashboard = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { connected }  = useSelector(state => state.sioBridge);
    const { collectors } = useSelector(state => state.collectors);
    const { printers }   = useSelector(state => state.printers);
    const { efts }       = useSelector(state => state.efts);

    useEffect(() => {
        if (connected) {
            dispatch(loadCollectors());
            dispatch(loadPrinters());
            dispatch(loadEfts());
        }
    }, [dispatch, connected]);

    const actions = <Link to="/devices">
        <Button color="info">
            <Icon path={ mdiSitemap } size={ 1 } color="white" /> { t('devices.map') }
        </Button>
    </Link>;
    const deviceStyle = {
        width: '220px',
        float: 'left',
        marginRight: '50px'
    };

    return (
        <div className="Dashboard">
            <PageTitle
                title={ t('devices.title') }
                icon="cubes"
                actions= {actions }
            />
            { collectors && collectors.length > 0 && (
            <>
                <h4>
                    <i className="fa fa-money" />{' '}
                    {t('collectors.title')}
                </h4>
                <Row>
                    <Col>
                    { collectors.filter(c => c.isConnected()).map(collector => (
                        <CollectorResume key={ collector.id } collector={ collector } className="mb-3" style={ deviceStyle } />
                    ))}
                    { collectors.filter(c => !c.isConnected()).map(collector => (
                        <CollectorResume key={ collector.id } collector={ collector } className="mb-3" style={ deviceStyle } />
                    ))}
                    </Col>
                </Row>
            </>
            )}
            { printers && printers.length > 0 && (
            <>
                <h4>
                    <i className="fa fa-print" />{' '}
                    {t('printers.title')}
                </h4>
                <Row>
                    <Col>
                   { printers.filter(p => p.isConnected()).map(printer => (
                        <PrinterResume key={ printer.id } printer={ printer } className="mb-3" style={ deviceStyle } />
                    ))}
                   { printers.filter(p => !p.isConnected() && p.wasRecentlyConnected()).map(printer => (
                        <PrinterResume key={ printer.id } printer={ printer } className="mb-3" style={ deviceStyle } />
                    ))}
                    </Col>
                </Row>
            </>
            )}
            { efts && efts.length > 0 && (
            <>
                <h4>
                    <i className="fa fa-credit-card" />{' '}
                    {t('efts.title')}
                </h4>
                <Row>
                    <Col>
                    { efts && efts.filter(eft => eft.isConnected()).map(eft => (
                        <EftResume key={ eft.id } eft={ eft } className="mb-3" style={ deviceStyle } />
                    ))}
                    { efts && efts.filter(eft => !eft.isConnected() && eft.wasRecentlyConnected()).map(eft => (
                        <EftResume key={ eft.id } eft={ eft } className="mb-3" style={ deviceStyle } />
                    ))}
                    </Col>
                </Row>
            </>
            )}
        </div>
    );
}

export default Dashboard;
