import React from "react";
import { v4 } from 'uuid';
import BaseModel from './BaseModel';
import FormLayout from './FormLayout';
import FormLayoutFieldChoice from './FormLayoutFieldChoice';
import _ from 'lodash';

/** Class representing a Form layout field. */
class FormLayoutField extends BaseModel {
    constructor(properties) {
        super(properties || {});

        if (!this.id)
            this.id = v4();

        if (this.choices?.length)
            this.choices = this.choices.map(choice => new FormLayoutFieldChoice(choice));
    }

    getType() {
        return this.type;
    }

    /**
     * Get this field default options
     */
    static getDefaultOptions() {
        return {
            name: '',
            label: {

            },
            width: 1,
            required: false,
            rows: 0,
            min: -Infinity,
            max: Infinity,
            step: 1,
            with_time: false,
            multiple: false,
            expanded: false,
            extensions: []
        };
    }

    /**
     * Create a new choice empty choice
     */
    static createChoice() {
        return {
            id: v4(),
            label: {

            }
        };
    }

    /**
     * Return the list of options this field handles
     */
    getHandledOptions() {
        return [
            FormLayout.OPTION_WIDTH,
            FormLayout.OPTION_NAME,
            FormLayout.OPTION_LABEL,
            FormLayout.OPTION_REQUIRED,
        ];
    }

    /**
     * Check if this field handles the provided option
     */
    handleOption(option) {
        return this.getHandledOptions().includes(option);
    }

    /**
     * Return this field preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {FormLayout} layout
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        return <div>{this.type}</div>;
    }

    /**
     * Return this field preview input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getPreviewInput(props) {
        return this.getInput(props);
    }

    /**
     * Return this field preview label
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getPreviewLabel(props) {
        return this.getLabel(props);
    }

    /**
     * Render this field input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getInput(props) {
        return (
            <input className="form-control" type="text" name={this.name} required={!!this.required} />
        );
    }

    /**
     * Render this field label
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getLabel(props) {
        return (
            <label className={!!this.required ? 'required' : ''}>
                { this.label[props.lang]}
            </label>
        );
    }

    /**
     * Render a value
     *
     * @param {Any} value
     * @param {Object} context: an object with the following keys:
     *                   - lang (mandatory)
     *                   - t (mandatory)
     *                   - format (optional)
     *
     * @return {String}
     */
    renderValue(value, context) {
        if (!_.isObject(value))
            // convert scalar values to string
            return '' + value;

        if (context?.lang && (context.lang in value))
            return value[context.lang];

        const keys = Object.keys(value);
        if (keys.length === 0)
            return null;

        return value[keys[0]];
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'cube';
    }

    /**
     * Is this field resizable ?
     **/
    isResizable = () => false;

    /**
     * Is this field resizable only horizontally ?
     **/
    isResizableHorizontallyOnly = () => false;

    /**
     * Is this field editable ?
     **/
    isEditable = () => true;

    /**
     * Set one of this field option
     */
    setOption = (option, value) => {
        this[option] = value;
    };

    /**
     * Convert this field instance to a plain object
     */
    toPlainObject = () => JSON.parse(JSON.stringify(this));
}

export default FormLayoutField;
