import React from 'react';
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import i18n from "i18next";

const SectorSelector = ({ sectors, single, name, onChange, placeholder }) => {

    const options = sectors.map((sector) => ({
        key: sector.id,
        text: Utils.localized_or_fallback(sector.name, i18n.language),
        value: sector.id
    }));

    return <Dropdown
        name={ name }
        options={ options }
        onChange={ onChange }
        placeholder={ placeholder }
        clearable
        search
        selection
    />;
}

export default SectorSelector;
