import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../../services/';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import _ from 'lodash';

import './PostcategoriesBrowser.css';

const PostcategoriesBrowser = ({ post_categories, active, onSelect }) => {
    const { t } = useTranslation();
    if (!post_categories)
        return null;

    const defaultLang = Utils.default_lang();

    const items = [];
    if (active && post_categories.length > 1)
        items.push({
            key: 'null',
            title: t('articles.show_all_categories'),
            icon: 'home',
            depth: 0
        });

    let parents    = [];
    let base       = active;
    let depth      = 0;
    let parent_id  = null;
    while(parent_id = post_categories.find(c => c.id === (base || {}).parent_id)) {
        if (parent_id)
            parents.unshift(parent_id);
        base   = parent_id;
        depth += 1;
    }
    parents.map((c, i) => {
        items.push({
            key: c.id,
            title: c.name[defaultLang],
            icon: 'folder-o',
            depth: i,
            selection: c
        });
    });

    if (active) {
        items.push({
            key: active.id,
            title: active.name[defaultLang],
            icon: 'folder-o',
            active: true,
            depth: depth,
            selection: active
        });
        depth++;
    }

    _.sortBy(
        post_categories.filter(c => {
            if (!active)
                return !c.parent_id;

            return c.parent_id === active.id;
        }),
        c => c.name[defaultLang]
    ).map(c => {
        items.push({
            key: c.id,
            title: c.name[defaultLang],
            icon: 'folder-o',
            active: (active && (active.id === c.id)),
            depth: depth,
            selection: c
        });
    });

    return (
        <div className="PostcategoriesBrowser">
            <ItemsList
                title={ t('articles.filter_by_category') }
                items={ items }
                onSelect={ onSelect }
            />
        </div>
    );
}

export default PostcategoriesBrowser;
