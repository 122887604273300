import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button, Input } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import matchSorter from 'match-sorter';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css'

import { loadSections } from '../../../redux/modules/sections';

import './SectionsList.css';
import Table from '../../../components/Table/Table';
import TableButtons from '../../../components/TableButtons';
import CreateButton from '../../../components/CreateButton';

class SectionsList extends Component<{}> {
    componentDidMount() {
        // Load sections on component mount
        this.props.dispatchLoadSections();
    }

    render() {
        const { t }                                              = this.props;
        const { sections, hasLoadingError, loadingErrorMessage } = this.props.sections;
        const default_lang = Utils.default_lang();

        const textFilter = ({ filter, onChange }) =>
            <Input
                type="text"
                onChange={event => onChange(event.target.value)}
            />;
        const textFilterMethod = (key) => (filter, rows) => matchSorter(rows, filter.value, { keys: [key] });

        const columns = [{
            Header: t("sections.name"),
            accessor: "name",
            filterMethod: textFilterMethod(`name.${default_lang}`),
            Filter: textFilter,
            filterAll: true,
            Cell: row => row.value && (
                <div className="name_cell">
                    <Link to={`/sections/edit/${row.original.id}`}>
                        {row.original.name[default_lang]}
                    </Link>
                </div>
            )
        }, {
            Header: t("common.actions"),
            accessor: "id",
            maxWidth: 110,
            filterable: false,
            Cell: row => (
                <TableButtons
                    id={row.value}
                    actions={["edit", "delete", "show"]}
                    module={`sections`}
                    owner={row.original.created_by}
                />
            )
        }];

        const actions =
            <CreateButton
                module={`sections`}
                text="sections.add"
            />

        const title =
        <span>{t("sections.section_plural")}</span>

        return (
            <div className="SectionsList">
                <PageTitle icon="indent" title={title} actions={ actions } />
                <Table  id="sectionsTable" data={ sections } columns={ columns } />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadSections: () => loadSections()
}

const mapStateToProps = state => ({
    sections: state.sections
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(SectionsList))
