import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { Utils } from '../../../services/';
import { loadProgrammation } from '../../../redux/modules/programmations';

class ProgrammationShow extends Component<{}> {
    componentDidMount() {
        const programmationId = this.props.match.params.programmationId;
        // Load programmations on component mount
        this.props.dispatchLoadProgrammation(programmationId);
    }

    render() {
        const { programmation } = this.props.programmations;

        const { t } = this.props;

        let title = <span>{t("programmations.programmation_plural")}</span>;
        if (programmation)
            title = (
                <span>
                    {t("programmations.programmation")}
                    <small> | { programmation.name } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/programmations" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {programmation &&
                    <Button tag={Link} to={ `/programmations/edit/${ programmation.id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="ProgrammationShow">
                <PageTitle icon="th" title={ title } actions={ actions } />
                {programmation &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-programmation"></i>&nbsp;{t("programmations.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("programmations.name")}</b>: { programmation.name }</div>
                                            <div><b>{t("programmations.description")}</b>: { programmation.description }</div>
                                            <div><b>{t("programmations.capacity")}</b>: { programmation.capacity }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadProgrammation: (programmationId) => loadProgrammation(programmationId)
}

const mapStateToProps = state => ({
    programmations: state.programmations
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ProgrammationShow))
