// Redux Tickettypes module
// see https://github.com/erikras/ducks-modular-redux

import { Customers } from '../../services/';
import _ from 'lodash';


const LOADING_CUSTOMERS                  = 'kronos/customers/LOADING_CUSTOMERS';
const LOADING_CUSTOMERS_SUCCESS          = 'kronos/customers/LOADING_CUSTOMERS_SUCCESS';
const LOADING_CUSTOMERS_FAILURE          = 'kronos/customers/LOADING_CUSTOMERS_FAILURE';
const LOADING_CUSTOMER                   = 'kronos/customers/LOADING_CUSTOMER';
const LOADING_CUSTOMER_SUCCESS           = 'kronos/customers/LOADING_CUSTOMER_SUCCESS';
const LOADING_CUSTOMER_FAILURE           = 'kronos/customers/LOADING_CUSTOMER_FAILURE';
const LOADING_INACTIVE_CUSTOMERS         = 'kronos/customers/LOADING_INACTIVE_CUSTOMERS';
const LOADING_INACTIVE_CUSTOMERS_SUCCESS = 'kronos/customers/LOADING_INACTIVE_CUSTOMERS_SUCCESS';
const LOADING_INACTIVE_CUSTOMERS_FAILURE = 'kronos/customers/LOADING_INACTIVE_CUSTOMERS_FAILURE';
const UPDATING_CUSTOMER                  = 'kronos/customers/UPDATING_CUSTOMER';
const UPDATING_CUSTOMER_SUCCESS          = 'kronos/customers/UPDATING_CUSTOMER_SUCCESS';
const UPDATING_CUSTOMER_FAILURE          = 'kronos/customers/UPDATING_CUSTOMER_FAILURE';
const START_CREATING_CUSTOMER            = 'kronos/customers/START_CREATING_CUSTOMER';
const CREATING_CUSTOMER                  = 'kronos/customers/CREATING_CUSTOMER';
const CREATING_CUSTOMER_SUCCESS          = 'kronos/customers/CREATING_CUSTOMER_SUCCESS';
const CREATING_CUSTOMER_FAILURE          = 'kronos/customers/CREATING_CUSTOMER_FAILURE';
const DELETING_CUSTOMER                  = 'kronos/customers/DELETING_CUSTOMER';
const DELETING_CUSTOMER_SUCCESS          = 'kronos/customers/DELETING_CUSTOMER_SUCCESS';
const DELETING_CUSTOMER_FAILURE          = 'kronos/customers/DELETING_CUSTOMER_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    customers: [],
    customer: {},
    customerSuccessfullyCreated: false,
    customerSuccessfullyUpdated: false,

}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_CUSTOMERS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                customers: action.customers,
            };
        case LOADING_CUSTOMERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
            };
        case LOADING_CUSTOMER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                customer: action.customer
            };
        case LOADING_CUSTOMER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_INACTIVE_CUSTOMERS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_INACTIVE_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                inactive_customers: action.customers
            };
        case LOADING_INACTIVE_CUSTOMERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_CUSTOMER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                customerSuccessfullyUpdated: false,
            };
        case UPDATING_CUSTOMER_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                customer: action.customer,
                customerSuccessfullyUpdated: true,
            };
        case UPDATING_CUSTOMER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
                customerSuccessfullyUpdated: false
            };
        case CREATING_CUSTOMER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_CUSTOMER_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                customerSuccessfullyCreated: true,
                                customer: {
                    ...action.customer,
                    "roles": "customer"
                }
            };
        case CREATING_CUSTOMER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                customerSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_CUSTOMER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_CUSTOMER_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                customerSuccessfullyDeleted: true,
                customer: action.customer
            };
        case DELETING_CUSTOMER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                customerSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingCustomers() { return { type: LOADING_CUSTOMERS } }
function loadingCustomersSuccess(customers) { return { type: LOADING_CUSTOMERS_SUCCESS, customers: customers } }
function loadingCustomersFailure(err) { return { type: LOADING_CUSTOMERS_FAILURE, error: err } }
export function loadCustomers() {
    return (dispatch) => {
        dispatch(loadingCustomers());
        Customers.list()
            .then(data => {
                const customers = data.customers;
                dispatch(loadingCustomersSuccess(customers));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingCustomersFailure(err))
            });
    }
}

function loadingCustomer() { return { type: LOADING_CUSTOMER } }
function loadingCustomerSuccess(customer) { return { type: LOADING_CUSTOMER_SUCCESS, customer: customer } }
function loadingCustomerFailure(err) { return { type: LOADING_CUSTOMER_FAILURE, error: err } }
export function loadCustomer(customerId) {
    return (dispatch) => {
        dispatch(loadingCustomer());
        Customers.get(customerId)
            .then(data => {
                const customer = data.customer;
                dispatch(loadingCustomerSuccess(customer));
            })
            .catch(err => {
                dispatch(loadingCustomerFailure(err))
            });
    }
}

function loadingInactiveCustomers() { return { type: LOADING_INACTIVE_CUSTOMERS } }
function loadingInactiveCustomersSuccess(customers) { return { type: LOADING_INACTIVE_CUSTOMERS_SUCCESS, customers: customers } }
function loadingInactiveCustomersFailure(err) { return { type: LOADING_INACTIVE_CUSTOMERS_FAILURE, error: err } }
export function loadInactiveCustomers() {
    return (dispatch) => {
        dispatch(loadingInactiveCustomers());
        Customers.inactive()
            .then(data => {
                const customers = data.customers;
                dispatch(loadingInactiveCustomersSuccess(customers));
            })
            .catch(err => {
                dispatch(loadingInactiveCustomersFailure(err))
            });
    }
}

function updatingCustomer() { return { type: UPDATING_CUSTOMER } }
function updatingCustomerSuccess(customer) { return { type: UPDATING_CUSTOMER_SUCCESS, customer: customer } }
function updatingCustomerFailure(err) { return { type: UPDATING_CUSTOMER_FAILURE, error: err } }
export function updateCustomer(customer) {
    return (dispatch) => {
        dispatch(updatingCustomer());
        Customers.update(customer)
            .then(data => {
                const user = data.customer;
                dispatch(updatingCustomerSuccess(customer));
            })
            .catch(err => {
                dispatch(updatingCustomerFailure(err))
            });
    }
}

function creatingCustomer() { return { type: CREATING_CUSTOMER } }
function creatingCustomerSuccess(customer) { return { type: CREATING_CUSTOMER_SUCCESS, customer: customer } }
function creatingCustomerFailure(err) { return { type: CREATING_CUSTOMER_FAILURE, error: err } }
export function startCreateCustomer() { return { type: START_CREATING_CUSTOMER } }
export function createCustomer(customer) {
    return (dispatch) => {
        dispatch(creatingCustomer());
        Customers.create(customer)
            .then(data => {
                const customer = data.customer;
                dispatch(creatingCustomerSuccess(customer));
                dispatch(loadingCustomerSuccess(customer));
            })
            .catch(err => {
                dispatch(creatingCustomerFailure(err))
            });
    }
}

function deletingCustomer() { return { type: DELETING_CUSTOMER } }
function deletingCustomerSuccess() { return { type: DELETING_CUSTOMER_SUCCESS } }
function deletingCustomerFailure(err) { return { type: DELETING_CUSTOMER_FAILURE, error: err } }
export function deleteCustomer(customerId) {
    return (dispatch) => {
        dispatch(deletingCustomer());
        Customers.delete(customerId)
            .then(data => {
                dispatch(deletingCustomerSuccess());
            })
            .catch(err => {
                dispatch(deletingCustomerFailure(err))
            });
    }
}


