import BaseModel from './BaseModel';
import Pricing from './Pricing';
import Window from './Window';
import _ from 'lodash';
import { v4 } from 'uuid';

/** Class representing an tickettype. */
class Tickettype extends BaseModel {

    static RENEW_TYPE_NOW           = 'now';
    static RENEW_TYPE_EXP           = 'expiration';
    static RENEW_TYPE_START_OF_YEAR = 'start-of-year';
    static RENEW_TYPE_SOONEST       = 'soonest';
    static RENEW_TYPE_LATEST        = 'latest';

    static renewTypes = () => [
        Tickettype.RENEW_TYPE_NOW,
        Tickettype.RENEW_TYPE_EXP,
        Tickettype.RENEW_TYPE_START_OF_YEAR,
        Tickettype.RENEW_TYPE_SOONEST,
        Tickettype.RENEW_TYPE_LATEST
    ];

    constructor(properties) {
        super(properties);

        this.pricings = this.pricings || {};
        _.mapKeys(this.pricings, (pricing, key) => {
            this.pricings[key] = new Pricing(pricing);
        });

        if (!this.opaque.eshop_sort_weight)
            this.opaque.eshop_sort_weight = 0;
        Object.keys(this?.opaque?.layouts?.overrides||{}).map(o => {
            if (Array.isArray(this?.opaque?.layouts?.overrides[o]))
                this.opaque.layouts.overrides[o] = {}
        })

        this.windows = (this.windows || []).map(w => new Window(w));
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = super.prepareForUpdate();
        _.mapKeys(this.pricings, (pricing, key) => {
            prepared.pricings[key] = pricing.prepareForUpdate();
        });

        if (!('_id' in prepared))
            prepared._id = v4();

        prepared.windows = (prepared.windows || []).map(window => {
            return window.prepareForUpdate();
        });

        if (prepared?.opaque?.layouts?.overrides) {
            Object.keys(prepared?.opaque?.layouts?.overrides).map(o => {
                Object.keys(prepared?.opaque?.layouts?.overrides[o]).map(l => {
                    if (prepared?.opaque?.layouts?.overrides[o][l] === "")
                        delete prepared?.opaque?.layouts?.overrides[o][l]
                })
            })
        }

        return prepared;
    }

    /**
    * Prepare this object for duplicate.
    * This is used to "normalize", if needed, some properties
    * before to send them.
    *
    * @param {Object} optional default properties
    * 
    * return{BaseModel}
    */
    prepareForDuplicate(options) {
        const prepared = _.cloneDeep(this);
        prepared._id = options._id;
        prepared.name = options.name;

        return prepared;
    }
}

export default Tickettype;
