import RestClient from './RestClient';
import { PdfLayout } from '../../models';
import _ from 'lodash';

class PdfLayouts extends RestClient {
    constructor() {
        super({
            "model": PdfLayout,
            "entryPoint": "layout",
            "resource": "layout",
            "resources": "layouts",
            "id_field": "_id",
        });
    }

    // TODO: rename to list when all Ticketack instances are up to date
    ___list(params) {
        // remove empty filter values
        params = _.pickBy(params, _.identity) || {};
        params.layout_type = 'pdf';
        return super.list(params);
    }
}

const PdfLayoutsApi = new PdfLayouts();

export default PdfLayoutsApi;
