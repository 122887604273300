import BaseModel from './BaseModel';
import { User, Activity, FormLayout } from '../models';

/** Class representing a sector. */
class ApplicationType extends BaseModel {

    constructor(properties) {
        super(properties);

        if (this.form)
            this.form = new FormLayout(this.form);

        if (this.admins) {
            this.admins = (this.admins || []).map(props => new User(props));
            this.admin_ids = this.admin_ids || this.admins.map(user => user.id);
        }

        if (this.activities) {
            this.activities     = (this.activities || []).map(props => new Activity(props));
            this.activities_ids = this.activities_ids || this.activities.map(activity => activity.id);
        }
    }
}

export default ApplicationType;
