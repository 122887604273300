import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import { swUpdate } from '../../redux';

import { useTranslation } from 'react-i18next';

import "./SwUpdateModal.css"

const SwUpdateModal = () => {
    const { hasUpdate }   = useSelector(state => state.worker);
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(hasUpdate);
    }, [hasUpdate]);

    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const doUpdate = () => {
        dispatch(swUpdate());
    };

    return (
        <Modal
            basic
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='small'
            className="SwUpdateModal"
        >
            <Header icon>
                <Icon name='refresh' />
                { t('app.worker.an_update_is_available') }
            </Header>
            <Modal.Content>
                <p>
                { t('app.worker.click_here_to_update') }
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' inverted onClick={() => doUpdate()}>
                    <Icon name='checkmark' />&nbsp;
                    { t('app.worker.do_update') }
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SwUpdateModal;
