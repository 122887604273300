import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Badge, ButtonGroup, Button, Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from "react-router-dom";
import { Article } from '../../../../models/';
import { Utils, Filters } from '../../../../services/';
import { updateArticle } from '../../../../redux/modules/articles';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveMutable } from 'array-move';
import _ from 'lodash';
import TableButtons from '../../../../components/TableButtons';
import 'react-table-6/react-table.css'
import Table from '../../../../components/Table/Table';

import './ArticlesTable.css';

const ArticlesTable = (props) => {
    const { salepoints, articles } = props;

    const [sortedArticles, setSortedArticles] = useState([]);
    const [mode, setMode] = useState('list');

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const default_lang = Utils.default_lang();

    useEffect(() => {
        setSortedArticles(_.sortBy(articles, 'sort_weight'));
    }, [articles]);

    const buildPreview = articles => {
        const DragHandle  = SortableHandle(() => <i className="drag-handle fa fa-bars" />);
        const SortableTab = SortableElement(({ article, sortIndex }) => (
            <Col sm={2} className="mt-3">
                <Link className="article" to={`/articles/edit/${article._id}`}>
                    <Card className="article-card">
                        <DragHandle />
                        <CardBody style={{
                            "backgroundImage": "url('" + (
                                article.posters && article.posters.length ?
                                    article.posters[0].url : ""
                            ) + "')",
                            "backgroundPosition": "center center",
                            "backgroundSize": "cover"
                        }}>
                            <div className="title-wrapper">
                                <CardTitle>
                                    <span className="pt-3">{article.name[default_lang]}</span>
                                    {article.tags && article.tags.map((t, i) => (
                                        <Badge key={i} color="primary" className="mr-1">{t.name}</Badge>
                                    ))}
                                </CardTitle>
                            </div>
                        </CardBody>
                    </Card>
                </Link>
            </Col>
        ));
        const SortableGrid = SortableContainer(({ articles }) => {
            return (
                <Row className="sortable-grid">
                    { (articles || []).map((article, index) => <SortableTab key={ `item-${ index }` } index={ index } article={ article } sortIndex={ index }/>)}
                </Row>
            );
        });

        const onSortEnd = ({oldIndex, newIndex}) => {
            const articles = [ ...sortedArticles ];
            arrayMoveMutable(articles, oldIndex, newIndex);
            setSortedArticles(articles);

            articles.forEach((props, index) => {
                // if the article didn't move
                if (props.sort_weight == index)
                    // do nada
                    return;

                // build and update a new article
                const article = new Article({
                    ...props,
                    sort_weight: index
                });
                dispatch(updateArticle(article));
            });
        };

        return <SortableGrid
            articles={ articles }
            axis="xy"
            onSortEnd={ onSortEnd }
            useDragHandle={ true }
            lockToContainerEdges={ true }
        />
    };

    const buildTable = articles => {
        articles && articles.forEach((a) => {
            a.pos_ids = [];
            a.pos = a.pos.map((p) => {
                a.pos_ids.push(p._id);

                const pos = salepoints.find((s) => s._id === p._id);
                p.name = pos ? pos.name : {};

                return p;
            });
        });

        const columns = [{
            Header: t("articles.name"),
            accessor: "name",
            filterMethod: Filters.textFilterMethod(`name.${default_lang}`),
            Filter: Filters.textFilter,
            filterAll: true,
            maxWidth: 300,
            Cell: row => (
                <div className="article_cell">
                    <Link to={`/articles/edit/${row.original._id}`}>
                        <Card>
                            <CardBody style={{
                                "backgroundImage": "url('" + (
                                    row.original.posters && row.original.posters.length ?
                                        row.original.posters[0].url : ""
                                ) + "')",
                                "backgroundPosition": "center center",
                                "backgroundSize": "cover"
                            }}>
                                <div className="title-wrapper">
                                    <CardTitle>
                                        <span className="pt-3">{row.value[default_lang]}</span>
                                        <small>{row.original.short_description[default_lang]}</small>
                                        {row.original.tags && row.original.tags.map((t, i) => (
                                            <Badge key={i} color="primary" className="mr-1">{t.name}</Badge>
                                        ))}
                                    </CardTitle>
                                </div>
                            </CardBody>
                        </Card>
                    </Link>
                </div>
            )
        }, {
            Header: t("articles.pos"),
            accessor: "pos_ids",
            filterMethod: Filters.textFilterMethod("pos_ids"),
            Filter: Filters.posFilter(salepoints),
            filterAll: true,
            Cell: row => {
                if (row.original.pos.length) {
                    return row.original.pos.filter(p => p?.name[default_lang]).map((p, i) => p.name[default_lang]).join(", ")
                }
            }
        }, {
            Header: t("common.actions"),
            accessor: "_id",
            maxWidth: 110,
            filterable: false,
            Cell: row => (
                <TableButtons
                    id={row.value}
                    actions={["show", "edit", "delete"]}
                    module="articles"
                />
            )
        }];

        return <Table id="articlesTable" data={articles} columns={columns} />
    }

    return (
        <div className="ArticlesTable">
            <div className="top-buttons-wrapper">
                <ButtonGroup>
                    <Button onClick={() => setMode('list')} color={mode === 'list' ? 'primary' : 'dark'} size="sm">
                        <i className="fa fa-bars"></i> {t("articles.list")}
                    </Button>
                    <Button onClick={() => setMode('order')} color={mode === 'order' ? 'primary' : 'dark'} size="sm">
                        <i className="fa fa-th"></i> {t("articles.order")}
                    </Button>
                </ButtonGroup>
            </div>

            { mode === 'list' ? buildTable(articles) : buildPreview(sortedArticles) }
        </div>
    );
}

export default ArticlesTable;
