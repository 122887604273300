import React from 'react';
import { Input } from 'reactstrap';

import './MoneyValue.css';

const MoneyValue = ({ title, value, className, ...rest}) => {
    return (
        <div className={ `MoneyValue ${className}` } { ...rest }>
            <div className="title">
                { title }
            </div>
            <div className="inputs">
                <Input type="text" size="3" value={ value } readOnly />
            </div>
        </div>
    );
}

export default MoneyValue;
