import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadPhase, deletePhase } from '../../../redux/modules/phases';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const PhaseDelete = () => {
    const { phaseId }  = useParams();
    const dispatch     = useDispatch();
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    const { phase, isLoading, phaseSuccessfullyDeleted } = useSelector(state => state.phases);

    useEffect(() => {
        dispatch(loadPhase(phaseId));
    }, []);

    const submit = (phaseId) => {
        dispatch(deletePhase(phaseId));
    };

    let title = <span>{t("bulidings.phase_plural")}</span>;
    if (phase)
        title = (
            <span>
                {phase.name[default_lang]}
                <small> |&nbsp;{t('phases.confirm_delete', { name: phase.name[default_lang] })}</small>
            </span>
        );

    const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/phases" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

    return (
        <div className="PhaseDelete">
            <PageTitle icon="arrows-h" title={title} actions={actions} />

            {phaseSuccessfullyDeleted &&
                <Redirect to="/phases" />
            }

            {phase &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{t('phases.confirm_delete')}</CardTitle>
                                <CardText tag="div">
                                    <div><b>{t("phases.name")}</b>: {phase.name[default_lang]}</div>
                                    <div><b>{t("phases.active_from")}</b>: {moment(phase.start_at).format('LLL')}</div>
                                    <div><b>{t("phases.active_until")}</b>: {moment(phase.stop_at).format('LLL')}</div>
                                    <Row>
                                        <Col className="text-center">
                                            <Button tag={Link} to="/phases" color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => submit(phase.id)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        {t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default PhaseDelete;
