import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services';

import { loadCashregister } from '../../../redux/modules/cashregisters';

class CashregisterShow extends Component<{}> {
    componentDidMount() {
        const cashregisterId = this.props.match.params.cashregisterId;
        // Load cashregisters on component mount
        this.props.dispatchLoadCashregister(cashregisterId);
    }

    render() {
        const { cashregister } = this.props.cashregisters;

        const { t } = this.props;

        const default_lang = Utils.default_lang();

        let title = <span>{t("cashregisters.cashregister_plural")}</span>;
        if (cashregister)
            title = (
                <span>
                    {t("cashregisters.cashregister")}
                    <small> | { cashregister.name[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/cashregisters" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {cashregister &&
                    <Button tag={Link} to={ `/cashregisters/edit/${ cashregister._id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="CashregisterShow">
                <PageTitle icon="th" title={ title } actions={ actions } />
                {cashregister &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-cashregister"></i>&nbsp;{t("cashregisters.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("cashregisters.name")}</b>: { cashregister.name[default_lang] }</div>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadCashregister: (cashregisterId) => loadCashregister(cashregisterId)
}

const mapStateToProps = state => ({
    cashregisters: state.cashregisters
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(CashregisterShow))
