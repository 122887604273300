import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadSector, deleteSector } from '../../../redux/modules/sectors';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const SectorDelete = () => {
    const { sectorId } = useParams();
    const dispatch     = useDispatch();
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    const { sector, isLoading, sectorSuccessfullyDeleted } = useSelector(state => state.sectors);

    useEffect(() => {
        dispatch(loadSector(sectorId));
    }, []);

    const submit = (sectorId) => {
        dispatch(deleteSector(sectorId));
    };

    let title = <span>{t("bulidings.sector_plural")}</span>;
    if (sector)
        title = (
            <span>
                <small> |&nbsp;{t('sectors.confirm_delete', { name: sector.name[default_lang] })}</small>
            </span>
        );

    const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/sectors" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

    return (
        <div className="SectorDelete">
            <PageTitle icon="location-arrow" title={title} actions={actions} />

            {sectorSuccessfullyDeleted &&
                <Redirect to="/sectors" />
            }

            {sector &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{t('sectors.confirm_delete')}</CardTitle>
                                <CardText tag="div">
                                <div><b>{t("sectors.name")}</b>: {sector.name[default_lang]}</div>
                                <div dangerouslySetInnerHTML= {{__html: sector.description[default_lang]}}></div>
                                <Row>
                                        <Col className="text-center">
                                            <Button tag={Link} to="/sectors" color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => submit(sector.id)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        {t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default SectorDelete;
