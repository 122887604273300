import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap';
import { isLoggedIn } from '../../services';
import { getLastConnectedUser } from '../../redux/modules/auth';
import { loadSetting } from '../../redux/modules/settings';
import MainContent from './MainContent/MainContent';
import Login from '../../containers/Login/Login';
import Public from '../../containers/Public/Public';
import {Help, SwUpdateModal, ChangeLog, Loader} from '../../components';
import Nav from './Nav/Nav';
import './App.css';
import i18n from "i18next";
import Utils from "../../services/Utils";
import overrideFR from "../../locales/override-fr.yml";
import overrideEN from "../../locales/override-en.yml";
import overrideDE from "../../locales/override-de.yml";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

const App = () => {
    const { pathname, hash }            = useLocation();
    const [loadingLang, setLoadingLang] = useState(false);
    const { user }                      = useSelector(state => state.auth);
    const { KUserSettings }             = useSelector(state => state.userSettings);
    const { setting, hasLoadingError }  = useSelector(state => state.settings);
    const dispatch                      = useDispatch();
    const { t }                         = useTranslation();

    const loadSpecificConfig = (customerType) => {
        if (customerType in overrideFR)
            i18n.addResourceBundle('fr', 'translations', Utils.dotify(overrideFR[customerType]), true, true);
        if (customerType in overrideEN)
            i18n.addResourceBundle('en', 'translations', Utils.dotify(overrideEN[customerType]), true, true);
        if (customerType in overrideDE)
            i18n.addResourceBundle('de', 'translations', Utils.dotify(overrideDE[customerType]), true, true);

        setLoadingLang(false);
    }

    useEffect(() => {
        dispatch(getLastConnectedUser());
    }, []);

    useEffect(() => {
        window.localStorage.setItem('KUserSettings', JSON.stringify(KUserSettings))
    }, [KUserSettings])

    useEffect(() => {
        if (user && user.id){
            setLoadingLang(true);
            dispatch(loadSetting('default'));
        }
    }, [user?.id]);

    useEffect(() => {
        if (setting && setting.customer) {
            const customerType = setting.customer.type;
            loadSpecificConfig(customerType)
        }
    }, [setting]);

    const theme = KUserSettings.darkTheme ? "dark" : "light";

    if (hasLoadingError && loadingLang) {
        setLoadingLang(false);
    }

    if (pathname.match('^\/public/.+'))
        return <Public />;

    if (isLoggedIn()) {
        return (
            <div className={`App ${theme}-app`}>
                <Container fluid>
                    {loadingLang ? (
                        <div className="container-fluid loader-container justify-content-center">
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <Nav />
                            {hasLoadingError &&  (
                                <div className="alert alert-danger">
                                    {t("app.errors_load_params")}
                                </div>
                            )}
                            <MainContent />
                        </>
                    )}
                </Container>
                <Help />
                <SwUpdateModal />
                <ChangeLog />
            </div>
        );
    }

    return (
        <>
            <Login />
            <Help />
        </>
    );
}

export default App;
