import store from '..';
import { Shifts } from '../../services';
import { createRestSlices, applyReducers } from './rest';

const {
    initialState,
    getReducer, listReducer, updateReducer, duplicateReducer,
    startCreateReducer, createReducer, deleteReducer,
    getAction, listAction, updateAction, duplicateAction,
    startCreateAction, createAction, deleteAction,
    linkedResourcesReducer, linkedResourcesAction
} = createRestSlices(Shifts);

/* Export reducer */
export default (state = initialState, action) => {
    return applyReducers(state, action, [
        getReducer, listReducer, updateReducer, duplicateReducer,
        startCreateReducer, createReducer, deleteReducer,
        linkedResourcesReducer
    ]);
}

/* Export CRUD actions */
export const resetShift               = startCreateAction;
export const startCreateShift         = startCreateAction;
export const createShift              = createAction;
export const loadShift                = getAction;
export const loadShifts               = listAction;
export const updateShift              = updateAction;
export const deleteShift              = deleteAction;
export const duplicateShift           = duplicateAction;
export const getShiftsLinkedResources = linkedResourcesAction;
