import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonGroup, Button } from 'reactstrap';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CustomerForm from '../CustomerForm/CustomerForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { loadCustomer, updateCustomer, } from '../../../redux';

const CustomerEdit = props => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { customerId } = useParams();
    useEffect(() => {
        if (customerId)
            dispatch(loadCustomer(customerId));
    }, [dispatch, customerId]);

    const submit = values => {
        dispatch(updateCustomer(values));
    }

    const { customer } = useSelector(state => state.customers);

    if (!customer)
        return null;

    const title = (
        <span>
            {t('customers.customer')}
            <small> | {t('customers.update_customer', {firstname: customer.contact?.firstname, lastname: customer.contact?.lastname})}</small>
        </span>
    );

    const actions = (
        <div>
            <ButtonGroup>
                <Button tag={Link} to={ `/customers/show/${ customer._id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;
                    {t('common.back')}
                </Button>
            </ButtonGroup>
        </div>
    );

    return (
        <div className="CustomerEdit">
            <PageTitle icon="users" title={ title } actions={ actions } />
            <CustomerForm onSubmit={ submit } />
        </div>
    );
}

export default CustomerEdit;
