import React from 'react';
import Device from './Device';

/** Class representing a host (pi). */
class Host extends Device {
    constructor(properties) {
        super({
            ...properties,
            type: 'host',
            // FIXME: This should be removed !!!
            status: 'connected'
        });

        this.connectedDevices = [];
    }

    connectDevice(device) {
        this.connectedDevices.push(device);
    }

    disconnectDevice(device) {
        this.connectedDevices = this.connectedDevices.filter(d => d._id !== device._id);
    }

    getConnectedDevices() {
        return this.connectedDevices;
    }
}

export default Host;
