// Redux Activities module
// see https://github.com/erikras/ducks-modular-redux

import { Activities } from '../../services';

const LOADING_ACTIVITIES         = 'kronos/activities/LOADING_ACTIVITIES';
const LOADING_ACTIVITIES_SUCCESS = 'kronos/activities/LOADING_ACTIVITIES_SUCCESS';
const LOADING_ACTIVITIES_FAILURE = 'kronos/activities/LOADING_ACTIVITIES_FAILURE';
const LOADING_ACTIVITY          = 'kronos/activities/LOADING_ACTIVITY';
const LOADING_ACTIVITY_SUCCESS  = 'kronos/activities/LOADING_ACTIVITY_SUCCESS';
const LOADING_ACTIVITY_FAILURE  = 'kronos/activities/LOADING_ACTIVITY_FAILURE';
const UPDATING_ACTIVITY         = 'kronos/activities/UPDATING_ACTIVITY';
const UPDATING_ACTIVITY_SUCCESS = 'kronos/activities/UPDATING_ACTIVITY_SUCCESS';
const UPDATING_ACTIVITY_FAILURE = 'kronos/activities/UPDATING_ACTIVITY_FAILURE';
const START_CREATING_ACTIVITY   = 'kronos/activities/START_CREATING_ACTIVITY';
const CREATING_ACTIVITY         = 'kronos/activities/CREATING_ACTIVITY';
const CREATING_ACTIVITY_SUCCESS = 'kronos/activities/CREATING_ACTIVITY_SUCCESS';
const CREATING_ACTIVITY_FAILURE = 'kronos/activities/CREATING_ACTIVITY_FAILURE';
const DELETING_ACTIVITY         = 'kronos/activities/DELETING_ACTIVITY';
const DELETING_ACTIVITY_SUCCESS = 'kronos/activities/DELETING_ACTIVITY_SUCCESS';
const DELETING_ACTIVITY_FAILURE = 'kronos/activities/DELETING_ACTIVITY_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    activitySuccessfullyCreated: false,
    activities: [],
    activity: null
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_ACTIVITIES:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_ACTIVITIES_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                activities: action.activities
            };
        case LOADING_ACTIVITIES_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_ACTIVITY:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_ACTIVITY_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                activity: action.activity
            };
        case LOADING_ACTIVITY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_ACTIVITY:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_ACTIVITY_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                activity: action.activity
            };
        case UPDATING_ACTIVITY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_ACTIVITY:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                activitySuccessfullyCreated: false,
                activity: {}
            };
        case CREATING_ACTIVITY:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_ACTIVITY_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                activitySuccessfullyCreated: true,
                activity: action.activity
            };
        case CREATING_ACTIVITY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                activitySuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_ACTIVITY:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_ACTIVITY_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                activitySuccessfullyDeleted: true,
                activity: action.activity
            };
        case DELETING_ACTIVITY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                activitySuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingActivities() { return { type: LOADING_ACTIVITIES } }
function loadingActivitiesSuccess(activities) { return { type: LOADING_ACTIVITIES_SUCCESS, activities: activities } }
function loadingActivitiesFailure(err) { return { type: LOADING_ACTIVITIES_FAILURE, error: err } }
export function loadActivities(activityname, password) {
    return (dispatch) => {
        dispatch(loadingActivities());
        Activities.list()
            .then(data => {
                const activities = data.activities;
                dispatch(loadingActivitiesSuccess(activities));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingActivitiesFailure(err))
            });
    }
}

function loadingActivity() { return { type: LOADING_ACTIVITY } }
function loadingActivitySuccess(activity) { return { type: LOADING_ACTIVITY_SUCCESS, activity: activity } }
function loadingActivityFailure(err) { return { type: LOADING_ACTIVITY_FAILURE, error: err } }
export function loadActivity(activityId) {
    return (dispatch) => {
        dispatch(loadingActivity());
        Activities.get(activityId)
            .then(data => {
                const activity = data.activity;
                dispatch(loadingActivitySuccess(activity));
            })
            .catch(err => {
                dispatch(loadingActivityFailure(err))
            });
    }
}

function updatingActivity() { return { type: UPDATING_ACTIVITY } }
function updatingActivitySuccess(activity) { return { type: UPDATING_ACTIVITY_SUCCESS, activity: activity } }
function updatingActivityFailure(err) { return { type: UPDATING_ACTIVITY_FAILURE, error: err } }
export function updateActivity(activity) {
    return (dispatch) => {
        dispatch(updatingActivity());
        Activities.update(activity)
            .then(data => {
                const activity = data.activity;
                dispatch(updatingActivitySuccess(activity));
            })
            .catch(err => {
                dispatch(updatingActivityFailure(err))
            });
    }
}

function creatingActivity() { return { type: CREATING_ACTIVITY } }
function creatingActivitySuccess(activity) { return { type: CREATING_ACTIVITY_SUCCESS, activity: activity } }
function creatingActivityFailure(err) { return { type: CREATING_ACTIVITY_FAILURE, error: err } }
export function startCreateActivity() { return { type: START_CREATING_ACTIVITY } }
export function createActivity(activity) {
    return (dispatch) => {
        dispatch(creatingActivity());
        Activities.create(activity)
            .then(data => {
                const activity = data.activity;
                dispatch(creatingActivitySuccess(activity));
                dispatch(loadingActivitySuccess(activity));
            })
            .catch(err => {
                dispatch(creatingActivityFailure(err))
            });
    }
}

function deletingActivity() { return { type: DELETING_ACTIVITY } }
function deletingActivitySuccess() { return { type: DELETING_ACTIVITY_SUCCESS } }
function deletingActivityFailure(err) { return { type: DELETING_ACTIVITY_FAILURE, error: err } }
export function deleteActivity(activityId) {
    return (dispatch) => {
        dispatch(deletingActivity());
        Activities.delete(activityId)
            .then(data => {
                dispatch(deletingActivitySuccess());
            })
            .catch(err => {
                dispatch(deletingActivityFailure(err))
            });
    }
}
