// Redux Stats module
// see https://github.com/erikras/ducks-modular-redux

import { Stats } from '../../services';
import moment from 'moment';

const LOADING_STATS         = 'kronos/stats/LOADING_STATS';
const LOADING_STATS_SUCCESS = 'kronos/stats/LOADING_STATS_SUCCESS';
const LOADING_STATS_FAILURE = 'kronos/stats/LOADING_STATS_FAILURE';
const LOADING_STAT          = 'kronos/stats/LOADING_STAT';
const LOADING_STAT_SUCCESS  = 'kronos/stats/LOADING_STAT_SUCCESS';
const LOADING_STAT_FAILURE  = 'kronos/stats/LOADING_STAT_FAILURE';
const SET_FILTERS           = 'kronos/stats/SET_FILTERS'
const RESET_STAT            = 'kronos/stats/RESET_STAT'

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    stats: {
        movies: null,    
        prices: null,    
        places: null,    
        days: null,      
        hours: null,     
        box_office: null,
        carts: null,
    },
    stat: null,
    filters: {
        start_date: moment().startOf('day'),
        end_date: moment().endOf('day'),
        cinema: null,
        room: null,
        movie: null
    }
}

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_STATS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_STATS_SUCCESS:
            const temp = {}
            Object.keys(state.stats).forEach(s => {
                if (s === action.statsType)
                    temp[s] = action.stats;
                else
                    temp[s] = null;
            });
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                stats: temp
            };
        case LOADING_STATS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_STAT:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_STAT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                stat: action.stat
            };
        case LOADING_STAT_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: action.filters
            };
        case RESET_STAT:
            return {
                ...state,
                stat: null
            }
        default:
            return state;
    }
};

// Actions
function loadingStats() { return { type: LOADING_STATS } }
function loadingStatsSuccess(stats, statsType) { return { type: LOADING_STATS_SUCCESS, stats, statsType } }
function loadingStatsFailure(err) { return { type: LOADING_STATS_FAILURE, error: err } }
export function loadStats(type, params) {
    return (dispatch) => {
        dispatch(loadingStats());
        Stats.get(type, params)
            .then(data => {
                dispatch(loadingStatsSuccess(data.stats, type));
            })
            .catch(err => {
                dispatch(loadingStatsFailure(err))
            });
    }
}


function loadingStat() { return { type: LOADING_STAT } }
function loadingStatSuccess(stat) { return { type: LOADING_STAT_SUCCESS, stat: stat } }
function loadingStatFailure(err) { return { type: LOADING_STAT_FAILURE, error: err } }
export function loadStat(type, params) {
    return (dispatch) => {
        dispatch(loadingStat());
        Stats.get(type, params)
            .then(data => {
                dispatch(loadingStatSuccess(data.stats));
            })
            .catch(err => {
                dispatch(loadingStatFailure(err))
            });
    }
}



function settingFilters(filters) { return { type: SET_FILTERS, filters } }
export function setFilters(filters) {
    return (dispatch) => {
        dispatch(settingFilters(filters));
    }
}

function resetingStat() { return { type: RESET_STAT } }
export function resetStat() {
    return (dispatch) => {
        dispatch(resetingStat())
    }
}


