// Redux Service Worker module
// see https://github.com/erikras/ducks-modular-redux

const SW_INIT       = 'kronos/sw/INIT';
const SW_HAS_UPDATE = 'kronos/sw/HAS_UPDATE';
const SW_DO_UPDATE  = 'kronos/sw/DO_UPDATE';

const initialState = {
    isInstalled: false,
    hasUpdate: false
}

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SW_INIT:
            return {
                ...state,
                isInstalled: true,
                hasUpdate: false
            };
        case SW_HAS_UPDATE:
            return {
                hasUpdate: true
            };
        case SW_DO_UPDATE:
            return {
                hasUpdate: false
            };
        default:
            return state;
    }
};

export function swInstalled(registration) {
    return (dispatch) => {
        console.log('[sw] successfully installed');
        dispatch({ type: SW_INIT, registration});
    }
}

export function swHasUpdate(registration) {
    return (dispatch) => {
        console.log('[sw] an update is available');
        dispatch({ type: SW_HAS_UPDATE, registration});
    }
}

export function swUpdate() {
    return (dispatch) => {
        console.log('[sw] updating app');
        caches.keys().then(function(cacheNames) {
            return Promise.all(
                cacheNames.map(function(cacheName) {
                    console.log('[sw] deleting cache', cacheName);
                    return caches.delete(cacheName);
                })
            ).then(() => {
                dispatch({ type: SW_DO_UPDATE});
                window.location.hash = 'changelog';
                window.location.reload();
            });
        })
    }
}
