import React from "react";
import FormLayout from '../FormLayout';
import TextField from './TextField';
import { Field } from 'redux-form';
import { Utils } from '../../services';

/** Class representing a PDF layout custom text field. */
class LongTextField extends TextField {

    constructor(properties) {
        super({
            ...(LongTextField.getDefaultOptions()),
            ...properties,
        });

        this.type = FormLayout.LONGTEXT;
    }

    /**
     * Get this field default options
     */
    static getDefaultOptions() {
        const properties = super.getDefaultOptions();
        Utils.langs().forEach(lang => { properties.label[lang] = "";  });

        properties.rows = 3;

        return properties;
    }

    /**
     * Return the list of options this field handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            FormLayout.OPTION_ROWS
        ]);
    }

    /**
     * Return this field preview input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getInput(props) {
        return (
            <Field
                component="textarea"
                type="text"
                name={this.id}
                required={!!this.required}
                className="form-control"
            />
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'comments-o';
    }
}

export default LongTextField;
