import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadPricinglist, deletePricinglist } from '../../../redux/modules/pricinglists';

class PricinglistDelete extends Component<{}> {
    componentDidMount() {
        const pricinglistId = this.props.match.params.pricinglistId;
        // Load pricinglists on component mount
        this.props.dispatchLoadPricinglist(pricinglistId);
    }

    deletePricinglist(pricinglistId) {
        this.props.dispatchDeletePricinglist(pricinglistId);
    }

    render() {
        const { pricinglist, isLoading, pricinglistSuccessfullyDeleted } = this.props.pricinglists;
        const { t } = this.props;

        let title = <span>{t("bulidings.pricinglist_plural")}</span>;
        if (pricinglist)
            title = (
                <span>
                    {t("pricinglists.pricinglist")}
                    <small> | { pricinglist.name } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/pricinglists" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="PricinglistDelete">
                <PageTitle icon="usd" title={ title } actions={ actions } />

                {pricinglistSuccessfullyDeleted &&
                    <Redirect to="/pricinglists"/>
                }

                {pricinglist &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('pricinglists.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("pricinglists.name")}</b>: { pricinglist.name }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/pricinglists" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deletePricinglist(pricinglist._id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadPricinglist: (pricinglistId) => loadPricinglist(pricinglistId),
    dispatchDeletePricinglist: (pricinglistId) => deletePricinglist(pricinglistId)
}

const mapStateToProps = state => ({
    pricinglists: state.pricinglists
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(PricinglistDelete))
