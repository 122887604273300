import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Button, Row, Col, Form } from 'reactstrap';
import './WidgetSharedSettings.css';

const WidgetSharedSettings = ({ width, bgColor }) => {
    const { t } = useTranslation();

    const [bgTransparent, setBgTransparent] = useState(bgColor === "rgb(0,0,0,0)" ? true : false);

    //Reset widget background color to default
    const resetBgColor = () => {
        document.getElementById("colorPickerWidgetSetting").value="#2a2a2a"
    }
    
    return (
        <div className='WidgetSharedSettings'>
            <Row className='text-center'>
                <h4 className='border-bottom pb-1 mb-2 w-100 ml-1'>{t("widgets.settings.options")}</h4>
            </Row>
            <Row>
                {/** Handle widget width (Bootstrap col) */}
                <Col>
                    <div className='settingsFormLabel'>
                        {t('widgets.settings.width')}
                    </div>
                </Col>
                <Col className='col-3'>
                    <div className='settingsFormInput'>
                        <Input
                            className='settingsInput'
                            name="widgetWidth"
                            type="select"
                            defaultValue={width}
                        >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </Input>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default WidgetSharedSettings;
