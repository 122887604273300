import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, Button, ButtonGroup, Card, CardBody, CardTitle, CardFooter } from 'reactstrap';
import { Field, submit } from 'redux-form'
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../../services';

import './EventPeople.css';

class EventPeople extends Component<{}> {
    remove(i) {
        const { fields, people, meta: { form }, dispatchSubmitForm } = this.props;

        fields.remove(i);
        people.splice(i, 1);
        dispatchSubmitForm(form);
    }

    render() {
        const { t, people } = this.props;

        const isLoading = false;
        return (
            <div className="EventPeople">
                <Row>
                    <Col>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Field component="select" className="form-control" type="text" name="new_people['activity']" id="new_people_activity">
                                        <option key="0" value="">---</option>
                                        {
                                            Utils.activities().map((a) => <option key={a} value={a}>{t("events.activity_" + a)}</option>)
                                        }
                                    </Field>
                                </Col>
                                <Col>
                                    <Field component="input" type="text" className="form-control" name="new_people['firstname']" id="new_people_firstname" placeholder={t("events.new_people_firstname_placeholder")}/>
                                </Col>
                                <Col>
                                    <Field component="input" type="text" className="form-control" name="new_people['lastname']" id="new_people['lastname']" placeholder={t("events.new_people_lastname_placeholder")}/>
                                </Col>
                                <Col>
                                    <Button type="submit" color="info">
                                        {isLoading ?
                                            <span> &nbsp;<i className="fa fa-spinner fa-spin"></i> </span>
                                            :
                                            <span> <i className="fa fa-plus"></i>&nbsp; {t("common.add")} </span>
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
                <Row>
                    {people.map((p, i) =>
                        <Col key={i} className="people-wrapper" xs="12" sm="12" md="4">
                            <Card className="people">
                                <CardTitle>
                                    <div className="text-center">
                                        <h5>{ t('events.activity_' + p.activity) }</h5>
                                    </div>
                                </CardTitle>
                                <CardBody>
                                    <div className="text-center">
                                        <h4>{ p.firstname } { p.lastname }</h4>
                                    </div>
                                </CardBody>
                                <CardFooter className="text-center">
                                    <ButtonGroup>
                                        <Button color="danger" size="sm" onClick={ () => { this.remove(i);  }}>
                                            <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                        </Button>
                                    </ButtonGroup>
                                    <Field component="input" type="hidden" className="form-control" name={`people[${i}].activity`} />
                                    <Field component="input" type="hidden" className="form-control" name={`people[${i}].firstname`} />
                                    <Field component="input" type="hidden" className="form-control" name={`people[${i}].lastname`} />
                                </CardFooter>
                            </Card>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchSubmitForm: (form) => submit(form)
}
const mapStateToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(EventPeople))
