import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadApplication, deleteApplication } from '../../../redux/modules/applications';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const ApplicationDelete = () => {
    const { applicationId } = useParams();
    const dispatch     = useDispatch();
    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    const { application, isLoading, applicationSuccessfullyDeleted } = useSelector(state => state.applications);

    useEffect(() => {
        dispatch(loadApplication(applicationId));
    }, []);

    const submit = (applicationId) => {
        dispatch(deleteApplication(applicationId));
    };

    let title = <span>{t("bulidings.application_plural")}</span>;
    if (application)
        title = (
            <span>
                <small> |&nbsp;{t('applications.confirm_delete', { name: application.user?.fullname })}</small>
            </span>
        );

    const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/applications" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

    return (
        <div className="ApplicationDelete">
            <PageTitle icon="address-book-o" title={title} actions={actions} />

            {applicationSuccessfullyDeleted &&
                <Redirect to="/applications" />
            }

            {application &&
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-warning"></i>&nbsp;{t('applications.confirm_delete')}</CardTitle>
                                <CardText tag="div">
                                <div><b>{t("applications.fullname")}</b>: {application.user?.fullname}</div>
                                <div><b>{t("applications.status")}</b>: { t(`applications.status_${application.status}`) }</div>
                                <Row>
                                        <Col className="text-center">
                                            <Button tag={Link} to="/applications" color="warning">
                                                <i className="fa fa-arrow-left"></i>&nbsp;{t('common.cancel')}
                                            </Button>&nbsp;
                                            <Button color="danger" onClick={(e) => submit(application.id)} disabled={isLoading}>
                                                {isLoading ?
                                                    <span>
                                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                    </span>
                                                    :
                                                    <span>
                                                        <i className="fa fa-trash"></i>&nbsp;
                                                        {t('common.delete')}
                                                    </span>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    );
}

export default ApplicationDelete;
