import RestClient from './RestClient';
import { Resource } from '../../models';

class Resources extends RestClient {
    constructor() {
        super({
            "model": Resource,
            "entryPoint": "resource",
            "resource": "resource",
            "resources": "resources"
        });
    }
}

const ResourcesApi = new Resources();

export default ResourcesApi;
