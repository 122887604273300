import React from 'react';
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';
import PricinglistsList from './PricinglistsList/PricinglistsList';
import PricinglistEdit from './PricinglistEdit/PricinglistEdit';
import PricinglistCreate from './PricinglistCreate/PricinglistCreate';
import PricinglistDelete from './PricinglistDelete/PricinglistDelete';

const Pricinglists = ({ match }) => {
    const { isLoading, hasLoadingError, loadingErrorMessage } = useSelector(state => state.pricinglists);

    return (
        <div className="Pricinglists">

            {isLoading &&
                <span className="loadingMessage"><i className="fa fa-spinner fa-spin"></i>&nbsp;Chargement en cours...</span>
            }
            {hasLoadingError &&
                <div
                    className="alert alert-danger"
                    dangerouslySetInnerHTML={{__html: loadingErrorMessage}} />
            }
            <Route exact path={ match.path } component={PricinglistsList} />
            <Route exact path={ `${ match.url }/new` } component={PricinglistCreate} />
            <Route exact path={ `${ match.url }/edit/:pricinglistId` } component={PricinglistEdit} />
            <Route exact path={ `${ match.url }/delete/:pricinglistId` } component={PricinglistDelete} />
        </div>
    );
}

export default Pricinglists;
