import React from 'react';
import { useTranslation } from 'react-i18next';
import GaugeChart from 'react-gauge-chart';
import _ from 'lodash';

import './CollectorSituationResume.css';

const CollectorSituationResume = ({ collector, className, ...rest }) => {
    const { t }     = useTranslation();
    const situation = collector.state.lastSituation

    if (!situation)
        return null;

    const _className = (module) => {
        return module.stock <= module.minAlert ? 'danger' : (
            module.stock >= module.maxAlert ? 'warning' : 'ok'
        )
    }

    const recycler_total = parseFloat(
        situation.modules.withdrawal.coins.reduce((memo, coin) => memo + (parseFloat(coin.value) * coin.stock), 0) +
        situation.modules.withdrawal.bills.reduce((memo, bill) => memo + (parseFloat(bill.value) * bill.stock), 0)
    );

    const recycler_gauges = _.uniqBy(_.concat(
        situation.modules.withdrawal.coins.filter(c => parseInt(c.state) === 1).reverse(),
        situation.modules.withdrawal.bills.filter(b => parseInt(b.state) === 1).reverse()
    ).map(coin => ({
        id: coin.id,
        className: _className(coin),
        title: t(`collectors.moneyTypes.${parseFloat(coin.value).toFixed(2)}`),
        values: coin.stock + ' / ' + coin.max,
    })), 'id');

    return (
        <div className={ `CollectorSituationResume ${className}` } { ...rest }>
            <div className="gauges recycler_gauges">
                <div className="gauge gauges-title">
                    <div>
                        { recycler_total.toFixed(2) } CHF
                    </div>
                </div>
                { recycler_gauges && recycler_gauges.map(gauge => (
                    <div key={ gauge.id } className={ `gauge ${gauge.className}` }>
                        <div className="gauge-inner">
                            <div className="gauge-title">
                                { gauge.title }
                            </div>
                            <div className="values">
                                { gauge.values }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CollectorSituationResume;
