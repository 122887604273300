// Redux Cashregisters module
// see https://github.com/erikras/ducks-modular-redux

import { Cashregisters } from '../../services/';


const LOADING_CASHREGISTERS         = 'kronos/cashregisters/LOADING_CASHREGISTERS';
const LOADING_CASHREGISTERS_SUCCESS = 'kronos/cashregisters/LOADING_CASHREGISTERS_SUCCESS';
const LOADING_CASHREGISTERS_FAILURE = 'kronos/cashregisters/LOADING_CASHREGISTERS_FAILURE';
const LOADING_CASHREGISTER          = 'kronos/cashregisters/LOADING_CASHREGISTER';
const LOADING_CASHREGISTER_SUCCESS  = 'kronos/cashregisters/LOADING_CASHREGISTER_SUCCESS';
const LOADING_CASHREGISTER_FAILURE  = 'kronos/cashregisters/LOADING_CASHREGISTER_FAILURE';
const UPDATING_CASHREGISTER         = 'kronos/cashregisters/UPDATING_CASHREGISTER';
const UPDATING_CASHREGISTER_SUCCESS = 'kronos/cashregisters/UPDATING_CASHREGISTER_SUCCESS';
const UPDATING_CASHREGISTER_FAILURE = 'kronos/cashregisters/UPDATING_CASHREGISTER_FAILURE';
const START_CREATING_CASHREGISTER   = 'kronos/cashregisters/START_CREATING_CASHREGISTER';
const CREATING_CASHREGISTER         = 'kronos/cashregisters/CREATING_CASHREGISTER';
const CREATING_CASHREGISTER_SUCCESS = 'kronos/cashregisters/CREATING_CASHREGISTER_SUCCESS';
const CREATING_CASHREGISTER_FAILURE = 'kronos/cashregisters/CREATING_CASHREGISTER_FAILURE';
const DELETING_CASHREGISTER         = 'kronos/cashregisters/DELETING_CASHREGISTER';
const DELETING_CASHREGISTER_SUCCESS = 'kronos/cashregisters/DELETING_CASHREGISTER_SUCCESS';
const DELETING_CASHREGISTER_FAILURE = 'kronos/cashregisters/DELETING_CASHREGISTER_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    cashregisterSuccessfullyCreated: false,
    cashregisters: []
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_CASHREGISTERS:
            return {
                ...state,
                isLoading: true,
                cashregisterSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_CASHREGISTERS_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                cashregisters: action.cashregisters
            };
        case LOADING_CASHREGISTERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_CASHREGISTER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_CASHREGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                cashregister: action.cashregister
            };
        case LOADING_CASHREGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_CASHREGISTER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_CASHREGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                cashregister: action.cashregister
            };
        case UPDATING_CASHREGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_CASHREGISTER:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                cashregisterSuccessfullyCreated: false,
                cashregister: {}
            };
        case CREATING_CASHREGISTER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_CASHREGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                cashregisterSuccessfullyCreated: true,
                cashregister: action.cashregister
            };
        case CREATING_CASHREGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                cashregisterSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_CASHREGISTER:
            return {
                ...state,
                isLoading: true,
                cashregisterSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case DELETING_CASHREGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                cashregisterSuccessfullyDeleted: true,
                cashregister: action.cashregister
            };
        case DELETING_CASHREGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                cashregisterSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingCashregisters() { return { type: LOADING_CASHREGISTERS } }
function loadingCashregistersSuccess(cashregisters) { return { type: LOADING_CASHREGISTERS_SUCCESS, cashregisters: cashregisters } }
function loadingCashregistersFailure(err) { return { type: LOADING_CASHREGISTERS_FAILURE, error: err } }
export function loadCashregisters() {
    return (dispatch) => {
        dispatch(loadingCashregisters());
        Cashregisters.list()
            .then(data => {
                const cashregisters = data.cashregisters;
                dispatch(loadingCashregistersSuccess(cashregisters));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingCashregistersFailure(err))
            });
    }
}

function loadingCashregister() { return { type: LOADING_CASHREGISTER } }
function loadingCashregisterSuccess(cashregister) { return { type: LOADING_CASHREGISTER_SUCCESS, cashregister: cashregister } }
function loadingCashregisterFailure(err) { return { type: LOADING_CASHREGISTER_FAILURE, error: err } }
export function loadCashregister(cashregisterId) {
    return (dispatch) => {
        dispatch(loadingCashregister());
        Cashregisters.get(cashregisterId)
            .then(data => {
                const cashregister = data.cashregister;
                dispatch(loadingCashregisterSuccess(cashregister));
            })
            .catch(err => {
                dispatch(loadingCashregisterFailure(err))
            });
    }
}

function updatingCashregister() { return { type: UPDATING_CASHREGISTER } }
function updatingCashregisterSuccess(cashregister) { return { type: UPDATING_CASHREGISTER_SUCCESS, cashregister: cashregister } }
function updatingCashregisterFailure(err) { return { type: UPDATING_CASHREGISTER_FAILURE, error: err } }
export function updateCashregister(cashregister) {
    return (dispatch) => {
        dispatch(updatingCashregister());
        Cashregisters.update(cashregister)
            .then(data => {
                const cashregister = data.cashregister;
                dispatch(updatingCashregisterSuccess(cashregister));
            })
            .catch(err => {
                dispatch(updatingCashregisterFailure(err))
            });
    }
}

function creatingCashregister() { return { type: CREATING_CASHREGISTER } }
function creatingCashregisterSuccess(cashregister) { return { type: CREATING_CASHREGISTER_SUCCESS, cashregister: cashregister } }
function creatingCashregisterFailure(err) { return { type: CREATING_CASHREGISTER_FAILURE, error: err } }
export function startCreateCashregister() { return { type: START_CREATING_CASHREGISTER } }
export function createCashregister(cashregister) {
    return (dispatch) => {
        dispatch(creatingCashregister());
        Cashregisters.create(cashregister)
            .then(data => {
                const cashregister = data.cashregister;
                dispatch(creatingCashregisterSuccess(cashregister));
            })
            .catch(err => {
                dispatch(creatingCashregisterFailure(err))
            });
    }
}

function deletingCashregister() { return { type: DELETING_CASHREGISTER } }
function deletingCashregisterSuccess() { return { type: DELETING_CASHREGISTER_SUCCESS } }
function deletingCashregisterFailure(err) { return { type: DELETING_CASHREGISTER_FAILURE, error: err } }
export function deleteCashregister(cashregisterId) {
    return (dispatch) => {
        dispatch(deletingCashregister());
        Cashregisters.delete(cashregisterId)
            .then(data => {
                dispatch(deletingCashregisterSuccess());
            })
            .catch(err => {
                dispatch(deletingCashregisterFailure(err))
            });
    }
}
