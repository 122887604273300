import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText, Form, FormGroup, Label } from 'reactstrap';
import { useParams } from "react-router-dom";
import _ from 'lodash';
import 'react-table-6/react-table.css'
import { loadTktUser } from '../../../redux/modules/tktusers';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import TktUsersRolesField from '../../../components/TktUsersRolesField';
import SalepointsField from '../../../components/SalepointsField/SalepointsField';

let TicketackUserForm = ({ handleSubmit, pristine, submitting, isCreation }) => {
    const { t }          = useTranslation();
    const dispatch       = useDispatch();
    const { userId }     = useParams();
    const { isLoading }  = useSelector(state => state.tktusers);
    const { salepoints } = useSelector(state => state.salepoints);

    useEffect(() => {
        if (!isCreation && userId)
            dispatch(loadTktUser(userId));
        dispatch(loadSalepoints());
    }, []);

    return (
        <Form onSubmit={handleSubmit} className="TicketackUserForm">
            <Row className="main">
                <Col className='col-12'>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <i className="fa fa-user-circle"></i>&nbsp;
                                {t('users.profile')}
                            </CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t('tktUsers.name')}</Label>
                                            <Col sm={9}>
                                                <Field required component="input" className="form-control" type="text" name="name" id="name" placeholder={t('places.name_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                        {!isCreation &&
                                        <Row>
                                            <Col sm={3}></Col>
                                            <Label sm={9}>{t('tktUsers.change_password')}</Label>
                                        </Row>
                                        }
                                        <FormGroup row>
                                            <Label for="password" sm={3}>{t('users.password')}</Label>
                                            <Col sm={9}>
                                                <Field required={ isCreation } component="input" className="form-control" type="password" name="password" id="password" autoComplete="new-password" placeholder={t('users.password_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="password-confirmation" sm={3}>{t('users.password_confirmation')}</Label>
                                            <Col sm={9}>
                                                <Field required={ isCreation } component="input" className="form-control" type="password" name="password-confirmation" id="password-confirmation" autoComplete="new-password" placeholder={t('users.password_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="address" sm={3}>{t('user_data.address')}</Label>
                                            <Col sm={9}>
                                                <Field component="input" className="form-control" type="text" name="contact.opaque.address" id="contact.opaque.address" placeholder={t('users.address_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="mail" sm={3}>{t('user_data.email')}</Label>
                                            <Col sm={9}>
                                                <Field component="input" className="form-control" type="text" name="contact.opaque.mail" id="contact.opaque.mail" placeholder={t('users.email_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="phone" sm={3}>{t('user_data.phone')}</Label>
                                            <Col sm={9}>
                                                <Field component="input" className="form-control" type="text" name="contact.opaque.phone" id="contact.opaque.phone" placeholder={t('users.phone_placeholder')} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col className='col-md-6 col-sm-12 col-xs-12'>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <i className="fa fa-tags"></i>&nbsp;
                                {t('tktUsers.role_plural')}
                            </CardTitle>
                            <CardText tag="div">
                                <FormGroup>
                                    <TktUsersRolesField name="roles" id="roles" />
                                </FormGroup>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col className='col-md-6 col-sm-12 col-xs-12'>
                    <Card>
                        <CardBody>
                            <CardTitle>
                                <i className="fa fa-map-marker"></i>&nbsp;
                                {t('tktUsers.salepoint_plural')}
                            </CardTitle>
                            <CardText tag="div">
                                <FormGroup>
                                    <SalepointsField name="salepoints" id="salepoints" salepoints={salepoints} />
                                </FormGroup>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row>
                <Col className="text-center">
                    <br />
                    <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                        {isLoading ?
                            <span>
                                &nbsp;<i className="fa fa-spinner fa-spin"></i>
                            </span>
                            :
                            <span>
                                <i className="fa fa-save"></i>&nbsp;
                                {t("common.save")}
                            </span>
                        }
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

const mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
TicketackUserForm = reduxForm({
    form: 'ticketackUserForm', // a unique identifier for this form
    enableReinitialize: true
})(TicketackUserForm)

// You have to connect() to any reducers that you wish to connect to yourself
TicketackUserForm = connect(
    state => ({
        initialValues: state.tktusers.tktuser,
        tktuser: state.tktusers.tktuser,
        salepoints: state.salepoints,
        destroyOnUnmount: false
    }), mapDispatchToProps
)(TicketackUserForm)

export default TicketackUserForm
