import RestClient from './RestClient';
import { Shift } from '../../models';

class Shifts extends RestClient {
    constructor() {
        super({
            "model": Shift,
            "entryPoint": "shift",
            "resource": "shift",
            "resources": "shifts",
            "id": "id"
        });
    }
}

const ShiftsApi = new Shifts();

export default ShiftsApi;
