import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import TaskForm from '../TaskForm/TaskForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../services/';
import { loadTask, updateTask } from '../../../redux/modules/tasks';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const TaskEdit = ({}) => {
    const { t }        = useTranslation();
    const dispatch     = useDispatch();
    const { taskId }   = useParams();
    const { task }     = useSelector(state => state.tasks);
    const default_lang = Utils.default_lang();

    useEffect(() => {
        dispatch(loadTask(taskId));
    }, [])

    const submit = (values) => {
        dispatch(updateTask(values));
    }

    let title = <span>{t('tasks.task')}</span>;
    if (task)
        title = (
            <span>
                {t('tasks.task')}
                <small> |&nbsp;{t('tasks.update_task', { name: task.activity.name[default_lang] })}  | <i>{t('tasks.status_' + task.status)}</i></small>
            </span>
        );

    const actions =
        <div>
            {task &&
                <ButtonGroup>
                    <Button tag={Link} to="/tasks" color="warning" size="sm">
                        <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                    </Button>
                </ButtonGroup>
            }
        </div>

    return (
        <div className="TaskEdit">
            <PageTitle icon="id-badge" title={title} actions={actions} />

            {task &&
                <TaskForm onSubmit={submit} />
            }
        </div>
    );
}

export default TaskEdit;
