import React, { Component, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { setTextSearch, setTagsSearch } from '../../redux/modules/search';
import TagsInput from '../TagsInput/TagsInput';
import _ from 'lodash';

import './SearchBloc.css';

const SearchBloc = ({ scope, title, textTitle, textPlaceholder, tags }) => {
    const dispatch  = useDispatch();
    const textInput = useRef(null);
    const tagsInput = useRef(null);
    const textValue = useSelector(state => state.search.text.scopes[scope]);
    const tagsValue = useSelector(state => state.search.tags.scopes[scope]);

    useEffect(() => {
        if (textInput.current) {
            textInput.current.focus();
            if (textValue)
                textInput.current.value = textValue;
        }
        if (tagsInput.current && tagsValue)
            tagsInput.current.value = tagsValue;
    }, []);

    const textChanged = _.debounce((value) => {
        dispatch(setTextSearch(scope, value));
    }, 250);

    const tagsChanged = _.debounce((value) => {
        dispatch(setTagsSearch(scope, value));
    }, 250);

    const clearText = () => {
        textInput.current.value = "";
        dispatch(setTextSearch(scope, ""));
    };

    return (
        <div className="SearchBloc">
            <div className="wrapper">
                <div className="title">
                    { title }
                </div>
                { textTitle && (
                <div className="input text-input">
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>{ textTitle }</InputGroupText>
                        </InputGroupAddon>
                        <Input
                            type="text"
                            placeholder={ textPlaceholder }
                            innerRef={ textInput }
                            onChange={ (e) => textChanged(e.target.value) }
                        />
                    </InputGroup>
                    { textValue && (
                    <i className="fa fa-times" onClick={ clearText }></i>
                    )}
                </div>
                )}
                { tags && (
                <div className="input tags-input">
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>Tag</InputGroupText>
                        </InputGroupAddon>
                        <TagsInput
                            name="tags"
                            onChange={ (tags) => tagsChanged(tags) }
                        />
                    </InputGroup>
                </div>
                )}
            </div>
        </div>
    );
}

export default SearchBloc;
