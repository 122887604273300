import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux'
import { reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Loader, ItemsList, LanguageSelector } from '../../../../components';
import { Form, Row, Col, Button, ButtonGroup, Card, CardBody, CardText, CardTitle } from "reactstrap";
import _ from "lodash";

import { Setting, FormLayout } from '../../../../models';
import { FormLayoutOptions } from './FormLayoutOptions';
import { FormFieldOptions } from './FormFieldOptions';
import { FormFieldsBrowser } from './FormFieldsBrowser';
import { SortableList } from "./SortableList";
import { arrayMoveImmutable } from 'array-move';

import {
    loadFormLayout, loadFormLayouts, updateFormLayout,
    createFormLayout, duplicateFormLayout, deleteFormLayout,
    createFormLayoutField, deleteFormLayoutField
} from '../../../../redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import './FormLayouts.css';

let FormLayouts = (props) => {
    const { handleSubmit, submitting } = props;

    const { setting }                   = useSelector(state => state.settings);
    const { forms, form, isLoadingOne } = useSelector(state => state.formLayouts);

    const dispatch     = useDispatch();
    const { t, i18n }  = useTranslation();
    const { isMobile } = useSelector(state => state.ui);

    const { formId } = useParams();

    const [fields, setFields]       = useState([]);
    const [inEdition, setInEdition] = useState(false);

    const [currentField, setCurrentField]               = useState(undefined);
    const [previewLanguage, setPreviewLanguage]         = useState(i18n.language);

    // form remove confirmation
    const [confirmationIsVisible, setConfirmationVisible] = useState(false);

    useEffect(() => {
        dispatch(loadFormLayouts());
    }, []);

    useEffect(() => {
        if (formId)
            dispatch(loadFormLayout(formId));
    }, [dispatch, formId])

    useEffect(() => {
        if (forms?.length > 0 && !formId)
            onSelectLayout(forms[0].id);
    }, [forms])

    useEffect(() => {
        if (form) {
            setFields(form.fields);
            if (currentField)
                setCurrentField(form.fields.find(field => field.id === currentField.id));
        }
    }, [form])

    useEffect(() => {
        if (!form || !fields)
            return;

        form.fields = [...fields];
    }, [fields]);

    const addLayout = () => dispatch(createFormLayout(FormLayout.defaultLayout()));

    const duplicate = async () => {
        await dispatch(duplicateFormLayout(form));
        dispatch(loadFormLayouts());
    }

    const removeLayout = async () => {
        // reset current form
        await dispatch(deleteFormLayout(form?.id));
        setCurrentField(undefined);
        setConfirmationVisible(false);
        dispatch(loadFormLayouts());
    }

    const addField = (type, subType) => {
        if (!form)
            return;

        const field = FormLayout.createField({
            form_id: form.id,
            name: 'new_field',
            label: {
                fr: 'Nouveau champ',
                en: 'New field',
                de: 'Neues Feld'
            },
            type,
        });

        form.fields.push(field);
        dispatch(updateFormLayout(form, (err, form) => {
            if (err)
                return;

            if (field.isEditable())
                setTimeout(() => setCurrentField(form.fields?.[form.fields?.length - 1]), 200);
        }));
    };

    const duplicateField = () => {
        if (!form)
            return;

        const newField = _.cloneDeep(currentField);
        delete newField.id;

        form.fields.push(newField);
        dispatch(updateFormLayout(form, (err, form) => {
            if (!err)
                setCurrentField(form.fields[form.fields.length - 1]);
        }));
    };

    const deleteField = () => {
        if (!form || !currentField)
            return;

        form.fields = form.fields.filter(w => w.id !== currentField.id);
        dispatch(updateFormLayout(form, (err, form) => {
            if (!err)
                setCurrentField(undefined);
        }));
    };

    const sortFields = (oldIndex, newIndex) => {
        const newFields = arrayMoveImmutable(fields, oldIndex, newIndex).map((field, index) => {
            field.position = index + 1;
            return field;
        });
        setFields(newFields);
    };

    const handleNewOptions = (field) => {
        setCurrentField(field);
        const newFields = _.cloneDeep(fields)?.map(w => {
            if (w.id === field.id)
                return { ...field };
            return w;
        });;
        setFields(newFields);
    };

    const history = useHistory();
    const onSelectLayout = (id) => {
        setCurrentField(undefined);

        if (id === '__add')
            return addLayout();

        history.push(`/form_layouts/edit/${id}`);
    }

    const selectField = (field, index) => {
        setCurrentField(undefined);
        // we let the options field the time to reset
        setTimeout(() => {
            if (field.isEditable())
                setCurrentField(field);
        }, 0);
    }

    const items = _.uniqBy((forms || []), 'id').map((l, index) => {
        return {
            key: `${l.name[i18n.language]}.${index}`,
            title: l.name[i18n.language],
            icon: 'th',
            active: l.id === formId ? true : false,
            depth: 0,
            selection: l.id
        }
    });
    items.push({
        key: '__add',
        selection: '__add',
        title: t("forms.add_new_form"),
        icon: 'plus',
        isButton: true,
        depth: 0
    });

    return (
        <div className="FormLayouts">
            <Card>
                <CardBody>
                        <CardText tag="div">
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col className="post_categories-browser" sm={2}>
                                        <div className="TypeSelector">
                                            <ItemsList
                                                title={t('forms.forms')}
                                                items={items}
                                                onSelect={onSelectLayout}
                                            />
                                        </div>
                                        {form &&
                                            <>
                                                <Card className="h-auto mb-3">
                                                    <CardBody>
                                                        <CardTitle className="small">{t('settings.delivery.type.general_informations')}</CardTitle>
                                                        <CardText tag="div">
                                                            <FormLayoutOptions />
                                                            {!confirmationIsVisible && (
                                                            <>
                                                                <Row>
                                                                    <Col>

                                                                        <ButtonGroup>
                                                                            <Button color="danger" onClick={() => setConfirmationVisible(true)}>
                                                                                <span>
                                                                                    <i className="fa fa-trash"></i>&nbsp;{t("common.delete")}
                                                                                </span>
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                                <Row>
                                                                    <Col>

                                                                        <ButtonGroup>
                                                                            <Button onClick={handleSubmit} type="submit" color="success" disabled={submitting || isLoadingOne}>
                                                                                {isLoadingOne ?
                                                                                    <span><i className="fa fa-spinner fa-spin"></i></span>
                                                                                    :
                                                                                    <span><i className="fa fa-save"></i>&nbsp; {t("common.save")} </span>
                                                                                }
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            )}
                                                            {confirmationIsVisible && (
                                                            <Row>
                                                                <Col>
                                                                    <b>{t('common.are_you_sure')}</b>&nbsp;
                                                                    <ButtonGroup>
                                                                        <Button onClick={() => setConfirmationVisible(false)} color='info' size="sm">
                                                                            <i className="fa fa-times"></i> {t("common.no")}
                                                                        </Button>
                                                                        <Button type="submit" color="danger" onClick={removeLayout}>
                                                                            <span>
                                                                                <i className="fa fa-trash"></i>&nbsp;{t("common.yes")}
                                                                            </span>
                                                                        </Button>
                                                                    </ButtonGroup>
                                                                </Col>
                                                            </Row>
                                                            )}
                                                        </CardText>
                                                    </CardBody>
                                                </Card>
                                            </>
                                        }
                                    </Col>
                                    <Col className="post_categories-browser" sm={10}>
                                        {form &&
                                            <>
                                                <Row>
                                                    <Col sm={inEdition ? 6 : 12}>
                                                        <div className={"topButtonsWrapper " + (isMobile ? "w-100" : "")}>
                                                            <ButtonGroup className={isMobile ? "w-100" : ""}>
                                                                <Button onClick={() => setInEdition(true)} color={inEdition ? 'primary' : 'dark'} size="sm">
                                                                    <i className="fa fa-pencil"></i> {t("settings.pdf.edit_mode")}
                                                                </Button>
                                                                <Button onClick={() => setInEdition(false)} color={!inEdition ? 'primary' : 'dark'} size="sm">
                                                                    <i className="fa fa-eye"></i> {t("settings.pdf.preview_mode.title")}
                                                                </Button>
                                                            </ButtonGroup>

                                                            <LanguageSelector onChange={lang => setPreviewLanguage(lang)} />
                                                        </div>
                                                        <div className={"previewWrapper " + (isMobile ? "w-100" : "")}>
                                                            <div className="preview">
                                                                <div className={fields?.length > 0 ? 'contentWrapper' : ''}>
                                                                    { isLoadingOne ? (
                                                                        <div className="loader-wrapper">
                                                                            <Loader />
                                                                        </div>
                                                                    ) : (
                                                                        <SortableList
                                                                            form={form}
                                                                            fields={fields.sort((a, b) => a.position - b.position)}
                                                                            currentField={currentField}
                                                                            inEdition={inEdition}
                                                                            lang={previewLanguage}
                                                                            setting={new Setting(setting)}
                                                                            onClick={(field, index) => selectField(field, index)}
                                                                            onSort={sortFields}
                                                                            onDelete={deleteField}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col sm={6}>
                                                        {inEdition && currentField && (
                                                        <>
                                                            <CardTitle className="small">
                                                                {t('forms.edit_field')} - {t(`forms.${currentField.type}`)}
                                                            </CardTitle>
                                                            <FormFieldOptions
                                                                field={FormLayout.createField(currentField)}
                                                                onSubmit={handleNewOptions}
                                                                onDuplicate={duplicateField}
                                                                onDelete={deleteField}
                                                                onClose={() => setCurrentField(undefined)}
                                                            />
                                                        </>
                                                        )}
                                                        {inEdition && !currentField && (
                                                        <>
                                                            <CardTitle className="small">
                                                                {t('forms.add_field')}
                                                            </CardTitle>
                                                            <FormFieldsBrowser addField={addField} />
                                                        </>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </CardText>
                </CardBody>
            </Card>
        </div>
    );
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FormLayouts = reduxForm({
  form: 'formForm', // a unique identifier for this form
  enableReinitialize: true
})(FormLayouts);

FormLayouts = connect(state => ({ initialValues: state.formLayouts.form, }), {})(FormLayouts);

export default FormLayouts;
