// Redux Tickettypes module
// see https://github.com/erikras/ducks-modular-redux

import { TktUsers } from '../../services';
import _ from 'lodash';

const LOADING_TKTUSERS                  = 'kronos/tktusers/LOADING_TKTUSERS';
const LOADING_TKTUSERS_SUCCESS          = 'kronos/tktusers/LOADING_TKTUSERS_SUCCESS';
const LOADING_TKTUSERS_FAILURE          = 'kronos/tktusers/LOADING_TKTUSERS_FAILURE';
const LOADING_TKTUSER                   = 'kronos/tktusers/LOADING_TKTUSER';
const LOADING_TKTUSER_SUCCESS           = 'kronos/tktusers/LOADING_TKTUSER_SUCCESS';
const LOADING_TKTUSER_FAILURE           = 'kronos/tktusers/LOADING_TKTUSER_FAILURE';
const LOADING_INACTIVE_TKTUSERS         = 'kronos/tktusers/LOADING_INACTIVE_TKTUSERS';
const LOADING_INACTIVE_TKTUSERS_SUCCESS = 'kronos/tktusers/LOADING_INACTIVE_TKTUSERS_SUCCESS';
const LOADING_INACTIVE_TKTUSERS_FAILURE = 'kronos/tktusers/LOADING_INACTIVE_TKTUSERS_FAILURE';
const UPDATING_TKTUSER                  = 'kronos/tktusers/UPDATING_TKTUSER';
const UPDATING_TKTUSER_SUCCESS          = 'kronos/tktusers/UPDATING_TKTUSER_SUCCESS';
const UPDATING_TKTUSER_FAILURE          = 'kronos/tktusers/UPDATING_TKTUSER_FAILURE';
const START_CREATING_TKTUSER            = 'kronos/tktusers/START_CREATING_TKTUSER';
const CREATING_TKTUSER                  = 'kronos/tktusers/CREATING_TKTUSER';
const CREATING_TKTUSER_SUCCESS          = 'kronos/tktusers/CREATING_TKTUSER_SUCCESS';
const CREATING_TKTUSER_FAILURE          = 'kronos/tktusers/CREATING_TKTUSER_FAILURE';
const DELETING_TKTUSER                  = 'kronos/tktusers/DELETING_TKTUSER';
const DELETING_TKTUSER_SUCCESS          = 'kronos/tktusers/DELETING_TKTUSER_SUCCESS';
const DELETING_TKTUSER_FAILURE          = 'kronos/tktusers/DELETING_TKTUSER_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    tktusers: [],
    tktuser: {},
    tktuserSuccessfullyCreated: false,
    tktuserSuccessfullyUpdated: false,
}

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_TKTUSERS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TKTUSERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tktusers: action.tktusers,
            };
        case LOADING_TKTUSERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
            };
        case LOADING_TKTUSER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_TKTUSER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tktuser: action.tktuser
            };
        case LOADING_TKTUSER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_INACTIVE_TKTUSERS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_INACTIVE_TKTUSERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                inactive_tktusers: action.tktusers
            };
        case LOADING_INACTIVE_TKTUSERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_TKTUSER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                tktuserSuccessfullyUpdated: false,
            };
        case UPDATING_TKTUSER_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                tktuser: action.tktuser,
                tktuserSuccessfullyUpdated: true,
            };
        case UPDATING_TKTUSER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message,
                tktuserSuccessfullyUpdated: false
            };
        case START_CREATING_TKTUSER:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tktuserSuccessfullyCreated: false,
                tktuser: {}
            };
        case CREATING_TKTUSER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_TKTUSER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tktuserSuccessfullyCreated: true,
                tktuser: {
                    ...action.tktuser
                }
            };
        case CREATING_TKTUSER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                tktuserSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_TKTUSER:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_TKTUSER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                tktuserSuccessfullyDeleted: true,
                tktuser: action.tktuser
            };
        case DELETING_TKTUSER_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                tktuserSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingTktUsers() { return { type: LOADING_TKTUSERS } }
function loadingTktUsersSuccess(tktusers) { return { type: LOADING_TKTUSERS_SUCCESS, tktusers } }
function loadingTktUsersFailure(err) { return { type: LOADING_TKTUSERS_FAILURE, error: err } }
export function loadTktUsers() {
    return (dispatch) => {
        dispatch(loadingTktUsers());
        TktUsers.list()
            .then(data => {
                const tktusers = data.tktusers;
                dispatch(loadingTktUsersSuccess(tktusers));
            })
            .catch(err => {
                dispatch(loadingTktUsersFailure(err))
            });
    }
}

function loadingTktUser() { return { type: LOADING_TKTUSER } }
function loadingTktUserSuccess(tktuser) { return { type: LOADING_TKTUSER_SUCCESS, tktuser } }
function loadingTktUserFailure(err) { return { type: LOADING_TKTUSER_FAILURE, error: err } }
export function loadTktUser(tktuserId) {
    return (dispatch) => {
        dispatch(loadingTktUser());
        TktUsers.get(tktuserId)
            .then(data => {
                const tktuser = data.user;
                dispatch(loadingTktUserSuccess(tktuser));
            })
            .catch(err => {
                dispatch(loadingTktUserFailure(err))
            });
    }
}

function loadingInactiveTktUsers() { return { type: LOADING_INACTIVE_TKTUSERS } }
function loadingInactiveTktUsersSuccess(tktusers) { return { type: LOADING_INACTIVE_TKTUSERS_SUCCESS, tktusers } }
function loadingInactiveTktUsersFailure(err) { return { type: LOADING_INACTIVE_TKTUSERS_FAILURE, error: err } }
export function loadInactiveTktUsers() {
    return (dispatch) => {
        dispatch(loadingInactiveTktUsers());
        TktUsers.inactive()
            .then(data => {
                const tktusers = data.tktusers;
                dispatch(loadingInactiveTktUsersSuccess(tktusers));
            })
            .catch(err => {
                dispatch(loadingInactiveTktUsersFailure(err))
            });
    }
}

function updatingTktUser() { return { type: UPDATING_TKTUSER } }
function updatingTktUserSuccess(tktuser) { return { type: UPDATING_TKTUSER_SUCCESS, tktuser } }
function updatingTktUserFailure(err) { return { type: UPDATING_TKTUSER_FAILURE, error: err } }
export function updateTktUser(tktuser) {
    return (dispatch) => {
        dispatch(updatingTktUser());
        TktUsers.update(tktuser)
            .then(data => {
                const user = data.tktuser;
                dispatch(updatingTktUserSuccess(tktuser));
            })
            .catch(err => {
                dispatch(updatingTktUserFailure(err))
            });
    }
}

function creatingTktUser() { return { type: CREATING_TKTUSER } }
function creatingTktUserSuccess(tktuser) { return { type: CREATING_TKTUSER_SUCCESS, tktuser } }
function creatingTktUserFailure(err) { return { type: CREATING_TKTUSER_FAILURE, error: err } }
export function startCreateTktUser() { return { type: START_CREATING_TKTUSER } }
export function createTktUser(tktuser) {
    return (dispatch) => {
        dispatch(creatingTktUser());
        TktUsers.create(tktuser)
            .then(data => {
                const tktuser = data.tktuser;
                dispatch(creatingTktUserSuccess(tktuser));
            })
            .catch(err => {
                dispatch(creatingTktUserFailure(err))
            });
    }
}

function deletingTktUser() { return { type: DELETING_TKTUSER } }
function deletingTktUserSuccess() { return { type: DELETING_TKTUSER_SUCCESS } }
function deletingTktUserFailure(err) { return { type: DELETING_TKTUSER_FAILURE, error: err } }
export function deleteTktUser(tktuserId) {
    return (dispatch) => {
        dispatch(deletingTktUser());
        TktUsers.delete(tktuserId)
            .then(data => {
                dispatch(deletingTktUserSuccess());
            })
            .catch(err => {
                dispatch(deletingTktUserFailure(err))
            });
    }
}


