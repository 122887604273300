import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { loadEvent, deleteEvent } from '../../../redux/modules/events';

class EventDelete extends Component<{}> {
    componentDidMount() {
        const { eventId, type } = this.props.match.params;
        // Load events on component mount
        this.props.dispatchLoadEvent(type, eventId);
    }

    deleteEvent(eventId) {
        const type = this.props.match.params.type;
        this.props.dispatchDeleteEvent(type, eventId);
    }

    render() {
        const { event, isLoading, eventSuccessfullyDeleted } = this.props.events;
        const { t, match } = this.props;
        const type         = match.params.type;
        const default_lang = Utils.default_lang();

        let title = <span>{t("bulidings.event_plural")}</span>;
        if (event)
            title = (
                <span>
                    {t("events.event")}
                    <small> | { event.title[default_lang] } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/events" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
            </ButtonGroup>
        </div>

        return (
            <div className="EventDelete">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {eventSuccessfullyDeleted &&
                    <Redirect to={`/events/${type}`}/>
                }

                {event &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-warning"></i>&nbsp;{ t('events.confirm_delete')}</CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t("events.title")}</b>: { event.title[default_lang] }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/events" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteEvent(event.id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            { t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadEvent: (type, eventId) => loadEvent(type, eventId),
    dispatchDeleteEvent: (type, eventId) => deleteEvent(type, eventId)
}

const mapStateToProps = state => ({
    events: state.events
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(EventDelete))
