import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { TabContent, TabPane } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import TranslatableField from '../../../components/TranslatableField/TranslatableField';
import CategoriesField from '../../../components/CategoriesField/CategoriesField';
import { loadPostcategories } from '../../../redux/modules/post_categories';
import { Utils } from '../../../services';
import { reduxForm } from 'redux-form';
import classnames from 'classnames';

import moment from 'moment';
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('fr');
momentLocalizer();

let PostcategoryForm = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadPostcategories());
    }, [ dispatch ]);

    const toggle = (tab) => {
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    const { handleSubmit, pristine, submitting, isCreation } = props;
    const { isLoading, post_category, post_categories }      = useSelector(state => state.post_categories);

    const { t } = useTranslation();
    const tree = Utils.sortAndIndentCats(post_categories, 'id');
    const parent_categories = tree.filter((c) => {
        return !post_category || c.id != post_category.id;
    });

    return (
        <Form onSubmit={handleSubmit}>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className="active"
                        onClick={() => { toggle('1'); }}
                    >
                        <i className="fa fa-info-circle"></i>&nbsp;
                        { t("post_categories.informations") }
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={ activeTab }>
                <TabPane tabId="1">
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("post_categories.informations")}</CardTitle>
                                    <CardText tag="div">
                                        <FormGroup row>
                                            <Label for="name" sm={3}>{t("post_categories.name")}</Label>
                                            <Col sm={9}>
                                                <TranslatableField name="name" placeholder={t("post_categories.name_placeholder")} component="input"/>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="parent_id" sm={3}>{t("articlecategories.parent")}</Label>
                                            <Col sm={9}>
                                                <CategoriesField name="parent_id" categories={ parent_categories } />
                                            </Col>
                                        </FormGroup>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
            <br/>
                <Row>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
        </Form>
    );

}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
PostcategoryForm = reduxForm({
  form: 'postcategoryForm', // a unique identifier for this form
  enableReinitialize: true
})(PostcategoryForm);

PostcategoryForm = connect(
  state => ({
    initialValues: state.post_categories.post_category, // pull initial values from post_categories reducer
    post_categories: state.post_categories,
}), {}
)(PostcategoryForm)


export default PostcategoryForm;
