import React from "react";
import EmailLayout from '../EmailLayout';
import EmailLayoutWidget from './EmailLayoutWidget';
import { Screen, Utils } from '../../services';
import { createStyleString } from '@capsizecss/core';

/** Class representing a PDF layout custom text widget. */
class CustomTextWidget extends EmailLayoutWidget {

    constructor(properties) {
        super({
            ...(CustomTextWidget.getDefaultOptions()),
            ...properties,
        });

        this.type    = EmailLayout.TEXT;
        this.subType = EmailLayout.CUSTOM;
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        const textProperties = {};
        Utils.langs().forEach(lang => { textProperties[lang] = "";  });

        return {
            options: {
                text: textProperties,
                style: {
                    color: '#000000',
                    backgroundColor: '#FFFFFFFF',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10,
                    },
                }
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            EmailLayout.OPTION_COLOR,
            EmailLayout.OPTION_TEXT,
            EmailLayout.OPTION_PADDING,
        ]);
    }

    /**
     * Return this widget preview text as string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreviewText(props) {
        const { inEdition, lang } = props;
        return this.options?.text[lang] || (inEdition ? '.....'  : '');
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {EmailLayout} layout
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        return (
            <div style={{
                color: this.options?.style?.color || '#000000',
                backgroundColor: this.options?.style?.backgroundColor,
                border: `${this.options?.style?.borderWidth}px ${this.options?.style?.borderStyle} ${this.options?.style?.borderColor}`,
                borderRadius: `${this.options?.style?.borderRadius || 0}px`,
                paddingTop: `${this.options?.style?.padding?.top || 0}px`,
                paddingRight: `${this.options?.style?.padding?.right || 0}px`,
                paddingBottom: `${this.options?.style?.padding?.bottom || 0}px`,
                paddingLeft: `${this.options?.style?.padding?.left || 0}px`
            }}>
                <div className={this.id} dangerouslySetInnerHTML={{__html: this.getPreviewText(props) }} />
            </div>
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'commenting-o';
    }
}

export default CustomTextWidget;
