import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { refund } from '../../../../../../redux';
import { Button, Form, FormGroup, Input, } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiCashRefund } from '@mdi/js';

const RefundButton = ({ collector }) => {
    const { t }           = useTranslation();
    const dispatch        = useDispatch();

    const [isFormVisible, showForm] = useState(false);
    const [amount, setAmount]       = useState(0);

    const doRefund = () => {
        dispatch(refund(collector.id, amount));
        setAmount(0);
    }

    return (
        <>
            <Button
                onClick={ () => { showForm(!isFormVisible) }}
                className={ `operation ${isFormVisible ? 'form-visible' : ''}` }
                color="warning"
                block
            >
                <div className="icon">
                    <Icon path={ mdiCashRefund } size={ 3 } color="#FFFFFF" />{ ' ' }
                </div>
                <div className="title">
                    { t('collectors.refund') }
                    <div className="subtitle">{ t('collectors.refund_desc') }</div>
                </div>
            </Button>

            { isFormVisible && (
            <Form className="operation-form">
                <div className="form-body">
                    <FormGroup>
                        <Input
                            type="decimal"
                            placeholder={ t('collectors.refund_amount_label_placeholder') }
                            onChange={(e) => { setAmount(e.target.value); }}
                            value={ amount }
                        />
                    </FormGroup>
                </div>
                <div className="form-footer">
                    <Button
                        color="warning"
                        onClick={ doRefund }
                    >
                        <Icon path={ mdiCashRefund } size={ 1 } color="#212529" />{ ' ' }
                        { t('collectors.refund') }
                    </Button>
                </div>
            </Form>
            )}
        </>
    );
}

export default RefundButton;
