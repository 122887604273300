import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PageTitle, CollectorResume, Loader } from '../../../../components';
import { loadCollectors } from '../../../../redux';
import { Row, Col, Button } from 'reactstrap';
import Icon from '@mdi/react';
import { mdiArrowLeftCircle } from '@mdi/js';

const CollectorsList = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const { connected }  = useSelector(state => state.sioBridge);
    const { collectors } = useSelector(state => state.collectors, shallowEqual);
    const { isLoading }  = useSelector(state => state.collectors);

    useEffect(() => {
        if (connected)
            dispatch(loadCollectors());
    }, [dispatch, connected]);

    const actions = <Link to="/devices">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    return (
        <div className="CollectorsList">
            <PageTitle
                title={ t('collectors.title') }
                icon="money"
                actions={ actions }
            />

            {isLoading && (
                <Loader />
            )}

            {!isLoading && (
            <div className="mt-3">
                <Row>
                { collectors && collectors.map(collector => (
                    <Col key={ collector.id } lg={ 2 } md={ 4 } sm={ 6 } xs={ 12 }>
                        <CollectorResume collector={ collector } className="mb-3" />
                    </Col>
                ))}
                </Row>
            </div>
            )}
        </div>
    );
}

export default CollectorsList;
