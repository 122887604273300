import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, FormGroup } from 'reactstrap';
import { Field } from 'redux-form';
import { Utils } from '../../../../../services';

const FeaturedMovie = ({ env, screen, widget }) => {
    const { t } = useTranslation();

    return (
        <>
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.random_featured_movie') }
                </Label>
                <Col sm={ 8 }>
                <Field
                    component="select"
                    className="form-control"
                    type="text"
                    name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].randomMovie` }
                    normalize={Utils.normalizeBoolean}
                >
                    <option key="false" value={false}>{t("common.no")}</option>
                    <option key="true" value={true}>{t("common.yes")}</option>
                </Field>
                </Col>
            </FormGroup>
            { !widget.randomMovie && (
            <FormGroup row className="widget-setting">
                <Label className="col-sm-4">
                    { t('settings.mobile.screens.widget.settings.featured_movie_id') }
                </Label>
                <Col sm={ 8 }>
                    <Field
                        type="text"
                        component="input"
                        className="form-control"
                        name={ `mobile.${env}.screens.${screen}.widgets[${widget.index}].movie` }
                    />
                </Col>
            </FormGroup>
            )}
        </>
    );
}

export default FeaturedMovie;
