import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './PhoneField.css';

const PhoneField = ({ input, required }) => {
    const { t } = useTranslation();
    const [country, setCountry] = useState(null);
    const countries = {
        ch: {
            text: '🇨🇭 +41',
            regex_ind: /^[+]?41|0041/,
            regex_num: /[0]?(\d{9})$/,
            state_ind_value: '+41',
            breakpoints: [2, 5, 7],
            num_length: 9,
            input_validity_pattern: "\\d{2}\\s\\d{3}\\s\\d{2}\\s\\d{2}"
        },
        fr: {
            text: '🇫🇷 +33',
            regex_ind: /^[+]?33|0033/,
            regex_num: /[0]?(\d{9})$/,
            state_ind_value: '+33',
            breakpoints: [1, 3, 5, 7],
            num_length: 9,
            input_validity_pattern: "\\d{1}\\s\\d{2}\\s\\d{2}\\s\\d{2}\\s\\d{2}"
        },
        de: {
            text: '🇩🇪 +49',
            regex_ind: /^[+]?49|0049/,
            regex_num: /[0]?(\d{9})$/,
            state_ind_value: '+49',
            breakpoints: [3, 6, 9],
            num_length: 9,
            input_validity_pattern: "\\d{3}\\s\\d{3}\\s\\d{3}"
        },
        it: {
            text: '🇮🇹 +39',
            regex_ind: /^[+]?39|0039/,
            regex_num: /[0]?(\d{9})$/,
            state_ind_value: '+39',
            breakpoints: [3, 6, 9],
            num_length: 9,
            input_validity_pattern: "\\d{3}\\s\\d{3}\\s\\d{3}"
        },
    }

    const removeSpaces = string => {
        return string.replace(/[^+0-9]/g, '')
    }

    const handleSpace = (phone_number, country_code) => {
        phone_number = removeSpaces(phone_number);
        let temp = '';
        for (let i = 0; i < phone_number.length; i++) {
            if (i < 9) {
                if (countries[country_code].breakpoints.find(e => e === i))
                    temp += " ";
                temp += phone_number[i];
            }
        }
        return temp;
    }

    // Remove anything that's not 0-9 or +
    const phoneInput = removeSpaces(input.value);

    // Default country
    let number        = "";
    let matchLength   = null;
    let countryResult = "ch";

    if (phoneInput) {
        // Try to match indicative
        Object.keys(countries).forEach(k => {
            const indMatch = phoneInput.match(countries[k].regex_ind);
            if (indMatch) {
                countryResult = k;
                matchLength = indMatch[0].length
            }
        })

        if (matchLength) {
            const toLength = phoneInput.length < matchLength ? matchLength : phoneInput.length;
            number         = handleSpace(phoneInput.slice(matchLength, toLength), countryResult);
        } else {
            number = handleSpace(phoneInput.slice(Math.max(phoneInput.length - countries[countryResult].num_length, 0)), countryResult);
        }
    }

    const handleNumber = (num, country) => {
        if (removeSpaces(num).length > countries[country].num_length)
            return false;
        else if (num === "")
            input.onChange('');
        else
            input.onChange(countries[country].state_ind_value + removeSpaces(num));
    }

    const handleCountry = (value) => {
        setCountry(value);
        if (number !== "")
            input.onChange(countries[value].state_ind_value + removeSpaces(number));
        else
            input.onChange('')
    }

    return (
        <div className='PhoneField'>
            <select
                className='form-control indicative'
                onChange={(e) => handleCountry(e.target.value)}
                value={country ?? countryResult}
            >
                {Object.keys(countries).map(k =>
                    <option
                        key={k}
                        value={k}
                    >
                        {countries[k].text}
                    </option>
                )}
            </select>
            <input
                type="text"
                className='form-control number'
                pattern={countries[country ?? countryResult].input_validity_pattern}
                value={number}
                required={ !!required }
                onInvalid={e => e.target.setCustomValidity(t("users.invalid_phone"))}
                onInput={e => e.target.setCustomValidity(t(""))}
                onChange={(e) => handleNumber(e.target.value, country ?? countryResult)}
                placeholder={t('users.phone_placeholder')}
            />
        </div>
    )
}

export default PhoneField;
