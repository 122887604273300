// Simply expose env vars defined in .env files
// See https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#adding-development-environment-variables-in-env

const config = {
    app_version: process.env.REACT_APP_VERSION,
    api_end_point: process.env.REACT_APP_API_ROOT,
    theme: process.env.REACT_APP_THEME,
    weather_api_appid : process.env.REACT_APP_WEATHER_APPID,
    yoda_project_id: process.env.REACT_APP_YODA_PROJECT_ID,
    yoda_kore_project_id: process.env.REACT_APP_YODA_KORE_PROJECT_ID
};

export default config;
