import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.yml';
import fr from './fr.yml';
import de from './de.yml';

const dotify = (obj) => {
    var res = {};
    function recurse(obj, current) {
        for (var key in obj) {
            var value = obj[key];
            var newKey = (current ? current + '.' + key : key);  // joined key with dot
            if (value && typeof value === 'object') {
                recurse(value, newKey);  // it's a nested object, so do it again
            } else {
                res[newKey] = value;  // it's not an object, so set the property
            }
        }
    }

    recurse(obj);
    return res;
};

const resources = {
    en: { translations: dotify(en) },
    fr: { translations: dotify(fr) },
    de: { translations: dotify(de) }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        // we init with resources
        resources: resources,
        fallbackLng: 'fr',
        debug: false,

        supportedLngs: ['fr', 'en', 'de'],

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ','
        },

        react: {
            wait: true
        }
    });

export default i18n;
