import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import SectorForm from '../SectorForm/SectorForm';
import PageTitle from '../../../components/PageTitle/PageTitle';

import { startCreateSector, createSector } from '../../../redux/modules/sectors';

const SectorCreate = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startCreateSector());
    }, [dispatch])

    const submit = (values) => {
        dispatch(createSector(values));
    }

    const { sectorSuccessfullyCreated } = useSelector(state => state.sectors);

    let title = <span>{t('sectors.add')}</span>;

    const actions =
        <Button tag={Link} to="/sectors" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="SectorCreate">
            <PageTitle icon="location-arrow" title={ title } actions={ actions } />

            {sectorSuccessfullyCreated &&
                <Redirect to="/sectors"/>
            }

            <SectorForm onSubmit={ submit } isCreation="true" />
        </div>
    );
}

export default SectorCreate
