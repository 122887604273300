import React, { Component } from 'react';
import moment from 'moment'
import { connect } from 'react-redux'
import { ButtonGroup, Button, Input } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import matchSorter from 'match-sorter';
import { withTranslation } from 'react-i18next';

import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css'

import { loadLegacyScreenings, tktsyncLegacyScreenings } from '../../../redux/modules/legacyScreenings';

class OldScreeningsTktSync extends Component<{}> {
    constructor(props) {
        super(props);

        this.tktsync = this.tktsync.bind(this);
    }

    tktsync() {
        this.props.dispatchTktsyncLegacyScreenings();
    }

    componentDidMount() {
        // Load screenings on component mount
        this.props.dispatchLoadLegacyScreenings();
    }

    render() {
        const { legacyScreenings, hasLoadingError, loadingErrorMessage } = this.props.legacyScreenings;

        const { t } = this.props;

        const actions =
        <ButtonGroup>
            <Button onClick={this.tktsync} color="info" size="sm">
                <i className="fa fa-download"></i>&nbsp;{t("events.tktsync")}
            </Button>
            <Button tag={Link} to="/screenings/new" color="success" size="sm">
                <i className="fa fa-plus"></i>&nbsp;{t("screenings.add")}
            </Button>
        </ButtonGroup>

        const title =
        <span>{t("screenings.screening_plural")}</span>

        return (
            <div className="ScreeningsTktSync">
                <PageTitle icon="th" title={title} actions={ actions } />
                <Button onClick={this.tktsync} color="info" size="sm">
                    <i className="fa fa-download"></i>&nbsp;{t("events.tktsync")}
                </Button>
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadLegacyScreenings: () => loadLegacyScreenings(),
    dispatchTktsyncLegacyScreenings: () => tktsyncLegacyScreenings()
}

const mapStateToProps = state => ({
    legacyScreenings: state.legacyScreenings
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(OldScreeningsTktSync))
