import React from 'react';
import { Utils } from '../../services';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import i18n from "i18next";

const SectionsField = ({ sections, single, name }) => {

    const default_lang = Utils.default_lang();

    const options = sections.map((section) => ({
        key: section.id,
        text: section.name[i18n.language] ?? section.name[default_lang],
        value: section.id
    }));

    return single ? (
        <SelectField name={ name } options={ options } />
    ) : (
        <MultiSelectField name={ name } options={ options } />
    );
}

export default SectionsField;