import React from "react";
import { v4 } from 'uuid';
import BaseModel from './BaseModel';

/** Class representing a Form layout field. */
class FormLayoutFieldChoice extends BaseModel {
    constructor(properties) {
        super(properties || {});

        if (!this.id)
            this.id = v4();
    }
}

export default FormLayoutFieldChoice;
