import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './Help.css';

const Help = () => {
    const [isVisible, setIsVisible] = useState(false);
    const { KUserSettings } = useSelector(state => state.userSettings);

    const toggle = () => {
        setIsVisible(!isVisible);
    }

    return (
        <>
            {KUserSettings.displayTooltips &&
                <div id="ht-kb-fe-embed-container" className="Help ht-kb-fe-embed-container noPrint">
                    <div id="hkbembed-button" className={isVisible ? 'hkbembed-button--open' : ''} onClick={toggle}>
                        <span className="hkbembed-button-open">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><g fill="#fff"><circle cx="12" cy="21.5" r="2" /><path d="M10.5 17.5V16c0-2.918 1.939-4.513 3.5-5.794S16.5 8.07 16.5 6.5c0-1.822-1.57-3-4-3a7.59 7.59 0 00-4.108 1.206l-1.2.877-1.78-2.39L6.6 2.3A10.514 10.514 0 0112.5.5c4.122 0 7 2.468 7 6 0 3.067-1.994 4.707-3.6 6.023-1.535 1.262-2.4 2.045-2.4 3.477v1.5z" /></g></svg>
                        </span>
                        <span className="hkbembed-button-close">
                            <svg xmlns="http://www.w3.org/2000/svg" strokeWidth="2" viewBox="0 0 24 24" height="24" width="24"><g fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeLinejoin="round"><path d="M19 5L5 19M19 19L5 5" /></g></svg>
                        </span>
                    </div>
                    <div id="ht-kb-fe-embed-contents" style={{ display: isVisible ? 'block' : 'none' }}>
                        <iframe
                            id="ht-kb-fe-embed-iframe"
                            src="https://ticketack.com/?kbembed=content"
                            loading="lazy"
                            style={{ width: isVisible ? '100%' : 0 }}
                        ></iframe>
                    </div>
                </div>
            }
        </>
    );
}

export default Help;
