import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import _ from 'lodash';

import './CollectorModules.css';

const CollectorModules = ({ collector, className, ...rest }) => {
    const { t }   = useTranslation();
    const modules = collector.state.modules.filter(m => m.getText().length);

    if (!modules.length)
        return null;

    const sortedModules = _.concat(
        modules.filter(m => !m.isOk()),
        modules.filter(m => m.isOk())
    );

    return (
        <div className={ `CollectorModules ${className}` } { ...rest }>
        { sortedModules && sortedModules.map((m, i) => (
            <div key={ i } className={ `module ${m.isOk() ? 'ok' : 'error' }` }>
                <div className="left">
                    <div className="icon">
                        <Icon path={ m.getIcon() } size={ 1.8 } color="#FFFFFF" />
                    </div>
                </div>
                <div className="right">
                    <div className="text">
                        { t(`collectors.modules.${m.getText()}`) }
                    </div>
                    <div className="datetime">
                        { m.getSubtext() }
                    </div>
                </div>
            </div>
        ))}
        </div>
    );
}

export default CollectorModules;
