import React, { useState, useEffect } from 'react';
import RichTextEditor, {
    getTextAlignClassName,
    getTextAlignStyles,
    getTextAlignBlockMetadata,
} from 'react-rte';

import './Wysiwyg.css';

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'IMAGE_BUTTON', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'},
        {label: 'Blockquote', style: 'blockquote'}
    ]
};

const Wysiwyg = ({ defaultValue, onChange, fullToolbar }) => {
    const [ value, setValue ] = useState(RichTextEditor.createEmptyValue());

    React.useMemo(() => {
        if (defaultValue === value?.toString('html'))
            return;

        setValue(!(defaultValue?.length > 0) ?
            RichTextEditor.createEmptyValue() :
            RichTextEditor.createValueFromString(defaultValue?.toString(), 'html', {
                customBlockFn: getTextAlignBlockMetadata
            }));

    }, [defaultValue]);

    const onEditorChange = newValue => {
        setValue(newValue);
        if (onChange && (value.toString('html') !== newValue.toString('html', { blockStyleFn: getTextAlignStyles })))
            onChange(newValue.toString('html', { blockStyleFn: getTextAlignStyles }) || '');
    };

    return (
        <div className="Wysiwyg">
            <RichTextEditor
                value={ value }
                onChange={ onEditorChange }
                blockStyleFn={getTextAlignClassName}
                toolbarConfig={ fullToolbar ? undefined : toolbarConfig }
            />
        </div>
    );
}

export default Wysiwyg;

