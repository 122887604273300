import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from '../SelectField/SelectField';
import MultiSelectField from '../MultiSelectField/MultiSelectField';
import _ from 'lodash';

import './PlacesField.css';

const PlacesField = ({ name, places, onChange, single, defaultValue }) => {
    const options = places?.length > 0 ? _.sortBy(places.map(place => {
        return {
            key: place._id,
            text: place.name,
            value: place._id
        };
    }), 'text') : [];

    const onFieldChange = (value) => {
        return onChange ? onChange(value) : true;
    }

    return single ? (
        <SelectField
            name={ name }
            options={ options }
            onSelectChange={ onFieldChange }
            defaultValue={defaultValue}
            clearable
        />
    ) : (
        <MultiSelectField
            name={ name }
            options={ options }
            onSelectChange={ onFieldChange }
            defaultValue={defaultValue}
        />
    );
}

export default PlacesField;
