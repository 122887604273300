import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Redirect, Link, useParams } from "react-router-dom";
import { Article } from '../../../models/';
import ArticleForm from '../ArticleForm/ArticleForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { startCreateArticle, createArticle } from '../../../redux/modules/articles';
import { Utils } from '../../../services/';
import _ from 'lodash';


const ArticleCreate = () => {
    const { t }           = useTranslation();
    const { articleType } = useParams();
    const dispatch        = useDispatch();
    const {
        articleSuccessfullyCreated,
        article
    } = useSelector(state => state.articles);

    useEffect(() => {
        dispatch(startCreateArticle(articleType));
    }, [ dispatch ]);

    const submit = (values) => {
        dispatch(createArticle(values));
    }

    let title = <span>{t('articles.add')}</span>;

    const actions =
        <Button tag={Link} to="/articles" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <>
            {articleSuccessfullyCreated &&
                <Redirect to={`/articles/edit/${article._id}`} />
            }
            <div className="ArticleCreate">
                <PageTitle icon="th" title={ title } actions={ actions } />
                <ArticleForm onSubmit={ submit } isCreation="true" />
            </div>
        </>
    );
}

export default ArticleCreate;
