import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import SalepointForm from '../SalepointForm/SalepointForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { Utils } from '../../../services/';

import { loadSalepoint, updateSalepoint } from '../../../redux/modules/salepoints';

class SalepointEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const salepointId = this.props.match.params.salepointId;
        // Load salepoints on component mount
        this.props.dispatchLoadSalepoint(salepointId);
    }

    submit(values) {
        this.props.dispatchUpdateSalepoint(values);
    }

    render() {
        const { salepoint } = this.props.salepoints;
        const { t } = this.props;
        const default_lang = Utils.default_lang();

        let title = <span>{t('salepoints.salepoint')}</span>;
        if (salepoint)
            title = (
                <span>
                    {t('salepoints.salepoint')}
                    <small> |&nbsp;{ t('salepoints.update_salepoint', { name: salepoint.name[default_lang] })} </small>
                </span>
            );

        const actions =
        <div>
            {salepoint &&
            <ButtonGroup>
                <Button tag={Link} to={ `/salepoints/show/${ salepoint._id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="SalepointEdit">
                <PageTitle icon="th" title={ title } actions={ actions } />

                {salepoint &&
                    <SalepointForm salepointId={salepoint._id} onSubmit={this.submit} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadSalepoint: (salepointId) => loadSalepoint(salepointId),
    dispatchUpdateSalepoint: (salepoint) => updateSalepoint(salepoint)
}

const mapStateToProps = state => ({
    salepoints: state.salepoints,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(SalepointEdit))
