import React from "react";
import FormLayout from '../FormLayout';
import TextField from './TextField';
import { Field } from 'redux-form';
import { Utils } from '../../services';

/** Class representing a PDF layout custom text field. */
class PasswordField extends TextField {

    constructor(properties) {
        super({
            ...(PasswordField.getDefaultOptions()),
            ...properties,
        });

        this.type = FormLayout.PASSWORD;
    }

    /**
     * Return this field preview input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getInput(props) {
        return (
            <Field
                component="input"
                type="password"
                name={this.id}
                required={!!this.required}
                className="form-control"
                autoComplete="new-password"
            />
        );
    }

    /**
     * Render a value
     *
     * @param {Any} value
     * @param {Object} context: an object with the following keys:
     *                   - lang (mandatory)
     *                   - t (mandatory)
     *                   - format (optional)
     *
     * @return {String}
     */
    renderValue(value, context) {
        return '&bull;&bull;&bull;&bull;&bull;&bull;';
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'key';
    }
}

export default PasswordField;
