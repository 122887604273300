import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Widget from '../Widget/Widget';

import './OthersLastEvents.css';

class OthersLastEvents extends Component<{}> {
    render() {
        return (
            <Widget title="Derniers films créés" icon="film">
            </Widget>
        );
    }
}

export default OthersLastEvents;

