import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import BucketlistForm from '../BucketlistForm/BucketlistForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { startCreateBucketlist, createBucketlist } from '../../../redux/modules/bucketlists';

const BucketlistCreate = () => {
    const { t }    = useTranslation();
    const dispatch = useDispatch();
    const {
        bucketlistSuccessfullyCreated,
        bucketlist
    } = useSelector(state => state.bucketlists);

    useEffect(() => {
        dispatch(startCreateBucketlist());
    }, [dispatch])

    const submit = (values) => {
        dispatch(createBucketlist(values));
    }

    let title = <span>{t('bucketlists.add')}</span>;

    const actions =
        <Button tag={Link} to="/bucketlists" color="warning" size="sm">
            <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
        </Button>

    return (
        <div className="BucketlistCreate">
            <PageTitle icon="battery-half" title={ title } actions={ actions } />

            {(bucketlistSuccessfullyCreated && bucketlist) &&
                <Redirect to={`/bucketlists/edit/${bucketlist._id}`}/>
            }

            <BucketlistForm bucketlist={bucketlist} onSubmit={submit} isCreation="true" />
        </div>
    );
}

export default BucketlistCreate;
