import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { TabContent, TabPane } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import TranslatableField from '../../../components/TranslatableField/TranslatableField';
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import SalepointsField from '../../../components/SalepointsField/SalepointsField';
import CashregistersField from '../../../components/CashregistersField/CashregistersField';
import RolesField from '../../../components/RolesField/RolesField';
import CategoriesField from '../../../components/CategoriesField/CategoriesField';
import { loadSalepoints } from '../../../redux/modules/salepoints';
import { loadCashregisters } from '../../../redux/modules/cashregisters';
import { Utils } from '../../../services';
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames';

import moment from 'moment';
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('fr');
momentLocalizer();

let ChannelForm = (props) => {
    const [selectedSalepointId, setSelectedSalepointId] = useState(props.initialValues?.salepoint_id);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadSalepoints());
        dispatch(loadCashregisters());
    }, [ dispatch ]);

    const { handleSubmit, pristine, submitting, isCreation } = props;

    const { salepoints }         = useSelector(state => state.salepoints);
    const { cashregisters }      = useSelector(state => state.cashregisters);
    const { isLoading, channel } = useSelector(state => state.channels);

    const { t } = useTranslation();

    const onSelectedSalepoint = (salepointId) => {
        setSelectedSalepointId(salepointId);
        return true;
    }

    const availableCashregisters = (cashregisters || []).filter(c => c.salepoint_id === selectedSalepointId);
    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("channels.informations")}</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t("channels.name")}</Label>
                                            <Col sm={12}>
                                                <Field required name="name" className="form-control" type="text" placeholder={t("channels.name_placeholder")} component="input"/>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row>
                                            <Label for="slug" sm={12}>{t("channels.slug")}</Label>
                                            <Col sm={12}>
                                                <Field required component="input" className="form-control" type="text" name="slug" id="slug" autoComplete="slug" placeholder={t("channels.slug")} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row>
                                            <Label for="salepoint_id" sm={12}>{t("channels.salepoint")}</Label>
                                            <Col sm={12}>
                                                <SalepointsField
                                                    name="salepoint_id"
                                                    salepoints={ salepoints }
                                                    single
                                                    onChange={ onSelectedSalepoint }
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        { selectedSalepointId && (
                                        <FormGroup row>
                                            <Label for="cashregister_id" sm={12}>{t("channels.cashregister")}</Label>
                                            <Col sm={12}>
                                                <CashregistersField name="cashregister_id" cashregisters={ availableCashregisters } single />
                                            </Col>
                                        </FormGroup>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row>
                                            <Label for="only_for_roles" sm={12}>{t("channels.only_for_roles")}</Label>
                                            <Col sm={12}>
                                                <RolesField name="only_for_roles" />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <Row>
                                            <Col>
                                                <FormGroup row>
                                                    <Label for="active_from" sm={12}>{t('channels.active_from')}</Label>
                                                    <Col sm={12}>
                                                        <Field
                                                            component={DateTimePicker}
                                                            type="text"
                                                            name="active_from"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup row>
                                                    <Label for="active_until" sm={12}>{t("channels.active_until")}</Label>
                                                    <Col sm={12}>
                                                        <Field
                                                            component={DateTimePicker}
                                                            type="text"
                                                            name="active_until"
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <br/>
                <Row>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
        </Form>
    );

}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ChannelForm = reduxForm({
  form: 'channelForm', // a unique identifier for this form
  enableReinitialize: true
})(ChannelForm);

ChannelForm = connect(
  state => ({
    initialValues: state.channels.channel, // pull initial values from channels reducer
    channels: state.channels,
}), {}
)(ChannelForm)


export default ChannelForm;
