import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { FillBar } from '../../../../components';
import './ProgressBarScreenings.css';

const ProgressBarScreenings = ({ data }) => {
    const { t } = useTranslation();

    const processedData = useMemo(() => {
        if (data) {
            data = _.cloneDeep(data);
            if (data.totalConfirmed)
                delete data.totalConfirmed;
            let totals = {
                confirmed: 0,
                unconfirmed: 0,
                available: 0,
                total_capacity: 0
            }
            const buckets = data.filter(d => d.total_capacity > 0).map((d) => {
                totals.confirmed += d.confirmed
                totals.unconfirmed += d.unconfirmed
                totals.available += d.available
                totals.total_capacity += d.total_capacity
                return {
                    label: d._id,
                    data: [
                        {
                            width: d.confirmed * 100 / d.total_capacity,
                            color: "#B82424",
                            text: t("screenings.list.confirmed"),
                            baseInt: d.confirmed
                        },
                        {
                            width: d.unconfirmed * 100 / d.total_capacity,
                            color: "#A3A300",
                            text: t("screenings.list.unconfirmed"),
                            baseInt: d.unconfirmed
                        },
                        {
                            width: d.available * 100 / d.total_capacity,
                            color: "#246924",
                            text: t("screenings.list.available"),
                            baseInt: d.available
                        },
                        {
                            width: 0,
                            color: "black",
                            text: t("screenings.list.total"),
                            baseInt: d.total_capacity
                        }
                    ]
                }
            });
            if (buckets.length > 1)
                buckets.push({
                    label: "total",
                    data: [
                        {
                            width: totals.confirmed * 100 / totals.total_capacity,
                            color: "#B82424",
                            text: t("screenings.list.confirmed"),
                            baseInt: totals.confirmed
                        },
                        {
                            width: totals.unconfirmed * 100 / totals.total_capacity,
                            color: "#A3A300",
                            text: t("screenings.list.unconfirmed"),
                            baseInt: totals.unconfirmed
                        },
                        {
                            width: totals.available * 100 / totals.total_capacity,
                            color: "#246924",
                            text: t("screenings.list.available"),
                            baseInt: totals.available
                        },
                        {
                            width: 0,
                            color: "black",
                            text: t("screenings.list.total"),
                            baseInt: totals.total_capacity
                        }
                    ]
                });
            return buckets;
        } else return false
    }, [data])

    return (
        <div className="ProgressBarScreenings">
            {processedData &&
                processedData.map((p, i) =>
                    <FillBar bars={p.data} key={i} clickable label={p.label} />
                )
            }
        </div>
    );
};

export default ProgressBarScreenings;
