import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { Utils } from '../../../services';
import { PageTitle, BucketsForm, BucketsTable, HelpTooltips } from '../../../components';
import { loadTickettypes, updateBucketlist, addBucket } from '../../../redux';
//import { /*addBucket, removeBucket, updateBucketlistBuckets, updateBucketsKeys*/ } from '../../../redux/modules/bucketlists';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css'

moment.locale('fr');
momentLocalizer();

let BucketlistForm = ({ isCreation, handleSubmit, submitting, bucketlist }) => {
    const { t }         = useTranslation();
    const dispatch      = useDispatch();
    const { isLoadingOne } = useSelector(state => state.bucketlists);

    const [showTable, setShowTable] = useState(true);

    useEffect(() => {
        dispatch(loadTickettypes());
    }, []);

    const updateBucketOrder = (buckets) => {
        dispatch(updateBucketlist({ ...bucketlist, buckets }));
    }

    const add = () => {
        dispatch(addBucket());
    }

    const remove = (_id) => {
        dispatch(updateBucketlist({
            ...bucketlist,
            buckets: bucketlist.buckets.filter(b => b._id !== _id)
        }));
    }

    const showBucketsForm = React.useMemo(() => {
        if (showTable)
            return <BucketsTable buckets={(bucketlist || {}).buckets} />;

        return (
            <BucketsForm
                buckets={(bucketlist || {}).buckets}
                remove={remove}
                updateBucketOrder={updateBucketOrder}
        />);
    }, [showTable, bucketlist]);

    const currency = Utils.currency()

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("bucketlists.informations")}</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row >
                                            <Label for="name" sm={3}>{t("bucketlists.name")}</Label>
                                            <Col sm={9}>
                                                <Field data-tip data-for='nametip' required component="input" className="form-control" type="text" name="name" id="name" autoComplete="name" placeholder={t("bucketlists.name_placeholder")} />
                                                <HelpTooltips id='nameTip' value='name' />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <FormGroup row>
                                            <Label for="_id" sm={3} className="text-right" >{t("bucketlists._id")}</Label>
                                            <Col sm={9} data-tip data-for='tktIdTip'>
                                                <Field
                                                    required
                                                    component="input"
                                                    className="form-control"
                                                    type="text"
                                                    name="_id"
                                                    id="_id"
                                                    autoComplete="_id"
                                                    disabled={!isCreation}
                                                    placeholder={t("bucketlists._id")}
                                                    onInvalid={e => e.target.setCustomValidity(t("common.invalid_id_input"))}
                                                    onInput={e => e.target.setCustomValidity(t(""))}
                                                    pattern={isCreation ? "[A-Za-z0-9_]+" : ".+"}
                                                />
                                            </Col>
                                            <HelpTooltips id='tktIdTip' value='tkt_id' />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {(!isCreation) &&
                <PageTitle tag="h5" icon="battery-half" title={t('bucketlists.buckets')} />
            }
            {(!isCreation && bucketlist?.buckets) &&
                <div>
                    <Row>
                        <Col className="text-center">
                            <ButtonGroup>
                                <Button style={{height: "33.5px"}} color={showTable ? 'primary' : 'dark'} onClick={() => setShowTable(true)}>
                                    <i className='fa fa-eye'></i> { t('bucketlists.preview') }
                                </Button>
                                <Button style={{height: "33.5px"}} color={!showTable ? 'primary' : 'dark'} onClick={() => setShowTable(false)}>
                                    <i className='fa fa-edit'></i> { t('bucketlists.edit') }
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    { showBucketsForm }
                </div>
            }
            <Row>
                <Col className="text-center">
                    <br />
                    <ButtonGroup>
                        {!isCreation && !showTable &&
                        <Button color="info" disabled={submitting || isLoadingOne} onClick={add}>
                            {isLoadingOne ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-plus"></i>&nbsp;
                                    {t("bucketlists.add_bucket")}
                                </span>
                            }
                        </Button>
                        }
                        <Button type="submit" color="success" disabled={submitting || isLoadingOne}>
                            {isLoadingOne ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    );
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
BucketlistForm = reduxForm({
    form: 'bucketlistForm', // a unique identifier for this form
    enableReinitialize: true,
    destroyOnUnmount: false
})(BucketlistForm)

// You have to connect() to any reducers that you wish to connect to yourself
BucketlistForm = connect(
    state => ({
        initialValues: state.bucketlists.bucketlist, // pull initial values from bucketlists reducer
        bucketlists: state.bucketlists,
    }), {}
)(BucketlistForm)

export default BucketlistForm;
