import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";
import Switch from './Switch/Switch';
import SearchForm from './SearchForm/SearchForm';
import MyLastEvents from '../../components/MyLastEvents/MyLastEvents';
import OthersLastEvents from '../../components/OthersLastEvents/OthersLastEvents';

import './Dashboard.css';

class Dashboard extends Component<{}> {
    render() {
        const { t } = this.props;
        return (
            <div className="Dashboard">
                <Row className="dashboardHeader">
                    <Col md="12">
                        <Row>
                            <Col md="9">
                                <Switch />
                            </Col>
                            <Col md="3">
                                <Button tag={Link} to="/events/new" color="success" size="sm">
                                    <i className="fa fa-plus"></i>&nbsp;
                                    {t('users.add_a_user')}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md="9">
                        <SearchForm />
                    </Col>
                    <Col md="3">
                        <Row>
                            <MyLastEvents />
                        </Row>
                        <Row>
                            <OthersLastEvents />
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = state => ({
    events: state.events
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(Dashboard));
