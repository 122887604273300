import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../../../services/';
import ItemsList from '../../../../components/ItemsList/ItemsList';
import _ from 'lodash';

import './ArticlecategoriesBrowser.css';

const ArticlecategoriesBrowser = ({ articlecategories, active, onSelect, onEdit }) => {
    const { t } = useTranslation();
    if (!articlecategories)
        return null;

    const defaultLang = Utils.default_lang();

    const items = [];
    if (active && articlecategories.length > 1)
        items.push({
            key: 'null',
            title: t('articles.show_all_categories'),
            icon: 'home',
            depth: 0
        });

    let parents = [];
    let base    = active;
    let depth   = 0;
    let parent  = null;
    while(parent = articlecategories.find(c => c._id === (base || {}).parent)) {
        if (parent)
            parents.unshift(parent);
        base   = parent;
        depth += 1;
    }
    parents.map((c, i) => {
        items.push({
            key: c._id,
            title: c.name[defaultLang],
            icon: 'folder-o',
            depth: i,
            selection: c
        });
    });

    if (active) {
        items.push({
            key: active._id,
            title: active.name[defaultLang],
            icon: 'folder-o',
            active: true,
            depth: depth,
            selection: active
        });
        depth++;
    }

    _.sortBy(
        articlecategories.filter(c => {
            if (!active)
                return !c.parent;

            return c.parent === active._id;
        }),
        c => c.name[defaultLang]
    ).map(c => {
        items.push({
            key: c._id,
            title: c.name[defaultLang],
            icon: 'folder-o',
            active: (active && (active._id === c._id)),
            depth: depth,
            selection: c
        });
    });

    return (
        <div className="ArticlecategoriesBrowser">
            <ItemsList
                title={ t('articles.filter_by_category') }
                items={ items }
                onSelect={ onSelect }
                onEdit={ onEdit }
            />
        </div>
    );
}

export default ArticlecategoriesBrowser;
