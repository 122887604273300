import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, ButtonGroup, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NavigationSettings from './NavigationSettings/NavigationSettings';
import ScreensSettings from './ScreensSettings/ScreensSettings';
import FeaturesSettings from './FeaturesSettings/FeaturesSettings';
import ThemeSettings from './ThemeSettings/ThemeSettings';
import ConfigSettings from './ConfigSettings/ConfigSettings';
import ItemsList from '../ItemsList/ItemsList';
import { copyMobileSettings } from '../../redux';
import QRCode from "react-qr-code";

import './SettingsMobileField.css';
import appIconImage from '../../assets/images/ticketack_viewer_icon.png';

const SettingsMobileField = (props) => {
    const { tickettypes, salepoints, articles, articlecategories, postCategories, places } = props;

    const [env, setEnv] = useState('production');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { t }       = useTranslation();
    const dispatch    = useDispatch();
    const { setting } = useSelector(state => state.settings);

    const [activeSection, showSection] = useState('navigation');

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    }

    const copySettings = (src, destination) => {
        dispatch(copyMobileSettings(src, destination));
    }

    const showModal = () => setIsModalOpen(true);
    const hideModal = () => setIsModalOpen(false);

    const icon = section => {
        switch (section.id) {
            case 'navigation':
                return 'bars';
            case 'screens':
                return 'tablet';
            case 'features':
                return 'puzzle-piece';
            case 'theme':
                return 'paint-brush';
            case 'config':
                return 'gear';
        }
    }

    const sections = [
        { id: 'navigation' },
        { id: 'screens' },
        { id: 'features' },
        { id: 'theme' },
        { id: 'config' },
    ];

    const items = sections.map(section => ({
        key: section.id,
        selection: section.id,
        title: t(`settings.mobile.sections.${section.id}`),
        icon: icon(section),
        active: section.id === activeSection,
        depth: 0
    }));

    const qrCodeContent = () => {
        if (!(setting.mobile[env])?.apiKey || !(setting.mobile[env])?.engineUri)
            return null;
        return JSON.stringify({
            env,
            apiKey: setting.mobile[env].apiKey,
            engineUri: setting.mobile[env].engineUri,
        });
    };

    return (
        <div className="SettingsMobileField">
            <br/>
            <div className="wrapper-0">
                <Row>
                    <Col>
                        <div className="d-flex justify-content-between">
                            <ButtonGroup>
                                <Button onClick={() => setEnv('production') } color={ env === 'production' ? 'info' : 'secondary' }>
                                    <i className="fa fa-rocket"></i>{' '}
                                    { t('settings.mobile.production_env') }
                                </Button>
                                <Button onClick={() => setEnv('preprod') } color={ env === 'preprod' ? 'info' : 'secondary' }>
                                    <i className="fa fa-comments"></i>{' '}
                                    { t('settings.mobile.preprod_env') }
                                </Button>
                                <Button onClick={() => setEnv('testing') } color={ env === 'testing' ? 'info' : 'secondary' }>
                                    <i className="fa fa-pencil"></i>{' '}
                                    { t('settings.mobile.testing_env') }
                                </Button>
                                <ButtonDropdown
                                    toggle="dropdown"
                                    toggle={toggleDropdown}
                                    isOpen={ isDropdownOpen }
                                >
                                    <DropdownToggle color='secondary' split className="no-border-after">
                                        <i className="fa fa-bars"></i>{' '}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem header>
                                            <h4>{ t('settings.mobile.copy_testing') }</h4>
                                        </DropdownItem>
                                        <DropdownItem onClick={ () => copySettings('testing', 'preprod') } color="link">
                                            <i className="fa fa-arrow-right"></i>{' '}
                                            { t('settings.mobile.to_preprod') }
                                        </DropdownItem>
                                        <DropdownItem onClick={ () => copySettings('testing', 'production') } color="link">
                                            <i className="fa fa-arrow-right"></i>{' '}
                                            { t('settings.mobile.to_production') }
                                        </DropdownItem>
                                        <DropdownItem header>
                                            <h4>{ t('settings.mobile.copy_preprod') }</h4>
                                        </DropdownItem>
                                        <DropdownItem onClick={ () => copySettings('preprod', 'testing') } color="link">
                                            <i className="fa fa-arrow-right"></i>{' '}
                                            { t('settings.mobile.to_testing') }
                                        </DropdownItem>
                                        <DropdownItem onClick={ () => copySettings('preprod', 'production') } color="link">
                                            <i className="fa fa-arrow-right"></i>{' '}
                                            { t('settings.mobile.to_production') }
                                        </DropdownItem>
                                        <DropdownItem header>
                                            <h4>{ t('settings.mobile.copy_production') }</h4>
                                        </DropdownItem>
                                        <DropdownItem onClick={ () => copySettings('production', 'preprod') } color="link">
                                            <i className="fa fa-arrow-right"></i>{' '}
                                            { t('settings.mobile.to_preprod') }
                                        </DropdownItem>
                                        <DropdownItem onClick={ () => copySettings('production', 'testing') } color="link">
                                            <i className="fa fa-arrow-right"></i>{' '}
                                            { t('settings.mobile.to_testing') }
                                        </DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </ButtonGroup>
                            <Button onClick={ showModal } color="info" className="ml-5">
                                <i className="fa fa-mobile"></i>{' '}
                                { t('settings.mobile.test_on_real_device') }
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ItemsList
                            horizontal
                            items={ items }
                            onSelect={ showSection }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                    { activeSection === 'navigation' && (
                        <NavigationSettings { ...props } env={ env } setting={ setting } />
                    )}
                    { activeSection === 'screens' && (
                        <ScreensSettings { ...props } env={ env } setting={ setting } />
                    )}
                    { activeSection === 'features' && (
                        <FeaturesSettings { ...props } env={ env } setting={ setting } />
                    )}
                    { activeSection === 'theme' && (
                        <ThemeSettings { ...props } env={ env } setting={ setting } />
                    )}
                    { activeSection === 'config' && (
                        <ConfigSettings { ...props } env={ env } setting={ setting } />
                    )}
                    </Col>
                </Row>
            </div>
            <Modal isOpen={isModalOpen} toggle={hideModal} >
                <ModalHeader toggle={hideModal}>
                    <i className="fa fa-mobile"></i>{' '}
                    {t('settings.mobile.test_on_real_device')}
                </ModalHeader>
                <ModalBody>
                    {!qrCodeContent() && (
                        <Row>
                            <Col>
                                <div className="m-3 text-center">{t('settings.mobile.first_configure_your_app')}</div>
                            </Col>
                        </Row>
                    )}
                    {qrCodeContent() && (
                        <>
                            <Row>
                                <Col md={12} lg={6}>
                                    <div className="step">
                                        <div className="big-number">1</div>
                                        <div className="text">{t('settings.mobile.download_the_app')}</div>
                                        <img src={appIconImage} className="appIcon" alt="appIcon" />
                                    </div>
                                </Col>
                                <Col md={12} lg={6}>
                                    <div className="step">
                                        <div className="big-number">2</div>
                                        <div className="text">{t('settings.mobile.scan_this_qr_code')}</div>
                                        <div className="m-1">
                                            <QRCode value={ qrCodeContent() } />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="step mt-3 mb-3">
                                        <div className="text">
                                            <i className="fa fa-info-circle"></i>{' '}
                                            {t('settings.mobile.how_to_reset_the_app')}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={hideModal}>
                        <i className="fa fa-close"></i>&nbsp;
                        {t('common.cancel')}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default SettingsMobileField;
