import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, Button, Card, CardBody, CardTitle } from 'reactstrap';
import { Field, reduxForm } from 'redux-form'
import DateTimePicker from '../../../components/DateTimePicker/DateTimePicker';
import { loadPlaces } from '../../../redux/modules/places';
import { setFilters } from '../../../redux/modules/stats';
import "./StatsForm.css";
import SelectField from '../../../components/SelectField/SelectField'

let StatsForm = ({ submitting, isLoading, handleSubmit, onSubmittedForm }) => {
    const { t }                             = useTranslation();
    const dispatch                          = useDispatch();
    const { places }                        = useSelector(state => state.places);
    const { filters }                       = useSelector(state => state.stats);
    const [ selectCinema, setSelectCinema ] = useState(null);
    const [ flag, setFlag ]                 = useState(true);

    //Onload, get places
    useEffect(() => {
        dispatch(loadPlaces());
    }, []);

    //When filters are updated in redux, we call the server for stats
    useEffect(() => {
        if (filters && flag){
            onSubmittedForm(filters);
            setFlag(false);
        }
    }, [filters]);

    //When cinema is selected on option tag, change state selectCinema
    //This will automatically update placesOptions (line 58)
    const onCinemaSelect = (value) => {
        setSelectCinema(value);
    }

    //when form submitted, update filters in redux
    const onSubmit = values => {
        dispatch(setFilters(values.filters));
        onSubmittedForm(values.filters);
    };

    //get cinemas list and remove duplicates with "...new Set"
    let cinemas = [...new Set(places.map(place => {
        return place.cinema
    }))];

    //Generate cinemaOptions array for options of select cinema
    const cinemaOptions = cinemas.map(place => {
        return {
            key: place,
            text: place,
            value: place
        };
    });

    //Generate placesOptions array for options of select places
    const placesOptions = places.filter(place => {

        //If selectCinema is set in state hook, return only places that are at selectCinema in places array
        return !selectCinema || (place.cinema === selectCinema);
    }).map((place, index) => {
        //then return all places (Either all of them or from only one cinema)
        return {
            key: place.name + index,
            text: place.name,
            value: place.name
        };
    });

    return (
        <div className="StatsForm">
            <Card id="statsFormCard">
                <CardBody>
                    <CardTitle><i className="fa fa-filter"></i>&nbsp;{t("statstypes.form.filters")}</CardTitle>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="rowStats">

                            <Col className="col-12 col-sm-6">
                                <label>{t("statstypes.form.date_start")}</label><br />
                                <Field 
                                    component={DateTimePicker}
                                    name="filters.start_date"
                                    className="statsFormPicker"
                                />
                            </Col>

                            <Col className="col-12 col-sm-6">
                                <label>{t("statstypes.form.date_end")}</label><br />
                                <Field
                                    component={DateTimePicker}
                                    name="filters.end_date"
                                    className="statsFormPicker"
                                />
                            </Col>

                            <Col className="col-12 col-sm-6">
                                <label>{t("statstypes.form.cinema")}</label><br />
                                <SelectField
                                    options={cinemaOptions}
                                    onSelectChange={e => onCinemaSelect(e)}
                                    placeholder={t("statstypes.form.all_cinemas")}
                                    name="filters.cinema"
                                    clearable
                                    className="statsFormPicker"
                                />
                            </Col>

                            <Col className="col-12 col-sm-6" name="filters.hall">
                                <label>{t("statstypes.form.hall")}</label><br />
                                <SelectField
                                    options={placesOptions}
                                    name="filters.hall"
                                    placeholder={t("statstypes.form.all_halls")}
                                    clearable
                                    className="statsFormPicker"
                                />
                            </Col>

                            <Col className="col-12">
                                <Button type="submit" color="success" className="float-right" disabled={ submitting || isLoading}>
                                    {isLoading ?
                                        <span>
                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                        </span>
                                        :
                                        <span>
                                            {t('statstypes.form.show')}&nbsp;
                                            <i className="fa fa-arrow-circle-right"></i>&nbsp;
                                        </span>
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

const mapDispatchToProps = {}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
StatsForm = reduxForm({
    form: 'statsForm', // a unique identifier for this form
})(StatsForm)

// You have to connect() to any reducers that you wish to connect to yourself
StatsForm = connect(
    state => ({
        initialValues: state.stats, // pull initial values from tasks reducer
        stats: state.stats,
        places: state.places,
    }), mapDispatchToProps
)(StatsForm)

export default StatsForm;
