import RestClient from './RestClient';
import { Channel } from '../../models';

class Channels extends RestClient {
    constructor() {
        super({
            "model": Channel,
            "entryPoint": "channel",
            "resource": "channel",
            "resources": "channels",
            "id_field": "id"
        });
    }
}

const ChannelsApi = new Channels();

export default ChannelsApi;
