import React, { useState }  from "react";
import { useTranslation } from 'react-i18next';
import {
    Card,
    CardText,
    CardFooter,
    Row,
    Col,
    Label,
    Input,
    ButtonGroup,
    Button
} from 'reactstrap';
import { TranslatableFieldWithInput } from '../../../../../../components';

import './ChoicesField.css';

const ChoicesField = (props) => {
    const { t } = useTranslation();
    const { choices, onAdd, onRemove, onEdit } = props;

    const addChoice = () => {
        return onAdd && onAdd();
    };

    const removeChoice = (index) => {
        return onRemove && onRemove(index);
    };

    const setChoiceLabel = (index, label) => {
        return onEdit && onEdit(index, label);
    };

    return (
        <Card className="ChoicesField h-auto">
            <CardText>
                <Row>
                    <Col>
                        {choices?.map((choice, i) => (
                            <div className="choiceWrapper">
                                <TranslatableFieldWithInput name={choice.label} type="text" onChange={ label => setChoiceLabel(i ,label) } />
                                <Button type="button" color="danger" onClick = { () => removeChoice(i) }>
                                    <span>
                                        <i className="fa fa-trash"></i>
                                    </span>
                                </Button>
                            </div>
                        ))}
                    </Col>
                </Row>
            </CardText>
            <CardFooter>
                <Row>
                    <Col>
                        <Button type="button" color="info" onClick = { () => addChoice() }>
                            <span>
                                <i className="fa fa-plus"></i>&nbsp;{t("common.add")}
                            </span>
                        </Button>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    );
};

export default ChoicesField;
