import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
  Input,
  ButtonGroup
} from "reactstrap";

const randomColor = require("randomcolor");

const SeatDetail = ({ seat, gridCells, onSubmit, onClose }) => {
  const { t }                       = useTranslation();
  const [zoneCat, setZoneCat]       = useState(seat.zoneCat);
  const [seatType, setSeatType]     = useState(seat.additional_class_names);
  const [seatNumber, setSeatNumber] = useState(seat.seat_number);
  const [row, setRow]               = useState(seat.row);
  const [availableCat]              = useState(_.without(_.uniq(_.map(gridCells, "zoneCat")), ""));

  useEffect (() => {
    setZoneCat(seat.zoneCat)
    setSeatType(seat.additional_class_names)
    setSeatNumber(seat.seat_number)
    setRow(seat.row)
  },[seat])

  const chooseCatName = (index) => {
    setZoneCat(availableCat[index]);
  }

  const submitData = () => {
    const randomClr = randomColor({
      luminosity: "bright",
      hue: "yellow",
    });
    const index = gridCells.findIndex((e) => e.x === seat.x && e.y === seat.y);

    if (index > -1) {
      const data = gridCells[index];
      data.additional_class_names = seatType;
      data.row         = row;
      data.seat_number = seatNumber;
      data.placing.row         = data.row;
      data.placing.seat_number = seatNumber;
      if (data.zoneCat !== zoneCat) {
        data.zoneCat = zoneCat;
        data.borderColor = randomClr;
      }
      gridCells[index] = data;
    }
    onSubmit(gridCells);
  };

  /**
   * Handle seat type change
   * @param {*} e
   */
  const handleSelect = (e) => {
    setSeatType(e);
  };

  const seatTypes = [
      'classic', 'sofa',
      'sofa-middle', 'sofa-left',
      'sofa-right', 'foldable-seat',
      'wheelchair'
  ];

  return (
    <div className="ZoneOptions">
      <FormGroup row>
        <Label for="category" sm={3}>
          {t("places.map.category")}
        </Label>
        <Col sm={9}>
          <InputGroup>
            {availableCat.map((data, index) => {
              return (
                <InputGroupText key={index} className = {'zoneOptionMargin'}>
                  <Button onClick={() => chooseCatName(index)}>{data}</Button>
                </InputGroupText>
              );
            })}
            <Input
              type="text"
              value={zoneCat}
              placeholder={"Zone Seats Category"}
              onChange={(event) => setZoneCat(event.target.value)}
            />
          </InputGroup>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="category" sm={3}>
          {t("places.map.seattype")}
        </Label>
        <Col sm={9}>
          <InputGroup>
            { seatTypes.map((type, index) => (
            <InputGroupText key = {index} className = {'zoneOptionMargin'}>
              <Button onClick={() => handleSelect(type)}>
                {t(`places.map.${type}`)}
              </Button>
            </InputGroupText>
            ))}
            <Input
              type="text"
              value={seatType}
              placeholder={"Zone Seats Type"}
              onChange={(event) => setSeatType(event.target.value)}
            />
          </InputGroup>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="category" sm={3}>
          {t("places.map.seatNumber")}
        </Label>
        <Col sm={9}>
          <InputGroup>
            <Input
              type="text"
              value={seatNumber}
              placeholder={t("places.map.seatNumber")}
              onChange={(event) => setSeatNumber(event.target.value)}
            />
          </InputGroup>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label for="row" sm={3}>
          {t("places.map.seatRow")}
        </Label>
        <Col sm={9}>
          <InputGroup>
            <Input
              type="text"
              value={ row }
              placeholder={t("places.map.row")}
              onChange={(event) => setRow(event.target.value)}
            />
          </InputGroup>
        </Col>
      </FormGroup>

      {/* <div className="seatTypeTitle">Seat Type</div>

            <Dropdown isOpen={ dropdownOpen } toggle={ toggle }>
                <DropdownToggle caret>
                    { t(`places.map.${seatType}`) }
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={ () => handleSelect("normal") }>
                        { t("places.map.normal") }
                    </DropdownItem>
                    <DropdownItem onClick={ () => handleSelect("left") }>
                        { t("places.map.left") }
                    </DropdownItem>
                    <DropdownItem onClick={ () => handleSelect("middle") }>
                        { t("places.map.middle") }
                    </DropdownItem>
                    <DropdownItem onClick={ () => handleSelect("right") }>
                        { t("places.map.right") }
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown> */}

            <Row>
                <Col className="text-right">
                    <br />
                    <ButtonGroup>
                        <Button color="warning" onClick={ onClose }>
                            <span>{t("common.cancel")}</span>
                        </Button>
                    </ButtonGroup>
                </Col>
                <Col className="text-left">
                    <br />
                    <ButtonGroup>
                        <Button color="success" onClick={ submitData }>
                            <span>{t("common.save")}</span>
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>  

    </div>
  );
};

export default SeatDetail;
