import RestClient from './RestClient';
import User from '../../models/User';

class Users extends RestClient {
    constructor() {
        super({
            "model": User,
            "entryPoint": "kuser",
            "resource": "user",
            "resources": "users",
            "id": "id"
        });
    }

    inactive() {
        return this.GET('/kuser/inactive')
            .then(response => response.json())
            .then(json => {
                if (json.users) {
                    json.users = json.users.map(u => new User(u));
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    disable(user_id) {
        let url = `/kuser/disable/id/${user_id}`;
        return this.POST(url)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    enable(user_id) {
        let url = `/kuser/enable/id/${user_id}`;
        return this.POST(url)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    hide(user_id) {
        let url = `/kuser/hide/id/${user_id}`;
        return this.POST(url)
            .then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    addSector(params) {
        let url = `/kuser/add_sector/id/${params.user_id}`;
         return this.PUT( url, { 'user_id': params.user_id, 'sector_id': params.sector_id }
            ).then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    removeSector(params) {
        let url = `/kuser/remove_sector/id/${params.user_id}`;
        return this.PUT( url, { 'user_id': params.user_id, 'sector_id': params.sector_id }
            ).then(response => response.json())
            .then(json => {
                if (json.user) {
                    json.user = new User(json.user);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const UsersApi = new Users();

export default UsersApi;
