import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import TktInstanceForm from '../TktInstanceForm/TktInstanceForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';
import { loadTktInstance, updateTktInstance } from '../../../redux/modules/tktInstances';

class TktInstanceEdit extends Component<{}> {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const tktInstanceId = this.props.match.params.tktInstanceId;
        // Load tktInstances on component mount
        this.props.dispatchLoadTktInstance(tktInstanceId);
    }

    submit(values) {
        this.props.dispatchUpdateTktInstance(values);
    }

    render() {
        const { tktInstance } = this.props.tktInstances;
        const { t } = this.props;

        let title = <span>{t('tktInstances.tktInstance')}</span>;
        if (tktInstance)
            title = (
                <span>
                    {t('tktInstances.tktInstance')}
                    <small> |&nbsp;{ t('tktInstances.update_tktInstance', { name: tktInstance.name })} </small>
                </span>
            );

        const actions =
        <div>
            {tktInstance &&
            <ButtonGroup>
                <Button tag={Link} to={ `/tktInstances/show/${ tktInstance.id }` } color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t('common.back')}
                </Button>
            </ButtonGroup>
            }
        </div>

        return (
            <div className="TktInstanceEdit">
                <PageTitle icon="tktInstance-o" title={ title } actions={ actions } />

                {tktInstance &&
                    <TktInstanceForm tktInstanceId={tktInstance.id} onSubmit={this.submit} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadTktInstance: (tktInstanceId) => loadTktInstance(tktInstanceId),
    dispatchUpdateTktInstance: (tktInstance) => updateTktInstance(tktInstance)
}

const mapStateToProps = state => ({
    tktInstances: state.tktInstances,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(TktInstanceEdit))
