import React from "react";
import PdfLayout from '../PdfLayout';
import PdfLayoutWidget from './PdfLayoutWidget';
import { Screen } from '../../services';

import imagePlaceholder from '../../assets/images/imagePlaceholder.png';

/** Class representing a PDF layout image widget. */
class ImageWidget extends PdfLayoutWidget {

    constructor(properties) {
        super({
            ...(ImageWidget.getDefaultOptions()),
            ...properties,
            type: PdfLayout.IMAGE,
            subType: PdfLayout.CUSTOM
        });
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            position: { x: 2, y: 2, z: 0 },
            dimensions : { width: 4, height: 4 },
            transform: { rotation: 0 },
            options: {
                style: {
                    align: 'center',
                    objectFit: "contain",
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            PdfLayout.OPTION_IMAGE,
            PdfLayout.OPTION_OBJECT_FIT,
            PdfLayout.OPTION_ALIGNMENT,
            PdfLayout.OPTION_PADDING,
        ]);
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        const { inEdition } = props;
        let imageStyle = {};
        if (this.options.style?.objectFit === 'cover') {
            imageStyle = {
                width: '100%',
                height: '100%',
                objectFit: this.options.style?.objectFit
            };
        } else if (this.options.style?.objectFit === 'contain') {
            imageStyle = {
                width: '100%',
                height: '100%',
                objectFit: this.options.style?.objectFit
            };
        } else if (this.options.style?.objectFit === 'none') {
            imageStyle = {
                width: '100%',
                height: '100%',
            };
        }

        return (
            <div style={{
                textAlign: this.options?.style?.align,
                width: Screen.cmToPx(this.dimensions?.width),
                height: Screen.cmToPx(this.dimensions?.height),
                backgroundColor: this.options?.style?.backgroundColor,
                border: `${this.options?.style?.borderWidth}px ${this.options?.style?.borderStyle} ${this.options?.style?.borderColor}`,
                borderRadius: `${this.options?.style?.borderRadius || 0}px`,
                paddingTop: `${Screen.cmToPx(this.options?.style?.padding?.top || 0)}px`,
                paddingRight: `${Screen.cmToPx(this.options?.style?.padding?.right || 0)}px`,
                paddingBottom: `${Screen.cmToPx(this.options?.style?.padding?.bottom || 0)}px`,
                paddingLeft: `${Screen.cmToPx(this.options?.style?.padding?.left || 0)}px`,
            }}>
                <>
                    { this.options?.rfc2397_data && (
                        <img ref={props.ref} src={ this.options.rfc2397_data } style={ imageStyle } />
                    )}

                    { !this.options?.rfc2397_data && (
                        <div>
                            <img src={ imagePlaceholder } style={{
                                width: Screen.cmToPx(this.dimensions?.width) - (inEdition ? 2 : 0),
                                height: Screen.cmToPx(this.dimensions?.height) - (inEdition ? 2 : 0),
                            }} />
                        </div>
                    )}

                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0
                    }} />
                </>
            </div>
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'picture-o';
    }
}

export default ImageWidget;
