import RestClient from './RestClient';
import { Message } from '../../models';

class Messages extends RestClient {
    constructor() {
        super({
            "model": Message,
            "entryPoint": "message",
            "resource": "message",
        });
    }
}

const MessagesApi = new Messages();

export default MessagesApi;
