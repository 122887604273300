import BaseModel from './BaseModel';
import ArticleAttribute from './ArticleAttribute';
import Tag from './Tag';
import { Utils } from '../services/';
import _ from 'lodash';

/** Class representing an article. */
class Article extends BaseModel {
    static TYPE_PRODUCT              = 'product';
    static TYPE_MANUAL_DISCOUNT      = 'manual_discount';
    static TYPE_AUTO_DISCOUNT        = 'auto_discount';
    static TYPE_CODE_DISCOUNT        = 'code_discount';
    static DISCOUNT_TARGET_CART      = 'cart';
    static DISCOUNT_TARGET_LAST_ITEM = 'last_item';

    static discountTargets = () => {
        return [
            Article.DISCOUNT_TARGET_CART,
            Article.DISCOUNT_TARGET_LAST_ITEM
        ];
    };

    constructor(properties) {
        super(properties);

        this.tags       = (this.tags || []).map(t => new Tag(t));
        this.attributes = (this.attributes || []).map((a, index) => new ArticleAttribute({ ...a, index }));
        this.features   = this.attributes.filter(a => a.isFeature());
        this.extras     = this.attributes.filter(a => a.isExtra());
    }

    addAttribute(properties) {
        this.attributes.push(new ArticleAttribute({
            ...properties,
            index: this.attributes.length
        }));
    }

    /**
     * Prepare this object for update.
     * This is used to "normalize", if needed, some properties
     * before to send them.
     *
     * return{BaseModel}
     */
    prepareForUpdate() {
        const prepared = _.cloneDeep(this);
        const currency = Utils.currency();

        delete prepared.features;
        delete prepared.extras;
        delete prepared.pos_ids;

        // Ensure pos is an array of objects
        prepared.pos = prepared.pos.map(p => {
            return {_id: (p._id ? p._id : p)};
        });

        // Ensure tags is an array of objects
        prepared.tags = prepared.tags.map((t) => {
            return t._id ? t : {'_id': t }
        });

        // Ensure delivery infos are numbers
        prepared.delivery = prepared.delivery || {};
        ['width', 'length', 'height', 'weight'].map((key) => {
            prepared.delivery[key] = parseFloat(prepared.delivery[key] || 0);
        });

        prepared.variants.map((v) => {
            ['price', 'value', 'purchasing_price'].map((key) => {
                v[key][currency] = parseFloat(v[key][currency]);
            });
            ['vat', 'stock', 'stock_factor'].map((key) => {
                v[key] = parseFloat(v[key]);
            });
            if (v.stock == -1 || !_.isNumber(v.stock_factor) || isNaN(v.stock_factor))
                v.stock_factor = 0;
            if ('discount' in v) {
                v.discount.value.percentage = parseFloat(v.discount.value.percentage);
                v.discount.value.amount[currency] = parseFloat(v.discount.value.amount[currency]);
            }
            if (prepared.type === Article.TYPE_CODE_DISCOUNT && v.discount.code) {
                _.mapKeys(v.name, (value, key) => {
                    v.name[key] = v.discount.code;
                    if(prepared.variants.length === 1)
                        prepared.name[key] = v.discount.code;
                });
            }
        });

        prepared.attributes.map((a) => {
            delete a.index;

            if ('min_nb_choices' in a)
                a.min_nb_choices = parseInt(a.min_nb_choices);

            if ('max_nb_choices' in a) {
                if (a.max_nb_choices.length == 0)
                    a.max_nb_choices = null;
                else
                    a.max_nb_choices = parseInt(a.max_nb_choices);
            }

            let hasDefault = false;
            a.choices.map(choice => {
                choice.price[currency] = parseFloat(choice.price[currency]);
                choice.default         = !hasDefault && choice.default;
                hasDefault             = hasDefault || choice.default;
            });
        });

        return prepared;
    }
}

/**
 * Get the fields to get server side when we
 * ask for a listing
 */
Article.getListingFields = () => [
    '_id',
    'name',
    'pos',
    'posters',
    'sort_weight',
    'short_description',
    'variants',
    'tags'
];

export default Article;
