import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import PageTitle from '../../../components/PageTitle/PageTitle';
import { Utils } from '../../../services/';
import { loadUser, deleteUser } from '../../../redux/modules/users';

class UserDelete extends Component<{}> {
    componentDidMount() {
        const userId = this.props.match.params.userId;
        // Load users on component mount
        this.props.dispatchLoadUser(userId);
    }

    deleteUser(userId) {
        this.props.dispatchDeleteUser(userId);
    }

    render() {
        const { t, current_user } = this.props;
        const { user, isLoading, userSuccessfullyDeleted, hasLoadingError } = this.props.users;

        if (!current_user || !user || hasLoadingError)
            return null;

        const title = current_user.id === user.id ?
            <span> {t('users.delete_my_profile')} </span>
            :
            <span>
                {t('users.user')}
                <small> | {t('users.delete_user', {firstname: user.firstname, lastname: user.lastname})}</small>
            </span>

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/users" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;
                    {t('common.delete')}
                </Button>
            </ButtonGroup>
        </div>

        const confirmation = current_user.id === user.id ?
            t('users.confirm_delete_my_profile') :
            t('users.confirm_delete_this_profile');
        return (
            <div className="UserDelete">
                <PageTitle icon="users" title={ title } actions={ actions } />

                {userSuccessfullyDeleted &&
                    <Redirect to="/users"/>
                }

                {user &&
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>
                                        <i className="fa fa-warning"></i>&nbsp;
                                        { confirmation }
                                    </CardTitle>
                                    <CardText tag="div">
                                        <div><b>{t('users.firstname')}</b>: { user.firstname }</div>
                                        <div><b>{t('users.lastname')}</b>: { user.lastname }</div>
                                        <div><b>{t('users.role')}</b>: { Utils.roles(user.role).label }</div>
                                        <Row>
                                            <Col className="text-center">
                                                <Button tag={Link} to="/users" color="warning">
                                                    <i className="fa fa-arrow-left"></i>&nbsp;
                                                    {t('common.cancel')}
                                                </Button>&nbsp;
                                                <Button color="danger" onClick={(e) => this.deleteUser(user.id)} disabled={isLoading}>
                                                    {isLoading ?
                                                        <span>
                                                            &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                                        </span>
                                                        :
                                                        <span>
                                                            <i className="fa fa-trash"></i>&nbsp;
                                                            {t('common.delete')}
                                                        </span>
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadUser: (userId) => loadUser(userId),
    dispatchDeleteUser: (userId) => deleteUser(userId)
}

const mapStateToProps = state => ({
    users: state.users,
    current_user: state.auth.user,
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(UserDelete));
