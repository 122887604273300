import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Button } from 'reactstrap';
import { Redirect, Link } from "react-router-dom";
import SectionForm from '../SectionForm/SectionForm';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { startCreateSection, createSection } from '../../../redux/modules/sections';

class SectionCreate extends Component<{}> {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.props.dispatchStartCreateSection();
    }

    submit(values) {
        this.props.dispatchCreateSection(values);
    }

    render() {
        const { t }                              = this.props;
        const { sectionSuccessfullyCreated } = this.props.sections;

        let title = <span>{t('sections.add')}</span>;

        const actions =
            <Button tag={Link} to="/sections" color="warning" size="sm">
                <i className="fa fa-arrow-left"></i>&nbsp;{ t('common.back')}
            </Button>

        return (
            <div className="SectionCreate">
                <PageTitle icon="indent" title={ title } actions={ actions } />

                {sectionSuccessfullyCreated &&
                    <Redirect to="/sections"/>
                }

                <SectionForm onSubmit={this.submit} isCreation="true" />
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchStartCreateSection: (section) => startCreateSection(section),
    dispatchCreateSection: (section) => createSection(section)
}

const mapStateToProps = state => ({
    sections: state.sections,
    form: state.form
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('translations')(SectionCreate))
