import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from "react-router-dom";
import { Utils, Filters } from '../../../../services/';
import 'react-table-6/react-table.css'
import TableButtons from '../../../../components/TableButtons';
import Table from '../../../../components/Table/Table';

import './PostsTable.css';

const PostsTable = (props) => {
    const { posts } = props;

    const { t }        = useTranslation();
    const default_lang = Utils.default_lang();

    const columns = [{
        Header: t("posts.title"),
        accessor: "title",
        filterMethod: Filters.textFilterMethod(`title.${default_lang}`),
        Filter: Filters.textFilter,
        filterAll: true,
        Cell: row => (
            <div className="post_cell">
                <Link to={`/posts/edit/${row.original.id}`}>
                    <Card>
                        <CardBody style={{
                            "backgroundImage": "url('" + (
                                row.original.images && row.original.images.length ?
                                row.original.images[0].url : ""
                            ) + "')",
                            "backgroundPosition": "center center",
                            "backgroundSize": "cover"
                        }}>
                            <div className="title-wrapper">
                                <CardTitle>
                                    <div className="pt-3">{row.value[default_lang]}</div>
                                    { row.original.tags && row.original.tags.map((t, i) => (
                                        <Badge key={ i } color="primary" className="mr-1">{ t.name }</Badge>
                                    ))}
                                </CardTitle>
                            </div>
                        </CardBody>
                    </Card>
                </Link>
            </div>
        )
    }, {
        Header: t('posts.sub_title'),
        accessor: "sub_title",
        filterMethod: Filters.textFilterMethod(`name.${default_lang}`),
        Filter: Filters.textFilter,
        filterAll: true,
        maxWidth: 600,
        Cell: row => (
                    <div className="post_cell">
                        <Card>
                            <CardBody>
                                <div className="title-wrapper">
                                    <CardTitle>
                                        {Utils.localized_or_fallback(row.value, default_lang)}
                                    </CardTitle>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
        )
    }, {
        Header: t("common.actions"),
        accessor: "id",
        maxWidth: 110,
        filterable: false,
        Cell: row => (
            <TableButtons
                id={row.value}
                actions={["edit", "delete"]}
                module="posts"
            />
        )
    }];

    return (
        <div className="PostsTable">
            <Table id="postsTable" data={ posts } columns={ columns } />
        </div>
    );
}

export default PostsTable;
