import RestClient from './RestClient';
import { Event } from '../../models';
import Utils from '../Utils';

class Events extends RestClient {
    constructor() {
        super({
            "model": Event,
            "entryPoint": "event",
            "resource": "event",
            "resources": "events"
        });
    }

    list(eventType) {
        const resource  = eventType || "event";
        const resources = eventType ? `${eventType}s` : "events";

        return this.GET(`/${resource}${this.LIST_PATH}`)
            .then(response => response.json())
            .then(json => {
                if (json[resources]) {
                    json.events = json[resources];
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    search(eventType, search) {
        const resource  = eventType || "event";
        const resources = eventType ? `${eventType}s` : "events";

        return this.GET(`/${resource}/search`, { search })
            .then(response => response.json())
            .then(json => {
                if (json[resources]) {
                    json.events = json[resources];
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    get(eventType, id) {
        const resource  = eventType;

        return this.GET(`/${resource}${this.GET_PATH}/${id}`)
            .then(response => response.json())
            .then(json => {
                if (json[resource]) {
                    json.event = new Event(json[resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    create(eventType, payload) {
        const resource  = eventType;
        payload = new Event(payload);
        if ('prepareForUpdate' in payload)
            payload = payload.prepareForUpdate();

        return this.POST(`/${resource}${this.CREATE_PATH}`, { [resource]: payload })
            .then(response => response.json())
            .then(json => {
                if (json[resource]) {
                    json.event = new Event(json[resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    copy(eventType, id) {
        const resource  = eventType;
        return this.POST(`/${resource}/copy/id/${id}`, {})
            .then(response => response.json())
            .then(json => {
                if (json[resource]) {
                    json.event = new Event(json[resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    update(eventType, payload) {
        const resource  = eventType;
        payload = new Event(payload);
        if ('prepareForUpdate' in payload)
            payload = payload.prepareForUpdate();

        return this.PUT(
                `/${resource}${this.UPDATE_PATH}/${payload.id}`,
                { [resource]: payload }
            ).then(response => response.json())
            .then(json => {
                if (json[resource]) {
                    json.event = new Event(json[resource]);
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }

    delete(eventType, id) {
        const resource  = eventType;

        return this.POST(`/${resource}${this.DELETE_PATH}/${id}`, {})
            .then(response => response.json())
            .then(json => {
                if (json[resource]) {
                    json.event = json[resource];
                    return json;
                }
                throw new Error(json.flash.error);
            });
    }
}

const EventsApi = new Events();

export default EventsApi;
