import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Form, FormGroup, Label } from 'reactstrap';
import { reduxForm } from 'redux-form'
import { Loader, FormsField, ActivitiesField, UsersField, EmailLayoutsField, WysiwygField, TranslatableField } from '../../../components';
import { getApplicationTypesLinkedResources } from '../../../redux';

import moment from 'moment';
import 'moment/locale/fr';
import momentLocalizer from 'react-widgets-moment';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('fr');
momentLocalizer();

let ApplicationTypeForm = (props) => {
    const { isCreation, handleSubmit, pristine, submitting } = props;

    const { t }    = useTranslation();
    const dispatch = useDispatch();

    const state = useSelector(state => state.applicationTypes);
    const { isLoading, application_type, linkedResources } = useSelector(state => state.applicationTypes);

    const forms      = linkedResources.forms ?? [];
    const users      = linkedResources.users ?? [];
    const emails     = linkedResources.emails ?? [];
    const activities = linkedResources.activities ?? [];

    useEffect(() => {
        dispatch(getApplicationTypesLinkedResources());
    }, [])

    return (
        <Form onSubmit={handleSubmit}>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("applicationTypes.informations")}</CardTitle>
                            <CardText tag="div">
                                <Row>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="name" sm={12}>{t("applicationTypes.name")}</Label>
                                            <Col sm={12}>
                                                <TranslatableField name="name" placeholder={t("applicationTypes.name_placeholder")} component="input" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="form_id" sm={12}>{t("applicationTypes.form")}</Label>
                                            <Col sm={12}>
                                                <FormsField name="form_id" className="form-control" forms={ forms } single />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup row>
                                            <Label for="description" sm={12}>{t("applicationTypes.description")}</Label>
                                            <Col sm={12}>
                                                <TranslatableField name="description" placeholder={t("applicationTypes.description_placeholder")} component={WysiwygField} />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            { !isCreation && (
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-envelope"></i>&nbsp;{t("applicationTypes.notifications")}</CardTitle>
                                <CardText tag="div">
                                    { linkedResources?.emails ? (
                                        <Row>
                                            <Col>
                                                <FormGroup row>
                                                    <Label for="admin_ids" sm={12}>{t("applicationTypes.admins")}</Label>
                                                    <Col sm={12}>
                                                        <UsersField name="admin_ids" className="form-control" users={ users } />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="on_receive_email_id" sm={12}>{t("applicationTypes.on_receive_email_id")}</Label>
                                                    <Col sm={12}>
                                                        <EmailLayoutsField name="on_receive_email_id" className="form-control" emailLayouts={ emails } single />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup row>
                                                    <Label for="on_submit_email_id" sm={12}>{t("applicationTypes.on_submit_email_id")}</Label>
                                                    <Col sm={12}>
                                                        <EmailLayoutsField name="on_submit_email_id" className="form-control" emailLayouts={ emails } single />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="on_approve_email_id" sm={12}>{t("applicationTypes.on_approve_email_id")}</Label>
                                                    <Col sm={12}>
                                                        <EmailLayoutsField name="on_approve_email_id" className="form-control" emailLayouts={ emails } single />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="on_reject_email_id" sm={12}>{t("applicationTypes.on_reject_email_id")}</Label>
                                                    <Col sm={12}>
                                                        <EmailLayoutsField name="on_reject_email_id" className="form-control" emailLayouts={ emails } single />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Loader />
                                    )}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            <br/>
            { !isCreation && (
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle><i className="fa fa-id-badge"></i>&nbsp;{t("applicationTypes.activities")}</CardTitle>
                                <CardText tag="div">
                                    { linkedResources?.activities ? (
                                        <Row>
                                            <Col>
                                                <FormGroup row>
                                                    <Label for="admin_ids" sm={12}>{t("applicationTypes.activities_to_assign_on_acceptation")}</Label>
                                                    <Col sm={12}>
                                                        <ActivitiesField name="activities_ids" className="form-control" activities={ activities } />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Loader />
                                    )}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
            <br/>
                <Row>
                    <Col className="text-center">
                        <br/>
                        <Button type="submit" color="success" disabled={pristine || submitting || isLoading}>
                            {isLoading ?
                                <span>
                                    &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                </span>
                                :
                                <span>
                                    <i className="fa fa-save"></i>&nbsp;
                                    {t("common.save")}
                                </span>
                            }
                        </Button>
                    </Col>
                </Row>
        </Form>
    );

}

ApplicationTypeForm = reduxForm({
  form: 'applicationTypeForm',
  enableReinitialize: true
})(ApplicationTypeForm);

ApplicationTypeForm = connect(
  state => ({
    initialValues: state.applicationTypes.application_type,
    ApplicationTypes: state.applicationTypes,
}), {}
)(ApplicationTypeForm)

export default ApplicationTypeForm;
