import React from 'react';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Field } from 'redux-form'
import { Utils } from '../../services';
import { Dropdown } from 'semantic-ui-react';
import SelectField from '../SelectField/SelectField';
import i18n from '../../locales/i18n';

import './PostCategoriesField.css';

const PostCategoriesField = ({ name, postCategories }) => {
    const { t } = useTranslation();

    if (!postCategories)
        return null;

    const options = postCategories.map(category => {
        return {
            key: category.id,
            text: Utils.localized_or_fallback(category.name, i18n.language),
            value: category.id
        };
    });

    return (
        <SelectField
            name={ name || "category_id" }
            options={ options }
            placeholder={ t('posts.choose_a_category') }
            clearable
        />
    );
}

export default PostCategoriesField;
