import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form'
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Utils } from '../../../../services';
import CountriesField from '../../../CountriesField/CountriesField';
import MoneyField from '../../../MoneyField/MoneyField';

import './DeliveryTypeRuleForm.css';

const DeliveryTypeRuleForm = ({ name, rule, type }) => {
    const { t }                  = useTranslation();
    const { isMobile, isTablet } = useSelector(state => state.ui);

    return (
        <div className="DeliveryTypeRuleForm">
            <Row>
                <Col sm={ 6 }>
                    <FormGroup row>
                        <Label for="name" className={"text-" + ( isMobile || isTablet ? "left" : "right")} sm={12} xl={6}>{t('settings.delivery.type.price')}</Label>
                        <Col sm={12} xl={6}>
                            <MoneyField name={ `${name}.price.${Utils.currency()}` } />
                        </Col>
                    </FormGroup>
                    { type === Utils.DELIVERY_TYPE_SHIPMENT && (
                    <>
                        <FormGroup row>
                            <Label for="name" className={"text-" + ( isMobile || isTablet ? "left" : "right")} sm={12} xl={6}>{t('settings.delivery.type.max_length')}</Label>
                            <Col sm={12} xl={6}>
                                <InputGroup>
                                    <Field
                                        component="input"
                                        type="number"
                                        step={ 1 }
                                        name={ `${name}.max_length` }
                                        className="form-control"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>{ t('common.mini_centimeters') }</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" className={"text-" + ( isMobile || isTablet ? "left" : "right")} sm={12} xl={6}>{t('settings.delivery.type.max_width')}</Label>
                            <Col sm={12} xl={6}>
                                <InputGroup>
                                    <Field
                                        component="input"
                                        type="number"
                                        step={ 1 }
                                        name={ `${name}.max_width` }
                                        className="form-control"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>{ t('common.mini_centimeters') }</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" className={"text-" + ( isMobile || isTablet ? "left" : "right")} sm={12} xl={6}>{t('settings.delivery.type.max_weight')}</Label>
                            <Col sm={12} xl={6}>
                                <InputGroup>
                                    <Field
                                        component="input"
                                        type="number"
                                        step={ 1 }
                                        name={ `${name}.max_weight` }
                                        className="form-control"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>{ t('common.mini_grammes') }</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </>
                    )}
                </Col>
                <Col sm={ 6 }>
                    <FormGroup row>
                        <Label for="name" className={"text-" + ( isMobile || isTablet ? "left" : "right")} sm={12} xl={6}>{t('settings.delivery.type.min_cart_amount')}</Label>
                        <Col sm={12} xl={6}>
                            <MoneyField name={ `${name}.min_cart_amount.${Utils.currency()}` } />
                        </Col>
                    </FormGroup>
                    { type === Utils.DELIVERY_TYPE_SHIPMENT && (
                    <>
                        <FormGroup row>
                            <Label for="name" className={"text-" + ( isMobile || isTablet ? "left" : "right")} sm={12} xl={6}>{t('settings.delivery.type.max_height')}</Label>
                            <Col sm={12} xl={6}>
                                <InputGroup>
                                    <Field
                                        component="input"
                                        type="number"
                                        step={ 1 }
                                        name={ `${name}.max_height` }
                                        className="form-control"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>{ t('common.mini_centimeters') }</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="name" className={"text-" + ( isMobile || isTablet ? "left" : "right")} sm={12} xl={6}>{t('settings.delivery.type.mini_max_length_width_height')}</Label>
                            <Col sm={12} xl={6}>
                                <InputGroup>
                                    <Field
                                        component="input"
                                        type="number"
                                        step={ 1 }
                                        name={ `${name}.max_length_width_height` }
                                        className="form-control"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>{ t('common.mini_centimeters') }</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </>
                    )}
                    <FormGroup row>
                        <Label for="countries" className={"text-" + ( isMobile || isTablet ? "left" : "right")} sm={12} xl={6}>{`${t("settings.delivery.type.countries")}`}</Label>
                        <Col sm={12} xl={6}>
                            <CountriesField name={ `${name}.countries` } />
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
}

export default DeliveryTypeRuleForm;
