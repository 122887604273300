// Redux logs module
// see https://github.com/erikras/ducks-modular-redux

import { Logs } from '../../services'

const LOADING_LOGS         = 'kronos/logs/LOADING_LOGS';
const LOADING_LOGS_SUCCESS = 'kronos/logs/LOADING_LOGS_SUCCESS';
const LOADING_LOGS_FAILURE = 'kronos/logs/LOADING_LOGS_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    logSuccessfullyCreated: false,
    logs: []
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_LOGS:
            return {
                ...state,
                isLoading: true,
                logSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_LOGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                logs: action.logs
            };
        case LOADING_LOGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingLogs() { return { type: LOADING_LOGS } }
function loadingLogsSuccess(logs) { return { type: LOADING_LOGS_SUCCESS, logs: logs } }
function loadingLogsFailure(err) { return { type: LOADING_LOGS_FAILURE, error: err } }
export function loadLogs(screeningId) {
    return (dispatch) => {
        dispatch(loadingLogs(screeningId));
        Logs.list({"screening_ids" : screeningId})
            .then(data => {
                const logs = data.logs;
                dispatch(loadingLogsSuccess(logs));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingLogsFailure(err))
            });
    }
}
