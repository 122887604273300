import React from "react";
import FormLayout from '../FormLayout';
import TextField from './TextField';
import { Field } from 'redux-form';
import { Utils } from '../../services';
import { PhoneField } from '../../components';

/** Class representing a PDF layout custom text field. */
class PhoneNumberField extends TextField {

    constructor(properties) {
        super({
            ...(PhoneNumberField.getDefaultOptions()),
            ...properties,
        });

        this.type = FormLayout.PHONE_NUMBER;
    }

    /**
     * Return this field preview input
     *
     * @param {Object} props with at least the following properties:
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     *                 - {Any} value
     */
    getInput(props) {
        return (
            <Field
                component={ PhoneField }
                name={this.id}
                required={!!this.required}
                className="form-control"
            />
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'phone';
    }
}

export default PhoneNumberField;
