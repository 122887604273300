import React from 'react';
import { Field } from 'redux-form'
import { Dropdown } from 'semantic-ui-react';

import './SelectField.css';

const SelectField = ({ name, options, placeholder, onSelectChange, ...rest }) => {
    const Component = (props) => {
        const { input } = props;
        const onChange = (e, data) => {
            input.onChange(data.value);
        }

        return <Dropdown
            name={ name }
            options={ options }
            defaultValue={ input.value }
            onChange={ onChange }
            placeholder={ placeholder }
            search
            selection
            { ...rest }
        />;
    }

    return (
        <div className="SelectField">
            <Field name={ name } component={ Component } onChange={onSelectChange} className="form-control" />
        </div>
    );
}

export default SelectField;
