import React from 'react';
import Widget from '../Widget';
import './AddWidget.css';

const AddWidget = () => {
    return(
        <Widget className="AddWidget"
            content={
                <i className='fa fa-plus' style={{fontSize: "2vw"}}></i>
            }
            border={`dashed`}
            clickable
        />
    )
}

export default AddWidget;
