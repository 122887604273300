import RestClient from './RestClient';
import { Font } from '../../models';

class Fonts extends RestClient {
    constructor() {
        super({
            "model": Font,
            "entryPoint": "font",
            "resource": "font",
            "resources": "fonts",
            "id_field": "_id",
            "sortBy": "name"
        });
    }

    get(id) {
        throw new Error('Not allowed');
    }

    create(payload) {
        throw new Error('Not allowed');
    }

    update(payload) {
        throw new Error('Not allowed');
    }

    duplicate(payload) {
        throw new Error('Not allowed');
    }

    delete(id) {
        throw new Error('Not allowed');
    }
}

const FontsApi = new Fonts();

export default FontsApi;
