import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PageTitle, Loader } from '../../../../components';
import { loadPrinter, updatePrinter } from '../../../../redux';
import { Row, Col, Badge, Button } from 'reactstrap';
import { Card, CardHeader, CardImg, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import printerImage from '../../../../assets/images/printer.png';
import Icon from '@mdi/react';
import { useSelector } from 'react-redux';
import {
    mdiSlotMachine, mdiArrowLeftCircle, mdiWall,
    mdiAndroidMessages, mdiBankTransfer
} from '@mdi/js';
import PrinterForm from './PrinterForm/PrinterForm';

import './PrinterManagement.css';

const PrinterManagement = () => {
    const { t }         = useTranslation();
    const dispatch      = useDispatch();
    const { printerId } = useParams();

    const { connected } = useSelector(state => state.sioBridge);
    const { printer, isLoadingOne, loadingOneErrorMessage } = useSelector(state => state.printers);

    useEffect(() => {
        if (connected) {
            dispatch(loadPrinter(printerId));
        }
    }, [dispatch, connected, printerId]);

    const saveOptions = printer => {
        if (('only_for_pos' in printer.options) && !printer.options.only_for_pos.length)
            delete printer.options.only_for_pos;
        dispatch(updatePrinter(printer));
    }

    const actions = <Link to="/devices/printers">
        <Button color="info">
            <Icon path={ mdiArrowLeftCircle } size={ 1 } color="white" /> { t('common.back') }
        </Button>
    </Link>;

    return (
        <div className="PrinterManagement">
            <Row>
                <Col>
                    <PageTitle
                        title={ t('printers.manage_title', { name: printer?.name }) }
                        icon="print"
                        actions={ actions }
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    { loadingOneErrorMessage && (
                        <div className="alert alert-danger">
                            { loadingOneErrorMessage }
                        </div>
                    )}
                    {(isLoadingOne) && (
                        <Loader />
                    )}

                    {!isLoadingOne && printer && (
                    <div>
                        <Row>
                            <Col>
                                <Card className="card-outline">
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            <i className="fa fa-info-circle" />{' '}
                                            { t('printers.infos') }
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="info">
                                            <label>{ t('printers.id') }</label>
                                            <span>{ printer?.id }</span>
                                        </div>
                                        <div className="info">
                                            <label>{ t('printers.name') }</label>
                                            <span>{ printer?.name }</span>
                                        </div>
                                        <div className="info">
                                            <label>{ t('printers.host') }</label>
                                            <span>{ printer?.host } <small>({ printer?.host_id})</small></span>
                                        </div>
                                        <div className="info">
                                            <label>{ t('printers.last_connexion') }</label>
                                            <span>{ printer?.getLastConnectedAt() }</span>
                                        </div>
                                        <div className="info">
                                            <label>{ t('printers.status') }</label>
                                            <span>
                                                <Badge color={ printer.isConnected() ? 'success' : 'danger' }>
                                                    { t(`printers.${printer.isConnected() ? 'connected' : 'disconnected'}`) }<br/>
                                                </Badge>
                                            </span>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className="card-outline">
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            <i className="fa fa-gear" />{' '}
                                            { t('printers.options') }
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <PrinterForm initialValues={ printer } onSubmit={ saveOptions } isLoading={ isLoadingOne }/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    )}
                </Col>
            </Row>
        </div>
    );
}

export default PrinterManagement;
