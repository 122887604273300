// Redux Distributors module
// see https://github.com/erikras/ducks-modular-redux

import { Distributors } from '../../services';

const LOADING_DISTRIBUTORS         = 'kronos/distributors/LOADING_DISTRIBUTORS';
const LOADING_DISTRIBUTORS_SUCCESS = 'kronos/distributors/LOADING_DISTRIBUTORS_SUCCESS';
const LOADING_DISTRIBUTORS_FAILURE = 'kronos/distributors/LOADING_DISTRIBUTORS_FAILURE';
const LOADING_DISTRIBUTOR          = 'kronos/distributors/LOADING_DISTRIBUTOR';
const LOADING_DISTRIBUTOR_SUCCESS  = 'kronos/distributors/LOADING_DISTRIBUTOR_SUCCESS';
const LOADING_DISTRIBUTOR_FAILURE  = 'kronos/distributors/LOADING_DISTRIBUTOR_FAILURE';
const UPDATING_DISTRIBUTOR         = 'kronos/distributors/UPDATING_DISTRIBUTOR';
const UPDATING_DISTRIBUTOR_SUCCESS = 'kronos/distributors/UPDATING_DISTRIBUTOR_SUCCESS';
const UPDATING_DISTRIBUTOR_FAILURE = 'kronos/distributors/UPDATING_DISTRIBUTOR_FAILURE';
const START_CREATING_DISTRIBUTOR   = 'kronos/distributors/START_CREATING_DISTRIBUTOR';
const CREATING_DISTRIBUTOR         = 'kronos/distributors/CREATING_DISTRIBUTOR';
const CREATING_DISTRIBUTOR_SUCCESS = 'kronos/distributors/CREATING_DISTRIBUTOR_SUCCESS';
const CREATING_DISTRIBUTOR_FAILURE = 'kronos/distributors/CREATING_DISTRIBUTOR_FAILURE';
const DELETING_DISTRIBUTOR         = 'kronos/distributors/DELETING_DISTRIBUTOR';
const DELETING_DISTRIBUTOR_SUCCESS = 'kronos/distributors/DELETING_DISTRIBUTOR_SUCCESS';
const DELETING_DISTRIBUTOR_FAILURE = 'kronos/distributors/DELETING_DISTRIBUTOR_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    distributorSuccessfullyCreated: false,
    distributors: [],
    distributor: null
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_DISTRIBUTORS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_DISTRIBUTORS_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                distributors: action.distributors
            };
        case LOADING_DISTRIBUTORS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_DISTRIBUTOR:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_DISTRIBUTOR_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                distributor: action.distributor
            };
        case LOADING_DISTRIBUTOR_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_DISTRIBUTOR:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_DISTRIBUTOR_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                distributor: action.distributor
            };
        case UPDATING_DISTRIBUTOR_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_DISTRIBUTOR:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                distributorSuccessfullyCreated: false,
                distributor: {}
            };
        case CREATING_DISTRIBUTOR:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_DISTRIBUTOR_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                distributorSuccessfullyCreated: true,
                distributor: action.distributor
            };
        case CREATING_DISTRIBUTOR_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                distributorSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_DISTRIBUTOR:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_DISTRIBUTOR_SUCCESS:
            return {
                isLoading: false,
                hasLoadingError: false,
                distributorSuccessfullyDeleted: true,
                distributor: action.distributor
            };
        case DELETING_DISTRIBUTOR_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                distributorSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingDistributors() { return { type: LOADING_DISTRIBUTORS } }
function loadingDistributorsSuccess(distributors) { return { type: LOADING_DISTRIBUTORS_SUCCESS, distributors: distributors } }
function loadingDistributorsFailure(err) { return { type: LOADING_DISTRIBUTORS_FAILURE, error: err } }
export function loadDistributors(distributorname, password) {
    return (dispatch) => {
        dispatch(loadingDistributors());
        Distributors.list()
            .then(data => {
                const distributors = data.distributors;
                dispatch(loadingDistributorsSuccess(distributors));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingDistributorsFailure(err))
            });
    }
}

function loadingDistributor() { return { type: LOADING_DISTRIBUTOR } }
function loadingDistributorSuccess(distributor) { return { type: LOADING_DISTRIBUTOR_SUCCESS, distributor: distributor } }
function loadingDistributorFailure(err) { return { type: LOADING_DISTRIBUTOR_FAILURE, error: err } }
export function loadDistributor(distributorId) {
    return (dispatch) => {
        dispatch(loadingDistributor());
        Distributors.get(distributorId)
            .then(data => {
                const distributor = data.distributor;
                dispatch(loadingDistributorSuccess(distributor));
            })
            .catch(err => {
                dispatch(loadingDistributorFailure(err))
            });
    }
}

function updatingDistributor() { return { type: UPDATING_DISTRIBUTOR } }
function updatingDistributorSuccess(distributor) { return { type: UPDATING_DISTRIBUTOR_SUCCESS, distributor: distributor } }
function updatingDistributorFailure(err) { return { type: UPDATING_DISTRIBUTOR_FAILURE, error: err } }
export function updateDistributor(distributor) {
    return (dispatch) => {
        dispatch(updatingDistributor());
        Distributors.update(distributor)
            .then(data => {
                const distributor = data.distributor;
                dispatch(updatingDistributorSuccess(distributor));
            })
            .catch(err => {
                dispatch(updatingDistributorFailure(err))
            });
    }
}

function creatingDistributor() { return { type: CREATING_DISTRIBUTOR } }
function creatingDistributorSuccess(distributor) { return { type: CREATING_DISTRIBUTOR_SUCCESS, distributor: distributor } }
function creatingDistributorFailure(err) { return { type: CREATING_DISTRIBUTOR_FAILURE, error: err } }
export function startCreateDistributor() { return { type: START_CREATING_DISTRIBUTOR } }
export function createDistributor(distributor) {
    return (dispatch) => {
        dispatch(creatingDistributor());
        Distributors.create(distributor)
            .then(data => {
                const distributor = data.distributor;
                dispatch(creatingDistributorSuccess(distributor));
                dispatch(loadingDistributorSuccess(distributor));
            })
            .catch(err => {
                dispatch(creatingDistributorFailure(err))
            });
    }
}

function deletingDistributor() { return { type: DELETING_DISTRIBUTOR } }
function deletingDistributorSuccess() { return { type: DELETING_DISTRIBUTOR_SUCCESS } }
function deletingDistributorFailure(err) { return { type: DELETING_DISTRIBUTOR_FAILURE, error: err } }
export function deleteDistributor(distributorId) {
    return (dispatch) => {
        dispatch(deletingDistributor());
        Distributors.delete(distributorId)
            .then(data => {
                dispatch(deletingDistributorSuccess());
            })
            .catch(err => {
                dispatch(deletingDistributorFailure(err))
            });
    }
}
