import React from "react";
import EmailLayout from '../EmailLayout';
import EmailLayoutWidget from './EmailLayoutWidget';

/** Class representing a PDF layout image widget. */
class LibraryImageWidget extends EmailLayoutWidget {

    constructor(properties) {
        super({
            ...(LibraryImageWidget.getDefaultOptions()),
            ...properties,
            type: EmailLayout.IMAGE,
            subType: EmailLayout.LIBRARY_IMAGE
        });
    }

    /**
     * Get this widget default options
     */
    static getDefaultOptions() {
        return {
            options: {
                type: 'logo-a4',
                style: {
                    align: 'center',
                    objectFit: "contain",
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderColor: '#000000',
                    borderRadius: 0,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    }
                },
            }
        };
    }

    /**
     * Return the list of options this widget handles
     */
    getHandledOptions() {
        return super.getHandledOptions().concat([
            EmailLayout.OPTION_OBJECT_FIT,
            EmailLayout.OPTION_ALIGNMENT,
            EmailLayout.OPTION_IMAGE_TYPE,
            EmailLayout.OPTION_PADDING,
        ]);
    }

    /**
     * Return this widget preview as JSX string
     *
     * @param {Object} props with at least the following properties:
     *                 - {EmailLayout} layout
     *                 - {Bool} inEdition
     *                 - {String} lang
     *                 - {Object} setting
     */
    getPreview(props) {
        const { setting, lang, inEdition } = props;

        const image = setting?.findImage(this.options?.type, lang);

        const imageStyle = {
            width: '100%',
            height: '100%',
        };
        if (this.options.style?.objectFit === 'cover' ||
            this.options.style?.objectFit === 'contain') {
            imageStyle.objectFit = this.options.style?.objectFit;
        };

        return (
            <div style={{
                width: '100%',
                height: 'auto',
                backgroundColor: this.options?.style?.backgroundColor,
                border: `${this.options?.style?.borderWidth}px ${this.options?.style?.borderStyle} ${this.options?.style?.borderColor}`,
                borderRadius: `${this.options?.style?.borderRadius || 0}px`,
                paddingTop: `${this.options?.style?.padding?.top || 0}px`,
                paddingRight: `${this.options?.style?.padding?.right || 0}px`,
                paddingBottom: `${this.options?.style?.padding?.bottom || 0}px`,
                paddingLeft: `${this.options?.style?.padding?.left || 0}px`,
            }}>
                { image?.rfc2397_data && (
                    <>
                        <img ref={props.ref} src={ image.rfc2397_data } style={ imageStyle } />
                    </>
                )}

                { !image?.rfc2397_data && (
                    <div style={{
                        backgroundColor: '#565656',
                        width: '100%',
                        height: '100%'
                    }} />
                )}

            </div>
        );
    }

    /**
     * Return the icon to use on the editor button
     */
    getIcon() {
        return 'photo';
    }
}

export default LibraryImageWidget;
