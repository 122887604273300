import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Row, Col, ButtonGroup, Button, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Link } from "react-router-dom";
import PageTitle from '../../../components/PageTitle/PageTitle';
import { withTranslation } from 'react-i18next';

import { loadDistributor } from '../../../redux/modules/distributors';

class DistributorShow extends Component<{}> {
    componentDidMount() {
        const distributorId = this.props.match.params.distributorId;
        // Load distributors on component mount
        this.props.dispatchLoadDistributor(distributorId);
    }

    render() {
        const { t }           = this.props;
        const { distributor } = this.props.distributors;

        let title = <span>{t("distributors.distributor_plural")}</span>;
        if (distributor)
            title = (
                <span>
                    {t("distributors.distributor")}
                    <small> | { distributor.name } </small>
                </span>
            );

        const actions =
        <div>
            <ButtonGroup>
                <Button tag={Link} to="/distributors" color="warning" size="sm">
                    <i className="fa fa-arrow-left"></i>&nbsp;{t("common.back")}
                </Button>
                {distributor &&
                    <Button tag={Link} to={ `/distributors/edit/${ distributor.id }` } color="info" size="sm">
                        <i className="fa fa-edit"></i>&nbsp;{t("common.edit")}
                    </Button>
                }
            </ButtonGroup>
        </div>

        return (
            <div className="DistributorShow">
                <PageTitle icon="money" title={ title } actions={ actions } />
                {distributor &&
                    <div>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle><i className="fa fa-info-circle"></i>&nbsp;{t("distributors.informations")}</CardTitle>
                                        <CardText tag="div">
                                            <div><b>{t("distributors.name")}</b>: { distributor.name }</div>
                                            {distributor.maccsbox_id &&
                                                <div><b>{t("distributors.maccsbox_id")}</b>: { distributor.maccsbox_id }</div>}
                                            {distributor.report_mail &&
                                                <div><b>{t("distributors.report_mail")}</b>: { distributor.report_mail }</div>}
                                            {distributor.contact_mail &&
                                                <div><b>{t("distributors.contact_mail")}</b>: { distributor.contact_mail }</div>}
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = {
    dispatchLoadDistributor: (distributorId) => loadDistributor(distributorId)
}

const mapStateToProps = state => ({
    distributors: state.distributors
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(DistributorShow))
