// Redux Screenings module
// see https://github.com/erikras/ducks-modular-redux

import { LegacyScreenings } from '../../services/';

const LOADING_LEGACY_SCREENINGS            = 'kronos/legacyScreenings/LOADING_LEGACY_SCREENINGS';
const LOADING_LEGACY_SCREENINGS_SUCCESS    = 'kronos/legacyScreenings/LOADING_LEGACY_SCREENINGS_SUCCESS';
const LOADING_LEGACY_SCREENINGS_FAILURE    = 'kronos/legacyScreenings/LOADING_LEGACY_SCREENINGS_FAILURE';
const LOADING_LEGACY_SCREENING             = 'kronos/legacyScreenings/LOADING_LEGACY_SCREENING';
const LOADING_LEGACY_SCREENING_SUCCESS     = 'kronos/legacyScreenings/LOADING_LEGACY_SCREENING_SUCCESS';
const LOADING_LEGACY_SCREENING_FAILURE     = 'kronos/legacyScreenings/LOADING_LEGACY_SCREENING_FAILURE';
const UPDATING_LEGACY_SCREENING            = 'kronos/legacyScreenings/UPDATING_LEGACY_SCREENING';
const UPDATING_LEGACY_SCREENING_SUCCESS    = 'kronos/legacyScreenings/UPDATING_LEGACY_SCREENING_SUCCESS';
const UPDATING_LEGACY_SCREENING_FAILURE    = 'kronos/legacyScreenings/UPDATING_LEGACY_SCREENING_FAILURE';
const START_CREATING_LEGACY_SCREENING      = 'kronos/legacyScreenings/START_CREATING_LEGACY_SCREENING';
const CREATING_LEGACY_SCREENING            = 'kronos/legacyScreenings/CREATING_LEGACY_SCREENING';
const CREATING_LEGACY_SCREENING_SUCCESS    = 'kronos/legacyScreenings/CREATING_LEGACY_SCREENING_SUCCESS';
const CREATING_LEGACY_SCREENING_FAILURE    = 'kronos/legacyScreenings/CREATING_LEGACY_SCREENING_FAILURE';
const DELETING_LEGACY_SCREENING            = 'kronos/legacyScreenings/DELETING_LEGACY_SCREENING';
const DELETING_LEGACY_SCREENING_SUCCESS    = 'kronos/legacyScreenings/DELETING_LEGACY_SCREENING_SUCCESS';
const DELETING_LEGACY_SCREENING_FAILURE    = 'kronos/legacyScreenings/DELETING_LEGACY_SCREENING_FAILURE';
const TKTSYNCING_LEGACY_SCREENINGS         = 'kronos/legacyScreenings/TKTSYNCING_LEGACY_SCREENINGS';
const TKTSYNCING_LEGACY_SCREENINGS_SUCCESS = 'kronos/legacyScreenings/TKTSYNCING_LEGACY_SCREENINGS_SUCCESS';
const TKTSYNCING_LEGACY_SCREENINGS_FAILURE = 'kronos/legacyScreenings/TKTSYNCING_LEGACY_SCREENINGS_FAILURE';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    legacyScreeningSuccessfullyCreated: false,
    legacyScreenings: [],
    legacyScreening: {},
    isTktsyncing: false,
    hasTktsyncingError: false,
    legacyScreeningSuccessfullyDeleted: false,
    tktsyncingErrorMessage: '',
    imported: [],
}

// Reducer
export default function reducer(state = initialState, action) {
    switch(action.type) {
        case LOADING_LEGACY_SCREENINGS:
            return {
                ...state,
                isLoading: true,
                legacyScreeningSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_LEGACY_SCREENINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                legacyScreenings: action.legacyScreenings
            };
        case LOADING_LEGACY_SCREENINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_LEGACY_SCREENING:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case LOADING_LEGACY_SCREENING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                legacyScreening: action.legacyScreening
            };
        case LOADING_LEGACY_SCREENING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_LEGACY_SCREENING:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_LEGACY_SCREENING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                legacyScreening: action.legacyScreening
            };
        case UPDATING_LEGACY_SCREENING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_LEGACY_SCREENING:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                screeningSuccessfullyCreated: false,
                legacyScreening: {}
            };
        case CREATING_LEGACY_SCREENING:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_LEGACY_SCREENING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                legacyScreeningSuccessfullyCreated: true,
                legacyScreening: action.legacyScreening
            };
        case CREATING_LEGACY_SCREENING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                legacyScreeningSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_LEGACY_SCREENING:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case DELETING_LEGACY_SCREENING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                legacyScreeningSuccessfullyDeleted: true,
                legacyScreening: action.legacyScreening
            };
        case DELETING_LEGACY_SCREENING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                legacyScreeningSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case TKTSYNCING_LEGACY_SCREENINGS:
            return {
                ...state,
                isTktsyncing: true,
                hasTktsyncingError: false
            };
        case TKTSYNCING_LEGACY_SCREENINGS_SUCCESS:
            return {
                ...state,
                isTktsyncing: false,
                hasTktsyncingError: false,
                imported: action.imported
            };
        case TKTSYNCING_LEGACY_SCREENINGS_FAILURE:
            return {
                ...state,
                isTktsyncing: false,
                hasTktsyncingError: true,
                tktsyncingErrorMessage: action.error.message
            };
        default:
            return state;
    }
};

// Actions
function loadingLegacyScreenings() { return { type: LOADING_LEGACY_SCREENINGS } }
function loadingLegacyScreeningsSuccess(legacyScreenings) { return { type: LOADING_LEGACY_SCREENINGS_SUCCESS, legacyScreenings: legacyScreenings } }
function loadingLegacyScreeningsFailure(err) { return { type: LOADING_LEGACY_SCREENINGS_FAILURE, error: err } }
export function loadLegacyScreenings(programmation_id) {
    return (dispatch) => {
        dispatch(loadingLegacyScreenings());
        LegacyScreenings.list(programmation_id)
            .then(data => {
                const legacyScreenings = data.screenings;
                dispatch(loadingLegacyScreeningsSuccess(legacyScreenings));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingLegacyScreeningsFailure(err))
            });
    }
}

function loadingLegacyScreening() { return { type: LOADING_LEGACY_SCREENING } }
function loadingLegacyScreeningSuccess(legacyScreening) { return { type: LOADING_LEGACY_SCREENING_SUCCESS, legacyScreening: legacyScreening } }
function loadingLegacyScreeningFailure(err) { return { type: LOADING_LEGACY_SCREENING_FAILURE, error: err } }
export function loadLegacyScreening(screeningId) {
    return (dispatch) => {
        dispatch(loadingLegacyScreening());
        LegacyScreenings.get(screeningId)
            .then(data => {
                const legacyScreening = data.screening;
                dispatch(loadingLegacyScreeningSuccess(legacyScreening));
            })
            .catch(err => {
                dispatch(loadingLegacyScreeningFailure(err))
            });
    }
}

function updatingLegacyScreening() { return { type: UPDATING_LEGACY_SCREENING } }
function updatingLegacyScreeningSuccess(legacyScreening) { return { type: UPDATING_LEGACY_SCREENING_SUCCESS, legacyScreening: legacyScreening } }
function updatingLegacyScreeningFailure(err) { return { type: UPDATING_LEGACY_SCREENING_FAILURE, error: err } }
export function updateLegacyScreening(legacyScreening) {
    return (dispatch) => {
        dispatch(updatingLegacyScreening());
        LegacyScreenings.update(legacyScreening)
            .then(data => {
                const legacyScreening = data.screening;
                dispatch(updatingLegacyScreeningSuccess(legacyScreening));
            })
            .catch(err => {
                dispatch(updatingLegacyScreeningFailure(err))
            });
    }
}

function creatingLegacyScreening() { return { type: CREATING_LEGACY_SCREENING } }
function creatingLegacyScreeningSuccess(legacyScreening) { return { type: CREATING_LEGACY_SCREENING_SUCCESS, legacyScreening: legacyScreening } }
function creatingLegacyScreeningFailure(err) { return { type: CREATING_LEGACY_SCREENING_FAILURE, error: err } }
export function startCreateScreening() { return { type: START_CREATING_LEGACY_SCREENING } }
export function createLegacyScreening(legacyScreening) {
    return (dispatch) => {
        dispatch(creatingLegacyScreening());
        LegacyScreenings.create(legacyScreening)
            .then(data => {
                const legacyScreening = data.screening;
                dispatch(creatingLegacyScreeningSuccess(legacyScreening));
                dispatch(loadingLegacyScreeningSuccess(legacyScreening));
            })
            .catch(err => {
                dispatch(creatingLegacyScreeningFailure(err))
            });
    }
}

function deletingLegacyScreening() { return { type: DELETING_LEGACY_SCREENING } }
function deletingLegacyScreeningSuccess() { return { type: DELETING_LEGACY_SCREENING_SUCCESS } }
function deletingLegacyScreeningFailure(err) { return { type: DELETING_LEGACY_SCREENING_FAILURE, error: err } }
export function deleteLegacyScreening(legacyScreeningId) {
    return (dispatch) => {
        dispatch(deletingLegacyScreening());
        LegacyScreenings.delete(legacyScreeningId)
            .then(data => {
                dispatch(deletingLegacyScreeningSuccess());
            })
            .catch(err => {
                dispatch(deletingLegacyScreeningFailure(err))
            });
    }
}

function tktsyncingLegacyScreenings() { return { type: TKTSYNCING_LEGACY_SCREENINGS } }
function tktsyncingLegacyScreeningsSuccess(imported) { return { type: TKTSYNCING_LEGACY_SCREENINGS_SUCCESS, imported: imported } }
function tktsyncingLegacyScreeningsFailure(err) { return { type: TKTSYNCING_LEGACY_SCREENINGS_FAILURE, error: err } }
export function tktsyncLegacyScreenings() {
    return (dispatch) => {
        dispatch(tktsyncingLegacyScreenings());
        LegacyScreenings.tktsync()
            .then(data => {
                const imported = data.imported;
                dispatch(tktsyncingLegacyScreeningsSuccess(imported));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(tktsyncingLegacyScreeningsFailure(err))
            });
    }
}